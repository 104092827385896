<section class="search-sec">
    <div class="container">
        <form name="searchSection" [formGroup]="searchSection" novalidate="novalidate" (ngSubmit)="loadSearch()">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="input-group flex-nowrap">
                            <span class="input-group-text"><i class="fa fa-filter"
                                aria-hidden="true"></i></span>
                            <input type="text" class="form-control keyword"
                                placeholder="Enter Keyword" aria-label="Username" #searchSectionKeyword maxlength="100"
                                aria-describedby="addon-wrapping" formControlName="keyword">
                            <select class="form-select" id="inputGroupSelect01"
                                aria-label="Example select with button addon" #taxIdValue formControlName="taxId" (change)="showActsRules(taxIdValue.value,contentValue.value)">
                                <option selected value="undefined">All Taxes</option>
                                <option *ngFor = "let taxMst of cbicTaxMsts" [value]="taxMst.id">{{taxMst.taxName}}</option>
                            </select>
                            <select class="form-select" id="inputGroupSelect02"
                                aria-label="Example select with button addon" #contentValue formControlName="content" (change)="showActsRules(taxIdValue.value,contentValue.value)">
                                <option selected value="undefined">All Contents</option>
                                <option *ngFor="let contentTest of cbicContents" [value]="contentTest.id">{{contentTest.contentName}}</option>
                            </select>
                            <select class="form-select" id="inputGroupSelect03"
                                aria-label="Example select with button addon" #actIdValue formControlName="actId">
                                <option selected value="undefined">All</option>
                                <option *ngFor="let actMst of cbicContentHeader" [value]="actMst.contentId" title="{{actMst.contentName}}">{{actMst.contentNameLable}}</option>
                            </select>                        
                            <button class="btn" type="button"><a  (click)="loadSearch()"><i
                                        class="fas fa-search"></i></a></button>
                        </div>
                        <!--<div class="col-lg-3 col-md-3 col-sm-12 p-0">
                            <input type="text" class="form-control search-slt" placeholder="Enter Pickup City">
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-12 p-0">
                            <select class="form-select search-slt" aria-label="Default select example">
                                <option selected>Default</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-12 p-0">
                            <select class="form-control search-slt" id="exampleFormControlSelect1">
                                <option>Select Vehicle</option>
                                <option>Example one</option>
                                <option>Example one</option>
                                <option>Example one</option>
                                <option>Example one</option>
                                <option>Example one</option>
                                <option>Example one</option>
                            </select>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-12 p-0">
                            <button type="button" class="btn"><i class="fas fa-search"></i></button>
                        </div> -->
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>