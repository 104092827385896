<div class="container">
    <!-- <div *ngIf="isLoaded" style="text-align: center;">
        <button class="btn btn-primary" (click)="prevPage()" [disabled]="page === 1">Prev</button>
        <span class="mr-2 ml-2">{{ page }} / {{ totalPages }}</span>
        <button class="btn btn-primary" (click)="nextPage()" [disabled]="page === totalPages">Next</button>
    </div> -->
    <br/>
    <div *ngIf="isLoaded" style="text-align: center; margin-left: 55%;">
        <a [routerLink]="" (click)="downloadDocuments(docId, docType)" data-toggle="tooltip" data-placement="top" title="Download Document" > 
            Download <i class="fa fa-download" aria-hidden="true"></i></a>
    </div>

    <pdf-viewer *ngIf="fileURL" [src]="fileURL" 
        [fit-to-page]="true" [show-borders]="true" [show-all]="true" [page]="page"
        (after-load-complete)="afterLoadComplete($event)">
    </pdf-viewer>
</div>