import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CbicSTRuleSearchInput, ICbicSearchInput } from 'app/searchbar/searchbar.model';
import { SessionStorageService } from 'ngx-webstorage';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { CbicAlliedActDtlsService } from 'app/entities/cbic-allied-act-dtls/service/cbic-allied-act-dtls.service';
@Component({
  selector: 'cbic-allied-act-search',
  templateUrl: './allied-act-search.component.html',
  styleUrls: ['./allied-act-search.component.scss']
})
export class AlliedActSearchComponent implements OnInit, OnChanges {
 @Output() alliedActNameSelected = new EventEmitter<any>();
  @Output() alliedActYearSelected = new EventEmitter<string>();
  @Output() alliedActSearch = new EventEmitter<string>();
  @Output() clearAlliedActSearch = new EventEmitter<string>();
  @Input() activeTab: any
  @Input() tabName: any
  @Input() taxName: any
  @Input() alliedActNameList: any;
  @Input() selectedYear: any;
  @Input() taxId: any;
  alliedActNameSelect: any;
  alliedActName: any;
  language: any
  alliedYear: any;
  yearList: any;
 alliedYearValue: any;
  searched: any;
  faSearch = faSearch;
  faTimesCircle = faTimesCircle;
  alliedYearDatabse: any;
  alliedYearValueDatabase: any;
  searchBarForm = this.fb.group({
    keyword: [],
    taxId: [],
    alliedYear: [],
    actName: []
  });

  constructor(private sessionStorage: SessionStorageService,
    private fb: FormBuilder,
    private cbicAlliedActDtlsService: CbicAlliedActDtlsService
    ) {
  }

  ngOnChanges(): void {
    return;
  }

  ngOnInit(): void {
    this.language = this.sessionStorage.retrieve('locale')
    
    const query: any = {
      taxId: this.taxId,
    };
    query['alliedActName'] = 'Directorate General of Foreign Trade (DGFT)';

    this.alliedYearValueDatabase = [];
    this.cbicAlliedActDtlsService
      .fetchAlliedActYear(query)
      .subscribe(
        (res) => {
          this.alliedYearDatabse = res.body;

          let j = 0;
          for (j; j < this.alliedYearDatabse.length; j++) {
            this.alliedYearValueDatabase.push(this.alliedYearDatabse[j].year)
          }
         //for getting the full year
          this.yearList = [];
          let i = 0;
          for (i; i < this.alliedYearValueDatabase.length; i++) {
            if (this.alliedYearValueDatabase[i] > 2000) 
            {
              this.yearList.push(this.alliedYearValueDatabase[i]);
            }

          }
       //this.tempYear= this.tempYear.slice().reverse();
        this.alliedYearValue = this.yearList[0];
        }
      );
      return;
  }

  //function called when we change the act name from dropdown and year is also selected
  changeCategorySelection(alliedActName: any): void {
    
    const query: any = {
      taxId: this.taxId,
    };
    query['alliedActName'] = alliedActName;

    this.alliedYearValueDatabase = [];
    this.cbicAlliedActDtlsService
      .fetchAlliedActYear(query)
      .subscribe(
        (res) => {
          this.alliedYearDatabse = res.body;
          let j = 0;
          for (j; j < this.alliedYearDatabse.length; j++)
          {
            this.alliedYearValueDatabase.push(this.alliedYearDatabse[j].year)
          }
         //for getting the full year
          this.yearList = [];
          let i = 0;
          for (i; i < this.alliedYearValueDatabase.length; i++) 
          {
            if (this.alliedYearValueDatabase[i] > 2000) 
            {
              this.yearList.push(this.alliedYearValueDatabase[i]);
            }
          }
          if(this.yearList.length>0)
          {
        this.alliedYearValue = this.yearList[0];
          }else{
            
            this.alliedYearValue = 1999;
          }
        const alliedYearValue=this.alliedYearValue
        this.alliedActNameSelected.emit({alliedActName,alliedYearValue});
        this.alliedActName = alliedActName;

        }
      );
     return;
  }

  //for setting the default value in dropdown
  setAlliedActName(alliedAct: any): void {
    this.alliedActName = alliedAct;
  }

  //for year selection
  setAlliedActYear(year: any): void {
    //alert(year)
    this.alliedYear = new Date().getFullYear();
    this.searchBarForm.patchValue({
      alliedYear: year
    });
  }

  //when changing the year from dropdown
  fireAlliedYearSelected(alliedYear: any): void {
    this.alliedActYearSelected.emit(alliedYear);
    return;
  }

  //for clear the search
  clear(): void {
    this.searched = false;

    this.searchBarForm = this.fb.group({
      keyword: "",
      taxId: "",
      alliedYear: "",
    });
    this.clearAlliedActSearch.emit();
    return;
  }

  //for search any keyword
  search(): void {
    const cbicSearchInput = this.createAlliedActForm();
    if (cbicSearchInput.keyword === undefined || cbicSearchInput.keyword!.length < 3) {
      alert("Please enter valid keyword");
      // this.serviceKeyword?.nativeElement.focus();
      return;
    }

    cbicSearchInput.keyword = cbicSearchInput.keyword?.trim()

    this.alliedActSearch.emit(JSON.stringify(cbicSearchInput));
    this.searched = true;
  }

  protected createAlliedActForm(): ICbicSearchInput {
    return {
      ...new CbicSTRuleSearchInput(),
      keyword: (this.searchBarForm.get(['keyword'])!.value === null || this.searchBarForm.get(['keyword'])!.value === undefined) ? null : this.searchBarForm.get(['keyword'])!.value,
      taxId: this.taxId,
      content: "AlliedActs",
    };
  }


}
