import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { Search } from 'app/core/request/request.model';
import { ICbicRegulationChapterMst, getCbicRegulationChapterMstIdentifier } from '../cbic-regulation-chapter-mst.model';

export type EntityResponseType = HttpResponse<ICbicRegulationChapterMst>;
export type EntityArrayResponseType = HttpResponse<ICbicRegulationChapterMst[]>;

@Injectable({ providedIn: 'root' })
export class CbicRegulationChapterMstService {
  
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/cbic-regulation-chapter-msts');
  public resourceSearchUrl = this.applicationConfigService.getEndpointFor('api/_search/cbic-regulation-chapter-msts');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  getChapterNameForRegulation(id: number) : Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicRegulationChapterMst[]>(`${this.resourceUrl}/getChapterNameForRegulation/${id}`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  create(cbicRegulationChapterMst: ICbicRegulationChapterMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicRegulationChapterMst);
    return this.http
      .post<ICbicRegulationChapterMst>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(cbicRegulationChapterMst: ICbicRegulationChapterMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicRegulationChapterMst);
    return this.http
      .put<ICbicRegulationChapterMst>(
        `${this.resourceUrl}/${getCbicRegulationChapterMstIdentifier(cbicRegulationChapterMst) as number}`,
        copy,
        { observe: 'response' }
      )
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(cbicRegulationChapterMst: ICbicRegulationChapterMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicRegulationChapterMst);
    return this.http
      .patch<ICbicRegulationChapterMst>(
        `${this.resourceUrl}/${getCbicRegulationChapterMstIdentifier(cbicRegulationChapterMst) as number}`,
        copy,
        { observe: 'response' }
      )
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICbicRegulationChapterMst>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }
  findAllChaptersForRegDocId(regulationDocId: number) : Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicRegulationChapterMst[]>(`${this.resourceUrl}/findChaptersByRegDocId/${regulationDocId}`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicRegulationChapterMst[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  search(req: Search): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicRegulationChapterMst[]>(this.resourceSearchUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  addCbicRegulationChapterMstToCollectionIfMissing(
    cbicRegulationChapterMstCollection: ICbicRegulationChapterMst[],
    ...cbicRegulationChapterMstsToCheck: (ICbicRegulationChapterMst | null | undefined)[]
  ): ICbicRegulationChapterMst[] {
    const cbicRegulationChapterMsts: ICbicRegulationChapterMst[] = cbicRegulationChapterMstsToCheck.filter(isPresent);
    if (cbicRegulationChapterMsts.length > 0) {
      const cbicRegulationChapterMstCollectionIdentifiers = cbicRegulationChapterMstCollection.map(
        cbicRegulationChapterMstItem => getCbicRegulationChapterMstIdentifier(cbicRegulationChapterMstItem)!
      );
      const cbicRegulationChapterMstsToAdd = cbicRegulationChapterMsts.filter(cbicRegulationChapterMstItem => {
        const cbicRegulationChapterMstIdentifier = getCbicRegulationChapterMstIdentifier(cbicRegulationChapterMstItem);
        if (
          cbicRegulationChapterMstIdentifier == null ||
          cbicRegulationChapterMstCollectionIdentifiers.includes(cbicRegulationChapterMstIdentifier)
        ) {
          return false;
        }
        cbicRegulationChapterMstCollectionIdentifiers.push(cbicRegulationChapterMstIdentifier);
        return true;
      });
      return [...cbicRegulationChapterMstsToAdd, ...cbicRegulationChapterMstCollection];
    }
    return cbicRegulationChapterMstCollection;
  }

  protected convertDateFromClient(cbicRegulationChapterMst: ICbicRegulationChapterMst): ICbicRegulationChapterMst {
    return Object.assign({}, cbicRegulationChapterMst, {
      createdDt: cbicRegulationChapterMst.createdDt?.isValid() ? cbicRegulationChapterMst.createdDt.toJSON() : undefined,
      updatedDt: cbicRegulationChapterMst.updatedDt?.isValid() ? cbicRegulationChapterMst.updatedDt.toJSON() : undefined,
      issueDt: cbicRegulationChapterMst.issueDt?.isValid() ? cbicRegulationChapterMst.issueDt.toJSON() : undefined,
      amendDt: cbicRegulationChapterMst.amendDt?.isValid() ? cbicRegulationChapterMst.amendDt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDt = res.body.createdDt ? dayjs(res.body.createdDt) : undefined;
      res.body.updatedDt = res.body.updatedDt ? dayjs(res.body.updatedDt) : undefined;
      res.body.issueDt = res.body.issueDt ? dayjs(res.body.issueDt) : undefined;
      res.body.amendDt = res.body.amendDt ? dayjs(res.body.amendDt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((cbicRegulationChapterMst: ICbicRegulationChapterMst) => {
        cbicRegulationChapterMst.createdDt = cbicRegulationChapterMst.createdDt ? dayjs(cbicRegulationChapterMst.createdDt) : undefined;
        cbicRegulationChapterMst.updatedDt = cbicRegulationChapterMst.updatedDt ? dayjs(cbicRegulationChapterMst.updatedDt) : undefined;
        cbicRegulationChapterMst.issueDt = cbicRegulationChapterMst.issueDt ? dayjs(cbicRegulationChapterMst.issueDt) : undefined;
        cbicRegulationChapterMst.amendDt = cbicRegulationChapterMst.amendDt ? dayjs(cbicRegulationChapterMst.amendDt) : undefined;
      });
    }
    return res;
  }
}
