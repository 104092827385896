import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { Search } from 'app/core/request/request.model';
import { ICbicVisitorCount, getCbicVisitorCountIdentifier } from '../cbic-visitor-count.model';

export type EntityResponseType = HttpResponse<ICbicVisitorCount>;
export type EntityArrayResponseType = HttpResponse<ICbicVisitorCount[]>;

@Injectable({ providedIn: 'root' })
export class CbicVisitorCountService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/cbic-visitor-counts');
  public resourceSearchUrl = this.applicationConfigService.getEndpointFor('api/_search/cbic-visitor-counts');
  public counterUrl = this.applicationConfigService.getEndpointFor('api/cbic-visitor-count/count');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(cbicVisitorCount: ICbicVisitorCount): Observable<EntityResponseType> {
    return this.http.post<ICbicVisitorCount>(this.resourceUrl, cbicVisitorCount, { observe: 'response' });
  }

  update(cbicVisitorCount: ICbicVisitorCount): Observable<EntityResponseType> {
    return this.http.put<ICbicVisitorCount>(
      `${this.resourceUrl}/${getCbicVisitorCountIdentifier(cbicVisitorCount) as number}`,
      cbicVisitorCount,
      { observe: 'response' }
    );
  }

  partialUpdate(cbicVisitorCount: ICbicVisitorCount): Observable<EntityResponseType> {
    return this.http.patch<ICbicVisitorCount>(
      `${this.resourceUrl}/${getCbicVisitorCountIdentifier(cbicVisitorCount) as number}`,
      cbicVisitorCount,
      { observe: 'response' }
    );
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<ICbicVisitorCount>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<ICbicVisitorCount[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  search(req: Search): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<ICbicVisitorCount[]>(this.resourceSearchUrl, { params: options, observe: 'response' });
  }

  getVisitorCounter() : Observable<number>{
    const count = this.http.get<number>(this.counterUrl);
    return count;
  }

  addCbicVisitorCountToCollectionIfMissing(
    cbicVisitorCountCollection: ICbicVisitorCount[],
    ...cbicVisitorCountsToCheck: (ICbicVisitorCount | null | undefined)[]
  ): ICbicVisitorCount[] {
    const cbicVisitorCounts: ICbicVisitorCount[] = cbicVisitorCountsToCheck.filter(isPresent);
    if (cbicVisitorCounts.length > 0) {
      const cbicVisitorCountCollectionIdentifiers = cbicVisitorCountCollection.map(
        cbicVisitorCountItem => getCbicVisitorCountIdentifier(cbicVisitorCountItem)!
      );
      const cbicVisitorCountsToAdd = cbicVisitorCounts.filter(cbicVisitorCountItem => {
        const cbicVisitorCountIdentifier = getCbicVisitorCountIdentifier(cbicVisitorCountItem);
        if (cbicVisitorCountIdentifier == null || cbicVisitorCountCollectionIdentifiers.includes(cbicVisitorCountIdentifier)) {
          return false;
        }
        cbicVisitorCountCollectionIdentifiers.push(cbicVisitorCountIdentifier);
        return true;
      });
      return [...cbicVisitorCountsToAdd, ...cbicVisitorCountCollection];
    }
    return cbicVisitorCountCollection;
  }
}
