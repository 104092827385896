<!-- BEGIN TABLE RESULT -->
<nav aria-label="Page navigation example">
  <ul class="pagination">
    <li class="page-item">
      <a class="page-link" (click)="onPrevious()" aria-label="Previous">
        <span aria-hidden="true">&laquo;</span>
      </a>
    </li>
    
    <li class="page-item" *ngFor="let page of paginationPage;" 
      [ngClass]="{active :  page === currentPage}"><a class="page-link" (click)="loadContent(page-1)">{{page}}</a></li>    
    <!-- <li class="page-item"><a class="page-link" href="#">1</a></li>
    <li class="page-item"><a class="page-link" (click)="loadContent()">2</a></li>
    <li class="page-item"><a class="page-link" href="#">3</a></li> -->
    <li class="page-item">
      <a class="page-link" (click)="onNext()" aria-label="Next">
        <span aria-hidden="true">&raquo;</span>
      </a>
    </li>
  </ul>
</nav>


<div class="table-responsive">
    <table class="table table-hover">
        <tbody>
            <tr>
                <th cbicTranslate="docName">Document Name / Ref. Number</th>
                <!-- <th>Reference Number</th> -->
                <th cbicTranslate="taxType">Tax Type</th>
                <th cbicTranslate="docType">Document Type</th>
                <th cbicTranslate="subject">Subject</th>
                <!-- <th>Last Updated/ Issue Date </th> -->
            </tr>
            <!-- <tr>{{cbicSearchOutputResults}}</tr> -->
            <tr *ngFor="let cbicSearchOutput of cbicSearchOutputResults.dtos">
                <td  class="search-link"><a [routerLink]="" (click)="openSearchRecord(cbicSearchOutput.documentType,cbicSearchOutput.sectionId, cbicSearchOutput.taxId)">{{cbicSearchOutput.documentName}}</a></td>
                <!-- <td class="number"><input type="hidden" value="{{cbicSearchOutput.sectionId}}">{{cbicSearchOutput.referenceNumber}}</td> -->
                <td >{{cbicSearchOutput.taxType}}</td>
                <td>{{cbicSearchOutput.documentType}}</td>
                <td ><a [routerLink]="" (click)="openSearchRecord(cbicSearchOutput.documentType,cbicSearchOutput.sectionId, cbicSearchOutput.taxId)">{{cbicSearchOutput.contentName}}</a></td>
                <!-- <td class="date">{{cbicSearchOutput.lastUpdateDt}}</td> -->
            </tr>
            <!-- <tr>
                <td  ><a href="./content.html">Customs Act, 1962</a></td>
                <td class="number">17</td>
                <td >Customs</td>
                <td >Act</td>
                <td >Section 30</td>
                <td class="date">12.02.2008</td>
            </tr>
            <tr>
                <td  ><a href="./content.html">Sea Cargo Manifest and Transhipment Regulations, 2018</a></td>
                <td class="number">18</td>
                <td >Customs</td>
                <td >Regulations</td>
                <td >Regulation 2</td>
                <td class="date"></td>
            </tr>
            <tr>
                <td  ><a href="./content.html">Sea Cargo Manifest and Transhipment Regulations, 2018</a></td>
                <td class="number">27</td>
                <td >Customs</td>
                <td >Regulations</td>
                <td >Regulation 3</td>
                <td class="date"></td>
            </tr>
            <tr>
                <td  ><a href="./content.html">Sea Cargo Manifest and Transhipment Regulations, 2018</a></td>
                <td class="number">26</td>
                <td >Customs</td>
                <td >Form</td>
                <td >Form I</td>
                <td class="date"></td>
            </tr>
            <tr>
                <td  ><a href="./content.html">Sea Cargo Manifest and Transhipment Regulations, 2018</a></td>
                <td class="number">32</td>
                <td >Customs</td>
                <td >Regulations</td>
                <td >Form II</td>
                <td class="date"></td>
            </tr>
            <tr>
                <td  ><a href="./content.html">CGST Rules, 2017</a></td>
                <td class="number">12</td>
                <td >GST</td>
                <td >Rule</td>
                <td >Rule 96</td>
                <td class="date">31.12.2018</td>
            </tr>     -->
        </tbody>
    </table>
</div>
<!-- END TABLE RESULT -->

<!-- BEGIN PAGINATION -->
<nav aria-label="Page navigation example">
    <ul class="pagination">
      <li class="page-item">
        <a class="page-link" (click)="onPrevious()" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      
      <li class="page-item" *ngFor="let page of paginationPage;" 
        [ngClass]="{active :  page === currentPage}"><a class="page-link" (click)="loadContent(page-1)">{{page}}</a></li>    
      <!-- <li class="page-item"><a class="page-link" href="#">1</a></li>
      <li class="page-item"><a class="page-link" (click)="loadContent()">2</a></li>
      <li class="page-item"><a class="page-link" href="#">3</a></li> -->
      <li class="page-item">
        <a class="page-link" (click)="onNext()" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
  <!-- {{currentPage}} -->
<!-- END PAGINATION -->

