import { HttpClient, HttpResponse } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ProfileService } from 'app/layouts/profiles/profile.service';
import {stopWords} from 'app/app.constants';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { ContenPageService } from '../service/content-page.service';
import { DataUtils } from 'app/core/util/data-util.service';
import { FormResponse } from 'app/entities/cbic-form-mst/cbic-form-mst.model';
import { InnerComponentComponent } from './inner-component/inner-component.component';
import { delay } from 'rxjs/operators';
import { CbicContentMapService } from 'app/entities/cbic-content-map/service/cbic-content-map.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RelatedContentRequest, RelatedContentResponse } from 'app/entities/cbic-content-map/cbic-content-map.model';
import { SessionStorageService } from 'ngx-webstorage';


@Component({
  selector: 'cbic-section-read',
  templateUrl: './section-read.component.html',
  styleUrls: ['./section-read.component.scss']
})
export class SectionReadComponent implements OnInit {

  inProduction?: boolean;
  openAPIEnabled?: boolean;
  errorPageTobeShown=false;
  @Input() sectionList:any;
  @Input() searchKeyWord:any;
  @Input() viewSection:any;
  @Input() viewBy:any;
  @Input() selectedTab:any;
  @Input()  hostAddress:any;
  //@ViewChild("sectionContentDiv", { static: false }) sectionContentDiv!: SafeHtml;
  sectionContentDiv:any;
  readSectionNo?:string;
  readSectionName?:string;
  reading:any;
  @Output() openSectionEvent = new EventEmitter<string>();
  @Output() gotoPreviousChapter = new EventEmitter<string>();
  @Output() gotoNextChapter = new EventEmitter<string>();
  @Input() selectedChapterNo!:any;
  @Input() selectedChapterName!:any;
  @Input() selectedChapterNoHi!:any;
  @Input() selectedChapterNameHi!:any;
  @ViewChild('relatedList', { static: true }) relatedListModal!: ElementRef;

  selectedSectionId!:any;
  selectedSectionView!:any;
  selectedChapterId!:any;
  editEnable:any;
  firstChapter = "";
  LastChapter = "";
  currentChapter = "";
  dateNow:any;
  @Input() getChapterName:any;
  @Input()
  currentSection!: number;
  @Input() sectionListLengthRead:any ;
  suneditorEnvUrl=this.applicationConfigService.getEndpointFor('/api/suneditor-env/');
  relatedTabs: any;
  relatedTabsAry: any;
  relatedContentResponse!: RelatedContentResponse;
  relatedContentType:any;
  @Input() contentName:any;
  showRelatedContent = true;
  language: any

  constructor(
    private router: Router,
    private profileService: ProfileService, private http : HttpClient,
    private sanitizer: DomSanitizer,
    private applicationConfigService: ApplicationConfigService,
    private contentPageService:ContenPageService,
    private dataUtils: DataUtils,
    private changeDedectionRef: ChangeDetectorRef,
    private elem:ElementRef,
    private cbicContentMapService: CbicContentMapService,
    private modalService : NgbModal,
    private sessionStorage: SessionStorageService
  ) { 

    
    this.contentPageService.emitFetchDataEvent.subscribe((data)=>{
    
      const jsonData=JSON.parse(JSON.stringify(data));
      this.sectionContentDiv=[];
      this.relatedTabsAry=[];
      if(this.selectedTab===(jsonData.contentTypeTobeFetched)){
      this.fetchData(jsonData.contentFilePath,jsonData.contentNo,jsonData.contentName,jsonData.index,jsonData.searchKeyword,jsonData.id);
     }
    });

    this.contentPageService.closeAllSection.subscribe(()=>{
     
      this.closeAllSection();
    }
    );
  }

  ngAfterContentChecked(): void {
    this.changeDedectionRef.detectChanges();
  }
 
  
  ngOnInit(): void {
  
    this.language = this.sessionStorage.retrieve('locale')
    this.profileService.getProfileInfo().subscribe(profileInfo => {
      this.inProduction = profileInfo.inProduction;
      this.openAPIEnabled = profileInfo.openAPIEnabled;
    });

    
    this.reading=[];
    //this.reading.push(true);
    for(let i=0;i<this.sectionList?.length;i++){
      this.reading.push(false);
    }
    this.http
    .get(this.suneditorEnvUrl ,
        { responseType: 'text' })
    .subscribe(data => {
      
        this.editEnable=data;
    },
    error => {
      
      this.errorPageTobeShown=true;
    }
      );
    
    /* for(let i=0;i<this.viewByAllSectionList.length;i++){
      this.reading.push(false);
    } */
  }


  openSection(sectionId:string):void{
    
    this.openSectionEvent.emit(sectionId);
  }
  goToPreviousChapter():void{
    this.gotoPreviousChapter.emit();
  }
  goToNextChapter():void{
    this.gotoNextChapter.emit();
  }
  closeAllSection():void{
    this.reading=[];
    for(let i=0;i<this.sectionList?.length;i++){
      this.reading.push(false);
    }
  }

 
  fetchData(sectionHtmlPath: string,sectionNo: string,sectionName: string,i:number,searchKeyword:any,id:any):void{
    /*const el = document.getElementById('v-pills-c1');
    if(null!=el){    
     el.scrollBy(0,(10)*i);
    } */

    
    this.selectedSectionId=id;
    this.readSectionNo=sectionNo;
    this.readSectionName=sectionName;
    for(let j=0;j<this.sectionList?.length;j++){
      //this.reading[j]=false;
    }
    this.reading[i]=true;
    this.currentSection = i;
    
    const arr = sectionHtmlPath.split('.');
    if(!(arr[arr.length - 1] === 'pdf')){

    
        this.http
          .get('content/html/' + sectionHtmlPath,
              { responseType: 'text' })
          .subscribe(data => {
            //in case one wants to chnage the size of popup window
            //const re = /width=1200,height=575/g;
            //data=data.replace(re,"width=50,height=50");
            //alert(this.hostAddress);
            const ipaddress = new RegExp("10.1.82.150:8080", 'gi');
            data=data.replace(ipaddress,this.hostAddress);
          //  const atag = new RegExp("<a", 'gi');
           // data=data.replace(atag,"<a target='_blank'");
            //const closAtag = new RegExp("</a", 'gi');
            //data=data.replace(closAtag,"</ab");
            if(searchKeyword){
              let y = String(searchKeyword);
              let x = undefined;
              if(y.startsWith("\"") && y.endsWith("\"")){
                y=y.slice(1,y.length-1);
                x = y.split(" ");
              }else{
                x = searchKeyword.split(" ");
              } 
              
              for(let j=0;j<x.length;j++){
                if(!stopWords.includes(x[j])){
                  const re = new RegExp(x[j], 'gi');
                data=data.replace(re,"<span class=\"searched\">"+"$&"+"</span>");
                }
              }
            } 
           
            this.sectionContentDiv[i]=this.sanitizer.bypassSecurityTrustHtml(data);
            this.errorPageTobeShown=false;  
              
          },
          error => {
            
            this.errorPageTobeShown=true;
          }
          );
      }else{
        this.http.get('content/pdf/' + sectionHtmlPath,
        { responseType: 'text' })
        .subscribe(res => {
          const json=JSON.parse(res);
          const fileURL = 'data:application/pdf;base64,'+String(json.data);
          //const newBlob = new Blob([json.data], { type: "application/pdf" });
          //const datalocalURL = window.URL.createObjectURL(newBlob);
        
          this.sectionContentDiv[i] = this.sanitizer.bypassSecurityTrustHtml(
            "<iframe  width=\"100%\" height=\"500\" src='" + fileURL + "' type='application/pdf' class='embed-responsive-item'>" +
            "Object " + fileURL + " failed" +
            "</iframe>");
  
          //this.dataUtils.openFile(String(json.data), "application/pdf");
          }
        );
      }

      this.fetchRelatedContent(i);

     
  }
 
  
  fetchRelatedContent(i:number):void{
    this.cbicContentMapService.fetchRelatedContent(this.selectedSectionId,this.selectedTab)
    .subscribe(res => {
      
        this.relatedTabs=res.body;
        this.relatedTabsAry[i] = res.body
        if(this.relatedTabs.length > 0){
          this.showRelatedContent=true;
        }
        else{
            this.showRelatedContent = false;
          }
      }
    ,()=>{
      this.showRelatedContent=false;
    });
    
    //[{"tabName":"Rules","count":2},{"tabName":"Regulations","count":4}];
  }
  setLastChapterIndex(firstChapter : any,lastChapter : any):void{
    this.LastChapter = lastChapter;
    this.firstChapter = firstChapter;
    this.currentChapter = firstChapter;
  }

  setCurrentChapter(chapterIndex : any):void{
    this.currentChapter =chapterIndex;
  }

  gotoNextSection():void{
    this.closeAllSection()
    if(this.currentSection < this.sectionList.length - 1){
      this.currentSection = this.currentSection + 1;
      //this.fetchData(this.sectionList[this.currentSection].contentFilePath,this.sectionList[this.currentSection].id,this.sectionList[this.currentSection].sectionName,this.currentSection,undefined);

      if(this.searchKeyWord){
        this.fetchData(this.sectionList[this.currentSection].contentFilePath,this.sectionList[this.currentSection].id,this.sectionList[this.currentSection].sectionName,this.currentSection,this.searchKeyWord,this.sectionList[this.currentSection].id);
      }else {
        this.fetchData(this.sectionList[this.currentSection].contentFilePath,this.sectionList[this.currentSection].id,this.sectionList[this.currentSection].sectionName,this.currentSection,undefined,this.sectionList[this.currentSection].id);
      }
    }
  }

  gotoPreviousSection():void{
    this.closeAllSection()
    if(this.currentSection > 0){
      
      this.currentSection -=1;
      //this.fetchData(this.sectionList[this.currentSection].contentFilePath,this.sectionList[this.currentSection].id,this.sectionList[this.currentSection].sectionName,this.currentSection,null);

      if(this.searchKeyWord){
        this.fetchData(this.sectionList[this.currentSection].contentFilePath,this.sectionList[this.currentSection].id,this.sectionList[this.currentSection].sectionName,this.currentSection,this.searchKeyWord,this.sectionList[this.currentSection].id);
      }else {
        
        this.fetchData(this.sectionList[this.currentSection].contentFilePath,this.sectionList[this.currentSection].id,this.sectionList[this.currentSection].sectionName,this.currentSection,null,this.sectionList[this.currentSection].id);
      }
    }
  }

  setCurrentSection(): void{
    this.currentSection = 0;
  }

  

  setCurrentSectionFromJ(index:number):void{
    if(index === -1){
      this.currentSection = 0;
    }else{
      this.currentSection = index;
    }
   
  }


  setSwitchDtls(switchDtls:{sectionView:any}):void{
    this.selectedSectionView = switchDtls.sectionView;
  }
  
  showContentList(relatedContentName : any, index: any):void{
    // alert(relatedContentName);
    // alert(this.selectedSectionId)
    let a;
    if(index === -1){
      a = this.selectedSectionId;
    } else {
      a = this.sectionList[index].contentId;
    }
    this.relatedContentType = relatedContentName;
    this.cbicContentMapService.fetchRelatedContentList(new RelatedContentRequest(relatedContentName, a))
    .subscribe(
      (res:HttpResponse<RelatedContentResponse>) => {
        if(res.body){
          this.relatedContentResponse = res.body;
          this.modalService.open(this.relatedListModal,{windowClass : "myCustomModalClass", centered : true});
        }
       
      }
    );

      
    }
    
  
  
  /* setCurrentSection(sectionIndex : any):void{
    this.currentSection = sectionIndex;
  } */
  
}
