import { HttpResponse } from '@angular/common/http';
import { Component,ElementRef,EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { faSearch} from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CbicSearchOutputResult } from 'app/search-page/search-output.model';
import { CbicSearchService } from 'app/search-page/service/cbic-search-service';
import { CbicFormSearchInput, CbicSearchInput1, ICbicSearchInput } from 'app/searchbar/searchbar.model';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'cbic-forms-content-search-bar',
  templateUrl: './forms-content-search-bar.component.html',
  styleUrls: ['./forms-content-search-bar.component.scss']
})
export class FormsContentSearchBarComponent implements OnInit {

  @Input()
  formsListFetched:any;
  selectedForm:any;
  searched:any;
  @Input()  formsTaxId:any;
  searchKeyword:any;
  faTimesCircle=faTimesCircle;
  faSearch=faSearch;
  @Output() formClearFired= new EventEmitter<string>();
  @Output() formSearchFired= new EventEmitter<string>();
  @Output() formSelected = new EventEmitter<{formId : string, valuefind : boolean}>();
  @ViewChild("formSearchKeyword") formKeyword? : ElementRef;

   formsSearchBarForm = this.fb.group({
    keyword : [],
    taxId : [],
    formNo : [],
    content : [],
  });

  searchBarFormAll = this.fb.group({
    keywordAll : "",
  });
  @ViewChild("ruleSearchKeyword") ruleKeyword? : ElementRef;
  @Input() taxName: any;
  @ViewChild('allSearch', { static: true }) allSearchTextModal!: ElementRef;
  allSearchMsg: any
  @ViewChild("allRuleSearchKeyword") allRuleSearchKeyword? : ElementRef;
  @Input() taxId:any;
  @Input() activeTab:any
  @Input() tabName:any
  storageLanguage:any

  constructor( private fb:FormBuilder,
    private service: CbicSearchService,
    private modalService : NgbModal,
    private router: Router,
    private sessionStorage: SessionStorageService) { }

  ngOnInit(): void {
    this.storageLanguage = this.sessionStorage.retrieve('locale')
    return;
    // do nothing.
    this.selectedForm = 0;
  }
  
  clear():void{
    this.searched=false;
    
    this.formsSearchBarForm = this.fb.group({
      keyword : "",
      formNo : ""
    });
    this.formClearFired.emit();
    return;
  }

  search():void{
    const cbicSearchInput = this.createFromForm();
    if(cbicSearchInput.keyword === undefined || cbicSearchInput.keyword!.length < 3){
      alert("Please enter valid keyword");
      this.formKeyword?.nativeElement.focus();
      return;
    }
    
    this.formSearchFired.emit(JSON.stringify(cbicSearchInput));
    this.searched=true;
    //this.router.navigate(['searchPage/search'],{queryParams : cbicSearchInput});
  }

  getFomrNo(form:any):string{
    let formNo : string;
    formNo =""
    if(form){
      for(let i=0;i<this.formsListFetched.length;i++){
        if(String(form) === String(this.formsListFetched[i].formNo)+'-'+String(this.formsListFetched[i].formName)){
          formNo = this.formsListFetched[i].id;
        }
      }
    }
    return formNo;
  }

  fireFormSelected(form:string):void{
    let selecteFormId = 0;
    let find =false;
    if(form !== ""){
      for(let i=0;i<this.formsListFetched.length;i++){
        if(form === String(this.formsListFetched[i].formNo)+'-'+String(this.formsListFetched[i].formName)){
          selecteFormId = this.formsListFetched[i].id;
          find = true
        }
      }
    }
      if(find){
        this.formSelected.emit({formId:String(selecteFormId),valuefind:find});
      }else{
        this.clear();
      }    
  }

  searchAll():void{
    
    const cbicSearchInput = this.createAllForm();
    if(cbicSearchInput.keyword === undefined || cbicSearchInput.keyword!.length < 3){
      alert("Please enter valid keyword");
      this.ruleKeyword?.nativeElement.focus();
      return;
    }

    cbicSearchInput.keyword = cbicSearchInput.keyword?.trim()

    this.service.pageNumber = 1;
      this.service.searchInSection(cbicSearchInput).subscribe(
        (res: HttpResponse<CbicSearchOutputResult>) => {
          if(res.body?.totalDocuments === 0){
            this.allSearchMsg = "No search result found."
            this.allRuleSearchKeyword?.nativeElement.focus();
            this.searchBarFormAll = this.fb.group({
              keywordAll : ""
            });
            return;
          } else{
            this.modalService.dismissAll()
            this.service.cbicSearchOutputResults = res.body ?? new CbicSearchOutputResult([], null);
            this.router.navigate(['searchPage/search'],{queryParams : cbicSearchInput});
            //const url = this.router.serializeUrl(this.router.createUrlTree(['searchPage/search'],{queryParams : cbicSearchInput}));

            //window.open(url, '_blank');
          }
         
        });

  }

  openAllSearch(): void{
    this.modalService.open(this.allSearchTextModal,{windowClass : "", centered : true});
  }

  protected createAllForm(): ICbicSearchInput {
    return {
      ...new CbicSearchInput1(),
      keyword: (this.searchBarFormAll.get(['keywordAll'])!.value === null || this.searchBarFormAll.get(['keywordAll'])!.value === undefined) ? null : this.searchBarFormAll.get(['keywordAll'])!.value.trim(),
      content: "FORMS",
      taxId: this.taxId,
      startIndex: 0,
      paginationFlag: true,
      rows: 10
    };
  }

  protected createFromForm(): CbicFormSearchInput {
    return {
      ...new CbicFormSearchInput(),
      keyword:(this.formsSearchBarForm.get(['keyword'])!.value === null || this.formsSearchBarForm.get(['keyword'])!.value === undefined) ? null : this.formsSearchBarForm.get(['keyword'])!.value.trim(),
      taxId: this.formsTaxId,
      formNo: this.getFomrNo(this.formsSearchBarForm.get(['formNo'])!.value),
      content: "FORMS",
      // startIndex: 0,
      // paginationFlag: true,
      // rows: 10
    };
  }
}
