<form>
    <!-- <span class="form-switch toggle-switch col-lg-5"> 
            <h6>
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" (click)="viewBySection()">
                <label class="form-check-label" for="flexSwitchCheckChecked" title="Click here to change the view (Section/Chapter)"><b>Section</b></label>
            </h6>
        </span> -->
    <div>
        <h4 style="display: flex;height: 47px;">
            <div class="col-md-9">

                <div class="secChNavigation">
                    <div class="input-group input-group-sm flex-nowrap" #scrollHere>

                        <!-- View By Dropdown-->
                        <!--<select class="form-select" #viewSelection (change)="viewBySection(viewSelection.value)">
                        <option value="Chapter">Chapter</option>
                        <option value="Section" *ngIf="selectedTab=='Acts'">Section</option>
                        <option value="Regulations" *ngIf="selectedTab=='Regulations'">Regulation</option>
                    </select>-->

                        <span class="col-md-3" style="font-size: 14px;margin-right:5px;" cbicTranslate="viewBy">View
                            By</span>
                        <span class="col-md-4" style="display: flex;">
                            <input type="radio" id="chapterView" name="sectionView" (change)="viewBySection('Chapter')"
                                [checked]="!sectionView">
                              <label style="font-size: 14px;" [ngClass]="{ 'active':!sectionView }"
                                cbicTranslate="chapter">Chapter</label>
                              <input type="radio" id="sectionView" name="sectionView"
                                (change)="selectedTab=='Acts' ? viewBySection('Section'): viewBySection(selectedTab)"
                                [checked]="sectionView">
                              <label *ngIf="selectedTab=='Acts'" style="font-size: 14px;"
                                [ngClass]="{ 'active':sectionView }" cbicTranslate="section">Section</label>
                              <label *ngIf="selectedTab!='Acts'" style="font-size: 14px;"
                                [ngClass]="{ 'active':sectionView }">{{tabName}}</label>
                        </span>

                    </div>
                    <!-- -->
                </div>

            </div>
            <div class="col-md-2" style="margin-left: 2%;">
                <div class="input-group input-group-sm " style="   flex-wrap: nowrap;">
                    <span *ngIf="!sectionView" class="input-group-text" (click)="goToPreviousChapter()"><a
                            [ngClass]="activeReadingNum === firstChapter ? 'disabled' : ''" [routerLink]=""
                            title="Previous Chapter"><i
                                [ngClass]="activeReadingNum === firstChapter ? 'fa fa-arrow-left disableIcon' : 'fa fa-arrow-left'"
                                aria-hidden="true"></i></a>
                    </span>
                    <span *ngIf="sectionView" class="input-group-text" (click)="gotoPreviousSection()"><a
                            [ngClass]="activeReadingNum === 0 ? 'disabled' : ''" [routerLink]=""
                            title="Previous Section"><i
                                [ngClass]="activeReadingNum === 0 ? 'fa fa-arrow-left disableIcon' : 'fa fa-arrow-left'"
                                aria-hidden="true"></i></a></span>

                    <span class="bg-transparent input-group-text"></span>
                    <span *ngIf="!sectionView" class="input-group-text" (click)="goToNextChapter()"><a
                            [ngClass]="activeReadingNum === totalChapter-1 ? 'disabled' : ''" [routerLink]=""
                            title="Next Chapter"><i
                                [ngClass]="activeReadingNum === totalChapter-1? 'fa fa-arrow-right disableIcon' : 'fa fa-arrow-right'"
                                aria-hidden="true"></i></a></span>

                    <span *ngIf="sectionView" class="input-group-text" (click)="gotoNextSection()"><a
                            [ngClass]="activeReadingNum ===totalSection-1 ? 'disabled' : ''" [routerLink]=""
                            title="Next Section"><i
                                [ngClass]="activeReadingNum === totalSection-1 ? 'fa fa-arrow-right disableIcon' : 'fa fa-arrow-right'"
                                aria-hidden="true"></i></a></span>
                </div>
            </div>
        </h4>
    </div>



    <!-- <button  type="button" class="btn float-end collapseChapterViewBtn"  role="button" (click)="expandViewArea()" data-toggle="tooltip" data-placement="top" title="Hide Chapter Navigation">
        <span class="input-group-text"><i class="fa fa-arrow-left" aria-hidden="true"></i></span>
    </button> -->
    <div>


    </div>

    <!-- <div class="input-group flex-nowrap">
        <input type="text" class="form-control" placeholder="Enter Keyword"
            aria-label="Username" aria-describedby="addon-wrapping">
        <button class="btn" type="button"><i class="fas fa-search"></i></button>
    </div> -->
    <!--<span class="input-group-text" style="width:13%"><a href=""><i class="fa fa-arrow-left" aria-hidden="true"></i></a></span>-->
    <div *ngIf="!sectionView" class="nav flex-column nav-pills me-3 wrap-content " id="offcanvas" role="tablist"
        aria-orientation="vertical" id="offcanvas" tabindex="-1" data-bs-keyboard="false" data-bs-backdrop="false">



        <button class="nav-link" id="v-pills-c1-tab" data-bs-toggle="pill" data-bs-target="#v-pills-c1" type="button"
            *ngFor="let chapter of chapterList; let i = index" role="tab" aria-controls="v-pills-c1"
            aria-selected="true"
            (click)="fetchSectionData(chapter.id, chapter.chapterNo, chapter.chapterName,chapter.chapterNameHi,chapter.chapterNoHi)"
            [ngClass]="{'active cbicScrollToSelected': i==activeReadingNum}">

            <!--  <p  class="name">{{chapter.chapterNo}}</p>
            <p  class="title">{{chapter.chapterName}}</p>
 -->

            <p *ngIf="language === 'en'" class="name">{{chapter.chapterNo}}</p>
            <p *ngIf="language === 'hi'" class="name">{{chapter.chapterNoHi ? chapter.chapterNoHi : chapter.chapterNo}}</p>
            

            <p *ngIf="language === 'en'" class="title">{{chapter.chapterName}}</p>
            <p *ngIf="language === 'hi'" class="title">{{chapter.chapterNameHi ? chapter.chapterNameHi : chapter.chapterName}}</p>
            
        </button>



    </div>


    <div *ngIf="sectionView" class="nav flex-column nav-pills me-3 wrap-content " id="offcanvas" role="tablist"
        aria-orientation="vertical" tabindex="-1" data-bs-keyboard="false" data-bs-backdrop="false">
        <button class="nav-link" id="v-pills-c2-tab" data-bs-toggle="pill" data-bs-target="#v-pills-c2" type="button"
            *ngFor="let section of viewByAllSectionList; let i = index" role="tab" aria-controls="v-pills-c2"
            aria-selected="true" (click)="fetchSections(section.id, i)"
            [ngClass]="{'active cbicScrollToSelected': i==activeReadingNum}">

            <p class="name" *ngIf="selectedTab=='Acts' && language === 'en'">{{section.sectionNo}}</p>
            <p class="name" *ngIf="selectedTab=='Acts' && language === 'hi'">
                {{section.sectionNoHi?section.sectionNoHi:section.sectionNo}}
            </p>
            
            <p class="title" *ngIf="selectedTab=='Acts' && language === 'en'">{{section.sectionName}}</p>
            <p class="title" *ngIf="selectedTab=='Acts' && language === 'hi'">
                {{section.sectionNameHi?section.sectionNameHi:section.sectionName}}
            </p>
           

            <!--<p class="name" *ngIf="selectedTab=='Acts'">{{section.sectionNo}}</p>
            <p class="title" *ngIf="selectedTab=='Acts'">{{section.sectionName}}</p> 
            <p  class="name" *ngIf="selectedTab=='Regulations'">{{section.regulationNo}}</p>
            <p  class="title" *ngIf="selectedTab=='Regulations'">{{section.regulationName}}</p>
            <p  class="name" *ngIf="selectedTab=='Rules'">{{section.sectionNo}}</p>
            <p  class="title" *ngIf="selectedTab=='Rules'">{{section.sectionName}}</p>-->

            <p class="name" *ngIf="selectedTab=='Rules' && language === 'en' ">{{section.sectionNo}}</p>
            <p class="name" *ngIf="selectedTab=='Rules' && language === 'hi'">
                {{section.sectionNoHi?section.sectionNoHi:section.sectionNo}}</p>

            <p class="title" *ngIf="selectedTab=='Rules' && language === 'en' ">{{section.sectionName}}</p>
            <p class="title" *ngIf="selectedTab=='Rules' && language === 'hi'">
                {{section.sectionNameHi?section.sectionNameHi:section.sectionName}}</p>


            <p class="name" *ngIf="selectedTab=='Regulations' && language === 'en'">{{section.regulationNo}}</p>
            <p class="name" *ngIf="selectedTab=='Regulations' && language === 'hi'">
                {{section.regulationNoHi?section.regulationNoHi:section.regulationNo}}
            </p>
            
            <p class="title" *ngIf="selectedTab=='Regulations' && language === 'en'">{{section.regulationName}}</p>
            <p class="title" *ngIf="selectedTab=='Regulations' && language === 'hi'">
                {{section.regulationNameHi?section.regulationNameHi:section.regulationName}}
             </p>
           
        </button>

    </div>
</form>