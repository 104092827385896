import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { Search } from 'app/core/request/request.model';
import { ICbicRuleChapterMst, getCbicRuleChapterMstIdentifier } from '../cbic-rule-chapter-mst.model';

export type EntityResponseType = HttpResponse<ICbicRuleChapterMst>;
export type EntityArrayResponseType = HttpResponse<ICbicRuleChapterMst[]>;

@Injectable({ providedIn: 'root' })
export class CbicRuleChapterMstService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/cbic-rule-chapter-msts');
  public resourceSearchUrl = this.applicationConfigService.getEndpointFor('api/_search/cbic-rule-chapter-msts');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  findChapterByRuleId(ruleId: number) : Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicRuleChapterMst[]>(`${this.resourceUrl}/findChapterByRuleId/${ruleId}`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  getChapterNameForRule(id: number) : Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicRuleChapterMst[]>(`${this.resourceUrl}/getChapterNameForRule/${id}`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }
  create(cbicRuleChapterMst: ICbicRuleChapterMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicRuleChapterMst);
    return this.http
      .post<ICbicRuleChapterMst>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(cbicRuleChapterMst: ICbicRuleChapterMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicRuleChapterMst);
    return this.http
      .put<ICbicRuleChapterMst>(`${this.resourceUrl}/${getCbicRuleChapterMstIdentifier(cbicRuleChapterMst) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(cbicRuleChapterMst: ICbicRuleChapterMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicRuleChapterMst);
    return this.http
      .patch<ICbicRuleChapterMst>(`${this.resourceUrl}/${getCbicRuleChapterMstIdentifier(cbicRuleChapterMst) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICbicRuleChapterMst>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicRuleChapterMst[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  search(req: Search): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicRuleChapterMst[]>(this.resourceSearchUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  addCbicRuleChapterMstToCollectionIfMissing(
    cbicRuleChapterMstCollection: ICbicRuleChapterMst[],
    ...cbicRuleChapterMstsToCheck: (ICbicRuleChapterMst | null | undefined)[]
  ): ICbicRuleChapterMst[] {
    const cbicRuleChapterMsts: ICbicRuleChapterMst[] = cbicRuleChapterMstsToCheck.filter(isPresent);
    if (cbicRuleChapterMsts.length > 0) {
      const cbicRuleChapterMstCollectionIdentifiers = cbicRuleChapterMstCollection.map(
        cbicRuleChapterMstItem => getCbicRuleChapterMstIdentifier(cbicRuleChapterMstItem)!
      );
      const cbicRuleChapterMstsToAdd = cbicRuleChapterMsts.filter(cbicRuleChapterMstItem => {
        const cbicRuleChapterMstIdentifier = getCbicRuleChapterMstIdentifier(cbicRuleChapterMstItem);
        if (cbicRuleChapterMstIdentifier == null || cbicRuleChapterMstCollectionIdentifiers.includes(cbicRuleChapterMstIdentifier)) {
          return false;
        }
        cbicRuleChapterMstCollectionIdentifiers.push(cbicRuleChapterMstIdentifier);
        return true;
      });
      return [...cbicRuleChapterMstsToAdd, ...cbicRuleChapterMstCollection];
    }
    return cbicRuleChapterMstCollection;
  }

  protected convertDateFromClient(cbicRuleChapterMst: ICbicRuleChapterMst): ICbicRuleChapterMst {
    return Object.assign({}, cbicRuleChapterMst, {
      createdDt: cbicRuleChapterMst.createdDt?.isValid() ? cbicRuleChapterMst.createdDt.toJSON() : undefined,
      updatedDt: cbicRuleChapterMst.updatedDt?.isValid() ? cbicRuleChapterMst.updatedDt.toJSON() : undefined,
      issueDt: cbicRuleChapterMst.issueDt?.isValid() ? cbicRuleChapterMst.issueDt.toJSON() : undefined,
      amendDt: cbicRuleChapterMst.amendDt?.isValid() ? cbicRuleChapterMst.amendDt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDt = res.body.createdDt ? dayjs(res.body.createdDt) : undefined;
      res.body.updatedDt = res.body.updatedDt ? dayjs(res.body.updatedDt) : undefined;
      res.body.issueDt = res.body.issueDt ? dayjs(res.body.issueDt) : undefined;
      res.body.amendDt = res.body.amendDt ? dayjs(res.body.amendDt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((cbicRuleChapterMst: ICbicRuleChapterMst) => {
        cbicRuleChapterMst.createdDt = cbicRuleChapterMst.createdDt ? dayjs(cbicRuleChapterMst.createdDt) : undefined;
        cbicRuleChapterMst.updatedDt = cbicRuleChapterMst.updatedDt ? dayjs(cbicRuleChapterMst.updatedDt) : undefined;
        cbicRuleChapterMst.issueDt = cbicRuleChapterMst.issueDt ? dayjs(cbicRuleChapterMst.issueDt) : undefined;
        cbicRuleChapterMst.amendDt = cbicRuleChapterMst.amendDt ? dayjs(cbicRuleChapterMst.amendDt) : undefined;
      });
    }
    return res;
  }
}
