import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
// import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from 'app/layouts/profiles/profile.service';
import { CbicSearchOutputResult } from './search-output.model';
import { CbicSearchInput } from 'app/searchbar/searchbar.model';
import { CbicSearchService } from './service/cbic-search-service';
import { HttpResponse } from '@angular/common/http';
import { SearchResultsComponent } from './search-results/search-results.component';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'cbic-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss']
})
export class SearchPageComponent implements OnInit {
  inProduction?: boolean;
  openAPIEnabled?: boolean;
  cbicSearchOutputResults: CbicSearchOutputResult = new CbicSearchOutputResult([], null);
  cbicSearchInt?: CbicSearchInput;
  cbicSearchInput !: CbicSearchInput;
  startValue !: string;
  keyWord !:  string;
  endValue !: string;
  pageRecordValue !: string;
  showRecord: number[] = [10, 25, 50, 100];
  showRecordSelected = 10;
  @ViewChild('showRecordValue', { static: true }) recordSelection!: ElementRef;
  @ViewChild(SearchResultsComponent)
  searchResultChild!: SearchResultsComponent;
  backflag = "N";
  storageLanguage: any
  tarrifCount:any

  constructor(
    private router: Router,
    private profileService: ProfileService,
    protected activatedRoute: ActivatedRoute,
    protected service: CbicSearchService,
    private sessionStorage: SessionStorageService
  ) { }


  ngOnInit(): void {

    this.storageLanguage = this.sessionStorage.retrieve('locale')

    this.profileService.getProfileInfo().subscribe(profileInfo => {
      this.inProduction = profileInfo.inProduction;
      this.openAPIEnabled = profileInfo.openAPIEnabled;
    });

      if(this.service.backToSearchResult === 'N'){
      this.cbicSearchInt = this.service.cbicSearchInput;
      this.cbicSearchOutputResults = this.service.cbicSearchOutputResults;
      this.keyWord = String(this.service.cbicSearchInput.keyword);
      this.recordSelection.nativeElement.value= 10;
      this.cbicSearchInput = this.service.cbicSearchInput;
      this.loadPageRecords();
    }else{
      this.service.pageNumber = 1;
      this.service.rows = 10;
      this.service.cbicSearchInput.rows = 10;
      this.cbicSearchInt = this.service.cbicSearchInput;
      this.loadCbicOutputs(this.service.cbicSearchInput);
      this.cbicSearchInput = this.service.cbicSearchInput;
      this.recordSelection.nativeElement.value= 10;
      this.keyWord = String(this.service.cbicSearchInput.keyword);
      this.service.backToSearchResult = 'N';
      this.backflag = 'Y';
      // this.searchResultChild.onBackToSearchPage();
     
    }
    
  }

  loadPageRecords(): void {
   this.startValue = String((this.service.pageNumber - 1) * this.service.rows + 1);
    this.endValue = String((this.service.pageNumber * this.service.rows) > Number(this.cbicSearchOutputResults.totalDocuments) ? this.cbicSearchOutputResults.totalDocuments : this.service.pageNumber * this.service.rows) ;
    this.pageRecordValue =Number(this.cbicSearchOutputResults.totalDocuments) > 0 ? this.startValue + "-" + this.endValue : String(0);
  }

  
  loadCbicOutputs(cbicSearchInt: CbicSearchInput): void {
       this.service.searchInSection(cbicSearchInt).subscribe(
        (res: HttpResponse<CbicSearchOutputResult>) => {
          this.cbicSearchOutputResults = res.body ?? new CbicSearchOutputResult([], null);
          this.loadPageRecords();
        });

  }

  onResultChange(data: boolean): void {
    if (data) {
      this.loadPageRecords();
    }
  }

  showRecordSearch(recordValue : any): void{
   
    this.service.searchInSectionWithRecord(recordValue).subscribe(
      (res: HttpResponse<CbicSearchOutputResult>) => {
        this.cbicSearchOutputResults = res.body ?? new CbicSearchOutputResult([], null);
      });
      this.loadPageRecords();
   

  }

  onRefineParamsChange(data:{refineResult:boolean,cbicOutputResult:CbicSearchOutputResult}):void{
    if(data.refineResult){
      this.cbicSearchOutputResults = data.cbicOutputResult;
      this.loadPageRecords();
    }
  }

  onSearchChange(data:{searchResultChange:boolean}):void{
    if(data.searchResultChange){
      this.cbicSearchInt = this.service.cbicSearchInput;
      this.cbicSearchOutputResults = this.service.cbicSearchOutputResults;
      this.keyWord = String(this.service.cbicSearchInput.keyword);
      this.recordSelection.nativeElement.value= 10;
      this.cbicSearchInput = this.service.cbicSearchInput;
      this.loadPageRecords();
    }
  }

}
