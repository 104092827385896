import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CbicCircularMstService } from 'app/entities/cbic-circular-mst/service/cbic-circular-mst.service';
import { CbicCircularMst, ICbicCircularMst } from 'app/entities/cbic-circular-mst/cbic-circular-mst.model';
import { CbicNotificationMstService } from 'app/entities/cbic-notification-mst/service/cbic-notification-mst.service';
import { CbicInstructionMstService } from 'app/entities/cbic-instruction-mst/service/cbic-instruction-mst.service';
import { CbicOrderMstService } from 'app/entities/cbic-order-mst/service/cbic-order-mst.service';
import { CbicNotificationMst } from 'app/entities/cbic-notification-mst/cbic-notification-mst.model';
import { CbicInstructionMst } from 'app/entities/cbic-instruction-mst/cbic-instruction-mst.model';
import { CbicOrderMst } from 'app/entities/cbic-order-mst/cbic-order-mst.model';
import { CbicFormMstService } from 'app/entities/cbic-form-mst/service/cbic-form-mst.service';
import { CbicFormMst, FormResponse } from 'app/entities/cbic-form-mst/cbic-form-mst.model';
import { CbicContentHistoryMapDtlsService } from 'app/entities/cbic-content-history-map-dtls/service/cbic-content-history-map-dtls.service';
import { CbicContentHistoryMapDtls } from 'app/entities/cbic-content-history-map-dtls/cbic-content-history-map-dtls.model';
import { CbicAttachmentDtlsService } from 'app/entities/cbic-attachment-dtls/service/cbic-attachment-dtls.service';
import { CbicAttachmentDtls } from 'app/entities/cbic-attachment-dtls/cbic-attachment-dtls.model';
import { CbicAlliedActDtlsService } from 'app/entities/cbic-allied-act-dtls/service/cbic-allied-act-dtls.service';
import { CbicAlliedActDtls } from 'app/entities/cbic-allied-act-dtls/cbic-allied-act-dtls.model';


@Injectable({ providedIn: 'root' })
export class ContentPageRoutingViewPdfResolveService implements Resolve<string> {

  filePath: any;

  constructor(protected service: CbicCircularMstService, 
              protected router: Router,
              protected notiService: CbicNotificationMstService,
              protected instService: CbicInstructionMstService,
              protected orderService: CbicOrderMstService,
              protected formService: CbicFormMstService,
              protected historyService: CbicContentHistoryMapDtlsService,
              protected attachmentServie: CbicAttachmentDtlsService,
              protected cbicAlliedActDtlsService:CbicAlliedActDtlsService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<string> | Observable<never> {
    const id = route.params['id'];
    const type = route.params['type'];
    const selectedTab = route.params['selectedTab'];
  
    if (selectedTab === 'Allied Acts') {
      return this.cbicAlliedActDtlsService.find(id).pipe(
        mergeMap((cbicAlliedActDtls: HttpResponse<CbicAlliedActDtls>) => {
         
          if (cbicAlliedActDtls.body) {
            if(type === 'ENG'){
              this.filePath = cbicAlliedActDtls.body.doc_file_path
            } 
            
            if(type === 'HINDI'){
              this.filePath = cbicAlliedActDtls.body.doc_file_path_hi
            }
            return of(String(this.filePath));
          } else {
            this.router.navigate(['404']);
            return EMPTY;
          }
        })
      );
    }

    if (selectedTab === 'Circulars') {
      return this.service.find(id).pipe(
        mergeMap((cbicCircularMst: HttpResponse<CbicCircularMst>) => {
          if (cbicCircularMst.body) {
            if(type === 'ENG'){
              this.filePath = cbicCircularMst.body.docFilePath
            } 
            
            if(type === 'HINDI'){
              this.filePath = cbicCircularMst.body.docFilePathHi
            }
            return of(String(this.filePath));
          } else {
            this.router.navigate(['404']);
            return EMPTY;
          }
        })
      );
    }

    if (selectedTab === 'Notifications') {
      return this.notiService.find(id).pipe(
        mergeMap((cbicNotificationMst: HttpResponse<CbicNotificationMst>) => {
          if (cbicNotificationMst.body) {
            if(type === 'ENG'){
              this.filePath = cbicNotificationMst.body.docFilePath
            } 
            
            if(type === 'HINDI'){
              this.filePath = cbicNotificationMst.body.docFilePathHi
            }
            return of(String(this.filePath));
          } else {
            this.router.navigate(['404']);
            return EMPTY;
          }
        })
      );
    }

    if (selectedTab === 'Instructions') {
      return this.instService.find(id).pipe(
        mergeMap((cbicInstructionMst: HttpResponse<CbicInstructionMst>) => {
          if (cbicInstructionMst.body) {
            if(type === 'ENG'){
              this.filePath = cbicInstructionMst.body.docFilePath
            } 
            
            if(type === 'HINDI'){
              this.filePath = cbicInstructionMst.body.docFilePathHi
            }
            return of(String(this.filePath));
          } else {
            this.router.navigate(['404']);
            return EMPTY;
          }
        })
      );
    }

    if (selectedTab === 'Orders') {
      return this.orderService.find(id).pipe(
        mergeMap((cbicOrderMst: HttpResponse<CbicOrderMst>) => {
          if (cbicOrderMst.body) {
            if(type === 'ENG'){
              this.filePath = cbicOrderMst.body.docFilePath
            } 
            
            if(type === 'HINDI'){
              this.filePath = cbicOrderMst.body.docFilePathHi
            }
            return of(String(this.filePath));
          } else {
            this.router.navigate(['404']);
            return EMPTY;
          }
        })
      );
    }

    /* if (selectedTab === 'Forms') {
      return this.formService.find(id).pipe(
        mergeMap((cbicFormMst: HttpResponse<CbicFormMst>) => {
          if (cbicFormMst.body) {
            if(type === 'ENG'){
              this.filePath = cbicFormMst.body.contentFilePath
            } 
            return of(String(this.filePath));
          } else {
            this.router.navigate(['404']);
            return EMPTY;
          }
        })
      );
    } */

    if (selectedTab === 'History') {
      return this.historyService.find(id).pipe(
        mergeMap((historyMap: HttpResponse<CbicContentHistoryMapDtls>) => {
          if (historyMap.body) {
            if(type === 'ENG'){
              this.filePath = historyMap.body.docFilePathAOD
            } 
            return of(String(this.filePath));
          } else {
            this.router.navigate(['404']);
            return EMPTY;
          }
        })
      );
    }

    if (selectedTab === 'ATT') {
      return this.attachmentServie.find(id).pipe(
        mergeMap((historyMap: HttpResponse<CbicAttachmentDtls>) => {
          if (historyMap.body) {
            if(type === 'ENG'){
              this.filePath = historyMap.body.docFilePath
            } 
            return of(String(this.filePath));
          } else {
            this.router.navigate(['404']);
            return EMPTY;
          }
        })
      );
    }

    return of("");
  }
}
