import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthServerProvider } from 'app/core/auth/auth-jwt.service';

@Injectable({ providedIn: 'root' })
export class HomeService {
	constructor(private authServerProvider: AuthServerProvider) { }

	homePageToken(): Observable<void> {
		return this.authServerProvider.loginHomePageToken();
	}

}