import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from 'app/layouts/profiles/profile.service';
import { CbicActSectionMstService } from '../../entities/cbic-act-section-mst/service/cbic-act-section-mst.service';
import { ContentPageComponent } from '../content-page.component';
import { ContenPageService } from '../service/content-page.service';
import { SessionStorageService } from 'ngx-webstorage';
@Component({
  selector: 'cbic-section-chapter-navigation',
  templateUrl: './section-chapter-navigation.component.html',
  styleUrls: ['./section-chapter-navigation.component.scss']
})
export class SectionChapterNavigationComponent implements OnInit {

  inProduction?: boolean;
  openAPIEnabled?: boolean;
  reading:any;
  activeReading:any;
  contentPageComponent?: ContentPageComponent;
  sectionView=false;
  size:any;
  @Input() chapterList:any=[];
  @Input() sectionList:any=[];
  @Input()  selectedTab:any;
  @Input() viewByAllSectionList:any=[];
  @Output() someEvent = new EventEmitter<string>();
  @Output() expandReadArea = new EventEmitter<string>();
  @Output() selectChapterEvent = new EventEmitter<{selectChapterNo:any, selectChapterName:any , selectChapterNoHi:any, selectChapterNameHi:any}>();
  @Output() selectSectionEvent = new EventEmitter<{selectedTab:any,selectSectionId: any, selectSectionNo:any, selectSectionName:any,selectSectionNoHi:any, selectSectionNameHi:any, selectChapterId:any}>();
  @Output() switchEvent = new EventEmitter<{selectSectionView:any, selection:string}>();
  @Output() gotoPreviousChapter = new EventEmitter<string>();
  @Output() gotoNextChapter = new EventEmitter<string>();
  @Input() totalChapter:any;
  @Input() totalSection:any;
  // @Output() viewSelected = new EventEmitter<string>();
  firstChapter = 0;
  LastChapter = this.chapterList.length-1;
  currentChapter = "";
  @Input()  activeReadingNum:any;
  selectedChapterNo!:any;
  selectedChapterName!:any;
  selectedChapterNoHi!:any;
  selectedChapterNameHi!:any;
  @Output() goToNxtSection = new EventEmitter<string>();
  @Output() goToPrvsSection = new EventEmitter<string>();
  lastSec = this.viewByAllSectionList.length-1; 
  @Input() tabName:any;
  language: any


  constructor(
    private router: Router,
    private profileService: ProfileService,
    protected cbicActSectionMstService: CbicActSectionMstService,
    private contentPageService:ContenPageService,
    private scrollHere:ElementRef,
    private elem: ElementRef,
    private sessionStorage: SessionStorageService
  ) { }

  ngOnInit(): void {
   // this.scrollHere.nativeElement.scrollIntoView({ behavior: 'smooth' });
   this.language = this.sessionStorage.retrieve('locale')
    this.contentPageService.changeViewBy.subscribe((data)=>{
      if(this.selectedTab===(data)||(data==='Section'&&this.selectedTab==='Acts')){
        this.viewBySection(data);
       
      }
    });
    
    this.profileService.getProfileInfo().subscribe(profileInfo => {
      this.inProduction = profileInfo.inProduction;
      this.openAPIEnabled = profileInfo.openAPIEnabled;
    });
    if(!this.reading){

      
        this.reading=[];
        this.reading.push(true);
        
      
    }
    this.sectionView=true;
  }
  fetchSectionData(chapterId:string, chapterNo:any, chapterName:any,chapterNameHi:any,chapterNoHi:any):void{
    this.someEvent.emit(chapterId);
    this.selectChapterEvent.emit({selectChapterNo: chapterNo,selectChapterName:chapterName,selectChapterNameHi:chapterNameHi,selectChapterNoHi:chapterNoHi});
    this.size = window.innerWidth;
    if (this.size < 600) {
      //alert("in Mobile View");
      window.scrollTo(0, 900);
    }
  }
  expandViewArea():void{
    this.expandReadArea.emit();
  }

  changeActiveChapter(j:number):void{
    this.reading=[];
    this.reading[j]=true;
    this.reading[j-1]=false;
  }
  setCurrentChapter(chapterIndex : any):void{
    this.currentChapter =chapterIndex;
  }
  viewBySection(selection: string): void{
    if(selection!=='Chapter'){
      this.sectionView = true;
    }else{
      this.sectionView = false;
    }
    
    this.switchEvent.emit({selectSectionView: this.sectionView, selection});
    // this.viewSelected.emit(selection);
  }
  
  
  fetchSections(id:string, i:any):void{
    if(this.selectedTab==='Acts'){
      this.selectSectionEvent.emit({selectedTab:'Acts',selectSectionId: id, selectSectionNo: this.viewByAllSectionList[i].sectionNo, selectSectionName: this.viewByAllSectionList[i].sectionName,selectSectionNoHi: this.viewByAllSectionList[i].sectionNoHi, selectSectionNameHi: this.viewByAllSectionList[i].sectionNameHi, selectChapterId: this.viewByAllSectionList[i].chapterId});
    }if(this.selectedTab==='Regulations'){
      this.selectSectionEvent.emit({selectedTab:'Regulations',selectSectionId: id, selectSectionNo: this.viewByAllSectionList[i].regulationNo, selectSectionName: this.viewByAllSectionList[i].regulationName,selectSectionNoHi: this.viewByAllSectionList[i].regulationNoHi, selectSectionNameHi: this.viewByAllSectionList[i].regulationNameHi, selectChapterId: this.viewByAllSectionList[i].chapterName});
    }
    if(this.selectedTab==='Rules'){
      this.selectSectionEvent.emit({selectedTab:'Rules',selectSectionId: id, selectSectionNo: this.viewByAllSectionList[i].sectionNo, selectSectionName: this.viewByAllSectionList[i].sectionName,selectSectionNoHi: this.viewByAllSectionList[i].sectionNoHi, selectSectionNameHi: this.viewByAllSectionList[i].sectionNameHi, selectChapterId: this.viewByAllSectionList[i].chapterName});
    }

    this.size = window.innerWidth;
    if (this.size < 600) {
      //alert("in Mobile View");
      window.scrollTo(0, 900);
    }

  }

  goToPreviousChapter():void{
    const el = this.elem.nativeElement.querySelectorAll('.cbicScrollToSelected');
    el[0].scrollIntoViewIfNeeded({ behavior: 'smooth' });
    this.gotoPreviousChapter.emit();
  }
  goToNextChapter():void{
    const el = this.elem.nativeElement.querySelectorAll('.cbicScrollToSelected');
    el[0].scrollIntoViewIfNeeded({ behavior: 'smooth'});
    this.gotoNextChapter.emit();
  }
  
  setLastChapterIndex(firstChapter : any,lastChapter : any):void{
    this.LastChapter = lastChapter;
    this.firstChapter = firstChapter;
    this.currentChapter = firstChapter;
  }

  setCurrentCh(chapterIndex : any):void{
    this.currentChapter =chapterIndex;
  } 
  
  setChapterDtls(chapterDtls:{chapterNo:any, chapterName:any,chapterNoHi:any, chapterNameHi:any}):void{
    this.selectedChapterNo = chapterDtls.chapterNo;
    this.selectedChapterName = chapterDtls.chapterName;
    this.selectedChapterNoHi = chapterDtls.chapterNoHi;
    this.selectedChapterNameHi = chapterDtls.chapterNameHi;
 
  }

  gotoNextSection():void{
    const el = this.elem.nativeElement.querySelectorAll('.cbicScrollToSelected');
    el[0].scrollIntoViewIfNeeded({ behavior: 'smooth' });
    this.goToNxtSection.emit();
  }

  gotoPreviousSection(): void{
    const el = this.elem.nativeElement.querySelectorAll('.cbicScrollToSelected');
    el[0].scrollIntoViewIfNeeded({ behavior: 'smooth'});
    this.goToPrvsSection.emit();
  }
  setSectionListLength(length:any):void{
    this.lastSec = Number(length);
  }

  

  changeActiveSection(j:number):void{
    this.reading=[];
    alert(j);
    this.reading[j]=true;
    this.reading[j-1]=false;
    this.reading[j+1]=false;
  }


}
