import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { Search } from 'app/core/request/request.model';
import { ICbicNotificationMst, getCbicNotificationMstIdentifier } from '../cbic-notification-mst.model';
import { RuleMstResponse } from 'app/entities/cbic-rule-mst/cbic-rule-mst.model';
import { ICbicUpdateMst } from '../cbic-update-mst.model';

export type EntityResponseType = HttpResponse<ICbicNotificationMst>;
export type EntityArrayResponseType = HttpResponse<ICbicNotificationMst[]>;

@Injectable({ providedIn: 'root' })
export class CbicNotificationMstService {
  
  
  rows = 10;
  
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/cbic-notification-msts');
  public resourceSearchUrl = this.applicationConfigService.getEndpointFor('api/_search/cbic-notification-msts');
  

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  fetchNotificationByParentId(parentId:number, taxId:string):Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicNotificationMst[]>(`${this.resourceUrl}/fetchNotificationByParentId/${parentId}/${taxId}`, {  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }
  fetchLatestUpdatesbyTaxId(taxId:number):Observable<EntityArrayResponseType>{
    return this.http
    .get<ICbicUpdateMst[]>(`${this.resourceUrl}/fetchUpdatesByTaxId/${taxId}`, {  observe: 'response' })
    .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  searchNotificationRecords(recordValue: any): Observable<EntityArrayResponseType> {
    this.rows=recordValue;
    throw new Error('Method not implemented.');
  }
  
  downloadNotification(id: number, language: string):Observable<RuleMstResponse>{
    return this.http.get<RuleMstResponse>(`${this.resourceUrl}/download/${id}/${language}`);
  }

  fetchNotificationHistory(contentId:number):Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicNotificationMst[]>(`${this.resourceUrl}/fetchNotificationHistory/${contentId}`, {  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  create(cbicNotificationMst: ICbicNotificationMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicNotificationMst);
    return this.http
      .post<ICbicNotificationMst>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(cbicNotificationMst: ICbicNotificationMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicNotificationMst);
    return this.http
      .put<ICbicNotificationMst>(`${this.resourceUrl}/${getCbicNotificationMstIdentifier(cbicNotificationMst) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(cbicNotificationMst: ICbicNotificationMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicNotificationMst);
    return this.http
      .patch<ICbicNotificationMst>(`${this.resourceUrl}/${getCbicNotificationMstIdentifier(cbicNotificationMst) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICbicNotificationMst>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any):  Observable<HttpResponse<{}>> {
    const options = createRequestOption(req);
    return this.http
      .get(this.resourceUrl, { params: options, observe: 'response' });
  }
  fetchNotificationByCategory(taxId:number,notificationCategory: string): Observable<EntityArrayResponseType> {
    const options = createRequestOption(notificationCategory);
    return this.http
      .get<ICbicNotificationMst[]>(`${this.resourceUrl}/fetchNotificationByCategory/${taxId}/${notificationCategory}`, {  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  fetchNotificationByYear(notiYear: any) :Observable<EntityArrayResponseType> {
    const options = createRequestOption(notiYear);
    return this.http
      .get<ICbicNotificationMst[]>(`${this.resourceUrl}/fetchNotificationByYear`, { params: options,  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  fetchNotificationByYearCategory(req?:any):Observable<EntityArrayResponseType>{
    const options = createRequestOption(req);
    return this.http
      .get<ICbicNotificationMst[]>(`${this.resourceUrl}/fetchNotificationByYearAndCategory`, { params: options,  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }
  fetchCategory(taxId: number): Observable<EntityArrayResponseType>  {
    return this.http
      .get<ICbicNotificationMst[]>(`${this.resourceUrl}/fetchCategory/${taxId}`, {observe: 'response' })
      //.pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  search(req: Search): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicNotificationMst[]>(this.resourceSearchUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  addCbicNotificationMstToCollectionIfMissing(
    cbicNotificationMstCollection: ICbicNotificationMst[],
    ...cbicNotificationMstsToCheck: (ICbicNotificationMst | null | undefined)[]
  ): ICbicNotificationMst[] {
    const cbicNotificationMsts: ICbicNotificationMst[] = cbicNotificationMstsToCheck.filter(isPresent);
    if (cbicNotificationMsts.length > 0) {
      const cbicNotificationMstCollectionIdentifiers = cbicNotificationMstCollection.map(
        cbicNotificationMstItem => getCbicNotificationMstIdentifier(cbicNotificationMstItem)!
      );
      const cbicNotificationMstsToAdd = cbicNotificationMsts.filter(cbicNotificationMstItem => {
        const cbicNotificationMstIdentifier = getCbicNotificationMstIdentifier(cbicNotificationMstItem);
        if (cbicNotificationMstIdentifier == null || cbicNotificationMstCollectionIdentifiers.includes(cbicNotificationMstIdentifier)) {
          return false;
        }
        cbicNotificationMstCollectionIdentifiers.push(cbicNotificationMstIdentifier);
        return true;
      });
      return [...cbicNotificationMstsToAdd, ...cbicNotificationMstCollection];
    }
    return cbicNotificationMstCollection;
  }

  protected convertDateFromClient(cbicNotificationMst: ICbicNotificationMst): ICbicNotificationMst {
    return Object.assign({}, cbicNotificationMst, {
      createdDt: cbicNotificationMst.createdDt?.isValid() ? cbicNotificationMst.createdDt.toJSON() : undefined,
      updatedDt: cbicNotificationMst.updatedDt?.isValid() ? cbicNotificationMst.updatedDt.toJSON() : undefined,
      notificationDt: cbicNotificationMst.notificationDt?.isValid() ? cbicNotificationMst.notificationDt.toJSON() : undefined,
      issueDt: cbicNotificationMst.issueDt?.isValid() ? cbicNotificationMst.issueDt.toJSON() : undefined,
      amendDt: cbicNotificationMst.amendDt?.isValid() ? cbicNotificationMst.amendDt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDt = res.body.createdDt ? dayjs(res.body.createdDt) : undefined;
      res.body.updatedDt = res.body.updatedDt ? dayjs(res.body.updatedDt) : undefined;
      res.body.notificationDt = res.body.notificationDt ? dayjs(res.body.notificationDt) : undefined;
      res.body.issueDt = res.body.issueDt ? dayjs(res.body.issueDt) : undefined;
      res.body.amendDt = res.body.amendDt ? dayjs(res.body.amendDt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((cbicNotificationMst: ICbicNotificationMst) => {
        cbicNotificationMst.createdDt = cbicNotificationMst.createdDt ? dayjs(cbicNotificationMst.createdDt) : undefined;
        cbicNotificationMst.updatedDt = cbicNotificationMst.updatedDt ? dayjs(cbicNotificationMst.updatedDt) : undefined;
        cbicNotificationMst.notificationDt = cbicNotificationMst.notificationDt ? dayjs(cbicNotificationMst.notificationDt) : undefined;
        cbicNotificationMst.issueDt = cbicNotificationMst.issueDt ? dayjs(cbicNotificationMst.issueDt) : undefined;
        cbicNotificationMst.amendDt = cbicNotificationMst.amendDt ? dayjs(cbicNotificationMst.amendDt) : undefined;
      });
    }
    return res;
  }
}
