import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CbicRegulationMst, ICbicRegulationMst } from 'app/entities/cbic-regulation-mst/cbic-regulation-mst.model';
import { CbicRegulationMstService } from 'app/entities/cbic-regulation-mst/service/cbic-regulation-mst.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { TranslateService } from '@ngx-translate/core';


@Injectable({ providedIn: 'root' })
export class ContentPageRoutingRegulationResolveService implements Resolve<ICbicRegulationMst> {
  constructor(protected service: CbicRegulationMstService, protected router: Router,
    private sessionStorage: SessionStorageService,
    private localStorage: LocalStorageService,
    private translateService: TranslateService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ICbicRegulationMst> | Observable<never> {
    const sectionId = route.params['regulationSectionId'];
    const language = route.params['language']
    this.sessionStorage.store('locale',language)
    const localLang = this.localStorage.retrieve('language')
    if(localLang){
      this.sessionStorage.store('locale',localLang)
      this.translateService.use(localLang);
    }

    if (sectionId) {
      return this.service.find(sectionId).pipe(
        mergeMap((cbicRegulationMst: HttpResponse<CbicRegulationMst>) => {
          if (cbicRegulationMst.body) {
            // alert(cbicRuleSectionMst.body)
            return of(cbicRegulationMst.body);
          } else {
            this.router.navigate(['404']);
            return EMPTY;
          }
        })
      );
    }
    return of(new CbicRegulationMst());
  }
}
