<div class="row">

    <div class="col-lg-10">
        <!--<div class="row cbic-section"><p class="name">{{selectedChapterNo}}</p></div>
            <div class="row cbic-section"><p class="title">{{selectedChapterName}}</p></div>
            <h4 class="d-flex"></h4> -->

        <!-- before hindi english translation -->
        <!--    <h4 class="d-flex" *ngIf="viewBy=='Chapter'">
                {{selectedChapterNo}} <span *ngIf="selectedChapterName!=''"> -
                    {{selectedChapterName}}</span>
                <div class="read-area-margin"></div>
            </h4> -->

        <!-- after hindi english translation for chapter heading -->


        <h4 class="d-flex" *ngIf="viewBy=='Chapter'">
            <div *ngIf="language==='en'">
                {{selectedChapterNo}} <span *ngIf="selectedChapterName!=''"> -
                    {{selectedChapterName}}</span>
            </div>
            <div *ngIf="language==='hi'">

                {{selectedChapterNoHi?selectedChapterNoHi:selectedChapterNo}}
                <span *ngIf="selectedChapterNameHi || selectedChapterName"> -
                    {{selectedChapterNameHi?selectedChapterNameHi:selectedChapterName}} </span>
            </div>
            <div class="read-area-margin"></div>
        </h4>

        <div *ngIf="viewBy!='Chapter'">
            <div *ngFor="let chapter of getChapterName; let i = index">

                <h4 class="d-flex">
                    <div *ngIf="language==='en'">
                        {{chapter.chapterNo}}<span *ngIf="chapter.chapterName!=''"> -
                            {{chapter.chapterName}}</span>

                    </div>

                    <div *ngIf="language==='hi'">
                        {{chapter.chapterNoHi?chapter.chapterNoHi:chapter.chapterNo}}
                        <span *ngIf="chapter.chapterName!='' || chapter.chapterNameHi!=''"> -
                            {{chapter.chapterNameHi?chapter.chapterNameHi:chapter.chapterName}}</span>

                    </div>
                    <div class="read-area-margin"></div>
                </h4>
            </div>
        </div>

        <!--  <h4 class="d-flex" *ngIf="selectedSectionView" >{{getChapterName}}</h4> -->
    </div>
    <div class="col-lg-2" *ngIf="viewBy=='Chapter'">
        <div class="input-group input-group-sm ">
            <span class="input-group-text" (click)="gotoPreviousSection()"><a
                    [ngClass]="currentSection === 0 ? 'disabled' : ''" [routerLink]="" title="Previous Section"><i
                        [ngClass]="currentSection === 0 ? 'fa fa-arrow-left disableIcon' : 'fa fa-arrow-left'"
                        aria-hidden="true"></i></a></span>
            <span class="bg-transparent input-group-text"></span>
            <span class="input-group-text" (click)="gotoNextSection()"><a
                    [ngClass]="currentSection === sectionListLengthRead ? 'disabled' : ''" [routerLink]=""
                    title="Next Section"><i
                        [ngClass]="currentSection === sectionListLengthRead ? 'fa fa-arrow-right disableIcon' : 'fa fa-arrow-right'"
                        aria-hidden="true"></i></a></span>
        </div>
    </div>
    <div *ngIf="viewBy=='Chapter'" class="tab-content wrap-content" id="v-pills-tabContent">
        <div class="tab-pane fade show active" id="v-pills-c1" role="tabpanel" aria-labelledby="v-pills-c1-tab">
            <div class="accordion accordion-flush" id="accordionFlushExample">
                <div *ngFor="let section of sectionList; let i = index" class="accordion-item">
                    <span *ngIf="editEnable==='Y'">
                        <a href="/suneditor/{{selectedTab}}/{{section.id}}/{{language}}" target="_blank">
                            <i class="fa fa-edit" aria-hidden="true"></i>
                        </a>
                    </span>
                    <h2 class="accordion-header" id="{{i}}" *ngIf="selectedTab=='Acts' || selectedTab=='Rules'">

                        <div *ngIf="language==='en'">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                [ngClass]="{'collapsed': !reading[i]}" [attr.data-bs-target]="'#flush-section'+i"
                                aria-expanded="true" aria-controls="flush-collapseOne"
                                (click)="fetchData(section.contentFilePath,section.sectionNo,section.sectionName,i,searchKeyWord,section.contentId)">
                                <a data-bs-toggle="modal" data-bs-target="#sectionRead">
                                    <i class="far fa-window-maximize"></i>
                                </a>
                                {{section.sectionNo}} <span *ngIf="section.sectionName!='' && section.sectionNo!='' "> -
                                </span> {{section.sectionName}}
                            </button>
                        </div>

                        <div *ngIf="language==='hi'">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                [ngClass]="{'collapsed': !reading[i]}" [attr.data-bs-target]="'#flush-section'+i"
                                aria-expanded="true" aria-controls="flush-collapseOne" (click)="fetchData((section.contentFilePathHi===null ||section.contentFilePathHi===''?section.contentFilePath:section.contentFilePathHi)
								,(section.sectionNoHi===null ||section.sectionNoHi===''?section.sectionNo:section.sectionNoHi)
								,(section.sectionNameHi===null ||section.sectionNameHi===''?section.sectionName:section.sectionNameHi)
								,i,searchKeyWord,section.contentId)">
                                <a data-bs-toggle="modal" data-bs-target="#sectionRead">
                                    <i class="far fa-window-maximize"></i>
                                </a>
                                {{section.sectionNoHi?section.sectionNoHi:section.sectionNo}}
                                <span *ngIf="section.sectionNameHi!='' || section.sectionName!='' "> - </span>
                                {{section.sectionNameHi?section.sectionNameHi:section.sectionName}}
                            </button>
                        </div>
                    </h2>

                    <!-- <h2 class="accordion-header" id="{{i}}" *ngIf="selectedTab=='Rules'">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            [ngClass]="{'collapsed': !reading[i]}" [attr.data-bs-target]="'#flush-section'+i"
                            aria-expanded="true" aria-controls="flush-collapseOne"
                            (click)="fetchData(section.contentFilePath,section.sectionNo,section.sectionName,i,searchKeyWord,section.id)">
                            <a data-bs-toggle="modal" data-bs-target="#sectionRead">
                                <i class="far fa-window-maximize"></i>
                            </a>
                            {{section.sectionNo}} <span *ngIf="section.sectionName!='' && section.sectionNo!='' "> - </span> {{section.sectionName}}
                        </button>
                        
                    </h2>-->
                    <!-- <h2 class="accordion-header" id="{{i}}" *ngIf="selectedTab=='Regulations'">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            [ngClass]="{'collapsed': !reading[i]}" [attr.data-bs-target]="'#flush-section'+i"
                            aria-expanded="true" aria-controls="flush-collapseOne"
                            (click)="fetchData(section.contentFilePath,section.regulationNo,section.regulationName,i,searchKeyWord,section.contentId)">
                            <a data-bs-toggle="modal" data-bs-target="#sectionRead">
                                <i class="far fa-window-maximize"></i>
                            </a>
                            {{section.regulationNo}} <span
                                *ngIf="section.regulationName!='' && section.regulationNo!='' ">-</span>
                            {{section.regulationName}}
                        </button>

                    </h2> -->

                    <h2 class="accordion-header" id="{{i}}" *ngIf="selectedTab=='Regulations'">
                        <div *ngIf="language==='hi'">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                [ngClass]="{'collapsed': !reading[i]}" [attr.data-bs-target]="'#flush-section'+i"
                                aria-expanded="true" aria-controls="flush-collapseOne" (click)="fetchData((section.contentFilePathHi===null ||section.contentFilePathHi===''?section.contentFilePath:section.contentFilePathHi) 
                        ,(section.regulationNoHi===null ||section.regulationNoHi===''?section.regulationNo:section.regulationNoHi)
                        ,(section.regulationNameHi===null ||section.regulationNameHi===''?section.regulationName:section.regulationNameHi),
                        i,searchKeyWord,section.contentId)">
                                <a data-bs-toggle="modal" data-bs-target="#sectionRead">
                                    <i class="far fa-window-maximize"></i>
                                </a>
                                {{section.regulationNoHi?section.regulationNoHi:section.regulationNo}}

                                <span *ngIf="section.regulationNameHi!=''|| section.regulationName!=''">-</span>

                                {{section.regulationNameHi?section.regulationNameHi:section.regulationName}}
                            </button>
                        </div>
                        <div *ngIf="language==='en'">

                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                [ngClass]="{'collapsed': !reading[i]}" [attr.data-bs-target]="'#flush-section'+i"
                                aria-expanded="true" aria-controls="flush-collapseOne"
                                (click)="fetchData(section.contentFilePath,section.regulationNo,section.regulationName,i,searchKeyWord,section.contentId)">
                                <a data-bs-toggle="modal" data-bs-target="#sectionRead">
                                    <i class="far fa-window-maximize"></i>
                                </a>
                                {{section.regulationNo}} <span
                                    *ngIf="section.regulationName!='' && section.regulationNo!='' ">-</span>
                                {{section.regulationName}}
                            </button>
                        </div>
                    </h2>

                    <div [attr.id]="'flush-section'+i" name="flush-headingOne" class="accordion-collapse collapse"
                        [ngClass]="{'show': reading[i]}" aria-labelledby="flush-heading"
                        data-bs-parent="#accordionFlushExample" #flushsection1>

                        <div class="accordion-body">
                            <div class="pagebody myFontFamily">
                                <cbic-inner-component [hidden]="errorPageTobeShown" [inputData]="sectionContentDiv[i]">
                                </cbic-inner-component>
                                <cbic-content-error-page [hidden]="!errorPageTobeShown" style="text-align: center;">
                                </cbic-content-error-page>

                            </div>
                            <br />
                            <ul class="nav nav-tabs" id="myTab" role="tablist" *ngIf="showRelatedContent">
                                <li class="nav-item text-center" role="presentation">
                                    <i class="fa fa-link" aria-hidden="true"></i>
                                </li>
                                <li class="nav-item" role="presentation"
                                    *ngFor="let related of relatedTabsAry[i]; let j = index">
                                    <button class="nav-link" id="related-rules-tab" data-bs-toggle="tab"
                                        data-bs-target="#related-rules" type="button" role="tab"
                                        (click)="showContentList(related.relatedContent, i)" aria-controls="rules"
                                        aria-selected="false">{{related.relatedTabName}}
                                        ({{related.count}})</button>
                                </li>

                            </ul>
                        </div>

                    </div>
                    <!-- Modal -->
                    <div class="modal fade" id="sectionRead" tabindex="-1" aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog " id="flipbook">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">
                                        {{readSectionNo}}-{{readSectionName}}</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div class="accordion-body">
                                        <cbic-inner-component [inputData]="sectionContentDiv[i]"></cbic-inner-component>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="tab-pane fade" id="v-pills-c3" role="tabpanel" aria-labelledby="v-pills-c3-tab"></div>
    <div class="tab-pane fade" id="v-pills-c4" role="tabpanel" aria-labelledby="v-pills-c4-tab"></div> -->
    </div>
    <div class="row">

        <div *ngIf="viewBy!='Chapter'" class="tab-content wrap-content" id="v-pills-tabContent">
            <div class="tab-pane fade show active" id="v-pills-c1" role="tabpanel" aria-labelledby="v-pills-c1-tab">
                <div class="accordion accordion-flush" id="accordionFlushExample">

                    <div *ngFor="let section of viewSection; let i = index" class="accordion-item">
                        <span *ngIf="editEnable==='Y'">

                            <a href="/suneditor/{{selectedTab}}/{{section.id}}/{{language}}" target="_blank">
                                <i class="fa fa-edit" aria-hidden="true"></i>
                            </a>
                        </span>


                        <h2 class="accordion-header" id="{{i}}" *ngIf="selectedTab=='Acts'">

                            <div *ngIf="language==='en'">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    [ngClass]="{'collapsed': !reading[i]}" [attr.data-bs-target]="'#flush-section'+i"
                                    aria-expanded="true" aria-controls="flush-collapseOne"
                                    (click)="fetchData(section.contentFilePath,section.sectionNo,section.sectionName,i,searchKeyWord,section.contentId)">
                                    <a data-bs-toggle="modal" data-bs-target="#sectionRead">
                                        <i class="far fa-window-maximize"></i>
                                    </a>
                                    {{section.sectionNo}} <span
                                        *ngIf="section.sectionName!='' && section.sectionNo!='' ">-</span>
                                    {{section.sectionName}}
                                </button>
                            </div>
                            <div *ngIf="language==='hi'">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    [ngClass]="{'collapsed': !reading[i]}" [attr.data-bs-target]="'#flush-section'+i"
                                    aria-expanded="true" aria-controls="flush-collapseOne" (click)="fetchData((section.contentFilePathHi===null ||section.contentFilePathHi===''?section.contentFilePath:section.contentFilePathHi)
									,(section.sectionNoHi===null ||section.sectionNoHi===''?section.sectionNo:section.sectionNoHi)
									,(section.sectionNameHi===null ||section.sectionNameHi===''?section.sectionName:section.sectionNameHi)
									,i,searchKeyWord,section.contentId)">
                                    <a data-bs-toggle="modal" data-bs-target="#sectionRead">
                                        <i class="far fa-window-maximize"></i>
                                    </a>
                                    {{section.sectionNoHi?section.sectionNoHi:section.sectionNo}}
                                    <span *ngIf="section.sectionNameHi!='' || section.sectionName!='' ">-</span>
                                    {{section.sectionNameHi?section.sectionNameHi:section.sectionName}}
                                </button>
                            </div>


                        </h2>
                        <h2 class="accordion-header" id="{{i}}" *ngIf="selectedTab=='Rules'">

                            <div *ngIf="language==='en'">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    [ngClass]="{'collapsed': !reading[i]}" [attr.data-bs-target]="'#flush-section'+i"
                                    aria-expanded="true" aria-controls="flush-collapseOne"
                                    (click)="fetchData(section.contentFilePath,section.sectionNo,section.sectionName,i,searchKeyWord,section.contentId)">
                                    <a data-bs-toggle="modal" data-bs-target="#sectionRead">
                                        <i class="far fa-window-maximize"></i>
                                    </a>
                                    {{section.sectionNo}} <span
                                        *ngIf="section.sectionName!='' && section.sectionNo!='' ">-</span>
                                    {{section.sectionName}}
                                </button>
                            </div>

                            <div *ngIf="language==='hi'">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    [ngClass]="{'collapsed': !reading[i]}" [attr.data-bs-target]="'#flush-section'+i"
                                    aria-expanded="true" aria-controls="flush-collapseOne" (click)="fetchData((section.contentFilePathHi===null ||section.contentFilePathHi===''?section.contentFilePath:section.contentFilePathHi)
									,(section.sectionNoHi===null ||section.sectionNoHi===''?section.sectionNo:section.sectionNoHi)
									,(section.sectionNameHi===null ||section.sectionNameHi===''?section.sectionName:section.sectionNameHi)
									,i,searchKeyWord,section.contentId)">
                                    <a data-bs-toggle="modal" data-bs-target="#sectionRead">
                                        <i class="far fa-window-maximize"></i>
                                    </a>
                                    {{section.sectionNoHi?section.sectionNoHi:section.sectionNo}}
                                    <span *ngIf="section.sectionNameHi!='' || section.sectionName!='' ">-</span>

                                    {{section.sectionNameHi?section.sectionNameHi:section.sectionName}}
                                </button>
                            </div>



                        </h2>

                        <!-- <h2 class="accordion-header" id="{{i}}" *ngIf="selectedTab=='Regulations'">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                [ngClass]="{'collapsed': !reading[i]}" [attr.data-bs-target]="'#flush-section'+i"
                                aria-expanded="true" aria-controls="flush-collapseOne"
                                (click)="fetchData(section.contentFilePath,section.sectionNo,section.sectionName,i,searchKeyWord,section.contentId)">
                                <a data-bs-toggle="modal" data-bs-target="#sectionRead">
                                    <i class="far fa-window-maximize"></i>
                                </a>
                                {{section.regulationNo}} <span
                                    *ngIf="section.regulationName!='' && section.regulationNo!=''"> - </span>
                                {{section.regulationName}}
                            </button>
                        </h2> -->

                        <h2 class="accordion-header" id="{{i}}" *ngIf="selectedTab=='Regulations'">
                            <div *ngIf="language==='hi'">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    [ngClass]="{'collapsed': !reading[i]}" [attr.data-bs-target]="'#flush-section'+i"
                                    aria-expanded="true" aria-controls="flush-collapseOne" (click)="fetchData((section.contentFilePathHi===null ||section.contentFilePathHi===''?section.contentFilePath:section.contentFilePathHi)
                                ,(section.regulationNoHi===null ||section.regulationNoHi===''?section.regulationNo:section.regulationNoHi)
                                ,(section.regulationNameHi===null ||section.regulationNameHi===''?section.regulationName:section.regulationNameHi)
                                ,i,searchKeyWord,section.contentId)">
                                    <a data-bs-toggle="modal" data-bs-target="#sectionRead">
                                        <i class="far fa-window-maximize"></i>
                                    </a>
                                    {{section.regulationNoHi?section.regulationNoHi:section.regulationNo}}
                                    <span *ngIf="section.regulationNameHi!='' || section.regulationName!=''"> - </span>
                                    {{section.regulationNameHi?section.regulationNameHi:section.regulationName}}
                                </button>

                            </div>
                            <div *ngIf="language==='en'">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    [ngClass]="{'collapsed': !reading[i]}" [attr.data-bs-target]="'#flush-section'+i"
                                    aria-expanded="true" aria-controls="flush-collapseOne"
                                    (click)="fetchData(section.contentFilePath,section.regulationNo,section.regulationName,i,searchKeyWord,section.contentId)">
                                    <a data-bs-toggle="modal" data-bs-target="#sectionRead">
                                        <i class="far fa-window-maximize"></i>
                                    </a>
                                    {{section.regulationNo}} <span
                                        *ngIf="section.regulationName!='' && section.regulationNo!=''"> - </span>
                                    {{section.regulationName}}
                                </button>

                            </div>

                        </h2>
                        <div [attr.id]="'flush-section'+i" name="flush-headingOne" class="accordion-collapse"
                            [ngClass]="{'collapse': !reading[i]}" aria-labelledby="flush-heading"
                            data-bs-parent="#accordionFlushExample" #flushsection1>
                            <div class="accordion-body">
                                <div class="pagebody">
                                    <cbic-inner-component [hidden]="errorPageTobeShown"
                                        [inputData]="sectionContentDiv[i]">
                                    </cbic-inner-component>
                                    <cbic-content-error-page [hidden]="!errorPageTobeShown" style="text-align: center;">
                                    </cbic-content-error-page>

                                </div>
                                <br />
                                <ul class="nav nav-tabs" id="myTab" role="tablist" *ngIf="showRelatedContent">
                                    <li class="nav-item text-center" role="presentation">
                                        <i class="fa fa-link" aria-hidden="true"></i>
                                    </li>
                                    <li class="nav-item" role="presentation"
                                        *ngFor="let related of relatedTabs; let j = index">
                                        <button class="nav-link" id="related-rules-tab" data-bs-toggle="tab"
                                            data-bs-target="#related-rules" type="button" role="tab"
                                            (click)="showContentList(related.relatedContent, -1)" aria-controls="rules"
                                            aria-selected="false">{{related.relatedTabName}}
                                            ({{related.count}})</button>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <!-- Modal -->
                        <div class="modal fade" id="sectionRead" tabindex="-1" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog " id="flipbook">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel" *ngIf="selectedTab=='Acts'">
                                            {{readSectionNo}}-{{readSectionName}}</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="accordion-body">
                                            <cbic-inner-component [inputData]="sectionContentDiv[i]">
                                            </cbic-inner-component>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="tab-pane fade" id="v-pills-c3" role="tabpanel" aria-labelledby="v-pills-c3-tab"></div>
    <div class="tab-pane fade" id="v-pills-c4" role="tabpanel" aria-labelledby="v-pills-c4-tab"></div> -->
        </div>
        <div class="row">
            <div class="col-lg-10 col-md-10">
            </div>
            <!-- <div class="col-lg-2 col-md-2">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text" (click)="gotoPreviousSection()"><a
                    [ngClass]="currentSection === 0 ? 'disabled' : ''" [routerLink]=""
                    title="Previous Section"><i
                        [ngClass]="currentSection === 0 ? 'fa fa-arrow-left disableIcon' : 'fa fa-arrow-left'"
                        aria-hidden="true"></i></a></span>
                <span class="bg-transparent input-group-text"></span>
                <span class="input-group-text" (click)="gotoNextSection()"><a
                        [ngClass]="currentSection === lastSection ? 'disabled' : ''" [routerLink]="" title="Next Section"><i
                        [ngClass]="currentSection === lastSection ? 'fa fa-arrow-right disableIcon' : 'fa fa-arrow-right'" aria-hidden="true"></i></a></span>
            </div>
        </div> -->
        </div>
    </div>
    <ng-template #relatedList let-modal>

        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" style="text-align: left;padding-left: 2px;">Related Content for
                    {{readSectionNo}}, {{contentName}}</h5>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')"><i
                        class="fas fa-times"></i>
                </button>
            </div>
            <div class="modal-body">
                <cbic-related-content-list [relatedContentList]="relatedContentResponse"
                    [contentType]="relatedContentType"></cbic-related-content-list>
            </div>
        </div>
    </ng-template>