<div class="related-search-view">
    <div class="result-data">
        <div class="table-responsive" *ngIf="contentType ==='ACT'">
            <table class="table table-hover">
                <tbody>
                    <tr>
                        <th cbicTranslate="ruleName">{{contentType}} Name</th>
                        <th cbicTranslate="taxType">Tax type</th>
                        <th cbicTranslate="subjectName">Subject Name</th>
                 </tr>

                    <tr *ngFor="let act of relatedContentList.actSectionMsts, let i=index">
                        <td class="search-link" title="{{act.actName}}"><a
                                (click)="openAct(act.taxId, act.sectionId)">{{act.actName}}</a></td>
                        <td class="search-link">{{act.taxType}}</td>
                        <td class="search-link" title="{{act.actSubjectName}}"><a
                                (click)="openAct(act.taxId, act.sectionId)">{{act.actSubjectName}}</a></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="table-responsive" *ngIf="contentType ==='RULE'">
            <table class="table table-hover">
                <tbody>
                    <tr>
                        <th cbicTranslate="ruleName">{{contentType}} Name</th>
                        <th cbicTranslate="taxType">Tax type</th>
                        <th cbicTranslate="subjectName">Subject Name</th>
                    </tr>

                    <tr *ngFor="let rule of relatedContentList.ruleSectionMsts, let i=index">
                        <td class="search-link" title="{{rule.ruleName}}"><a
                                (click)="openRule(rule.taxId, rule.ruleId)">{{rule.ruleName}}</a></td>
                        <td class="search-link">{{rule.taxType}}</td>
                        <td class="search-link" title="{{rule.ruleSubjectName}}"><a
                                (click)="openRule(rule.taxId, rule.ruleId)">{{rule.ruleSubjectName}}</a></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="table-responsive" *ngIf="contentType ==='REGULATION'">
            <table class="table table-hover">
                <tbody>
                    <tr>
                        <th cbicTranslate="ruleName">{{contentType}} Name</th>
                        <th cbicTranslate="taxType">Tax type</th>
                        <th cbicTranslate="subjectName">Subject Name</th>
                    </tr>

                    <tr *ngFor="let regulation of relatedContentList.regulationMsts, let i=index">
                        <td class="search-link" title="{{regulation.regulationName}}"><a
                                (click)="openRegulation(regulation.taxId, regulation.regulationId)">{{regulation.regulationName}}</a>
                        </td>
                        <td class="search-link">{{regulation.taxType}}</td>
                        <td class="search-link" title="{{regulation.regulationSubjectName}}"><a
                                (click)="openRegulation(regulation.taxId, regulation.regulationId)">{{regulation.regulationSubjectName}}</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="table-responsive" *ngIf="contentType ==='FORM'">
            <table class="table table-hover custom-td">
                <tbody>
                    <tr>
                        <th cbicTranslate="formCategory">Form Category</th>
                        <th cbicTranslate="formNo">Form No</th>
                        <th cbicTranslate="formName">Form Name</th>
                    </tr>

                    <tr *ngFor="let form of relatedContentList.formMsts, let i=index">
                        <td class="search-link">{{form.formCategory}}</td>
                        <td class="search-link"><a (click)="openForm(form.taxId, form.formId)">{{form.formNumber}}</a>
                        </td>
                        <td class="search-link" title="{{form.formName}}"><a
                                (click)="openForm(form.taxId, form.formId)">{{form.formName}}</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="table-responsive" *ngIf="contentType ==='NOTIFICATION'">
            <table class="table table-hover custom-td">
                <tbody>
                    <tr>
                        <th cbicTranslate="notificationCategory">Notification Category</th>
                        <th cbicTranslate="notificationNo">Notification No</th>
                        <th cbicTranslate="notificationName">Notification Name</th>
                    </tr>

                    <tr *ngFor="let notification of relatedContentList.notificationMsts, let i=index">
                        <td class="search-link">{{notification.notificationCategory}}</td>
                        <td class="search-link"><a
                                (click)="openNotification(notification.taxId, notification.notificationId)">{{notification.notificationNumber}}</a>
                        </td>
                        <td class="search-link" title="{{notification.notificationName}}"><a
                                (click)="openNotification(notification.taxId, notification.notificationId)">{{notification.notificationName}}</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="table-responsive" *ngIf="contentType ==='CIRCULAR'">
            <table class="table table-hover custom-td">
                <tbody>
                    <tr>
                        <th cbicTranslate="circularCategory">Circular Category</th>
                        <th cbicTranslate="circularNo">Circular No</th>
                        <th cbicTranslate="circularName">Circular Name</th>
                    </tr>

                    <tr *ngFor="let circular of relatedContentList.circularMsts, let i=index">
                        <td class="search-link">{{circular.circularCategory}}</td>
                        <td class="search-link">{{circular.circularNo}}</td>
                        <td class="search-link" title="{{circular.circularName}}">{{circular.circularName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>