import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { ProfileService } from 'app/layouts/profiles/profile.service';
import { faGlobe,faSun,faHome} from '@fortawesome/free-solid-svg-icons';
import {faAccessibleIcon} from '@fortawesome/free-brands-svg-icons';
import { LANGUAGES } from 'app/config/language.constants';
import { SessionStorageService } from 'ngx-webstorage';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'cbic-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  openAPIEnabled?: boolean;
  inProduction?: boolean;
  faGlobe=faGlobe;
  faSun=faSun;
  faAccessibleIcon=faAccessibleIcon;
  faHome=faHome;
  languages = LANGUAGES;
  @Input() logoSrc:any;
  @Input() cbicLogoSrc:any;
  constructor(
    private router: Router,
    private sessionStorage: SessionStorageService,
    private translateService: TranslateService,
    private profileService: ProfileService
  ) { }

  ngOnInit(): void {
    this.profileService.getProfileInfo().subscribe(profileInfo => {
      this.inProduction = profileInfo.inProduction;
      this.openAPIEnabled = profileInfo.openAPIEnabled;
    });
  }

  changeLanguage(languageKey: string): void {
    this.sessionStorage.store('locale', languageKey);
    this.translateService.use(languageKey);
    
  }

}
