import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ProfileService } from 'app/layouts/profiles/profile.service';
import { faSearch} from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import { CbicSearchInput1, ICbicSearchInput } from 'app/searchbar/searchbar.model';
import { CbicSearchService } from 'app/search-page/service/cbic-search-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CbicSearchOutputResult } from 'app/search-page/search-output.model';
import { SessionStorageService } from 'ngx-webstorage';
@Component({
  selector: 'cbic-regulation-search-bar',
  templateUrl: './regulation-search-bar.component.html',
  styleUrls: ['./regulation-search-bar.component.scss']
})
export class RegulationSearchBarComponent implements OnInit {
  inProduction?: boolean;
  openAPIEnabled?: boolean;
  sample: any;
  @Input() regulationDocList:any;
  @Input() rulesChapterList:any;
  @Input() sectionListFetched:any;
  @Input()  searchRegulationId:any;
  @Output() regulationSelected = new EventEmitter<string>();
  @Output() chapterSelected = new  EventEmitter<string>();
  @Output() regulationDocSelected= new  EventEmitter<string>();
  @Output() regulationSearchFired= new EventEmitter<string>();
  @Output() regulationClearFired= new EventEmitter<string>();
  @Output() categorySelected = new  EventEmitter<string>();
  @Input() regulationsCategoryList:any;
  @Input() regulationList:any;
  selectedRegulationDocId:any;
  faTimesCircle=faTimesCircle;
  faSearch=faSearch;
  @Input() searchRuleId:any;
  searched:any;
  searchBarForm = this.fb.group({
    keyword : "",
    regulationId:"",
    chapterId:"",
    regulationDocId:""
  });
  @ViewChild('regulationDocSelection', { static: true }) regulationDocSelection!: ElementRef;
  @ViewChild("regSearchKeyword") regulationKeyword? : ElementRef;
  
  searchBarFormAll = this.fb.group({
    keywordAll : "",
  });
  @ViewChild("ruleSearchKeyword") ruleKeyword? : ElementRef;
  @Input() taxName: any;
  @ViewChild('allSearch', { static: true }) allSearchTextModal!: ElementRef;
  allSearchMsg: any
  @ViewChild("allRuleSearchKeyword") allRuleSearchKeyword? : ElementRef;
  @Input() taxId:any;
  @Input() activeTab:any
  @Input() tabName:any
  storageLanguage:any

  constructor(private http : HttpClient, 
    private sanitizer: DomSanitizer,
    private router: Router,
    private profileService: ProfileService,
    private fb:FormBuilder,
    private service: CbicSearchService,
    private modalService : NgbModal,
    private sessionStorage: SessionStorageService) { }

 

  

  ngOnInit(): void {
    this.storageLanguage = this.sessionStorage.retrieve('locale')
    this.profileService.getProfileInfo().subscribe(profileInfo => {
      this.inProduction = profileInfo.inProduction;
      this.openAPIEnabled = profileInfo.openAPIEnabled;
    });
    if(this.searchRegulationId){
      this.selectedRegulationDocId=this.searchRegulationId;
    }
   
    // alert(this.selectedRegulationDocId)
    
  }
   createFromForm(): ICbicSearchInput {
    return {
      ...new CbicSearchInput1(),
      keyword: (this.searchBarForm.get(['keyword'])!.value === null || this.searchBarForm.get(['keyword'])!.value === undefined ) ? null : this.searchBarForm.get(['keyword'])!.value,
      actId: this.searchBarForm.get(['regulationDocId'])!.value,
      sectionId: this.searchBarForm.get(['regulationId'])!.value,

    };
  }
  
  fireRegulationDocSelected(regulationDocId:string):void{
    this.searched=false;
    this.selectedRegulationDocId=regulationDocId;
    this.regulationDocSelected.emit(regulationDocId);
  }
  fireRegulationSelected(regulationNoAndName:string):void{
    let regulationId;
    if(regulationNoAndName !== ""){
      for(let i=0;i<this.regulationList.length;i++){
        if(regulationNoAndName === String(this.regulationList[i].regulationNo)+'-'+String(this.regulationList[i].regulationName)){
          regulationId = this.regulationList[i].id;
          
        }
      }
      
      this.regulationSelected.emit(regulationId);
    }
  }

  changeCategorySelection(regulationCategory:string):void
  {
      if(regulationCategory !== "" )
      {
        if(regulationCategory==="All" || regulationCategory==="सभी" )
        {
          this.categorySelected.emit(regulationCategory);
        }else 
        {

          for(let i=0;i<this.regulationsCategoryList.length;i++){
          if(regulationCategory === this.regulationsCategoryList[i].regulationCategory){
            if(this.storageLanguage==='hi')
           {
          regulationCategory = this.regulationsCategoryList[i].regulationCategoryHi;
          regulationCategory=regulationCategory.replace("/","*");
          this.categorySelected.emit(regulationCategory);
          
           }else{
          
          regulationCategory = this.regulationsCategoryList[i].regulationCategory;
          regulationCategory=regulationCategory.replace("/","*");
          this.categorySelected.emit(regulationCategory);
                }
          }
        }

       }
        
      }
   }

    
    
  
  setRegulationId(regulationDocIdFirst:string, regulationId:string):void{
    // this.selectedRegulationDocId = regulationDocId;
    this.searchBarForm.patchValue({
      regulationDocId:regulationDocIdFirst
    });
    this.regulationSelected.emit(regulationId);
  }

  setRegulationIdFromClear(regulationDocIdFirst:string):void{
    this.searchBarForm.patchValue({
        regulationDocId:regulationDocIdFirst
      });
  }

  search():void{
    
    const cbicSearchInput = this.createFromForm();
    if(cbicSearchInput.keyword === undefined || cbicSearchInput.keyword!.length < 3){
      alert("Please enter valid keyword");
      this.regulationKeyword?.nativeElement.focus();
      return;
    }

    cbicSearchInput.keyword = cbicSearchInput.keyword?.trim()
    for(let i=0;i<this.regulationList.length;i++){
      if(String(this.regulationList[i].regulationNo)+'-'+String(this.regulationList[i].regulationName)===cbicSearchInput.sectionId){
        cbicSearchInput.sectionId=this.regulationList[i].id;
      }
    }
    this.regulationSearchFired.emit(JSON.stringify(cbicSearchInput));
    this.searched=true;
    //this.router.navigate(['searchPage/search'],{queryParams : cbicSearchInput});
  }
   clear():void{
    this.searched=false;
    this.searchBarForm.reset()
    this.setRegulationIdFromClear(this.regulationDocList[0].id)
    this.regulationClearFired.emit();
    return;
  }

  searchAll():void{
    
    const cbicSearchInput = this.createAllForm();
    if(cbicSearchInput.keyword === undefined || cbicSearchInput.keyword!.length < 3){
      alert("Please enter valid keyword");
      this.ruleKeyword?.nativeElement.focus();
      return;
    }

    cbicSearchInput.keyword = cbicSearchInput.keyword?.trim()

    this.service.pageNumber = 1;
      this.service.searchInSection(cbicSearchInput).subscribe(
        (res: HttpResponse<CbicSearchOutputResult>) => {
          if(res.body?.totalDocuments === 0){
            this.allSearchMsg = "No search result found."
            this.allRuleSearchKeyword?.nativeElement.focus();
            this.searchBarFormAll = this.fb.group({
              keywordAll : ""
            });
            return;
          } else{
            this.modalService.dismissAll()
            this.service.cbicSearchOutputResults = res.body ?? new CbicSearchOutputResult([], null);
            this.router.navigate(['searchPage/search'],{queryParams : cbicSearchInput});
            //const url = this.router.serializeUrl(this.router.createUrlTree(['searchPage/search'],{queryParams : cbicSearchInput}));

            //window.open(url, '_blank');
          }
         
        });

  }

  openAllSearch(): void{
    this.modalService.open(this.allSearchTextModal,{windowClass : "", centered : true});
  }

  protected createAllForm(): ICbicSearchInput {
    return {
      ...new CbicSearchInput1(),
      keyword: (this.searchBarFormAll.get(['keywordAll'])!.value === null || this.searchBarFormAll.get(['keywordAll'])!.value === undefined) ? null : this.searchBarFormAll.get(['keywordAll'])!.value.trim(),
      content: "REGULATIONS",
      taxId: this.taxId,
      startIndex: 0,
      paginationFlag: true,
      rows: 10
    };
  }

}
