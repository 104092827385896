import { HttpResponse } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { CbicContentMap2, CbicContentMapResponse } from "app/entities/cbic-content-map/cbic-content-map.model";
import { CbicContentMapService } from "app/entities/cbic-content-map/service/cbic-content-map.service";
import { ICbicTaxMst } from "app/entities/cbic-tax-mst/cbic-tax-mst.model";
import { CbicTaxMstService } from "app/entities/cbic-tax-mst/service/cbic-tax-mst.service";
import { CbicContent, CbicContentDtls, CbicContentHeader, CbicContentLink, CbicContentLink1, CbicContentLink2, CbicContentSearch, FetchLink, ICbicContent, RegulationDtls } from "app/searchbar/searchbar.model";

@Component({
  selector: "cbic-link-page",
  templateUrl: "./link-page.component.html"
})
export class linkPageComponent implements OnInit {

  cbicTaxMsts?: ICbicTaxMst[];
  cbicContents?: ICbicContent[];
  cbicContentHeader?: CbicContentHeader[];
  parentContentHeader?: CbicContentHeader[];
  childContentHeader?: CbicContentHeader[];
  cbicContentDtls?: CbicContentDtls[];
  parentContentDtls?: CbicContentDtls[];
  childContentDtls?: CbicContentDtls[];
  errMsg?: string;
  cbicContentMapResponse?: CbicContentMapResponse | null;
  successMsg?: string;
  @ViewChild("childTaxType") childTaxType!: ElementRef;
  @ViewChild("childContentType") childContentType!: ElementRef;
  contentsLink?: Map<string, string>;
  yearList: any;
  tempYear: any;
  @ViewChild("parentYear") parentYear!: ElementRef;
  @ViewChild("childYear") childYear!: ElementRef;
  fetchLink: any;
  @ViewChild("parentLink") parentLink!: ElementRef;
  @ViewChild("childLink") childLink!: ElementRef;
  regulationCategory?: string[];
  parentRegulationCategory1?: string[];
  childRegulationCategory1?: string[];
  @ViewChild("parentRegulationCategory") category!: ElementRef;
  @ViewChild("childRegulationCategory") childCategory!: ElementRef;
  constructor(
    private taxMstService: CbicTaxMstService,
    private contentMapService: CbicContentMapService
  ) {
  }

  ngOnInit(): void {
    this.loadTaxMsts();
    this.loadContents();
    this.loadActs();
    this.loadContentDtls();
    this.loadContentMap();
    this.loadYearList();
    this.loadRegualtionCategory()
  }

  loadRegualtionCategory(): void {
    this.taxMstService.fetchRegulationCategory(null).subscribe(
      (res: HttpResponse<string[]>) => {
        if(res.body){
          this.regulationCategory = res.body;
          this.parentRegulationCategory1 = this.regulationCategory;
          this.childRegulationCategory1 = this.regulationCategory;
        }
        
      }
    );

  }

  loadYearList(): void {
    this.tempYear = [];
    for (let i = 2000; i <= new Date().getFullYear(); i++) {
      this.tempYear.push(i);
    }
    this.tempYear.reverse()
    this.yearList = this.tempYear;
  }

  loadContentMap(): void {
    const contentMap = new Map();
    contentMap.set('ACTS', 'RULES');
    contentMap.set('RULES', 'REGULATIONS');
    contentMap.set('REGULATIONS', 'FORMS');
    contentMap.set('FORMS', 'NOTIFICATIONS');
    contentMap.set('NOTIFICATIONS', 'CIRCULARS');
    this.contentsLink = contentMap;
  }

  loadTaxMsts(): void {
    this.taxMstService.query().subscribe(
      (res: HttpResponse<ICbicTaxMst[]>) => {
        this.cbicTaxMsts = res.body ?? [];
      }
    );
  }

  loadContents(): void {
    this.cbicContents = [];
    this.cbicContents.push(
      new CbicContent('ACTS', 'Acts'),
      new CbicContent('RULES', 'Rules'),
      new CbicContent('REGULATIONS', 'Regulations'),
      new CbicContent('FORMS', 'Forms'),
      new CbicContent('NOTIFICATIONS', 'Notifications'),
      new CbicContent('CIRCULARS', 'Circulars')
    )
  }


  loadActs(): void {
    this.taxMstService.fetchContentHeader(new CbicContentSearch("", "")).subscribe(
      (res: HttpResponse<CbicContentHeader[]>) => {
        this.cbicContentHeader = res.body ?? [];
        for (let i = 0; i < this.cbicContentHeader.length; i++) {
          let maxlen = this.cbicContentHeader[i].contentName.length;
          if (maxlen > 70) {
            maxlen = 67;
          }
          this.cbicContentHeader[i].contentNameLable = String(this.cbicContentHeader[i].contentName.substring(0, maxlen));
          if (maxlen === 67) {
            this.cbicContentHeader[i].contentNameLable = String(this.cbicContentHeader[i].contentName.substring(0, maxlen)) + "...";
          }
        }
        this.parentContentHeader = this.cbicContentHeader;
        this.childContentHeader = this.cbicContentHeader;
      }
    );

  }

  loadContentDtls(): void {
    this.taxMstService.fetchContentDtls(new CbicContentLink("", "", "")).subscribe(
      (res: HttpResponse<CbicContentDtls[]>) => {
        this.cbicContentDtls = res.body ?? [];
        for (let i = 0; i < this.cbicContentDtls.length; i++) {
          let maxlen = this.cbicContentDtls[i].contentDtlsName.length;
          if (maxlen > 70) {
            maxlen = 67;
          }
          this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen));
          if (maxlen === 67) {
            this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen)) + "...";
          }
        }
        this.parentContentDtls = this.cbicContentDtls;
        this.childContentDtls = this.cbicContentDtls;
      }
    );

  }

  showParentContent(taxId: any, contentType: any, contentCategory: any): void {
    let taxRegulationCategory: any = null;
    if (taxId === "undefined") {
      taxId = "";
    }
    if (contentType === "undefined") {
      contentType = "";
    }

    this.taxMstService.fetchContentHeader(new CbicContentSearch(taxId, contentType)).subscribe(
      (res: HttpResponse<CbicContentHeader[]>) => {
        this.cbicContentHeader = res.body ?? [];
        for (let i = 0; i < this.cbicContentHeader.length; i++) {
          let maxlen = this.cbicContentHeader[i].contentName.length;
          if (maxlen > 70) {
            maxlen = 67;
          }
          this.cbicContentHeader[i].contentNameLable = String(this.cbicContentHeader[i].contentName.substring(0, maxlen));
          if (maxlen === 67) {
            this.cbicContentHeader[i].contentNameLable = String(this.cbicContentHeader[i].contentName.substring(0, maxlen)) + "...";
          }
        }
        this.parentContentHeader = this.cbicContentHeader;
      }
    );
    this.taxMstService.fetchContentDtls(new CbicContentLink(taxId, contentType, "")).subscribe(
      (res: HttpResponse<CbicContentDtls[]>) => {
        this.cbicContentDtls = res.body ?? [];
        for (let i = 0; i < this.cbicContentDtls.length; i++) {
          let maxlen = this.cbicContentDtls[i].contentDtlsName.length;
          if (maxlen > 70) {
            maxlen = 67;
          }
          this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen));
          if (maxlen === 67) {
            this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen)) + "...";
          }
        }
        this.parentContentDtls = this.cbicContentDtls;
        if (contentType !== "" && (contentType === 'NOTIFICATIONS' || contentType === 'CIRCULARS')) {
          this.parentYear.nativeElement.value = "undefined";
          this.parentLink.nativeElement.value = "";
        }
        if (contentType !== "" && contentType === 'REGULATIONS') {
          this.category.nativeElement.value = "undefined";
          if(taxId !== ""){
            taxRegulationCategory = taxId;
          }
          this.taxMstService.fetchRegulationCategory(taxRegulationCategory).subscribe(
            (res2: HttpResponse<string[]>) => {
              if(res2.body){
                this.regulationCategory = res2.body;
                this.parentRegulationCategory1 = this.regulationCategory;
              }
              
            }
          );
        }
      }
    );

    if (taxId !== "") {
      this.childTaxType.nativeElement.value = taxId;
      if (contentType !== "") {
        if (this.contentsLink?.has(contentType)) {
          this.childContentType.nativeElement.value = this.contentsLink.get(contentType);
          this.showChildContent(taxId, this.contentsLink.get(contentType), "");
        } else {
          this.showChildContent(taxId, "", "");
        }
      } else {
        this.showChildContent(taxId, "", "");
      }
    } else {
      if (contentType !== "") {
        if (this.contentsLink?.has(contentType)) {
          this.childContentType.nativeElement.value = this.contentsLink.get(contentType);
          this.showChildContent("", this.contentsLink.get(contentType), "");
        }
      }
    }
  }

  showParentContentDtls(taxId: any, contentType: any, contentCategory: any): void {
    let contentCategory1;
    let contentType1;
    if (taxId === "undefined") {
      taxId = "";
    }
    if (contentType === "undefined") {
      contentType = "";
    }
    if (contentCategory === "undefined") {
      contentCategory = "";
    }
    if (contentCategory !== "") {
      const content = String(contentCategory).split("_");
      contentCategory1 = content[0];
      if (contentType === "") {
        contentType1 = content[1].toUpperCase();
      } else {
        contentType1 = contentType;
      }

    }
    this.taxMstService.fetchContentDtls(new CbicContentLink(taxId, contentType1, contentCategory1)).subscribe(
      (res: HttpResponse<CbicContentDtls[]>) => {
        this.cbicContentDtls = res.body ?? [];
        for (let i = 0; i < this.cbicContentDtls.length; i++) {
          let maxlen = this.cbicContentDtls[i].contentDtlsName.length;
          if (maxlen > 70) {
            maxlen = 67;
          }
          this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen));
          if (maxlen === 67) {
            this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen)) + "...";
          }
        }
        this.parentContentDtls = this.cbicContentDtls;
        if (contentType !== "" && (contentType === 'NOTIFICATIONS' || contentType === 'CIRCULARS')) {
          this.parentYear.nativeElement.value = "undefined";
          this.parentLink.nativeElement.value = "";
        }
      }
    );
  }

  showChildContent(taxId: any, contentType: any, contentCategory: any): void {
    let taxRegulationCategory: any = null;
    if (taxId === "undefined") {
      taxId = "";
    }
    if (contentType === "undefined") {
      contentType = "";
    }
    if (contentCategory === "undefined") {
      contentCategory = "";
    }

    this.taxMstService.fetchContentHeader(new CbicContentSearch(taxId, contentType)).subscribe(
      (res: HttpResponse<CbicContentHeader[]>) => {
        this.cbicContentHeader = res.body ?? [];
        for (let i = 0; i < this.cbicContentHeader.length; i++) {
          let maxlen = this.cbicContentHeader[i].contentName.length;
          if (maxlen > 70) {
            maxlen = 67;
          }
          this.cbicContentHeader[i].contentNameLable = String(this.cbicContentHeader[i].contentName.substring(0, maxlen));
          if (maxlen === 67) {
            this.cbicContentHeader[i].contentNameLable = String(this.cbicContentHeader[i].contentName.substring(0, maxlen)) + "...";
          }
        }
        this.childContentHeader = this.cbicContentHeader;
      }
    );
    this.taxMstService.fetchContentDtls(new CbicContentLink(taxId, contentType, "")).subscribe(
      (res: HttpResponse<CbicContentDtls[]>) => {
        this.cbicContentDtls = res.body ?? [];
        for (let i = 0; i < this.cbicContentDtls.length; i++) {
          let maxlen = this.cbicContentDtls[i].contentDtlsName.length;
          if (maxlen > 70) {
            maxlen = 67;
          }
          this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen));
          if (maxlen === 67) {
            this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen)) + "...";
          }
        }
        this.childContentDtls = this.cbicContentDtls;
        if (contentType !== "" && (contentType === 'NOTIFICATIONS' || contentType === 'CIRCULARS')) {
          this.childYear.nativeElement.value = "undefined";
          this.childLink.nativeElement.value = "";
        }
        if (contentType !== "" && contentType === 'REGULATIONS') {
          this.childCategory.nativeElement.value = "undefined";
          if(taxId !== ""){
            taxRegulationCategory = taxId;
          }
          this.taxMstService.fetchRegulationCategory(taxRegulationCategory).subscribe(
            (res2: HttpResponse<string[]>) => {
              if(res2.body){
                this.regulationCategory = res2.body;
                this.childRegulationCategory1 = this.regulationCategory;
              }
              
            }
          );
        }
      }
    );
  }

  showChildContentDtls(taxId: any, contentType: any, contentCategory: any): void {
    let contentCategory1;
    let contentType1;
    if (taxId === "undefined") {
      taxId = "";
    }
    if (contentType === "undefined") {
      contentType = "";
    }
    if (contentCategory === "undefined") {
      contentCategory = "";
    }
    if (contentCategory !== "") {
      const content = String(contentCategory).split("_");
      contentCategory1 = content[0];
      if (contentType === "") {
        contentType1 = content[1].toUpperCase();
      } else {
        contentType1 = contentType;
      }

    }
    this.taxMstService.fetchContentDtls(new CbicContentLink(taxId, contentType1, contentCategory1)).subscribe(
      (res: HttpResponse<CbicContentDtls[]>) => {
        this.cbicContentDtls = res.body ?? [];
        for (let i = 0; i < this.cbicContentDtls.length; i++) {
          let maxlen = this.cbicContentDtls[i].contentDtlsName.length;
          if (maxlen > 70) {
            maxlen = 67;
          }
          this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen));
          if (maxlen === 67) {
            this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen)) + "...";
          }
        }
        this.childContentDtls = this.cbicContentDtls;
        if (contentType !== "" && (contentType === 'NOTIFICATIONS' || contentType === 'CIRCULARS')) {
          this.childYear.nativeElement.value = "undefined";
          this.childLink.nativeElement.value = "";
        }
      }
    );
  }

  showParentContentDtlsWithYear(taxType: any, contentType: any, contentCategory: any, year: any): void {
    if (taxType === "undefined") {
      taxType = "";
    }
    if (contentCategory === "undefined") {
      contentCategory = "";
    }
    if (year === "undefined") {
      year = "";
    }
    this.taxMstService.fetchContentByYear(new CbicContentLink1(taxType, contentType, contentCategory, year)).subscribe(
      (res: HttpResponse<CbicContentDtls[]>) => {
        this.cbicContentDtls = res.body ?? [];
        for (let i = 0; i < this.cbicContentDtls.length; i++) {
          let maxlen = this.cbicContentDtls[i].contentDtlsName.length;
          if (maxlen > 70) {
            maxlen = 67;
          }
          this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen));
          if (maxlen === 67) {
            this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen)) + "...";
          }
        }
        this.parentContentDtls = this.cbicContentDtls;
        this.parentLink.nativeElement.value = "";
      }
    );
  }

  showChildtContentDtlsWithYear(taxType: any, contentType: any, contentCategory: any, year: any): void {
    if (taxType === "undefined") {
      taxType = "";
    }
    if (contentCategory === "undefined") {
      contentCategory = "";
    }
    if (year === "undefined") {
      year = "";
    }
    this.taxMstService.fetchContentByYear(new CbicContentLink1(taxType, contentType, contentCategory, year)).subscribe(
      (res: HttpResponse<CbicContentDtls[]>) => {
        this.cbicContentDtls = res.body ?? [];
        for (let i = 0; i < this.cbicContentDtls.length; i++) {
          let maxlen = this.cbicContentDtls[i].contentDtlsName.length;
          if (maxlen > 70) {
            maxlen = 67;
          }
          this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen));
          if (maxlen === 67) {
            this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen)) + "...";
          }
        }
        this.childContentDtls = this.cbicContentDtls;
        this.childLink.nativeElement.value = "";
      }
    );
  }

  linkContent(parentContentId: any, childContentId: any): void {
    this.errMsg = "";
    this.successMsg = "";
    if (parentContentId === "undefined" || parentContentId === null || childContentId === "undefined" || childContentId === null) {
      this.errMsg = "Please Select Content No";
    } else {
      if (parentContentId === childContentId) {
        this.errMsg = "You can not link same content";
      } else {
        const parentContent = String(parentContentId).split("_");
        const childContent = String(childContentId).split("_");
        const parentId = parentContent[0];
        const parentType = parentContent[1];
        const childId = childContent[0];
        const childType = childContent[1];
        const contentMap = new CbicContentMap2(Number(parentId), Number(childId), parentType, childType);
        this.contentMapService.linkContent(contentMap).subscribe(
          (res: HttpResponse<CbicContentMapResponse>) => {
            this.cbicContentMapResponse = res.body;
            if (this.cbicContentMapResponse?.linkResult) {
              this.successMsg = "Link between contents is successful";
            } else {
              this.successMsg = "Link is already exist";
            }
          }

        );
      }

    }
  }

  unlinkContent(parentContentId: any, childContentId: any): void {
    this.errMsg = "";
    this.successMsg = "";
    if (parentContentId === "undefined" || parentContentId === null || childContentId === "undefined" || childContentId === null) {
      this.errMsg = "Please Select Content No";
    } else {
      if (parentContentId === childContentId) {
        this.errMsg = "You can not unlink same content";
      } else {
        const parentContent = String(parentContentId).split("_");
        const childContent = String(childContentId).split("_");
        const parentId = parentContent[0];
        const parentType = parentContent[1];
        const childId = childContent[0];
        const childType = childContent[1];
        const contentMap = new CbicContentMap2(Number(parentId), Number(childId), parentType, childType);
        this.contentMapService.unlinkContent(contentMap).subscribe(
          (res: HttpResponse<CbicContentMapResponse>) => {
            this.cbicContentMapResponse = res.body;
            if (this.cbicContentMapResponse?.linkResult) {
              this.successMsg = "UnLink between contents is successful";
            } else {
              this.successMsg = "Link does not exist between these contents";
            }
          }

        );
      }

    }
  }

  showLink(contentName: any): void {
    this.errMsg = "";
    if (contentName === "" || contentName === "undefined") {
      this.errMsg = "Please select Content Name"
    } else {
      this.taxMstService.fetchLink(contentName).subscribe(
        (res: HttpResponse<FetchLink>) => {
          this.fetchLink = res.body;
          if (this.fetchLink !== null) {
            this.parentLink.nativeElement.value = this.fetchLink.link;
          }
        }
      );
    }
  }

  showChildLink(contentName: any): void {
    this.errMsg = "";
    if (contentName === "" || contentName === "undefined") {
      this.errMsg = "Please select Content Name"
    } else {
      this.taxMstService.fetchLink(contentName).subscribe(
        (res: HttpResponse<FetchLink>) => {
          this.fetchLink = res.body;
          if (this.fetchLink !== null) {
            this.childLink.nativeElement.value = this.fetchLink.link;
          }
        }
      );
    }
  }

  copyInputMessage(inputElement: ElementRef): void {
    inputElement.nativeElement.select();
    document.execCommand('copy');
    inputElement.nativeElement.setSelectionRange(0, 0);
  }

  copyParentInputMsg():void{
    this.parentLink.nativeElement.select();
    document.execCommand('copy');
    this.parentLink.nativeElement.setSelectionRange(0, 0);
  }

  copyChildInputMsg():void{
    this.childLink.nativeElement.select();
    document.execCommand('copy');
    this.childLink.nativeElement.setSelectionRange(0, 0);
  }

  showParentContentCategoryAndName(category:any, taxType:any):void{
    if (taxType === "undefined") {
      taxType = "";
    }
    if (category === "undefined") {
      category = "";
    }
    this.taxMstService.fetchRegulationContent(new CbicContentLink2(taxType, category)).subscribe(
      (res : HttpResponse<RegulationDtls>) => {
        if(res.body){
          this.cbicContentDtls = res.body.contentDtls;
          this.cbicContentHeader = res.body.contentHeaders;
          for (let i = 0; i < this.cbicContentHeader.length; i++) {
            let maxlen = this.cbicContentHeader[i].contentName.length;
            if (maxlen > 70) {
              maxlen = 67;
            }
            this.cbicContentHeader[i].contentNameLable = String(this.cbicContentHeader[i].contentName.substring(0, maxlen));
            if (maxlen === 67) {
              this.cbicContentHeader[i].contentNameLable = String(this.cbicContentHeader[i].contentName.substring(0, maxlen)) + "...";
            }
          }
          for (let i = 0; i < this.cbicContentDtls.length; i++) {
            let maxlen = this.cbicContentDtls[i].contentDtlsName.length;
            if (maxlen > 70) {
              maxlen = 67;
            }
            this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen));
            if (maxlen === 67) {
              this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen)) + "...";
            }
          }
          this.parentContentHeader = this.cbicContentHeader;
          this.parentContentDtls = this.cbicContentDtls;
        }
      }
    );
  }

  showChildContentCategoryAndName(category:any, taxType:any):void{
    if (taxType === "undefined") {
      taxType = "";
    }
    if (category === "undefined") {
      category = "";
    }
    this.taxMstService.fetchRegulationContent(new CbicContentLink2(taxType, category)).subscribe(
      (res : HttpResponse<RegulationDtls>) => {
        if(res.body){
          this.cbicContentDtls = res.body.contentDtls;
          this.cbicContentHeader = res.body.contentHeaders;
          for (let i = 0; i < this.cbicContentHeader.length; i++) {
            let maxlen = this.cbicContentHeader[i].contentName.length;
            if (maxlen > 70) {
              maxlen = 67;
            }
            this.cbicContentHeader[i].contentNameLable = String(this.cbicContentHeader[i].contentName.substring(0, maxlen));
            if (maxlen === 67) {
              this.cbicContentHeader[i].contentNameLable = String(this.cbicContentHeader[i].contentName.substring(0, maxlen)) + "...";
            }
          }
          for (let i = 0; i < this.cbicContentDtls.length; i++) {
            let maxlen = this.cbicContentDtls[i].contentDtlsName.length;
            if (maxlen > 70) {
              maxlen = 67;
            }
            this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen));
            if (maxlen === 67) {
              this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen)) + "...";
            }
          }
          this.childContentHeader = this.cbicContentHeader;
          this.childContentDtls = this.cbicContentDtls;
        }
      }
    );
  }

}