import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { SpinnerService } from "./spinner.service";

@Component({
    selector:'cbic-spinner',
    templateUrl: './spinner.component.html',
})
export class SpinnerComponent implements OnInit{

    showSpinner = false;
    
    constructor(public spinnerService : SpinnerService, private cdRef: ChangeDetectorRef){}

    ngOnInit(): void {
        this.init();
    }

    init():void{
        this.spinnerService.getSpinnerObserver().subscribe(
            (status) => {
                this.showSpinner = status === 'start';
                this.cdRef.detectChanges();
            }
        )
    }

}