import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { Search } from 'app/core/request/request.model';
import { ICbicActMst, getCbicActMstIdentifier } from '../cbic-act-mst.model';

export type EntityResponseType = HttpResponse<ICbicActMst>;
export type EntityArrayResponseType = HttpResponse<ICbicActMst[]>;

@Injectable({ providedIn: 'root' })
export class CbicActMstService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/cbic-act-msts');
  public resourceSearchUrl = this.applicationConfigService.getEndpointFor('api/_search/cbic-act-msts');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(cbicActMst: ICbicActMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicActMst);
    return this.http
      .post<ICbicActMst>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(cbicActMst: ICbicActMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicActMst);
    return this.http
      .put<ICbicActMst>(`${this.resourceUrl}/${getCbicActMstIdentifier(cbicActMst) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(cbicActMst: ICbicActMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicActMst);
    return this.http
      .patch<ICbicActMst>(`${this.resourceUrl}/${getCbicActMstIdentifier(cbicActMst) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICbicActMst>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }
  findActList(taxId:number):Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicActMst[]>(`${this.resourceUrl}/fetchActs/${taxId}`, {  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicActMst[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  search(req: Search): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicActMst[]>(this.resourceSearchUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  addCbicActMstToCollectionIfMissing(
    cbicActMstCollection: ICbicActMst[],
    ...cbicActMstsToCheck: (ICbicActMst | null | undefined)[]
  ): ICbicActMst[] {
    const cbicActMsts: ICbicActMst[] = cbicActMstsToCheck.filter(isPresent);
    if (cbicActMsts.length > 0) {
      const cbicActMstCollectionIdentifiers = cbicActMstCollection.map(cbicActMstItem => getCbicActMstIdentifier(cbicActMstItem)!);
      const cbicActMstsToAdd = cbicActMsts.filter(cbicActMstItem => {
        const cbicActMstIdentifier = getCbicActMstIdentifier(cbicActMstItem);
        if (cbicActMstIdentifier == null || cbicActMstCollectionIdentifiers.includes(cbicActMstIdentifier)) {
          return false;
        }
        cbicActMstCollectionIdentifiers.push(cbicActMstIdentifier);
        return true;
      });
      return [...cbicActMstsToAdd, ...cbicActMstCollection];
    }
    return cbicActMstCollection;
  }

  protected convertDateFromClient(cbicActMst: ICbicActMst): ICbicActMst {
    return Object.assign({}, cbicActMst, {
      issueDt: cbicActMst.issueDt?.isValid() ? cbicActMst.issueDt.toJSON() : undefined,
      amendDt: cbicActMst.amendDt?.isValid() ? cbicActMst.amendDt.toJSON() : undefined,
      createdDt: cbicActMst.createdDt?.isValid() ? cbicActMst.createdDt.toJSON() : undefined,
      updatedDt: cbicActMst.updatedDt?.isValid() ? cbicActMst.updatedDt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.issueDt = res.body.issueDt ? dayjs(res.body.issueDt) : undefined;
      res.body.amendDt = res.body.amendDt ? dayjs(res.body.amendDt) : undefined;
      res.body.createdDt = res.body.createdDt ? dayjs(res.body.createdDt) : undefined;
      res.body.updatedDt = res.body.updatedDt ? dayjs(res.body.updatedDt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((cbicActMst: ICbicActMst) => {
        cbicActMst.issueDt = cbicActMst.issueDt ? dayjs(cbicActMst.issueDt) : undefined;
        cbicActMst.amendDt = cbicActMst.amendDt ? dayjs(cbicActMst.amendDt) : undefined;
        cbicActMst.createdDt = cbicActMst.createdDt ? dayjs(cbicActMst.createdDt) : undefined;
        cbicActMst.updatedDt = cbicActMst.updatedDt ? dayjs(cbicActMst.updatedDt) : undefined;
      });
    }
    return res;
  }
}
