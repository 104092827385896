import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { Search } from 'app/core/request/request.model';
import { ICbicFormMst, getCbicFormMstIdentifier, CbicFormMstCategory, CbicFormMst, FormResponse } from '../cbic-form-mst.model';
import { saveAs } from "file-saver";

export type EntityResponseType = HttpResponse<ICbicFormMst>;
export type EntityArrayResponseType = HttpResponse<ICbicFormMst[]>;
export type CbicFormMstCategoryResponseType = HttpResponse<CbicFormMstCategory[]>;


@Injectable({ providedIn: 'root' })
export class CbicFormMstService {

  formRes: any;
  
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/cbic-form-msts');
  public resourceSearchUrl = this.applicationConfigService.getEndpointFor('api/_search/cbic-form-msts');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(cbicFormMst: ICbicFormMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicFormMst);
    return this.http
      .post<ICbicFormMst>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(cbicFormMst: ICbicFormMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicFormMst);
    return this.http
      .put<ICbicFormMst>(`${this.resourceUrl}/${getCbicFormMstIdentifier(cbicFormMst) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(cbicFormMst: ICbicFormMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicFormMst);
    return this.http
      .patch<ICbicFormMst>(`${this.resourceUrl}/${getCbicFormMstIdentifier(cbicFormMst) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  findFormsList(taxId:number):Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicFormMst[]>(`${this.resourceUrl}/fetchForms/${taxId}`, {  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  findFormsCategory(taxId:number):Observable<CbicFormMstCategoryResponseType>{
    return this.http
      .get<CbicFormMstCategory[]>(`${this.resourceUrl}/fetchFormsCategory/${taxId}`, {  observe: 'response' });
  }
  
  findFormByFormCategory(taxId: number, formCategory: string): Observable<EntityArrayResponseType> {
    return this.http
      .get<ICbicFormMst[]>(`${this.resourceUrl}/findFormByFormCategory/${taxId}/${formCategory}`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  findFormsById(formId:number):Observable<EntityArrayResponseType>{
    return this.http
      .get<CbicFormMstCategory[]>(`${this.resourceUrl}/findFormsById/${formId}`, {  observe: 'response' });
  }

  findFormsCategoryById(formId:number):Observable<EntityArrayResponseType>{
    return this.http
      .get<CbicFormMstCategory[]>(`${this.resourceUrl}/findFormsCategoryById/${formId}`, {  observe: 'response' });
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICbicFormMst>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicFormMst[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  search(req: Search): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicFormMst[]>(this.resourceSearchUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }


  downloadForm(formId: number):Observable<FormResponse>{
    return this.http.get<FormResponse>(`/api/cbic-form-msts/download/${formId}`);
 }

 downloadFormHindiEnglish(formId: number , language: string):Observable<FormResponse>{
  return this.http.get<FormResponse>(`/api/cbic-form-msts/download/${formId}/${language}`);
}

 

saveForm(formRes: any) : Observable<Blob>{
    const url = 'content/html/' + String(formRes.contentFilePath);
    return this.http.get( url, {responseType : 'blob'});
}

  addCbicFormMstToCollectionIfMissing(
    cbicFormMstCollection: ICbicFormMst[],
    ...cbicFormMstsToCheck: (ICbicFormMst | null | undefined)[]
  ): ICbicFormMst[] {
    const cbicFormMsts: ICbicFormMst[] = cbicFormMstsToCheck.filter(isPresent);
    if (cbicFormMsts.length > 0) {
      const cbicFormMstCollectionIdentifiers = cbicFormMstCollection.map(cbicFormMstItem => getCbicFormMstIdentifier(cbicFormMstItem)!);
      const cbicFormMstsToAdd = cbicFormMsts.filter(cbicFormMstItem => {
        const cbicFormMstIdentifier = getCbicFormMstIdentifier(cbicFormMstItem);
        if (cbicFormMstIdentifier == null || cbicFormMstCollectionIdentifiers.includes(cbicFormMstIdentifier)) {
          return false;
        }
        cbicFormMstCollectionIdentifiers.push(cbicFormMstIdentifier);
        return true;
      });
      return [...cbicFormMstsToAdd, ...cbicFormMstCollection];
    }
    return cbicFormMstCollection;
  }

  protected convertDateFromClient(cbicFormMst: ICbicFormMst): ICbicFormMst {
    return Object.assign({}, cbicFormMst, {
      createdDt: cbicFormMst.createdDt?.isValid() ? cbicFormMst.createdDt.toJSON() : undefined,
      updatedDt: cbicFormMst.updatedDt?.isValid() ? cbicFormMst.updatedDt.toJSON() : undefined,
      issueDt: cbicFormMst.issueDt?.isValid() ? cbicFormMst.issueDt.toJSON() : undefined,
      amendDt: cbicFormMst.amendDt?.isValid() ? cbicFormMst.amendDt.toJSON() : undefined,
      enactmentDt: cbicFormMst.enactmentDt?.isValid() ? cbicFormMst.enactmentDt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDt = res.body.createdDt ? dayjs(res.body.createdDt) : undefined;
      res.body.updatedDt = res.body.updatedDt ? dayjs(res.body.updatedDt) : undefined;
      res.body.issueDt = res.body.issueDt ? dayjs(res.body.issueDt) : undefined;
      res.body.amendDt = res.body.amendDt ? dayjs(res.body.amendDt) : undefined;
      res.body.enactmentDt = res.body.enactmentDt ? dayjs(res.body.enactmentDt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((cbicFormMst: ICbicFormMst) => {
        cbicFormMst.createdDt = cbicFormMst.createdDt ? dayjs(cbicFormMst.createdDt) : undefined;
        cbicFormMst.updatedDt = cbicFormMst.updatedDt ? dayjs(cbicFormMst.updatedDt) : undefined;
        cbicFormMst.issueDt = cbicFormMst.issueDt ? dayjs(cbicFormMst.issueDt) : undefined;
        cbicFormMst.amendDt = cbicFormMst.amendDt ? dayjs(cbicFormMst.amendDt) : undefined;
        cbicFormMst.enactmentDt = cbicFormMst.enactmentDt ? dayjs(cbicFormMst.enactmentDt) : undefined;
      });
    }
    return res;
  }
}
