import * as dayjs from 'dayjs';
import { ICbicCircularMst } from 'app/entities/cbic-circular-mst/cbic-circular-mst.model';
import { ICbicNotificationMst } from 'app/entities/cbic-notification-mst/cbic-notification-mst.model';
import { ICbicTaxMst } from 'app/entities/cbic-tax-mst/cbic-tax-mst.model';

export interface ICbicAttachmentDtls {
  id?: number;
  isActive?: string | null;
  attachmentName?: string | null;
  docFilePath?: string | null;
  docFileName?: string | null;
  createdDt?: dayjs.Dayjs | null;
  updatedDt?: dayjs.Dayjs | null;
  createdBy?: number | null;
  updatedBy?: number | null;
  attachmentType?: string | null;
  circular?: ICbicCircularMst | null;
  notification?: ICbicNotificationMst | null;
  tax?: ICbicTaxMst | null;
  attachmentContentId?: number;
}

export class CbicAttachmentDtls implements ICbicAttachmentDtls {
  constructor(
    public id?: number,
    public isActive?: string | null,
    public attachmentName?: string | null,
    public docFilePath?: string | null,
    public docFileName?: string | null,
    public createdDt?: dayjs.Dayjs | null,
    public updatedDt?: dayjs.Dayjs | null,
    public createdBy?: number | null,
    public updatedBy?: number | null,
    public attachmentType?: string | null,
    public circular?: ICbicCircularMst | null,
    public notification?: ICbicNotificationMst | null,
    public tax?: ICbicTaxMst | null,
    public attachmentContentId?: number
  ) {}
}

export function getCbicAttachmentDtlsIdentifier(cbicAttachmentDtls: ICbicAttachmentDtls): number | undefined {
  return cbicAttachmentDtls.id;
}
