import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ProfileService } from 'app/layouts/profiles/profile.service';
import { CbicSearchInput1, ICbicSearchInput } from 'app/searchbar/searchbar.model';
import { faSearch} from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import { CbicSearchOutputResult } from 'app/search-page/search-output.model';
import { CbicSearchService } from 'app/search-page/service/cbic-search-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'cbic-content-search-bar',
  templateUrl: './content-search-bar.component.html',
  styleUrls: ['./content-search-bar.component.scss']
})
export class ContentSearchBarComponent implements OnInit {
  inProduction?: boolean;
  openAPIEnabled?: boolean;
  @Input()  actList:any;
  @Input() chapterList:any;
  @Input() sectionList:any;
  @Input() searchActId:any;
  @Output() actSelected = new EventEmitter<string>();
  @Output() searchFired= new EventEmitter<string>();
  @Output() chapterSelected= new EventEmitter<string>();
  @Output() clearFired= new EventEmitter<string>();
  @Output() sectionSelected = new EventEmitter<{sectionId : string, index: number}>();
  faTimesCircle=faTimesCircle;
  searched=false;
  faSearch=faSearch;
  selectedChapter="";
  selectedAct:any;
  @ViewChild("contentSearchKeyword") contentKeyword? : ElementRef;
  searchBarForm = this.fb.group({
    keyword : "",
    actId : "",
    chapterId : "",
    sectionId:""
  });
  searchBarFormAll = this.fb.group({
    keywordAll : "",
  });

  @ViewChild("ruleSearchKeyword") ruleKeyword? : ElementRef;
  @Input() taxName: any;
  @ViewChild('allSearch', { static: true }) allSearchTextModal!: ElementRef;
  allSearchMsg: any
  @ViewChild("allRuleSearchKeyword") allRuleSearchKeyword? : ElementRef;
  @Input() taxId:any;
  @Input() activeTab:any
  @Input() tabName:any
  storageLanguage:any

  constructor(
    private router: Router,
    private profileService: ProfileService, private http : HttpClient,
    private fb:FormBuilder,
    private service: CbicSearchService,
    private modalService : NgbModal,
    private sessionStorage: SessionStorageService
  ) { }
  
  ngOnInit(): void {
    this.storageLanguage = this.sessionStorage.retrieve('locale')
    this.profileService.getProfileInfo().subscribe(profileInfo => {
      this.inProduction = profileInfo.inProduction;
      this.openAPIEnabled = profileInfo.openAPIEnabled;
    });

    this.selectedAct = this.searchActId;
  }

  fireActSelected(actId:string):void{
    this.searched=false;
    
    
    this.actSelected.emit(actId);
    
  }
  fireChapterSeleted(chapterId:string):void{
    this.chapterSelected.emit(chapterId);
  }

  search():void{
    
    const cbicSearchInput = this.createFromForm();
    if(cbicSearchInput.keyword === undefined || cbicSearchInput.keyword!.length < 3){
      alert("Please enter valid keyword");
      this.contentKeyword?.nativeElement.focus();
      return;
    }

    cbicSearchInput.keyword = cbicSearchInput.keyword?.trim()
    for(let i=0;i<this.sectionList.length;i++){
      if(String(this.sectionList[i].sectionNo)+'-'+String(this.sectionList[i].sectionName)===cbicSearchInput.sectionId){
        cbicSearchInput.sectionId=this.sectionList[i].id;
      }
    }
    this.searchFired.emit(JSON.stringify(cbicSearchInput));
    this.searched=true;
    //this.router.navigate(['searchPage/search'],{queryParams : cbicSearchInput});
  }

  searchAll():void{
    
    const cbicSearchInput = this.createAllForm();
    if(cbicSearchInput.keyword === undefined || cbicSearchInput.keyword!.length < 3){
      alert("Please enter valid keyword");
      this.ruleKeyword?.nativeElement.focus();
      return;
    }

    cbicSearchInput.keyword = cbicSearchInput.keyword?.trim()

    this.service.pageNumber = 1;
      this.service.searchInSection(cbicSearchInput).subscribe(
        (res: HttpResponse<CbicSearchOutputResult>) => {
          if(res.body?.totalDocuments === 0){
            this.allSearchMsg = "No search result found."
            this.allRuleSearchKeyword?.nativeElement.focus();
            this.searchBarFormAll = this.fb.group({
              keywordAll : ""
            });
            return;
          } else{
            this.modalService.dismissAll()
            this.service.cbicSearchOutputResults = res.body ?? new CbicSearchOutputResult([], null);
            this.router.navigate(['searchPage/search'],{queryParams : cbicSearchInput});
            //const url = this.router.serializeUrl(this.router.createUrlTree(['searchPage/search'],{queryParams : cbicSearchInput}));

            //window.open(url, '_blank');
          }
         
        });

  }
  
  clear():void{
    this.searched=false;
    
    this.searchBarForm = this.fb.group({
      keyword : "",
      actId : "",
      chapterId : "",
      sectionId:""
    });
    this.clearFired.emit();
    return;
  }

  setActId(actId : any):void{
    this.selectedAct = actId;
  }

  fireSectionSelected(sectionName : string):void{
    let sectionId = 0;
    let indexI =0;
    // alert(sectionName)
    if(sectionName !== ""){
      for(let i=0;i<this.sectionList.length;i++){
        if(this.storageLanguage==='en')
        {
        if(sectionName === String(this.sectionList[i].sectionNo)+'-'+String(this.sectionList[i].sectionName)){
          sectionId = this.sectionList[i].id;
          indexI = i;
        }
      }else{
        if(sectionName === String(this.sectionList[i].sectionNoHi)+'-'+String(this.sectionList[i].sectionNameHi)){
          sectionId = this.sectionList[i].id;
          indexI = i;
        }
      }
      }
      this.sectionSelected.emit({sectionId:String(sectionId), index : indexI});
    }
    
    // alert(sectionId)
    
  }

  openAllSearch(): void{
    //this.modalService.open(this.allSearchTextModal,{centered: true});
    this.modalService.open(this.allSearchTextModal,{windowClass : "", centered : true});
  }

  protected createFromForm(): ICbicSearchInput {
    return {
      ...new CbicSearchInput1(),
      keyword: (this.searchBarForm.get(['keyword'])!.value === null || this.searchBarForm.get(['keyword'])!.value === undefined)? null : this.searchBarForm.get(['keyword'])!.value.trim(),
      actId: this.searchBarForm.get(['actId'])!.value,
      chapterId: this.searchBarForm.get(['chapterId'])!.value,
      sectionId: this.searchBarForm.get(['sectionId'])!.value,

    };
  }

  protected createAllForm(): ICbicSearchInput {
    return {
      ...new CbicSearchInput1(),
      keyword: (this.searchBarFormAll.get(['keywordAll'])!.value === null || this.searchBarFormAll.get(['keywordAll'])!.value === undefined) ? null : this.searchBarFormAll.get(['keywordAll'])!.value.trim(),
      content: "ACTS",
      taxId: this.taxId,
      startIndex: 0,
      paginationFlag: true,
      rows: 10
    };
  }
  
}
