import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { ApplicationConfigService } from '../config/application-config.service';
import { SpinnerService } from 'app/layouts/spinner/spinner.service';
import { tap } from 'rxjs/operators';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(
		private localStorage: LocalStorageService,
		private sessionStorage: SessionStorageService,
		private applicationConfigService: ApplicationConfigService,
		private spinnerService : SpinnerService
	) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const serverApiUrl = this.applicationConfigService.getEndpointFor('');
		if (!request.url || (request.url.startsWith('http') && !(serverApiUrl && request.url.startsWith(serverApiUrl)))) {
			return next.handle(request);
		}

		const token: string | null = this.localStorage.retrieve('authenticationToken') ?? this.sessionStorage.retrieve('authenticationToken');

		const tokenHomePage: string | null = this.localStorage.retrieve('authenticationTokenHomePage');
		let language='en';
		if(null!=this.sessionStorage.retrieve('locale')){

		
			 language=this.sessionStorage.retrieve('locale');
		}
		if (token) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${token}`,
					language
					
				},
			});
		}

		if (tokenHomePage) {
			request = request.clone({
				setHeaders: {
					Authorization1: `homeToken ${tokenHomePage}`,
					language
				},
			});
		}

		this.spinnerService.requestStarted();
		
		return next.handle(request).pipe(
			tap(undefined, (error)=>{
				this.spinnerService.requestEnded();
			},
				()=>{
					this.spinnerService.requestEnded();
				}
			)
		);
		
	}

	
}
