<div class="page-header">
    <div class="container-fluid">
        <div class="row">
            <h5 style="font-size: 14px;"><label cbicTranslate="homeButton"> Home</label> <span style="color: #E19F20;">>></span> {{taxName}} <span> </span>
                {{tabName === "Instructions" ? "Instructions / Guidelines" : tabName}}</h5>
            <div class="col-8 wrapTitleUnderline">
                <h4> {{actName}}</h4>
            </div>
            <!-- <div class="col-8 wrapTitleUnderline" *ngIf="activeTab==='Rules'">
                <h4>{{taxName}} Rules Information - {{ruleDocName}}</h4>
            </div> -->
            <div class="col-4 updted-dt" *ngIf="selectedTab==='Acts' && taxId !== 1000004">
                <b cbicTranslate="enactmentDate">Enactment Date:</b> {{enactmentDt}} 
                
                <!-- | <b cbicTranslate="lastUpdated">Last
                    Updated:</b> {{lastUpdatedDt}} -->
            </div>
            <!-- <div class="col-4 updted-dt" *ngIf="selectedTab==='Rules' && taxId !== 1000004">
                <b cbicTranslate="issueDate">Issue Date:</b> {{enactmentDt}} | <b cbicTranslate="lastUpdated">Last Updated:</b>
                {{lastUpdatedDt}}
            </div>
            <div class="col-4 updted-dt" *ngIf="selectedTab==='Regulations' && taxId !== 1000004">
                <b cbicTranslate="issueDate">Issue Date:</b> {{enactmentDt}} | <b cbicTranslate="lastUpdated">Last Updated:</b>
                {{lastUpdatedDt}}
            </div> -->
            <!-- <div *ngIf="(activeTab==='Acts' || activeTab ==='Rules' || activeTab ==='Regulations') && taxId !== 1000004"> -->
                <!-- <div class="col-12 updted-dt" *ngIf="(activeTab==='Acts' || activeTab ==='Rules' || activeTab ==='Regulations') && taxId !== 1000004">
                    <p *ngIf="(fullPdfPath!==null && fullPdfPath!=='')||fullHtmlPath!==null"><b cbicTranslate="viewComplete">View
                            Complete</b>
                        <b>{{tabName.substring(0,tabName.length-1)}}: </b>
                        <span *ngIf="fullPdfPath!==null">PDF<a [routerLink]="" (click)="downloadCompleteDocument(fullPdfPath)"> <i
                                    class="fas fa-file-pdf" aria-hidden="true"></i></a></span>
                        |
                        <span *ngIf="fullHtmlPath!==null">HTML<a [routerLink]=""
                                (click)="downloadCompleteHTMLDocument(fullHtmlPath)"> <i class="fas fa-window-maximize"
                                    aria-hidden="true"></i></a></span>
                        <span> |
                            <a [routerLink]="" (click)="showHistoryModel()">
                                <span style="font-size:14px;text-decoration: underline; font-weight: bold;"
                                    cbicTranslate="amendmenthistory">
                                    Amendment History </span> </a>
                        </span>
                    </p>
                </div> -->
            <!-- </div> -->
        </div>
    </div>
</div>
<div class="content-view">
    <div class="container-fluid">
        <div class="row">
            <div class="card text-center">
                <!--<div class="card-header">
                </div>-->
                <div class="card-body">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="act-tab" data-bs-toggle="tab" data-bs-target="#act"
                                type="button" role="tab" aria-controls="act" aria-selected="true"
                                [ngClass]="{ 'active':activeTab==='Acts' || contentType==='ACTS'}" (click)="loadActs()"
                                cbicTranslate="acts">Acts</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="rules-tab" data-bs-toggle="tab" data-bs-target="#rules"
                                type="button" role="tab" aria-controls="rules" aria-selected="false"
                                [ngClass]="{ 'active':activeTab==='Rules' || contentType==='RULES'}"
                                (click)="loadRules()" cbicTranslate="rules">Rules</button>
                        </li>

                        <li class="nav-item" role="presentation" *ngIf="taxName!=='GST' && taxName !== 'जीएसटी' && taxName!=='Service Tax' && taxName!=='सेवा कर'">
                            <button class="nav-link" id="regulations-tab" data-bs-toggle="tab"
                                data-bs-target="#regulations" type="button" role="tab" aria-controls="regulations"
                                [ngClass]="{ 'active':activeTab==='Regulations'}" aria-selected="false"
                                (click)="loadRegulations()" cbicTranslate="regulations">Regulations</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="forms-tab" data-bs-toggle="tab" data-bs-target="#forms"
                                type="button" role="tab" aria-controls="forms" aria-selected="false"
                                [ngClass]="{ 'active':activeTab==='Forms'}" (click)="loadForms()"
                                cbicTranslate="forms">Forms</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="notifications-tab" data-bs-toggle="tab"
                                data-bs-target="#notifications" type="button" role="tab" aria-controls="notifications"
                                aria-selected="false" [ngClass]="{ 'active':activeTab==='Notifications'}"
                                (click)="loadNotificationsCategory()"
                                cbicTranslate="notification">Notifications</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="circulars-tab" data-bs-toggle="tab" data-bs-target="#circulars"
                                type="button" role="tab" aria-controls="circulars" aria-selected="false"
                                [ngClass]="{ 'active':activeTab==='Circulars'}" (click)="loadCirculars()"
                                cbicTranslate="circulars">Circulars</button>
                        </li>
                        <!--Instructions and Orders-->
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="instruction-tab" data-bs-toggle="tab"
                                data-bs-target="#instructions" type="button" role="tab" aria-controls="intructions"
                                aria-selected="false" [ngClass]="{ 'active':activeTab==='Instructions'}"
                                (click)="loadInstructions()" cbicTranslate="instructionGuidelines">Instructions /
                                Guidelines</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="orders-tab" data-bs-toggle="tab" data-bs-target="#orders"
                                type="button" role="tab" aria-controls="orders" aria-selected="false"
                                [ngClass]="{ 'active':activeTab==='Orders'}" (click)="loadOrders()"
                                cbicTranslate="orders">Orders</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="allied-tab" data-bs-toggle="tab" data-bs-target="#alliedacts"
                                type="button" role="tab" aria-controls="allied_acts" aria-selected="false"
                                [ngClass]="{ 'active':activeTab==='Allied Acts'}" (click)="loadAlliedActName()"
                                 cbicTranslate="alliedacts" *ngIf="taxId === 1000002">Allied Acts</button>
                        </li>


                        <!--                       
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="forms-tab" data-bs-toggle="tab" data-bs-target="#forms"
                                type="button" role="tab" aria-controls="forms"
                                aria-selected="false">Forms</button>
                        </li>   -->
                        <!--
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="notifications-tab" data-bs-toggle="tab"
                                data-bs-target="#notifications" type="button" role="tab"
                                aria-controls="notifications" aria-selected="false">Notifications</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="circulars-tab" data-bs-toggle="tab"
                                data-bs-target="#circulars" type="button" role="tab" aria-controls="circulars"
                                aria-selected="false">Circulars / Orders</button>
                        </li>-->
                    </ul>

                    <div class="tab-content" id="myTabContent"
                        *ngIf="taxName!=='Service Tax' && taxName!=='सेवा कर' || (activeTab ==='Notifications' || activeTab ==='Circulars' || activeTab === 'Instructions' || activeTab === 'Orders')">
                        <div class="tab-pane fade show active" id="act" role="tabpanel" aria-labelledby="act-tab"
                            [ngClass]="{ 'active':activeTab==='Acts' || contentType==='ACTS' }">
                            <div class="card text-center">
                                <div class="card-header">
                                    <cbic-content-search-bar [actList]="actListFetched"
                                        [chapterList]="chapterListFetched" [sectionList]="allSectionList"
                                        [searchActId]="searchActId" (actSelected)="actSelected($event)"
                                        (chapterSelected)="fetchSectionRelatedChapter($event)"
                                        (searchFired)="search($event)" (clearFired)="clearSearchBar()"
                                        (sectionSelected)="fetchSectionSelection($event)" [taxName]="taxName"
                                        [taxId]="taxIdRuleCategory" [activeTab]="activeTab"
                                        [tabName]="tabName"></cbic-content-search-bar>
                                </div>
                            </div>
                            <div class="container" style="margin: 0%;padding: 0px;">
                                <div class="row" *ngIf="!searchNotFound">

                                    <div #sidebar1 class="content-col col-lg-3 col-md-12" id="sidebar1">
                                        <!-- <cbic-section-chapter-navigation [chapterList]="chapterListFetched"
                                            (someEvent)="fetchSectionDataContent($event)"
                                            [selectedTab]="'Acts'"
                                            (expandReadArea)="expandReadArea(sidebar1,readingArea,expandChViewBtn)"
                                            (selectChapterEvent)="selectChapterDtls($event)"
                                            [viewByAllSectionList]="viewByAllSectionList"
                                            (selectSectionEvent)="selectSectionDtls($event)" 
                                            (switchEvent)="selectSwitchEvent($event)" 
                                            (goToNxtSection)="goToNxtSection()"
                                            (goToPrvsSection)="goToPrvsSection()"
                                            (gotoPreviousChapter)="gotoPreviousChapter()" 
                                            (gotoNextChapter)="goToNextChapter()"
                                            [activeReadingNum]="activeReadingSectionNum"
                                            [totalChapter]="totalChapterForActs"
                                            [totalSection]="totalSectionForActs">
                                        </cbic-section-chapter-navigation> -->
                                        <cbic-section-chapter-navigation [chapterList]="chapterListFetched"
                                            (someEvent)="fetchSectionDataContent($event)" [selectedTab]="'Acts'"
                                            (selectChapterEvent)="selectChapterDtls($event)"
                                            [viewByAllSectionList]="viewByAllSectionList"
                                            (selectSectionEvent)="selectSectionDtls($event)"
                                            (switchEvent)="selectSwitchEvent($event)"
                                            (goToNxtSection)="goToNxtSection()" (goToPrvsSection)="goToPrvsSection()"
                                            (gotoPreviousChapter)="gotoPreviousChapter()"
                                            (gotoNextChapter)="goToNextChapter()"
                                            [activeReadingNum]="activeReadingSectionNum"
                                            [totalChapter]="totalChapterForActs" [totalSection]="totalSectionForActs">
                                        </cbic-section-chapter-navigation>
                                    </div>

                                    <div #readingArea class="content-col col-lg-9 col-md-12" id="readingArea">
                                        <!-- <a> <button #expandChViewBtn class="btn float-end expandChapterViewBtn"
                                                id="expandChViewBtn" role="button"
                                                (click)="expandChViewArea(sidebar1,readingArea,expandChViewBtn)"
                                                style="display:none" data-toggle="tooltip" data-placement="top"
                                                title="View Chapters">
                                                <span class="input-group-text"><i class="fa fa-arrow-right"
                                                        aria-hidden="true"></i></span>
                                            </button></a> -->
                                        <!--<a  [routerLink]="" class="sectionLink" (click)="openSection('1')">openSectiond</a>-->
                                        <cbic-section-read [sectionList]="sectionDataFetched"
                                            [searchKeyWord]="searchKeyWord" [currentSection]="currentSection"
                                            [sectionListLengthRead]="sectionListLengthRead"
                                            (openSectionEvent)="openSection($event)"
                                            (gotoPreviousChapter)="gotoPreviousChapter()"
                                            (gotoNextChapter)="goToNextChapter()" [viewSection]="viewSection"
                                            [viewBy]="sectionViewBy" [hostAddress]="hostAddress" [selectedTab]="'Acts'"
                                            [selectedChapterNo]="selectedChapterNoAct"
                                            [selectedChapterName]="selectedChapterNameAct"
                                            [selectedChapterNoHi]="selectedChapterNoActHi"
                                            [selectedChapterNameHi]="selectedChapterNameActHi"
                                            [getChapterName]="getChapterName" [contentName]="actName">
                                        </cbic-section-read>
                                    </div>




                                </div>
                            </div>
                            <div class="container">
                                <div class="row">
                                    <div *ngIf="searchNotFound" #searchError class="col-md-12 searchNotFound">
                                        <i class="fas fa-exclamation-triangle" style="color:grey;font-size: 40px;"></i>
                                        <p>No search result found.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show" id="rules" role="tabpanel" aria-labelledby="rules-tab"
                            [ngClass]="{ 'active':activeTab==='Rules' || contentType==='RULES'}">
                            <div class="card text-center">
                                <div class="card-header">
                                    <cbic-rules-content-search-bar [rulesListFetched]="rulesListFetched"
                                        [rulesChapterList]="ruleChapterListFetched"
                                        (ruleSelected)="ruleSelected($event)"
                                        (chapterSelectedForRules)="fetchChapterRelatedRules($event)"
                                        [searchRuleId]="searchRuleId"
                                        (sectionSelected)="fetchRuleSectionSelection($event)"
                                        [ruleSectionList]="allRulesSection" (searchFiredRules)="searchForRules($event)"
                                        (clearFiredRules)="clearSearchBarRules()"
                                        [rulesCategoryList]="rulesCategoryListFetched"
                                        (categorySelected)="fetchCategoryRelatedRules($event)"
                                        [taxId]="taxIdRuleCategory" [searchKeyWord]="searchKeyWord" [taxName]="taxName"
                                        [activeTab]="activeTab" [tabName]="tabName">
                                    </cbic-rules-content-search-bar>
                                </div>
                                <div *ngIf="noRules" class="col-md-12 noRules">
                                    <i class="fas fa-info-circle" style="color:grey;font-size: 80px;"></i>
                                    <p>{{taxName}} Rules will be available soon!</p>
                                </div>
                            </div>
                            <div class="container" style="margin: 0%;padding: 0px;">
                                <div class="row" *ngIf="!searchNotFound">
                                    <div #sidebar1 class="content-col col-lg-3 col-md-12" id="sidebar1"
                                        *ngIf="!searchNotFound">
                                        <!-- <cbic-section-chapter-navigation [chapterList]="ruleChapterListFetched"
                                    (someEvent)="fetchRulesData($event)"
                                    [selectedTab]="'Rules'"
                                    (expandReadArea)="expandReadArea(sidebar1,readingArea,expandChViewBtn)"
                                    (selectChapterEvent)="selectChapterDtlsForRules($event)"
                                    [viewByAllSectionList]="viewBySectionAllRuleList"
                                    (selectSectionEvent)="selectRuleSectionDtls($event)" 
                                    (switchEvent)="selectSwitchEvent($event)" 
                                    (goToNxtSection)="goToNxtSection()"
                                    (goToPrvsSection)="goToPrvsSection()"
                                    (gotoPreviousChapter)="gotoPreviousChapter()" 
                                    (gotoNextChapter)="goToNextChapter()"
                                    [activeReadingNum]="activeReadingRuleNum"
                                    [totalChapter]="totalChapterForRules"
                                    [totalSection]="totalSectionForRules">
                                </cbic-section-chapter-navigation> -->
                                        <cbic-section-chapter-navigation [chapterList]="ruleChapterListFetched"
                                            (someEvent)="fetchRulesData($event)" [selectedTab]="'Rules'"
                                            (selectChapterEvent)="selectChapterDtlsForRules($event)"
                                            [viewByAllSectionList]="viewBySectionAllRuleList"
                                            (selectSectionEvent)="selectRuleSectionDtls($event)"
                                            (switchEvent)="selectSwitchEvent($event)"
                                            (goToNxtSection)="goToNxtSection()" (goToPrvsSection)="goToPrvsSection()"
                                            (gotoPreviousChapter)="gotoPreviousChapter()"
                                            (gotoNextChapter)="goToNextChapter()"
                                            [activeReadingNum]="activeReadingRuleNum"
                                            [totalChapter]="totalChapterForRules" [totalSection]="totalSectionForRules"
                                            [tabName]="tabName">
                                        </cbic-section-chapter-navigation>
                                    </div>

                                    <div #readingArea class="content-col col-lg-9 col-md-12" id="readingArea">
                                        <!-- <a> <button #expandChViewBtn class="btn float-end expandChapterViewBtn"
                                        id="expandChViewBtn" role="button"
                                        (click)="expandChViewArea(sidebar1,readingArea,expandChViewBtn)"
                                        style="display:none" data-toggle="tooltip" data-placement="top"
                                        title="View Chapters">
                                        <span class="input-group-text"><i class="fa fa-arrow-right"
                                                aria-hidden="true"></i></span>
                                    </button></a> -->
                                        <!--<a  [routerLink]="" class="sectionLink" (click)="openSection('1')">openSectiond</a>-->
                                        <cbic-section-read [sectionList]="rulesDataFetched"
                                            [searchKeyWord]="searchKeyWord" [currentSection]="currentSection"
                                            (openSectionEvent)="openSection($event)"
                                            (gotoPreviousChapter)="gotoPreviousChapter()"
                                            (gotoNextChapter)="goToNextChapter()"
                                            [viewSection]="viewSelectedRuleSection" [viewBy]="rulesViewBy"
                                            [hostAddress]="hostAddress" [selectedTab]="'Rules'"
                                            [selectedChapterNo]="selectedChapterNoRules"
                                            [selectedChapterName]="selectedChapterNameRules"
                                            [getChapterName]="getChapterNameForRule" [contentName]="actName">
                                        </cbic-section-read>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div *ngIf="searchNotFound" #searchError class="col-md-12 searchNotFound">
                                    <i class="fas fa-exclamation-triangle" style="color:grey;font-size: 40px;"></i>
                                    <p>No search result found.</p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show" id="regulations" role="tabpanel"
                            aria-labelledby="regulations-tab" [ngClass]="{ 'active':activeTab==='Regulations'}">
                            <div class="card text-center">
                                <div class="card-header">
                                    <cbic-regulation-search-bar [regulationDocList]="regulationDocList"
                                        (regulationSelected)="regulationSelected($event)"
                                        [regulationList]="regulationList"
                                        [regulationsCategoryList]="regulationsCategoryList"
                                        (chapterSelected)="fetchChapterRelatedRules($event)"
                                        (regulationDocSelected)="regulationDocSelectedEvent($event)"
                                        [searchRegulationId]="searchRegulationId"
                                        (regulationSearchFired)="regulationSearchFired($event)"
                                        (regulationClearFired)="regulationClearFired()"
                                        (categorySelected)="fetchCategoryRelatedRegulations($event)" [taxName]="taxName"
                                        [taxId]="taxIdRuleCategory" [activeTab]="activeTab" [tabName]="tabName">
                                    </cbic-regulation-search-bar>
                                </div>
                            </div>
                            <div class="container" style="margin: 0%;padding: 0px;">
                                <div class="row" *ngIf="!searchNotFound">
                                    <div #sidebar1 class="content-col col-lg-3 col-md-12" id="sidebar1"
                                        *ngIf="!searchNotFound">
                                        <!-- <cbic-regulation-chapter-navigation [regulationList]="regulationList" (someEvent)="fetchRegulationContent($event)"
                                                                (selectRegulationEvent)="changeTitleOfRegulation($event)">
                                    </cbic-regulation-chapter-navigation> 
                                    -->
                                        <cbic-section-chapter-navigation [chapterList]="totalChapterForRegulations"
                                            (someEvent)="fetchRegulationListforChapter($event)"
                                            [selectedTab]="'Regulations'"
                                            (selectChapterEvent)="selectChapterDtls($event)"
                                            [viewByAllSectionList]="regulationList"
                                            (selectSectionEvent)="selectSectionDtls($event)"
                                            (switchEvent)="selectSwitchEvent($event)"
                                            (goToNxtSection)="goToNxtSection()" (goToPrvsSection)="goToPrvsSection()"
                                            (gotoPreviousChapter)="gotoPreviousChapter()"
                                            (gotoNextChapter)="goToNextChapter()"
                                            [activeReadingNum]="activeReadingRegulationNum"
                                            [totalChapter]="totalChapterForRegulationsLen"
                                            [totalSection]="totalRegulationForRegulation" [tabName]="tabName">
                                        </cbic-section-chapter-navigation>
                                    </div>
                                    <div #readingArea class="content-col col-lg-9 col-md-12" id="readingArea">
                                        <cbic-section-read [sectionList]="regulationListByChapter"
                                            [viewSection]="regulationView" [searchKeyWord]="searchKeyWord"
                                            [selectedTab]="'Regulations'"
                                            [selectedChapterNo]="selectedChapterNoRegulation"
                                            [selectedChapterName]="selectedChapterNameRegulation"
                                            [selectedChapterNoHi]="selectedChapterNoRegulationHi"
                                            [selectedChapterNameHi]="selectedChapterNameRegulationHi"
                                            [viewBy]="regulationViewBy" [hostAddress]="hostAddress"
                                            [getChapterName]="getChapterNameForRegulation" [contentName]="actName">
                                        </cbic-section-read>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div *ngIf="searchNotFound" #searchError class="col-md-12 searchNotFound">
                                    <i class="fas fa-exclamation-triangle" style="color:grey;font-size: 40px;"></i>
                                    <p>No search result found.</p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show" id="forms" role="tabpanel" aria-labelledby="forms-tab"
                            [ngClass]="{ 'active':activeTab==='Forms'}">
                            <div class="card text-center">
                                <div class="card-header">
                                    <cbic-forms-content-search-bar [formsListFetched]="formsListFetched"
                                        [formsTaxId]="taxId" (formSearchFired)="searchForm($event)"
                                        (formClearFired)="clearForm()" (formSelected)="formSelectFire($event)"
                                        (notiYearSelected)="notiYearSelected($event)" [taxName]="taxName"
                                        [taxId]="taxIdRuleCategory" [activeTab]="activeTab"
                                        [tabName]="tabName">
                                    </cbic-forms-content-search-bar>
                                </div>
                            </div>
                            <div class="row" *ngIf="!searchNotFound">
                                <div class="content-col col-lg-3 col-md-12">
                                    <cbic-forms-navigation [formsCategoryList]="formsCategoryListFetched"
                                        (selectFormEvent)="selectFormDtls($event)"
                                        (someEvent)="fetchFormsforGivenCategory($event)">

                                    </cbic-forms-navigation>
                                </div>
                                <div class="content-col col-lg-9 col-md-12">
                                    <cbic-forms-read [formByCategory]="formByCategoryFetched"></cbic-forms-read>
                                </div>
                            </div>
                            <div class="row">
                                <div *ngIf="searchNotFound" #searchError class="col-md-12 searchNotFound">
                                    <i class="fas fa-exclamation-triangle" style="color:grey;font-size: 40px;"></i>
                                    <p>No search result found.</p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show" id="notifications" role="tabpanel"
                            aria-labelledby="notifications-tab"
                            [ngClass]="{ 'active':activeTab==='Notifications' || contentType==='Notifications'}">
                            <div class="container-fluid">
                                <div class="card-header">
                                    <!-- Changed Starts -->
                                    <cbic-notification-search [notificationCategoryList]="notificationCategoryList"
                                        (categorySelected)="notificationCategorySelected($event)" [taxId]="taxId"
                                        [selectedYear]="notiYearSelected"
                                        (notificationSearch)="searchForNotifications($event)"
                                        (clearnotificationSearch)="clearSearchBarNotifications()"
                                        (notiYearSelected)="notificationYearSelected($event)" [taxName]="taxName"
                                        [activeTab]="activeTab" [tabName]="tabName">
                                    </cbic-notification-search>
                                </div> <!-- Changed Ends -->
                                <div class="row refined-view">
                                    <!--<div class="content-col refineby col-lg-3 col-md-12">
                                   
                                    <h4>Refine By</h4>
                                    <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <div class="accordion-header" id="flush-headingOne">
                                               
                                                <button class="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                                                    aria-expanded="false" aria-controls="flush-collapseOne">
                                                    Notification Category
                                                </button>
                                            </div>
                                            <div id="flush-collapseOne" class="accordion-collapse collapse"
                                                aria-labelledby="flush-headingOne"
                                                data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body text-start">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value=""
                                                            id="defaultCheck1">
                                                        <label class="form-check-label" for="defaultCheck1">
                                                            Tariff
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value=""
                                                            id="defaultCheck2">
                                                        <label class="form-check-label" for="defaultCheck2">
                                                            Non - Tariff
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value=""
                                                            id="defaultCheck3">
                                                        <label class="form-check-label" for="defaultCheck3">
                                                            Safeguards
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value=""
                                                            id="defaultCheck4">
                                                        <label class="form-check-label" for="defaultCheck4">
                                                            Anti Dumping Duty
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value=""
                                                            id="defaultCheck5">
                                                        <label class="form-check-label" for="defaultCheck5">
                                                            CVD
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <div class="accordion-header" id="flush-headingTwo">
                                                <button class="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo"
                                                    aria-expanded="false" aria-controls="flush-collapseTwo">
                                                    Notification Period
                                                </button>
                                            </div>
                                            <div id="flush-collapseTwo" class="accordion-collapse collapse"
                                                aria-labelledby="flush-headingTwo"
                                                data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body">
                                                    <span class="input-group-text">From Date
                                                    </span>
                                                    <input class="form-control py-2 border-right-0 border" type="date"
                                                        value="">
                                                    <span class="input-group-text">To Date
                                                    </span>
                                                    <input class="form-control py-2 border-right-0 border" type="date"
                                                        value="">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <div class="accordion-header" id="flush-headingThree">
                                                <button class="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#flush-collapseThree"
                                                    aria-expanded="false" aria-controls="flush-collapseThree">
                                                    Year
                                                </button>
                                            </div>
                                            <div id="flush-collapseThree" class="accordion-collapse collapse"
                                                aria-labelledby="flush-headingTwo"
                                                data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body">
                                                    <input class="form-control" type="year" placeholder="YYYY">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <div class="accordion-header" id="flush-headingFour">
                                                <button class="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseFour" aria-expanded="false"
                                                    aria-controls="flush-collapseFour">
                                                    Information Type
                                                </button>
                                            </div>
                                            <div id="flush-collapseFour" class="accordion-collapse collapse"
                                                aria-labelledby="flush-headingTwo"
                                                data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body text-start">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                                                        <label class="form-check-label" for="defaultCheck1">
                                                            Document
                                                        </label>
                                                      </div>
                                                      <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck2">
                                                        <label class="form-check-label" for="defaultCheck2">
                                                            Form
                                                        </label>
                                                      </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingFive">
                                                <button class="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseFive" aria-expanded="false"
                                                    aria-controls="flush-collapseFive">
                                                    Visitor Intent
                                                </button>
                                            </h2>
                                            <div id="flush-collapseFive" class="accordion-collapse collapse"
                                                aria-labelledby="flush-headingTwo"
                                                data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body text-start">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                                                        <label class="form-check-label" for="defaultCheck1">
                                                            Import
                                                        </label>
                                                      </div>
                                                      <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck2">
                                                        <label class="form-check-label" for="defaultCheck2">
                                                            Export
                                                        </label>
                                                      </div>
                                                      <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck3">
                                                        <label class="form-check-label" for="defaultCheck3">
                                                            Immigration
                                                        </label>
                                                      </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingSix">
                                                <button class="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSix" aria-expanded="false"
                                                    aria-controls="flush-collapseSix">
                                                    Visitor Type
                                                </button>
                                            </h2>
                                            <div id="flush-collapseSix" class="accordion-collapse collapse"
                                                aria-labelledby="flush-headingTwo"
                                                data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body text-start">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                                                        <label class="form-check-label" for="defaultCheck1">
                                                            Personal
                                                        </label>
                                                      </div>
                                                      <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck2">
                                                        <label class="form-check-label" for="defaultCheck2">
                                                            Business
                                                        </label>
                                                      </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>-->
                                    <!-- Changed Ends -->

                                    <div class="content-col result-data col-lg-12 col-md-12">
                                        <!--<div class="text-start result-title"> Showing 5 of 123 results matching
                                            "rate" </div> -->
                                        <!-- BEGIN TABLE RESULT -->
                                        <div class="table-responsive" style="border: none;">
                                            <cbic-notification-read [notificationDtls]="notificationDtls"
                                                [selectedTab]="'Notifications'" [totalRecords]="totalRecordsNotiRead"
                                                [selectedYear]="notiYearSelected" [notiNotFound]="notiNotFound"
                                                [notificationCategory]="notificationCategory"
                                                [searchKeyword]="notifiSearchKeyWord" [taxId]="taxId"
                                                [currentPageInput]="currentPageInputParent"
                                                [ngbPageInput]="ngbPageInputParent"
                                                [showRecordInput]="recordSelectedInputParent">
                                            </cbic-notification-read>

                                        </div>
                                        <!-- END TABLE RESULT -->
                                        <!-- Modal -->
                                        <div class="modal fade" id="section1" tabindex="-1"
                                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">
                                                            <p class="text-center">GOVERNMENT OF INDIA
                                                                MINISTRY OF FINANCE
                                                                (DEPARTMENT OF REVENUE)</p>
                                                        </h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                            aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div class="accordion-body">
                                                            <div class="pagebody">
                                                                <p class="text-center"><b>Ad hoc Exemption Order No.
                                                                        5/2021-Customs</b></p><br />
                                                                <p class="text-left">
                                                                    G.S.R. (E)â€¦- In exercise of the powers conferred
                                                                    by
                                                                    sub-section (2) of section 25 of the Customs
                                                                    Act,
                                                                    1962 (52 of 1962), the Central Government, under
                                                                    exceptional circumstances prevailing due to the
                                                                    COVID-19 pandemic, on being satisfied that it is
                                                                    necessary in the public interest so to do,
                                                                    hereby
                                                                    makes the following amendment in the ad hoc
                                                                    exemption
                                                                    order of the Government of India, in the
                                                                    Ministry of Finance (Department of Revenue), No.
                                                                    4/2021-Customs, dated the 3rd May, 2021,
                                                                    published in the Gazette of India, Extraordinary,
                                                                    Part
                                                                    II, Section 3, Sub-section (i), vide
                                                                    number G.S.R.
                                                                    316(E), dated the 3rd May, 2021, namely:- <br>
                                                                    In the said order, in paragraph 2, for the figures,
                                                                    letters and words â€œ30th day of June, 2021â€,
                                                                    the figures,
                                                                    letters and words â€œ31st day of August 2021â€
                                                                    shall be
                                                                    substituted.
                                                                </p>
                                                                <br>
                                                                <p class="text-right">[F.No.
                                                                    CBIC-190354/37/2021-TO(TRU-I)-CBEC(Pt-I)]<br>
                                                                    (Rajeev Ranjan)<br>
                                                                    Under Secretary to the Government of India</p>

                                                            </div>
                                                            <hr>
                                                            <div class="pagefooter">
                                                                Note: The principal order No. 4/2021-Customs, dated the
                                                                3rd
                                                                May, 2021 was published in the
                                                                Gazette
                                                                of India, Extraordinary vide number G.S.R. 316(E), dated
                                                                the
                                                                3rd May, 2021.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="modal-footer">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show" id="circulars" role="tabpanel" aria-labelledby="circulars-tab"
                            [ngClass]="{ 'active':activeTab==='Circulars' || contentType==='Circulars'}">
                            <div class="container-fluid">
                                <div class="card-header">
                                    <!-- Search Changed Starts -->
                                    <cbic-circular-search-bar (circularSearch)="searchForCirculars($event)"
                                        [taxId]="taxId" [circularCategoryList]="circularCategoryList"
                                        (clearcircularSearch)="clearSearchBarCirculars()"
                                        (categorySelected)="fetchSelectedCategoryCirculars($event)"
                                        (circularYearSelected)="circularYearSelected($event)" [taxName]="taxName"
                                        [activeTab]="activeTab" [tabName]="tabName">
                                    </cbic-circular-search-bar>
                                </div>
                                <div class="row refined-view">
                                    <div class="content-col result-data col-lg-12 col-md-12">
                                        <!--Circular read area-->
                                        <div class="table-responsive" style="border: none;">
                                            <cbic-notification-read [circularList]="circularList"
                                                [selectedTab]="'Circulars'" [circularCategory]="circularCategory"
                                                [totalRecordsCircular]="totalRecordsCircularRead"
                                                [selectedYear]="cirYearSelected" [taxId]="taxId"
                                                [circularNotFound]="circularNotFound"
                                                [currentPageInput]="currentPageInputParent"
                                                [ngbPageInput]="ngbPageInputParent"
                                                [showRecordInput]="recordSelectedInputParent"
                                                [searchKeyword]="circularSearchKeyword"></cbic-notification-read>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show" id="instuctions" role="tabpanel"
                            aria-labelledby="instuctions-tab"
                            [ngClass]="{ 'active':activeTab==='Instructions' || contentType==='Instructions'}">
                            <div class="container-fluid">
                                <div class="card-header">
                                    <!-- Search Changed Starts -->
                                    <cbic-instruction-search-bar (InstructionSearch)="searchForInstructions($event)"
                                        [taxId]="taxId" [instructionCategoryList]="instructionCategoryList"
                                        (clearInstrcutionSearch)="clearSearchBarInstrcutions()"
                                        (categorySelected)="fetchSelectedCategoryInstrcutions($event)"
                                        (instructionYearSelected)="fetchInstructionBasedOnYearSelected($event)"
                                        [taxName]="taxName" [activeTab]="activeTab" [tabName]="tabName">
                                    </cbic-instruction-search-bar>
                                </div>
                                <div class="row refined-view">
                                    <div class="content-col result-data col-lg-12 col-md-12">
                                        <!--Instructions read area-->
                                        <div class="table-responsive" style="border: none;">
                                            <cbic-notification-read [instructionList]="insturctionList"
                                                [selectedTab]="'Instructions'"
                                                [instructionCategory]="instructionCategory"
                                                [totalRecords]="totalRecordsInstructionRead"
                                                [selectedYear]="instYearSelected" [taxId]="taxId"
                                                [instructionNotFound]="instructionNotFound"
                                                [currentPageInput]="currentPageInputParent"
                                                [ngbPageInput]="ngbPageInputParent"
                                                [showRecordInput]="recordSelectedInputParent" [dummy]="dummyTest">
                                            </cbic-notification-read>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show" id="orders" role="tabpanel" aria-labelledby="orders-tab"
                            [ngClass]="{ 'active':activeTab==='Orders' || contentType==='Orders'}">
                            <div class="container-fluid">
                                <div class="card-header">
                                    <!-- Search Changed Starts -->
                                    <cbic-order-search-bar (OrderSearch)="searchForOrders($event)" [taxId]="taxId"
                                        [orderCategoryList]="orderCategoryList"
                                        (clearOrderSearch)="clearSearchBarOrders()"
                                        (categorySelected)="fetchSelectedCategoryOrders($event)"
                                        (orderYearSelected)="fetchOrderBasedOnYearSelected($event)" [taxName]="taxName"
                                        [activeTab]="activeTab" [tabName]="tabName">
                                    </cbic-order-search-bar>
                                </div>
                                <div class="row refined-view">
                                    <div class="content-col result-data col-lg-12 col-md-12">
                                        <!--Orders read area-->
                                        <div class="table-responsive" style="border: none;">
                                            <cbic-notification-read [orderList]="orderList" [selectedTab]="'Orders'"
                                                [orderCategory]="orderCategory" [totalRecords]="totalRecordsOrderRead"
                                                [selectedYear]="ordYearSelected" [taxId]="taxId"
                                                [orderNotFound]="orderNotFound"
                                                [currentPageInput]="currentPageInputParent"
                                                [ngbPageInput]="ngbPageInputParent"
                                                [showRecordInput]="recordSelectedInputParent" [dummy]="dummyTest">
                                            </cbic-notification-read>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <!-- Allied Act  -->
                        <div class="tab-pane fade show" id="allied-tab" role="tabpanel" aria-labelledby="allied-act-tab"
                            [ngClass]="{ 'active':activeTab==='Allied Acts'}">
                            <div class="container-fluid">
                                <div class="card-header">
                                    <!-- Search Changed Starts -->
                                    <cbic-allied-act-search [alliedActNameList]="alliedActNameList" 
                                        (alliedActNameSelected)="alliedActNameSelectedEvent($event)"
                                        [selectedYear]="alliedYearSelected"
                                        (alliedActYearSelected)="alliedActYearSelected($event)"
                                        (alliedActSearch)="searchForAlliedAct($event)"
                                        (clearAlliedActSearch)="clearSearchBarAlliedAct()"
                                         [taxId]="taxId" [taxName]="taxName" [activeTab]="activeTab" [tabName]="tabName">
                                    </cbic-allied-act-search>
                                </div>
                                <div class="row refined-view">
                                    <div class="content-col result-data col-lg-12 col-md-12">
                                        <!--Orders read area-->
                                        <div class="table-responsive" style="border: none;">
                                            <cbic-allied-act-read 
                                                [alliedActDtls]="alliedActDtls"
                                                [selectedTab]="'Allied Acts'" [totalRecords]="totalRecordsAlliedRead"
                                                [selectedYear]="alliedYearSelected" [alliedNotFound]="alliedNotFound"
                                                [alliedActNameCategory]="alliedActNameCategory"
                                                [searchKeyword]="alliedActSearchKeyWord" [taxId]="taxId"
                                                [currentPageInput]="currentPageInputParent"
                                                [ngbPageInput]="ngbPageInputParent"
                                                [showRecordInput]="recordSelectedInputParent">
                                            </cbic-allied-act-read>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                          
                        

                    </div>
                    <div class="tab-content" id="myTabContent"
                        *ngIf="(taxName==='Service Tax' || taxName==='सेवा कर') && (activeTab ==='Acts' || activeTab ==='Rules' || activeTab ==='Forms')">
                        <div class="tab-pane fade show active" id="act" role="tabpanel" aria-labelledby="act-tab"
                            [ngClass]="{ 'active':activeTab==='Acts' || contentType==='ACTS' }">

                            <div class="container" style="margin: 0%;padding: 0px;">
                                <div class="row" *ngIf="!searchNotFound">



                                    <div #readingArea class="content-col col-lg-12 col-md-12" id="readingArea">

                                        <cbic-table-content-display [dataList]="serviceTaxDataList" [dataType]="'Act'">
                                        </cbic-table-content-display>
                                    </div>




                                </div>
                            </div>
                            <div class="container">
                                <div class="row">
                                    <div *ngIf="searchNotFound" #searchError class="col-md-12 searchNotFound">
                                        <i class="fas fa-exclamation-triangle" style="color:grey;font-size: 40px;"></i>
                                        <p>No search result found.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show" id="rules" role="tabpanel" aria-labelledby="rules-tab"
                            [ngClass]="{ 'active':activeTab==='Rules' || contentType==='RULES'}">
                            <div class="card-header">
                                <cbic-service-tax-rule-searchbar [serviceTaxRuleList]="serviceTaxRuleList"
                                    (sectionSelected)="fetchSelectedSTRule($event)" [searchKeyWord]="searchKeyWord"
                                    (clearFiredRules)="clearSearchBarSTRules()"
                                    (searchFiredRules)="searchForSTRules($event)" [rulesTaxId]="taxId">
                                </cbic-service-tax-rule-searchbar>
                            </div>
                            <div class="container" style="margin: 0%;padding: 0px;">
                                <div class="row" *ngIf="!searchNotFound">

                                    <div #readingArea class="content-col col-lg-12 col-md-12" id="readingArea">
                                        <cbic-table-content-display [dataList]="serviceTaxRuleList"
                                            [dataType]="'Rules'">

                                        </cbic-table-content-display>
                                    </div>

                                </div>
                            </div>
                            <div class="container">
                                <div class="row">
                                    <div *ngIf="searchNotFound" #searchError class="col-md-12 searchNotFound">
                                        <i class="fas fa-exclamation-triangle" style="color:grey;font-size: 40px;"></i>
                                        <p>No search result found.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show" id="forms" role="tabpanel" aria-labelledby="forms-tab"
                            [ngClass]="{ 'active':activeTab==='Forms'}">
                            <div class="card text-center">
                                <div class="card-header">
                                    <cbic-forms-content-search-bar [formsListFetched]="formsListFetched"
                                        [formsTaxId]="taxId" (formSearchFired)="searchForm($event)"
                                        (formClearFired)="clearForm()" (formSelected)="formSelectFire($event)"
                                        [taxName]="taxName" [taxId]="taxIdRuleCategory" [activeTab]="activeTab" 
                                        [tabName]="tabName">
                                    </cbic-forms-content-search-bar>
                                </div>
                            </div>
                            <div class="row" *ngIf="!searchNotFound">
                                <div class="content-col col-lg-3 col-md-12">
                                    <cbic-forms-navigation [formsCategoryList]="formsCategoryListFetched"
                                        (selectFormEvent)="selectFormDtls($event)"
                                        (someEvent)="fetchFormsforGivenCategory($event)">

                                    </cbic-forms-navigation>
                                </div>
                                <div class="content-col col-lg-9 col-md-12">
                                    <cbic-forms-read [formByCategory]="formByCategoryFetched"></cbic-forms-read>
                                </div>
                            </div>
                            <div class="row">
                                <div *ngIf="searchNotFound" #searchError class="col-md-12 searchNotFound">
                                    <i class="fas fa-exclamation-triangle" style="color:grey;font-size: 40px;"></i>
                                    <p>No search result found.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #relatedList let-modal>
        <div class="modal-content" style="padding-left:16px; padding-right: 16px; padding-bottom:10px;">
            <div class="modal-header page-header wrapTitleUnderline ">
                <h4 *ngIf="storageLanguage === 'en'" class="modal-title" style="text-align: left;">
                    Amendment History of {{actName}} </h4>
                <h4 *ngIf="storageLanguage === 'hi'" class="modal-title" style="text-align: left;">
                    {{actName}} का संशोधन इतिहास</h4>
                
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')"><i
                        class="fas fa-times"></i>
                </button>
            </div>
            <div class="modal-body">
                <div class="row no-notification" *ngIf="!amendmentHistory || amendmentHistory.length===0"
                    style="margin-top:80px;">
                    <!-- <h4 class="modal-title"  style="text-align: center;" >
                        Amendment History is not available </h4> -->
                    <p *ngIf="!amendmentHistory || amendmentHistory.length===0" cbicTranslate="noAmendmentHistory"> No
                        Amendment History</p>
                </div>
                <div class="tab-content wrap-content">
                    <div class="content-view">
                        <div class="table-responsive" style="border: none;">
                            <table class="table table-hover" *ngIf="amendmentHistory && amendmentHistory.length>0">
                                <tbody>
                                    <tr>
                                        <th style="text-align: center;" cbicTranslate="amendmentDate">Amendment Date
                                        </th>
                                        <th style="text-align: center;" cbicTranslate="pdf">PDF</th>
                                        <th style="text-align: center;" cbicTranslate="html">HTML</th>
                                    </tr>
                                    <tr *ngFor="let tax of amendmentHistory, let i=index">
                                        <td style="text-align: center; width: 10%;">{{tax.amendmentDt |
                                            date:'dd-MMM-yyyy' }}</td>
                                        <td style="text-align: center; width: 10%;">
                                            <a [routerLink]="" (click)="downloadCompleteDocument(tax.pdfFilePath)">
                                                View </a>
                                        </td>
                                        <td style="text-align: center; width: 10%;">
                                            <a [routerLink]="" (click)="downloadCompleteHTMLDocument(tax.htmlFilePath)">
                                                View</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <span class="modal-title" style="text-align: left; font-size: 14px;"
                    cbicTranslate="PleaseNoteAmendment">
                    Please note that Amendment History is available from 2021 Onwards.
                </span>
            </div>
        </div>
    </ng-template>