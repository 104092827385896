import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CbicCoreError } from "./cbiccoreerror.model";

@Injectable({ providedIn: 'root' })
export class CbicErrorService {
    cbicCoreError : CbicCoreError = new CbicCoreError();

    loadCbicCoreError(err:HttpErrorResponse) : void{
       this.cbicCoreError.exceptionMsg = String(err.error.message);
       this.cbicCoreError.excpetionId = String(err.error.Exception_id);
    }
}