import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { Search } from 'app/core/request/request.model';
import { ICbicOrderMst, getCbicOrderMstIdentifier } from '../cbic-order-mst.model';
import { RuleMstResponse } from 'app/entities/cbic-rule-mst/cbic-rule-mst.model';

export type EntityResponseType = HttpResponse<ICbicOrderMst>;
export type EntityArrayResponseType = HttpResponse<ICbicOrderMst[]>;

@Injectable({ providedIn: 'root' })
export class CbicOrderMstService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/cbic-order-msts');
  public resourceSearchUrl = this.applicationConfigService.getEndpointFor('api/_search/cbic-order-msts');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(cbicOrderMst: ICbicOrderMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicOrderMst);
    return this.http
      .post<ICbicOrderMst>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(cbicOrderMst: ICbicOrderMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicOrderMst);
    return this.http
      .put<ICbicOrderMst>(`${this.resourceUrl}/${getCbicOrderMstIdentifier(cbicOrderMst) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(cbicOrderMst: ICbicOrderMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicOrderMst);
    return this.http
      .patch<ICbicOrderMst>(`${this.resourceUrl}/${getCbicOrderMstIdentifier(cbicOrderMst) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICbicOrderMst>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicOrderMst[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  search(req: Search): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicOrderMst[]>(this.resourceSearchUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  addCbicOrderMstToCollectionIfMissing(
    cbicOrderMstCollection: ICbicOrderMst[],
    ...cbicOrderMstsToCheck: (ICbicOrderMst | null | undefined)[]
  ): ICbicOrderMst[] {
    const cbicOrderMsts: ICbicOrderMst[] = cbicOrderMstsToCheck.filter(isPresent);
    if (cbicOrderMsts.length > 0) {
      const cbicOrderMstCollectionIdentifiers = cbicOrderMstCollection.map(
        cbicOrderMstItem => getCbicOrderMstIdentifier(cbicOrderMstItem)!
      );
      const cbicOrderMstsToAdd = cbicOrderMsts.filter(cbicOrderMstItem => {
        const cbicOrderMstIdentifier = getCbicOrderMstIdentifier(cbicOrderMstItem);
        if (cbicOrderMstIdentifier == null || cbicOrderMstCollectionIdentifiers.includes(cbicOrderMstIdentifier)) {
          return false;
        }
        cbicOrderMstCollectionIdentifiers.push(cbicOrderMstIdentifier);
        return true;
      });
      return [...cbicOrderMstsToAdd, ...cbicOrderMstCollection];
    }
    return cbicOrderMstCollection;
  }

  download(id: number, language: string):Observable<RuleMstResponse>{
    return this.http.get<RuleMstResponse>(`${this.resourceUrl}/download/${id}/${language}`);
  }

  
  fetchOrderByYearCategory(req?:any):Observable<EntityArrayResponseType>{
    const options = createRequestOption(req);
    return this.http
      .get<ICbicOrderMst[]>(`${this.resourceUrl}/fetch-orders-year-category`, { params: options,  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  fetchOrderCount(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicOrderMst[]>(`${this.resourceUrl}/order-count`, { params: options, observe: 'response' })
  }

  fetchOrderCategory(taxId:number):Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicOrderMst[]>(`${this.resourceUrl}/order-category/${taxId}`, {  observe: 'response' });
  }

  protected convertDateFromClient(cbicOrderMst: ICbicOrderMst): ICbicOrderMst {
    return Object.assign({}, cbicOrderMst, {
      createdDt: cbicOrderMst.createdDt?.isValid() ? cbicOrderMst.createdDt.toJSON() : undefined,
      updatedDt: cbicOrderMst.updatedDt?.isValid() ? cbicOrderMst.updatedDt.toJSON() : undefined,
      orderDt: cbicOrderMst.orderDt?.isValid() ? cbicOrderMst.orderDt.toJSON() : undefined,
      issueDt: cbicOrderMst.issueDt?.isValid() ? cbicOrderMst.issueDt.toJSON() : undefined,
      amendDt: cbicOrderMst.amendDt?.isValid() ? cbicOrderMst.amendDt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDt = res.body.createdDt ? dayjs(res.body.createdDt) : undefined;
      res.body.updatedDt = res.body.updatedDt ? dayjs(res.body.updatedDt) : undefined;
      res.body.orderDt = res.body.orderDt ? dayjs(res.body.orderDt) : undefined;
      res.body.issueDt = res.body.issueDt ? dayjs(res.body.issueDt) : undefined;
      res.body.amendDt = res.body.amendDt ? dayjs(res.body.amendDt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((cbicOrderMst: ICbicOrderMst) => {
        cbicOrderMst.createdDt = cbicOrderMst.createdDt ? dayjs(cbicOrderMst.createdDt) : undefined;
        cbicOrderMst.updatedDt = cbicOrderMst.updatedDt ? dayjs(cbicOrderMst.updatedDt) : undefined;
        cbicOrderMst.orderDt = cbicOrderMst.orderDt ? dayjs(cbicOrderMst.orderDt) : undefined;
        cbicOrderMst.issueDt = cbicOrderMst.issueDt ? dayjs(cbicOrderMst.issueDt) : undefined;
        cbicOrderMst.amendDt = cbicOrderMst.amendDt ? dayjs(cbicOrderMst.amendDt) : undefined;
      });
    }
    return res;
  }
}
