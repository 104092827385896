import * as dayjs from 'dayjs';

export interface ICbicTaxMst {
  id?: number;
  contentId?: number | null;
  taxName?: string | null;
  isActive?: string | null;
  createdBy?: number | null;
  createdDt?: dayjs.Dayjs | null;
  updatedBy?: number | null;
  updatedDt?: dayjs.Dayjs | null;
}

export class CbicTaxMst implements ICbicTaxMst {
  constructor(
    public id?: number,
    public contentId?: number | null,
    public taxName?: string | null,
    public isActive?: string | null,
    public createdBy?: number | null,
    public createdDt?: dayjs.Dayjs | null,
    public updatedBy?: number | null,
    public updatedDt?: dayjs.Dayjs | null
  ) {}
}

export function getCbicTaxMstIdentifier(cbicTaxMst: ICbicTaxMst): number | undefined {
  return cbicTaxMst.id;
}
