import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DataUtils } from "app/core/util/data-util.service";
import { RelatedContentResponse } from "app/entities/cbic-content-map/cbic-content-map.model";
import { FormResponse } from "app/entities/cbic-form-mst/cbic-form-mst.model";
import { CbicFormMstService } from "app/entities/cbic-form-mst/service/cbic-form-mst.service";

@Component({
    selector: 'cbic-related-content-list',
    templateUrl: './related-content-list.component.html'
     })
  export class RelatedContentListComponent implements OnInit {

    @Input() relatedContentList!: RelatedContentResponse;
    @Input() contentType: any;

    constructor(
      private router: Router,
      private formService: CbicFormMstService,
      private dataUtils: DataUtils,
    ){
    }

    ngOnInit():void{
        return
    }

    openRule(taxId:any, ruleId:any):void{
      const link = this.router.serializeUrl(this.router.createUrlTree(['content-page/explore-rules/' + String(ruleId) + '/' + String(taxId)]));
      window.open(link, '_blank');
    }

    openAct(taxId:any, actId:any):void{
      const link = this.router.serializeUrl(this.router.createUrlTree(['content-page/explore-act/' + String(actId) + '/' + String(taxId)]));
      window.open(link, '_blank');
    }

    openRegulation(taxId:any, regulationId:any):void{
      const link = this.router.serializeUrl(this.router.createUrlTree(['content-page/explore-regulations/' + String(regulationId)]));
      window.open(link, '_blank');
    }

    openForm(taxId:any, formId:any):void{
      // const link = this.router.serializeUrl(this.router.createUrlTree(['content-page/explore-forms/' + String(formId)]));
      // window.open(link, '_blank');
      this.formService.downloadForm(Number(formId)).subscribe(
        (res: FormResponse) => {
          const data = res.data;
          if(String (res.fileName).indexOf(".pdf")===-1){
            alert("Preview not available, Please Download")
          }else{
            this.dataUtils.openFile(String(data), "application/pdf");
          }
        }
      );
    }

    openNotification(taxId:any, notificationId:any):void{
      const link = this.router.serializeUrl(this.router.createUrlTree(['content-page/explore-notification/' + String(notificationId)]));
      window.open(link, '_blank');
    }

    openCircular(taxId:any, circularId:any):void{
      const link = this.router.serializeUrl(this.router.createUrlTree(['content-page/explore-rules/' + String(circularId) + '/' + String(taxId)]));
      window.open(link, '_blank');
    }
  }