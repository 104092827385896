import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'ngx-webstorage';

import { VERSION } from 'app/app.constants';
import { LANGUAGES } from 'app/config/language.constants';
import { AccountService } from 'app/core/auth/account.service';
import { LoginService } from 'app/login/login.service';
import { ProfileService } from 'app/layouts/profiles/profile.service';
import { faGlobe, faSun, faHome } from '@fortawesome/free-solid-svg-icons';
import { Cbicmenumst, ICbicmenumst } from '../../entities/cbicmenumst/cbicmenumst.model';
import { CbicmenumstService } from '../../entities/cbicmenumst/service/cbicmenumst.service';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { createMethodSignature } from 'typescript';
import { MegaMenuItemComponent } from '../mega-menu-item/mega-menu-item.component';
import { CbicActMstService } from 'app/entities/cbic-act-mst/service/cbic-act-mst.service';
declare let showLanguageSwitch:any;
@Component({
  selector: 'cbic-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  inProduction?: boolean;
  isNavbarCollapsed = true;
  languages = LANGUAGES;
  openAPIEnabled?: boolean;
  version = '';
  currentSelectionAct: any;
  logoSrcUrl = "content/images/india_logo_revised.png";
  cbicLogoSrc = "content/images/CBIC_logo.png";
  faHome = faHome;
  cbicmenumsts?: ICbicmenumst[];
  isLoading = false;
  currentSearch: string | undefined;
  menuItemTitle: any;
  langTitle:any;
  path:any;
  menuInput: any;
  taxId:any
  activeTab:any
  isHome = true
  storageLanguage: any
  isEnglish=true;

  constructor(
    private loginService: LoginService,
    private translateService: TranslateService,
    private sessionStorage: SessionStorageService,
    private accountService: AccountService,
    private profileService: ProfileService,
    private router: Router,
    protected cbicmenumstService: CbicmenumstService,
    protected modalService: NgbModal,
    protected activatedRoute: ActivatedRoute,
    protected cbicActMstService: CbicActMstService

  ) {
    if (VERSION) {
      this.version = VERSION.toLowerCase().startsWith('v') ? VERSION : 'v' + VERSION;
      this.currentSearch = this.activatedRoute.snapshot.queryParams['search'] ?? '';
    }
  }

  ngOnInit(): void {
    this.loadAll();
    this.profileService.getProfileInfo().subscribe(profileInfo => {
      this.inProduction = profileInfo.inProduction;
      this.openAPIEnabled = profileInfo.openAPIEnabled;
    });

    this.storageLanguage = this.sessionStorage.retrieve('locale')
    if (this.storageLanguage === "hi") {
      this.langTitle = "English"
    } else {
      this.langTitle = "हिन्दी"
    }
    
  }

  changeLanguage(languageKey: string): void {
    this.sessionStorage.store('locale', languageKey);
    this.taxId = this.sessionStorage.retrieve('taxId')
    this.activeTab = this.sessionStorage.retrieve('activeTab')
    const url = this.router.url;
    this.translateService.use(languageKey);
    if(url !== "/"){
      this.path = this.sessionStorage.retrieve('path')

      const navigationExtras: NavigationExtras = {
        state: {
          menuInput: JSON.parse(JSON.stringify({taxId: this.taxId, menuType: this.activeTab}))
        }
      };
      if(this.path==='externalLink'){
        window.open(JSON.parse(this.menuInput).externalLink, "_blank");
      }
      else{
  
      
      if(this.router.url===this.path){
        
          this.router.navigateByUrl('/#',{skipLocationChange:false}).then(() => {
            this.router.navigate([this.path],navigationExtras);
          });
      }
      else{
  
        
          this.router.navigate([this.path],navigationExtras);
        }
      }
    } else {
      window.location.reload()
      
    }
    
    
  }


  /* toggleSwitchLanguage(event: any):void {
    if (event.target.checked) {
      this.langTitle = "English";
      this.changeLanguage("hi");
    } else {
      this.langTitle = "Hindi";
      this.changeLanguage("en");
    }
  } */

  toggleSwitchLanguage(): void {
    this.storageLanguage = this.sessionStorage.retrieve('locale')
    if (this.storageLanguage === "hi") {
      this.isEnglish = false
    } else {
      this.isEnglish = true
    }

    if (this.isEnglish) {
      this.isEnglish = false;
      this.langTitle = "English";
      this.changeLanguage("hi");
    } else {
      this.isEnglish = true;
      this.langTitle = "हिन्दी";
      this.changeLanguage("en");
    }


  }

  collapseNavbar(): void {
    this.isNavbarCollapsed = true;
  }

  isAuthenticated(): boolean {
    return this.accountService.isAuthenticated();
  }

  login(): void {
    this.router.navigate(['/login']);
  }

  logout(): void {
    this.collapseNavbar();
    this.loginService.logout();
    this.router.navigate(['']);
  }

  toggleNavbar(): void {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

  getImageUrl(): string {
    return this.isAuthenticated() ? this.accountService.getImageUrl() : '';
  }

  loadAll(): void {
    this.isLoading = true;
    if (this.currentSearch) {
      this.cbicmenumstService
        .search({
          query: this.currentSearch,
        })
        .subscribe(
          (res: HttpResponse<ICbicmenumst[]>) => {
            this.isLoading = false;
            this.cbicmenumsts = res.body ?? [];
          },
          () => {
            this.isLoading = false;
          }
        );
      return;
    }

    this.cbicmenumstService.query().subscribe(
      (res: HttpResponse<ICbicmenumst[]>) => {
        this.isLoading = false;
        this.cbicmenumsts = res.body ?? [];
        this.createMenu();

      },
      () => {
        this.isLoading = false;

      }
    );
  }
  search(query: string): void {
    this.currentSearch = query;
    this.loadAll();
  }
  menuItemClicked(selectedMenu: string): void {
    this.currentSelectionAct = selectedMenu;
  }

  navigateHome(): void {
    showLanguageSwitch();
    this.currentSelectionAct = "";
    this.router.navigate([""]);
  }

  createMenu(): void {
    if (this.cbicmenumsts) {

      this.menuItemTitle = [];
      for (let i = 0; i < this.cbicmenumsts.length; i++) {
        if (this.cbicmenumsts[i].parentMenuId === 0) {
          const mainMenu = { 'menuTitle': this.cbicmenumsts[i].menuName, 'menuId': this.cbicmenumsts[i].id, 'logopath': this.cbicmenumsts[i].menuIcon };


          this.menuItemTitle.push(mainMenu);
        } else {
          for (let j = 0; j < this.menuItemTitle.length; j++) {
            if (this.menuItemTitle[j].menuId === this.cbicmenumsts[i].parentMenuId) {
              if (this.cbicmenumsts[i].type === 'INFO') {
                if (this.menuItemTitle[j].informationList) {
                  this.menuItemTitle[j].informationList.push({ 'lable': this.cbicmenumsts[i].menuName, 'path': this.cbicmenumsts[i].path, 'menuInput': this.cbicmenumsts[i].menuInput });
                } else {
                  this.menuItemTitle[j].informationList = [{ 'lable': this.cbicmenumsts[i].menuName, 'path': this.cbicmenumsts[i].path, 'menuInput': this.cbicmenumsts[i].menuInput }];
                }

              } else if (this.cbicmenumsts[i].type === 'FRQ_VIEW') {

                if (this.menuItemTitle[j].frequentViewList) {
                  this.menuItemTitle[j].frequentViewList.push({ 'lable': this.cbicmenumsts[i].menuName, 'path': this.cbicmenumsts[i].path, 'menuInput': JSON.parse(String(this.cbicmenumsts[i].menuInput)) });
                } else {
                  this.menuItemTitle[j].frequentViewList = [{ 'lable': this.cbicmenumsts[i].menuName, 'path': this.cbicmenumsts[i].path, 'menuInput': JSON.parse(String(this.cbicmenumsts[i].menuInput)) }];
                }

              } else if (this.cbicmenumsts[i].type === 'UPDATED') {

                if (this.menuItemTitle[j].lastUpdateList) {
                  this.menuItemTitle[j].lastUpdateList.push({ 'lable': this.cbicmenumsts[i].menuName, 'path': this.cbicmenumsts[i].path, 'menuInput': JSON.parse(String(this.cbicmenumsts[i].menuInput)) });
                } else {
                  this.menuItemTitle[j].lastUpdateList = [{ 'lable': this.cbicmenumsts[i].menuName, 'path': this.cbicmenumsts[i].path, 'menuInput': JSON.parse(String(this.cbicmenumsts[i].menuInput)) }];
                }

              }


            }
          }
        }

      }
    }
  }
}
