import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { errorRoute } from './layouts/error/error.route';
import { navbarRoute } from './layouts/navbar/navbar.route';
import { DEBUG_INFO_ENABLED } from 'app/app.constants';
import { Authority } from 'app/config/authority.constants';
import { UserRouteAccessService } from 'app/core/auth/user-route-access.service';
import { SuneditorComponent } from './suneditor/suneditor.component';
import { ContentPageRoutingResolveService } from './content-page/content-page-routing-resolve.service';
import { ContentPageRoutingRulesResolveService } from './content-page/content-page-routing-rules-resolve.service';
import { ContentPageRoutingRegulationResolveService } from './content-page/content-page-routing-regulation-resolve.service';
import { linkPageComponent } from './link-page/link-page.component';
import { AmendOrdinanceComponent } from './amend-ordinance/amend-ordinance.component';

import { ContentPageRoutingViewPdfResolveService } from './content-page/content-page-routing-view-pdf-resolve.service';
import { ViewPdfComponent } from './content-page/view-pdf/view-pdf.component';
import { LinkHistoryComponent } from './link-history/link-history.component';
import { NotificationHistoryComponent } from './notification-history/notification-history.component';

const LAYOUT_ROUTES = [navbarRoute, ...errorRoute];

@NgModule({

  imports: [
    RouterModule.forRoot(
      [
        /*{
          path: 'admin',
          data: {
            authorities: [Authority.ADMIN],
          },
          canActivate: [UserRouteAccessService],
          loadChildren: () => import('./admin/admin-routing.module').then(m => m.AdminRoutingModule),
        },*/
        {
          path: 'account',
          loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
        },
      /*  {
          path: 'login',
          loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
        },*/
        {
          path:'searchPage',
          loadChildren: () => import('./search-page/searchPage-routing.module').then(m => m.SearchPageRoutingModule),
        },
        {
          path:'content-page',
          loadChildren: () => import('./content-page/content-page-routing.module').then(m => m.ContentPageRoutingModule),
        },
        {
          path: 'suneditor/Acts/:sectionId',
          component: SuneditorComponent,   
          resolve: {
            cbicactsectionmst: ContentPageRoutingResolveService,
          },
          
        },
        {
          path: 'suneditor/Acts/:sectionId/:language',
          component: SuneditorComponent,   
          resolve: {
            cbicactsectionmst: ContentPageRoutingResolveService,
          },
          
        },
        {
          path: 'suneditor/Rules/:ruleSectionId',
          component: SuneditorComponent,   
          resolve: {
            cbicactsectionmst: ContentPageRoutingRulesResolveService,
          },
          
        },
        {
          path: 'suneditor/Rules/:ruleSectionId/:language',
          component: SuneditorComponent,   
          resolve: {
            cbicactsectionmst: ContentPageRoutingRulesResolveService,
          },
          
        },
        {
          path: 'suneditor/Regulations/:regulationSectionId',
          component: SuneditorComponent,   
          resolve: {
            cbicactsectionmst: ContentPageRoutingRegulationResolveService,
          },
        },
        {
          path: 'suneditor/Regulations/:regulationSectionId/:language',
          component: SuneditorComponent,   
          resolve: {
            cbicactsectionmst: ContentPageRoutingRegulationResolveService,
          },
        },
        {
          path:'history-page',
          loadChildren: () => import('./notification-history/notification-history-routing.module').then(m => m.NotificationHistoryRoutingModule),
        },
        {
          path:'link-page',
          component: linkPageComponent
        },
        {
          path:'link-history',
          component: LinkHistoryComponent
        },
        {
          path:'explore-others',
          data: {
            pageTitle: 'cbicWebPortalApp.cbicOthersDocumentMst.content.title',
          },
          component: AmendOrdinanceComponent
        },
        {
          path: 'view-pdf/:id/:type/:selectedTab',
          component: ViewPdfComponent,
          data: {
            pageTitle: 'content.title',
          },
          resolve: {
            pdfPath: ContentPageRoutingViewPdfResolveService,
          },
        },
        {
          path: 'history-page/:id/:taxId/:selectedTab',
          component: NotificationHistoryComponent,
          data: {
            pageTitle: 'content.title',
          },
        },
        ...LAYOUT_ROUTES,
        
      ],
      { enableTracing: DEBUG_INFO_ENABLED }
      
    ),
    
  ],
  exports: [RouterModule],
 
})
export class AppRoutingModule {}
