<div class="container noPadding">
  <div *ngIf="notiNotFound || circularNotFound || instructionNotFound || orderNotFound" class="row no-notification">
    <p *ngIf="notiNotFound" cbicTranslate="notificationNot">Notification not available for selected Year</p>
    <p *ngIf="circularNotFound" cbicTranslate="circularNot">Circular not available for selected Year</p>
    <p *ngIf="instructionNotFound" cbicTranslate="instructionGuide">Instruction / Guidelines not available for selected Year</p>
    <p *ngIf="orderNotFound" cbicTranslate="orderNot">Order not available for selected Year</p>
  </div>
  <div class="input-group flex-nowrap diplay-block-sm" *ngIf="selectedTab=='Notifications' && !notiNotFound">
    <ngb-pagination *ngIf="notificationDtls && notificationDtls.length > 0" style="margin-top: 5px; width: 35%;" class="width100"
      [collectionSize]="totalRecords" [(page)]="ngbPaginationPage" [pageSize]="showRecordSelected" [maxSize]="5"
      [rotate]="true" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>

    <cbic-item-count *ngIf="notificationDtls && notificationDtls.length > 0"
      class="item-count" style="width:45%;"
      [params]="{ page: currentPage, totalItems: totalRecords, itemsPerPage: showRecordSelected }">
    </cbic-item-count>

    <label *ngIf="notificationDtls && notificationDtls.length > 0"
      style="margin-top: 10px; align-items: flex-end; width: 20%;margin-bottom: 10px;"> <label cbicTranslate="show">Show</label> 
      <select size="1" name="example_length" aria-controls="example" #showRecordValue
        (change)="showRecordSearch(showRecordValue.value)">
        <option *ngFor="let record of showRecord" [value]="record" [selected]="record===showRecordSelected">{{record}}
        </option>
      </select>
      <label cbicTranslate="entries">entries</label> 
      
    </label>
  </div>
  
  <p class="history-msg" cbicTranslate="amendmentHistoryIs" *ngIf="selectedTab=='Notifications'" >*Amendment history is shown only for certain notifications</p>

  <!-- Notification Table-->
  
  <table class="table table-hover" *ngIf="selectedTab=='Notifications' && !notiNotFound">
    <tbody>
      <tr>
        <th style="text-align: center;width: 10%;" cbicTranslate="number">Number</th>
        <th style="text-align: center;width: 10%;" cbicTranslate="date">Date</th>
        <th style="text-align: center;width: 50%;" cbicTranslate="subject">Subject</th>
        <th style="text-align: center;width: 5%;" cbicTranslate="download">Download </th>
        <th style="text-align: center;width: 10%;" cbicTranslate="history">History </th> 
        <th style="text-align: center;width: 15%;" cbicTranslate="remarks">Remarks </th> 
      </tr>
      <tr *ngFor="let notificationDtl of notificationDtls, let i=index">

       
        <td class="text-start" >            
          <div *ngIf="language === 'en'">{{notificationDtl.notificationNo}}</div>
          <div *ngIf="language === 'hi'">{{notificationDtl.notificationNoHi ? notificationDtl.notificationNoHi : notificationDtl.notificationNo }}</div>
       </td>
         <td class="date">
          <div *ngIf="language === 'en'">{{notificationDtl.notificationDt| date: 'dd-MMM-yyyy':'UTC'}}</div>
          <div *ngIf="language === 'hi'">{{notificationDtl.notificationDt| date: 'dd-MMM-yyyy':'UTC': 'hi'}} </div>
         </td> 
         
        <!-- <td class="text-start">{{notificationDtl.notificationNo}}</td> -->
      <!--   <td class="date">{{notificationDtl.notificationDt| date: 'dd-MMM-yyyy':'UTC'}}</td> -->
        <td class="text-start" style="text-align: justify !important;">
          <div>
            <div *ngIf="language === 'en'">{{notificationDtl.notificationName}}</div>
            <div *ngIf="language === 'hi'">{{notificationDtl.notificationNameHi ?notificationDtl.notificationNameHi : notificationDtl.notificationName }}</div>
         </div>
          <!-- {{notificationDtl.notificationName}} <br /> -->
         <a *ngIf="notificationDtl.isAttachment === 'Y'" class="active" [routerLink]=""
            (click)="viewAttachments(notificationDtl.contentId, 'NOTI')">Click Here to view Attachments/Annexures</a>
          <div *ngIf="attachmentList && notificationDtl.isAttachment === 'Y'">
            <div *ngFor="let attachments of attachmentList, let i=index">
              <b><a *ngIf="notificationDtl.isAttachment === 'Y' && (notificationDtl.contentId == attachments.attachmentContentId)"
                  [routerLink]=""
                  (click)="viewDocumentsV2(attachments.docFilePath, attachments.id, 'ENG', 'ATT')">{{attachments.attachmentName}}</a></b>
            </div>
          </div>
        </td>
        <td>
          <div class="notification-download-container input-group flex-nowrap">
            <a [routerLink]="" *ngIf="notificationDtl.docFilePath"
            (click)="viewDocumentsV2( notificationDtl.docFilePath, notificationDtl.id, 'ENG', selectedTab)">English</a>
            <a [routerLink]="" (click)="downloadDocuments(notificationDtl.id, 'ENG')"
              *ngIf="notificationDtl.docFilePath"> <i class="fa fa-download" aria-hidden="true"></i></a>

            <a [routerLink]="" *ngIf="notificationDtl.docFilePathHi"
               (click)="viewDocumentsV2( notificationDtl.docFilePathHi, notificationDtl.id, 'HINDI', selectedTab)">हिन्दी</a>
            <a [routerLink]="" (click)="downloadDocuments(notificationDtl.id, 'HINDI')"
              *ngIf="notificationDtl.docFilePathHi"><i class="fa fa-download" aria-hidden="true"></i></a>
          </div>
        </td>
        <td><a *ngIf="notificationDtl.isHistory === 'Y'" [routerLink]="" (click)="viewHistory( notificationDtl.contentId, notificationDtl.tax.id, notificationDtl.parentId, selectedTab)">View</a></td>
        <td class="text-start">{{notificationDtl.ntRemarks}}</td>
      </tr>

    </tbody>
  </table>
  <!-- Down pagination notification-->
  <div class="input-group flex-nowrap diplay-block-sm" *ngIf="notificationDtls && notificationDtls.length > 0">

    <ngb-pagination class="width50" style="margin-top: 5px; width: 35%;" [collectionSize]="totalRecords" [(page)]="ngbPaginationPage"
      [pageSize]="showRecordSelected" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"
      (pageChange)="loadPage($event)" ></ngb-pagination>

    <cbic-item-count *ngIf="notificationDtls && notificationDtls.length > 0"
      [params]="{ page: currentPage, totalItems: totalRecords, itemsPerPage: showRecordSelected }"
      style="margin-top: 5px; width: 45%; text-align: center;"></cbic-item-count>

  </div>

  <!-- Circular Pagination -->
  <div class="input-group flex-nowrap diplay-block-sm" *ngIf="selectedTab=='Circulars' && !circularNotFound">
    <ngb-pagination *ngIf="circularList && circularList.length > 0" style="display:table;margin-top: 5px; width: 35%;" class="width100"
      [collectionSize]="totalRecordsCircular" [(page)]="ngbPaginationPage" [pageSize]="showRecordSelected" [maxSize]="5"
      [rotate]="true" [boundaryLinks]="true" (pageChange)="loadPageCircular($event)" class="float-start width100"></ngb-pagination>

    <cbic-item-count *ngIf="circularList && circularList.length > 0"
      class="item-count"style="width:45%;"
      [params]="{ page: currentPage, totalItems: totalRecordsCircular, itemsPerPage: showRecordSelected }">
    </cbic-item-count>

    <label *ngIf="circularList && circularList.length > 0"
      style="margin-top: 10px; align-items: flex-end; width: 20%"><label cbicTranslate="show">Show</label>
      <select size="1" name="example_length" aria-controls="example" #showRecordValue
        (change)="showRecordSearch(showRecordValue.value)">
        <option *ngFor="let record of showRecord" [value]="record" [selected]="record===showRecordSelected">{{record}}
        </option>
      </select>
      <label cbicTranslate="entries">entries</label> 
    </label>
  </div>

  <!-- Circular Table-->
  <table class="table table-hover" *ngIf="selectedTab=='Circulars' && !circularNotFound">
    <tbody>
      <tr>
        <th style="text-align: center;width: 10%;" cbicTranslate="number">Number</th>
        <th style="text-align: center;width: 10%;" cbicTranslate="date">Date</th>
        <th style="text-align: center;width: 60%;" cbicTranslate="subject">Subject</th>
        <th style="text-align: center;width: 10%;" cbicTranslate="download">Download </th>
        <!-- <th style="text-align: center;width: 10%;" cbicTranslate="history">History </th> 
        <th style="text-align: center;width: 15%;" cbicTranslate="remarks">Remarks </th> -->
        <!-- <th style="text-align: center;width: 10%;">History </th> -->
      </tr>
      <tr *ngFor="let circular of circularList, let i=index">

        <td class="text-start" >            
          <div *ngIf="language === 'en'">{{circular.circularNo}}</div>
          <div *ngIf="language === 'hi'">{{circular.circularNoHi ?circular.circularNoHi : circular.circularNo }}</div>
          
         </td>

        <!-- <td class="text-start">{{circular.circularNo}}</td> -->
        <!-- <td class="date">{{circular.circularDt| date: 'dd-MMM-yyyy':'UTC'}}</td> -->
        <td class="date">
        <div *ngIf="language === 'en'">{{circular.circularDt| date: 'dd-MMM-yyyy':'UTC'}}</div>
        <div *ngIf="language === 'hi'">{{circular.circularDt| date: 'dd-MMM-yyyy':'UTC': 'hi'}} </div>
      </td>
        <td class="text-start">
          <div>
            <div *ngIf="language === 'en'">{{circular.circularName}}</div>
          <div *ngIf="language === 'hi'">{{circular.circularNameHi ? circular.circularNameHi : circular.circularName}}</div>
          
          </div> <br />

          <a *ngIf="circular.isAttachment === 'Y'" class="active" [routerLink]=""
            (click)="viewAttachments(circular.contentId, 'CIR')">Click Here to view Attachments/Annexures</a>
         
            <div *ngIf="attachmentList && circular.isAttachment === 'Y'">
            <div *ngFor="let attachments of attachmentList, let i=index">
              <b><a *ngIf="circular.isAttachment === 'Y' && (circular.contentId == attachments.attachmentContentId)" [routerLink]=""
                  (click)="viewDocuments( attachments.docFilePath)">{{attachments.attachmentName}}</a></b>
            </div>
          </div>
          
        </td>
        <!-- {{circular.circularName}} -->
        <td>
          <div class="notification-download-container input-group flex-nowrap">
            <a [routerLink]="" *ngIf="circular.docFilePath" (click)="viewDocumentsV2( circular.docFilePath, circular.id, 'ENG', selectedTab)">English</a>
            <a [routerLink]="" (click)="downloadDocuments(circular.id, 'ENG')" *ngIf="circular.docFilePath"> <i
                class="fa fa-download" aria-hidden="true"></i></a>

            <a [routerLink]="" *ngIf="circular.docFilePathHi"
            (click)="viewDocumentsV2( circular.docFilePathHi, circular.id, 'HINDI', selectedTab)">हिन्दी</a>
            <a [routerLink]="" (click)="downloadDocuments(circular.id, 'HINDI')" *ngIf="circular.docFilePathHi"><i
                class="fa fa-download" aria-hidden="true"></i></a>
          </div>
        </td>
        <!-- <td><a *ngIf="circular.isHistory === 'Y'" [routerLink]="" (click)="viewHistory( circular.contentId, circular.tax.id, circular.parentId, selectedTab)">View</a></td>
        <td class="text-start">{{circular.cirRemarks}}</td> -->
      </tr>

    </tbody>
  </table>

  <!-- Down pagination Circular-->
  <div class="input-group flex-nowrap diplay-block-sm" *ngIf="circularList && circularList.length > 0">

    <ngb-pagination *ngIf="circularList && circularList.length > 0" style="display:table;margin-top: 5px; width: 35%;"
      [collectionSize]="totalRecordsCircular" [(page)]="ngbPaginationPage" [pageSize]="showRecordSelected" [maxSize]="5"
      [rotate]="true" [boundaryLinks]="true" (pageChange)="loadPageCircular($event)" class="float-start width100"></ngb-pagination>

    <cbic-item-count *ngIf="circularList && circularList.length > 0"
      style="margin-top: 5px; width: 45%; text-align: center;"
      [params]="{ page: currentPage, totalItems: totalRecordsCircular, itemsPerPage: showRecordSelected }">
    </cbic-item-count>



  </div>

  <!-- Instruction Pagination -->
  <div class="input-group flex-nowrap diplay-block-sm" *ngIf="selectedTab=='Instructions' && !instructionNotFound">
    <ngb-pagination *ngIf="instructionList && instructionList.length > 0" style="display:table;margin-top: 5px; width: 35%;" class="width100"
      [collectionSize]="totalRecords" [(page)]="ngbPaginationPage" [pageSize]="showRecordSelected" [maxSize]="5"
      [rotate]="true" [boundaryLinks]="true" (pageChange)="loadPageInstruction($event)" class="float-start width100"></ngb-pagination>

    <cbic-item-count *ngIf="instructionList && instructionList.length > 0"class="item-count" style="width:45%;"
     
      [params]="{ page: currentPage, totalItems: totalRecords, itemsPerPage: showRecordSelected }">
    </cbic-item-count>

      <label style="margin-top: 10px; align-items: flex-end; width: 20%"><label cbicTranslate="show">Show</label>
        <select size="1" name="example_length" aria-controls="example" #showRecordInstructionValue
          (change)="showRecordSearch(showRecordInstructionValue.value)" [(ngModel)]="showRecordSelected">
          <option *ngFor="let record of showRecord" [value]="record" >{{record}}
          </option>
        </select>
        <label cbicTranslate="entries">entries</label> 
      </label>


  </div>

  <!-- Instruction Table-->
  <table class="table table-hover" *ngIf="selectedTab=='Instructions' && !instructionNotFound">
    <tbody>
      <tr>
        <!-- <th style="text-align: center;width: 10%;">Sr. No</th> -->
        <th style="text-align: center;width: 10%;" cbicTranslate="number">Number</th>
        <th style="text-align: center;width: 10%;" cbicTranslate="date">Date</th>
        <th style="text-align: center;width: 60%;" cbicTranslate="subject">Subject</th>
        <th style="text-align: center;width: 10%;" cbicTranslate="download">Download </th>
        <!-- <th style="text-align: center;width: 10%;">History </th> -->
      </tr>
      <tr *ngFor="let instruction of instructionList, let i=index">
        <!-- <td class="text-start">{{instruction.serialNo}}</td> -->
        <!-- <td class="text-start">{{instruction.instructionNo}}</td> -->

        <td class="text-start" >            
          <div *ngIf="language === 'en'">{{instruction.instructionNo}}</div>
          <div *ngIf="language === 'hi'">{{instruction.instructionNoHi ? instruction.instructionNoHi : instruction.instructionNo}}</div>
        </td>

        <td class="date">
          <div *ngIf="language === 'en'">{{instruction.instructionDt| date: 'dd-MMM-yyyy':'UTC'}}</div>
          <div *ngIf="language === 'hi'">{{instruction.instructionDt| date: 'dd-MMM-yyyy':'UTC': 'hi'}} </div>
        </td>

       <!--  <td class="date">{{instruction.instructionDt| date: 'dd-MMM-yyyy':'UTC'}}</td> -->
        <td class="text-start">
          <div>
            <div *ngIf="language === 'en'">{{instruction.instructionName}}</div>
          <div *ngIf="language === 'hi'">{{instruction.instructionNameHi ? instruction.instructionNameHi : instruction.instructionName}}</div>
          
          </div> <br />
          <a *ngIf="instruction.isAttachment === 'Y'" class="active" [routerLink]=""
            (click)="viewAttachments(instruction.contentId, 'CIR')">Click Here to view Attachments/Annexures</a>
          <div *ngIf="attachmentList && instruction.isAttachment === 'Y'">
            <div *ngFor="let attachments of attachmentList, let i=index">
              <b><a *ngIf="instruction.isAttachment === 'Y' && (instruction.contentId == attachments.attachmentContentId)" [routerLink]=""
                  (click)="viewDocuments( attachments.docFilePath)">{{attachments.attachmentName}}</a></b>
            </div>
          </div>
        </td>
        <!-- {{instruction.instructionName}} -->
        <td>
          <div class="notification-download-container input-group flex-nowrap">
            <a [routerLink]="" *ngIf="instruction.docFilePath"
            (click)="viewDocumentsV2( instruction.docFilePath, instruction.id, 'ENG', selectedTab)">English</a>
            <a [routerLink]="" (click)="downloadDocuments(instruction.id, 'ENG')" *ngIf="instruction.docFilePath">
              <i class="fa fa-download" aria-hidden="true"></i></a>

            <a [routerLink]="" *ngIf="instruction.docFilePathHi"
            (click)="viewDocumentsV2( instruction.docFilePathHi, instruction.id, 'HINDI', selectedTab)">हिन्दी</a>
            <a [routerLink]="" (click)="downloadDocuments(instruction.id, 'HINDI')" *ngIf="instruction.docFilePathHi"><i
                class="fa fa-download" aria-hidden="true"></i></a>
          </div>
        </td>
        <!-- <td><a [routerLink]="" (click)="viewHisotry( circular.id, circular.tax.id, circular.parentId, selectedTab)">View</a></td> -->
      </tr>

    </tbody>
  </table>

  <div *ngIf="instructionList && instructionList.length > 0" class="container">

    <div class="row justify-content-center float-end">
      <cbic-item-count [params]="{ page: currentPage, totalItems: totalRecords, itemsPerPage: showRecordSelected }">
      </cbic-item-count>
    </div>


    <div class="row justify-content-center">
      <ngb-pagination [collectionSize]="totalRecords" [(page)]="ngbPaginationPage" [pageSize]="showRecordSelected"
        [maxSize]="5" [rotate]="true" [boundaryLinks]="true" (pageChange)="loadPageInstruction($event)">
      </ngb-pagination>
    </div>
  </div>

  <!--Order Pagination -->
  <div class="input-group flex-nowrap diplay-block-sm" *ngIf="selectedTab=='Orders' && !orderNotFound">
    <ngb-pagination *ngIf="orderList && orderList.length > 0" style="margin-top: 5px; width: 35%;"
      [collectionSize]="totalRecords" [(page)]="ngbPaginationPage" [pageSize]="showRecordSelected" [maxSize]="5"
      [rotate]="true" [boundaryLinks]="true" (pageChange)="loadPageOrder($event)" class="float-start width100"></ngb-pagination>

    <cbic-item-count *ngIf="orderList && orderList.length > 0" style="text-align: center; margin-top: 10px; width: 45%"
      [params]="{ page: currentPage, totalItems: totalRecords, itemsPerPage: showRecordSelected }">
    </cbic-item-count>

    <label style="margin-top: 10px; align-items: flex-end; width: 20%"><label cbicTranslate="show">Show</label>
      <select size="1" name="example_length" aria-controls="example" #showRecordValue
        (change)="showRecordSearch(showRecordValue.value)">
        <option *ngFor="let record of showRecord" [value]="record" [selected]="record===showRecordSelected">{{record}}
        </option>
      </select>
      <label cbicTranslate="entries">entries</label> 
    </label>
  </div>

  <!-- Order Table-->
  <table class="table table-hover" *ngIf="selectedTab=='Orders' && !orderNotFound">
    <tbody>
      <tr>
        <!-- <th style="text-align: center;width: 10%;">Sr. No</th> -->
        <th style="text-align: center;width: 10%;" cbicTranslate="number">Number</th>
        <th style="text-align: center;width: 10%;" cbicTranslate="date">Date</th>
        <th style="text-align: center;width: 60%;" cbicTranslate="subject">Subject</th>
        <th style="text-align: center;width: 10%;" cbicTranslate="download">Download </th>
        <!-- <th style="text-align: center;width: 10%;">History </th> -->
      </tr>
      <tr *ngFor="let order of orderList, let i=index">
        <!-- <td class="text-start">{{order.serialNo}}</td> -->
        <td class="text-start">{{order.orderNo}}</td>
        <td class="date">
          <div *ngIf="language === 'en'">{{order.orderDt| date: 'dd-MMM-yyyy':'UTC'}}</div>
          <div *ngIf="language === 'hi'">{{order.orderDt| date: 'dd-MMM-yyyy':'UTC': 'hi'}} </div>
        </td>
        <!-- <td class="date">{{order.orderDt| date: 'dd-MMM-yyyy':'UTC'}}</td> -->
        <td class="text-start">
          <div>
            <div *ngIf="language === 'en'">{{order.orderName}}</div>
          <div *ngIf="language === 'hi'">{{order.orderNameHi ? order.orderNameHi : order.orderName}}</div>
          
          </div>
           <br />
          <a *ngIf="order.isAttachment === 'Y'" class="active" [routerLink]=""
            (click)="viewAttachments(order.contentId, 'CIR')">Click Here to view Attachments/Annexures</a>
          <div *ngIf="attachmentList && order.isAttachment === 'Y'">
            <div *ngFor="let attachments of attachmentList, let i=index">
              <b><a *ngIf="order.isAttachment === 'Y' && (order.contentId == attachments.attachmentContentId)" [routerLink]=""
                  (click)="viewDocuments( attachments.docFilePath)">{{attachments.attachmentName}}</a></b>
            </div>
          </div>
        </td>
        <!-- {{order.orderName}} -->
        <td>
          <div class="notification-download-container input-group flex-nowrap">
            <a [routerLink]="" *ngIf="order.docFilePath" 
            (click)="viewDocumentsV2( order.docFilePath, order.id, 'ENG', selectedTab)">English</a>
            <a [routerLink]="" (click)="downloadDocuments(order.id, 'ENG')" *ngIf="order.docFilePath">
              <i class="fa fa-download" aria-hidden="true"></i></a>

            <a [routerLink]="" *ngIf="order.docFilePathHi" 
               (click)="viewDocumentsV2( order.docFilePathHi, order.id, 'HINDI', selectedTab)">हिन्दी</a>
            <a [routerLink]="" (click)="downloadDocuments(order.id, 'HINDI')" *ngIf="order.docFilePathHi"><i
                class="fa fa-download" aria-hidden="true"></i></a>
          </div>
        </td>
        <!-- <td><a [routerLink]="" (click)="viewHisotry( circular.id, circular.tax.id, circular.parentId, selectedTab)">View</a></td> -->
      </tr>

    </tbody>
  </table>

  <div *ngIf="orderList && orderList.length > 0" class="container">

    <div class="row justify-content-center">
      <cbic-item-count [params]="{ page: currentPage, totalItems: totalRecords, itemsPerPage: showRecordSelected }">
      </cbic-item-count>
    </div>

    <div class="row justify-content-center">
      <ngb-pagination [collectionSize]="totalRecords" [(page)]="ngbPaginationPage" [pageSize]="showRecordSelected"
        [maxSize]="5" [rotate]="true" [boundaryLinks]="true" (pageChange)="loadPageOrder($event)"></ngb-pagination>
    </div>
  </div>
</div>
<!-- <div>
  <h4>NgbPage: {{ngbPaginationPage}}</h4>
  <h4>CurrentPage: {{currentPage}}</h4>
  <h4>RecordSelected: {{showRecordSelected}}</h4>
  <h4>DummyTest: {{dummy}}</h4>
  <h4>TotalRecord: {{totalRecords}}</h4>
</div> -->