import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { DataUtils } from 'app/core/util/data-util.service';
import { FormResponse } from 'app/entities/cbic-form-mst/cbic-form-mst.model';
import { CbicOtherDocumentMstResponse } from 'app/entities/cbic-others-document-mst/cbic-others-document-mst.model';
import { CbicOthersDocumentMstService } from 'app/entities/cbic-others-document-mst/service/cbic-others-document-mst.service';
import { RuleMstResponse } from 'app/entities/cbic-rule-mst/cbic-rule-mst.model';
import { CbicRuleMstService } from 'app/entities/cbic-rule-mst/service/cbic-rule-mst.service';
import { CbicServiceTaxMstService } from 'app/entities/cbic-service-tax-mst/service/cbic-service-tax-mst.service';
import * as saveAs from 'file-saver';

@Component({
  selector: 'cbic-table-content-display',
  templateUrl: './table-content-display.component.html',
  styleUrls: ['./table-content-display.component.scss']
})
export class TableContentDisplayComponent implements OnInit {

  @Input() dataType:any;
  @Input() dataList:any;
  @Input() documentList:any;

  constructor(
    private downloadService: CbicServiceTaxMstService,
    private dataUtils: DataUtils, 
    private ruleMstService: CbicRuleMstService,
    private http : HttpClient,
    private service : CbicOthersDocumentMstService) { return;}

  ngOnInit(): void {
    return;
  }

  downloadForm(id: any,dataType:any): void {
    if(this.dataType === 'Act') {
      this.downloadService.downloadFile(Number(id),dataType).subscribe(
        (res: FormResponse) => {
          const data = res.data;
          // this.dataUtils.openFile(String(data),"application/pdf");
          const byteCharacters = atob(String(data));
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "application/pdf" });
          saveAs(blob, String(res.fileName));
        }
      );
    } else if(this.dataType === 'Rules') {
        this.ruleMstService.downloadRule(Number(id)).subscribe(
          (res: RuleMstResponse) => {
            const data = res.data;
            const byteCharacters = atob(String(data));
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            saveAs(blob, String(res.fileName));
          }
        );
    }
  }


  openForm(id: any,dataType:any): void {
    if(this.dataType === 'Act') {
      this.downloadService.downloadFile(Number(id),dataType).subscribe(
        (res: FormResponse) => {
          const data = res.data;
          this.dataUtils.openFile(String(data), "application/pdf");
        }
      );
    } else if(this.dataType === 'Rules') {
      this.ruleMstService.downloadRule(Number(id)).subscribe(
        (res: RuleMstResponse) => {
          const data = res.data;
          this.dataUtils.openFile(String(data), "application/pdf");
        }
      );
    }
  }

  viewDocuments(notificationPath:any):void{
    this.http.get('content/pdf/' + String(notificationPath),
        { responseType: 'text' })
        .subscribe(res => {
          const json=JSON.parse(res);
          const fileURL = 'data:application/pdf;base64,'+String(json.data);
          this.dataUtils.openFile(String(json.data), "application/pdf");
        }
        );
  }

  downloadDocuments(id: any, language: any): void {
    
      this.service
        .downloadDocument(Number(id), language).subscribe(
          (res: CbicOtherDocumentMstResponse) => {
            const data = res.pdfData;
            const byteCharacters = atob(String(data));
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            saveAs(blob, String(res.fileName));
          }
        ); 
  }
}
