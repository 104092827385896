import { Component, OnInit} from '@angular/core';
import { CbicCoreError } from './cbiccoreerror.model';
import { CbicErrorService } from './cbiccoreerror.service';

@Component({
    selector:'cbic-coreerror',
    templateUrl: './cbicoreerror.component.html',
})
export class CbiccoreerrorComponent implements OnInit{
    cbicCoreError: CbicCoreError = new CbicCoreError();

    constructor(private errorService : CbicErrorService){

    }

    ngOnInit(): void {
        this.cbicCoreError.exceptionMsg = this.errorService.cbicCoreError.exceptionMsg;
        this.cbicCoreError.excpetionId = this.errorService.cbicCoreError.excpetionId;
    }

}