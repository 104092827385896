import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { faSearch} from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CbicSearchOutputResult } from 'app/search-page/search-output.model';
import { CbicSearchService } from 'app/search-page/service/cbic-search-service';
import { CbicSearchInput1, CbicSTRuleSearchInput, ICbicSearchInput } from 'app/searchbar/searchbar.model';
import { SessionStorageService } from 'ngx-webstorage';
@Component({
  selector: 'cbic-notification-search',
  templateUrl: './notification-search.component.html',
  styleUrls: ['./notification-search.component.scss']
})
export class NotificationSearchComponent implements OnInit, OnChanges  {
  inProduction?: boolean;
  openAPIEnabled?: boolean;
  sample: any;
  regulationCategorySelection:any;
  selectedRegulationDocId:any;
  notiYear:any;
  yearList:any;
  tempYear:any;
  searched:any;
  faSearch=faSearch;
  faTimesCircle=faTimesCircle;
  regulationList:any;
  notiYearValue:any;
  @Input() regulationDocList:any;
  @Input() rulesChapterList:any;
  @Input() sectionListFetched:any;
  @Input()  searchRegulationId:any;
  @Input()  notificationCategoryList:any;
  @Output() categorySelected = new  EventEmitter<string>();
  @Output() notiYearSelected = new  EventEmitter<string>();
  searchBarForm = this.fb.group({
    keyword : [],
    taxId: [],
    notiYear:[],
    category: []
  });
  @Input() taxId: any;
  @Output() notificationSearch= new EventEmitter<string>();
  @Output() clearnotificationSearch = new EventEmitter<string>();
  @Input() selectedYear: any;
  notificationCategory: any;

  searchBarFormAll = this.fb.group({
    keywordAll : "",
  });
  @ViewChild("ruleSearchKeyword") ruleKeyword? : ElementRef;
  @Input() taxName: any;
  @ViewChild('allSearch', { static: true }) allSearchTextModal!: ElementRef;
  allSearchMsg: any
  @ViewChild("allRuleSearchKeyword") allRuleSearchKeyword? : ElementRef;
  @Input() activeTab:any
  @Input() tabName:any
  storageLanguage:any
 constructor(private fb:FormBuilder,
    private service: CbicSearchService,
    private modalService : NgbModal,
    private router: Router,
    private sessionStorage: SessionStorageService) {
    return;
   }
  ngOnChanges(): void {
    
    return;
  }

  ngOnInit(): void {
    this.storageLanguage = this.sessionStorage.retrieve('locale')
    this.yearList=[];
    this.tempYear=[];

    let i;
    if(this.taxId === 1000001){
      i = 2017;
    } else if(this.taxId === 1000004){
      i = 2002;
    } else {
      i = 2000;
    }

    for(i;i<=new Date().getFullYear();i++){
      this.tempYear.push(i);
      this.notiYearValue=i;
    }

   /*  if(this.taxId === 1000001){
      for(let i=2017;i<=new Date().getFullYear();i++){
        this.tempYear.push(i);
        //this.yearList.push(i);
        this.notiYearValue=i;
      }

    } else {
      for(let i=2000;i<=new Date().getFullYear();i++){
        this.tempYear.push(i);
        //this.yearList.push(i);
        this.notiYearValue=i;
      }
    } */
    
    this.tempYear.reverse()
    this.yearList = this.tempYear
    return;
  }

  
  changeCategorySelection(notificationCategory:any):void{
    this.categorySelected.emit(notificationCategory);
    return;
  }
  search():void{
    const cbicSearchInput = this.createCircularForm();
    if(cbicSearchInput.keyword === undefined || cbicSearchInput.keyword!.length < 3){
      alert("Please enter valid keyword");
      // this.serviceKeyword?.nativeElement.focus();
      return;
    }

    cbicSearchInput.keyword = cbicSearchInput.keyword?.trim()
    
    this.notificationSearch.emit(JSON.stringify(cbicSearchInput));
    this.searched=true;
  }
  
  clear():void{
    this.searched=false;
    
    this.searchBarForm = this.fb.group({
      keyword : "",
      taxId : "",
      notiYear : "",
    });
    this.clearnotificationSearch.emit();
    return;
  }

  setNotificationYear(year:any):void{
    this.notiYear=new Date().getFullYear();
    this.searchBarForm.patchValue({
      //notiYear:this.notiYear
      notiYear:year
    });
    //alert(this.yearList[0])
    //this.selectedYear = this.yearList[0]
  }
  fireRegulationDocSelected(regulationDocSelection:any):void{
    return;
  }
  fireNotiYearSelected(notificationYear:any):void{
    this.notiYearSelected.emit(notificationYear);
    return;
  }

  setNotificationCategory(category: any): void{
    this.notificationCategory = category;
  }

  searchAll():void{
    
    const cbicSearchInput = this.createAllForm();
    if(cbicSearchInput.keyword === undefined || cbicSearchInput.keyword!.length < 3){
      alert("Please enter valid keyword");
      this.ruleKeyword?.nativeElement.focus();
      return;
    }

    cbicSearchInput.keyword = cbicSearchInput.keyword?.trim()

    this.service.pageNumber = 1;
      this.service.searchInSection(cbicSearchInput).subscribe(
        (res: HttpResponse<CbicSearchOutputResult>) => {
          if(res.body?.totalDocuments === 0){
            this.allSearchMsg = "No search result found."
            this.allRuleSearchKeyword?.nativeElement.focus();
            this.searchBarFormAll = this.fb.group({
              keywordAll : ""
            });
            return;
          } else{
            this.modalService.dismissAll()
            this.service.cbicSearchOutputResults = res.body ?? new CbicSearchOutputResult([], null);
            this.router.navigate(['searchPage/search'],{queryParams : cbicSearchInput});
            //const url = this.router.serializeUrl(this.router.createUrlTree(['searchPage/search'],{queryParams : cbicSearchInput}));

            //window.open(url, '_blank');
          }
         
        });

  }

  openAllSearch(): void{
    this.modalService.open(this.allSearchTextModal,{windowClass : "", centered : true});
  }

  protected createAllForm(): ICbicSearchInput {
    return {
      ...new CbicSearchInput1(),
      keyword: (this.searchBarFormAll.get(['keywordAll'])!.value === null || this.searchBarFormAll.get(['keywordAll'])!.value === undefined) ? null : this.searchBarFormAll.get(['keywordAll'])!.value.trim(),
      content: "NOTIFICATIONS",
      taxId: this.taxId,
      startIndex: 0,
      paginationFlag: true,
      rows: 10
    };
  }

  protected createCircularForm(): ICbicSearchInput {
    return {
      ...new CbicSTRuleSearchInput(),
      keyword: (this.searchBarForm.get(['keyword'])!.value === null || this.searchBarForm.get(['keyword'])!.value === undefined) ? null : this.searchBarForm.get(['keyword'])!.value,
      taxId: this.taxId,
      content: "NOTIFICATIONS",
    };
  }


}
