import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    RouterModule.forChild([
      /* {
        path: 'cbicmenumst',
        data: { pageTitle: 'cbicWebPortalApp.cbicmenumst.home.title' },
        loadChildren: () => import('./cbicmenumst/cbicmenumst.module').then(m => m.CbicmenumstModule),
      },
      {
        path: 'cbic-tax-mst',
        data: { pageTitle: 'cbicWebPortalApp.cbicTaxMst.home.title' },
        loadChildren: () => import('./cbic-tax-mst/cbic-tax-mst.module').then(m => m.CbicTaxMstModule),
      },
      {
        path: 'cbic-act-mst',
        data: { pageTitle: 'cbicWebPortalApp.cbicActMst.home.title' },
        loadChildren: () => import('./cbic-act-mst/cbic-act-mst.module').then(m => m.CbicActMstModule),
      },
      {
        path: 'cbic-act-chapter-mst',
        data: { pageTitle: 'cbicWebPortalApp.cbicActChapterMst.home.title' },
        loadChildren: () => import('./cbic-act-chapter-mst/cbic-act-chapter-mst.module').then(m => m.CbicActChapterMstModule),
      },
      {
        path: 'cbic-act-section-mst',
        data: { pageTitle: 'cbicWebPortalApp.cbicActSectionMst.home.title' },
        loadChildren: () => import('./cbic-act-section-mst/cbic-act-section-mst.module').then(m => m.CbicActSectionMstModule),
      },
      {
        path: 'cbic-log-user-activity',
        data: { pageTitle: 'cbicWebPortalApp.cbicLogUserActivity.home.title' },
        loadChildren: () => import('./cbic-log-user-activity/cbic-log-user-activity.module').then(m => m.CbicLogUserActivityModule),
      },
      {
        path: 'authority-1',
        data: { pageTitle: 'cbicWebPortalApp.authority1.home.title' },
        loadChildren: () => import('./authority-1/authority-1.module').then(m => m.Authority1Module),
      },*/
      // {
      //   path: 'cbic-visitor-count',
      //   data: { pageTitle: 'cbicWebPortalApp.cbicVisitorCount.home.title' },
      //   loadChildren: () => import('./cbic-visitor-count/cbic-visitor-count.module').then(m => m.CbicVisitorCountModule),
      // },
      // {
      //   path: 'cbic-rule-mst',
      //   data: { pageTitle: 'cbicWebPortalApp.cbicRuleMst.home.title' },
      //   loadChildren: () => import('./cbic-rule-mst/cbic-rule-mst.module').then(m => m.CbicRuleMstModule),
      // },
      // {
      //   path: 'cbic-rule-chapter-mst',
      //   data: { pageTitle: 'cbicWebPortalApp.cbicRuleChapterMst.home.title' },
      //   loadChildren: () => import('./cbic-rule-chapter-mst/cbic-rule-chapter-mst.module').then(m => m.CbicRuleChapterMstModule),
      // },
      {
        path: 'cbic-service-tax-mst',
        data: { pageTitle: 'cbicWebPortalApp.cbicServiceTaxMst.home.title' },
        loadChildren: () => import('./cbic-service-tax-mst/cbic-service-tax-mst.module').then(m => m.CbicServiceTaxMstModule),
      },
      {
        path: 'cbic-circular-mst',
        data: { pageTitle: 'cbicWebPortalApp.cbicCircularMst.home.title' },
        loadChildren: () => import('./cbic-circular-mst/cbic-circular-mst.module').then(m => m.CbicCircularMstModule),
      },
      {
        path: 'cbic-attachment-dtls',
        data: { pageTitle: 'cbicWebPortalApp.cbicAttachmentDtls.home.title' },
        loadChildren: () => import('./cbic-attachment-dtls/cbic-attachment-dtls.module').then(m => m.CbicAttachmentDtlsModule),
      },
      {
        path: 'cbic-notification-mst',
        data: { pageTitle: 'cbicWebPortalApp.cbicNotificationMst.home.title' },
        loadChildren: () => import('./cbic-notification-mst/cbic-notification-mst.module').then(m => m.CbicNotificationMstModule),
      },
      {
        path: 'cbic-instruction-mst',
        data: { pageTitle: 'cbicWebPortalApp.cbicInstructionMst.home.title' },
        loadChildren: () => import('./cbic-instruction-mst/cbic-instruction-mst.module').then(m => m.CbicInstructionMstModule),
      },
      {
        path: 'cbic-content-map',
        data: { pageTitle: 'cbicWebPortalApp.cbicContentMap.home.title' },
        loadChildren: () => import('./cbic-content-map/cbic-content-map.module').then(m => m.CbicContentMapModule),
      },
      {
        path: 'cbic-others-document-mst',
        data: { pageTitle: 'cbicWebPortalApp.cbicOthersDocumentMst.home.title' },
        loadChildren: () => import('./cbic-others-document-mst/cbic-others-document-mst.module').then(m => m.CbicOthersDocumentMstModule),
      },
      {
        path: 'cbic-order-mst',
        data: { pageTitle: 'cbicWebPortalApp.cbicOrderMst.home.title' },
        loadChildren: () => import('./cbic-order-mst/cbic-order-mst.module').then(m => m.CbicOrderMstModule),
      },
      {
        path: 'cbic-content-history-map-dtls',
        data: { pageTitle: 'cbicWebPortalApp.cbicContentHistoryMapDtls.home.title' },
        loadChildren: () =>
          import('./cbic-content-history-map-dtls/cbic-content-history-map-dtls.module').then(m => m.CbicContentHistoryMapDtlsModule),
      },
      {
        path: 'cbic-amendment-doc-history',
        data: { pageTitle: 'cbicWebPortalApp.cbicAmendmentDocHistory.home.title' },
        loadChildren: () =>
          import('./cbic-amendment-doc-history/cbic-amendment-doc-history.module').then(m => m.CbicAmendmentDocHistoryModule),
      },
      {
        path: 'cbic-associate-ip-dtls',
        data: { pageTitle: 'cbicWebPortalApp.cbicAssociateIpDtls.home.title' },
        loadChildren: () => import('./cbic-associate-ip-dtls/cbic-associate-ip-dtls.module').then(m => m.CbicAssociateIpDtlsModule),
      },
      {
        path: 'cbic-allied-act-mst',
        data: { pageTitle: 'cbicWebPortalApp.cbicAlliedActMst.home.title' },
        loadChildren: () => import('./cbic-allied-act-mst/cbic-allied-act-mst.module').then(m => m.CbicAlliedActMstModule),
      },
      {
        path: 'cbic-allied-act-dtls',
        data: { pageTitle: 'cbicWebPortalApp.cbicAlliedActDtls.home.title' },
        loadChildren: () => import('./cbic-allied-act-dtls/cbic-allied-act-dtls.module').then(m => m.CbicAlliedActDtlsModule),
      },
      /* jhipster-needle-add-entity-route - JHipster will add entity modules routes here */
    ]),
  ],
})
export class EntityRoutingModule {}
