export interface ICbicmenumst {
  id?: number;
  menuName?: string | null;
  type?: string | null;
  parentMenuId?: number | null;
  order?: number | null;
  path?:string;
  menuIcon?:string;
  menuInput?:string;
  menuNameHi?: string | null;
}

export class Cbicmenumst implements ICbicmenumst {
  constructor(
    public id?: number,
    public menuName?: string | null,
    public type?: string | null,
    public parentMenuId?: number | null,
    public order?: number | null,
    data1?:string|null,
  ) {}
}

export function getCbicmenumstIdentifier(cbicmenumst: ICbicmenumst): number | undefined {
  return cbicmenumst.id;
}
