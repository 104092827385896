<div class="container noPadding">
    <div *ngIf="alliedNotFound " class="row no-notification">
      <p *ngIf="alliedNotFound" cbicTranslate="alliedActNotFound"></p>
    </div>
     <div class="input-group flex-nowrap diplay-block-sm" *ngIf="selectedTab=='Allied Acts' && !alliedNotFound">
        <ngb-pagination *ngIf="alliedActDtls && alliedActDtls.length > 0" style="margin-top: 5px; width: 35%;" class="width100"
          [collectionSize]="totalRecords" [(page)]="ngbPaginationPage" [pageSize]="showRecordSelected" [maxSize]="5"
          [rotate]="true" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
    
        <cbic-item-count *ngIf="alliedActDtls && alliedActDtls.length > 0"
          class="item-count" style="width:45%;"
          [params]="{ page: currentPage, totalItems: totalRecords, itemsPerPage: showRecordSelected }">
        </cbic-item-count>
    
        <label *ngIf="alliedActDtls && alliedActDtls.length > 0"
          style="margin-top: 10px; align-items: flex-end; width: 20%;margin-bottom: 10px;"> <label cbicTranslate="show">Show</label> 
          <select size="1" name="example_length" aria-controls="example" #showRecordValue
            (change)="showRecordSearch(showRecordValue.value)">
            <option *ngFor="let record of showRecord" [value]="record" [selected]="record===showRecordSelected">{{record}}
            </option>
          </select>
          <label cbicTranslate="entries">entries</label> 
          
        </label>
      </div>

      <!-- Allied Act Table-->
  
  <table class="table table-hover" *ngIf="selectedTab=='Allied Acts' && !alliedNotFound">
    <tbody>
      <tr>
        <th style="text-align: center;width: 20%;" cbicTranslate="egazetteReferenceNumber"></th>
        <th style="text-align: center;width: 10%;" cbicTranslate="date"></th>
        <th style="text-align: center;width: 35%;" cbicTranslate="subject"></th>
        <th style="text-align: center;width: 30%;" cbicTranslate="ccnNo"></th>
        <th style="text-align: center;width: 5%;" cbicTranslate="download"></th>
       
      </tr>
      <tr *ngFor="let alliedActDtls of alliedActDtls, let i=index">

      <td class="text-start" >
        <div *ngIf="language === 'en'">
        {{alliedActDtls.allied_act_ref_no}}
      </div>
      <div *ngIf="language === 'hi'">
        {{alliedActDtls.allied_act_ref_no_hi ? alliedActDtls.allied_act_ref_no_hi : alliedActDtls.allied_act_ref_no}}
      </div>
      </td>
       
      <td class="date">
        <div *ngIf="language === 'en'">{{alliedActDtls.allied_act_dt| date: 'dd-MMM-yyyy':'UTC'}}</div>
        <div *ngIf="language === 'hi'">{{alliedActDtls.allied_act_dt| date: 'dd-MMM-yyyy':'UTC': 'hi'}}</div>
      </td>
      <td class="text-start" style="text-align: justify !important;">
        <div *ngIf="language === 'en'">
        {{alliedActDtls.allied_act_subject}} <br />
      </div>
      <div *ngIf="language === 'hi'">
        {{alliedActDtls.allied_act_subject_hi ? alliedActDtls.allied_act_subject_hi : alliedActDtls.allied_act_subject}} <br />
      </div>
      </td>
      <td class="text-start" >{{alliedActDtls.allied_act_ccn_no}}</td>
      <td>
        <div class="notification-download-container input-group flex-nowrap">
          <a  *ngIf="alliedActDtls.doc_file_path==='' || alliedActDtls.doc_file_path===null"  href='{{alliedActDtls.url}}' target="_blank">
            {{alliedActDtls.url}}</a>&nbsp;&nbsp;

          <a [routerLink]="" *ngIf="alliedActDtls.doc_file_path"
          (click)="viewDocumentsV2( alliedActDtls.doc_file_path, alliedActDtls.id, 'ENG', selectedTab)">View</a>&nbsp;&nbsp;
          <a [routerLink]="" (click)="downloadDocuments(alliedActDtls.id, 'ENG')"
            *ngIf="alliedActDtls.doc_file_path"> <i class="fa fa-download" aria-hidden="true"></i></a>

          <a [routerLink]="" *ngIf="alliedActDtls.doc_file_path_hi"
             (click)="viewDocumentsV2( alliedActDtls.doc_file_path_hi, alliedActDtls.id, 'HINDI', selectedTab)">हिन्दी</a>
          <a [routerLink]="" (click)="downloadDocuments(alliedActDtls.id, 'HINDI')"
            *ngIf="alliedActDtls.doc_file_path_hi"><i class="fa fa-download" aria-hidden="true"></i></a>
        </div>
      </td> 
        
      </tr>

    </tbody>
  </table>

      <!-- Down pagination allied act-->
  <div class="input-group flex-nowrap diplay-block-sm" *ngIf="alliedActDtls && alliedActDtls.length > 0">

    <ngb-pagination class="width50" style="margin-top: 5px; width: 35%;" [collectionSize]="totalRecords" [(page)]="ngbPaginationPage"
      [pageSize]="showRecordSelected" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"
      (pageChange)="loadPage($event)" ></ngb-pagination>

    <cbic-item-count *ngIf="alliedActDtls && alliedActDtls.length > 0"
      [params]="{ page: currentPage, totalItems: totalRecords, itemsPerPage: showRecordSelected }"
      style="margin-top: 5px; width: 45%; text-align: center;"></cbic-item-count>

      

    </div>