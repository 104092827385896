<div class="search-view">
    <div class="container-fluid">
        <div class="row">
            <div class="card text-center">
                <div class="card-header">
                    <cbic-search-section [cbicSearchInt]="cbicSearchInt" (searchChanged)="onSearchChange($event)"></cbic-search-section>
                </div>
                <div class="card-body">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="act" role="tabpanel"
                            aria-labelledby="act-tab">
                            <div class="row">
                                <!-- <div class="content-col refineby col-lg-3  col-md-12">
                                    <cbic-search-refine (refineResultChanged)="onRefineParamsChange($event)"></cbic-search-refine>
                                </div> -->
                                <div class="content-col result-data col-lg-12 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-9">
                                            <div class="text-start result-title" *ngIf="storageLanguage === 'en'"> Showing {{pageRecordValue}} of
                                                {{cbicSearchOutputResults.totalDocuments}} results for matching
                                                "{{cbicSearchInput.keyword}}"
                                            </div>
                                            <div class="text-start result-title" *ngIf="storageLanguage === 'hi'"> 
                                                "{{cbicSearchInput.keyword}}" के मिलान के लिए {{cbicSearchOutputResults.totalDocuments}} परिणामों में से 1-10 दिखा रहा है
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <div id="example_length" class="dataTables-length">
                                                <label><label cbicTranslate="show">Show</label>
                                                    <select size="1" name="example_length" aria-controls="example" #showRecordValue (change)="showRecordSearch(showRecordValue.value)">
                                                        <option *ngFor="let record of showRecord" [value]="record" [selected]="record===showRecordSelected">{{record}}</option>
                                                        <!-- <option> value="10" selected="selected">10</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option> -->
                                                    </select>
                                                    <label cbicTranslate="entries">entries</label> 
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <cbic-search-results [cbicSearchOutputResults]="cbicSearchOutputResults" (resultChanged)="onResultChange($event)" [keyWord]="keyWord" [backSearchFlag]="backflag"></cbic-search-results>
                                    
                                </div>    

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

