import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CbicSearchOutputResult } from 'app/search-page/search-output.model';
import { CbicSearchService } from 'app/search-page/service/cbic-search-service';
import { CbicInstructionSearch, CbicSearchInput1, ICbicSearchInput } from 'app/searchbar/searchbar.model';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'cbic-instruction-search-bar',
  templateUrl: './instruction-search-bar.component.html',
  styleUrls: ['./instruction-search-bar.component.scss']
})
export class InstructionSearchBarComponent implements OnInit {
  faTimesCircle=faTimesCircle;
  faSearch=faSearch;
  searched=false;
  @Output() clearInstrcutionSearch = new EventEmitter<string>();
  @Output() InstructionSearch= new EventEmitter<string>();
  @Input() taxId: any;
  @Input() instructionCategoryList: any;
  @Output() categorySelected = new  EventEmitter<string>();
  @Output() instructionYearSelected = new EventEmitter<string>();
  yearList:any;
  instructionYear:any;
  instructionCategory: any;
  tempYear:any;
  instructionYearValue: any;
  selectedYear: any;

  searchBarInstruction = this.fb.group({
    keyword : [],
    taxId: [],
    instructionYear:[],
    category: [],
  });

  searchBarFormAll = this.fb.group({
    keywordAll : "",
  });
  @ViewChild("ruleSearchKeyword") ruleKeyword? : ElementRef;
  @Input() taxName: any;
  @ViewChild('allSearch', { static: true }) allSearchTextModal!: ElementRef;
  allSearchMsg: any
  @ViewChild("allRuleSearchKeyword") allRuleSearchKeyword? : ElementRef;
  @Input() activeTab:any
  @Input() tabName:any
  storageLanguage:any

  constructor(private fb:FormBuilder,
    private service: CbicSearchService,
    private modalService : NgbModal,
    private router: Router,
    private sessionStorage: SessionStorageService) { }

  ngOnInit(): void {
    this.storageLanguage = this.sessionStorage.retrieve('locale')
    this.yearList=[];
    this.tempYear=[];

    let i;
    if(this.taxId === 1000001){
      i = 2017;
    } else {
      i = 2000;
    }
    for(i;i<=new Date().getFullYear();i++){
      this.tempYear.push(i);
      this.instructionYear=i;
    }
    
    this.tempYear.reverse()
    this.yearList = this.tempYear
    return;
  }

  setInstructionYear(year: any):void{
    this.instructionYear = new Date().getFullYear();
    this.searchBarInstruction.patchValue({
      instructionYear: year
    });
  }

  setInstructionCategory(category: any): void{
    this.instructionCategory = category;
  }

  clear():void{
    this.searched=false;
    
    this.searchBarInstruction = this.fb.group({
      keyword : "",
      taxId : "",
      instructionYear : "",
    });
    this.clearInstrcutionSearch.emit();
  }

  search():void{
    const cbicSearchInput = this.createInstructionForm();
    if(cbicSearchInput.keyword === undefined || cbicSearchInput.keyword!.length < 3){
      alert("Please enter valid keyword");
    }

    cbicSearchInput.keyword = cbicSearchInput.keyword?.trim()
    
    this.InstructionSearch.emit(JSON.stringify(cbicSearchInput));
    this.searched=true;
  }

  changeCategorySelection(instructionCategory:string):void{
    this.categorySelected.emit(instructionCategory);
  }

  fireInstructionYearSelected(instructionYear:any):void{
    this.instructionYearSelected.emit(instructionYear);
  }

  searchAll():void{
    
    const cbicSearchInput = this.createAllForm();
    if(cbicSearchInput.keyword === undefined || cbicSearchInput.keyword!.length < 3){
      alert("Please enter valid keyword");
      this.ruleKeyword?.nativeElement.focus();
      return;
    }

    cbicSearchInput.keyword = cbicSearchInput.keyword?.trim()

    this.service.pageNumber = 1;
      this.service.searchInSection(cbicSearchInput).subscribe(
        (res: HttpResponse<CbicSearchOutputResult>) => {
          if(res.body?.totalDocuments === 0){
            this.allSearchMsg = "No search result found."
            this.allRuleSearchKeyword?.nativeElement.focus();
            this.searchBarFormAll = this.fb.group({
              keywordAll : ""
            });
            return;
          } else{
            this.modalService.dismissAll()
            this.service.cbicSearchOutputResults = res.body ?? new CbicSearchOutputResult([], null);
            this.router.navigate(['searchPage/search'],{queryParams : cbicSearchInput});
            //const url = this.router.serializeUrl(this.router.createUrlTree(['searchPage/search'],{queryParams : cbicSearchInput}));

            //window.open(url, '_blank');
          }
         
        });

  }

  openAllSearch(): void{
    this.modalService.open(this.allSearchTextModal,{windowClass : "", centered : true});
  }

  protected createAllForm(): ICbicSearchInput {
    return {
      ...new CbicSearchInput1(),
      keyword: (this.searchBarFormAll.get(['keywordAll'])!.value === null || this.searchBarFormAll.get(['keywordAll'])!.value === undefined) ? null : this.searchBarFormAll.get(['keywordAll'])!.value.trim(),
      content: "INSTRUCTIONS",
      taxId: this.taxId,
      startIndex: 0,
      paginationFlag: true,
      rows: 10
    };
  }

  protected createInstructionForm(): ICbicSearchInput {
    return {
      ...new CbicInstructionSearch(),
      keyword: (this.searchBarInstruction.get(['keyword'])!.value === null || this.searchBarInstruction.get(['keyword'])!.value === undefined) ? null : this.searchBarInstruction.get(['keyword'])!.value,
      taxId: this.taxId,
      content: "INSTRUCTIONS",
    };
  }
}
