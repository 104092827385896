<section class="search-sec">
    <div class="container">
        <form name="searchBarForm" [formGroup]="searchBarForm" novalidate="novalidate" (ngSubmit)="save()" >
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="input-group flex-nowrap">

                            <input type="text" class="form-control keyword mb10 width100"
                                placeholder="{{ 'enterKeyword' | translate }}" aria-label="Username" #keyWordInput
                                aria-describedby="addon-wrapping" maxlength="100" formControlName="keyword">
                            <!-- <span class="input-group-text spanbtn"><fa-icon [icon]="faSearch"></fa-icon></span> -->
                            <select class="form-select mb10 width100 " id="inputGroupSelect01"
                                aria-label="Example select with button addon" formControlName="taxId" #taxIdValue (change)="showActsRules(taxIdValue.value,contentTypeValue.value)">
                                <option selected value="undefined" cbicTranslate="alltaxes"></option>
                                <option *ngFor = "let taxMst of cbicTaxMsts" [value]="taxMst.id" [selected]="taxMst.id === undefined">{{taxMst.taxName}}</option>
                                <!-- <option value="2">Customs</option>
                                <option value="3">Central Excise</option>
                                <option value="3">Service Tax</option> -->
                            </select>
                            <select class="form-select mb10 width100 " id="inputGroupSelect02"
                                aria-label="Example select with button addon" formControlName="content" #contentTypeValue (change)="showActsRules(taxIdValue.value,contentTypeValue.value)">
                                <option selected value="undefined" cbicTranslate="allcontents">All Contents</option>
                                <option *ngFor="let contentTest of cbicContents" [value]="contentTest.id" >{{contentTest.contentName}}</option>
                              
                            </select>
                            <select class="form-select actDropDown mb10 width100" id="inputGroupSelect03"
                                aria-label="Example select with button addon" formControlName="actId">
                                <option selected value="undefined" cbicTranslate="all">All</option>
                                <option *ngFor="let actMst of cbicContentHeader" [value]="actMst.contentId" [selected]="actMst.contentId === undefined" title="{{actMst.contentName}}">{{actMst.contentNameLable}}</option>
                                <!-- <option value="1">Customs Act, 1962</option>
                                <option value="2">Customs Tariff Act, 1975</option>
                                <option value="3">Provisional Collection of Taxes Act, 1931
                                </option>
                                <option value="3">Foreign Trade (Development and Regulation)
                                    Act, 1992</option>
                                <option value="2">Taxation Laws (Amendment) Act, 2006
                                </option> -->
                            </select>
                            <button class="btn" type="button"><a (click)="save()"><fa-icon [icon]="faSearch"></fa-icon></a></button>
                        </div>
                        <!-- <div class="advance-search">
                            <a href="" >Advance Search</a>
                        </div> -->
                        <!--<div class="col-lg-3 col-md-3 col-sm-12 p-0">
                            <input type="text" class="form-control search-slt" placeholder="Enter Pickup City">
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-12 p-0">
                            <select class="form-select search-slt" aria-label="Default select example">
                                <option selected>Default</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-12 p-0">
                            <select class="form-control search-slt" id="exampleFormControlSelect1">
                                <option>Select Vehicle</option>
                                <option>Example one</option>
                                <option>Example one</option>
                                <option>Example one</option>
                                <option>Example one</option>
                                <option>Example one</option>
                                <option>Example one</option>
                            </select>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-12 p-0">
                            <button type="button" class="btn"><i class="fas fa-search"></i></button>
                        </div> -->
                    </div>
                </div>
            </div>
         
        </form>
    </div>
</section>
