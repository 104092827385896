<div class="wrapper">
  <header>
    <div id="header" class="header-wrapper top-bar ">
      <div class="container-fluid noPadding">
        <nav class="navbar navbar-expand-lg topbar-ribbon">
          <div class="container-fluid">
            <a class="navbar-brand" href="#">
              <img src="/content/images/National_Flag.png" alt="" width="30" height="24"
                class="d-inline-block align-text-top" />
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <i class="fa fa-bars" style="color: #CCA290;"></i>
            </button>
            <div class="collapse navbar-collapse " id="navbarNav">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page">
                    <div class="top-bar-title-HN">भारत सरकार</div>
                    Government of India
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link">
                    <div class="top-bar-title-HN">वित्त मंत्रालय</div>
                    Ministry of Finance
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link">
                    <div class="top-bar-title-HN">राजस्व विभाग</div>
                    Department of Revenue
                  </a>
                </li>
                <li class="nav-item ">
                  <a class="nav-link" style="text-decoration: underline; color: white;" href="https://www.cbic.gov.in/">
                    <div class="top-bar-title-HN"><a style="text-decoration: underline; color: white;"
                        href="https://www.cbic.gov.in/Hindi">सीबीआईसी होम</a></div>
                    CBIC Home
                  </a>
                </li>
                <li class="nav-item ">
                  <span id="languageDiv">
                    <a [routerLink]="" style="text-decoration: underline; color: white;"
                      class="form-check-label nav-link" (click)="toggleSwitchLanguage()"
                      for="flexSwitchCheckDefault">{{langTitle}}</a>
                  </span>
                </li>
              </ul>
              <span class="navbar-text">
              </span>
            </div>

          </div>
        </nav>
        <cbic-topbar [logoSrc]="logoSrcUrl" [cbicLogoSrc]="cbicLogoSrc"></cbic-topbar>
      </div>

      <!-- Nav Bar Start -->
      <div class="container-fluid">
        <div class="nav-bar">
          <nav class="navbar navbar-expand-lg navbar-dark bg-dark" id="nav">
            <button class="navbar-toggler text-white" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
              aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav d-flex">
                <li class="nav-item ">
                  <a class="nav-link home-link sm-hide" aria-current="page" (click)="navigateHome()"
                    data-toggle="collapse" href="#collapseExample">
                    <fa-icon [icon]="faHome"></fa-icon>
                    <label cbicTranslate="homeButton">Home</label>
                  </a>
                </li>

                <cbic-mega-menu-item *ngFor="let menu of menuItemTitle" [informationLinks]="menu.informationList"
                  [logopath]="menu.logopath" [menuItemTitle]="menu.menuTitle"
                  [currentSelectionAct]="currentSelectionAct" [frequentViewList]="menu.frequentViewList"
                  [lastUpdateList]="menu.lastUpdateList" (menuItemClicked)="menuItemClicked($event)">
                </cbic-mega-menu-item>

              </ul>

              <!--mobile menu-->


            </div>


          </nav>
        </div>


      </div>
      <!-- Nav Bar End -->

    </div>
  </header>
</div>