import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { Search } from 'app/core/request/request.model';
import { ICbicContentMap, getCbicContentMapIdentifier, CbicContentMap2, CbicContentMapResponse, RelatedContentRequest, RelatedContentResponse } from '../cbic-content-map.model';

export type EntityResponseType = HttpResponse<ICbicContentMap>;
export type EntityArrayResponseType = HttpResponse<ICbicContentMap[]>;
export type EntityReponse = HttpResponse<CbicContentMapResponse>;
export type EntityRelatedContentResponse = HttpResponse<RelatedContentResponse>;

@Injectable({ providedIn: 'root' })
export class CbicContentMapService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/cbic-content-maps');
  public resourceSearchUrl = this.applicationConfigService.getEndpointFor('api/_search/cbic-content-maps');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(cbicContentMap: ICbicContentMap): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicContentMap);
    return this.http
      .post<ICbicContentMap>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(cbicContentMap: ICbicContentMap): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicContentMap);
    return this.http
      .put<ICbicContentMap>(`${this.resourceUrl}/${getCbicContentMapIdentifier(cbicContentMap) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(cbicContentMap: ICbicContentMap): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicContentMap);
    return this.http
      .patch<ICbicContentMap>(`${this.resourceUrl}/${getCbicContentMapIdentifier(cbicContentMap) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICbicContentMap>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicContentMap[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  fetchRelatedContent(id:number,contentType:string): Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicContentMap[]>(`${this.resourceUrl}/get-related-content/${contentType}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  search(req: Search): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicContentMap[]>(this.resourceSearchUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  addCbicContentMapToCollectionIfMissing(
    cbicContentMapCollection: ICbicContentMap[],
    ...cbicContentMapsToCheck: (ICbicContentMap | null | undefined)[]
  ): ICbicContentMap[] {
    const cbicContentMaps: ICbicContentMap[] = cbicContentMapsToCheck.filter(isPresent);
    if (cbicContentMaps.length > 0) {
      const cbicContentMapCollectionIdentifiers = cbicContentMapCollection.map(
        cbicContentMapItem => getCbicContentMapIdentifier(cbicContentMapItem)!
      );
      const cbicContentMapsToAdd = cbicContentMaps.filter(cbicContentMapItem => {
        const cbicContentMapIdentifier = getCbicContentMapIdentifier(cbicContentMapItem);
        if (cbicContentMapIdentifier == null || cbicContentMapCollectionIdentifiers.includes(cbicContentMapIdentifier)) {
          return false;
        }
        cbicContentMapCollectionIdentifiers.push(cbicContentMapIdentifier);
        return true;
      });
      return [...cbicContentMapsToAdd, ...cbicContentMapCollection];
    }
    return cbicContentMapCollection;
  }

  linkContent(cbicContentMap:CbicContentMap2):Observable<EntityReponse>{
    return this.http
    .post<CbicContentMapResponse>(`${this.resourceUrl}/link-content`, cbicContentMap, { observe: 'response' });
  }

  unlinkContent(cbicContentMap:CbicContentMap2):Observable<EntityReponse>{
    return this.http
    .post<CbicContentMapResponse>(`${this.resourceUrl}/unlink-content`, cbicContentMap, { observe: 'response' });
  }

  fetchRelatedContentList(relatedContentRequest:RelatedContentRequest):Observable<EntityRelatedContentResponse>{
    return this.http.post<RelatedContentResponse>(`${this.resourceUrl}/fetch-related-content`, relatedContentRequest, { observe: 'response' });
  }

  protected convertDateFromClient(cbicContentMap: ICbicContentMap): ICbicContentMap {
    return Object.assign({}, cbicContentMap, {
      createdDt: cbicContentMap.createdDt?.isValid() ? cbicContentMap.createdDt.toJSON() : undefined,
      updatedDt: cbicContentMap.updatedDt?.isValid() ? cbicContentMap.updatedDt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDt = res.body.createdDt ? dayjs(res.body.createdDt) : undefined;
      res.body.updatedDt = res.body.updatedDt ? dayjs(res.body.updatedDt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((cbicContentMap: ICbicContentMap) => {
        cbicContentMap.createdDt = cbicContentMap.createdDt ? dayjs(cbicContentMap.createdDt) : undefined;
        cbicContentMap.updatedDt = cbicContentMap.updatedDt ? dayjs(cbicContentMap.updatedDt) : undefined;
      });
    }
    return res;
  }
}
