
  <!-- Footer Start -->
<!-- <div>
    <div style="text-align: center;
    width: 5%;
    background: #00769d;
    margin-left: 47.5%;
    border-radius: 50% 50% 0% 0%;">
        <div id="downArrow" style="text-align:center;" (click)="toggle()" [style.display]="display"><a
                href="javascript:" onclick=""><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                    fill="white" class="bi bi-chevron-down" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                </svg></a></div>
        <div id="upArrow" (click)="toggle()" [style.display]="displayNone"><a href="javascript:" onclick=""><svg
                    xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-chevron-up"
                    viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                        d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                </svg></a></div>
    </div>
</div> -->
<div class="footer " id="collapseExample" (click)="toggle()">
    <div class="container">
        <div class="row">
            <div class="col-md-3 cbic-footer disclaimer"><a (click)="openDisclaimer()" style="color:#f6fdff;" cbicTranslate="feedbackSuggestion"></a></div>
            <div class="col-md-1"></div>
            <div class="col-md-2 visiter" cbicTranslate="visitorCount"></div>
            <div class="col-md-3">
            <div *ngFor="let i of visitorCountDigits" class="col-md-2 number" [attr.data-number]="i">
                
                <span class="primary">
                </span>
                <span class="secondary">
                </span>
            </div>
            </div>
            
            <!-- <div class="col-md-3 cbic-footer" style="text-align: right;"><a href="https://www.cbic.gov.in/" style="color:#f6fdff;" cbicTranslate="gotoCBICHomepage">Go To CBIC Home Page</a></div>  -->
            <div class="col-md-3 cbic-footer disclaimer"><a (click)="openDisclaimerText()" style="color:#f6fdff; padding-left: 60%;" cbicTranslate="disclaimer"></a></div>
        </div>
        
    </div>
    <!-- <div>
        <div class="row">
            <button type="button" class="btn btn-light" (click)="downloadFile()" cbicTranslate="Test">Download</button>
        </div>
        <div class="row">
              <select (change)="changeLanguage(lanValue.value); collapseNavbar()" #lanValue>
                  <option *ngFor="let language of languages"  [value]="language" [selected]="language === 'en'">{{language}}</option>
              </select>
        </div>-->
        <!-- <div class="row">
            <button (click)="toogleTheme()">Theme</button>
        </div> -->
        <!-- <div class="row">
            <button (click)="downloadForm1()">Download</button>
        </div> -->
        
        
    <!-- </div>  -->

    <!-- <div class="container" id="container1" [style.display]="displayNone">
        <div class="row">
            <div class="col-md-6 col-lg-3">
                <div class="footer-link">
                    <h2><i class="far fa-chart-bar"><span></span></i></h2>
                    <a href="">Last update : 15-May-2021</a>
                    <a href="">10,000 Visits Today</a>
                    <a href="">20,000 Total Visits</a>
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
                <div class="footer-link">
                    <h2><span>Useful Pages</span></h2>
                    <a href="">About CBIC</a>
                    <a href="">FAQs</a>
                    <a href="">Sitemap</a>
                    <a href="">Awards & Achievements</a>
                </div>
            </div>
            <div class="col-md-5 col-lg-3">
                <div class="footer-link">
                    <h2><span>Website Policies</span></h2>
                    <a href="">Terms of Use</a>
                    <a href="">Privacy Policy</a>
                    <a href="">Cookies Policy</a>
                </div>
            </div>
            <div class="col-md-7 col-lg-3">
                <div class="footer-contact">
                    <div class="footer-link">
                        <h2><span>Connect</span></h2>
                        <a href="">Contact Us</a>
                        <a href="">Submit Feedback</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container" id="container2" [style.display]="display">
        <div class="row">
            <div class="col-md-6 col-lg-3">
                <div class="footer-link-collapse">
                    <div class="footer-link">
                        <h2><i class="far fa-chart-bar"><span></span></i></h2>
                        
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
                <div class="footer-link-collapse">
                    <h2><span>Useful Pages</span></h2>
                    
                </div>
            </div>
            <div class="col-md-5 col-lg-3">
                <div class="footer-link-collapse">
                    <h2><span>Website Policies</span></h2>
                    
                </div>
            </div>
            <div class="col-md-7 col-lg-3">
                <div class="footer-contact">
                    <div class="footer-link-collapse">
                        <h2><span>Connect</span></h2>
                        
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>
<div class="copyright">
    <p cbicTranslate="allRightReserved">&copy;</p>
</div>
<div class="row" *ngIf="notAllowed">
    <div class="col-md-2"><a [routerLink]="'/link-page'">Link content</a></div>
    <div class="col-md-2"><a [routerLink]="'/link-history'">Notification History</a></div>
</div>
<!-- Footer End -->
<!--Footer Feebback/Suggestion Modal-->
<ng-template #disclaimer let-modal>

    <div class="modal-header">
        <h4 class="modal-title" style="text-align: center;padding-left: 120px;" cbicTranslate="feedbackSuggestion"></h4>
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')"><i
                class="fas fa-times"></i>
        </button>
    </div>
    <div class="modal-body">
        <p cbicTranslate="feedbackPopup"></p>
    </div>
    <!-- <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Continue</button>
    </div> -->


</ng-template>
<!--Footer Feebback/Suggestion Modal-->

<!--Footer Disclaimer Modal-->
<ng-template #disclaimerText let-modal>

    <div class="modal-header">
        <h4 class="modal-title" style="text-align: center;padding-left: 150px;" cbicTranslate="disclaimer"></h4>
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')"><i
                class="fas fa-times"></i>
        </button>
    </div>
    <div class="modal-body">
        <p cbicTranslate="disclaimerPopup"></p>
    </div>

</ng-template>
<!--Footer Disclaimer Modal-->


