<div class="home-banner">
    <div class="news">
        <section class="cd-intro cd-headline loading-bar">
            <span cbicTranslate="newsFlash"></span>
            <span class="cd-words-wrapper">
                <b class="is-visible" cbicTranslate="cbicIsPleased"></b>
                <b cbicTranslate="theContent"></b>
                <b cbicTranslate="information"></b>
                <b cbicTranslate="inCase"></b>
                <!-- <b style="color: black;">OM dated 31.05.2021, regarding corrections in the integrated AISL of
                                Administrative
                                Officers (Customs) for the period from 01.01.2008 to 30.06.2014. Click here</b>
                            <b style="color: black;">OM dated 31.05.2021, regarding corrections in the integrated AISL of Customs
                                Appraisers
                                for the period from 01.04.2003 to 31.12.2011. Click here</b> -->
            </span>
        </section>
    </div>
    <br /> <br />
    <div class="container-fluid">
        <div class="row">
            <div div class="col-lg-1 col-md-12">
            </div>
            <div div class="col-lg-10 col-md-12">
                <div class="bg-effect-search">
                    <!-- <ul class="cd-filter">
                      <li>
                          <input type="radio" id="rotate-1" name="cd-animation-type" checked>
                          <label for="rotate-1">Rotate 1</label>
                      </li>

                      <li>
                          <input type="radio" id="type" name="cd-animation-type">
                          <label for="type">Type</label>
                      </li>

                      <li>
                          <input type="radio" id="rotate-2" name="cd-animation-type">
                          <label for="rotate-2">Rotate 2</label>
                      </li>

                      <li>
                          <input type="radio" id="loading-bar" name="cd-animation-type">
                          <label for="loading-bar">Loading Bar</label>
                      </li>

                      <li>
                          <input type="radio" id="slide" name="cd-animation-type">
                          <label for="slide">Slide</label>
                      </li>

                      <li>
                          <input type="radio" id="clip" name="cd-animation-type">
                          <label for="clip">Clip</label>
                      </li>

                      <li>
                          <input type="radio" id="zoom" name="cd-animation-type">
                          <label for="zoom">Zoom</label>
                      </li>

                      <li>
                          <input type="radio" id="rotate-3" name="cd-animation-type">
                          <label for="rotate-3">Rotate 3</label>
                      </li>

                      <li>
                          <input type="radio" id="scale" name="cd-animation-type">
                          <label for="scale">Scale</label>
                      </li>

                      <li>
                          <input type="radio" id="push" name="cd-animation-type">
                          <label for="push">Push</label>
                      </li>
                  </ul> -->
                    <section class="cd-intro">
                        <h1 class="cd-headline slide">
                            <span cbicTranslate="searchInformation"></span>
                            <span class="cd-words-wrapper">
                                <b class="is-visible" cbicTranslate="acts">Acts</b>
                                <b cbicTranslate="rules">Rules</b>
                                <b cbicTranslate="regulations">Regulations</b>
                                <b cbicTranslate="forms">Forms</b>
                                <!-- <b class="is-visible">All Taxes</b>
                                <b>All Acts</b>
                                <b>Rules</b>
                                <b>Notifications</b>
                                <b>All Contents</b> -->
                            </span>
                        </h1>
                    </section>
                    <cbic-searchbar></cbic-searchbar>
                </div>
                <div class="bg-effect-update">
                    <section class="update-sec">
                        <div class="tab-content" id="myTabContent">
                            <div class=" show" id="gst" role="tabpanel" aria-labelledby="gst-tab">
                                <div class="" >
                                    <ul class="nav nav-tabs" id="myTab" role="tablist" style="border-bottom: 1px solid;border-bottom-color:#e19f20">
                                       
                                        <li class="nav-item" role="presentation">
                                            <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true" cbicTranslate="latestUpdate"></a>
                                        </li>
                                        <li class="nav-item noPadding" style="padding-left: 18%;">
                                            
                                        </li>                                       
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="gst-tab" data-bs-toggle="tab"
                                                data-bs-target="#gst1" type="button" role="tab" aria-controls="gst"
                                                aria-selected="true" (click)="loadUpdates('GST')" cbicTranslate="gst"></button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="ct-tab" data-bs-toggle="tab" data-bs-target="#gst1"
                                                type="button" role="tab" aria-controls="ct"
                                                aria-selected="false" (click)="loadUpdates('Customs')" cbicTranslate="customs"></button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="ce-tab" data-bs-toggle="tab" data-bs-target="#gst1"
                                                type="button" role="tab" aria-controls="ce" aria-selected="false"  (click)="loadUpdates('Central Excise')" cbicTranslate="centralExcise">
                                                </button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="st-tab" data-bs-toggle="tab" data-bs-target="#gst1"
                                                type="button" role="tab" aria-controls="st" aria-selected="false"  (click)="loadUpdates('Service Tax')" cbicTranslate="serviceTax">
                                                </button>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade show active" id="gst1" role="tabpanel"
                                            aria-labelledby="gst-tab">
                                            <div class="row align-items-center">
                                              
                                                <div class="col" *ngFor="let col of gstUpdates">
                                                    
                                                    <div class="heading">
                                                       <p class="content-type" *ngIf="language === 'en' && col.updateType === 'Notification'"> {{col.updateType}}</p>
                                                        <p class="content-type" *ngIf="language === 'hi' && col.updateType === 'Notification'"> सूचनाएं</p>
                                                        <p class="content-type" *ngIf="language === 'en' && col.updateType === 'Circular'"> {{col.updateType}}</p>
                                                        <p class="content-type" *ngIf="language === 'hi' && col.updateType === 'Circular'"> परिपत्र</p>
                                                        <!-- <span class="content-date">{{col.updatedDate}}</span> -->

                                                        <span class="content-date" *ngIf="language === 'hi' && col.updateType === 'Notification' "> {{col.updatedDateHi}}</span>
                                                            <span class="content-date" *ngIf="language === 'en' && col.updateType === 'Notification' "> {{col.updatedDate}}</span>
                                                            <span class="content-date" *ngIf="language === 'hi' && col.updateType === 'Circular' "> {{col.updatedDateHi}}</span>
                                                            <span class="content-date" *ngIf="language === 'en' && col.updateType === 'Circular' "> {{col.updatedDate}}</span>
                                            
                                                    </div>
                                                    <div class="data ">
                                                        <p class="wrap-noti-text" style="font-size: 12px; " *ngIf="language === 'en' " title=" {{col.notificationNo}}-{{col.notificationName}}"> {{col.notificationNo}}-{{col.notificationNameToBeDisplay}}</p>
                                                        <p class="wrap-noti-text" style="font-size: 12px; " *ngIf="language === 'hi' && col.notificationNoHi!=null && col.notificationNoHi!='' && col.notificationNameHi!=null  && col.notificationNameHi!='' " title=" {{col.notificationNoHi}}-{{col.notificationNameHi}}"> {{col.notificationNoHi}}-{{col.notificationNameToBeDisplayHi}}</p>
                                                        
                                                    <p class="wrap-noti-text" style="font-size: 12px; " *ngIf="language === 'hi' && (col.notificationNoHi===null || col.notificationNoHi==='' )
                                                    && (col.notificationNameHi===null || col.notificationNameHi==='')
                                                    " title=" {{col.notificationNo}}-{{col.notificationName}}"> {{col.notificationNo}}-{{col.notificationNameToBeDisplay}}</p>
                                            
                                                   <!--  <p class="wrap-noti-text" style="font-size: 12px; " title="{{col.notificationNo}}-{{col.notificationName}}">{{col.notificationNo}}-{{col.notificationNameToBeDisplay}}</p> -->
                                                    </div>
                                                    <div class="notification-download-container">
                                                        <span  >
                                                            <a [routerLink]="" (click)="viewDocumentsV2( col.docFilePath, col.id, 'ENG', col.updateType)" *ngIf="col.docFilePath">English</a>
                                                        </span> 
                                                        <span >
                                                            <a [routerLink]="" (click)="downloadDocuments(col.updateType,col.id, 'ENG')" *ngIf="col.docFilePath">  
                                                                <i class="fa fa-download" aria-hidden="true"></i>
                                                            </a> 
                                                            &nbsp;&nbsp;&nbsp; 
                                                            <a [routerLink]="" (click)="viewDocumentsV2( col.docFilePathHi, col.id, 'HINDI', col.updateType)" *ngIf="col.docFilePathHi">
                                                                हिन्दी
                                                            </a></span> 
                                                        <span>
                                                            <a [routerLink]="" (click)="downloadDocuments(col.updateType,col.id, 'HINDI')" *ngIf="col.docFilePathHi">  
                                                                <i class="fa fa-download" aria-hidden="true">

                                                                </i>
                                                            </a>
                                                        </span>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       <!-- <div class="tab-pane fade" id="ct" role="tabpanel" aria-labelledby="ct-tab">
                                            <div class="">
                                                <div class="row align-items-center">
                                                    
                                                    <div class="col" *ngFor="let col of gstUpdates">
                                                    
                                                        <div class="heading">
                                                            <p class="content-type">{{col.updateType}}  <span class="content-date"><br />{{col.updatedDate}}</span></p>
                                                            
                                                        </div>
                                                        <div class="data ">
                                                        <p class="wrap-noti-text" style="font-size: 12px; " title="{{col.notificationNo}}-{{col.notificationName}}">{{col.notificationNo}}-{{col.notificationName}}</p>
                                                        </div>
                                                        <div class="notification-download-container">
                                                            <span  ><a [routerLink]="" (click)="viewDocuments( col.docFilePath)" *ngIf="col.docFilePath">English</a></span> <span ><a [routerLink]="" (click)="downloadDocuments(col.updateType,col.id, 'ENG')" *ngIf="col.docFilePath">  <i class="fa fa-download" aria-hidden="true"></i></a> &nbsp;&nbsp;&nbsp; <a [routerLink]="" (click)="viewDocuments( col.docFilePathHi)" *ngIf="col.docFilePathHi">हिन्दी</a></span> <span ><a [routerLink]="" (click)="downloadDocuments(col.updateType,col.id, 'HINDI')" *ngIf="col.docFilePathHi">  <i class="fa fa-download" aria-hidden="true"></i></a></span>
                                                           
                                                        </div>
                                                    </div> 
                                                      
                                                </div>
                                            </div>
                                        </div>-->
                                        <div class="tab-pane fade" id="ce" role="tabpanel" aria-labelledby="ce-tab">
                                            <div class="container">
                                                <div class="row align-items-center">
                                                    <div class="col" *ngFor="let col of gstUpdates">
                                                    
                                                        <div class="heading">
                                                            <p class="content-type" *ngIf="language === 'en' && col.updateType === 'Notification'"> {{col.updateType}}</p>
                                                            <p class="content-type" *ngIf="language === 'hi' && col.updateType === 'Notification'"> सूचनाएं</p>
                                                            <p class="content-type" *ngIf="language === 'en' && col.updateType === 'Circular'"> {{col.updateType}}</p>
                                                            <p class="content-type" *ngIf="language === 'hi' && col.updateType === 'Circular'"> परिपत्र</p>
                                                            <!-- <span class="content-date"><br />{{col.updatedDate}}</span> -->

                                                            <span class="content-date" *ngIf="language === 'hi' && col.updateType === 'Notification' "> {{col.updatedDateHi}}</span>
                                                            <span class="content-date" *ngIf="language === 'en' && col.updateType === 'Notification' "> {{col.updatedDate}}</span>
                                                            <span class="content-date" *ngIf="language === 'hi' && col.updateType === 'Circular' ">{{col.updatedDateHi}}</span>
                                                            <span class="content-date" *ngIf="language === 'en' && col.updateType === 'Circular' "> {{col.updatedDate</span>
                                            
                                                        </div>
                                                        <div class="data ">
                                                            <p class="wrap-noti-text" style="font-size: 12px; " *ngIf="language === 'en' " title=" {{col.notificationNo}}-{{col.notificationName}}"> {{col.notificationNo}}-{{col.notificationName}}</p>
                                                            <p class="wrap-noti-text" style="font-size: 12px; " *ngIf="language === 'hi' && col.notificationNoHi!=null && col.notificationNoHi!='' && col.notificationNameHi!=null && col.notificationNameHi!='' " title=" {{col.notificationNoHi}}-{{col.notificationNameHi}}"> {{col.notificationNoHi}}-{{col.notificationNameHi}}</p>
                                                            <p class="wrap-noti-text" style="font-size: 12px; " *ngIf="language === 'hi'
                                                            && (col.notificationNoHi===null || col.notificationNoHi==='' )
                                                            && (col.notificationNameHi===null || col.notificationNameHi==='') " title=" {{col.notificationNo}}-{{col.notificationName}}"> {{col.notificationNo}}-{{col.notificationName}}</p>
                                                
                                                        <!-- <p class="wrap-noti-text" style="font-size: 12px; " title="{{col.notificationNo}}-{{col.notificationName}}">{{col.notificationNo}}-{{col.notificationName}}</p> -->
                                                        </div>
                                                        <div class="notification-download-container">
                                                            <span  ><a [routerLink]="" (click)="viewDocuments( col.docFilePath)" *ngIf="col.docFilePath">English</a></span> <span ><a [routerLink]="" (click)="downloadDocuments(col.updateType,col.id, 'ENG')" *ngIf="col.docFilePath">  <i class="fa fa-download" aria-hidden="true"></i></a>  &nbsp;&nbsp;&nbsp;  <a [routerLink]="" (click)="viewDocuments( col.docFilePathHi)" *ngIf="col.docFilePathHi">हिन्दी</a></span> <span ><a [routerLink]="" (click)="downloadDocuments(col.updateType,col.id, 'HINDI')" *ngIf="col.docFilePathHi">  <i class="fa fa-download" aria-hidden="true"></i></a></span>
                                                           
                                                        </div>
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="st" role="tabpanel" aria-labelledby="st-tab">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col" *ngFor="let col of gstUpdates">
                                                    
                                                        <div class="heading">
                                                            <p class="content-type" *ngIf="language === 'en' && col.updateType === 'Notification'"> {{col.updateType}}</p>
                                                            <p class="content-type" *ngIf="language === 'hi' && col.updateType === 'Notification'"> सूचनाएं</p>
                                                            <p class="content-type" *ngIf="language === 'en' && col.updateType === 'Circular'"> {{col.updateType}}</p>
                                                            <p class="content-type" *ngIf="language === 'hi' && col.updateType === 'Circular'"> परिपत्र</p>
                                                            
                                                            <span class="content-date" *ngIf="language === 'hi' && col.updateType === 'Notification' "> {{col.updatedDateHi}}</span>
                                                            <span class="content-date" *ngIf="language === 'en' && col.updateType === 'Notification' "> {{col.updatedDate}}</span>
                                                            <span class="content-date" *ngIf="language === 'hi' && col.updateType === 'Circular' "> {{col.updatedDateHi</span>
                                                            <span class="content-date" *ngIf="language === 'en' && col.updateType === 'Circular' "> {{col.updatedDate}}</span>
                                            
                                                            
                                                            
                                                        </div>
                                                        <div class="data ">
                                                            <p class="wrap-noti-text" style="font-size: 12px; " *ngIf="language === 'en' " title=" {{col.notificationNo}}-{{col.notificationName}}"> {{col.notificationNo}}-{{col.notificationName}}</p>
                                                            <p class="wrap-noti-text" style="font-size: 12px; " *ngIf="language === 'hi' && col.notificationNoHi!=null && col.notificationNoHi!='' && col.notificationNameHi!=null && col.notificationNameHi!='' " title=" {{col.notificationNoHi}}-{{col.notificationNameHi}}"> {{col.notificationNoHi}}-{{col.notificationNameHi}}</p>
                                                            <p class="wrap-noti-text" style="font-size: 12px; " *ngIf="language === 'hi'  && (col.notificationNoHi===null || col.notificationNoHi==='' )
                                                            && (col.notificationNameHi===null || col.notificationNameHi==='')
                                                            " title=" {{col.notificationNo}}-{{col.notificationName}}"> {{col.notificationNo}}-{{col.notificationName}}</p>
                                                
                                                        <!-- <p class="wrap-noti-text" style="font-size: 12px; " title="{{col.notificationNo}}-{{col.notificationName}}">{{col.notificationNo}}-{{col.notificationName}}</p> -->
                                                        </div>
                                                        <div class="notification-download-container">
                                                            <span  ><a [routerLink]="" (click)="viewDocuments( col.docFilePath)" *ngIf="col.docFilePath">English</a></span> <span ><a [routerLink]="" (click)="downloadDocuments(col.updateType,col.id, 'ENG')" *ngIf="col.docFilePath">  <i class="fa fa-download" aria-hidden="true"></i></a>  &nbsp;&nbsp;&nbsp;  <a [routerLink]="" (click)="viewDocuments( col.docFilePathHi)" *ngIf="col.docFilePathHi">हिन्दी</a></span> <span ><a [routerLink]="" (click)="downloadDocuments(col.updateType,col.id, 'HINDI')" *ngIf="col.docFilePathHi">  <i class="fa fa-download" aria-hidden="true"></i></a> </span>
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-end">
                                            
                                            <!-- <a *ngIf="language === 'en' " class="nav-link-home-custom"  (click) = "navigateCustomNotification()">  <label cbicTranslate="customNontarrif"></label></a> &nbsp;&nbsp;&nbsp; 
                                            <a *ngIf="language === 'hi' " class="nav-link-home-custom"  (click) = "navigateCustomNotification()">  <label cbicTranslate="customNontarrif"></label></a> &nbsp;&nbsp;&nbsp;  -->
                                      <!--       <a *ngIf="language === 'en' " class="nav-link-home"  (click) = "navigateToCustomTarrifGlobalSearch()">  <label cbicTranslate="customNontarrif"></label></a> &nbsp;&nbsp;&nbsp; 
                                            <a *ngIf="language === 'hi' " class="nav-link-home"  (click) = "navigateToCustomTarrifGlobalSearch()">  <label cbicTranslate="customNontarrif"></label></a> &nbsp;&nbsp;&nbsp;  -->
                                            <a *ngIf="language === 'en'" class="nav-link-home"  (click) = "navigateNotification()">  <label cbicTranslate="ViewLatest">View</label> {{currentSelectedTab}} <label cbicTranslate="notification">Notifications</label> ></a> &nbsp;&nbsp;&nbsp; 
                                            <a *ngIf="language === 'en'" class="nav-link-home" (click) = "navigateCirculars()"><label cbicTranslate="ViewLatest">View</label> {{currentSelectedTab}} <label cbicTranslate="circulars">Circulars</label> ></a>
                                            <a *ngIf="language === 'hi'" class="nav-link-home"  (click) = "navigateNotification()">  {{currentSelectedTab}} <label cbicTranslate="notification">Notifications</label> <label cbicTranslate="ViewLatest">View</label> ></a> &nbsp;&nbsp;&nbsp; 
                                            <a *ngIf="language === 'hi'" class="nav-link-home" (click) = "navigateCirculars()">{{currentSelectedTab}} <label cbicTranslate="circulars">Circulars</label> <label cbicTranslate="ViewLatest">View</label> ></a>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div div class="col-lg-2 col-md-12">
            </div>
            <br />
            <br />
            <br /><br /><br />
        </div>
    </div>

    <!-- <ng-template #content let-modal>
        <div class="modal-header">
            <h4 class="modal-title" style="text-align: center;  padding-left: 120px;" cbicTranslate="importantMessage">Important Message</h4>
            <button type="button" class="btn btn-light" (click)="modal.close('Close click')"><i class="fas fa-times"></i>
            </button>
        </div>
        <div class="modal-body">
            <p cbicTranslate="homeMsg">
            </p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light" (click)="modal.close('Close click')" cbicTranslate="continue">Continue</button>
        </div>
    </ng-template> -->