import * as dayjs from 'dayjs';

export interface ICbicContentHistoryMapDtls {
  id?: number;
  contentParentId?: number | null;
  contentChildId?: number | null;
  childNotificationNo?: string | null;
  parentNotificationNo?: string | null;
  childNotificationName?: string | null;
  parentNotificationName?: string | null;
  childNotificationDt?: dayjs.Dayjs | null;
  parentNotificationDt?: dayjs.Dayjs | null;
  contentType?: string | null;
  isActive?: string | null;
  createdDt?: dayjs.Dayjs | null;
  updatedDt?: dayjs.Dayjs | null;
  createdBy?: number | null;
  updatedBy?: number | null;
  taxId?: number | null;
  docFilePathAOD?: string | null;
  docFileNameAOD?: string | null;
}

export class CbicContentHistoryMapDtls implements ICbicContentHistoryMapDtls {
  constructor(
    public id?: number,
    public contentParentId?: number | null,
    public contentChildId?: number | null,
    public childNotificationNo?: string | null,
    public parentNotificationNo?: string | null,
    public childNotificationName?: string | null,
    public parentNotificationName?: string | null,
    public childNotificationDt?: dayjs.Dayjs | null,
    public parentNotificationDt?: dayjs.Dayjs | null,
    public contentType?: string | null,
    public isActive?: string | null,
    public createdDt?: dayjs.Dayjs | null,
    public updatedDt?: dayjs.Dayjs | null,
    public createdBy?: number | null,
    public updatedBy?: number | null,
    public taxId?: number | null,
    public docFilePathAOD?: string | null,
    public docFileNameAOD?: string | null
  ) {}
}

export class HistoryRequest{
  constructor( 
    public parentContentId?: number | null,
    public childContentId?: number | null,
    public contentType?: string | null,
    public ntRemarks?: string | null){}
}

export class HistoryRequestV2{
  constructor(
    public historyId?: number | null
  )
  {}
}

export class HistoryResponse{
  constructor(
    public message?: string | null
  ){}
}

export class HistoryResponseV2{
  constructor(
    public msg?: string | null,
    public result?: boolean | null
  ){}
}

export function getCbicContentHistoryMapDtlsIdentifier(cbicContentHistoryMapDtls: ICbicContentHistoryMapDtls): number | undefined {
  return cbicContentHistoryMapDtls.id;
}
