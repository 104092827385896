import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { Search } from 'app/core/request/request.model';
import { ICbicRegulationDocMst, getCbicRegulationDocMstIdentifier } from '../cbic-regulation-doc-mst.model';

export type EntityResponseType = HttpResponse<ICbicRegulationDocMst>;
export type EntityArrayResponseType = HttpResponse<ICbicRegulationDocMst[]>;

@Injectable({ providedIn: 'root' })
export class CbicRegulationDocMstService {
  
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/cbic-regulation-doc-msts');
  public resourceSearchUrl = this.applicationConfigService.getEndpointFor('api/_search/cbic-regulation-doc-msts');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(cbicRegulationDocMst: ICbicRegulationDocMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicRegulationDocMst);
    return this.http
      .post<ICbicRegulationDocMst>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(cbicRegulationDocMst: ICbicRegulationDocMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicRegulationDocMst);
    return this.http
      .put<ICbicRegulationDocMst>(`${this.resourceUrl}/${getCbicRegulationDocMstIdentifier(cbicRegulationDocMst) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(cbicRegulationDocMst: ICbicRegulationDocMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicRegulationDocMst);
    return this.http
      .patch<ICbicRegulationDocMst>(`${this.resourceUrl}/${getCbicRegulationDocMstIdentifier(cbicRegulationDocMst) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICbicRegulationDocMst>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  findByTaxId(taxId:number):Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicRegulationDocMst[]>(`${this.resourceUrl}/findByTaxId/${taxId}`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicRegulationDocMst[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }
  fetchRegulationCategory(taxId:number):Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicRegulationDocMst[]>(`${this.resourceUrl}/fetchRegulationCategory/${taxId}`, {  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  
  fetchCategoryRelatedRegulations(taxId:number, regulationCategory:string):Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicRegulationDocMst[]>(`${this.resourceUrl}/fetchCategoryRelatedRegulationDocs/${taxId}/${regulationCategory}`, {  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }
  search(req: Search): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicRegulationDocMst[]>(this.resourceSearchUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  addCbicRegulationDocMstToCollectionIfMissing(
    cbicRegulationDocMstCollection: ICbicRegulationDocMst[],
    ...cbicRegulationDocMstsToCheck: (ICbicRegulationDocMst | null | undefined)[]
  ): ICbicRegulationDocMst[] {
    const cbicRegulationDocMsts: ICbicRegulationDocMst[] = cbicRegulationDocMstsToCheck.filter(isPresent);
    if (cbicRegulationDocMsts.length > 0) {
      const cbicRegulationDocMstCollectionIdentifiers = cbicRegulationDocMstCollection.map(
        cbicRegulationDocMstItem => getCbicRegulationDocMstIdentifier(cbicRegulationDocMstItem)!
      );
      const cbicRegulationDocMstsToAdd = cbicRegulationDocMsts.filter(cbicRegulationDocMstItem => {
        const cbicRegulationDocMstIdentifier = getCbicRegulationDocMstIdentifier(cbicRegulationDocMstItem);
        if (cbicRegulationDocMstIdentifier == null || cbicRegulationDocMstCollectionIdentifiers.includes(cbicRegulationDocMstIdentifier)) {
          return false;
        }
        cbicRegulationDocMstCollectionIdentifiers.push(cbicRegulationDocMstIdentifier);
        return true;
      });
      return [...cbicRegulationDocMstsToAdd, ...cbicRegulationDocMstCollection];
    }
    return cbicRegulationDocMstCollection;
  }

  protected convertDateFromClient(cbicRegulationDocMst: ICbicRegulationDocMst): ICbicRegulationDocMst {
    return Object.assign({}, cbicRegulationDocMst, {
      createdDt: cbicRegulationDocMst.createdDt?.isValid() ? cbicRegulationDocMst.createdDt.toJSON() : undefined,
      updatedDt: cbicRegulationDocMst.updatedDt?.isValid() ? cbicRegulationDocMst.updatedDt.toJSON() : undefined,
      issueDt: cbicRegulationDocMst.issueDt?.isValid() ? cbicRegulationDocMst.issueDt.toJSON() : undefined,
      amendDt: cbicRegulationDocMst.amendDt?.isValid() ? cbicRegulationDocMst.amendDt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDt = res.body.createdDt ? dayjs(res.body.createdDt) : undefined;
      res.body.updatedDt = res.body.updatedDt ? dayjs(res.body.updatedDt) : undefined;
      res.body.issueDt = res.body.issueDt ? dayjs(res.body.issueDt) : undefined;
      res.body.amendDt = res.body.amendDt ? dayjs(res.body.amendDt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((cbicRegulationDocMst: ICbicRegulationDocMst) => {
        cbicRegulationDocMst.createdDt = cbicRegulationDocMst.createdDt ? dayjs(cbicRegulationDocMst.createdDt) : undefined;
        cbicRegulationDocMst.updatedDt = cbicRegulationDocMst.updatedDt ? dayjs(cbicRegulationDocMst.updatedDt) : undefined;
        cbicRegulationDocMst.issueDt = cbicRegulationDocMst.issueDt ? dayjs(cbicRegulationDocMst.issueDt) : undefined;
        cbicRegulationDocMst.amendDt = cbicRegulationDocMst.amendDt ? dayjs(cbicRegulationDocMst.amendDt) : undefined;
      });
    }
    return res;
  }
}
