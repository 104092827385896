import * as dayjs from 'dayjs';
import { ICbicTaxMst } from 'app/entities/cbic-tax-mst/cbic-tax-mst.model';

export interface ICbicOthersDocumentMst {
  id?: number;
  contentId?: number | null;
  contentLanguage?: string | null;
  isActive?: string | null;
  createdDt?: dayjs.Dayjs | null;
  updatedDt?: dayjs.Dayjs | null;
  isAmended?: string | null;
  isOmitted?: string | null;
  parentId?: number | null;
  orderId?: number | null;
  versionNo?: number | null;
  createdBy?: number | null;
  updatedBy?: number | null;
  documentName?: string | null;
  documentType?: string | null;
  othersDocCategory?: string | null;
  docFilePath?: string | null;
  docFileName?: string | null;
  docFilePathHi?: string | null;
  docFileNameHi?: string | null;
  tax?: ICbicTaxMst | null;
}

export class CbicOthersDocumentMst implements ICbicOthersDocumentMst {
  constructor(
    public id?: number,
    public contentId?: number | null,
    public contentLanguage?: string | null,
    public isActive?: string | null,
    public createdDt?: dayjs.Dayjs | null,
    public updatedDt?: dayjs.Dayjs | null,
    public isAmended?: string | null,
    public isOmitted?: string | null,
    public parentId?: number | null,
    public orderId?: number | null,
    public versionNo?: number | null,
    public createdBy?: number | null,
    public updatedBy?: number | null,
    public documentName?: string | null,
    public documentType?: string | null,
    public othersDocCategory?: string | null,
    public docFilePath?: string | null,
    public docFileName?: string | null,
    public docFilePathHi?: string | null,
    public docFileNameHi?: string | null,
    public tax?: ICbicTaxMst | null
  ) {}
}

export class CbicOtherDocumentMstRequest{
  constructor(
    public othersDocCategory:string
  ){}
}

export class CbicOtherDocumentMstResponse{
  constructor(
    public pdfData?: string | null,
    public fileName ?: string | null
  ){}
}

export function getCbicOthersDocumentMstIdentifier(cbicOthersDocumentMst: ICbicOthersDocumentMst): number | undefined {
  return cbicOthersDocumentMst.id;
}
