<section class="search-sec">
    <div class="container">
        <form action="#" method="post" novalidate="novalidate">
            <div class="row">
                <div class="col-lg-12">
                    <form name="searchBarFormRule"   role="form" [formGroup]="searchBarFormRule" novalidate="novalidate" (ngSubmit)="search()">
                    <div class="row">
                        <div class="input-group flex-nowrap">
                            <button *ngIf="taxId === 1000002 || taxId === 1000003" class="btn disablebtn" cbicTranslate="selectCategory">Select Category</button>
                            <select *ngIf="taxId === 1000002 || taxId === 1000003" class="form-select" #ruleCategorySelection
                                    id="inputGroupSelectCategoryForContentPage"
                                    aria-label="Category Selection" style="width: 5%;" 
                                    (change)="changeCategorySelection(ruleCategorySelection.value)">
                                
                                    <option *ngIf="storageLanguage === 'en'" value="All">All</option> 
                                    <option *ngIf="storageLanguage === 'hi'" value="सभी">सभी</option> 
         
                                <option *ngFor="let category of rulesCategoryList; let i = index"  value="{{category.ruleCategory}}" >
                                    <div *ngIf="storageLanguage === 'hi'">{{category.ruleCategoryHi}}</div>
                                    <div *ngIf="storageLanguage === 'en'">{{category.ruleCategory}}</div>
                                    
                                </option>
                                
                            </select>
                            <span *ngIf="taxId === 1000002 || taxId === 1000003" class="btn disablebtn" class="input-group-text" style="background: #F1F1E6;"></span>
                            <button class="btn disablebtn" cbicTranslate="selectRule"></button>
                            <select class="form-select" #ruleSelection
                                id="inputGroupSelectActListForContentPage"
                                aria-label="Rules Selection"
                                formControlName="actId" [(ngModel)]="selectedRule" style="width: 10%;" 
                                (change)='fireRuleSelected(ruleSelection.value)' >
                                
                                <option *ngFor="let rule of rulesListFetched; let i = index"  title="{{rule.ruleDocName}}" value="{{rule.id}}" [selected]="i === 0"  >
                                    {{rule.ruleDocNameLable}}
                                </option>
                                
                                
                            </select>
                            <span class="input-group-text" style="background: #F1F1E6;"></span>
                            <!-- <button class="btn disablebtn">Chapter</button>
                            <select class="form-select"
                                id="inputGroupSelectChapterListForContentPage" #chpterSelection
                                aria-label="Chapter Selection" formControlName="chapterId" 
                                (change)='fireChapterSeleted(chpterSelection.value)'
                                [(ngModel)]="selectedChapter">
                                <option  value=""  >All chapters</option>
                                <option *ngFor="let chapter of rulesChapterList; let i = index" value="{{chapter.id}}" >{{chapter.chapterNo}}</option>
                                
                            </select> -->
                            <input class="form-control" list="datalistOptionsRules" id="exampleDataListRules" #sectionSelection
                                placeholder="{{ 'enterRuleNumber' | translate }}" formControlName="sectionId" (change)="fireRuleSectionSelected(sectionSelection.value)">
                                <datalist id="datalistOptionsRules " *ngIf="storageLanguage === 'en'">
                                    <option *ngFor="let section of ruleSectionList; let i = index"  data-value="{{section.id}}" value="{{section.sectionNo}}-{{section.sectionName}}"></option>
                                        <input type="hidden" name="answer" id="exampleDataListRules-hidden">
                                </datalist>
                                <datalist id="datalistOptionsRules" *ngIf="storageLanguage === 'hi'">
                                    <option *ngFor="let section of ruleSectionList; let i = index"  data-value="{{section.id}}" value="{{section.sectionNoHi ? section.sectionNoHi : section.sectionNo}}-{{section.sectionNameHi ?section.sectionNameHi:section.sectionName }}"></option>
                                        <input type="hidden" name="answer" id="exampleDataListRules-hidden">
                                </datalist>
                                <span class="input-group-text" style="background: #F1F1E6;"></span>
                                <input type="text" class="form-control" placeholder="{{ 'enterKeyword' | translate }}" aria-label="Username" maxlength="100" #ruleSearchKeyword
                                aria-describedby="addon-wrapping" formControlName="keyword" autocomplete="off" [(ngModel)]="searchKeyWord"> 
                            <button class="btn" *ngIf="!searched" type="button" (click)="search()"><fa-icon [icon]="faSearch"></fa-icon></button>
                            <button class="btn" *ngIf="searched" type="button" (click)="clear()"><fa-icon [icon]="faTimesCircle"></fa-icon></button>
                            
                        </div>
                        <br/>
                        <div class="advance-search">
                            <a *ngIf="storageLanguage === 'en'" [routerLink]="" (click)="openAllSearch()"><u>Click here </u>to search across all {{taxName}} {{tabName}}</a>
                            <a *ngIf="storageLanguage === 'hi'" [routerLink]="" (click)="openAllSearch()">सभी {{taxName}} {{tabName}} में खोजने के लिए यहां <u>क्लिक करें</u></a>
                        </div>
                        
                        <!-- <div class="advance-search">
                            Advance Search
                        </div> -->
                    </div>
                    </form>
                </div>
            </div>
        </form>
    </div>
</section>

<ng-template #allSearch let-modal>
    <div class="modal-content all-search-modal">
        <div class="modal-header page-header">
            <h4 *ngIf="storageLanguage === 'en'">Search across all {{taxName}} {{tabName}}</h4>
            <h4 *ngIf="storageLanguage === 'hi'">सभी {{taxName}} {{tabName}} में खोजें</h4>
            
            <button type="button" class="btn btn-light" (click)="modal.close('Close click')"><i
                    class="fas fa-times"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-12">
                    <form name="searchBarFormRuleAll" role="form" [formGroup]="searchBarFormRuleAll"
                        novalidate="novalidate" (ngSubmit)="searchAll()">
                        <div class="input-group flex-nowrap search-sec">
                            <input type="text" class="form-control" placeholder="{{ 'enterKeyword' | translate }}" aria-label="Username"
                                maxlength="100" #allRuleSearchKeyword aria-describedby="addon-wrapping"
                                formControlName="keywordAll" autocomplete="off"/>
                                <button class="btn" type="button" (click)="searchAll()"><fa-icon [icon]="faSearch"></fa-icon></button>
                        </div>
                        <p *ngIf="allSearchMsg" class="all-search-msg">{{allSearchMsg}}</p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-template>