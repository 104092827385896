<div class="row">
    <div class="col-md-12 d-lg-none noPadding">
        <div class="col-md-4 pull-left">
            <a href="/">
                <img [src]="cbicLogoSrc" alt="Central Board of Indirect Taxes and Customs" class="logo">
            </a>
        </div>
        <div class="col-md-4 pull-left">
            <div class="top-bar-title">
                <div class="top-bar-title-HN top-bar-title-border">केंद्रीय अप्रत्यक्ष कर एवं सीमा शुल्क बोर्ड</div>
                <div>Central Board of Indirect Taxes & Customs</div>
            </div>
        </div>
        <div class="col-md-4 pull-right">
            <a href="/" class="emblem">
                <img [src]="logoSrc" alt="Central Board of Indirect Taxes and Customs" class="logo">
            </a>
        </div>
    </div>
</div>
<div class="row" id="hide-on-small-screen">
    <div class="col-md-7">
        <div class="row">
            <div class="col-md-2 d-flex justify-content-center d-none d-lg-block">
                <a href="/">
                    <img [src]="cbicLogoSrc" alt="Central Board of Indirect Taxes and Customs" class="logo">
                </a>
            </div>
            <div class="col-md-8 d-flex justify-content-center d-none d-lg-block" id="brand-text">
                <div class="top-bar-title">
                    <div class="top-bar-title-HN top-bar-title-border">केंद्रीय अप्रत्यक्ष कर एवं सीमा शुल्क बोर्ड</div>
                    <div>Central Board of Indirect Taxes & Customs</div>
                </div>
            </div>
            <div class="col-md-1 d-none d-lg-block noPadding">
                <a href="/" class="emblem">
                    <img [src]="logoSrc" alt="Central Board of Indirect Taxes and Customs" width="52">
                </a>
            </div>
        </div>
    </div>
    <div class="col-md-4 d-none d-lg-block right_section">


        <div class="col-md-12">

            <div class="input-group input-group-sm mb-3 nav-bar navbar-social float-end" style="float: right;">
                <div class="top-bar-portal-title" cbicTranslate="taxInformationPortal">
                    Tax Information Portal
                </div>
                <div class="navbar-social">
                    <a href=" https://ddei5-0-ctp.trendmicro.com:443/wis/clicktime/v1/query?url=https%3a%2f%2fwhatsapp.com%2fchannel%2f0029VaIHEmo9Gv7XcriPOW27&umid=3CCD6AEE-0E95-5F06-A240-2DDBEFBDAD92&auth=810ae9b9efa57b811c23b3c1988c7311b38de9ea-aa6b68532038a010bef0df09867de98d6bdb85fc"
                        target="_blank"><i class="whatsapp-icon"></i></a>

                    <a href="https://twitter.com/cbic_india" target="_blank">
                        <i class="twitter-icon"></i></a>

                    <a href="https://www.facebook.com/pages/CBEC-Central-Board-of-Excise-and-Customs/109132989432740"
                        target="_blank"><i class="fb-icon"></i></a>

                    <a href="https://www.youtube.com/channel/UC4Pwb1Utd_Pu3tEp09gMZlQ" target="_blank"><i
                            class="youtube-icon"></i></a>

                    <a href="https://instagram.com/cbicindia" target="_blank"><i class="insta-icon"></i></a>

                    <a href="https://www.kooapp.com/profile/cbic" target="_blank"><i class="koo-icon"></i></a>
                </div>


            </div>
        </div>
    </div>


</div>