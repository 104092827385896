import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import locale from '@angular/common/locales/en';
import { BrowserModule, Title } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { TranslateModule, TranslateService, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { NgxWebstorageModule } from 'ngx-webstorage';
import * as dayjs from 'dayjs';
import { NgbDateAdapter, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

import { SERVER_API_URL } from './app.constants';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import './config/dayjs';
import { SharedModule } from 'app/shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { HomeModule } from './home/home.module';
import { EntityRoutingModule } from './entities/entity-routing.module';
// jhipster-needle-angular-add-module-import JHipster will add new module here
import { NgbDateDayjsAdapter } from './config/datepicker-adapter';
import { fontAwesomeIcons } from './config/font-awesome-icons';
import { httpInterceptorProviders } from 'app/core/interceptor/index';
import { translatePartialLoader, missingTranslationHandler } from './config/translation.config';
import { MainComponent } from './layouts/main/main.component';
import { NavbarComponent } from './layouts/navbar/navbar.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { PageRibbonComponent } from './layouts/profiles/page-ribbon.component';
import { ActiveMenuDirective } from './layouts/navbar/active-menu.directive';
import { ErrorComponent } from './layouts/error/error.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import * as $ from 'jquery';
import { TopbarComponent } from './topbar/topbar.component';
import { MegaMenuItemComponent } from './layouts/mega-menu-item/mega-menu-item.component';
import { SearchSectionComponent } from './search-page/search-section/search-section.component';
import { SearchPageComponent } from './search-page/search-page.component';
import { SearchRefineComponent } from './search-page/search-refine/search-refine.component';
import { SearchResultsComponent } from './search-page/search-results/search-results.component';
import { ContentPageComponent } from './content-page/content-page.component';
import { SectionChapterNavigationComponent } from './content-page/section-chapter-navigation/section-chapter-navigation.component';
import { SectionReadComponent } from './content-page/section-read/section-read.component';
import { ContentSearchBarComponent } from './content-page/content-search-bar/content-search-bar.component';
import {SafeHtmlPipe} from 'app/content-page/SafeHtmlPipe';
import { InnerComponentComponent } from './content-page/section-read/inner-component/inner-component.component';
import { ContentErrorPageComponent } from './content-page/content-error-page/content-error-page.component';
import { CbiccoreerrorComponent } from './layouts/cbiccoreError/cbiccoreerror.component';
import { SpinnerComponent } from './layouts/spinner/spinner.component';
import { SuneditorComponent } from './suneditor/suneditor.component';

import { RulesContentSearchBarComponent } from './content-page/rules-content-search-bar/rules-content-search-bar.component';

import { RegulationSearchBarComponent } from './content-page/regulation-search-bar/regulation-search-bar.component';
import { FormsContentSearchBarComponent } from './content-page/forms-content-search-bar/forms-content-search-bar.component';
import { FormsNavigationComponent } from './content-page/forms-navigation/forms-navigation.component';
import { FormsReadComponent } from './content-page/forms-read/forms-read.component';
import { TableContentDisplayComponent } from './content-page/table-content-display/table-content-display.component';
import { NotificationReadComponent } from './content-page/notification-read/notification-read.component';
import { NotificationSearchComponent } from './content-page/notification-search/notification-search.component';
import { ScrollToSelectedDirective } from './content-page/section-chapter-navigation/scroll-to-selected.directive';
import { ServiceTaxRuleSearchbarComponent } from './content-page/service-tax-rule-searchbar/service-tax-rule-searchbar.component';
import { NotificationHistoryComponent } from './notification-history/notification-history.component';
import { NotificationHistoryRoutingModule } from './notification-history/notification-history-routing.module';
import { CircularSearchBarComponent } from './content-page/circular-search-bar/circular-search-bar.component';
import { linkPageComponent } from './link-page/link-page.component';
import { AmendOrdinanceComponent } from './amend-ordinance/amend-ordinance.component';
import { RelatedContentListComponent} from './content-page/related-content-list/related-content-list.component';
import { FormOpenComponent } from './content-page/form-open/form-open.component';
import { InstructionSearchBarComponent } from './content-page/instruction-search-bar/instruction-search-bar.component';
import { OrderSearchBarComponent } from './content-page/order-search-bar/order-search-bar.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ViewPdfComponent } from './content-page/view-pdf/view-pdf.component';
import { LinkHistoryComponent } from './link-history/link-history.component';
import { AlliedActReadComponent } from './content-page/allied-act-read/allied-act-read.component';
import { AlliedActSearchComponent } from './content-page/allied-act-search/allied-act-search.component';

@NgModule({
  imports: [
    BrowserModule,
    SharedModule,
    HomeModule,
    // jhipster-needle-angular-add-module JHipster will add new module here
    EntityRoutingModule,
    AppRoutingModule,
    // Set this to true to enable service worker (PWA)
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: false }),
    HttpClientModule,
    FontAwesomeModule,
    NgxWebstorageModule.forRoot({ prefix: 'cbic', separator: '-', caseSensitive: true }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translatePartialLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useFactory: missingTranslationHandler,
      },
    }),
    NotificationHistoryRoutingModule,
    PdfViewerModule,
    //NgxExtendedPdfViewerModule,
  ],
  providers: [
    Title,
    { provide: LOCALE_ID, useValue: 'en' },
    { provide: NgbDateAdapter, useClass: NgbDateDayjsAdapter },
    httpInterceptorProviders,
  ],
  declarations: [MainComponent, NavbarComponent, ErrorComponent, PageRibbonComponent, 
                ActiveMenuDirective, FooterComponent, TopbarComponent, MegaMenuItemComponent,
                SearchPageComponent, SearchSectionComponent, SearchRefineComponent, SearchResultsComponent, 
                ContentPageComponent, SectionChapterNavigationComponent, SectionReadComponent, ContentSearchBarComponent,
                SafeHtmlPipe, InnerComponentComponent, ContentErrorPageComponent, CbiccoreerrorComponent, 
                SpinnerComponent, SuneditorComponent, 
                RulesContentSearchBarComponent, 
                 RegulationSearchBarComponent,FormsContentSearchBarComponent, 
                FormsNavigationComponent, FormsReadComponent, TableContentDisplayComponent, NotificationReadComponent, 
                NotificationSearchComponent, ScrollToSelectedDirective, ServiceTaxRuleSearchbarComponent, 
                NotificationHistoryComponent, CircularSearchBarComponent, linkPageComponent,AmendOrdinanceComponent,
                RelatedContentListComponent, FormOpenComponent, InstructionSearchBarComponent, OrderSearchBarComponent, ViewPdfComponent,
                LinkHistoryComponent,
                AlliedActReadComponent,
                AlliedActSearchComponent],
  bootstrap: [MainComponent],
  exports:[ScrollToSelectedDirective]
})
export class AppModule {
  constructor(
    applicationConfigService: ApplicationConfigService,
    iconLibrary: FaIconLibrary,
    dpConfig: NgbDatepickerConfig,
    translateService: TranslateService
  ) {
    applicationConfigService.setEndpointPrefix(SERVER_API_URL);
    registerLocaleData(locale);
    iconLibrary.addIcons(...fontAwesomeIcons);
    dpConfig.minDate = { year: dayjs().subtract(100, 'year').year(), month: 1, day: 1 };
    translateService.setDefaultLang('en');
    translateService.use('en');
  }
}
