import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { Search } from 'app/core/request/request.model';
import { ICbicServiceTaxMst, getCbicServiceTaxMstIdentifier, ServiceTaxMstResponse } from '../cbic-service-tax-mst.model';
import { FormResponse } from 'app/entities/cbic-form-mst/cbic-form-mst.model';

export type EntityResponseType = HttpResponse<ICbicServiceTaxMst>;
export type EntityArrayResponseType = HttpResponse<ICbicServiceTaxMst[]>;

@Injectable({ providedIn: 'root' })
export class CbicServiceTaxMstService {
  
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/cbic-service-tax-msts');
  public resourceSearchUrl = this.applicationConfigService.getEndpointFor('api/_search/cbic-service-tax-msts');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(cbicServiceTaxMst: ICbicServiceTaxMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicServiceTaxMst);
    return this.http
      .post<ICbicServiceTaxMst>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(cbicServiceTaxMst: ICbicServiceTaxMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicServiceTaxMst);
    return this.http
      .put<ICbicServiceTaxMst>(`${this.resourceUrl}/${getCbicServiceTaxMstIdentifier(cbicServiceTaxMst) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  findBydataType(dataTypeTobeFetched: string) : Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicServiceTaxMst[]>(`${this.resourceUrl}/findByDataType/${dataTypeTobeFetched}`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  downloadFile(serviceTaxMstId: number,dataType:string):Observable<FormResponse>{
    return this.http.get<FormResponse>(`${this.resourceUrl}/download/${serviceTaxMstId}/${dataType}`);
 }

  partialUpdate(cbicServiceTaxMst: ICbicServiceTaxMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicServiceTaxMst);
    return this.http
      .patch<ICbicServiceTaxMst>(`${this.resourceUrl}/${getCbicServiceTaxMstIdentifier(cbicServiceTaxMst) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICbicServiceTaxMst>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicServiceTaxMst[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  search(req: Search): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicServiceTaxMst[]>(this.resourceSearchUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  addCbicServiceTaxMstToCollectionIfMissing(
    cbicServiceTaxMstCollection: ICbicServiceTaxMst[],
    ...cbicServiceTaxMstsToCheck: (ICbicServiceTaxMst | null | undefined)[]
  ): ICbicServiceTaxMst[] {
    const cbicServiceTaxMsts: ICbicServiceTaxMst[] = cbicServiceTaxMstsToCheck.filter(isPresent);
    if (cbicServiceTaxMsts.length > 0) {
      const cbicServiceTaxMstCollectionIdentifiers = cbicServiceTaxMstCollection.map(
        cbicServiceTaxMstItem => getCbicServiceTaxMstIdentifier(cbicServiceTaxMstItem)!
      );
      const cbicServiceTaxMstsToAdd = cbicServiceTaxMsts.filter(cbicServiceTaxMstItem => {
        const cbicServiceTaxMstIdentifier = getCbicServiceTaxMstIdentifier(cbicServiceTaxMstItem);
        if (cbicServiceTaxMstIdentifier == null || cbicServiceTaxMstCollectionIdentifiers.includes(cbicServiceTaxMstIdentifier)) {
          return false;
        }
        cbicServiceTaxMstCollectionIdentifiers.push(cbicServiceTaxMstIdentifier);
        return true;
      });
      return [...cbicServiceTaxMstsToAdd, ...cbicServiceTaxMstCollection];
    }
    return cbicServiceTaxMstCollection;
  }

  protected convertDateFromClient(cbicServiceTaxMst: ICbicServiceTaxMst): ICbicServiceTaxMst {
    return Object.assign({}, cbicServiceTaxMst, {
      createdDt: cbicServiceTaxMst.createdDt?.isValid() ? cbicServiceTaxMst.createdDt.toJSON() : undefined,
      updatedDt: cbicServiceTaxMst.updatedDt?.isValid() ? cbicServiceTaxMst.updatedDt.toJSON() : undefined,
      issueDt: cbicServiceTaxMst.issueDt?.isValid() ? cbicServiceTaxMst.issueDt.toJSON() : undefined,
      amendDt: cbicServiceTaxMst.amendDt?.isValid() ? cbicServiceTaxMst.amendDt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDt = res.body.createdDt ? dayjs(res.body.createdDt) : undefined;
      res.body.updatedDt = res.body.updatedDt ? dayjs(res.body.updatedDt) : undefined;
      res.body.issueDt = res.body.issueDt ? dayjs(res.body.issueDt) : undefined;
      res.body.amendDt = res.body.amendDt ? dayjs(res.body.amendDt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((cbicServiceTaxMst: ICbicServiceTaxMst) => {
        cbicServiceTaxMst.createdDt = cbicServiceTaxMst.createdDt ? dayjs(cbicServiceTaxMst.createdDt) : undefined;
        cbicServiceTaxMst.updatedDt = cbicServiceTaxMst.updatedDt ? dayjs(cbicServiceTaxMst.updatedDt) : undefined;
        cbicServiceTaxMst.issueDt = cbicServiceTaxMst.issueDt ? dayjs(cbicServiceTaxMst.issueDt) : undefined;
        cbicServiceTaxMst.amendDt = cbicServiceTaxMst.amendDt ? dayjs(cbicServiceTaxMst.amendDt) : undefined;
      });
    }
    return res;
  }
}
