import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { faSearch} from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

import { ProfileService } from 'app/layouts/profiles/profile.service';
import { CbicTaxMst, ICbicTaxMst } from 'app/entities/cbic-tax-mst/cbic-tax-mst.model';
import { CbicTaxMstService } from 'app/entities/cbic-tax-mst/service/cbic-tax-mst.service';
import { HttpResponse } from '@angular/common/http';
import { CbicActMst, ICbicActMst } from 'app/entities/cbic-act-mst/cbic-act-mst.model';
import { CbicActMstService } from 'app/entities/cbic-act-mst/service/cbic-act-mst.service';
import { FormBuilder } from '@angular/forms';
import { CbicContent, CbicContentHeader, CbicContentSearch, CbicSearchInput, ICbicContent, ICbicSearchInput } from './searchbar.model';
import { CbicSearchService } from 'app/search-page/service/cbic-search-service';
import { CbicSearchOutputResult } from 'app/search-page/search-output.model';
import { SessionStorageService } from 'ngx-webstorage';


@Component({
  selector: 'cbic-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent implements OnInit {
  inProduction?: boolean;
  openAPIEnabled?: boolean;
  faSearch=faSearch;
  cbicTaxMsts?: ICbicTaxMst[];
  isLoading = false;
  cbicActMsts?: ICbicActMst[];
  cbicContents?: ICbicContent[];
  regexFormat =  /^[A-Za-z0-9/()-.,": ]*$/;
  cbicContentHeader?: CbicContentHeader[];
  contentId : any = null;
  @ViewChild("keyWordInput") keywordInput? : ElementRef;
  storageLanguage: any

  searchBarForm = this.fb.group({
    keyword : [],
    taxId : [],
    actId : [],
    content : [],
  });

  constructor(
    private router: Router,
    private profileService: ProfileService,
    private taxMstService: CbicTaxMstService,
    private actMstService: CbicActMstService,
    protected fb: FormBuilder,
    private service: CbicSearchService,
    private sessionStorage: SessionStorageService
  ) { }

  ngOnInit(): void {
    this.profileService.getProfileInfo().subscribe(profileInfo => {
      this.inProduction = profileInfo.inProduction;
      this.openAPIEnabled = profileInfo.openAPIEnabled;
    });

    this.loadTaxMsts();
    this.loadActs();
    this.loadContents();
    this.searchBarForm.patchValue({
      actId : "undefined",
      content : "undefined",
      taxId : "undefined"
    });
  }

  loadContents(): void {
    this.cbicContents = [];
    
    // this.cbicContents.push(new CbicContent('ACTS','Acts'),
    // new CbicContent('CIRCULARS','Circulars / Orders'),
    // new CbicContent('FORMS','Forms'),
    // new CbicContent('NOTIFICATIONS','Notifications'),
    // new CbicContent('REGULATIONS','Regulations'),
    // new CbicContent('RULES','Rules'),
    // )

    /* this.storageLanguage = this.sessionStorage.retrieve('locale')
    if (this.storageLanguage === "hi") {

      this.cbicContents.push(
        new CbicContent('ACTS','अधिनियमों'),
        new CbicContent('RULES','नियम'),
        new CbicContent('REGULATIONS','विनियमन'),
        new CbicContent('FORMS','फार्म'),
        new CbicContent('NOTIFICATIONS','सूचनाएं'),
        new CbicContent('CIRCULARS','परिपत्र'),
        new CbicContent('INSTRUCTIONS','निर्देश / दिशानिर्देश'),
        new CbicContent('ORDERS','आदेश')
        )
    } else{

      this.cbicContents.push(
        new CbicContent('ACTS','Acts'),
        new CbicContent('RULES','Rules'),
        new CbicContent('REGULATIONS','Regulations'),
        new CbicContent('FORMS','Forms'),
        new CbicContent('NOTIFICATIONS','Notifications'),
        new CbicContent('CIRCULARS','Circulars'),
        new CbicContent('INSTRUCTIONS','Instructions / Guidelines'),
        new CbicContent('ORDERS','Orders')
        )
    } */

    this.cbicContents.push(
      new CbicContent('ACTS','Acts'),
      new CbicContent('RULES','Rules'),
      new CbicContent('REGULATIONS','Regulations'),
      new CbicContent('FORMS','Forms'),
      new CbicContent('NOTIFICATIONS','Notifications'),
      new CbicContent('CIRCULARS','Circulars'),
      new CbicContent('INSTRUCTIONS','Instructions'),
      new CbicContent('ORDERS','Orders'),
      new CbicContent('ALLIEDACTS','Allied Acts')
      )

    // this.cbicContents.push(new CbicContent('ALL','All Contents'))
    // this.cbicContents.push(
    //   new CbicContent('ACTS','Acts'),
    //   new CbicContent('CIRCULARS','Circulars'),
    //   new CbicContent('FORMS','Forms'),
    //   new CbicContent('NOTIFICATIONS','Notifications'),
    //   new CbicContent('REGULATIONS','Regulations'),
    //   new CbicContent('RULES','Rules'))
    // this.cbicContents.push(new CbicContent(undefined,'All Contents'))
    
  }

  loadTaxMsts(): void{
    this.taxMstService.query().subscribe(
      (res: HttpResponse<ICbicTaxMst[]>) => {
        this.isLoading = false;
        // this.cbicTaxMsts = [];
        // this.cbicTaxMsts.push(new CbicTaxMst(undefined,undefined,'All Taxes','',undefined,undefined,undefined,undefined))
        this.cbicTaxMsts = res.body ?? [];
        
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  loadActs(): void{
    this.taxMstService.fetchContentHeader(new CbicContentSearch("","")).subscribe(
      (res: HttpResponse<CbicContentHeader[]>) => {
        // this.cbicContentHeader = [];
        // this.cbicContentHeader.push(new CbicContentHeader(undefined,"All"));
        this.cbicContentHeader = res.body ?? [];
        for(let i=0;i<this.cbicContentHeader.length;i++){
          let maxlen=this.cbicContentHeader[i].contentName.length;
          if(maxlen>70){
            maxlen=67;
          }
          this.cbicContentHeader[i].contentNameLable=String(this.cbicContentHeader[i].contentName.substring(0,maxlen));
          if(maxlen===67){
            this.cbicContentHeader[i].contentNameLable=String(this.cbicContentHeader[i].contentName.substring(0,maxlen))+"...";
          }
        }
      
      }
    );
   
  }

  showActsFromTaxId(taxId:any):void{
    if(taxId !== "undefined"){
      this.actMstService.findActList(Number(taxId)).subscribe(
        (res: HttpResponse<ICbicActMst[]>) => {
          this.isLoading = false;
          this.cbicActMsts = res.body ?? [];
          this.cbicActMsts.push(new CbicActMst(undefined,undefined,undefined,'All Acts','','',undefined,undefined,undefined,undefined,undefined,undefined,undefined,undefined,undefined,undefined));
        },
        () => {
          this.isLoading = false;
        }
      );
    }else{
      this.loadActs();
    }
    
  }

  save():void{
    const cbicSearchInput = this.createFromForm();
    let flag = false;
    // alert(this.regexFormat.test(String(cbicSearchInput.keyword)))
    if(!(cbicSearchInput.keyword === undefined || cbicSearchInput.keyword === null)){
      
        if(cbicSearchInput.keyword.length > 2){
          if(this.regexFormat.test(String(cbicSearchInput.keyword))){
            flag = true;
          }
        }
      
        
    } 
    if(flag){
     this.service.pageNumber = 1;
      this.service.searchInSection(cbicSearchInput).subscribe(
        (res: HttpResponse<CbicSearchOutputResult>) => {
            this.service.cbicSearchOutputResults = res.body ?? new CbicSearchOutputResult([], null);
          this.router.navigate(['searchPage/search'],{queryParams : cbicSearchInput});
        });
    }else{
      alert("Please enter valid keyword")
      this.keywordInput?.nativeElement.focus();
    }
    
   
  }

  showActsRules(taxId:any, contentType:any):void{
    if(taxId === "undefined"){
      taxId = "";
    }
    if(contentType === "undefined"){
      contentType = "";
    }
      this.taxMstService.fetchContentHeader(new CbicContentSearch(taxId,contentType)).subscribe(
        (res: HttpResponse<CbicContentHeader[]>) => {
          // this.cbicContentHeader = [];
          // this.cbicContentHeader.push(new CbicContentHeader(undefined,"All"));
          this.cbicContentHeader = res.body ?? [];
          for(let i=0;i<this.cbicContentHeader.length;i++){
            let maxlen=this.cbicContentHeader[i].contentName.length;
            if(maxlen>70){
              maxlen=67;
            }
            this.cbicContentHeader[i].contentNameLable=String(this.cbicContentHeader[i].contentName.substring(0,maxlen));
            if(maxlen===67){
              this.cbicContentHeader[i].contentNameLable=String(this.cbicContentHeader[i].contentName.substring(0,maxlen))+"...";
            }
          }
         
        }
      );
  }

  // testList(contentName : any, contentId : any):void{
  //   testDetails()
  //   this.contentId = contentId;
  // }

 
  protected createFromForm(): ICbicSearchInput {
    return {
      ...new CbicSearchInput(),
      keyword: (this.searchBarForm.get(['keyword'])!.value === "undefined" || this.searchBarForm.get(['keyword'])!.value === null)? null : this.searchBarForm.get(['keyword'])!.value.trim(),
      taxId: (this.searchBarForm.get(['taxId'])!.value === "undefined")? null: this.searchBarForm.get(['taxId'])!.value,
      actId: (this.searchBarForm.get(['actId'])!.value === "undefined")? null: this.searchBarForm.get(['actId'])!.value,
      content: (this.searchBarForm.get(['content'])!.value === "undefined")? null: this.searchBarForm.get(['content'])!.value,
      startIndex: 0,
      paginationFlag: true,
      rows: 10
    };
  }







}
