import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { Search } from 'app/core/request/request.model';
import { ICbicAssociateIpDtls, getCbicAssociateIpDtlsIdentifier } from '../cbic-associate-ip-dtls.model';

export type EntityResponseType = HttpResponse<ICbicAssociateIpDtls>;
export type EntityArrayResponseType = HttpResponse<ICbicAssociateIpDtls[]>;

@Injectable({ providedIn: 'root' })
export class CbicAssociateIpDtlsService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/cbic-associate-ip-dtls');
  public resourceSearchUrl = this.applicationConfigService.getEndpointFor('api/_search/cbic-associate-ip-dtls');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  checkLinkageAllowed(): Observable<EntityResponseType> {
    return this.http.get<ICbicAssociateIpDtls>(`${this.resourceUrl}/checkLinkageAllowed`, { observe: 'response' });
  }

  create(cbicAssociateIpDtls: ICbicAssociateIpDtls): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicAssociateIpDtls);
    return this.http
      .post<ICbicAssociateIpDtls>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(cbicAssociateIpDtls: ICbicAssociateIpDtls): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicAssociateIpDtls);
    return this.http
      .put<ICbicAssociateIpDtls>(`${this.resourceUrl}/${getCbicAssociateIpDtlsIdentifier(cbicAssociateIpDtls) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(cbicAssociateIpDtls: ICbicAssociateIpDtls): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicAssociateIpDtls);
    return this.http
      .patch<ICbicAssociateIpDtls>(`${this.resourceUrl}/${getCbicAssociateIpDtlsIdentifier(cbicAssociateIpDtls) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICbicAssociateIpDtls>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicAssociateIpDtls[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  search(req: Search): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicAssociateIpDtls[]>(this.resourceSearchUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  addCbicAssociateIpDtlsToCollectionIfMissing(
    cbicAssociateIpDtlsCollection: ICbicAssociateIpDtls[],
    ...cbicAssociateIpDtlsToCheck: (ICbicAssociateIpDtls | null | undefined)[]
  ): ICbicAssociateIpDtls[] {
    const cbicAssociateIpDtls: ICbicAssociateIpDtls[] = cbicAssociateIpDtlsToCheck.filter(isPresent);
    if (cbicAssociateIpDtls.length > 0) {
      const cbicAssociateIpDtlsCollectionIdentifiers = cbicAssociateIpDtlsCollection.map(
        cbicAssociateIpDtlsItem => getCbicAssociateIpDtlsIdentifier(cbicAssociateIpDtlsItem)!
      );
      const cbicAssociateIpDtlsToAdd = cbicAssociateIpDtls.filter(cbicAssociateIpDtlsItem => {
        const cbicAssociateIpDtlsIdentifier = getCbicAssociateIpDtlsIdentifier(cbicAssociateIpDtlsItem);
        if (cbicAssociateIpDtlsIdentifier == null || cbicAssociateIpDtlsCollectionIdentifiers.includes(cbicAssociateIpDtlsIdentifier)) {
          return false;
        }
        cbicAssociateIpDtlsCollectionIdentifiers.push(cbicAssociateIpDtlsIdentifier);
        return true;
      });
      return [...cbicAssociateIpDtlsToAdd, ...cbicAssociateIpDtlsCollection];
    }
    return cbicAssociateIpDtlsCollection;
  }

  protected convertDateFromClient(cbicAssociateIpDtls: ICbicAssociateIpDtls): ICbicAssociateIpDtls {
    return Object.assign({}, cbicAssociateIpDtls, {
      createdDt: cbicAssociateIpDtls.createdDt?.isValid() ? cbicAssociateIpDtls.createdDt.toJSON() : undefined,
      updatedDt: cbicAssociateIpDtls.updatedDt?.isValid() ? cbicAssociateIpDtls.updatedDt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDt = res.body.createdDt ? dayjs(res.body.createdDt) : undefined;
      res.body.updatedDt = res.body.updatedDt ? dayjs(res.body.updatedDt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((cbicAssociateIpDtls: ICbicAssociateIpDtls) => {
        cbicAssociateIpDtls.createdDt = cbicAssociateIpDtls.createdDt ? dayjs(cbicAssociateIpDtls.createdDt) : undefined;
        cbicAssociateIpDtls.updatedDt = cbicAssociateIpDtls.updatedDt ? dayjs(cbicAssociateIpDtls.updatedDt) : undefined;
      });
    }
    return res;
  }
}
