import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { Search } from 'app/core/request/request.model';
import { ICbicContentHistoryMapDtls, getCbicContentHistoryMapDtlsIdentifier, HistoryResponse, HistoryResponseV2 } from '../cbic-content-history-map-dtls.model';
import { RuleMstResponse } from 'app/entities/cbic-rule-mst/cbic-rule-mst.model';

export type EntityResponseType = HttpResponse<ICbicContentHistoryMapDtls>;
export type EntityArrayResponseType = HttpResponse<ICbicContentHistoryMapDtls[]>;
export type HistoryResponseType = HttpResponse<HistoryResponse>;
export type HistoryResponseTypeV2 = HttpResponse<HistoryResponseV2>;

@Injectable({ providedIn: 'root' })
export class CbicContentHistoryMapDtlsService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/cbic-content-history-map-dtls');
  public resourceSearchUrl = this.applicationConfigService.getEndpointFor('api/_search/cbic-content-history-map-dtls');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(cbicContentHistoryMapDtls: ICbicContentHistoryMapDtls): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicContentHistoryMapDtls);
    return this.http
      .post<ICbicContentHistoryMapDtls>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(cbicContentHistoryMapDtls: ICbicContentHistoryMapDtls): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicContentHistoryMapDtls);
    return this.http
      .put<ICbicContentHistoryMapDtls>(
        `${this.resourceUrl}/${getCbicContentHistoryMapDtlsIdentifier(cbicContentHistoryMapDtls) as number}`,
        copy,
        { observe: 'response' }
      )
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(cbicContentHistoryMapDtls: ICbicContentHistoryMapDtls): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicContentHistoryMapDtls);
    return this.http
      .patch<ICbicContentHistoryMapDtls>(
        `${this.resourceUrl}/${getCbicContentHistoryMapDtlsIdentifier(cbicContentHistoryMapDtls) as number}`,
        copy,
        { observe: 'response' }
      )
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICbicContentHistoryMapDtls>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicContentHistoryMapDtls[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  search(req: Search): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicContentHistoryMapDtls[]>(this.resourceSearchUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  addCbicContentHistoryMapDtlsToCollectionIfMissing(
    cbicContentHistoryMapDtlsCollection: ICbicContentHistoryMapDtls[],
    ...cbicContentHistoryMapDtlsToCheck: (ICbicContentHistoryMapDtls | null | undefined)[]
  ): ICbicContentHistoryMapDtls[] {
    const cbicContentHistoryMapDtls: ICbicContentHistoryMapDtls[] = cbicContentHistoryMapDtlsToCheck.filter(isPresent);
    if (cbicContentHistoryMapDtls.length > 0) {
      const cbicContentHistoryMapDtlsCollectionIdentifiers = cbicContentHistoryMapDtlsCollection.map(
        cbicContentHistoryMapDtlsItem => getCbicContentHistoryMapDtlsIdentifier(cbicContentHistoryMapDtlsItem)!
      );
      const cbicContentHistoryMapDtlsToAdd = cbicContentHistoryMapDtls.filter(cbicContentHistoryMapDtlsItem => {
        const cbicContentHistoryMapDtlsIdentifier = getCbicContentHistoryMapDtlsIdentifier(cbicContentHistoryMapDtlsItem);
        if (
          cbicContentHistoryMapDtlsIdentifier == null ||
          cbicContentHistoryMapDtlsCollectionIdentifiers.includes(cbicContentHistoryMapDtlsIdentifier)
        ) {
          return false;
        }
        cbicContentHistoryMapDtlsCollectionIdentifiers.push(cbicContentHistoryMapDtlsIdentifier);
        return true;
      });
      return [...cbicContentHistoryMapDtlsToAdd, ...cbicContentHistoryMapDtlsCollection];
    }
    return cbicContentHistoryMapDtlsCollection;
  }

  linkHistory(req:any, file1:File): Observable<HistoryResponseTypeV2>{
    // this.http.get(this.resourceUrl,{ observe: 'response' }).subscribe(
    //   res => console.log(String(res.body))
    // )
  //  const copy = {
  //   requestParams : req,
  //   file : file1
  //  }
  const copy = new FormData();
  copy.append("file", file1);
  copy.append("req", JSON.stringify(req));
   return this.http.post(`${this.resourceUrl}/link-history`, copy , { observe: 'response' });
  }


  linkHistoryWithoutFile(req:any): Observable<HistoryResponseTypeV2>{
  const copy = new FormData();
  copy.append("req", JSON.stringify(req));
   return this.http.post(`${this.resourceUrl}/link-history`, copy , { observe: 'response' });
  }

  getHistory(contentId : number):Observable<EntityArrayResponseType>{
    return this.http.get<ICbicContentHistoryMapDtls[]>(`${this.resourceUrl}/get-history/${contentId}`, { observe: 'response' });
  }

  unlinkContent(req:any):Observable<HistoryResponseTypeV2>{
    return this.http
    .post<HistoryResponseV2>(`${this.resourceUrl}/unlink-history`, JSON.stringify(req), { observe: 'response' });
  }

  deleteFile(req:any):Observable<HistoryResponseTypeV2>{
    return this.http.post<HistoryResponseV2>(`${this.resourceUrl}/delete-file-path`, JSON.stringify(req), { observe: 'response' });
  }

  updateFile(req:any, file1:File):Observable<HistoryResponseTypeV2>{
    const copy = new FormData();
    copy.append("file", file1);
    copy.append("req", JSON.stringify(req));
    return this.http.post<HistoryResponseV2>(`${this.resourceUrl}/update-file`, copy, { observe: 'response' });
  }

  fetchNotificationByChildId(contentId:number, contentType:string):Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicContentHistoryMapDtls[]>(`${this.resourceUrl}/fetchNotificationByChildId/${contentId}/${contentType}`, {  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  fetchCircularForHistory(contentId:number, contentType:string):Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicContentHistoryMapDtls[]>(`${this.resourceUrl}/fetchCircularForHistory/${contentId}/${contentType}`, {  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  downloadNotification(id: number, language: string):Observable<RuleMstResponse>{
    return this.http.get<RuleMstResponse>(`${this.resourceUrl}/download/${id}/${language}`);
  }

  protected convertDateFromClient(cbicContentHistoryMapDtls: ICbicContentHistoryMapDtls): ICbicContentHistoryMapDtls {
    return Object.assign({}, cbicContentHistoryMapDtls, {
      childNotificationDt: cbicContentHistoryMapDtls.childNotificationDt?.isValid()
        ? cbicContentHistoryMapDtls.childNotificationDt.toJSON()
        : undefined,
      parentNotificationDt: cbicContentHistoryMapDtls.parentNotificationDt?.isValid()
        ? cbicContentHistoryMapDtls.parentNotificationDt.toJSON()
        : undefined,
      createdDt: cbicContentHistoryMapDtls.createdDt?.isValid() ? cbicContentHistoryMapDtls.createdDt.toJSON() : undefined,
      updatedDt: cbicContentHistoryMapDtls.updatedDt?.isValid() ? cbicContentHistoryMapDtls.updatedDt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.childNotificationDt = res.body.childNotificationDt ? dayjs(res.body.childNotificationDt) : undefined;
      res.body.parentNotificationDt = res.body.parentNotificationDt ? dayjs(res.body.parentNotificationDt) : undefined;
      res.body.createdDt = res.body.createdDt ? dayjs(res.body.createdDt) : undefined;
      res.body.updatedDt = res.body.updatedDt ? dayjs(res.body.updatedDt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((cbicContentHistoryMapDtls: ICbicContentHistoryMapDtls) => {
        cbicContentHistoryMapDtls.childNotificationDt = cbicContentHistoryMapDtls.childNotificationDt
          ? dayjs(cbicContentHistoryMapDtls.childNotificationDt)
          : undefined;
        cbicContentHistoryMapDtls.parentNotificationDt = cbicContentHistoryMapDtls.parentNotificationDt
          ? dayjs(cbicContentHistoryMapDtls.parentNotificationDt)
          : undefined;
        cbicContentHistoryMapDtls.createdDt = cbicContentHistoryMapDtls.createdDt ? dayjs(cbicContentHistoryMapDtls.createdDt) : undefined;
        cbicContentHistoryMapDtls.updatedDt = cbicContentHistoryMapDtls.updatedDt ? dayjs(cbicContentHistoryMapDtls.updatedDt) : undefined;
      });
    }
    return res;
  }
}
