import * as dayjs from 'dayjs';
import { ICbicTaxMst } from 'app/entities/cbic-tax-mst/cbic-tax-mst.model';

export interface ICbicFormMst {
  id?: number;
  contentId?: number | null;
  contentLanguage?: string | null;
  isActive?: string | null;
  createdDt?: dayjs.Dayjs | null;
  updatedDt?: dayjs.Dayjs | null;
  isAmended?: string | null;
  parentId?: number | null;
  orderId?: number | null;
  versionNo?: number | null;
  createdBy?: number | null;
  updatedBy?: number | null;
  formNo?: string | null;
  formName?: string | null;
  formCategory?: string | null;
  issueDt?: dayjs.Dayjs | null;
  amendDt?: dayjs.Dayjs | null;
  contentFilePath?: string | null;
  contentFileName?: string | null;
  contentData?: string | null;
  enactmentDt?: dayjs.Dayjs | null;
  docFilePath?: string | null;
  docFileName?: string | null;
  docFileType?: string | null;
  tax?: ICbicTaxMst | null;
  contentFilePathHi?: string | null;
  contentFileNameHi?: string | null;
  formCategoryHi?: string | null;
  formNoHi?: string | null;
  formNameHi?: string | null;

}

export class CbicFormMst implements ICbicFormMst {
  constructor(
    public id?: number,
    public contentId?: number | null,
    public contentLanguage?: string | null,
    public isActive?: string | null,
    public createdDt?: dayjs.Dayjs | null,
    public updatedDt?: dayjs.Dayjs | null,
    public isAmended?: string | null,
    public parentId?: number | null,
    public orderId?: number | null,
    public versionNo?: number | null,
    public createdBy?: number | null,
    public updatedBy?: number | null,
    public formNo?: string | null,
    public formName?: string | null,
    public formCategory?: string | null,
    public issueDt?: dayjs.Dayjs | null,
    public amendDt?: dayjs.Dayjs | null,
    public contentFilePath?: string | null,
    public contentFileName?: string | null,
    public contentData?: string | null,
    public enactmentDt?: dayjs.Dayjs | null,
    public docFilePath?: string | null,
    public docFileName?: string | null,
    public docFileType?: string | null,
    public tax?: ICbicTaxMst | null,
    public contentFilePathHi?: string | null,
    public contentFileNameHi?: string | null,
    public formCategoryHi?: string | null,
    public formNoHi?: string | null,
    public formNameHi?: string | null,

  ) {}
}
export class CbicFormMstCategory{

  constructor(
    public formCategory?: string | null,
    public count?: number | null
  ){}
}

export function getCbicFormMstIdentifier(cbicFormMst: ICbicFormMst): number | undefined {
  return cbicFormMst.id;
}

export class FormResponse{
  constructor(
    public data?: string | null,
    public fileName ?: string | null
  ){}
}
