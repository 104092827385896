import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ProfileService } from 'app/layouts/profiles/profile.service';
import { threadId } from 'node:worker_threads';
import { CbicSearchOutput, CbicSearchOutputResult } from '../search-output.model';
import { CbicSearchService } from '../service/cbic-search-service';
declare let hideLanguageSwitch : any;

@Component({
  selector: 'cbic-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit, OnChanges {

  @Input()
  cbicSearchOutputResults!: CbicSearchOutputResult;
  @Input() keyWord!: any;
  inProduction?: boolean;
  openAPIEnabled?: boolean;
  numberOfPage = 4;
  pages: any[] = [];
  @Output()
  resultChanged = new EventEmitter<boolean>();
  paginationPage: any[] = [];
  currentPage = 1;
  startPage = 0;
  endPage = 5;
  @Input()
  backSearchFlag! : any;
 
  constructor(
    private router: Router,
    private profileService: ProfileService,
    private service: CbicSearchService
  ) { }

  ngOnChanges(): void {
   
   if (this.cbicSearchOutputResults.totalDocuments) {
      this.loadResult();
      this.currentPage = 1;
      this.startPage = 0;
      this.endPage = 5;
      this.paginationPage = this.pages.slice(this.startPage, this.endPage);
     }
    if(this.backSearchFlag === 'Y'){
      this.onBackToSearchPage();
    }
  }

  ngOnInit(): void {
 hideLanguageSwitch();
    this.profileService.getProfileInfo().subscribe(profileInfo => {
      this.inProduction = profileInfo.inProduction;
      this.openAPIEnabled = profileInfo.openAPIEnabled;
    });

  }

  loadResult(): void {
    this.numberOfPage = Math.ceil(Number(this.cbicSearchOutputResults.totalDocuments) / this.service.rows);
    this.pages = new Array<number>(this.numberOfPage).fill(this.numberOfPage).map((x, i) => i + 1);
    if (this.numberOfPage < this.endPage) {
      this.endPage = this.numberOfPage;
    }
    this.paginationPage = this.pages.slice(this.startPage, this.endPage);
  }


  navigate(path: string, contentType: string, contentId: string): void {
    this.router.navigate(['contentPage/explore']);
  }

  loadContent(index: any): void {
       this.service.searchForPagination(index).subscribe(
      (res: HttpResponse<CbicSearchOutputResult>) => {
        this.cbicSearchOutputResults.dtos = res.body?.dtos ?? [];
        this.currentPage = Number(index) + 1;
      }
    );
    this.resultChanged.emit(true);
  }

  openSearchRecord(documentType: any, documentId: any, taxId : any): void {
   const navigationExtras: NavigationExtras = {
      state: {
        keyword: this.keyWord,
        contentType : documentType
      }
    };
    //this.cbicSearchInt = new CbicSearchInput(params['keyword'], params['taxId'], params['actId'], params['content'], params['startIndex'], params['paginationFlag'], params['rows']);
    if(documentType === 'ACTS'){
      this.service.backToSearchResult = 'Y';
      const link = this.router.serializeUrl(this.router.createUrlTree(['content-page/explore-act/' + String(documentId) + '/' + String(taxId) + '/' + String(this.keyWord) + '/' + String(documentType)]));
      window.open(link, '_blank');
    }else if(documentType === 'RULES'){
      this.service.backToSearchResult = 'Y';
      const link = this.router.serializeUrl(this.router.createUrlTree(['content-page/explore-rules/' + String(documentId) + '/' + String(taxId) + '/' + String(this.keyWord) + '/' + String(documentType)]));
      window.open(link, '_blank');
      // this.router.navigate(['content-page/explore-rules/' + String(documentId) + '/' + String(taxId) ], navigationExtras);
    }else if(documentType === 'REGULATIONS'){
      this.service.backToSearchResult = 'Y';
      const link = this.router.serializeUrl(this.router.createUrlTree(['content-page/explore-regulations/' + String(documentId) + '/' + String(this.keyWord) + '/' + String(documentType)]));
      window.open(link, '_blank');
      // this.router.navigate(['content-page/explore-regulations/' + String(documentId)], navigationExtras);
    }else if(documentType === 'FORMS'){
      this.service.backToSearchResult = 'Y';
      const link = this.router.serializeUrl(this.router.createUrlTree(['content-page/explore-forms/' + String(documentId) + '/' + String(this.keyWord) + '/' + String(documentType)]));
      window.open(link, '_blank');
      // this.router.navigate(['content-page/explore-forms/' + String(documentId)], navigationExtras);
    }else if(documentType === 'NOTIFICATIONS'){
      this.service.backToSearchResult = 'Y';
      const link = this.router.serializeUrl(this.router.createUrlTree(['content-page/explore-notification/' + String(documentId) + '/' + String(this.keyWord) + '/' + String(documentType)]));
      window.open(link, '_blank');
      // this.router.navigate(['content-page/explore-notification/' + String(documentId)], navigationExtras);
    }else if(documentType === 'CIRCULARS'){
      this.service.backToSearchResult = 'Y';
      const link = this.router.serializeUrl(this.router.createUrlTree(['content-page/explore-circulars/' + String(documentId) + '/' + String(this.keyWord) + '/' + String(documentType)]));
      window.open(link, '_blank');
    }else if(documentType === 'INSTRUCTIONS'){
      this.service.backToSearchResult = 'Y';
      const link = this.router.serializeUrl(this.router.createUrlTree(['content-page/explore-instructions/' + String(documentId) + '/' + String(this.keyWord) + '/' + String(documentType)]));
      window.open(link, '_blank');
    }else if(documentType === 'ORDERS'){
      this.service.backToSearchResult = 'Y';
      const link = this.router.serializeUrl(this.router.createUrlTree(['content-page/explore-orders/' + String(documentId) + '/' + String(this.keyWord) + '/' + String(documentType)]));
      window.open(link, '_blank');
    }else if(documentType === 'ALLIEDACTS'){
      this.service.backToSearchResult = 'Y';
      const link = this.router.serializeUrl(this.router.createUrlTree(['content-page/explore-allied-acts/' + String(documentId) + '/' + String(this.keyWord) + '/' + String(documentType)]));
      window.open(link, '_blank');
    }
     
    
  }

  onPrevious(): void {
    // if(this.currentPage > 1){
    //   this.currentPage = this.currentPage - 1;
    //   this.loadContent(this.currentPage-1);
    // }
    if(this.currentPage > 1){
      this.currentPage = this.currentPage - 1;
      this.loadContent(this.currentPage-1);
    }

    if(this.startPage > 0){
      this.endPage -= 1;
      this.startPage -=1;
      this.paginationPage = this.pages.slice(this.startPage, this.endPage);
    }
  
  }

  onNext(): void {
    //   if(this.currentPage < this.numberOfPage){
    //     this.loadContent(this.currentPage);
    //     this.currentPage = this.currentPage + 1;
    //   }
    // }
    if(this.currentPage < this.numberOfPage){
      this.loadContent(this.currentPage);
      this.currentPage = this.currentPage + 1;
    
    }
    if(this.endPage < this.numberOfPage){
      this.endPage += 1;
      this.startPage +=1;
      this.paginationPage = this.pages.slice(this.startPage, this.endPage);
    }
  }

  onBackToSearchPage():void{
    if (this.cbicSearchOutputResults.totalDocuments) {
      this.loadResult();
      this.currentPage = 1;
      this.startPage = 0;
      this.endPage = 5;
      this.paginationPage = this.pages.slice(this.startPage, this.endPage);
    }
  }

}
