<li class="nav-item ">
    <a class="nav-link dropdown-toggle" href="#" id="navGST" role="button"
        data-bs-toggle="dropdown" aria-expanded="false" [ngClass]="menuItemTitle ===currentSelectionAct  ? 'active' : ''">
        <i class="{{logopath}}"></i>
        {{menuItemTitle}}
    </a>
    <ul class="dropdown-menu" aria-labelledby="navGST">
        <div class="container">
            <div class="row align-items-start">
                <div class="col d-flex flex-column">
                    <h4><label cbicTranslate="info">Information</label> - {{menuItemTitle}}</h4>
                    <a class="sm-hide" href=""  [routerLink]="" *ngFor="let info of informationLinks; let i = index" (click)="navigate(info.path,info.menuInput)">
                        {{info.lable}}</a>
                  
                </div>
               <!-- <div class="col d-flex flex-column">
                    <h4>Frequently Viewed</h4>
                    <div *ngFor="let itemName of frequentViewList; let i = index" class="d-flex w-100 justify-content-between">
                        <a class="" href="#" class="wrap-text" title="{{itemName.lable}}">{{itemName.lable}}</a>
                        <div class="badge text-wrap">
                            <i class="fa fa-eye" aria-hidden="true"></i> {{itemName.menuInput.view}}
                        </div>
                    </div>
                </div>-->
                <!--<div class="col d-flex flex-column">
                    <h4>Last updated</h4>
                    <div *ngFor="let itemName2 of lastUpdateList; let i = index" class="d-flex w-100 justify-content-between">
                        <a class="" href="#" class="wrap-text" title="{{itemName2.lable}}">{{itemName2.lable}}</a>
                        <div class="badge">
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                            {{itemName2.menuInput.lastUpdated}}
                        </div>
                    </div>
                </div>-->
            </div>
        </div>
    </ul>
</li>