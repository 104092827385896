import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { Search } from 'app/core/request/request.model';
import { ICbicAlliedActMst, getCbicAlliedActMstIdentifier } from '../cbic-allied-act-mst.model';

export type EntityResponseType = HttpResponse<ICbicAlliedActMst>;
export type EntityArrayResponseType = HttpResponse<ICbicAlliedActMst[]>;

@Injectable({ providedIn: 'root' })
export class CbicAlliedActMstService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/cbic-allied-act-msts');
  public resourceSearchUrl = this.applicationConfigService.getEndpointFor('api/_search/cbic-allied-act-msts');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  fetchAlliedActName(taxId: number): Observable<EntityArrayResponseType>  {
    return this.http
      .get<ICbicAlliedActMst[]>(`${this.resourceUrl}/fetchAlliedActName/${taxId}`, {observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
      
    }

    getAlliedActName(id: number) : Observable<EntityArrayResponseType>{
      return this.http
        .get<ICbicAlliedActMst[]>(`${this.resourceUrl}/getAlliedActName/${id}`, { observe: 'response' })
        .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
    }

   create(cbicAlliedActMst: ICbicAlliedActMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicAlliedActMst);
    return this.http
      .post<ICbicAlliedActMst>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(cbicAlliedActMst: ICbicAlliedActMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicAlliedActMst);
    return this.http
      .put<ICbicAlliedActMst>(`${this.resourceUrl}/${getCbicAlliedActMstIdentifier(cbicAlliedActMst) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(cbicAlliedActMst: ICbicAlliedActMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicAlliedActMst);
    return this.http
      .patch<ICbicAlliedActMst>(`${this.resourceUrl}/${getCbicAlliedActMstIdentifier(cbicAlliedActMst) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICbicAlliedActMst>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicAlliedActMst[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  search(req: Search): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicAlliedActMst[]>(this.resourceSearchUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  addCbicAlliedActMstToCollectionIfMissing(
    cbicAlliedActMstCollection: ICbicAlliedActMst[],
    ...cbicAlliedActMstsToCheck: (ICbicAlliedActMst | null | undefined)[]
  ): ICbicAlliedActMst[] {
    const cbicAlliedActMsts: ICbicAlliedActMst[] = cbicAlliedActMstsToCheck.filter(isPresent);
    if (cbicAlliedActMsts.length > 0) {
      const cbicAlliedActMstCollectionIdentifiers = cbicAlliedActMstCollection.map(
        cbicAlliedActMstItem => getCbicAlliedActMstIdentifier(cbicAlliedActMstItem)!
      );
      const cbicAlliedActMstsToAdd = cbicAlliedActMsts.filter(cbicAlliedActMstItem => {
        const cbicAlliedActMstIdentifier = getCbicAlliedActMstIdentifier(cbicAlliedActMstItem);
        if (cbicAlliedActMstIdentifier == null || cbicAlliedActMstCollectionIdentifiers.includes(cbicAlliedActMstIdentifier)) {
          return false;
        }
        cbicAlliedActMstCollectionIdentifiers.push(cbicAlliedActMstIdentifier);
        return true;
      });
      return [...cbicAlliedActMstsToAdd, ...cbicAlliedActMstCollection];
    }
    return cbicAlliedActMstCollection;
  }

  protected convertDateFromClient(cbicAlliedActMst: ICbicAlliedActMst): ICbicAlliedActMst {
    return Object.assign({}, cbicAlliedActMst, {
      created_dt: cbicAlliedActMst.created_dt?.isValid() ? cbicAlliedActMst.created_dt.toJSON() : undefined,
      updated_dt: cbicAlliedActMst.updated_dt?.isValid() ? cbicAlliedActMst.updated_dt.toJSON() : undefined,
      allied_act_dt: cbicAlliedActMst.allied_act_dt?.isValid() ? cbicAlliedActMst.allied_act_dt.toJSON() : undefined,
      issue_dt: cbicAlliedActMst.issue_dt?.isValid() ? cbicAlliedActMst.issue_dt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.created_dt = res.body.created_dt ? dayjs(res.body.created_dt) : undefined;
      res.body.updated_dt = res.body.updated_dt ? dayjs(res.body.updated_dt) : undefined;
      res.body.allied_act_dt = res.body.allied_act_dt ? dayjs(res.body.allied_act_dt) : undefined;
      res.body.issue_dt = res.body.issue_dt ? dayjs(res.body.issue_dt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((cbicAlliedActMst: ICbicAlliedActMst) => {
        cbicAlliedActMst.created_dt = cbicAlliedActMst.created_dt ? dayjs(cbicAlliedActMst.created_dt) : undefined;
        cbicAlliedActMst.updated_dt = cbicAlliedActMst.updated_dt ? dayjs(cbicAlliedActMst.updated_dt) : undefined;
        cbicAlliedActMst.allied_act_dt = cbicAlliedActMst.allied_act_dt ? dayjs(cbicAlliedActMst.allied_act_dt) : undefined;
        cbicAlliedActMst.issue_dt = cbicAlliedActMst.issue_dt ? dayjs(cbicAlliedActMst.issue_dt) : undefined;
      });
    }
    return res;
  }
}
