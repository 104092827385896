
<section class="search-sec">
    <div class="container">
        <form action="#" method="post" novalidate="novalidate">
            <div class="row">
                <div class="col-lg-12">
                    <form name="searchBarNotification"   role="notification" [formGroup]="searchBarForm" novalidate="novalidate" (ngSubmit)="search()">
                    <div class="row">
                        <div class="input-group flex-nowrap">
                            <button class="btn disablebtn" *ngIf="taxId !== 1000004" cbicTranslate="selectCategory">Select Category</button>
                            <select  *ngIf="taxId !== 1000004" class="form-select" #notificationCategorySelection
                                    id="inputGroupSelectCategoryForContentPage"
                                    aria-label="Category Selection" style="width: 5% !important;" 
                                    (change)="changeCategorySelection(notificationCategorySelection.value)"
                                    formControlName="category" [(ngModel)]="notificationCategory">
                                
                                <!-- <option value="All">All</option>  -->
                                <option *ngFor="let category of notificationCategoryList; let i = index"  value="{{category.notificationCategory}}" >{{category.notificationCategory}}</option>
                                
                            </select>
                           
                            <span class="input-group-text" *ngIf="taxId !== 1000004"></span>
                            <button class="btn disablebtn" style="width: 10%;" cbicTranslate="selectYear">Select Year</button>
                            <select class="form-select" #notiYear
                                id="inputGroupSelectNotificationYearForContentPage"
                                aria-label="Notification Year Selection"
                                formControlName="notiYear" style="width: 5%;text-align: center;" 
                                (change)='fireNotiYearSelected(notiYear.value)' 
                                [(ngModel)]="selectedYear">
                                
                                <option *ngFor="let year of yearList; let i = index"  value="{{year}}" title="{{year}}"  [selected]="year == notiYearValue">{{year}}</option>
                                <option value="1999" *ngIf="taxId === 1000002 || taxId === 1000003"> < 2000 </option>
                                
                                
                            </select>
                            

                            <span class="input-group-text"></span>
                            <input type="text" class="form-control" placeholder="{{ 'enterKeyword' | translate }}" aria-label="Username" maxlength="100" #regSearchKeyword
                                aria-describedby="addon-wrapping" formControlName="keyword" autocomplete="off" style="width: 35%;">
                            <button class="btn" *ngIf="!searched" type="button" (click)="search()"><fa-icon [icon]="faSearch"></fa-icon></button>
                            <button class="btn" *ngIf="searched" type="button" (click)="clear()"><fa-icon [icon]="faTimesCircle"></fa-icon></button>
                            
                            
                        </div>
                        
                        <div class="advance-search adjusted-font">
                            <a *ngIf="storageLanguage === 'en'" [routerLink]="" (click)="openAllSearch()"><u>Click here </u>to search across all {{taxName}} {{tabName}}</a>
                            <a *ngIf="storageLanguage === 'hi'" [routerLink]="" (click)="openAllSearch()">सभी {{taxName}} {{tabName}} में खोजने के लिए यहां <u>क्लिक करें</u></a>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        </form>
    </div>
</section>

<ng-template #allSearch let-modal>
    <div class="modal-content all-search-modal" >
        <div class="modal-header page-header">
            <h4 *ngIf="storageLanguage === 'en'">Search across all {{taxName}} {{tabName}}</h4>
            <h4 *ngIf="storageLanguage === 'hi'">सभी {{taxName}} {{tabName}} में खोजें</h4>

            <button type="button" class="btn btn-light" (click)="modal.close('Close click')"><i
                    class="fas fa-times"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-12">
                    <form name="searchBarFormAll" role="form" [formGroup]="searchBarFormAll"
                        novalidate="novalidate" (ngSubmit)="searchAll()">
                        <div class="input-group flex-nowrap search-sec">
                            <input type="text" class="form-control" placeholder="{{ 'enterKeyword' | translate }}" aria-label="Username"
                                maxlength="100" #allRuleSearchKeyword aria-describedby="addon-wrapping"
                                formControlName="keywordAll" autocomplete="off"/>
                                <button class="btn" type="button" (click)="searchAll()"><fa-icon [icon]="faSearch"></fa-icon></button>
                        </div>
                        <p *ngIf="allSearchMsg" class="all-search-msg">{{allSearchMsg}}</p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-template>
