import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class ContenPageService {
    keyword:any;
    emitFetchDataEvent=new  Subject<any>();
    closeAllSection=new Subject<any>();
    notificationsRecived=new Subject<any>();
    changeViewBy=new Subject<any>();
    setKeyWord(keyword:any):void{
        this.keyword = keyword;
    }
    


    
}
