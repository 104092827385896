import { Component, ElementRef, Input, NgModule, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ProfileService } from 'app/layouts/profiles/profile.service';
import { DataUtils } from 'app/core/util/data-util.service';
import { NavigationExtras, Router } from '@angular/router';
import { CbicNotificationMstService } from 'app/entities/cbic-notification-mst/service/cbic-notification-mst.service';
import { RuleMstResponse } from 'app/entities/cbic-rule-mst/cbic-rule-mst.model';
import { CbicCircularMstService } from 'app/entities/cbic-circular-mst/service/cbic-circular-mst.service';
import { ICbicNotificationMst } from 'app/entities/cbic-notification-mst/cbic-notification-mst.model';
import { ContenPageService } from '../service/content-page.service';
import { saveAs} from 'file-saver';
import { CbicAttachmentDtlsService } from 'app/entities/cbic-attachment-dtls/service/cbic-attachment-dtls.service';
import { CbicInstructionMstService } from 'app/entities/cbic-instruction-mst/service/cbic-instruction-mst.service';
import { CbicOrderMstService } from 'app/entities/cbic-order-mst/service/cbic-order-mst.service';
import { ICbicAttachmentDtls } from 'app/entities/cbic-attachment-dtls/cbic-attachment-dtls.model';
import { Subject } from 'rxjs';
import { FormResponse } from 'app/entities/cbic-form-mst/cbic-form-mst.model';

import { registerLocaleData } from '@angular/common';
import localeHi from '@angular/common/locales/hi';
import { SessionStorageService } from 'ngx-webstorage';


@Component({
  selector: 'cbic-notification-read',
  templateUrl: './notification-read.component.html',
  styleUrls: ['./notification-read.component.scss']
})

export class NotificationReadComponent implements OnInit {

  @Input() notificationDtls:any;
  @Input() circularList:any;
  @Input() selectedTab:any;
  @Input() selectedYear:any;
  @Input() searchKeyword:any;
  numberOfPage:any;
  pages: any[] = [];
  paginationPage:any[] = [];
  showRecord: number[] = [10, 25, 50, 100];
  showRecordSelected = 10;
  startPage = 0;
  endPage = 5;
  currentPage:any;
  @Input() notiNotFound:any;
  @Input() notificationCategory:any;
  @Input() circularCategory:any;

  @Input() totalRecords:any;
  @Input() totalRecordsCircular: any;
  @Input() taxId:any;
  @Input() circularNotFound:any;
  ngbPaginationPage = 1;
  circularAttachmentList:any;
  notificationAttachmentList:any;
  attachmentList:any;
  viewFlag:any;

  @Input() instructionCategory:any;
  @Input() instructionNotFound:any;
  @Input() instructionList:any;

  @Input() orderCategory:any;
  @Input() orderNotFound:any;
  @Input() orderList:any;

  @Input() currentPageInput:any;
  @Input() ngbPageInput:any;
  @Input() showRecordInput:any;
  @Input() dummy?:number;

  fileURL: any;
  language: any
    
//   @Input() set showRecordInsSelected(value: number) {
//     alert(value);
//     this.showRecordSelected = value;
//  }
  constructor(  private router : Router,
    private profileService: ProfileService,
               private http : HttpClient,
              private dataUtils:DataUtils,
             private cbicNotificationMstService: CbicNotificationMstService,
              private cbicCircularMstService: CbicCircularMstService ,
              private contentPageService:ContenPageService,
              protected cbicAttDtlsService: CbicAttachmentDtlsService,
              private cbicInstructionMstService: CbicInstructionMstService,
              private cbicOrderMstService:CbicOrderMstService,
              private sessionStorage: SessionStorageService
            
              ){
              
               
            }

  ngOnChanges(changes: SimpleChanges): void {
     this.currentPage = this.currentPageInput;
    this.ngbPaginationPage = this.ngbPageInput;
    this.showRecordSelected = this.showRecordInput;
   
  }
  
  ngOnInit(): void {
  this.language = this.sessionStorage.retrieve('locale')
  registerLocaleData(localeHi, 'hi');

     this.currentPage = this.currentPageInput;
    this.ngbPaginationPage = this.ngbPageInput;
    this.showRecordSelected = this.showRecordInput;
    this.viewFlag=[];
    //this.reading.push(true);
    for(let i=0;i<this.circularList?.length;i++){
      this.viewFlag.push(false);
    }
      
    return;
  }


  
  

  viewHistory(notificationId:any, taxId:any, parentId: any, selectedTab: any):void{
    const navigationExtras: NavigationExtras = {
      state: {
        id: notificationId,
        tax : taxId,
        parent : parentId,
        selection : selectedTab
      }
    };
    //this.router.navigate(['history-page/explore-history/'], navigationExtras);

    const link = this.router.serializeUrl(this.router.createUrlTree(['history-page/'+ String(notificationId)+ '/'+ String(taxId) +'/'+String(selectedTab)]));
      window.open(link, '_blank');
  }

  /* downloadNotification(notificationPath:any):void{
    this.http.get('content/pdf/' + String(notificationPath),
        { responseType: 'text' })
        .subscribe(res => {
          const json=JSON.parse(res);
          const fileURL = 'data:application/pdf;base64,'+String(json.data);
          this.dataUtils.openFile(String(json.data), "application/pdf");
        }
        );
  } */

  viewDocuments(notificationPath:any):void{
    this.http.get('content/pdf/' + String(notificationPath),
        { responseType: 'text' })
        .subscribe(res => {
          if(res){

          
            const json=JSON.parse(res);
            this.fileURL = 'data:application/pdf;base64,'+String(json.data);
            this.dataUtils.openFile(String(json.data), "application/pdf;");
          }else{
            alert("No Data Found");
          }
        }
        );
  }

  viewDocumentsV2(notificationPath:any, id:any, type:any, selectedTab:any):void{
      const link = this.router.serializeUrl(this.router.createUrlTree(['view-pdf/'+ String(id)+ '/'+ String(type) +'/'+String(selectedTab)]));
      window.open(link, '_blank');
  }
  onPrevious():void{
    return;
  }
  onNext():void{
    return;
  }

  loadContent(pageNumber:any):void{
    return;
  }

  showRecordSearch(recordValue : any): void{
    const pageToLoad =  1;
    this.showRecordSelected=recordValue;
    if(this.selectedTab === "Notifications") {

      const query:any={
        page: pageToLoad - 1,
        size: this.showRecordSelected,
        year:this.selectedYear,
        category: this.notificationCategory,
        taxId: this.taxId
      };
      if(this.searchKeyword){
        query.searchKeyword=this.searchKeyword;
      }

    this.cbicNotificationMstService
      .fetchNotificationByYearCategory({
        page: pageToLoad - 1,
        size: this.showRecordSelected,
        year:this.selectedYear,
        category: this.notificationCategory,
        taxId: this.taxId
      })
      .subscribe(
        (res) => {
        this.notificationDtls=res.body;
        
        }
      );
    }

    if(this.selectedTab === "Circulars") {
      this.cbicCircularMstService
     .fetchCircularByYearCategory({
        page: pageToLoad - 1,
        size: this.showRecordSelected,
        year:this.selectedYear,
        category: this.circularCategory,
        taxId: this.taxId
     })
     .subscribe(
       (res) => {
         this.circularList = res.body
       }
     )
    }
    if(this.selectedTab === "Instructions") {
      this.cbicInstructionMstService
     .fetchInstructionByYearCategory({
        page: pageToLoad - 1,
        size: recordValue,
        year:this.selectedYear,
        category: this.instructionCategory,
        taxId: this.taxId
     })
     .subscribe(
       (res) => {
         this.instructionList = res.body
       }
     )
    }
    if(this.selectedTab === "Orders") {
      this.cbicOrderMstService
     .fetchOrderByYearCategory({
        page: pageToLoad - 1,
        size: this.showRecordSelected,
        year:this.selectedYear,
        category: this.orderCategory,
        taxId: this.taxId
     })
     .subscribe(
       (res) => {
         this.orderList = res.body
       }
     )
    }
    // const str = "Show Page Method:" + String(this.showRecordSelected);
    // alert(str);
  }


  downloadDocuments(id: any, language: any): void {
    if(this.selectedTab === "Notifications") {
      this.cbicNotificationMstService
        .downloadNotification(Number(id), language).subscribe(
          (res: RuleMstResponse) => {
            const data = res.data;
            const byteCharacters = atob(String(data));
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            saveAs(blob, String(res.fileName));
          }
        );
    }
    if(this.selectedTab === "Circulars") {
      this.cbicCircularMstService
        .download(Number(id), language).subscribe(
          (res: RuleMstResponse) => {
            const data = res.data;
            const byteCharacters = atob(String(data));
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            saveAs(blob, String(res.fileName));
          }
        );
    }
    if(this.selectedTab === "Instructions") {
      this.cbicInstructionMstService
        .download(Number(id), language).subscribe(
          (res: RuleMstResponse) => {
            const data = res.data;
            const byteCharacters = atob(String(data));
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            saveAs(blob, String(res.fileName));
          }
        );
    }
    if(this.selectedTab === "Orders") {
      this.cbicOrderMstService
        .download(Number(id), language).subscribe(
          (res: RuleMstResponse) => {
            const data = res.data;
            const byteCharacters = atob(String(data));
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            saveAs(blob, String(res.fileName));
          }
        );
    }
    
  }


  loadPage(page?: number, dontNavigate?: boolean): void {
    this.currentPage=page;
    const pageToLoad: number = page ?? this.currentPage ?? 1;
    const query:any={
      page: pageToLoad - 1,
      size: this.showRecordSelected,
      year:this.selectedYear,
      category: this.notificationCategory,
      taxId: this.taxId
    };
    if(this.searchKeyword){
      query.searchKeyword=this.searchKeyword;
    }

     
 this.cbicNotificationMstService
    .fetchNotificationByYearCategory(query)
    .subscribe(
      (res) => {
      this.notificationDtls=res.body;
      });
    

    
  }

  loadPageCircular(page?: number, dontNavigate?: boolean): void {
    this.currentPage=page;
    const pageToLoad: number = page ?? this.currentPage ?? 1;
    const query:any={
      page: pageToLoad - 1,
      size: this.showRecordSelected,
      year:this.selectedYear,
      category: this.circularCategory,
      taxId: this.taxId
    };

    if(this.searchKeyword){
      query.searchKeyword=this.searchKeyword;
    }
    this.cbicCircularMstService
    .fetchCircularByYearCategory(query)
    .subscribe(
      (res) => {
        this.circularList = res.body
      }
    )

  }

  viewAttachments(id: any, type:any): void {
   
    this.cbicAttDtlsService
      .fetchAttachmentList(id, type)
      .subscribe(
        (res: HttpResponse<ICbicAttachmentDtls[]>) => {
          this.attachmentList = res.body;
        }
      )

  } 

  loadPageInstruction(page?: number, dontNavigate?: boolean): void {
    this.currentPage=page;
    const pageToLoad: number = page ?? this.currentPage ?? 1;
    const query:any={
      page: pageToLoad - 1,
      size: this.showRecordSelected,
      year:this.selectedYear,
      category: this.instructionCategory,
      taxId: this.taxId
    };
    if(this.searchKeyword){

    
      query.searchKeyword=this.searchKeyword;
    }
    this.cbicInstructionMstService.fetchInstructionByYearCategory(query)
    .subscribe(
      (res) => {
        this.instructionList = res.body
      }
    )

  }

  loadPageOrder(page?: number, dontNavigate?: boolean): void {
    this.currentPage=page;
    const pageToLoad: number = page ?? this.currentPage ?? 1;

    this.cbicOrderMstService
    .fetchOrderByYearCategory({
      page: pageToLoad - 1,
      size: this.showRecordSelected,
      year:this.selectedYear,
      category: this.orderCategory,
      taxId: this.taxId
    })
    .subscribe(
      (res) => {
        this.orderList = res.body
      }
    )

  }

  setPageValues():void{
    this.ngbPaginationPage = 1;
    this.showRecordSelected = 10;
    this.currentPage = 1;
  }

}
