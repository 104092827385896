import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationExtras, Router } from '@angular/router';
import { ProfileService } from 'app/layouts/profiles/profile.service';
import { CbicActSectionMstService } from '../entities/cbic-act-section-mst/service/cbic-act-section-mst.service';
import { CbicActSectionMst, ICbicActSectionMst } from '../entities/cbic-act-section-mst/cbic-act-section-mst.model';
import { SpinnerService } from 'app/layouts/spinner/spinner.service';

import { CbicActChapterMstService } from '../entities/cbic-act-chapter-mst/service/cbic-act-chapter-mst.service';
import { CbicActMstService } from '../entities/cbic-act-mst/service/cbic-act-mst.service';
import { ICbicActChapterMst } from 'app/entities/cbic-act-chapter-mst/cbic-act-chapter-mst.model';
import { ICbicActMst } from '../entities/cbic-act-mst/cbic-act-mst.model';
import { SectionReadComponent } from './section-read/section-read.component'

import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { SectionChapterNavigationComponent } from './section-chapter-navigation/section-chapter-navigation.component';
import { CbicSearchOutput, CbicSearchOutputResult } from 'app/search-page/search-output.model';
import { CbicSearchService } from 'app/search-page/service/cbic-search-service';
import { CbicSearchInput } from 'app/searchbar/searchbar.model';
import { CbicTaxMstService } from '../entities/cbic-tax-mst/service/cbic-tax-mst.service';
import { ICbicTaxMst } from 'app/entities/cbic-tax-mst/cbic-tax-mst.model';
import { ContentSearchBarComponent } from './content-search-bar/content-search-bar.component';
import { HttpClient } from '@angular/common/http';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { CbicRuleMstService } from '../entities/cbic-rule-mst/service/cbic-rule-mst.service';
import { ICbicRuleMst } from '../entities/cbic-rule-mst/cbic-rule-mst.model';
import { CbicRuleChapterMstService } from '../entities/cbic-rule-chapter-mst/service/cbic-rule-chapter-mst.service';
import { ICbicRuleChapterMst } from 'app/entities/cbic-rule-chapter-mst/cbic-rule-chapter-mst.model';
import { CbicRuleSectionMstService } from '../entities/cbic-rule-section-mst/service/cbic-rule-section-mst.service';
import { ICbicRuleSectionMst } from 'app/entities/cbic-rule-section-mst/cbic-rule-section-mst.model';
import { CbicRegulationMstService } from 'app/entities/cbic-regulation-mst/service/cbic-regulation-mst.service';
import { ICbicRegulationMst } from 'app/entities/cbic-regulation-mst/cbic-regulation-mst.model';
import { CbicRegulationDocMstService } from 'app/entities/cbic-regulation-doc-mst/service/cbic-regulation-doc-mst.service';
import { CbicRegulationDocMst, ICbicRegulationDocMst } from 'app/entities/cbic-regulation-doc-mst/cbic-regulation-doc-mst.model';
import { RegulationSearchBarComponent } from './regulation-search-bar/regulation-search-bar.component';
import { RulesContentSearchBarComponent } from './rules-content-search-bar/rules-content-search-bar.component';

import { ContenPageService } from './service/content-page.service';
import { CbicRegulationChapterMstService } from 'app/entities/cbic-regulation-chapter-mst/service/cbic-regulation-chapter-mst.service';
import { ICbicRegulationChapterMst } from 'app/entities/cbic-regulation-chapter-mst/cbic-regulation-chapter-mst.model';
import { CbicFormMst, CbicFormMstCategory, FormResponse, ICbicFormMst } from 'app/entities/cbic-form-mst/cbic-form-mst.model';
import { CbicFormMstService } from 'app/entities/cbic-form-mst/service/cbic-form-mst.service';
import { FormsReadComponent } from './forms-read/forms-read.component';
import { FormsNavigationComponent } from './forms-navigation/forms-navigation.component';
import { mergeMap } from 'rxjs/operators';
import { FormsContentSearchBarComponent } from './forms-content-search-bar/forms-content-search-bar.component';
import { CbicServiceTaxMstService } from 'app/entities/cbic-service-tax-mst/service/cbic-service-tax-mst.service';
import { ICbicServiceTaxMst } from 'app/entities/cbic-service-tax-mst/cbic-service-tax-mst.model';
import { ICbicNotificationMst } from 'app/entities/cbic-notification-mst/cbic-notification-mst.model';
import { CbicNotificationMstService } from 'app/entities/cbic-notification-mst/service/cbic-notification-mst.service';
import { NotificationSearchComponent } from './notification-search/notification-search.component';
import { CbicCircularMstService } from 'app/entities/cbic-circular-mst/service/cbic-circular-mst.service';
import { ICbicCircularMst } from 'app/entities/cbic-circular-mst/cbic-circular-mst.model';
import { NotificationReadComponent } from './notification-read/notification-read.component';
import { CircularSearchBarComponent } from './circular-search-bar/circular-search-bar.component';
import { EntityResponseType } from 'app/entities/cbicmenumst/service/cbicmenumst.service';
import { DataUtils } from 'app/core/util/data-util.service';
import { CbicInstructionMstService } from 'app/entities/cbic-instruction-mst/service/cbic-instruction-mst.service';
import { ICbicInstructionMst } from 'app/entities/cbic-instruction-mst/cbic-instruction-mst.model';
import { InstructionSearchBarComponent } from './instruction-search-bar/instruction-search-bar.component';
import { OrderSearchBarComponent } from './order-search-bar/order-search-bar.component';
import { CbicOrderMstService } from 'app/entities/cbic-order-mst/service/cbic-order-mst.service';
import { ICbicOrderMst } from 'app/entities/cbic-order-mst/cbic-order-mst.model';
import { CbicAmendmentDocHistoryService } from 'app/entities/cbic-amendment-doc-history/service/cbic-amendment-doc-history.service';
import { ICbicAmendmentDocHistory } from 'app/entities/cbic-amendment-doc-history/cbic-amendment-doc-history.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { TranslateService } from '@ngx-translate/core';
import { CbicAlliedActMst, ICbicAlliedActMst } from 'app/entities/cbic-allied-act-mst/cbic-allied-act-mst.model';
import { CbicAlliedActMstService } from 'app/entities/cbic-allied-act-mst/service/cbic-allied-act-mst.service';
import { AlliedActSearchComponent } from './allied-act-search/allied-act-search.component';
import { CbicAlliedActDtlsService } from 'app/entities/cbic-allied-act-dtls/service/cbic-allied-act-dtls.service';
import { ICbicAlliedActDtls } from 'app/entities/cbic-allied-act-dtls/cbic-allied-act-dtls.model';
declare let hideLanguageSwitch: any;

@Component({
  selector: 'cbic-content-page',
  templateUrl: './content-page.component.html',
  styleUrls: ['./content-page.component.scss']
})
export class ContentPageComponent implements OnInit {
  sectionDataFetched: any;
  chapterListFetched: any;
  allSectionList: any;
  viewSection: any;
  notiYearSelected: any;
  regulationView: any;
  viewByAllSectionList: any;
  getChapterName: any;
  inProduction?: boolean;
  notificationCategory: any = null;
  openAPIEnabled?: boolean;
  actListFetched: any;
  hostAddress: any;
  navigation: any;
  notifiSearchKeyWord: any;
  taxName: any;
  cbicSearchOutputResults: CbicSearchOutputResult = new CbicSearchOutputResult([], null);
  taxId: any;
  actId: any;
  actName: any;
  sectionListLengthRead: any;
  sectionToBeOpened: any;
  searchKeyWord = "";
  activeReadingSectionNum: any;
  activeReadingRegulationNum: any;
  query!: CbicSearchInput;
  notificationDtls: any;
  @ViewChild(SectionChapterNavigationComponent) childChaterComponent!: SectionChapterNavigationComponent;
  @ViewChild(ContentSearchBarComponent) childSearch!: ContentSearchBarComponent;
  @ViewChild(RegulationSearchBarComponent) reguChildSearch!: RegulationSearchBarComponent;
  @ViewChild(NotificationSearchComponent) notifiSearch!: NotificationSearchComponent;
  @ViewChild(NotificationReadComponent) notifiReadChild!: NotificationReadComponent;
  @ViewChild(CircularSearchBarComponent) circularSearch!: CircularSearchBarComponent;
  @ViewChild(AlliedActSearchComponent) alliedSearch!: AlliedActSearchComponent;
  @Output() changeChapter = new EventEmitter<string>();
  chapterData!: { chapterName: any; chapterNo: any; };
  searchNotFound = false;
  searchActId: any;
  searchRegulationId: any;
  sectionSelection = false;
  currentSection = 0;
  readSectionNo?: string;
  readSectionName?: string;
  fullPdfPath: any;
  fullHtmlPath: any = null;
  // reading:any;
  errorPageTobeShown = false;
  sectionSelectionRules = false;

  enactmentDt: any;
  lastUpdatedDt: any;
  editEnable: any;
  @Input() activeTab: any;
  ruleChapterListFetched: any;
  rulesListFetched: any;
  ruleToBoOpen: any;
  regulationList: any;
  regulationDocList: any;
  ruleId: any;
  regulationDocId: any;
  rulesDataFetched: any;
  ruleDocName: any;
  actLoaded = false;
  ruleLoaded = false;
  regulationHtmlPath: any;
  searchRuleId: any;
  sectionListFetched: any;
  ruleSelection = false;
  regulationDoc!: CbicRegulationDocMst;
  currentRuleSection = 0;
  allRulesSection: any;
  viewBySectionAllRuleList: any;
  viewSelectedRuleSection: any;
  getChapterNameForRule: any;
  @ViewChild(RulesContentSearchBarComponent) childRuleSearch!: RulesContentSearchBarComponent;
  selectedTab = "";
  contentType: any;
  activeReadingRuleNum: any;
  rulesViewBy = "Chapter";
  rulesCategoryListFetched: any;

  sectionViewBy = "Section";
  regulationViewBy = "Regulations";
  totalChapterForActs: any;
  totalSectionForActs: any;
  totalChapterForRegulations: any;
  totalChapterForRegulationsLen: any;
  totalRegulationForRegulation: any;
  selectedChapterNoAct: any;
  selectedChapterNameAct: any;
  selectedChapterNameActHi: any;
  selectedChapterNoActHi: any;
  selectedChapterNoRegulation: any;
  selectedChapterNameRegulation: any;
  selectedChapterNoRegulationHi: any;
  selectedChapterNameRegulationHi: any;
  selectedChapterNoRules: any;
  selectedChapterNameRules: any;
  regulationListByChapter: any;
  totalSectionForRules: any;
  totalChapterForRules: any;
  firstRuleId: any;
  serviceTaxDataList: any;

  isRuleChanged = false;
  taxIdRuleCategory: any;
  regualtionToBoOpen: any;
  formsLoaded = false;
  formsListFetched: any;
  formsCategoryListFetched: any;
  formByCategoryFetched: any;
  @ViewChild(FormsReadComponent) formRead!: FormsReadComponent;
  @ViewChild(FormsReadComponent) formSearch!: FormsContentSearchBarComponent;
  formToOpen: any;
  @ViewChild(FormsNavigationComponent) formNavigate!: FormsNavigationComponent;
  notificationCategoryList: any;
  getChapterNameForRegulation: any;
  regulationsCategoryList: any;
  noRules = false;
  formSelect?: CbicFormMst;
  activeRegulationPrevNumber: any;
  serviceTaxRuleList: any
  selectedSTRule: any;
  circularList: any;
  circularCategoryList: any;
  circularYearList: any;
  totalRecordsNotiRead: any;
  routeKeyword: any;
  formIdLink: any;
  routeContentType: any;
  circularToBeOpened: any;
  notificationToBeOpened: any;
  notificationLoaded = false;
  notiNotFound = false;
  cirYearSelected: any;
  circularCategory: any = null;
  circularNotFound = false;
  totalRecordsCircularRead: any;
  notificationSearchFlag = "Y";

  instructionYearSelected: any;
  insturctionList: any;
  instructionCategoryList: any;
  instructionYearList: any;
  instYearSelected: any;
  instructionCategory: any = null;
  instructionNotFound = false;
  totalRecordsInstructionRead: any;
  @ViewChild(InstructionSearchBarComponent) instructionSearch!: InstructionSearchBarComponent;
  insSearchKeyWord: any;

  ordSearchKeyword: any;
  orderYearSelected: any;
  orderList: any;
  orderCategoryList: any;
  orderYearList: any;
  ordYearSelected: any;
  orderCategory: any = null;
  orderNotFound = false;
  totalRecordsOrderRead: any;
  @ViewChild(OrderSearchBarComponent) orderSearch!: OrderSearchBarComponent;
  dummyTime = false;
  currentPageInputParent = 1;
  ngbPageInputParent = 1;
  recordSelectedInputParent = 10;
  dummyTest = 15;
  circularSearchKeyword: any;
  instructionToBeOpened: any;
  orderToBeOpened: any;
  instructionSearchFlag = "Y";
  orderSearchFlag = "Y";
  circularSearchFlag = "Y";
  amendmentHistory: any;
  actContentId: any;
  historyFlag = false
  @ViewChild('relatedList', { static: true }) relatedListModal!: ElementRef;
  meuInputForReload: any
  menuTypeForReload: any
  pathForReload: any
  tabName: any
  storageLanguage: any
  //for allied act functionality
  alliedActToBeOpened: any;
  alliedActNameList: any;
  aliedActName: any;
  alliedYearSelected: any;
  alliedActNameCategory: any;
  alliedActSearchFlag = "Y";
  alliedActSearchKeyWord: any;
  alliedActDtls: any;
  alliedNotFound = false;
  totalRecordsAlliedRead: any;
  getAlliedActName: any;
  alliedYearValueDatabase: any;
  alliedYearDatabse: any;
  constructor(
    private router: Router,
    private profileService: ProfileService,
    protected cbicActSectionMstService: CbicActSectionMstService,
    protected cbicActChapterMstService: CbicActChapterMstService,
    protected cbicActMstService: CbicActMstService,
    protected activatedRoute: ActivatedRoute,
    protected service: CbicSearchService,
    protected cbicTaxMstService: CbicTaxMstService,
    private spinnerService: SpinnerService,
    private applicationConfigService: ApplicationConfigService,
    private http: HttpClient,
    protected cbicRuleMstService: CbicRuleMstService,
    protected cbicRuleChapterMstService: CbicRuleChapterMstService,
    protected cbicRuleSectionMstService: CbicRuleSectionMstService,
    protected cbicRegulationMstService: CbicRegulationMstService,
    protected cbicRegulationChapterMstService: CbicRegulationChapterMstService,
    protected cbicRegulationDocMstService: CbicRegulationDocMstService,
    protected contentPageService: ContenPageService,
    protected cbicFormMstService: CbicFormMstService,
    protected cbicServiceTaxMstService: CbicServiceTaxMstService,
    protected cbicNotificationMstService: CbicNotificationMstService,
    protected cbicCircularMstService: CbicCircularMstService,
    protected cbicInstructionMstService: CbicInstructionMstService,
    protected cbicOrderMstService: CbicOrderMstService,
    private dataUtils: DataUtils,
    private cbicAmendmentHisService: CbicAmendmentDocHistoryService,
    private modalService: NgbModal,
    private sessionStorage: SessionStorageService,
    private localStorage: LocalStorageService,
    private translateService: TranslateService,
    protected cbicAlliedActMstService: CbicAlliedActMstService,
    protected cbicAlliedActDtlsService: CbicAlliedActDtlsService
  ) {
    this.actListFetched = "";
    this.sectionDataFetched = "";//[{title:'Section-1 : Short title, extent and commencement',sectionHtmlPath:'data\\acts\\customs\\Customs Act,1962\\chapter1\\Section 1.html'},{title:'Section-2 : Definitions',sectionHtmlPath:'data\\acts\\customs\\Customs Act,1962\\chapter1\\Section 2.html'}];
    this.chapterListFetched = "";
    this.viewSection = "";
    this.rulesDataFetched = "";
    this.activatedRoute.data.subscribe(({ cbicactsectionmst }) => {
      this.sectionToBeOpened = cbicactsectionmst;
      if (this.sectionToBeOpened) {
        if (this.router.url.includes('act')) {
          this.taxId = this.sectionToBeOpened.taxId?.id;
          this.activeTab = 'Acts';
          this.taxIdRuleCategory = this.sectionToBeOpened.taxId?.id;
        }
      }
    });
    this.activatedRoute.data.subscribe(({ cbicrulesectionmst }) => {
      this.ruleToBoOpen = cbicrulesectionmst;
      if (this.ruleToBoOpen) {
        if (this.router.url.includes('rule')) {
          this.taxId = this.ruleToBoOpen.cbicTaxMst?.id;
          this.activeTab = 'Rules';
          this.taxIdRuleCategory = this.ruleToBoOpen.cbicTaxMst?.id;
          // this.selectedTab = "Rules";
          // this.fetchTaxName(this.taxId);
          // if (this.taxId !== 1000004) {
          //   this.fetchAllRules(this.taxId);//11 is id for tax type customs
          // } else {
          //   this.fetchAllServiceTaxRuleList(this.taxId);
          // }
        }
      }
    });
    this.activatedRoute.data.subscribe(({ cbicregulationmst }) => {
      this.regualtionToBoOpen = cbicregulationmst;
      if (this.regualtionToBoOpen) {
        if (this.router.url.includes('regulation')) {
          this.taxId = this.regualtionToBoOpen.tax?.id;
          this.activeTab = 'Regulations';
          this.taxIdRuleCategory = this.regualtionToBoOpen.tax?.id;
        }
      }
    });
    this.activatedRoute.data.subscribe(({ cbicformmst }) => {
      this.formToOpen = cbicformmst;
      if (this.formToOpen) {
        if (this.router.url.includes('form')) {
          this.taxId = this.formToOpen.tax?.id;
          this.activeTab = 'Forms';
          this.taxIdRuleCategory = this.formToOpen.tax?.id;
        }
      }
    });
    this.activatedRoute.data.subscribe(({ cbicNotificationMst }) => {
      this.notificationToBeOpened = cbicNotificationMst;
      if (this.notificationToBeOpened) {
        if (this.router.url.includes('notification')) {
          this.taxId = this.notificationToBeOpened.tax?.id;
          this.activeTab = 'Notifications';
          this.taxIdRuleCategory = this.notificationToBeOpened.tax?.id;
        }
      }

    });
    this.activatedRoute.data.subscribe(({ cbicCircularMst }) => {
      this.circularToBeOpened = cbicCircularMst;
      if (this.circularToBeOpened) {
        if (this.router.url.includes('circular')) {
          this.taxId = this.circularToBeOpened.tax?.id;
          this.activeTab = 'Circulars';
          this.taxIdRuleCategory = this.circularToBeOpened.tax?.id;
        }
      }
    });
    this.activatedRoute.data.subscribe(({ cbicInstructionMst }) => {
      this.instructionToBeOpened = cbicInstructionMst;
      if (this.instructionToBeOpened) {
        if (this.router.url.includes('instruction')) {
          this.taxId = this.instructionToBeOpened.tax?.id;
          this.activeTab = 'Instructions';
          this.taxIdRuleCategory = this.instructionToBeOpened.tax?.id;
        }
      }
    });
    this.activatedRoute.data.subscribe(({ cbicOrderMst }) => {
      this.orderToBeOpened = cbicOrderMst;
      if (this.orderToBeOpened) {
        if (this.router.url.includes('order')) {
          this.taxId = this.orderToBeOpened.tax?.id;
          this.activeTab = 'Orders';
          this.taxIdRuleCategory = this.orderToBeOpened.tax?.id;
        }
      }
    });



    //allied act fetch name
    this.activatedRoute.data.subscribe(({ cbicAlliedActMst }) => {
      this.alliedActToBeOpened = cbicAlliedActMst;
      if (this.alliedActToBeOpened) {
        if (this.router.url.includes('allied-acts')) {
          this.taxId = this.alliedActToBeOpened.tax?.id;
          this.activeTab = 'Allied Acts';
          this.taxIdRuleCategory = this.alliedActToBeOpened.tax?.id;
        }
      }


    });


  }
 /* allied acts related function */
  /*  ----------------------------------------- */

  fetchAlliedActNameSearch(id: string): void {
    this.cbicAlliedActMstService
      .getAlliedActName(Number(id))
      .subscribe(
        (res: HttpResponse<ICbicAlliedActMst[]>) => {
          this.getAlliedActName = res.body;
          this.alliedActNameCategory = this.getAlliedActName[0].allied_act_name;

          if (this.searchKeyWord) {
            this.search(JSON.stringify({ "keyword": this.searchKeyWord }));
          }


        }

      );
    this.spinnerService.requestEnded();
  }

  loadAlliedActSearch(): void {
    this.loadAlliedActName();
    this.selectedTab = "Allied Acts";
    this.activeTab = "Allied Acts";
    this.searchForAlliedAct(JSON.stringify({ "keyword": this.searchKeyWord }));
    this.alliedActSearchFlag = "N";
  }


  loadAlliedActName(): void {
    this.selectedTab = "Allied Acts";
    this.activeTab = "Allied Acts";
    if (this.storageLanguage === "hi") {
      this.tabName = "संबद्ध अधिनियम"
    } else {
      this.tabName = this.activeTab
    }
    this.sessionStorage.store('activeTab', this.activeTab);
    this.actName = ""
    if (this.alliedActToBeOpened) {
      
      this.alliedYearSelected = this.alliedActToBeOpened.allied_act_dt.get('y');
      this.alliedActNameCategory = this.alliedActToBeOpened.cbicAlliedMst.allied_act_name;
    } else {
      const query: any = {
        taxId: this.taxId,
      };
      query['alliedActName'] = 'Directorate General of Foreign Trade (DGFT)';
      this.alliedYearValueDatabase = [];
      this.cbicAlliedActDtlsService
        .fetchAlliedActYear(query)
        .subscribe(
          (res) => {
            this.alliedYearDatabse = res.body;
            let j = 0;
            for (j; j < this.alliedYearDatabse.length; j++) {
              this.alliedYearValueDatabase.push(this.alliedYearDatabse[j].year)
            }
            this.alliedYearSelected = this.alliedYearValueDatabase[0];

            this.alliedActYearSelected(this.alliedYearSelected);
          });

    }
    this.cbicAlliedActMstService.fetchAlliedActName(this.taxId).subscribe(
      (res: HttpResponse<ICbicAlliedActMst[]>) => {
        this.alliedActNameList = res.body;
      });
    this.fetchTaxName(this.taxId);

  }

  alliedActNameSelectedEvent(value: any): void {
    this.setPageinationDtls();
    if (this.alliedActToBeOpened) {
      this.alliedActToBeOpened = undefined;
    }
    this.alliedActNameCategory = value.alliedActName;
    this.alliedYearSelected = value.alliedYearValue
   
    const query: any = {
      year: this.alliedYearSelected,
      page: 0,
      size: 10,
      taxId: this.taxId

    }

    if (this.alliedActNameCategory) {
      query['alliedActNamecategory'] = this.alliedActNameCategory;
    }


    this.cbicAlliedActDtlsService.fetchAlliedActByYearName(query)
      .subscribe(
        (res: HttpResponse<ICbicAlliedActDtls[]>) => {
          this.alliedActDtls = res.body
          if (this.alliedActDtls.length === 0) {
            this.alliedNotFound = true
          } else {
            this.alliedNotFound = false
          }
        }
      );

    const queryForCount: any = {
      year: this.alliedYearSelected, taxId: this.taxId
    }

    if (this.alliedActNameCategory) {
      queryForCount['alliedActNamecategory'] = this.alliedActNameCategory;
    }
    if (this.alliedActSearchKeyWord) {
      queryForCount['keyword'] = this.alliedActSearchKeyWord;
    }
    this.cbicAlliedActDtlsService.queryCountForAlliedAct(queryForCount).subscribe(
      (res) => {
        this.totalRecordsAlliedRead = res.body;
      });

    return;
  }


  alliedActYearSelected(alliedYear: string): void {
    
    if (this.alliedActToBeOpened && this.alliedActSearchFlag === "N") {
      this.alliedActToBeOpened = undefined;

    }

    const query: any = { year: alliedYear, page: 0, size: 10, taxId: this.taxId };

    if (this.alliedActNameCategory) {
      query['alliedActNamecategory'] = this.alliedActNameCategory;
    } else {
      this.alliedActNameCategory = 'Directorate General of Foreign Trade (DGFT)'; //Defalt should be  Directorate General of Foreign Trade (DGFT)
      query['alliedActNamecategory'] = this.alliedActNameCategory
    }
    if (this.alliedActSearchKeyWord) {
      query['searchKeyword'] = this.alliedActSearchKeyWord;
    }

    this.alliedYearSelected = alliedYear;


    this.cbicAlliedActDtlsService.fetchAlliedActByYearName(query)
      .subscribe(
        (res: HttpResponse<ICbicAlliedActDtls[]>) => {
          this.alliedActDtls = res.body
          if (this.alliedActDtls.length === 0) {
            this.alliedNotFound = true
          } else {
            this.setPageinationDtls();
            this.alliedNotFound = false;
          }

          this.alliedSearch.setAlliedActName(this.alliedActNameCategory)
          this.alliedSearch.setAlliedActYear(alliedYear);
          if (this.alliedActToBeOpened) {
            this.alliedNotFound = false
            this.alliedActDtls = [];
            this.alliedActDtls.push(this.alliedActToBeOpened);
          }

        }
      );
    const queryForCount: any = {};
    queryForCount['year'] = alliedYear;
    queryForCount['taxId'] = this.taxId

    if (this.alliedActNameCategory) {
      queryForCount['alliedActNamecategory'] = this.alliedActNameCategory;
    } else {
      this.alliedActNameCategory = ' Directorate General of Foreign Trade (DGFT)'; //Defalt should be  Directorate General of Foreign Trade (DGFT)
      queryForCount['alliedActNamecategory'] = this.alliedActNameCategory
    }

    if (this.alliedActSearchKeyWord) {
      queryForCount['keyword'] = this.alliedActSearchKeyWord;
    }


    this.cbicAlliedActDtlsService.queryCountForAlliedAct(queryForCount).subscribe(
      (res) => {
        this.totalRecordsAlliedRead = res.body;
        if (this.alliedActToBeOpened) {
          this.totalRecordsAlliedRead = 1;
        }
      });
  }



  searchForAlliedAct(searchQueryJson: string): void {
    this.query = JSON.parse(searchQueryJson);
    this.alliedActSearchKeyWord = this.query.keyword;
    this.alliedActYearSelected(this.alliedYearSelected);
  }

  clearSearchBarAlliedAct(): void {
    this.query = {};
    this.searchKeyWord = "";
    this.alliedActSearchKeyWord = "";
    this.cbicSearchOutputResults.dtos = [];
    this.alliedActYearSelected(this.alliedYearSelected)
  }


  /*  ----------------------------------------- *

 /*acts page related function **/
  fetchAllActs(taxId: string): void {
    this.cbicActMstService
      .findActList(Number(taxId))
      .subscribe(
        (res: HttpResponse<ICbicActMst[]>) => {
          this.actListFetched = res.body;
          if (this.sectionToBeOpened) {
            this.fetchAllchapters(this.sectionToBeOpened.actId.id);
            this.fetchActName(this.sectionToBeOpened.actId.id);
            this.enactmentDt = this.sectionToBeOpened.actId.issueDt !== null ? (this.sectionToBeOpened.actId.issueDt).format('DD-MMM-YYYY') : null;
            this.lastUpdatedDt = this.sectionToBeOpened.actId.amendDt !== null ? (this.sectionToBeOpened.actId.amendDt).format('DD-MMM-YYYY') : this.enactmentDt;
          } else {
            this.fetchAllchapters(this.actListFetched[0].id);
            if (this.storageLanguage === "en") {
              this.actName = this.actListFetched[0].actName;
            } else {
              if (this.actListFetched[0].actNameHi === '' || this.actListFetched[0].actNameHi === null) {
                this.actName = this.actListFetched[0].actName;
              } else {
                this.actName = this.actListFetched[0].actNameHi;
              }
            }

            this.enactmentDt = this.actListFetched[0].issueDt !== null ? (this.actListFetched[0].issueDt).format('DD-MMM-YYYY') : null;
            this.lastUpdatedDt = this.actListFetched[0].amendDt !== null ? (this.actListFetched[0].amendDt).format('DD-MMM-YYYY') : this.enactmentDt;
            this.childSearch.setActId(this.actListFetched[0].id);
            this.fullPdfPath = this.actListFetched[0].contentFilePath;
            this.fullHtmlPath = this.actListFetched[0].contentHtmlFilePath;
            this.actContentId = this.actListFetched[0].contentId;
          }

        });
  }

  actSelected(actId: string): void {
    this.actId = actId;
    this.query = {};
    this.searchKeyWord = "";
    this.cbicSearchOutputResults.dtos = [];
    this.sectionToBeOpened = undefined;
    this.searchNotFound = false;
    /* if(this.sectionViewBy==='Chapter'){
      this.fetchAllchapters(actId);
    }else{
      this.viewByAllSection(actId);
    } */
    this.fetchAllchapters(actId);
    this.fetchActName(actId);
  }
  fetchAllchapters(actId: string): void {

    this.actId = actId;
    this.cbicActChapterMstService
      .findByActId(Number(actId))
      .subscribe(
        (res: HttpResponse<ICbicActChapterMst[]>) => {
          this.chapterListFetched = res.body;
          this.totalChapterForActs = this.chapterListFetched.length;
          this.sectionViewBy = "Section";
          if (this.sectionToBeOpened) {


            for (let i = 0; i < this.chapterListFetched.length; i++) {
              if (this.chapterListFetched[i].id === this.sectionToBeOpened.chapterId.id) {
                this.activeReadingSectionNum = i;
              }
            }
            this.sectionViewBy = "Section";
            if (this.chapterListFetched.length === 1 || this.chapterListFetched.length === 0) {
              this.contentPageService.changeViewBy.next("Section");
              this.sectionViewBy = "Section";

            }

            if (this.chapterListFetched) {
              this.selectedChapterNameAct = this.chapterListFetched[this.activeReadingSectionNum].chapterName;
              this.selectedChapterNoAct = this.chapterListFetched[this.activeReadingSectionNum].chapterNo;
              this.selectedChapterNameActHi = this.chapterListFetched[this.activeReadingSectionNum].chapterNameHi;
              this.selectedChapterNoActHi = this.chapterListFetched[this.activeReadingSectionNum].chapterNoHi;
              //this.child.setChapterDtls({chapterNo : this.chapterListFetched[j].chapterNo, chapterName:this.chapterListFetched[j].chapterName});
            }
            this.fetchSectionData(this.sectionToBeOpened.chapterId.id);


          } else {
            if (this.chapterListFetched) {
              this.selectedChapterNameAct = this.chapterListFetched[0].chapterName;
              this.selectedChapterNoAct = this.chapterListFetched[0].chapterNo;
              this.selectedChapterNameActHi = this.chapterListFetched[0].chapterNameHi;
              this.selectedChapterNoActHi = this.chapterListFetched[0].chapterNoHi;
              //this.child.setChapterDtls({chapterNo : this.chapterListFetched[j].chapterNo, chapterName:this.chapterListFetched[j].chapterName});
            }
            this.fetchSectionData(this.chapterListFetched[0].id);
          }
          const t = this.chapterListFetched.length;

          this.fetchAllSection(actId);
        });
  }

  fetchSectionDataContent(chapterId: string): void {
    this.sectionSelection = false;
    let j = 0;
    for (let i = 0; i < this.chapterListFetched.length; i++) {
      if (this.chapterListFetched[i].id === chapterId) {
        j = i;
      }
    }
    this.activeReadingSectionNum = j;
    this.fetchSectionListForChapter(chapterId);

  }

  fetchSectionListForChapter(chapterId: string): void {
    this.cbicActSectionMstService
      .findByChapterId(Number(chapterId))
      .subscribe(
        (res: HttpResponse<ICbicActSectionMst[]>) => {
          this.sectionDataFetched = res.body;
          if (this.cbicSearchOutputResults.dtos.length > 0) {
            this.filterResults();

          }
        });
    this.contentPageService.closeAllSection.next();
  }


  fetchAllSection(actId: string): void {
    this.cbicActSectionMstService
      .findByActId(Number(actId))
      .subscribe(
        (res: HttpResponse<ICbicActSectionMst[]>) => {
          this.allSectionList = res.body;
          if (this.searchKeyWord) {


            this.search(JSON.stringify({ "keyword": this.searchKeyWord }));
          }
        }
      );
    this.viewByAllSection(actId);
    this.spinnerService.requestEnded();
  }


  openSection(sectionId: string): void {

    this.cbicActSectionMstService.find(Number(sectionId))
      .subscribe(
        (res: HttpResponse<ICbicActSectionMst>) => {
          this.sectionToBeOpened = res.body;
          // this.fetchAllActs(this.sectionToBeOpened.taxId.id);
          // this.fetchSectionData(this.sectionToBeOpened.chapterId.id);
          if (this.sectionViewBy === 'Section') {
            this.fetchSection(sectionId, this.sectionToBeOpened.chapterId.id);
          } else {
            this.fetchSectionData(this.sectionToBeOpened.chapterId.id);
          }

        });

  }
  fetchSection(sectionId: string, id: string): void {

    let j = 0;
    for (let i = 0; i < this.viewByAllSectionList.length; i++) {
      if (Number(this.viewByAllSectionList[i].id) === Number(sectionId)) {
        j = i;
      }
    }
    this.activeReadingSectionNum = j;
    this.cbicActSectionMstService
      .viewSectionById(Number(sectionId))
      .subscribe(
        (res: HttpResponse<ICbicActSectionMst[]>) => {
          this.viewSection = res.body;

          if (this.storageLanguage === "hi") {
            const contentFilePathValue: string = this.viewSection[0].contentFilePathHi ? this.viewSection[0].contentFilePathHi : this.viewSection[0].contentFilePath;
            const contentNoValue: string = this.viewSection[0].sectionNoHi ? this.viewSection[0].sectionNoHi : this.viewSection[0].sectionNo;
            const contentNameValue: string = this.viewSection[0].sectionNameHi ? this.viewSection[0].sectionNameHi : this.viewSection[0].sectionName;
            this.contentPageService.emitFetchDataEvent.next({ "contentFilePath": contentFilePathValue, "contentNo": contentNoValue, "contentName": contentNameValue, "index": 0, "searchKeyword": this.searchKeyWord, "contentTypeTobeFetched": this.selectedTab, "id": this.viewSection[0].contentId });
          } else {
            this.contentPageService.emitFetchDataEvent.next({ "contentFilePath": this.viewSection[0].contentFilePath, "contentNo": this.viewSection[0].sectionNo, "contentName": this.viewSection[0].sectionName, "index": 0, "searchKeyword": this.searchKeyWord, "contentTypeTobeFetched": this.selectedTab, "id": this.viewSection[0].contentId });
          }


          this.fetchChapterName(this.viewSection[0].chapterId.id);
          if (this.searchKeyWord) {
            this.sectionToBeOpened = this.viewByAllSectionList[j];
            this.search(JSON.stringify({ "keyword": this.searchKeyWord }));
          }
        }
      );

  }
  fetchSectionData(chapterId: string): void {
    let a = 0;

    this.cbicActSectionMstService
      .findByChapterId(Number(chapterId))
      .subscribe(
        (res: HttpResponse<ICbicActSectionMst[]>) => {
          this.sectionDataFetched = res.body;
          if (this.cbicSearchOutputResults.dtos.length > 0) {
            this.filterResults();

          }

          if (!this.sectionToBeOpened) {
            this.sectionToBeOpened = this.sectionDataFetched[0];
          }
          let j = -1;
          for (let i = 0; i < this.sectionDataFetched.length; i++) {
            if (this.sectionDataFetched[i].id === this.sectionToBeOpened.id) {
              j = i;
            }
          }
          if (j !== -1) {

            if (this.storageLanguage === "hi") {
              const contentFilePathValue: string = this.sectionDataFetched[j].contentFilePathHi ? this.sectionDataFetched[j].contentFilePathHi : this.sectionDataFetched[j].contentFilePath;
              const contentNoValue: string = this.sectionDataFetched[j].sectionNoHi ? this.sectionDataFetched[j].sectionNoHi : this.sectionDataFetched[j].sectionNo;
              const contentNameValue: string = this.sectionDataFetched[j].sectionNameHi ? this.sectionDataFetched[j].sectionNameHi : this.sectionDataFetched[j].sectionName;
              this.contentPageService.emitFetchDataEvent.next({ "contentFilePath": contentFilePathValue, "contentNo": contentNoValue, "contentName": contentNameValue, "index": j, "searchKeyword": this.searchKeyWord, "contentTypeTobeFetched": this.selectedTab, "id": this.sectionDataFetched[j].contentId });
            } else {
              this.contentPageService.emitFetchDataEvent.next({ "contentFilePath": this.sectionDataFetched[j].contentFilePath, "contentNo": this.sectionDataFetched[j].sectionNo, "contentName": this.sectionDataFetched[j].sectionName, "index": j, "searchKeyword": this.searchKeyWord, "contentTypeTobeFetched": this.selectedTab, "id": this.sectionDataFetched[j].contentId });
            }


            this.activeReadingSectionNum = j;
            if (this.sectionViewBy === 'Chapter') {
              for (let i = 0; i < this.chapterListFetched.length; i++) {
                if (Number(this.chapterListFetched[i].id) === Number(chapterId)) {
                  a = i;
                }
              }
              this.activeReadingSectionNum = a;
            }
          }
          if (!this.sectionSelection) {
            if (j === -1 || j === 0) {

              this.sectionToBeOpened = this.sectionDataFetched[0];
              this.contentPageService.closeAllSection.next();
            }
          }


          this.totalSectionForActs = this.sectionDataFetched.length - 1;
          this.currentSection = j;
          this.sectionListLengthRead = this.sectionDataFetched.length - 1;



        }

      );
  }

  search(searchQueryJson: string): void {

    this.query = JSON.parse(searchQueryJson);
    this.query.actId = this.actId;
    this.query.rows = 100;
    if (!this.query.content) {
      this.query.content = "ACTS"
    }
    this.service.searchInSection(this.query).subscribe(
      (res: HttpResponse<CbicSearchOutputResult>) => {
        this.searchNotFound = false;
        this.cbicSearchOutputResults = res.body ?? new CbicSearchOutputResult([], null);
        if (this.cbicSearchOutputResults.dtos.length === 0) {
          this.searchNotFound = true;
        }
        // alert("From search filterResults")
        this.filterResults();
      });

  }

  filterResults(): void {
    this.chapterListFetched = this.chapterListFetched.filter(((f: ICbicActChapterMst) => this.cbicSearchOutputResults.dtos.some((item: CbicSearchOutput) => item.chapterId?.includes(String(f.id)))));
    this.sectionDataFetched = this.sectionDataFetched.filter(((f: ICbicActSectionMst) => this.cbicSearchOutputResults.dtos.some((item: CbicSearchOutput) => item.sectionId?.includes(String(f.id)))));
    if (this.sectionViewBy === "Section") {
      this.viewByAllSectionList = this.viewByAllSectionList.filter(((f: ICbicRuleSectionMst) => this.cbicSearchOutputResults.dtos.some((item: CbicSearchOutput) => item.sectionId?.includes(String(f.id)))));
    }

    let j = 0;

    if (this.sectionViewBy === "Chapter") {
      if (this.sectionToBeOpened) {
        for (let i = 0; i < this.sectionDataFetched.length; i++) {
          if (this.sectionDataFetched[i].id === this.sectionToBeOpened.id) {
            j = i;

          }
        }
      }
      this.sectionToBeOpened = this.sectionDataFetched[j];
    }

    if (this.sectionViewBy === "Section") {
      if (this.sectionToBeOpened) {
        for (let i = 0; i < this.viewByAllSectionList.length; i++) {
          if (this.viewByAllSectionList[i].id === this.sectionToBeOpened.id) {
            j = i;

          }
        }
      }
      this.sectionToBeOpened = this.viewByAllSectionList[j];
    }
    if (this.query.keyword) {

      this.searchKeyWord = this.query.keyword;
    }
    if (this.sectionToBeOpened) {

      if (this.storageLanguage === "hi") {
        const contentFilePathValue: string = this.sectionToBeOpened.contentFilePathHi ? this.sectionToBeOpened.contentFilePathHi : this.sectionToBeOpened.contentFilePath;
        const contentNoValue: string = this.sectionToBeOpened.sectionNoHi ? this.sectionToBeOpened.sectionNoHi : this.sectionToBeOpened.sectionNo;
        const contentNameValue: string = this.sectionToBeOpened.sectionNameHi ? this.sectionToBeOpened.sectionNameHi : this.sectionToBeOpened.sectionName;
        this.contentPageService.emitFetchDataEvent.next({ "contentFilePath": contentFilePathValue, "contentNo": contentNoValue, "contentName": contentNameValue, "index": j, "searchKeyword": this.searchKeyWord, "contentTypeTobeFetched": this.selectedTab, "id": this.sectionToBeOpened.contentId });
      } else {
        this.contentPageService.emitFetchDataEvent.next({ "contentFilePath": this.sectionToBeOpened.contentFilePath, "contentNo": this.sectionToBeOpened.sectionNo, "contentName": this.sectionToBeOpened.sectionName, "index": j, "searchKeyword": this.searchKeyWord, "contentTypeTobeFetched": this.selectedTab, "id": this.sectionToBeOpened.contentId });
      }



    }
  }

  clearSearchBar(): void {
    this.query = {};
    this.searchKeyWord = "";
    this.cbicSearchOutputResults.dtos = [];
    this.fetchAllchapters(this.sectionToBeOpened.actId.id);
  }
  fetchActName(actId: string): void {
    this.cbicActMstService.find(Number(actId))
      .subscribe(
        (res: HttpResponse<ICbicActMst>) => {
          this.actContentId = res.body?.contentId;
          if (this.storageLanguage === "en") {
            this.actName = res.body?.actName;
          }
          if (this.storageLanguage === "hi") {
            this.actName = res.body?.actNameHi;
            if (this.actName === '' || this.actName === null) {
              this.actName = res.body?.actName;

            }
          }

          this.fullPdfPath = res.body?.contentFilePath;
          this.fullHtmlPath = res.body?.contentHtmlFilePath;
          this.enactmentDt = res.body?.amendDt !== null ? (res.body?.issueDt)?.format('DD-MMM-YYYY') : "";
          this.lastUpdatedDt = res.body?.amendDt !== null ? (res.body?.amendDt)?.format('DD-MMM-YYYY') : this.enactmentDt;
        }
      )
      ;
  }

  loadActs(): void {
    this.selectedTab = "Acts";
    //this.child.closeAllSection();
    this.searchNotFound = false;
    this.contentPageService.closeAllSection.next();
    this.query = {};
    this.searchKeyWord = "";
    this.cbicSearchOutputResults.dtos = [];

    if (this.activeTab !== 'Acts') {
      this.activeTab = 'Acts';
      if (this.storageLanguage === "hi") {
        this.tabName = "अधिनियम"
      } else {
        this.tabName = 'Acts'
      }
      this.sessionStorage.store('activeTab', this.activeTab);
      if (!this.actLoaded) {
        this.fetchTaxName(this.taxId);
        if (this.taxId !== 1000004) {
          this.fetchAllActs(this.taxId);//11 is id for tax type customs
        } else {
          this.fetchServiceTaxData('Acts');
        }

        this.actLoaded = true;
      } else {
        this.fetchActName(this.actId);
      }
    } else {
      this.fetchActName(this.actId);
    }
  }
  loadNotificationsCategory(): void {
    this.selectedTab = "Notifications";
    this.activeTab = "Notifications";
    if (this.storageLanguage === "hi") {
      this.tabName = "सूचनाएं"
    } else {
      this.tabName = this.activeTab
    }
    this.sessionStorage.store('activeTab', this.activeTab);
    this.actName = ""
    if (this.notificationToBeOpened) {
      this.notiYearSelected = this.notificationToBeOpened.notificationDt.get('y');
      this.notificationCategory = this.notificationToBeOpened.notificationCategory;
    } else {
      this.notiYearSelected = new Date().getFullYear();
    }

    this.notificationYearSelected(this.notiYearSelected);
    this.cbicNotificationMstService.fetchCategory(this.taxId).subscribe(
      (res: HttpResponse<ICbicNotificationMst[]>) => {

        this.notificationCategoryList = res.body;
      });
    this.fetchTaxName(this.taxId);
    //this.notifiSearch.setNotificationYear(this.notiYearSelected); 
  }

  loadNotificationsSearch(): void {
    this.selectedTab = "Notifications";
    this.activeTab = "Notifications";

    this.loadNotificationsCategory();
    this.searchForNotifications(JSON.stringify({ "keyword": this.searchKeyWord }));
    this.notificationSearchFlag = "N";



    //this.notifiSearch.setNotificationYear(); 

  }



  loadCirculars(): void {
    this.selectedTab = "Circulars";
    this.activeTab = "Circulars";
    if (this.storageLanguage === "hi") {
      this.tabName = "परिपत्र"
    } else {
      this.tabName = this.activeTab
    }
    this.sessionStorage.store('activeTab', this.activeTab);
    this.actName = ""
    this.fetchAllCircularList(this.taxId);

  }



  selectChapterDtls(data: { selectChapterNo: any, selectChapterName: any, selectChapterNoHi: any, selectChapterNameHi: any }): void {
    this.selectedChapterNameAct = data.selectChapterName;
    this.selectedChapterNoAct = data.selectChapterNo;
    this.selectedChapterNoActHi = data.selectChapterNoHi;
    this.selectedChapterNameActHi = data.selectChapterNameHi;


  }
  fetchChapterName(id: string): void {
    this.cbicActChapterMstService
      .getChapterName(Number(id))
      .subscribe(
        (res: HttpResponse<ICbicActChapterMst[]>) => {
          this.getChapterName = res.body;
          this.selectedChapterNoAct = this.getChapterName[0].chapterNo;
          this.selectedChapterNameAct = this.getChapterName[0].chapterName;
          this.selectedChapterNoActHi = this.getChapterName[0].chapterNoHi;
          this.selectedChapterNameActHi = this.getChapterName[0].chapterNameHi;
          if (this.searchKeyWord) {


            this.search(JSON.stringify({ "keyword": this.searchKeyWord }));
          }
        }
      );
    this.spinnerService.requestEnded();
  }

  fetchSectionSelection(data: { sectionId: string, index: number }): void {
    this.sectionSelection = true;
    this.openSection(data.sectionId);
  }

  viewByAllSection(actId: string): void {
    this.cbicActSectionMstService
      .viewAllSectionByActId(Number(actId))
      .subscribe(
        (res: HttpResponse<ICbicActSectionMst[]>) => {
          this.viewByAllSectionList = res.body;
          if (this.sectionViewBy === 'Section') {
            if (this.sectionToBeOpened) {
              this.fetchSection(this.sectionToBeOpened.id, this.sectionToBeOpened.chapterId.id);
            } else {
              this.fetchSection(this.viewByAllSectionList[0].id, this.viewByAllSectionList[0].chapterId.id);
            }

          }

          this.totalSectionForActs = this.viewByAllSectionList.length;

          let j = 0;
          if (this.sectionToBeOpened) {
            for (let i = 0; i < this.viewByAllSectionList.length; i++) {
              if (this.viewByAllSectionList[i].id === this.sectionToBeOpened.id) {
                j = i;
              }
            }
          }

          if (this.searchKeyWord) {


            this.search(JSON.stringify({ "keyword": this.searchKeyWord }));
          }
          /*if(this.sectionViewBy ==='Section'){
            this.fetchSection(this.viewByAllSectionList[j].id, this.viewByAllSectionList[j].chapterId);
          }*/
        }
      );
  }

  fetchSectionRelatedChapter(chapterId: string): void {
    this.searchNotFound = false;
    if (chapterId !== "") {


      this.cbicActSectionMstService
        .findByChapterId(Number(chapterId))
        .subscribe(
          (res: HttpResponse<ICbicActSectionMst[]>) => {
            this.sectionDataFetched = res.body;
            //this.sectionToBeOpened = this.sectionDataFetched[0];
            this.allSectionList = this.sectionDataFetched;
            if (this.sectionViewBy === 'Chapter') {
              this.sectionToBeOpened = this.sectionDataFetched[0];
              for (let i = 0; i < this.chapterListFetched.length; i++) {
                if (this.chapterListFetched[i].id === Number(chapterId)) {
                  this.activeReadingSectionNum = i;
                }
              }
            }

            if (this.storageLanguage === "hi") {
              const contentFilePathValue: string = this.sectionToBeOpened.contentFilePathHi ? this.sectionToBeOpened.contentFilePathHi : this.sectionToBeOpened.contentFilePath;
              const contentNoValue: string = this.sectionToBeOpened.sectionNoHi ? this.sectionToBeOpened.sectionNoHi : this.sectionToBeOpened.sectionNo;
              const contentNameValue: string = this.sectionToBeOpened.sectionNameHi ? this.sectionToBeOpened.sectionNameHi : this.sectionToBeOpened.sectionName;
              this.contentPageService.emitFetchDataEvent.next({ "contentFilePath": contentFilePathValue, "contentNo": contentNoValue, "contentName": contentNameValue, "index": 0, "searchKeyword": this.searchKeyWord, "contentTypeTobeFetched": this.selectedTab, "id": this.sectionToBeOpened.contentId });
            } else {
              this.contentPageService.emitFetchDataEvent.next({ "contentFilePath": this.sectionToBeOpened.contentFilePath, "contentNo": this.sectionToBeOpened.sectionNo, "contentName": this.sectionToBeOpened.sectionName, "index": 0, "searchKeyword": this.searchKeyWord, "contentTypeTobeFetched": this.selectedTab, "id": this.sectionToBeOpened.contentId });
            }



            //this.child.fetchData(this.sectionToBeOpened.contentFilePath,this.sectionToBeOpened.sectionNo,this.sectionToBeOpened.sectionName,0, this.searchKeyWord);


            this.selectChapterDtls({
              selectChapterNo: this.chapterListFetched[this.activeReadingSectionNum].chapterNo, selectChapterName: this.chapterListFetched[this.activeReadingSectionNum].chapterName
              , selectChapterNoHi: this.chapterListFetched[this.activeReadingSectionNum].chapterNoHi, selectChapterNameHi: this.chapterListFetched[this.activeReadingSectionNum].chapterNameHi
            });
          });
    } else {
      this.fetchAllSection(this.actId);
    }

  }
  /*acts page related function ends**/

  /** common function for all tabs*/
  goToNextChapter(): void {
    this.sectionSelection = false;
    if (this.activeTab === 'Acts') {
      this.activeReadingSectionNum += 1;
      for (let i = 0; i < this.chapterListFetched.length; i++) {
        if (this.chapterListFetched[i].id === this.sectionToBeOpened.chapterId.id) {
          const nextChapterId = this.chapterListFetched[i + 1].id;
          this.fetchSectionData(nextChapterId);
          this.selectedChapterNameAct = this.chapterListFetched[i + 1].chapterName;
          this.selectedChapterNoAct = this.chapterListFetched[i + 1].chapterNo;
          this.selectedChapterNameActHi = this.chapterListFetched[i + 1].chapterNameHi;
          this.selectedChapterNoActHi = this.chapterListFetched[i + 1].chapterNoHi;

        }
      }

    } else if (this.activeTab === 'Regulations') {
      this.activeReadingRegulationNum += 1;
      this.fetchRegulationListForGivenChapter(this.totalChapterForRegulations[this.activeReadingRegulationNum].id);
    } else if (this.activeTab === 'Rules') {
      this.activeReadingRuleNum = Number(this.activeReadingRuleNum) + 1;
      this.fetchRulesData(this.ruleChapterListFetched[this.activeReadingRuleNum].id)
      this.selectedChapterNameRules = this.ruleChapterListFetched[this.activeReadingRuleNum].chapterName;
      this.selectedChapterNoRules = this.ruleChapterListFetched[this.activeReadingRuleNum].chapterNo;
    }
  }
  gotoPreviousChapter(): void {
    this.sectionSelection = false;
    if (this.activeTab === 'Acts') {
      for (let i = 0; i < this.chapterListFetched.length; i++) {
        if (this.chapterListFetched[i].id === this.sectionToBeOpened.chapterId.id) {
          const nextChapterId = this.chapterListFetched[i - 1].id;
          this.fetchSectionData(nextChapterId);
          this.selectedChapterNameAct = this.chapterListFetched[i - 1].chapterName;
          this.selectedChapterNoAct = this.chapterListFetched[i - 1].chapterNo;
          this.selectedChapterNameAct = this.chapterListFetched[i - 1].chapterNameHi;
          this.selectedChapterNoAct = this.chapterListFetched[i - 1].chapterNoHi;
          //this.child.fetchData(this.sectionToBeOpened.contentFilePath,this.sectionToBeOpened.sectionNo,this.sectionToBeOpened.sectionName,0);
          //this.child.setChapterDtls({chapterNo : this.chapterListFetched[i-1].chapterNo, chapterName:this.chapterListFetched[i-1].chapterName});
          this.activeReadingSectionNum -= 1;
        }
      }
    } else if (this.activeTab === 'Regulations') {
      this.activeReadingRegulationNum += 1;
      this.fetchRegulationListForGivenChapter(this.totalChapterForRegulations[this.activeReadingRegulationNum].id);
    } else if (this.activeTab === 'Rules') {
      this.activeReadingRuleNum = Number(this.activeReadingRuleNum) - 1;
      this.fetchRulesData(this.ruleChapterListFetched[this.activeReadingRuleNum].id)
      this.selectedChapterNameRules = this.ruleChapterListFetched[this.activeReadingRuleNum].chapterName;
      this.selectedChapterNoRules = this.ruleChapterListFetched[this.activeReadingRuleNum].chapterNo;
    }
  }

  fetchTaxName(taxId: string): void {
    this.cbicTaxMstService.find(Number(taxId))
      .subscribe(
        (res: HttpResponse<ICbicTaxMst>) => {
          this.taxName = res.body?.taxName;
        }
      )
      ;
  }

  selectSwitchEvent(data: { selectSectionView: any, selection: string }): void {
    if (data.selection === "Section") {
      //this.child.closeAllSection();
      this.contentPageService.closeAllSection.next();
      this.sectionViewBy = data.selection;

      this.selectSectionDtls({ selectedTab: 'Acts', selectSectionId: this.viewByAllSectionList[0].id, selectSectionNo: this.viewByAllSectionList[0].sectionNo, selectSectionName: this.viewByAllSectionList[0].sectionName, selectSectionNoHi: this.viewByAllSectionList[0].sectionNoHi, selectSectionNameHi: this.viewByAllSectionList[0].sectionNameHi, selectChapterId: this.viewByAllSectionList[0].chapterId.id });
      this.activeReadingSectionNum = 0;
    } else if (data.selection === "Regulations") {
      this.regulationViewBy = data.selection;
      if (!this.regualtionToBoOpen) {
        this.fetchRegulationContent(this.regulationList[0].id);
        this.activeReadingRegulationNum = this.activeRegulationPrevNumber;
        this.activeRegulationPrevNumber = 0;
      } else {
        this.fetchRegulationContent(this.regualtionToBoOpen.id);
      }

    } else if (data.selection === "Rules") {

      //this.child.closeAllSection();
      this.contentPageService.closeAllSection.next();
      this.rulesViewBy = data.selection;
      if (!this.ruleToBoOpen) {


        this.selectRuleSectionDtls({ selectSectionId: this.viewBySectionAllRuleList[0].id, selectSectionNo: this.viewBySectionAllRuleList[0].sectionNo, selectSectionName: this.viewBySectionAllRuleList[0].sectionName, selectChapterId: this.viewBySectionAllRuleList[0].cbicRuleChapterMst.id });
      } else {
        this.selectRuleSectionDtls({ selectSectionId: this.ruleToBoOpen.id, selectSectionNo: this.ruleToBoOpen.sectionNo, selectSectionName: this.ruleToBoOpen.sectionName, selectChapterId: this.ruleToBoOpen.cbicRuleChapterMst.id });
      }


      this.activeReadingRuleNum = 0;

    }

    else {

      this.sectionViewBy = "Chapter";
      this.regulationViewBy = "Chapter";
      this.rulesViewBy = "Chapter";

      this.contentPageService.closeAllSection.next();
      this.activeReadingSectionNum = 0;
      if (this.activeTab === 'Acts') {



        this.fetchSectionData(this.chapterListFetched[0].id);
        this.selectedChapterNoAct = this.chapterListFetched[0].chapterNo;
        this.selectedChapterNameAct = this.chapterListFetched[0].chapterName;
        this.selectedChapterNoActHi = this.chapterListFetched[0].chapterNo;
        this.selectedChapterNameActHi = this.chapterListFetched[0].chapterName;

      } else if (this.activeTab === 'Rules') {

        this.selectRuleSectionDtls({ selectSectionId: this.viewBySectionAllRuleList[0].id, selectSectionNo: this.viewBySectionAllRuleList[0].sectionNo, selectSectionName: this.viewBySectionAllRuleList[0].sectionName, selectChapterId: this.viewBySectionAllRuleList[0].cbicRuleChapterMst.id })
        this.activeReadingRuleNum = 0;

      } else if (this.activeTab === 'Regulations') {

        this.fetchRegulationListforChapter(this.totalChapterForRegulations[0].id)

      }

    }

  }

  selectSectionDtls(data: { selectedTab: any, selectSectionId: any, selectSectionNo: any, selectSectionName: any, selectSectionNoHi: any, selectSectionNameHi: any, selectChapterId: any }): void {

    if (data.selectedTab === 'Acts') {

      this.fetchSection(data.selectSectionId, data.selectChapterId);
    } else if (data.selectedTab === 'Regulations') {
      this.regulationSelected(data.selectSectionId);
    } else if (data.selectedTab === 'Rules') {
      this.ruleSelected(data.selectSectionId);
    }

  }

  goToNxtSection(): void {
    if (this.activeTab === 'Acts') {
      if (this.activeReadingSectionNum < this.viewByAllSectionList.length - 1) {
        this.activeReadingSectionNum += 1;
        this.selectSectionDtls({ selectedTab: 'Acts', selectSectionId: this.viewByAllSectionList[this.activeReadingSectionNum].id, selectSectionNo: this.viewByAllSectionList[this.activeReadingSectionNum].sectionNo, selectSectionName: this.viewByAllSectionList[this.activeReadingSectionNum].sectionName, selectSectionNoHi: this.viewByAllSectionList[this.activeReadingSectionNum].sectionNoHi, selectSectionNameHi: this.viewByAllSectionList[this.activeReadingSectionNum].sectionNameHi, selectChapterId: this.viewByAllSectionList[this.activeReadingSectionNum].chapterId.id });
      }
    } else if (this.activeTab === 'Rules') {
      this.activeReadingRuleNum += 1;
      this.selectRuleSectionDtls({ selectSectionId: this.viewBySectionAllRuleList[this.activeReadingRuleNum].id, selectSectionNo: this.viewBySectionAllRuleList[this.activeReadingRuleNum].sectionNo, selectSectionName: this.viewBySectionAllRuleList[this.activeReadingRuleNum].sectionName, selectChapterId: this.viewBySectionAllRuleList[this.activeReadingRuleNum].cbicRuleChapterMst.id });
    } else if (this.activeTab === 'Regulations') {
      this.activeReadingRegulationNum += 1;
      this.regulationSelected(this.regulationList[this.activeReadingRegulationNum].id);
    }
  }

  goToPrvsSection(): void {
    if (this.activeTab === 'Acts') {

      //this.child.closeAllSection();
      this.contentPageService.closeAllSection.next();
      if (this.activeReadingSectionNum > 0) {
        this.activeReadingSectionNum -= 1;
        this.selectSectionDtls({ selectedTab: 'Acts', selectSectionId: this.viewByAllSectionList[this.activeReadingSectionNum].id, selectSectionNo: this.viewByAllSectionList[this.activeReadingSectionNum].sectionNo, selectSectionName: this.viewByAllSectionList[this.activeReadingSectionNum].sectionName, selectSectionNoHi: this.viewByAllSectionList[this.activeReadingSectionNum].sectionNoHi, selectSectionNameHi: this.viewByAllSectionList[this.activeReadingSectionNum].sectionNameHi, selectChapterId: this.viewByAllSectionList[this.activeReadingSectionNum].chapterId.id });

      }
    } else if (this.activeTab === 'Rules') {
      this.activeReadingRuleNum -= 1;
      this.selectRuleSectionDtls({ selectSectionId: this.viewBySectionAllRuleList[this.activeReadingRuleNum].id, selectSectionNo: this.viewBySectionAllRuleList[this.activeReadingRuleNum].sectionNo, selectSectionName: this.viewBySectionAllRuleList[this.activeReadingRuleNum].sectionName, selectChapterId: this.viewBySectionAllRuleList[this.activeReadingRuleNum].cbicRuleChapterMst.id });
    } else if (this.activeTab === 'Regulations') {
      this.activeReadingRegulationNum -= 1;
      this.regulationSelected(this.regulationList[this.activeReadingRegulationNum].id);
    }
  }
  /** common function for all tabs ends*/

  /**regulation tab related functions */
  regulationClearFired(): void {
    this.query = {};
    this.searchKeyWord = "";
    this.cbicSearchOutputResults.dtos = [];
    this.searchNotFound = false;
    this.fetchAllRegulationsforRegDocId(this.regulationDocId);
    this.fetchRegulationDocName(this.regulationDocId);
    // this.reguChildSearch.setRegulationIdFromClear(this.regulationDocId)
  }
  regulationSearchFired(searchQueryJson: string): void {
    this.query = JSON.parse(searchQueryJson);
    this.query.content = 'REGULATIONS';
    this.query.rows = 100;
    if (this.regualtionToBoOpen) {
      this.query.actId = this.regualtionToBoOpen.regulationDoc.id;
    }
    this.searchKeyWord = this.query.keyword ? this.query.keyword : "";
    this.service.searchInSection(this.query).subscribe(
      (res: HttpResponse<CbicSearchOutputResult>) => {
        this.searchNotFound = false;
        this.cbicSearchOutputResults = res.body ?? new CbicSearchOutputResult([], null);
        if (this.cbicSearchOutputResults.dtos.length === 0) {
          this.searchNotFound = true;
        }
        if (!this.searchNotFound) {
          this.filterRegulationsResults();
        }

      });
  }
  filterRegulationsResults(): void {
    this.regulationList = this.regulationList.filter(((f: ICbicRegulationMst) => this.cbicSearchOutputResults.dtos.some((item: CbicSearchOutput) => item.sectionId?.includes(String(f.id)))));
    if (!this.regualtionToBoOpen) {
      this.fetchRegulationContent(this.regulationList[0].id)
    }
    //this.regulationChildRead.fetchData(this.regulationList[0].contentFilePath,this.regulationList[0].regulationNo,this.regulationList[0].regulationName,0,this.searchKeyWord);
  }
  loadRegulations(): void {
    this.query = {};
    this.searchKeyWord = "";
    this.cbicSearchOutputResults.dtos = [];
    this.searchNotFound = false;
    if (this.activeTab !== 'Regulations') {
      this.activeTab = 'Regulations';
      if (this.storageLanguage === "hi") {
        this.tabName = "विनियमन"
      } else {
        this.tabName = this.activeTab
      }
      this.sessionStorage.store('activeTab', this.activeTab);
      this.fetchTaxName(this.taxId);
      this.fetchAllRegulationDoc(this.taxId);


    }
  }
  fetchAllRegulationDoc(taxId: string): void {

    this.cbicRegulationDocMstService
      .findByTaxId(Number(taxId))
      .subscribe(
        (res: HttpResponse<ICbicRegulationDocMst[]>) => {
          this.regulationDocList = res.body;
          if (!this.regualtionToBoOpen) {


            this.regulationDocSelected(this.regulationDocList[0].id)
            // this.reguChildSearch.setRegulationId(this.regulationDocList[0].id);
          } else {
            this.regulationDocSelected(this.regualtionToBoOpen.regulationDoc.id)
            // this.reguChildSearch.setRegulationId(this.regualtionToBoOpen.regulationDoc.id);
          }
        });

    this.fetchRegulationCategory(taxId);

  }

  regulationDocSelectedEvent(regulationDocId: string): void {
    if (this.regualtionToBoOpen) {
      this.query = {};
      this.searchKeyWord = "";
      this.cbicSearchOutputResults.dtos = [];
      this.regualtionToBoOpen = undefined;
      this.searchNotFound = false;
    }

    this.regulationDocSelected(regulationDocId);
  }

  regulationDocSelected(regulationDocId: string): void {

    this.regulationDocId = regulationDocId;
    this.fetchAllRegulationsforRegDocId(regulationDocId);
    this.fetchRegulationDocName(regulationDocId);
  }

  regulationSelected(regulationId: string): void {
    let j = 0;
    for (let i = 0; i < this.regulationList.length; i++) {
      if (Number(regulationId) === this.regulationList[i].id) {
        j = i;
      }
    }
    this.activeReadingRegulationNum = j;

    this.fetchRegulationContent(regulationId);
  }
  fetchRegulationDocName(regulationDocId: string): void {
    this.cbicRegulationDocMstService.find(Number(regulationDocId)).subscribe(

      (res: HttpResponse<CbicRegulationDocMst>) => {
        this.regulationDoc = {};
        if (res.body) {
          this.regulationDoc = res.body;
          this.actContentId = this.regulationDoc.contentId
          this.actName = this.regulationDoc.regulationDocName;
          this.enactmentDt = (this.regulationDoc.issueDt)?.format('DD-MMM-YYYY');
          this.lastUpdatedDt = this.regulationDoc.amendDt !== null ? (this.regulationDoc.amendDt)?.format('DD-MMM-YYYY') : this.enactmentDt;

          this.fullPdfPath = this.regulationDoc.contentFilePath;
          this.fullHtmlPath = this.regulationDoc.contentHtmlFilePath;
        }
      }
    )


  }


  fetchRegulationListforChapter(regualtionChapterId: string): void {
    for (let i = 0; i < this.totalChapterForRegulations.length; i++) {
      if (this.totalChapterForRegulations[i].id === regualtionChapterId) {
        this.activeReadingRegulationNum = i;
      }
    }

    this.fetchRegulationListForGivenChapter(regualtionChapterId);
  }
  fetchRegulationContent(regulationId: string): void {

    this.cbicRegulationMstService.find(Number(regulationId)).subscribe(
      (res: HttpResponse<ICbicRegulationMst>) => {
        this.regulationView = [];
        this.regulationView[0] = res.body;

        if (this.storageLanguage === "hi") {
          const contentFilePathValue: string = this.regulationView[0].contentFilePathHi ? this.regulationView[0].contentFilePathHi : this.regulationView[0].contentFilePath;
          const contentNoValue: string = this.regulationView[0].regulationNoHi ? this.regulationView[0].regulationNoHi : this.regulationView[0].regulationNo;
          const contentNameValue: string = this.regulationView[0].regulationNameHi ? this.regulationView[0].regulationNameHi : this.regulationView[0].regulationName;
          this.contentPageService.emitFetchDataEvent.next({ "contentFilePath": contentFilePathValue, "contentNo": contentNoValue, "contentName": contentNameValue, "index": 0, "searchKeyword": this.searchKeyWord, "contentTypeTobeFetched": this.activeTab, "id": this.regulationView[0].contentId });
        } else {
          this.contentPageService.emitFetchDataEvent.next({ "contentFilePath": this.regulationView[0].contentFilePath, "contentNo": this.regulationView[0].regulationNo, "contentName": this.regulationView[0].regulationName, "index": 0, "searchKeyword": this.searchKeyWord, "contentTypeTobeFetched": this.activeTab, "id": this.regulationView[0].contentId });
        }



        //this.contentPageService.emitFetchDataEvent.next({ "contentFilePath": this.regulationView[0].contentFilePath, "contentNo": this.regulationView[0].regulationNo, "contentName": this.regulationView[0].regulationName, "index": 0, "searchKeyword": this.searchKeyWord, "contentTypeTobeFetched": this.activeTab, "id": this.regulationView[0].contentId });

      });

  }


  fetchAllRegulationsforRegDocId(regulationDocId: string): void {

    this.cbicRegulationMstService.findRegulationList(Number(regulationDocId))
      .subscribe(
        (res: HttpResponse<ICbicRegulationMst[]>) => {
          this.regulationList = [];
          this.regulationList = res.body;

          this.fetchRegulationChapterName(this.regulationList[0].regulationChapter.id);
          this.totalRegulationForRegulation = this.regulationList.length;
          if (this.regualtionToBoOpen) {

            for (let i = 0; i < this.regulationList.length; i++) {
              if (this.regualtionToBoOpen.id === this.regulationList[i].id) {
                this.activeReadingRegulationNum = i;

              }
            }
            // this.fetchRegulationContent(this.regualtionToBoOpen.id)
            this.reguChildSearch.setRegulationId(this.regualtionToBoOpen.regulationDoc.id, this.regualtionToBoOpen.id);
          } else {
            this.activeReadingRegulationNum = 0;
            // this.fetchRegulationContent(this.regulationList[0].id);
            this.reguChildSearch.setRegulationId(regulationDocId, this.regulationList[0].id);
          }
          if (this.searchKeyWord) {
            this.regulationSearchFired(JSON.stringify({ "keyword": this.searchKeyWord }));
            for (let i = 0; i < this.regulationList.length; i++) {
              if (this.regualtionToBoOpen.id === this.regulationList[i].id) {
                this.activeReadingRegulationNum = i;

              }
            }
          }
          this.cbicRegulationChapterMstService.findAllChaptersForRegDocId(Number(regulationDocId))
            .subscribe(
              (regRes: HttpResponse<ICbicRegulationChapterMst[]>) => {
                this.totalChapterForRegulations = [];

                this.totalChapterForRegulations = regRes.body;
                if (this.totalChapterForRegulations.length === 1 || this.totalChapterForRegulations.length === 0) {

                  this.regulationViewBy = "Regulations";
                  this.activeRegulationPrevNumber = this.activeReadingRegulationNum;
                  this.contentPageService.changeViewBy.next("Regulations");
                } else {
                  this.totalChapterForRegulationsLen = this.totalChapterForRegulations.length;
                  this.fetchRegulationListForGivenChapter(this.totalChapterForRegulations[0].id);
                }
              }
            );
        }
      );


  }
  fetchRegulationListForGivenChapter(regulationChapterId: any): void {
    this.cbicRegulationMstService.findRegulationListByChapterId(Number(regulationChapterId))
      .subscribe(
        (res: HttpResponse<ICbicRegulationMst[]>) => {
          this.regulationListByChapter = [];
          this.regulationListByChapter = res.body;

          if (this.storageLanguage === "hi") {
            const contentFilePathValue: string = this.regulationListByChapter[0].contentFilePathHi ? this.regulationListByChapter[0].contentFilePathHi : this.regulationListByChapter[0].contentFilePath;
            const contentNoValue: string = this.regulationListByChapter[0].regulationNoHi ? this.regulationListByChapter[0].regulationNoHi : this.regulationListByChapter[0].regulationNo;
            const contentNameValue: string = this.regulationListByChapter[0].regulationNameHi ? this.regulationListByChapter[0].regulationNameHi : this.regulationListByChapter[0].regulationName;
            this.contentPageService.emitFetchDataEvent.next({ "contentFilePath": contentFilePathValue, "contentNo": contentNoValue, "contentName": contentNameValue, "index": 0, "searchKeyword": this.searchKeyWord, "contentTypeTobeFetched": this.selectedTab, "id": this.regulationListByChapter[0].contentId })
          } else {
            this.contentPageService.emitFetchDataEvent.next({ "contentFilePath": this.regulationListByChapter[0].contentFilePath, "contentNo": this.regulationListByChapter[0].regulationNo, "contentName": this.regulationListByChapter[0].regulationName, "index": 0, "searchKeyword": this.searchKeyWord, "contentTypeTobeFetched": this.selectedTab, "id": this.regulationListByChapter[0].contentId })
          }
        }
      );

    this.cbicRegulationChapterMstService.find(regulationChapterId)
      .subscribe(
        (res: HttpResponse<ICbicRegulationChapterMst>) => {

          this.selectedChapterNoRegulation = res.body?.chapterNo;
          this.selectedChapterNameRegulation = res.body?.chapterName;
          this.selectedChapterNoRegulationHi = res.body?.chapterNoHi;
          this.selectedChapterNameRegulationHi = res.body?.chapterNameHi;
        }
      );

  }

  fetchRegulationCategory(taxId: string): void {
    this.cbicRegulationDocMstService
      .fetchRegulationCategory(Number(taxId))
      .subscribe(
        (res: HttpResponse<ICbicRegulationDocMst[]>) => {
          this.regulationsCategoryList = res.body;

        });
  }




  fetchCategoryRelatedRegulations(regulationCategory: string): void {
    if (regulationCategory === "All" || regulationCategory === "सभी") {
      this.fetchAllRegulationDoc(this.taxId);
      //this.regulationDocSelected(this.regulationDocList[0].id);
    } else {
      this.cbicRegulationDocMstService.fetchCategoryRelatedRegulations(this.taxId, regulationCategory)
        .subscribe(
          (res: HttpResponse<ICbicRegulationDocMst[]>) => {
            this.regulationDocList = res.body;
            this.regulationDocSelected(this.regulationDocList[0].id);
            this.reguChildSearch.selectedRegulationDocId = this.regulationDocList[0].id;
          });

    }
  }
  fetchRegulationChapterName(id: string): void {

    this.cbicRegulationChapterMstService
      .getChapterNameForRegulation(Number(id))
      .subscribe(
        (res: HttpResponse<ICbicRegulationChapterMst[]>) => {
          this.getChapterNameForRegulation = res.body;
          this.selectedChapterNoRegulation = this.getChapterNameForRegulation[0].chapterNo;
          this.selectedChapterNameRegulation = this.getChapterNameForRegulation[0].chapterName;
          this.selectedChapterNoRegulationHi = this.getChapterNameForRegulation[0].chapterNoHi;
          this.selectedChapterNameRegulationHi = this.getChapterNameForRegulation[0].chapterNameHi;

        }
      );
    this.spinnerService.requestEnded();
  }

  /**regulation tab related functions ends*/






  /**Rules tab related functions */


  loadRules(): void {
    this.selectedTab = "Rules";
    //this.childRulesRead.closeAllSection();
    this.searchNotFound = false;
    this.contentPageService.closeAllSection.next();
    this.query = {};
    this.searchKeyWord = "";
    this.cbicSearchOutputResults.dtos = [];
    // this.childRuleSearch.searched = false;

    if (this.activeTab !== 'Rules') {
      this.activeTab = 'Rules';
      if (this.storageLanguage === "hi") {
        this.tabName = "नियम"
      } else {
        this.tabName = this.activeTab
      }
      this.sessionStorage.store('activeTab', this.activeTab);
      if (!this.ruleLoaded) {
        this.fetchTaxName(this.taxId);
        //this.fetchAllRules(this.taxId);
        if (this.taxId !== 1000004) {
          this.fetchAllRules(this.taxId);//11 is id for tax type customs
        } else {
          this.fetchAllServiceTaxRuleList(this.taxId);
        }
        this.ruleLoaded = true;
      } else {
        this.fetchRuleName(this.ruleId);
      }

    } else {

      this.fetchRuleName(this.ruleId);

    }
  }
  fetchAllChaptersForRules(ruleId: string): void {

    this.ruleId = ruleId;
    this.cbicRuleChapterMstService
      .findChapterByRuleId(Number(ruleId))
      .subscribe(
        (res: HttpResponse<ICbicRuleChapterMst[]>) => {
          this.ruleChapterListFetched = res.body;
          const a = this.childRuleSearch.selectedRule;
          let j = 0;


          if (this.ruleToBoOpen) {
            for (let i = 0; i < this.ruleChapterListFetched.length; i++) {
              if (this.ruleChapterListFetched[i].id === this.ruleToBoOpen.cbicRuleChapterMst.id) {
                j = i;
              }
            }
            this.activeReadingRuleNum = j;

            if (this.ruleChapterListFetched) {
              //this.childRulesRead.setChapterDtls({chapterNo : this.ruleChapterListFetched[j].chapterNo, chapterName:this.ruleChapterListFetched[j].chapterName});
              this.selectedChapterNoRules = this.ruleChapterListFetched[j].chapterNo;
              this.selectedChapterNameRules = this.ruleChapterListFetched[j].chapterName;
            }
            this.fetchRulesData(this.ruleToBoOpen.cbicRuleChapterMst.id);

          } else {
            if (this.ruleChapterListFetched.length !== 0) {
              //this.childRulesRead.setRulesChapterDtls({chapterNo : this.ruleChapterListFetched[j].chapterNo, chapterName:this.ruleChapterListFetched[j].chapterName});
              this.selectedChapterNoRules = this.ruleChapterListFetched[j].chapterNo;
              this.selectedChapterNameRules = this.ruleChapterListFetched[j].chapterName;
              //this.fetchRulesData(this.ruleChapterListFetched[0].id);
            }
            this.fetchRulesData(this.ruleChapterListFetched[0].id);
          }
          this.totalChapterForRules = this.ruleChapterListFetched.length;
          const t = this.ruleChapterListFetched.length;

          this.fetchAllSectionForRules(ruleId);
        });
  }
  fetchAllRules(taxId: string): void {
    this.cbicRuleMstService
      .findRulesList(Number(taxId))
      .subscribe(
        (res: HttpResponse<ICbicRuleMst[]>) => {
          this.rulesListFetched = res.body;
          if (this.rulesListFetched.length === 0) {
            // alert("Rules will be available soon!")
            this.noRules = true;
          } else {
            for (let i = 0; i < this.rulesListFetched.length; i++) {
              if (this.storageLanguage === "hi" && this.rulesListFetched[i].ruleDocNameHi) {
                let maxlen = this.rulesListFetched[i].ruleDocNameHi.length;
                if (maxlen > 70) {
                  maxlen = 67;
                }

                this.rulesListFetched[i].ruleDocNameLable = String(this.rulesListFetched[i].ruleDocNameHi.substring(0, maxlen));
                if (maxlen === 67) {
                  this.rulesListFetched[i].ruleDocNameLable = String(this.rulesListFetched[i].ruleDocNameHi.substring(0, maxlen)) + "...";
                }

              } else {

                let maxlen = this.rulesListFetched[i].ruleDocName.length;
                if (maxlen > 70) {
                  maxlen = 67;
                }
                this.rulesListFetched[i].ruleDocNameLable = String(this.rulesListFetched[i].ruleDocName.substring(0, maxlen));
                if (maxlen === 67) {
                  this.rulesListFetched[i].ruleDocNameLable = String(this.rulesListFetched[i].ruleDocName.substring(0, maxlen)) + "...";
                }
              }

            }
            this.firstRuleId = this.rulesListFetched[0].id;
            this.fetchRulesCategory(taxId);
            if (this.ruleToBoOpen) {
              this.fetchAllChaptersForRules(this.ruleToBoOpen.cbicRuleMst.id);
              this.fetchRuleName(this.ruleToBoOpen.cbicRuleMst.id);
            } else {
              if (this.rulesListFetched[0]) {


                this.fetchAllChaptersForRules(this.rulesListFetched[0].id);
                this.ruleDocName = this.rulesListFetched[0].ruleDocName;
                this.actContentId = this.rulesListFetched[0].contentId;
                this.actName = this.rulesListFetched[0].ruleDocName;
                this.fullPdfPath = this.rulesListFetched[0].contentFilePath;
                this.fullHtmlPath = this.rulesListFetched[0].contentHtmlFilePath;
                this.enactmentDt = this.rulesListFetched[0].issueDt ? (this.rulesListFetched[0].issueDt).format('DD-MMM-YYYY') : "";
                this.lastUpdatedDt = this.rulesListFetched[0].amendDt ? (this.rulesListFetched[0].amendDt).format('DD-MMM-YYYY') : this.enactmentDt;
                this.childRuleSearch.setRuleId(this.rulesListFetched[0].id);
              }
            }
          }
        });
  }

  fetchRulesData(chapterId: string): void {
    this.cbicRuleSectionMstService
      .findRuleByChapterId(Number(chapterId))
      .subscribe(
        (res: HttpResponse<ICbicRuleSectionMst[]>) => {
          this.rulesDataFetched = res.body;
          if (this.cbicSearchOutputResults.dtos.length > 0) {
            this.filterResultsRules();
          }
          if (!this.ruleToBoOpen) {
            this.ruleToBoOpen = this.rulesDataFetched[0];
          }
          let j = -1;
          for (let i = 0; i < this.rulesDataFetched.length; i++) {
            if (this.rulesDataFetched[i].id === this.ruleToBoOpen.id) {
              j = i;

            }
          }

          if (j !== -1) {
            //this.contentPageService.emitFetchDataEvent.next({ "contentFilePath": this.rulesDataFetched[j].contentFilePath, "contentNo": this.rulesDataFetched[j].sectionNo, "contentName": this.rulesDataFetched[j].sectionName, "index": j, "searchKeyword": this.searchKeyWord, "id": this.rulesDataFetched[j].contentId });
            if (this.storageLanguage === "hi") {
              const contentFilePathValue: string = this.rulesDataFetched[j].contentFilePathHi ? this.rulesDataFetched[j].contentFilePathHi : this.rulesDataFetched[j].contentFilePath;
              const contentNoValue: string = this.rulesDataFetched[j].sectionNoHi ? this.rulesDataFetched[j].sectionNoHi : this.rulesDataFetched[j].sectionNo;
              const contentNameValue: string = this.rulesDataFetched[j].sectionNameHi ? this.rulesDataFetched[j].sectionNameHi : this.rulesDataFetched[j].sectionName;
              this.contentPageService.emitFetchDataEvent.next({ "contentFilePath": contentFilePathValue, "contentNo": contentNoValue, "contentName": contentNameValue, "index": j, "searchKeyword": this.searchKeyWord, "id": this.rulesDataFetched[j].contentId });
            } else {
              this.contentPageService.emitFetchDataEvent.next({ "contentFilePath": this.rulesDataFetched[j].contentFilePath, "contentNo": this.rulesDataFetched[j].sectionNo, "contentName": this.rulesDataFetched[j].sectionName, "index": j, "searchKeyword": this.searchKeyWord, "id": this.rulesDataFetched[j].contentId });
            }
          }
          if (this.ruleChapterListFetched) {


            for (let m = 0; m < this.ruleChapterListFetched.length; m++) {

              if (this.ruleChapterListFetched[m].id === chapterId) {
                this.activeReadingRuleNum = m;
              }
            }
            this.fetchRuleChapterName(this.ruleChapterListFetched[this.activeReadingRuleNum].id);
          }


          if (!this.sectionSelectionRules) {
            if (j === -1 || j === 0) {

              this.ruleToBoOpen = this.rulesDataFetched[0];
              this.contentPageService.closeAllSection.next();
            }
          }



        }

      );

  }

  ruleSelected(ruleId: string): void {
    this.ruleId = ruleId;
    this.isRuleChanged = true;
    this.contentPageService.closeAllSection.next();
    this.query = {};
    this.searchKeyWord = "";
    this.cbicSearchOutputResults.dtos = [];
    this.ruleToBoOpen = undefined;
    this.searchNotFound = false;
    this.fetchAllChaptersForRules(ruleId);
    this.fetchRuleName(ruleId);
  }

  fetchRuleName(ruleId: string): void {
    this.cbicRuleMstService.find(Number(ruleId))
      .subscribe(
        (res: HttpResponse<ICbicRuleMst>) => {
          this.ruleDocName = res.body?.ruleDocName;
          this.actContentId = res.body?.contentId;
          this.actName = this.ruleDocName;
          this.enactmentDt = (res.body?.issueDt)?.format('DD-MMM-YYYY');
          this.fullPdfPath = res.body?.contentFilePath;
          this.fullHtmlPath = res.body?.contentHtmlFilePath;

          this.lastUpdatedDt = res.body?.amendDt !== null ? (res.body?.amendDt)?.format('DD-MMM-YYYY') : this.enactmentDt;
        }
      )
      ;
  }

  fetchRuleDataContent(chapterId: string): void {
    this.sectionSelectionRules = false;
    this.fetchRulesData(chapterId);

  }



  fetchChapterRelatedRules(chapterId: string): void {
    // this.searchNotFound = false;
    if (chapterId !== "") {
      this.cbicRuleSectionMstService
        .findRuleByChapterId(Number(chapterId))
        .subscribe(
          (res: HttpResponse<ICbicRuleSectionMst[]>) => {
            this.rulesDataFetched = res.body; // sectionListFetched
            this.ruleToBoOpen = this.rulesDataFetched[0];
            this.allRulesSection = this.rulesDataFetched;
            // this.allSectionList=this.sectionDataFetched;
            let j = 0;
            for (let i = 0; i < this.ruleChapterListFetched.length; i++) {
              if (this.ruleChapterListFetched[i].id === Number(chapterId)) {
                j = i;
              }
            }

            //this.contentPageService.emitFetchDataEvent.next({ "contentFilePath": this.ruleToBoOpen.contentFilePath, "contentNo": this.ruleToBoOpen.sectionNo, "contentName": this.ruleToBoOpen.sectionName, "index": 0, "searchKeyword": this.searchKeyWord, "contentTypeTobeFetched": this.selectedTab, "id": this.ruleToBoOpen.contentId });
            if (this.storageLanguage === "hi") {
              const contentFilePathValue: string = this.ruleToBoOpen.contentFilePathHi ? this.ruleToBoOpen.contentFilePathHi : this.ruleToBoOpen.contentFilePath;
              const contentNoValue: string = this.ruleToBoOpen.sectionNoHi ? this.ruleToBoOpen.sectionNoHi : this.ruleToBoOpen.sectionNo;
              const contentNameValue: string = this.ruleToBoOpen.sectionNameHi ? this.ruleToBoOpen.sectionNameHi : this.ruleToBoOpen.sectionName;
              this.contentPageService.emitFetchDataEvent.next({ "contentFilePath": contentFilePathValue, "contentNo": contentNoValue, "contentName": contentNameValue, "index": 0, "searchKeyword": this.searchKeyWord, "contentTypeTobeFetched": this.selectedTab, "id": this.ruleToBoOpen.contentId });
            } else {
              this.contentPageService.emitFetchDataEvent.next({ "contentFilePath": this.ruleToBoOpen.contentFilePath, "contentNo": this.ruleToBoOpen.sectionNo, "contentName": this.ruleToBoOpen.sectionName, "index": 0, "searchKeyword": this.searchKeyWord, "contentTypeTobeFetched": this.selectedTab, "id": this.ruleToBoOpen.contentId });
            }
            this.activeReadingRuleNum = j + 0;

            this.selectChapterDtlsForRules({ selectChapterNo: this.ruleChapterListFetched[j].chapterNo, selectChapterName: this.ruleChapterListFetched[j].chapterName });

          });
    } else {
      this.fetchAllSectionForRules(this.ruleId);
    }

  }

  selectChapterDtlsForRules(data: { selectChapterNo: any, selectChapterName: any }): void {
    this.selectedChapterNoRules = data.selectChapterNo;
    this.selectedChapterNameRules = data.selectChapterName;


  }

  fetchRuleSectionSelection(data: { sectionId: string, index: number }): void {
    this.sectionSelectionRules = true;
    this.openRuleSection(data.sectionId);
  }

  openRuleSection(sectionId: string): void {
    this.cbicRuleSectionMstService.find(Number(sectionId))
      .subscribe(
        (res: HttpResponse<ICbicRuleSectionMst>) => {
          this.ruleToBoOpen = res.body;

          if (this.rulesViewBy === "Rules") {
            this.fetchRuleSectionForSelectedRule(sectionId, this.ruleToBoOpen.cbicRuleChapterMst.id)
          } else if (this.rulesViewBy === "Chapter") {
            this.fetchRulesData(this.ruleToBoOpen.cbicRuleChapterMst.id);
          }
        });
  }

  fetchAllSectionForRules(ruleId: string): void {

    this.cbicRuleSectionMstService
      .findSectionByRuleId(Number(ruleId))
      .subscribe(
        (res: HttpResponse<ICbicRuleSectionMst[]>) => {
          this.allRulesSection = res.body;
          this.totalSectionForRules = this.allRulesSection.length;

          if (this.searchKeyWord) {
            this.searchForRules(JSON.stringify({ "keyword": this.searchKeyWord }));
          }
        }
      );
    this.rulesViewBy = "Rules";
    this.viewBySectionAllRules(ruleId);

  }

  selectRuleSectionDtls(data: { selectSectionId: any, selectSectionNo: any, selectSectionName: any, selectChapterId: any }): void {

    this.fetchRuleSectionForSelectedRule(data.selectSectionId, data.selectChapterId);
  }



  fetchRuleSectionForSelectedRule(sectionId: string, id: string): void {
    this.cbicRuleSectionMstService
      .fetchRuleSectionForSelectedRule(Number(sectionId))
      .subscribe(
        (res: HttpResponse<ICbicRuleSectionMst[]>) => {
          this.viewSelectedRuleSection = res.body;

          for (let m = 0; m < this.viewBySectionAllRuleList.length; m++) {
            if (Number(this.viewBySectionAllRuleList[m].id) === Number(sectionId)) {
              this.activeReadingRuleNum = m;

            }

          }
          this.contentPageService.closeAllSection.next();
          //this.contentPageService.emitFetchDataEvent.next({ "contentFilePath": this.viewSelectedRuleSection[0].contentFilePath, "contentNo": this.viewSelectedRuleSection[0].sectionNo, "contentName": this.viewSelectedRuleSection[0].sectionName, "index": 0, "searchKeyword": this.searchKeyWord, "contentTypeTobeFetched": this.selectedTab, "id": this.viewSelectedRuleSection[0].contentId });
          if (this.storageLanguage === "hi") {
            const contentFilePathValue: string = this.viewSelectedRuleSection[0].contentFilePathHi ? this.viewSelectedRuleSection[0].contentFilePathHi : this.viewSelectedRuleSection[0].contentFilePath;
            const contentNoValue: string = this.viewSelectedRuleSection[0].sectionNoHi ? this.viewSelectedRuleSection[0].sectionNoHi : this.viewSelectedRuleSection[0].sectionNo;
            const contentNameValue: string = this.viewSelectedRuleSection[0].sectionNameHi ? this.viewSelectedRuleSection[0].sectionNameHi : this.viewSelectedRuleSection[0].sectionName;
            this.contentPageService.emitFetchDataEvent.next({ "contentFilePath": contentFilePathValue, "contentNo": contentNoValue, "contentName": contentNameValue, "index": 0, "searchKeyword": this.searchKeyWord, "contentTypeTobeFetched": this.selectedTab, "id": this.viewSelectedRuleSection[0].contentId });
          } else {
            this.contentPageService.emitFetchDataEvent.next({ "contentFilePath": this.viewSelectedRuleSection[0].contentFilePath, "contentNo": this.viewSelectedRuleSection[0].sectionNo, "contentName": this.viewSelectedRuleSection[0].sectionName, "index": 0, "searchKeyword": this.searchKeyWord, "contentTypeTobeFetched": this.selectedTab, "id": this.viewSelectedRuleSection[0].contentId });
          }
          this.fetchRuleChapterName(this.viewSelectedRuleSection[0].cbicRuleChapterMst.id);
          if (this.searchKeyWord) {
            this.ruleToBoOpen = this.viewBySectionAllRuleList[this.activeReadingRuleNum]
            this.searchForRules(JSON.stringify({ "keyword": this.searchKeyWord }));
          }
        }
      );

  }

  viewBySectionAllRules(ruleId: string): void {
    this.cbicRuleSectionMstService
      .viewBySectionAllRules(Number(ruleId))
      .subscribe(
        (res: HttpResponse<ICbicRuleSectionMst[]>) => {
          this.viewBySectionAllRuleList = res.body;
          this.fetchRuleChapterName(this.viewBySectionAllRuleList[0].cbicRuleChapterMst.id);
          if (this.rulesViewBy === 'Rules') {
            if (this.ruleToBoOpen) {
              this.fetchRuleSectionForSelectedRule(this.ruleToBoOpen.id, this.ruleToBoOpen.cbicRuleChapterMst.id);
            } else {
              this.fetchRuleSectionForSelectedRule(this.viewBySectionAllRuleList[0].id, this.viewBySectionAllRuleList[0].cbicRuleChapterMst.id);
            }

            // this.isRuleChanged = false;
          }
          this.totalSectionForRules = this.viewBySectionAllRuleList.length;

          if (this.ruleChapterListFetched.length >= 0) {

            this.rulesViewBy = "Rules";
            this.contentPageService.changeViewBy.next("Rules");
          } else {
            this.rulesViewBy = "Chapter";
            this.contentPageService.changeViewBy.next("Chapter");
          }
          if (this.searchKeyWord) {
            this.searchForRules(JSON.stringify({ "keyword": this.searchKeyWord }));
          }
        }
      );
  }

  fetchRuleChapterName(id: string): void {
    this.cbicRuleChapterMstService
      .getChapterNameForRule(Number(id))
      .subscribe(
        (res: HttpResponse<ICbicRuleChapterMst[]>) => {
          this.getChapterNameForRule = res.body;
          this.selectedChapterNoRules = this.getChapterNameForRule[0].chapterNo;
          this.selectedChapterNameRules = this.getChapterNameForRule[0].chapterName;
          /* if (this.searchKeyWord) {
            this.searchForRules(JSON.stringify({ "keyword": this.searchKeyWord }));
          } */
        }
      );
    this.spinnerService.requestEnded();
  }




  searchForRules(searchQueryJson: string): void {

    this.query = JSON.parse(searchQueryJson);
    this.query.actId = this.ruleId;
    this.query.rows = 100;
    if (!this.query.content) {
      this.query.content = "RULES";
    }
    this.service.searchInSection(this.query).subscribe(
      (res: HttpResponse<CbicSearchOutputResult>) => {
        this.searchNotFound = false;
        this.cbicSearchOutputResults = res.body ?? new CbicSearchOutputResult([], null);
        if (this.cbicSearchOutputResults.dtos.length === 0) {
          this.searchNotFound = true;
        }
        this.filterResultsRules();
      });

  }
  filterResultsRules(): void {
    this.ruleChapterListFetched = this.ruleChapterListFetched.filter(((f: ICbicRuleChapterMst) => this.cbicSearchOutputResults.dtos.some((item: CbicSearchOutput) => item.chapterId?.includes(String(f.id)))));
    this.rulesDataFetched = this.rulesDataFetched.filter(((f: ICbicRuleSectionMst) => this.cbicSearchOutputResults.dtos.some((item: CbicSearchOutput) => item.sectionId?.includes(String(f.id)))));
    this.totalChapterForRules = this.ruleChapterListFetched.length;
    if (this.rulesViewBy === "Rules") {
      this.viewBySectionAllRuleList = this.viewBySectionAllRuleList.filter(((f: ICbicRuleSectionMst) => this.cbicSearchOutputResults.dtos.some((item: CbicSearchOutput) => item.sectionId?.includes(String(f.id)))));
      this.totalSectionForRules = this.viewBySectionAllRuleList.length;
    }

    let j = 0;
    if (this.rulesViewBy === "Chapter") {
      if (this.ruleToBoOpen) {
        for (let i = 0; i < this.rulesDataFetched.length; i++) {
          if (this.rulesDataFetched[i].id === this.ruleToBoOpen.id) {
            j = i;

          }
        }
      }
      this.ruleToBoOpen = this.rulesDataFetched[j];
    }

    if (this.rulesViewBy === "Rules") {
      if (this.ruleToBoOpen) {
        for (let i = 0; i < this.viewBySectionAllRuleList.length; i++) {
          if (this.viewBySectionAllRuleList[i].id === this.ruleToBoOpen.id) {
            j = i;

          }
        }
      }
      this.ruleToBoOpen = this.viewBySectionAllRuleList[j];
    }

    if (this.query.keyword) {

      this.searchKeyWord = this.query.keyword;
    }

    if (this.ruleToBoOpen) {
      /* this.contentPageService.emitFetchDataEvent.next({ "contentFilePath": this.ruleToBoOpen.contentFilePath, "contentNo": this.ruleToBoOpen.sectionNo, "contentName": this.ruleToBoOpen.sectionName, "index": j, "searchKeyword": this.searchKeyWord, "contentTypeTobeFetched": this.selectedTab, "id": this.ruleToBoOpen.contentId }); */

      if (this.storageLanguage === "hi") {
        const contentFilePathValue: string = this.ruleToBoOpen.contentFilePathHi ? this.ruleToBoOpen.contentFilePathHi : this.ruleToBoOpen.contentFilePath;
        const contentNoValue: string = this.ruleToBoOpen.sectionNoHi ? this.ruleToBoOpen.sectionNoHi : this.ruleToBoOpen.sectionNo;
        const contentNameValue: string = this.ruleToBoOpen.sectionNameHi ? this.ruleToBoOpen.sectionNameHi : this.ruleToBoOpen.sectionName;
        this.contentPageService.emitFetchDataEvent.next({ "contentFilePath": contentFilePathValue, "contentNo": contentNoValue, "contentName": contentNameValue, "index": j, "searchKeyword": this.searchKeyWord, "contentTypeTobeFetched": this.selectedTab, "id": this.ruleToBoOpen.contentId });
      } else {
        this.contentPageService.emitFetchDataEvent.next({ "contentFilePath": this.ruleToBoOpen.contentFilePath, "contentNo": this.ruleToBoOpen.sectionNo, "contentName": this.ruleToBoOpen.sectionName, "index": j, "searchKeyword": this.searchKeyWord, "contentTypeTobeFetched": this.selectedTab, "id": this.ruleToBoOpen.contentId });
      }


    }
  }

  clearSearchBarRules(): void {
    this.query = {};
    this.searchKeyWord = "";
    this.cbicSearchOutputResults.dtos = [];
    this.fetchAllChaptersForRules(this.ruleToBoOpen.cbicRuleMst.id);
  }

  fetchRulesCategory(taxId: string): void {
    this.cbicRuleMstService
      .fetchRulesCategory(Number(taxId))
      .subscribe(
        (res: HttpResponse<ICbicRuleMst[]>) => {
          this.rulesCategoryListFetched = res.body;

        });
  }
  fetchCategoryRelatedRules(ruleCategory: string): void {

    if (ruleCategory === "All" || ruleCategory === "सभी") {
      this.fetchAllRules(this.taxId);
      this.ruleSelected(this.firstRuleId);
    } else {
      this.cbicRuleMstService
        .fetchCategoryRelatedRules(this.taxId, ruleCategory)
        .subscribe(
          (res: HttpResponse<ICbicRuleMst[]>) => {
            this.rulesListFetched = res.body;
            for (let i = 0; i < this.rulesListFetched.length; i++) {
              if (this.storageLanguage === "hi" && this.rulesListFetched[i].ruleDocNameHi) {


                let maxlen = this.rulesListFetched[i].ruleDocNameHi.length;

                if (maxlen > 70) {
                  maxlen = 67;
                }
                this.rulesListFetched[i].ruleDocNameLable = String(this.rulesListFetched[i].ruleDocNameHi.substring(0, maxlen));
                if (maxlen === 67) {
                  this.rulesListFetched[i].ruleDocNameLable = String(this.rulesListFetched[i].ruleDocNameHi.substring(0, maxlen)) + "...";
                }
              }
              else {
                let maxlen = this.rulesListFetched[i].ruleDocName.length;
                if (maxlen > 70) {
                  maxlen = 67;
                }
                this.rulesListFetched[i].ruleDocNameLable = String(this.rulesListFetched[i].ruleDocName.substring(0, maxlen));
                if (maxlen === 67) {
                  this.rulesListFetched[i].ruleDocNameLable = String(this.rulesListFetched[i].ruleDocName.substring(0, maxlen)) + "...";
                }
              }
              this.ruleSelected(this.rulesListFetched[0].id);
              this.childRuleSearch.setRuleId(this.rulesListFetched[0].id);
            }
          });
    }
  }

  /**Rules tab related functions ends*/




  /**Forms related function */


  fetchFormsforGivenCategory(formCategory: string): void {
    this.cbicFormMstService
      .findFormByFormCategory(Number(this.taxId), String(formCategory))
      .subscribe(
        (res: HttpResponse<ICbicFormMst[]>) => {
          this.formByCategoryFetched = res.body;
          if (this.cbicSearchOutputResults.dtos.length > 0) {
            this.formByCategoryFetched = this.formByCategoryFetched.filter(((f: ICbicFormMst) => this.cbicSearchOutputResults.dtos.some((item: CbicSearchOutput) => item.sectionId?.includes(String(f.id)))));
          }
          this.formRead.setFormName(formCategory);
        }
      );
  }

  fetchFormsDataContent(formCategory: string): void {


    this.fetchFormsData(this.taxId, formCategory);

    this.formRead.setFormName(formCategory);
    this.actName = formCategory;
  }

  selectFormDtls(data: { selectformId: any, selectformCategory: any }): void {
    return;
  }

  fetchFormsData(taxId: number, formCategory: string): void {
    this.cbicFormMstService
      .findFormByFormCategory(Number(taxId), String(formCategory))
      .subscribe(
        (res: HttpResponse<ICbicFormMst[]>) => {
          this.formByCategoryFetched = res.body;
          if (this.cbicSearchOutputResults.dtos.length > 0) {
            this.filterResultsForms();
          }
          if (this.searchKeyWord) {
            this.searchAndFilterForms();
          }
        }
      );
  }

  loadForms(): void {
    this.query = {};
    this.searchKeyWord = "";
    this.cbicSearchOutputResults.dtos = [];
    this.searchNotFound = false;
    this.selectedTab = 'Forms';

    if (this.activeTab !== 'Forms') {
      this.activeTab = 'Forms';
      if (this.storageLanguage === "hi") {
        this.tabName = "प्रपत्र"
      } else {
        this.tabName = this.activeTab
      }
      this.sessionStorage.store('activeTab', this.activeTab);
      if (!this.formsLoaded) {
        this.fetchTaxName(this.taxId);
        this.fetchAllForms(this.taxId);
        this.fetchAllFormsCategory(this.taxId);
        this.formsLoaded = true;
      } else {
        this.fetchAllFormsCategory(this.taxId);

      }
    }


  }

  fetchAllFormsCategory(taxId: string): void {
    this.cbicFormMstService
      .findFormsCategory(Number(taxId))
      .subscribe(
        (res: HttpResponse<CbicFormMstCategory[]>) => {
          this.formsCategoryListFetched = res.body;
          if (this.formToOpen) {
            this.fetchFormsDataContent(this.formToOpen.formCategory);
          } else {
            this.fetchFormsDataContent(this.formsCategoryListFetched[0].formCategory);
            this.formNavigate.setCurrentCategory(this.formsCategoryListFetched[0].formCategory)
          }


        });
  }


  fetchAllForms(taxId: string): void {
    this.cbicFormMstService
      .findFormsList(Number(taxId))
      .subscribe(
        (res: HttpResponse<ICbicFormMst[]>) => {
          this.formsListFetched = res.body;

          if (this.formToOpen) {
            this.actName = this.formToOpen.formCategory;
          } else {
            // let dummyFormListFetch = this.formsListFetched;
            // dummyFormListFetch.sort((a:ICbicFormMst,b:ICbicFormMst) => (a.formCategory? > b.formCategory?) ? 1 : ((b.last_nom > a.last_nom) ? -1 : 0))
            // this.actName = this.formsListFetched[0].formCategory;
          }
          this.formNavigate.setCurrentCategory(this.actName)

        });
  }

  searchForm(searchQueryJson: string): void {
    this.query = JSON.parse(searchQueryJson);
    if (this.taxId) {
      this.query.taxId = this.taxId;
    } else {
      this.query.taxId = this.formToOpen.tax.id;
    }
    this.query.rows = 100;
    if (!this.query.content) {
      this.query.content = "FORMS";
    }


    this.service.searchInSection(this.query).subscribe(
      (res: HttpResponse<CbicSearchOutputResult>) => {
        this.searchNotFound = false;
        this.cbicSearchOutputResults = res.body ?? new CbicSearchOutputResult([], null);
        if (this.cbicSearchOutputResults.dtos.length === 0) {
          this.searchNotFound = true;
        }
        this.filterResultsForms();
        //this.fetchFormsDataContent(this.formsCategoryListFetched[0].formCategory);
      });

  }
  filterResultsForms(): void {
    this.formsListFetched = this.formsListFetched.filter(((f: ICbicFormMst) => this.cbicSearchOutputResults.dtos.some((item: CbicSearchOutput) => item.sectionId?.includes(String(f.id)))));



    this.formsCategoryListFetched = this.formsCategoryListFetched.filter(((f: ICbicFormMst) => this.cbicSearchOutputResults.dtos.some((item: CbicSearchOutput) => item.formCategory?.includes(String(f.formCategory)))))
    if (this.formsCategoryListFetched.length > 0) {
      if (this.formToOpen) {
        this.fetchFormsDataOnFilter(this.taxId, this.formToOpen.formCategory);
        this.formRead.setFormName(this.formToOpen.formCategory);
        this.formNavigate.setCurrentCategory(this.formToOpen.formCategory);
      } else {
        this.fetchFormsDataOnFilter(this.taxId, this.formsCategoryListFetched[0].formCategory);
        this.formRead.setFormName(this.formsCategoryListFetched[0].formCategory);
        this.formNavigate.setCurrentCategory(this.formsCategoryListFetched[0].formCategory);
      }
      const countObj: { [category: string]: any } = {};
      this.cbicSearchOutputResults.dtos.forEach(
        (f: CbicSearchOutput) => {
          if (!countObj[String(f.formCategory)]) {
            countObj[String(f.formCategory)] = 1;
          } else if (countObj[String(f.formCategory)]) {
            countObj[String(f.formCategory)] += 1;
          }
        }
      );
      this.formsCategoryListFetched.forEach(
        (ele: CbicFormMstCategory) => {
          ele.count = countObj[String(ele.formCategory)]
        }
      );
      this.formSearch.searched = true;
      this.formSearch.searchKeyword = this.searchKeyWord;

    }
  }


  searchAndFilterForms(): void {
    this.searchForm((JSON.stringify({ "keyword": this.searchKeyWord })));
  }

  clearForm(): void {
    this.query = {};
    this.searchKeyWord = "";
    this.cbicSearchOutputResults.dtos = [];
    this.searchNotFound = false;
    this.fetchTaxName(this.taxId);
    this.fetchAllForms(this.taxId);
    this.fetchAllFormsCategory(this.taxId);
  }

  formSelectFire(data: { formId: string, valuefind: boolean }): void {
    // alert(data.formId)
    if (data.valuefind) {
      this.cbicFormMstService.find(Number(data.formId)).subscribe(
        (res: HttpResponse<CbicFormMst>) => {
          if (res.body) {
            this.formSelect = res.body;
            this.fetchFormsBySpecificCategory(Number(this.formSelect.tax?.id), String(this.formSelect.formCategory))
          }
        }
      )
    }
  }

  filterSpecificForm(): void {
    this.formsListFetched = this.formsListFetched.filter(((f: ICbicFormMst) => f.id === this.formSelect?.id));
    this.formsCategoryListFetched = this.formsCategoryListFetched.filter(((f: ICbicFormMst) => f.formCategory === this.formSelect?.formCategory))
    this.formByCategoryFetched = this.formByCategoryFetched.filter(((f: ICbicFormMst) => f.id === this.formSelect?.id));
    this.formsCategoryListFetched.forEach(
      (ele: CbicFormMstCategory) => {
        ele.count = 1
      }
    );
    this.formRead.setFormName(this.formByCategoryFetched[0].formCategory);
    this.formNavigate.setCurrentCategory(this.formByCategoryFetched[0].formCategory);
  }

  fetchFormsBySpecificCategory(taxId: number, formCategory: string): void {
    this.cbicFormMstService
      .findFormByFormCategory(Number(taxId), String(formCategory))
      .subscribe(
        (res: HttpResponse<ICbicFormMst[]>) => {
          this.formByCategoryFetched = res.body;
          this.filterSpecificForm();
        }
      );

  }

  fetchFormsDataOnFilter(taxId: number, formCategory: string): void {
    this.cbicFormMstService
      .findFormByFormCategory(Number(taxId), String(formCategory))
      .subscribe(
        (res: HttpResponse<ICbicFormMst[]>) => {
          this.formByCategoryFetched = res.body;
          this.formByCategoryFetched = this.formByCategoryFetched.filter(((f: ICbicFormMst) => this.cbicSearchOutputResults.dtos.some((item: CbicSearchOutput) => item.sectionId?.includes(String(f.id)))));
        }
      );
  }

  /**Forms related function ends */




  /*show and hide side bar functions */
  expandReadArea(sidebar: HTMLElement, readingArea: HTMLElement, expandChViewBtn: HTMLElement): void {

    readingArea.classList.remove("col-lg-9");
    readingArea.className += " " + "col-lg-12";

    sidebar.style.display = 'none';


    expandChViewBtn.style.display = 'block';
  }




  expandChViewArea(sidebar: HTMLElement, readingArea: HTMLElement, expandChViewBtn: HTMLElement): void {
    readingArea.classList.remove("col-lg-12");
    readingArea.className += " " + "col-lg-9";

    sidebar.style.display = 'block';


    expandChViewBtn.style.display = 'none';
  }
  /*show and hide side bar functions ends*/

  ngOnInit(): void {
    hideLanguageSwitch();
    this.storageLanguage = this.sessionStorage.retrieve('locale')
    this.localStorage.store('language', this.storageLanguage);
    this.translateService.use(this.storageLanguage);

    if (history.state.menuInput) {
      this.taxId = history.state.menuInput.taxId;
      this.activeTab = history.state.menuInput.menuType;
      this.sessionStorage.store('taxId', this.taxId);
      this.sessionStorage.store('activeTab', this.activeTab);
      this.taxIdRuleCategory = this.taxId;
    }
    this.activatedRoute.paramMap.subscribe(
      res => {
        this.routeKeyword = res.get('keyword');
        this.routeContentType = res.get('documentType')
        this.formIdLink = res.get('formId')

      });
    if (this.routeKeyword) {
      this.searchKeyWord = this.routeKeyword;
      this.contentType = this.routeContentType;
    }
    // if (history.state.keyword) {

    //   this.searchKeyWord = history.state.keyword;
    //   this.contentType = history.state.contentType;
    //   //this.search(JSON.stringify({"keyword":this.searchKeyWord}));
    // }




    if (this.searchKeyWord !== '') {
      if (this.contentType === 'ACTS') {
        this.activatedRoute.data.subscribe(({ cbicactsectionmst }) => {
          this.sectionToBeOpened = cbicactsectionmst;
          this.selectedTab = "Acts";
          if (this.storageLanguage === "hi") {
            this.tabName = "अधिनियम"
          } else {
            this.tabName = this.activeTab
          }
          this.fetchTaxName(this.sectionToBeOpened.taxId.id);
          if (this.sectionToBeOpened.taxId.id === 1000004) {
            this.fetchServiceTaxData('Acts');
          } else {
            this.fetchAllActs(this.sectionToBeOpened.taxId.id);
            this.fetchSectionData(this.sectionToBeOpened.chapterId.id);
            if (this.sectionToBeOpened.actId) {
              this.searchActId = this.sectionToBeOpened.actId.id;
            }
          }


          this.taxId = this.sectionToBeOpened.taxId.id;
        });
      } else if (this.contentType === 'RULES') {
        this.activatedRoute.data.subscribe(({ cbicrulesectionmst }) => {
          this.ruleToBoOpen = cbicrulesectionmst;
          this.selectedTab = "Rules";
          if (this.storageLanguage === "hi") {
            this.tabName = "नियम"
          } else {
            this.tabName = this.activeTab
          }
          if (this.ruleToBoOpen.cbicTaxMst.id === 1000004) {
            this.fetchTaxName(this.ruleToBoOpen.cbicTaxMst.id);
            this.fetchAllServiceTaxRuleList(this.ruleToBoOpen.cbicTaxMst.id);
            this.taxId = this.ruleToBoOpen.cbicTaxMst.id;
          } else {
            this.fetchTaxName(this.ruleToBoOpen.cbicTaxMst.id);
            this.fetchAllRules(this.ruleToBoOpen.cbicTaxMst.id);
            this.fetchRulesData(this.ruleToBoOpen.cbicRuleChapterMst.id);
            if (this.ruleToBoOpen.cbicRuleMst) {
              this.searchRuleId = this.ruleToBoOpen.cbicRuleMst.id;
            }
            this.taxIdRuleCategory = this.ruleToBoOpen.cbicTaxMst.id;
            this.taxId = this.ruleToBoOpen.cbicTaxMst.id;

          }
        });
      } else if (this.contentType === 'REGULATIONS') {
        this.activeTab = 'Regulations';
        if (this.storageLanguage === "hi") {
          this.tabName = "विनियमन"
        } else {
          this.tabName = this.activeTab
        }
        this.activatedRoute.data.subscribe(({ cbicregulationmst }) => {
          this.regualtionToBoOpen = cbicregulationmst;

          this.fetchTaxName(this.regualtionToBoOpen.tax.id);
          this.fetchAllRegulationDoc(this.regualtionToBoOpen.tax.id);

          if (this.regualtionToBoOpen.regulationDoc) {
            this.searchRuleId = this.regualtionToBoOpen.regulationDoc.id;
          }
          this.taxIdRuleCategory = this.regualtionToBoOpen.tax.id;
          this.taxId = this.regualtionToBoOpen.tax.id;
        });
      } else if (this.contentType === 'FORMS') {
        this.activeTab = 'Forms';
        if (this.storageLanguage === "hi") {
          this.tabName = "प्रपत्र"
        } else {
          this.tabName = this.activeTab
        }
        this.activatedRoute.data.subscribe(({ cbicformmst }) => {
          this.formToOpen = cbicformmst;
          this.fetchTaxName(this.formToOpen.tax.id);
          this.fetchAllForms(this.formToOpen.tax.id);
          this.fetchAllFormsCategory(this.formToOpen.tax.id);
          this.taxId = this.formToOpen.tax.id;
        });
      } else if (this.contentType === 'NOTIFICATIONS') {
        this.activeTab = 'Notifications';
        if (this.storageLanguage === "hi") {
          this.tabName = "सूचनाएं"
        } else {
          this.tabName = this.activeTab
        }
        this.activatedRoute.data.subscribe(({ cbicNotificationMst }) => {
          this.notificationToBeOpened = cbicNotificationMst;
          this.taxId = this.notificationToBeOpened.tax.id;
          this.loadNotificationsSearch();
        });
      } else if (this.contentType === 'ALLIEDACTS') {
        this.activeTab = 'Allied Acts';
        this.selectedTab = 'Allied Acts';
        if (this.storageLanguage === "hi") {
          this.tabName = "संबद्ध अधिनियम"
        } else {
          this.tabName = this.activeTab
        }

        this.activatedRoute.data.subscribe(({ cbicAlliedActDtls }) => {
          this.alliedActToBeOpened = cbicAlliedActDtls;
          this.taxId = this.alliedActToBeOpened.tax.id;
          this.fetchAlliedActNameSearch(this.alliedActToBeOpened.cbicAlliedMst.id);
          this.loadAlliedActSearch();

        });

      } else if (this.contentType === 'CIRCULARS') {
        this.activeTab = 'Circulars';
        if (this.storageLanguage === "hi") {
          this.tabName = "परिपत्र"
        } else {
          this.tabName = this.activeTab
        }
        this.activatedRoute.data.subscribe(({ cbicCircularMst }) => {
          this.circularToBeOpened = cbicCircularMst;
          this.fetchAllCircularListFromSearch(this.circularToBeOpened.tax.id);
          this.taxId = this.circularToBeOpened.tax.id;
        });
      } else if (this.contentType === 'INSTRUCTIONS') {
        this.activeTab = 'Instructions';
        if (this.storageLanguage === "hi") {
          this.tabName = "निर्देश / दिशानिर्देश"
        } else {
          this.tabName = this.activeTab
        }
        this.activatedRoute.data.subscribe(({ cbicInstructionMst }) => {
          this.instructionToBeOpened = cbicInstructionMst;
          this.taxId = this.instructionToBeOpened.tax.id;
          this.fetchAllInstructionsSearch(this.taxId);
        });
      } else if (this.contentType === 'ORDERS') {
        this.activeTab = 'Orders';
        if (this.storageLanguage === "hi") {
          this.tabName = "आदेश"
        } else {
          this.tabName = this.activeTab
        }
        this.activatedRoute.data.subscribe(({ cbicOrderMst }) => {
          this.orderToBeOpened = cbicOrderMst;
          this.taxId = this.orderToBeOpened.tax.id;
          this.fetchAllOrdersSearch(this.taxId);
        });
      }
    } else {

      if (this.activeTab === 'Acts') {
        this.selectedTab = "Acts";
        if (this.storageLanguage === "hi") {
          this.tabName = "अधिनियम"
        } else {
          this.tabName = this.activeTab
        }
        this.fetchTaxName(this.taxId);
        if (this.taxId !== 1000004) {


          this.fetchAllActs(this.taxId);//11 is id for tax type customs
        } else {
          this.fetchServiceTaxData('Acts');
        }

      } else if (this.activeTab === 'Rules') {
        this.selectedTab = "Rules";
        if (this.storageLanguage === "hi") {
          this.tabName = "नियम"
        } else {
          this.tabName = this.activeTab
        }
        this.fetchTaxName(this.taxId);
        //this.fetchAllRules(this.taxId);
        if (this.taxId !== 1000004) {
          this.fetchAllRules(this.taxId);//11 is id for tax type customs
        } else {
          this.fetchAllServiceTaxRuleList(this.taxId);
        }

      }
      else if (this.activeTab === 'Regulations') {
        this.selectedTab = "Regulations";
        if (this.storageLanguage === "hi") {
          this.tabName = "विनियमन"
        } else {
          this.tabName = this.activeTab
        }
        this.fetchTaxName(this.taxId);
        this.fetchAllRegulationDoc(this.taxId);

      } else if (this.activeTab === 'Forms') {
        this.fetchTaxName(this.taxId);
        if (this.storageLanguage === "hi") {
          this.tabName = "प्रपत्र"
        } else {
          this.tabName = this.activeTab
        }

        if (this.formIdLink !== null) {
          this.fetchSelectedForm(this.formIdLink)
          this.formIdLink = null;
        } else {
          this.fetchAllForms(this.taxId);
          this.fetchAllFormsCategory(this.taxId);
        }

      } else if (this.activeTab === 'Notifications') {
        if (this.storageLanguage === "hi") {
          this.tabName = "सूचनाएं"
        } else {
          this.tabName = this.activeTab
        }
        this.loadNotificationsCategory()
      } else if (this.activeTab === 'Circulars') {
        if (this.storageLanguage === "hi") {
          this.tabName = "परिपत्र"
        } else {
          this.tabName = this.activeTab
        }
        this.fetchAllCircularList(this.taxId)
      } else if (this.activeTab === 'Instructions') {
        if (this.storageLanguage === "hi") {
          this.tabName = "निर्देश / दिशानिर्देश"
        } else {
          this.tabName = this.activeTab
        }
        this.fetchAllInstructions(this.taxId);
      } else if (this.activeTab === 'Orders') {
        if (this.storageLanguage === "hi") {
          this.tabName = "आदेश"
        } else {
          this.tabName = this.activeTab
        }
        this.fetchAllOrders(this.taxId);
      } else if (this.activeTab === 'Allied Acts') {
        if (this.storageLanguage === "hi") {
          this.tabName = "संबद्ध अधिनियम"
        } else {
          this.tabName = this.activeTab
        }
        this.loadAlliedActName()

      }
    }





    this.profileService.getProfileInfo().subscribe(profileInfo => {
      this.inProduction = profileInfo.inProduction;
      this.openAPIEnabled = profileInfo.openAPIEnabled;
      this.hostAddress = profileInfo.hostAddress;
    });



  }

  fetchServiceTaxData(dataTypeTobeFetched: string): void {
    /*this.serviceTaxDataList=[];
   
    this.cbicServiceTaxMstService
        .findBydataType(dataTypeTobeFetched)
        .subscribe(
          (res: HttpResponse<ICbicServiceTaxMst[]>) => {
            this.serviceTaxDataList = res.body ?? [];
          }
        );
      return;
    */
    this.cbicActMstService
      .findActList(Number(this.taxId))
      .subscribe(
        (res: HttpResponse<ICbicActMst[]>) => {
          this.serviceTaxDataList = [];
          const serviceTaxActList = res.body;
          if (serviceTaxActList) {
            for (let i = 0; i < serviceTaxActList.length; i++) {
              const serviceTaxData = { 'contentName': serviceTaxActList[i].actName, 'id': serviceTaxActList[i].id, 'dataType': 'Acts' };
              this.serviceTaxDataList.push(serviceTaxData);
            }
          }

        });

  }

  fetchAllServiceTaxRuleList(taxId: string): void {
    this.cbicRuleMstService
      .findRulesList(Number(taxId))
      .subscribe(
        (res: HttpResponse<ICbicRuleMst[]>) => {
          this.serviceTaxRuleList = res.body
          if (this.searchKeyWord) {
            this.searchForSTRules(JSON.stringify({ "keyword": this.searchKeyWord }));
          }
        }
      )
  }

  fetchSelectedSTRule(data: { sectionId: string, index: number }): void {
    this.cbicRuleMstService
      .fetchSelectedRulesByTaxId(this.taxId, data.sectionId)
      .subscribe(
        (res: HttpResponse<ICbicRuleMst[]>) => {
          this.serviceTaxRuleList = res.body;
        }
      )
  }

  fetchSelectedForm(formId: any): void {
    this.cbicFormMstService
      .findFormsById(formId)
      .subscribe(
        (res: HttpResponse<ICbicFormMst[]>) => {
          this.formByCategoryFetched = res.body
          this.formsListFetched = res.body
        }
      )

    this.cbicFormMstService
      .findFormsCategoryById(formId)
      .subscribe(
        (res: HttpResponse<ICbicFormMst[]>) => {
          this.formsCategoryListFetched = res.body
          this.formNavigate.setCurrentCategory(this.formsCategoryListFetched[0].formCategory)
        }
      )
    // this.formRead.openForm(formId);
    this.cbicFormMstService.downloadForm(Number(formId)).subscribe(
      (res: FormResponse) => {
        const data = res.data;
        if (String(res.fileName).indexOf(".pdf") === -1) {
          alert("Preview not available, Please Download")
        } else {
          this.dataUtils.openFile(String(data), "application/pdf");
        }
      }
    );
  }
  clearSearchBarSTRules(): void {
    this.query = {};
    this.searchKeyWord = "";
    this.cbicSearchOutputResults.dtos = [];
    this.fetchAllServiceTaxRuleList(this.taxId);
  }

  searchForSTRules(searchQueryJson: string): void {
    this.query = JSON.parse(searchQueryJson);
    this.query.rows = 100;
    if (!this.query.content) {
      this.query.content = "RULES";
    }
    if (!this.query.taxId) {
      this.query.taxId = this.taxId;
    }
    this.service.searchInSection(this.query).subscribe(
      (res: HttpResponse<CbicSearchOutputResult>) => {
        this.searchNotFound = false;
        this.cbicSearchOutputResults = res.body ?? new CbicSearchOutputResult([], null);
        if (this.cbicSearchOutputResults.dtos.length === 0) {
          this.searchNotFound = true;
        }
        this.filterResultsSTRules();
      });

  }

  filterResultsSTRules(): void {
    this.serviceTaxRuleList = this.serviceTaxRuleList.filter(((f: ICbicRuleMst) => this.cbicSearchOutputResults.dtos.some((item: CbicSearchOutput) => item.ruleId?.includes(String(f.id)))));

    if (this.query.keyword) {
      this.searchKeyWord = this.query.keyword;
    }

  }

  /*notification related function */


  notificationCategorySelected(notificationCategory: any): void {
    this.setPageinationDtls();
    if (this.notificationToBeOpened) {
      this.notificationToBeOpened = undefined;
    }
    this.notificationCategory = notificationCategory;

    const query: any = {
      year: this.notiYearSelected,
      page: 0,
      size: 10,
      taxId: this.taxId

    }
    /* if(this.notificationCategory && this.notificationCategory!=='All'){ */
    if (this.notificationCategory) {
      query['category'] = this.notificationCategory;
    }

    this.cbicNotificationMstService.fetchNotificationByYearCategory(query)
      .subscribe(
        (res: HttpResponse<ICbicNotificationMst[]>) => {
          this.notificationDtls = res.body
          if (this.notificationDtls.length === 0) {
            this.notiNotFound = true
          } else {
            this.notiNotFound = false
          }
        }
      );

    const queryForCount: any = {
      year: this.notiYearSelected, taxId: this.taxId
    }
    if (this.notificationCategory && this.notificationCategory !== 'All') {
      queryForCount['category'] = this.notificationCategory;
    }
    if (this.notifiSearchKeyWord) {
      queryForCount['keyword'] = this.notifiSearchKeyWord;
    }
    this.cbicNotificationMstService.query(queryForCount).subscribe(
      (res) => {
        this.totalRecordsNotiRead = res.body;
      });

    return;
  }

  fetchAllCircularList(taxId: any): void {
    this.selectedTab = "Circulars";
    this.activeTab = "Circulars";
    this.actName = ""
    // !this.cirYearSelected ? this.cirYearSelected = new Date().getFullYear() : this.cirYearSelected;
    if (this.circularToBeOpened) {
      this.cirYearSelected = this.circularToBeOpened.circularDt.get('y');
      if (this.circularToBeOpened.circularCategory) {
        this.circularCategory = this.circularToBeOpened.circularCategory;
      }
    } else {
      this.cirYearSelected = new Date().getFullYear()
    }


    this.circularYearSelected(this.cirYearSelected)

    if (taxId === 1000001) {

      this.cbicCircularMstService
        .fetchCircularCategory(taxId)
        .subscribe(
          (res: HttpResponse<ICbicCircularMst[]>) => {
            this.circularCategoryList = res.body;
          }
        )

    }
    this.fetchTaxName(this.taxId)
  }

  fetchAllCircularListFromSearch(taxId: any): void {
    // this.cbicCircularMstService
    //   .fetchAllCircularsByTaxId(taxId)
    //   .subscribe(
    //     (res: HttpResponse<ICbicCircularMst[]>) => {
    //       this.circularList = res.body
    //       this.cbicCircularMstService
    //         .fetchCircularCategory(taxId)
    //         .subscribe(
    //           (res1: HttpResponse<ICbicCircularMst[]>) => {
    //             this.circularCategoryList = res1.body;
    //             this.searchForCirculars(JSON.stringify({ "keyword": this.searchKeyWord }));
    //           }
    //         )
    //     }
    //   )
    this.selectedTab = "Circulars";
    this.activeTab = "Circulars";

    this.fetchAllCircularList(this.taxId);
    this.searchForCirculars(JSON.stringify({ "keyword": this.searchKeyWord }));
    this.circularSearchFlag = "N";

  }

  searchForCirculars(searchQueryJson: string): void {
    this.query = JSON.parse(searchQueryJson);
    this.circularSearchKeyword = this.query.keyword;
    this.circularYearSelected(this.cirYearSelected);
  }
  /* searchForCirculars(searchQueryJson: string): void {
    this.query = JSON.parse(searchQueryJson);
    this.query.rows = 100;
    if (!this.query.content) {
      this.query.content = "CIRCULARS";
    }
    if (!this.query.taxId) {
      this.query.taxId = this.taxId;
    }
    this.service.searchInSection(this.query).subscribe(
      (res: HttpResponse<CbicSearchOutputResult>) => {
        this.searchNotFound = false;
        this.cbicSearchOutputResults = res.body ?? new CbicSearchOutputResult([], null);
        if (this.cbicSearchOutputResults.dtos.length === 0) {
          this.searchNotFound = true;
        }
        this.filterResultsCirculars();
      });

  } */

  filterResultsCirculars(): void {
    this.circularList = this.circularList.filter(((f: ICbicRuleMst) => this.cbicSearchOutputResults.dtos.some((item: CbicSearchOutput) => item.sectionId?.includes(String(f.id)))));
    this.totalRecordsCircularRead = this.circularList.length

    if (this.query.keyword) {
      this.searchKeyWord = this.query.keyword;
    }

  }

  searchForNotifications(searchQueryJson: string): void {
    this.query = JSON.parse(searchQueryJson);
    this.notifiSearchKeyWord = this.query.keyword;
    this.notificationYearSelected(this.notiYearSelected);
  }



  /*filterResultsNotifications(): void {
    this.notificationDtls = this.notificationDtls.filter(((f: ICbicRuleMst) => this.cbicSearchOutputResults.dtos.some((item: CbicSearchOutput) => item.sectionId?.includes(String(f.id)))));
    this.contentPageService.notificationsRecived.next();
    this.totalRecordsNotiRead = this.notificationDtls.length
    if (this.query.keyword) {
      this.searchKeyWord = this.query.keyword;
    }

  }*/

  notificationYearSelected(notiYear: string): void {
    if (this.notificationToBeOpened && this.notificationSearchFlag === "N") {
      this.notificationToBeOpened = undefined;
    }
  const query: any = { year: notiYear, page: 0, size: 10, taxId: this.taxId };
    // As All option removed from dropdown
    // if(this.notificationCategory && this.notificationCategory!=='All'){
    if (this.notificationCategory) {
      query['category'] = this.notificationCategory;
    } else {
      if (this.taxId === 1000002 || this.taxId === 1000003) {
        if (this.storageLanguage === "en") {
          this.notificationCategory = 'Tariff'; //Defalt should be tariff in Customs, Central Excise
        }
        if (this.storageLanguage === "hi") {
          this.notificationCategory = 'टैरिफ'; //Defalt should be tariff in Customs, Central Excise
        }


      }

      if (this.taxId === 1000001) {
        if (this.storageLanguage === "en") {
          this.notificationCategory = 'Central Tax'; //Defalt should be Central Tax in GST

        }
        if (this.storageLanguage === "hi") {
          this.notificationCategory = 'केंद्रीय कर'; //Defalt should be tariff in Customs, Central Excise
        }
      }

      query['category'] = this.notificationCategory

    }
    if (this.notifiSearchKeyWord) {
      query['searchKeyword'] = this.notifiSearchKeyWord;
    }
    this.notiYearSelected = notiYear;
    this.cbicNotificationMstService.fetchNotificationByYearCategory(query)
      .subscribe(
        (res: HttpResponse<ICbicNotificationMst[]>) => {
          this.notificationDtls = res.body
          if (this.notificationDtls.length === 0) {
            this.notiNotFound = true
          } else {
            this.setPageinationDtls();
            this.notiNotFound = false;
          }
          if (this.taxId !== 1000004) {
            this.notifiSearch.setNotificationCategory(this.notificationCategory)
          }
          this.notifiSearch.setNotificationYear(notiYear);
          if (this.notificationToBeOpened) {
            this.notificationDtls = [];
            this.notificationDtls.push(this.notificationToBeOpened);
          }
          //this.contentPageService.notificationsRecived.next();
          //this.contentPageService.notificationsRecived.next();
        }
      );


    const queryForCount: any = {};
    queryForCount['year'] = notiYear;
    queryForCount['taxId'] = this.taxId

    if (this.notificationCategory) {
      queryForCount['category'] = this.notificationCategory;
    } else {
      //queryForCount['category'] = 'Tariff';
      if (this.taxId === 1000002 || this.taxId === 1000003) {
        if (this.storageLanguage === "en") {
          this.notificationCategory = 'Tariff'; //Defalt should be tariff in Customs, Central Excise
        }
        if (this.storageLanguage === "hi") {
          this.notificationCategory = 'टैरिफ'; //Defalt should be tariff in Customs, Central Excise
        }
      }
      if (this.taxId === 1000001) {
        if (this.storageLanguage === "en") {
          this.notificationCategory = 'Central Tax'; //Defalt should be Central Tax in GST

        }
        if (this.storageLanguage === "hi") {
          this.notificationCategory = 'केंद्रीय कर'; //Defalt should be tariff in Customs, Central Excise
        }
      }
      queryForCount['category'] = this.notificationCategory
    }

    if (this.notifiSearchKeyWord) {


      queryForCount['keyword'] = this.notifiSearchKeyWord;
    }

    this.cbicNotificationMstService.query(queryForCount).subscribe(
      (res) => {
        this.totalRecordsNotiRead = res.body;
        if (this.notificationToBeOpened) {
          this.totalRecordsNotiRead = 1;
        }
      });
  }

  clearSearchBarCirculars(): void {
    this.query = {};
    this.searchKeyWord = "";
    this.circularSearchKeyword = ""
    this.cbicSearchOutputResults.dtos = [];
    this.circularYearSelected(this.cirYearSelected);
  }

  clearSearchBarNotifications(): void {
    this.query = {};
    this.searchKeyWord = "";
    this.notifiSearchKeyWord = "";
    this.cbicSearchOutputResults.dtos = [];
    //this.loadNotificationsCategory();
    this.notificationYearSelected(this.notiYearSelected)
  }

  fetchSelectedCategoryCirculars(category: string): void {
    if (this.circularToBeOpened && this.circularSearchFlag === "N") {
      this.circularToBeOpened = undefined;
    }
    this.setPageinationDtls();
    this.circularCategory = category;
    const query: any = {
      year: this.cirYearSelected,
      page: 0,
      size: 10,
      taxId: this.taxId
    }

    if (this.circularCategory) {
      query['category'] = this.circularCategory;
    }

    this.cbicCircularMstService.fetchCircularByYearCategory(query)
      .subscribe(
        (res: HttpResponse<ICbicCircularMst[]>) => {
          this.circularList = res.body
          if (this.circularList.length === 0) {
            this.circularNotFound = true
          } else {
            this.circularNotFound = false
          }
        }
      )

    const queryForCount: any = {
      year: this.cirYearSelected,
      taxId: this.taxId
    }

    if (this.circularCategory) {
      queryForCount['category'] = this.circularCategory;
    }
    this.cbicCircularMstService.query(queryForCount).subscribe(
      (res) => {
        this.totalRecordsCircularRead = res.body;
      });

    /* if(category === "All") {
      this.fetchAllCircularList(this.taxId)
    } else {
      this.cbicCircularMstService
     .fetchCategoryRelatedCirculars(this.taxId, category)
     .subscribe(
       (res: HttpResponse<ICbicCircularMst[]>) => {
          this.circularList = res.body;
       }
     )
    } */
  }

  downloadCompleteDocument(fullPdfPath: string): void {

    if (fullPdfPath) {


      this.http.get('content/pdf/' + String(fullPdfPath),
        { responseType: 'text' })
        .subscribe(res => {
          if (res) {
            const json = JSON.parse(res);
            const fileURL = 'data:application/pdf;base64,' + String(json.data);
            this.dataUtils.openFile(String(json.data), "application/pdf");
          } else {
            alert("No data found");
          }
        }
        );
    }
  }


  downloadCompleteHTMLDocument(fullHtmlPath: string): void {

    window.open('content/html/' + String(fullHtmlPath), "_blank");

  }

  circularYearSelected(cirYear: string): void {
    if (this.circularToBeOpened && this.circularSearchFlag === "N") {
      this.circularToBeOpened = undefined;
    }
    const query: any = { year: cirYear, page: 0, size: 10, taxId: this.taxId };
    if (this.circularCategory) {
      query['category'] = this.circularCategory;
    } else {
      if (this.taxId === 1000001) {
        if (this.storageLanguage === "en") {
          this.circularCategory = 'Circulars CGST'
        }
        if (this.storageLanguage === "hi") {
          this.circularCategory = 'सीजीएसटी के परिपत्र';
        }

        query['category'] = this.circularCategory//Defalt should be Circulars CGST in GST CIRCULAR
      }

    }

    this.cirYearSelected = cirYear;

    if (this.circularSearchKeyword) {
      query['searchKeyword'] = this.circularSearchKeyword;
    }

    this.cbicCircularMstService.fetchCircularByYearCategory(query)
      .subscribe(
        (res: HttpResponse<ICbicCircularMst[]>) => {
          this.circularList = res.body
          if (this.circularList.length === 0) {
            this.circularNotFound = true
          } else {
            this.circularNotFound = false
            this.setPageinationDtls();
          }
          if (this.circularToBeOpened) {
            this.circularList = [];
            this.circularList.push(this.circularToBeOpened);
          }
          if (this.taxId === 1000001) {
            this.circularSearch.setCircularCategory(this.circularCategory)
          }
          this.circularSearch.setCircularYear(cirYear)
        })

    const queryForCount: any = {};
    queryForCount['year'] = cirYear;
    queryForCount['taxId'] = this.taxId;

    if (this.circularCategory) {
      queryForCount['category'] = this.circularCategory;
    } else {
      /* this.circularCategory = ''
      queryForCount['category'] = this.circularCategory */
      if (this.taxId === 1000001) {
        //this.circularCategory = 'Circulars CGST'
        if (this.storageLanguage === "en") {
          this.circularCategory = 'Circulars CGST'
        }
        if (this.storageLanguage === "hi") {
          this.circularCategory = 'सीजीएसटी के परिपत्र';
        }
        queryForCount['category'] = this.circularCategory
      }
    }

    if (this.circularSearchKeyword) {
      queryForCount['keyword'] = this.circularSearchKeyword;
    }

    if (this.circularToBeOpened) {
      this.totalRecordsCircularRead = 1;
    } else {
      this.cbicCircularMstService.query(queryForCount).subscribe(
        (res) => {
          this.totalRecordsCircularRead = res.body
        }
      )
    }

  }

  //Instruction Functions
  fetchAllInstructions(taxId: any): void {
    this.selectedTab = "Instructions";
    this.activeTab = "Instructions";
    this.actName = ""
    if (this.instructionToBeOpened) {
      this.instructionYearSelected = this.instructionToBeOpened.instructionDt.get('y');
      if (this.instructionToBeOpened.instructionCategory) {
        this.instructionCategory = this.instructionToBeOpened.instructionCategory;
      }
    } else {
      this.instructionYearSelected = new Date().getFullYear();
    }
    this.fetchInstructionYearSelected(this.instructionYearSelected)

    // if (taxId === 1000001) {

    //   this.cbicInstructionMstService
    //     .fetchInstructionCategory(taxId)
    //     .subscribe(
    //       (res: HttpResponse<ICbicInstructionMst[]>) => {
    //         this.instructionCategoryList = res.body;
    //       }
    //     )

    // }
    this.fetchTaxName(this.taxId)
  }

  fetchInstructionYearSelected(instructionYear: string): void {
    if (this.instructionToBeOpened && this.instructionSearchFlag === "N") {
      this.instructionToBeOpened = undefined;
    }
    const query: any = { year: instructionYear, page: 0, size: 10, taxId: this.taxId };
    // if(this.circularCategory ){
    //   query['category'] =this.circularCategory;
    // } else{
    //   if(this.taxId === 1000001){
    //     this.circularCategory = 'Circulars CGST' 
    //     query['category'] = this.circularCategory
    //   }

    // }
    //Check this value
    if (this.insSearchKeyWord) {
      query['searchKeyword'] = this.insSearchKeyWord;
    }
    this.instYearSelected = instructionYear;
    this.cbicInstructionMstService.fetchInstructionByYearCategory(query)
      .subscribe(
        (res: HttpResponse<ICbicInstructionMst[]>) => {
          this.insturctionList = res.body
          if (this.insturctionList.length === 0) {
            this.instructionNotFound = true
          } else {
            this.instructionNotFound = false
          }

          //Set Instruction Year in Search bar
          this.instructionSearch.setInstructionYear(this.instYearSelected);

          if (this.instructionToBeOpened) {
            this.insturctionList = [];
            this.insturctionList.push(this.instructionToBeOpened);
          }
        })

    const queryForCount: any = {};
    queryForCount['year'] = instructionYear;
    queryForCount['taxId'] = this.taxId;

    if (this.instructionCategory) {
      queryForCount['category'] = this.instructionCategory;
    }

    if (this.insSearchKeyWord) {
      queryForCount['keyword'] = this.insSearchKeyWord;
    }

    this.cbicInstructionMstService.fetchIntructionCount(queryForCount).subscribe(
      (res) => {
        this.totalRecordsInstructionRead = res.body
        if (this.instructionToBeOpened) {
          this.totalRecordsInstructionRead = 1;
        }
      }
    )
  }

  searchForInstructions(searchQueryJson: string): void {
    this.query = JSON.parse(searchQueryJson);
    this.insSearchKeyWord = this.query.keyword;
    this.fetchInstructionYearSelected(this.instYearSelected);
  }

  clearSearchBarInstrcutions(): void {
    this.query = {};
    this.searchKeyWord = "";
    this.insSearchKeyWord = "";
    this.cbicSearchOutputResults.dtos = [];
    this.fetchInstructionYearSelected(this.instYearSelected);
  }

  fetchSelectedCategoryInstrcutions(category: string): void {
    if (this.instructionToBeOpened && this.instructionSearchFlag === "N") {
      this.instructionToBeOpened = undefined;
    }
    this.setPageinationDtls();
    this.instructionCategory = category;

    const query: any = {
      year: this.instYearSelected,
      page: 0,
      size: 10,
      taxId: this.taxId

    }
    /* if(this.notificationCategory && this.notificationCategory!=='All'){ */
    if (this.instructionCategory) {
      query['category'] = this.instructionCategory;
    }

    this.cbicInstructionMstService.fetchInstructionByYearCategory(query)
      .subscribe(
        (res: HttpResponse<ICbicInstructionMst[]>) => {
          this.insturctionList = res.body
          if (this.insturctionList.length === 0) {
            this.instructionNotFound = true
          } else {
            this.instructionNotFound = false
          }
        }
      );

    const queryForCount: any = {
      year: this.instYearSelected, taxId: this.taxId
    }
    if (this.instructionCategory && this.instructionCategory !== 'All') {
      queryForCount['category'] = this.instructionCategory;
    }
    if (this.insSearchKeyWord) {
      queryForCount['keyword'] = this.insSearchKeyWord;
    }
    this.cbicInstructionMstService.fetchIntructionCount(queryForCount).subscribe(
      (res) => {
        this.totalRecordsInstructionRead = res.body;
      });

    return;
  }

  fetchInstructionBasedOnYearSelected(instructionYear: string): void {
    if (this.instructionToBeOpened && this.instructionSearchFlag === "N") {
      this.instructionToBeOpened = undefined;
    }
    const query: any = { year: instructionYear, page: 0, size: 10, taxId: this.taxId };
    if (this.instructionCategory) {
      query['category'] = this.instructionCategory;
    }
    // } else{
    //   if(this.taxId === 1000001){
    //     this.circularCategory = 'Circulars CGST' 
    //     query['category'] = this.circularCategory
    //   }



    this.instYearSelected = instructionYear;

    this.cbicInstructionMstService.fetchInstructionByYearCategory(query)
      .subscribe(
        (res: HttpResponse<ICbicInstructionMst[]>) => {
          this.insturctionList = res.body
          if (this.insturctionList.length === 0) {
            this.instructionNotFound = true
          } else {
            this.instructionNotFound = false
          }

          this.setPageinationDtls();
          this.dummyTest = this.dummyTest + 25;
          //Set Instruction Year in Search bar
          this.instructionSearch.setInstructionYear(this.instYearSelected);
        })

    const queryForCount: any = {};
    queryForCount['year'] = instructionYear;
    queryForCount['taxId'] = this.taxId;

    if (this.instructionCategory) {
      queryForCount['category'] = this.instructionCategory;
    } else {
      //Change Condition based on instruction Data
      // if(this.taxId === 1000001){
      //   this.circularCategory = 'Circulars CGST' 
      //   queryForCount['category'] = this.circularCategory
      // }
    }

    this.cbicInstructionMstService.fetchIntructionCount(queryForCount).subscribe(
      (res) => {
        this.totalRecordsInstructionRead = res.body
      }
    )
  }


  loadInstructions(): void {
    this.selectedTab = "Instructions";
    this.activeTab = "Instructions";
    if (this.storageLanguage === "hi") {
      this.tabName = "निर्देश / दिशानिर्देश"
    } else {
      this.tabName = this.activeTab
    }
    this.sessionStorage.store('activeTab', this.activeTab);
    this.actName = ""
    this.fetchAllInstructions(this.taxId);
  }

  //Order Functions
  fetchAllOrders(taxId: any): void {
    this.selectedTab = "Orders";
    this.activeTab = "Orders";
    this.actName = ""
    if (this.orderToBeOpened) {
      this.orderYearSelected = this.orderToBeOpened.orderDt.get('y');
      if (this.orderToBeOpened.orderCategory) {
        this.orderCategory = this.orderToBeOpened.orderCategory;
      }
    } else {
      this.orderYearSelected = new Date().getFullYear();
    }
    this.fetchOrderYearSelected(this.orderYearSelected)
    this.cbicOrderMstService
      .fetchOrderCategory(taxId)
      .subscribe(
        (res: HttpResponse<ICbicOrderMst[]>) => {
          this.orderCategoryList = res.body;
        }
      )
    this.fetchTaxName(this.taxId)
  }

  fetchOrderYearSelected(orderYear: string): void {

    const query: any = { year: orderYear, page: 0, size: 10, taxId: this.taxId };
    // if(this.circularCategory ){
    //   query['category'] =this.circularCategory;
    // } else{
    //   if(this.taxId === 1000001){
    //     this.circularCategory = 'Circulars CGST' 
    //     query['category'] = this.circularCategory
    //   }

    // }
    //Check this value
    if (this.orderCategory) {
      query['category'] = this.orderCategory;
    } else {
      if (this.taxId === 1000001) {
        if (this.storageLanguage === "hi") {
          this.orderCategory = 'आदेश-सीजीएसटी';
          query['category'] = this.orderCategory;
        } else {
          this.orderCategory = 'Order-CGST';
          query['category'] = this.orderCategory;
        }

      } else if (this.taxId === 1000002) {

        if (this.storageLanguage === "hi") {
          this.orderCategory = 'नॉन टैरिफ';
          query['category'] = this.orderCategory;
        } else {
          this.orderCategory = 'Non Tariff';
          query['category'] = this.orderCategory;
        }


      }
    }
    if (this.ordSearchKeyword) {
      query['searchKeyword'] = this.ordSearchKeyword;
    }
    this.ordYearSelected = orderYear;
    this.cbicOrderMstService.fetchOrderByYearCategory(query)
      .subscribe(
        (res: HttpResponse<ICbicOrderMst[]>) => {
          this.orderList = res.body
          if (this.orderList.length === 0) {
            this.orderNotFound = true
          } else {
            this.orderNotFound = false
          }
          if (this.orderToBeOpened) {
            this.orderList = [];
            this.orderList.push(this.orderToBeOpened);
          }
          if (this.taxId === 1000001 || this.taxId === 1000002) {
            this.orderSearch.setOrderCategory(this.orderCategory);
          }
          //Set Instruction Year in Search bar
          this.orderSearch.setOrderYear(this.ordYearSelected);
        })

    const queryForCount: any = {};
    queryForCount['year'] = orderYear;
    queryForCount['taxId'] = this.taxId;

    if (this.orderCategory) {
      queryForCount['category'] = this.orderCategory;
    } else {
      //Change Condition based on instruction Data
      // if(this.taxId === 1000001){
      //   this.circularCategory = 'Circulars CGST' 
      //   queryForCount['category'] = this.circularCategory
      // }
    }
    if (this.ordSearchKeyword) {
      queryForCount['keyword'] = this.ordSearchKeyword;
    }
    if (this.orderToBeOpened) {
      this.totalRecordsOrderRead = 1;
    } else {
      this.cbicOrderMstService.fetchOrderCount(queryForCount).subscribe(
        (res) => {
          this.totalRecordsOrderRead = res.body
        }
      )
    }

  }

  searchForOrders(searchQueryJson: string): void {
    this.query = JSON.parse(searchQueryJson);
    this.ordSearchKeyword = this.query.keyword;
    this.fetchOrderYearSelected(this.ordYearSelected);
  }

  clearSearchBarOrders(): void {
    this.query = {};
    this.searchKeyWord = "";
    this.ordSearchKeyword = "";
    this.cbicSearchOutputResults.dtos = [];
    this.fetchOrderYearSelected(this.ordYearSelected);
  }

  fetchSelectedCategoryOrders(category: string): void {
    if (this.orderToBeOpened && this.orderSearchFlag === 'N') {
      this.orderToBeOpened = undefined;
    }

    this.setPageinationDtls();
    // if (this.notificationToBeOpened) {
    //   this.notificationToBeOpened = undefined;
    // }
    this.orderCategory = category;

    const query: any = {
      year: this.ordYearSelected,
      page: 0,
      size: 10,
      taxId: this.taxId

    }
    /* if(this.notificationCategory && this.notificationCategory!=='All'){ */
    if (this.orderCategory) {
      query['category'] = this.orderCategory;
    }

    this.cbicOrderMstService.fetchOrderByYearCategory(query)
      .subscribe(
        (res: HttpResponse<ICbicOrderMst[]>) => {
          this.orderList = res.body
          if (this.orderList.length === 0) {
            this.orderNotFound = true
          } else {
            this.orderNotFound = false
          }
        }
      );

    const queryForCount: any = {
      year: this.ordYearSelected, taxId: this.taxId
    }
    if (this.orderCategory && this.orderCategory !== 'All') {
      queryForCount['category'] = this.orderCategory;
    }
    // if (this.notifiSearchKeyWord) {
    //   queryForCount['keyword'] = this.notifiSearchKeyWord;
    // }
    this.cbicOrderMstService.fetchOrderCount(queryForCount).subscribe(
      (res) => {
        this.totalRecordsOrderRead = res.body;
      });

    return;
  }

  fetchOrderBasedOnYearSelected(orderYear: string): void {
    if (this.orderToBeOpened && this.orderSearchFlag === 'N') {
      this.orderToBeOpened = undefined;
    }
    const query: any = { year: orderYear, page: 0, size: 10, taxId: this.taxId };
    if (this.orderCategory) {
      query['category'] = this.orderCategory;
    }
    // } else{
    //   if(this.taxId === 1000001){
    //     this.circularCategory = 'Circulars CGST' 
    //     query['category'] = this.circularCategory
    //   }



    this.ordYearSelected = orderYear;

    this.cbicOrderMstService.fetchOrderByYearCategory(query)
      .subscribe(
        (res: HttpResponse<ICbicOrderMst[]>) => {
          this.orderList = res.body
          if (this.orderList.length === 0) {
            this.orderNotFound = true
          } else {
            this.orderNotFound = false
          }
          this.setPageinationDtls();
          if (this.taxId === 1000001 || this.taxId === 1000002) {
            this.orderSearch.setOrderCategory(this.orderCategory);
          }
          //Set Order Year in Search bar
          this.orderSearch.setOrderYear(this.ordYearSelected);
        })

    const queryForCount: any = {};
    queryForCount['year'] = orderYear;
    queryForCount['taxId'] = this.taxId;

    if (this.orderCategory) {
      queryForCount['category'] = this.orderCategory;
    } else {
      //Change Condition based on order Data
      // if(this.taxId === 1000001){
      //   this.circularCategory = 'Circulars CGST' 
      //   queryForCount['category'] = this.circularCategory
      // }
    }

    this.cbicOrderMstService.fetchOrderCount(queryForCount).subscribe(
      (res) => {
        this.totalRecordsOrderRead = res.body
      }
    )
  }


  loadOrders(): void {
    this.selectedTab = "Orders";
    this.activeTab = "Orders";
    if (this.storageLanguage === "hi") {
      this.tabName = "आदेश"
    } else {
      this.tabName = this.activeTab
    }
    this.sessionStorage.store('activeTab', this.activeTab);
    this.actName = ""
    this.fetchAllOrders(this.taxId);
  }

  setPageinationDtls(): void {
    this.currentPageInputParent = 1;
    this.ngbPageInputParent = 1;
    this.recordSelectedInputParent = 10;
  }

  fetchAllInstructionsSearch(taxId: any): void {
    this.selectedTab = "Instructions";
    this.activeTab = "Instructions";

    this.fetchAllInstructions(this.taxId);
    this.searchForInstructions(JSON.stringify({ "keyword": this.searchKeyWord }));
    this.instructionSearchFlag = "N";
  }

  fetchAllOrdersSearch(taxId: any): void {
    this.selectedTab = "Orders";
    this.activeTab = "Orders";

    this.fetchAllOrders(this.taxId);
    this.searchForOrders(JSON.stringify({ "keyword": this.searchKeyWord }));
    this.orderSearchFlag = "N";
  }

  showHistoryModel(): void {

    this.cbicAmendmentHisService.fetchHistorybyTaxId(this.taxId, this.actContentId).subscribe(
      (res: HttpResponse<ICbicAmendmentDocHistory[]>) => {
        this.amendmentHistory = res.body
      }
    )
    this.modalService.open(this.relatedListModal, { windowClass: "myCustomHistoryClass", centered: true });

  }
}
