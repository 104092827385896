import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { Search } from 'app/core/request/request.model';
import { ICbicAmendmentDocHistory, getCbicAmendmentDocHistoryIdentifier } from '../cbic-amendment-doc-history.model';

export type EntityResponseType = HttpResponse<ICbicAmendmentDocHistory>;
export type EntityArrayResponseType = HttpResponse<ICbicAmendmentDocHistory[]>;

@Injectable({ providedIn: 'root' })
export class CbicAmendmentDocHistoryService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/cbic-amendment-doc-histories');
  public resourceSearchUrl = this.applicationConfigService.getEndpointFor('api/_search/cbic-amendment-doc-histories');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(cbicAmendmentDocHistory: ICbicAmendmentDocHistory): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicAmendmentDocHistory);
    return this.http
      .post<ICbicAmendmentDocHistory>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }


  fetchHistorybyTaxId(taxId:number,contentId:number):Observable<EntityArrayResponseType>{
    return this.http
    .get<ICbicAmendmentDocHistory[]>(`${this.resourceUrl}/fetchHistoryByTaxId/${taxId}/${contentId}`, {  observe: 'response' })
    .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  update(cbicAmendmentDocHistory: ICbicAmendmentDocHistory): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicAmendmentDocHistory);
    return this.http
      .put<ICbicAmendmentDocHistory>(
        `${this.resourceUrl}/${getCbicAmendmentDocHistoryIdentifier(cbicAmendmentDocHistory) as number}`,
        copy,
        { observe: 'response' }
      )
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(cbicAmendmentDocHistory: ICbicAmendmentDocHistory): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicAmendmentDocHistory);
    return this.http
      .patch<ICbicAmendmentDocHistory>(
        `${this.resourceUrl}/${getCbicAmendmentDocHistoryIdentifier(cbicAmendmentDocHistory) as number}`,
        copy,
        { observe: 'response' }
      )
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICbicAmendmentDocHistory>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicAmendmentDocHistory[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  search(req: Search): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicAmendmentDocHistory[]>(this.resourceSearchUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  addCbicAmendmentDocHistoryToCollectionIfMissing(
    cbicAmendmentDocHistoryCollection: ICbicAmendmentDocHistory[],
    ...cbicAmendmentDocHistoriesToCheck: (ICbicAmendmentDocHistory | null | undefined)[]
  ): ICbicAmendmentDocHistory[] {
    const cbicAmendmentDocHistories: ICbicAmendmentDocHistory[] = cbicAmendmentDocHistoriesToCheck.filter(isPresent);
    if (cbicAmendmentDocHistories.length > 0) {
      const cbicAmendmentDocHistoryCollectionIdentifiers = cbicAmendmentDocHistoryCollection.map(
        cbicAmendmentDocHistoryItem => getCbicAmendmentDocHistoryIdentifier(cbicAmendmentDocHistoryItem)!
      );
      const cbicAmendmentDocHistoriesToAdd = cbicAmendmentDocHistories.filter(cbicAmendmentDocHistoryItem => {
        const cbicAmendmentDocHistoryIdentifier = getCbicAmendmentDocHistoryIdentifier(cbicAmendmentDocHistoryItem);
        if (
          cbicAmendmentDocHistoryIdentifier == null ||
          cbicAmendmentDocHistoryCollectionIdentifiers.includes(cbicAmendmentDocHistoryIdentifier)
        ) {
          return false;
        }
        cbicAmendmentDocHistoryCollectionIdentifiers.push(cbicAmendmentDocHistoryIdentifier);
        return true;
      });
      return [...cbicAmendmentDocHistoriesToAdd, ...cbicAmendmentDocHistoryCollection];
    }
    return cbicAmendmentDocHistoryCollection;
  }

  protected convertDateFromClient(cbicAmendmentDocHistory: ICbicAmendmentDocHistory): ICbicAmendmentDocHistory {
    return Object.assign({}, cbicAmendmentDocHistory, {
      createdDt: cbicAmendmentDocHistory.createdDt?.isValid() ? cbicAmendmentDocHistory.createdDt.toJSON() : undefined,
      updatedDt: cbicAmendmentDocHistory.updatedDt?.isValid() ? cbicAmendmentDocHistory.updatedDt.toJSON() : undefined,
      amendmentDt: cbicAmendmentDocHistory.amendmentDt?.isValid() ? cbicAmendmentDocHistory.amendmentDt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDt = res.body.createdDt ? dayjs(res.body.createdDt) : undefined;
      res.body.updatedDt = res.body.updatedDt ? dayjs(res.body.updatedDt) : undefined;
      res.body.amendmentDt = res.body.amendmentDt ? dayjs(res.body.amendmentDt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((cbicAmendmentDocHistory: ICbicAmendmentDocHistory) => {
        cbicAmendmentDocHistory.createdDt = cbicAmendmentDocHistory.createdDt ? dayjs(cbicAmendmentDocHistory.createdDt) : undefined;
        cbicAmendmentDocHistory.updatedDt = cbicAmendmentDocHistory.updatedDt ? dayjs(cbicAmendmentDocHistory.updatedDt) : undefined;
        cbicAmendmentDocHistory.amendmentDt = cbicAmendmentDocHistory.amendmentDt ? dayjs(cbicAmendmentDocHistory.amendmentDt) : undefined;
      });
    }
    return res;
  }
}
