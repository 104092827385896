import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { CbicAlliedActDtlsService } from 'app/entities/cbic-allied-act-dtls/service/cbic-allied-act-dtls.service';
import { CbicAlliedActMstService } from 'app/entities/cbic-allied-act-mst/service/cbic-allied-act-mst.service';
import { RuleMstResponse } from 'app/entities/cbic-rule-mst/cbic-rule-mst.model';
import * as saveAs from 'file-saver';
import { SessionStorageService } from 'ngx-webstorage';
import { NavigationExtras, Router } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeHi from '@angular/common/locales/hi';
@Component({
  selector: 'cbic-allied-act-read',
  templateUrl: './allied-act-read.component.html',
  styleUrls: ['./allied-act-read.component.scss']
})
export class AlliedActReadComponent implements OnInit {
  language: any
  currentPage:any;
  showRecordSelected = 10;
  ngbPaginationPage = 1;
  showRecord: number[] = [10, 25, 50, 100];
  @Input() alliedActDtls:any
  @Input() selectedTab:any
  @Input() totalRecords:any;
  @Input() selectedYear:any;
  @Input() alliedNotFound: any;
  @Input() alliedActNameCategory:any;
  @Input() searchKeyword:any;
  @Input() taxId:any;
  @Input() currentPageInput:any;
  @Input() ngbPageInput:any;
  @Input() showRecordInput:any;

  
  constructor( private sessionStorage: SessionStorageService,
    private cbicAlliedActMstService: CbicAlliedActMstService,
    private cbicAlliedActDtlsService:CbicAlliedActDtlsService,
    private router : Router,
  ) {
    
   }

  ngOnInit(): void {
    this.language = this.sessionStorage.retrieve('locale')
    registerLocaleData(localeHi, 'hi');
    this.currentPage = this.currentPageInput;
    this.ngbPaginationPage = this.ngbPageInput;
    this.showRecordSelected = this.showRecordInput;
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.currentPage = this.currentPageInput;
   this.ngbPaginationPage = this.ngbPageInput;
   this.showRecordSelected = this.showRecordInput;
  
 }

 loadPage(page?: number, dontNavigate?: boolean): void {
  this.currentPage=page;
  const pageToLoad: number = page ?? this.currentPage ?? 1;
  const query:any={
    page: pageToLoad - 1,
    size: this.showRecordSelected,
    year:this.selectedYear,
    alliedActNamecategory: this.alliedActNameCategory,
    taxId: this.taxId
  };
  if(this.searchKeyword){
    query.keyword=this.searchKeyword;
  }
  
  this.cbicAlliedActDtlsService
  .fetchAlliedActByYearName(query)
  .subscribe(
    (res) => {
    this.alliedActDtls=res.body;
    });
}

  showRecordSearch(recordValue : any): void{
    const pageToLoad =  1;
    this.showRecordSelected=recordValue;
    
      this.cbicAlliedActDtlsService
      .fetchAlliedActByYearName({
        page: pageToLoad - 1,
        size: this.showRecordSelected,
        year:this.selectedYear,
        alliedActNamecategory: this.alliedActNameCategory,
        taxId: this.taxId
      })
      .subscribe(
        (res) => {
        this.alliedActDtls=res.body;
        
        }
      );
    
  }

  /* for downloading pdf and html */

  downloadDocuments(id: any, language: any): void {
    
      this.cbicAlliedActDtlsService
        .downloadAlliedActDocument(Number(id), language).subscribe(
          (res: RuleMstResponse) => {
            const data = res.data;
            const byteCharacters = atob(String(data));
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            saveAs(blob, String(res.fileName));
          }
        );
    
    
  }


  /* for view the document */
  viewDocumentsV2(alliedDocumentAny:any, id:any, type:any, selectedTab:any):void{
    const link = this.router.serializeUrl(this.router.createUrlTree(['view-pdf/'+ String(id)+ '/'+ String(type) +'/'+String(selectedTab)]));
    window.open(link, '_blank');
}


}
