import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from 'app/layouts/profiles/profile.service';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { faSearch} from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import { CbicSearchInput1, CbicSTRuleSearchInput, ICbicSearchInput } from 'app/searchbar/searchbar.model';

@Component({
  selector: 'cbic-service-tax-rule-searchbar',
  templateUrl: './service-tax-rule-searchbar.component.html',
  styleUrls: ['./service-tax-rule-searchbar.component.scss']
})
export class ServiceTaxRuleSearchbarComponent implements OnInit {

  inProduction?: boolean;
  openAPIEnabled?: boolean;
  searchBarFormRule = this.fb.group({
    keyword : [],
    taxId : [],
    ruleId : [],
  });
  faTimesCircle=faTimesCircle;
  faSearch=faSearch;
  searched=false;
  @Input() serviceTaxRuleList:any;
  @Input() searchKeyWord='';
  @Output() sectionSelected = new EventEmitter<{sectionId : string, index: number}>();
  @Output() clearFiredRules= new EventEmitter<string>();
  @Output() searchFiredRules= new EventEmitter<string>();
  @Input() rulesTaxId:any;
  @ViewChild("serviceTaxKeyWord") serviceKeyword? : ElementRef;

  constructor(private router: Router,
    private profileService: ProfileService, 
    private http : HttpClient,
    private fb:FormBuilder) { }

  ngOnInit(): void {
    this.profileService.getProfileInfo().subscribe(profileInfo => {
      this.inProduction = profileInfo.inProduction;
      this.openAPIEnabled = profileInfo.openAPIEnabled;
    });
  }

  fireSeletedRule(ruleName: string):void{
    let sectionId = 0;
    let indexI =0;
    let find =false;
    
    if(ruleName !== ""){
      for(let i=0;i<this.serviceTaxRuleList.length;i++){
        if(ruleName === String(this.serviceTaxRuleList[i].ruleDocName)){
          sectionId = this.serviceTaxRuleList[i].id;
          indexI = i;
          find = true
        }
      }
    }
    if(find){
      this.sectionSelected.emit({sectionId:String(sectionId), index : indexI});
    }else{
      this.clear();
    }    
  }

  clear():void{
    this.searched=false;
    
    this.searchBarFormRule = this.fb.group({
      keyword : "",
      taxId : "",
      ruleId : "",
    });
    this.clearFiredRules.emit();
    return;
  }

  search():void{
    
    const cbicSearchInput = this.createFromForm();
    if(cbicSearchInput.keyword === undefined || cbicSearchInput.keyword!.length < 3){
      alert("Please enter valid keyword");
      this.serviceKeyword?.nativeElement.focus();
      return;
    }

    cbicSearchInput.keyword = cbicSearchInput.keyword?.trim()
    
    this.searchFiredRules.emit(JSON.stringify(cbicSearchInput));
    this.searched=true;
    //this.router.navigate(['searchPage/search'],{queryParams : cbicSearchInput});
  }


  protected createFromForm(): ICbicSearchInput {
    return {
      ...new CbicSTRuleSearchInput(),
      keyword: (this.searchBarFormRule.get(['keyword'])!.value === null || this.searchBarFormRule.get(['keyword'])!.value === undefined) ? null : this.searchBarFormRule.get(['keyword'])!.value,
      taxId: this.rulesTaxId,
      ruleId: this.searchBarFormRule.get(['ruleId'])!.value,
      content: "RULES",
    };
  }
}
