import { Injectable } from "@angular/core";
import { Theme, light, dark } from "./theme.model";

@Injectable({
  providedIn: "root"
})
export class ThemeService {
  count = 0;
  private active: Theme = dark;
  private availableThemes: Theme[] = [light];

  
  getAvailableThemes(): Theme[] {
    return this.availableThemes;
  }

  getActiveTheme(): Theme {
    return this.active;
  }

//   isDarkTheme(): boolean {
//     return this.active.name === dark.name;
//   }

//   setDarkTheme(): void {
//     this.setActiveTheme(dark);
//   }

  setLightTheme(): void {
      alert("call")
      if(this.count === 1){
        this.setActiveTheme(light);
        this.count--;
      }else if(this.count === 0){
        this.setActiveTheme(dark);
        this.count++;
      }
    
  }

  setActiveTheme(theme: Theme): void {
    this.active = theme;

    Object.keys(this.active.properties).forEach(property => {
      document.documentElement.style.setProperty(
        property,
        this.active.properties[property]
      );
    });
  }
}