import * as dayjs from 'dayjs';
import { ICbicTaxMst } from 'app/entities/cbic-tax-mst/cbic-tax-mst.model';

export interface ICbicActMst {
  id?: number;
  contentId?: number | null;
  actNo?: number | null;
  actName?: string | null;
  isActive?: string | null;
  isAmended?: string | null;
  issueDt?: dayjs.Dayjs | null;
  amendDt?: dayjs.Dayjs | null;
  parentId?: number | null;
  orderId?: number | null;
  versionNo?: number | null;
  createdBy?: number | null;
  createdDt?: dayjs.Dayjs | null;
  updatedBy?: number | null;
  updatedDt?: dayjs.Dayjs | null;
  taxId?: ICbicTaxMst | null;
  contentFilePath?: string | null;
  contentHtmlFilePath?:string|null;
  actNameHi?:string|null;
}

export class CbicActMst implements ICbicActMst {
  constructor(
    public id?: number,
    public contentId?: number | null,
    public actNo?: number | null,
    public actName?: string | null,
    public isActive?: string | null,
    public isAmended?: string | null,
    public issueDt?: dayjs.Dayjs | null,
    public amendDt?: dayjs.Dayjs | null,
    public parentId?: number | null,
    public orderId?: number | null,
    public versionNo?: number | null,
    public createdBy?: number | null,
    public createdDt?: dayjs.Dayjs | null,
    public updatedBy?: number | null,
    public updatedDt?: dayjs.Dayjs | null,
    public taxId?: ICbicTaxMst | null,
    public contentFilePath?: string | null,
    public contentHtmlFilePath?: string | null,
    public actNameHi?:string|null
  ) {}
}

export function getCbicActMstIdentifier(cbicActMst: ICbicActMst): number | undefined {
  return cbicActMst.id;
}
