import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CbicActMst, ICbicActMst } from 'app/entities/cbic-act-mst/cbic-act-mst.model';
import { CbicActMstService } from 'app/entities/cbic-act-mst/service/cbic-act-mst.service';
import { CbicTaxMst, ICbicTaxMst } from 'app/entities/cbic-tax-mst/cbic-tax-mst.model';
import { CbicTaxMstService } from 'app/entities/cbic-tax-mst/service/cbic-tax-mst.service';
import { ProfileService } from 'app/layouts/profiles/profile.service';
import { CbicContent, CbicContentHeader, CbicContentSearch, CbicSearchInput, ICbicContent, ICbicSearchInput } from 'app/searchbar/searchbar.model';
import { CbicSearchOutputResult } from '../search-output.model';
import { CbicSearchService } from '../service/cbic-search-service';
@Component({
  selector: 'cbic-search-section',
  templateUrl: './search-section.component.html',
  styleUrls: ['./search-section.component.scss']
})
export class SearchSectionComponent implements OnInit {

  inProduction?: boolean;
  openAPIEnabled?: boolean;
  @Input() cbicSearchInt?: CbicSearchInput;
  cbicTaxMsts?: ICbicTaxMst[];
  isLoading = false;
  cbicActMsts?: ICbicActMst[];
  cbicContents?: ICbicContent[];
  cbicContentHeader?: CbicContentHeader[];
 
  searchSection = this.fb.group({
    keyword : [],
    taxId : [],
    actId : [],
    content : [],
  })
  @Output()
  searchChanged = new EventEmitter<{searchResultChange:boolean}>();
  regexFormat =  /^[A-Za-z0-9/()-.,:" ]*$/;
  @ViewChild("searchSectionKeyword") searchSectionKeyword? : ElementRef;

  constructor(
    private router: Router,
    private profileService: ProfileService,
    protected fb: FormBuilder,
    public service: CbicSearchService,
    private taxMstService: CbicTaxMstService,
    private actMstService: CbicActMstService
  ) { }

  ngOnInit(): void {
    
    this.profileService.getProfileInfo().subscribe(profileInfo => {
      this.inProduction = profileInfo.inProduction;
      this.openAPIEnabled = profileInfo.openAPIEnabled;
    });
    // this.loadActs();
    this.loadTaxMsts();
    this.loadContents();
    this.loadSearchSection();
    this.showActsRules(this.searchSection.get(['taxId'])!.value,this.searchSection.get(['content'])!.value);
  }

  loadSearchSection() : void{
    this.searchSection.patchValue({
      keyword : this.service.cbicSearchInput.keyword,
      taxId : (this.service.cbicSearchInput.taxId == null)? "undefined" : this.service.cbicSearchInput.taxId,
      actId : (this.service.cbicSearchInput.actId == null)? "undefined" : this.service.cbicSearchInput.actId,
      content : (this.service.cbicSearchInput.content == null)? "undefined" : this.service.cbicSearchInput.content
    });
  }

  loadContents(): void {
    this.cbicContents = [];
    
    // this.cbicContents.push(new CbicContent('ACTS','Acts'),
    // new CbicContent('CIRCULARS','Circulars / Orders'),
    // new CbicContent('FORMS','Forms'),
    // new CbicContent('NOTIFICATIONS','Notifications'),
    // new CbicContent('REGULATIONS','Regulations'),
    // new CbicContent('RULES','Rules')
    // )
    this.cbicContents.push(
      new CbicContent('ACTS','Acts'),
      new CbicContent('RULES','Rules'),
      new CbicContent('REGULATIONS','Regulations'),
      new CbicContent('FORMS','Forms'),
      new CbicContent('NOTIFICATIONS','Notifications'),
      new CbicContent('CIRCULARS','Circulars'),
      new CbicContent('INSTRUCTIONS','Instructions'),
      new CbicContent('ORDERS','Orders'),
      new CbicContent('ALLIEDACTS','Allied Acts')
      )
    // this.cbicContents.push(
    //   new CbicContent('ACTS','Acts'),
    //   new CbicContent('CIRCULARS','Circulars'),
    //   new CbicContent('FORMS','Forms'),
    //   new CbicContent('NOTIFICATIONS','Notifications'),
    //   new CbicContent('REGULATIONS','Regulations'),
    //   new CbicContent('RULES','Rules'))

    // this.cbicContents.push(new CbicContent(undefined,'All Contents'));
    
  }

  loadTaxMsts(): void{
    this.taxMstService.query().subscribe(
      (res: HttpResponse<ICbicTaxMst[]>) => {
        this.isLoading = false;
        this.cbicTaxMsts = res.body ?? [];
        // this.cbicTaxMsts.push(new CbicTaxMst(undefined,undefined,'All Taxes','',undefined,undefined,undefined,undefined))
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  loadActs(): void{
    this.taxMstService.fetchContentHeader(new CbicContentSearch("","")).subscribe(
      (res: HttpResponse<CbicContentHeader[]>) => {
        
        
        this.cbicContentHeader = res.body ?? [];
        // this.cbicContentHeader.push(new CbicContentHeader(undefined,"All"));
        for(let i=0;i<this.cbicContentHeader.length;i++){
          const maxlen=60;
          if(this.cbicContentHeader[i].contentName.length>maxlen){
            this.cbicContentHeader[i].contentNameLable=String(this.cbicContentHeader[i].contentName).substring(0,maxlen)+"...";
          }else{
            this.cbicContentHeader[i].contentNameLable=this.cbicContentHeader[i].contentName;
          }
          
          
        }
        
      }
    );
  }

  showActsFromTaxId(taxId:any):void{
    if(taxId !== "undefined"){
      this.actMstService.findActList(Number(taxId)).subscribe(
        (res: HttpResponse<ICbicActMst[]>) => {
          this.isLoading = false;
          this.cbicActMsts = res.body ?? [];
          // this.cbicActMsts.push(new CbicActMst(undefined,undefined,undefined,'All Acts','','',undefined,undefined,undefined,undefined,undefined,undefined,undefined,undefined,undefined,undefined));
        },
        () => {
          this.isLoading = false;
        }
      );
    }else{
      this.loadActs();
    }
    
  }

  loadSearch() : void{
    const cbicSearchInput = this.createFromForm();
    let flag = false;
    if(!(cbicSearchInput.keyword === undefined || cbicSearchInput.keyword === null)){
      if(cbicSearchInput.keyword.length > 2){
        if(this.regexFormat.test(String(cbicSearchInput.keyword))){
          flag = true;
        }
      }
     
    }
    if(flag){
      this.service.pageNumber = 1;
    this.service.searchInSection(cbicSearchInput).subscribe(
      (res: HttpResponse<CbicSearchOutputResult>) => {
        this.service.cbicSearchOutputResults = res.body ?? new CbicSearchOutputResult([], null);
        this.router.navigate(['searchPage/search'],{queryParams : cbicSearchInput});
        this.searchChanged.emit({searchResultChange:true});
      });
    }else{
      alert("Please enter valid keyword")
      this.searchSectionKeyword?.nativeElement.focus()
    }
  
    // this.router.navigate(['searchPage/search'],{queryParams : cbicSearchInput});
    
  }

  showActsRules(taxId:any, contentType:any):void{
    if(taxId === "undefined"){
      taxId = "";
    }
    if(contentType === "undefined"){
      contentType = "";
    }
      this.taxMstService.fetchContentHeader(new CbicContentSearch(taxId,contentType)).subscribe(
        (res: HttpResponse<CbicContentHeader[]>) => {
          this.cbicContentHeader = res.body ?? [];
          for(let i=0;i<this.cbicContentHeader.length;i++){
            const maxlen=60;
            if(this.cbicContentHeader[i].contentName.length>maxlen){
              this.cbicContentHeader[i].contentNameLable=String(this.cbicContentHeader[i].contentName).substring(0,maxlen)+"...";
            }else{
              this.cbicContentHeader[i].contentNameLable=this.cbicContentHeader[i].contentName;
            }
            
          }
          // this.cbicContentHeader.push(new CbicContentHeader(undefined,"All"))
        }
      );
  }

  protected createFromForm(): ICbicSearchInput {
    return {
      ...new CbicSearchInput(),
      keyword: (this.searchSection.get(['keyword'])!.value === "undefined" || this.searchSection.get(['keyword'])!.value === null)? null : this.searchSection.get(['keyword'])!.value.trim(),
      taxId: (this.searchSection.get(['taxId'])!.value === "undefined")? null : this.searchSection.get(['taxId'])!.value,
      actId: (this.searchSection.get(['actId'])!.value === "undefined")? null : this.searchSection.get(['actId'])!.value,
      content: (this.searchSection.get(['content'])!.value === "undefined")? null : this.searchSection.get(['content'])!.value,
      startIndex: 0,
      paginationFlag: true,
      rows: 10
    };
  }

}
