import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ProfileService } from 'app/layouts/profiles/profile.service';

@Component({
  selector: 'cbic-inner-component',
  templateUrl: './inner-component.component.html',
  styleUrls: ['./inner-component.component.scss']
})
export class InnerComponentComponent implements OnInit, OnChanges {
  openAPIEnabled?: boolean;
  inProduction?: boolean;
  @Input() inputData: any;
  public anchors:any;
  @Output() openSectionEvent = new EventEmitter<string>();
  @ViewChild('scrollHere') private myScrollContainer?: ElementRef;
  constructor(
    private profileService: ProfileService,
     private elementRef: ElementRef,
     private scrollHere:ElementRef,
     private changeDedectionRef: ChangeDetectorRef
  ) { 

    
  }
  ngOnChanges(): void {
   /* if(this.myScrollContainer){
      this.scrollToBottom();
    }*/
    return;
  }

  ngAfterContentChecked(): void {
    this.changeDedectionRef.detectChanges();
  }

  scrollToBottom():void{
    this.myScrollContainer?.nativeElement.scrollIntoView({ behavior: 'smooth' });      
  }

  public ngAfterViewInit():void {
    // Solution for catching click events on anchors using querySelectorAll:
    this.anchors = this.elementRef.nativeElement.querySelectorAll('a');
    this.anchors.forEach((anchor: HTMLAnchorElement) => {
      anchor.addEventListener('click', this.handleAnchorClick)
    })
  }
  public handleAnchorClick(event: Event) :void {
    // Prevent opening anchors the default way
    event.preventDefault();
    alert('it is here');
    const anchor = event.target as HTMLAnchorElement;
    alert(`You are trying to navigate to ${anchor.href}`);
  }
  ngOnInit(): void {
    
    this.profileService.getProfileInfo().subscribe(profileInfo => {
      this.inProduction = profileInfo.inProduction;
      this.openAPIEnabled = profileInfo.openAPIEnabled;
    });
  }

  scrollTothisElement():void{
    this.scrollHere.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
  openSection(sectionId:string):void{
    alert(sectionId);
    this.openSectionEvent.emit(sectionId);
  }

}
