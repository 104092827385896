<div class="page-header">
    <div class="container content-view">
        <div class="row">
            <h5 style="font-size: 14px;"><label cbicTranslate="homeButton"> Home</label> <span style="color: #E19F20;"> >> </span> {{tabName}} <span
                    style="color: #E19F20;"> >> </span> <label cbicTranslate="amendmenthistory"> Amendment History</label> </h5>
            <br />
            <div class="input-group flex-nowrap diplay-block-sm">
                <h4 class="headingfont"> {{tabName}} <label cbicTranslate="amendmenthistory"> Amendment History</label> </h4>
                <p style="color: #00769D; padding-left: 15%;" class="noPadding" cbicTranslate="notificationHistoryMsg">*Text of amended notifications (where applicable), will be made available in a phased manner</p>
            </div>
            <!-- <div class="col-8 wrapTitleUnderline" *ngIf="selectedTab === 'Notifications'">
                <h4>Notification Amendment History</h4>
            </div> -->
            <!-- <div class="col-8 wrapTitleUnderline" *ngIf="selectedTab === 'Circulars'">
                <h4>Circulars History</h4>
            </div> -->
                
        </div>
        <div class="row">
            <div class="content-col result-data col-lg-12 col-md-12">
                <div *ngIf="historyNotFound" class="row no-notification">
                    <p *ngIf="historyNotFound && selectedTab === 'Notifications'" cbicTranslate="historyNotNotification">History not available for selected Notification</p>
                    <p *ngIf="historyNotFound && selectedTab === 'Circulars'" cbicTranslate="historyNotCircular">History not available for selected Circular</p>
                </div>
                <!-- Notification History-->
                <div *ngIf="!historyNotFound && notificationHistoryList" class="accordion accordion-flush" id="accordionFlushExample">

                    <div *ngFor="let history of notificationHistoryList; let i = index" class="accordion-item">

                        <h3 class="accordion-header" id="{{i}}">
                            <button class="history-accordian accordion-button collapsed " type="button"
                                data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-section'+i"
                                aria-expanded="false" aria-controls="flush-collapseOne"
                                (click)="fetchNotificationHistory(history.contentId)">
                                Amendment(s) to Notification No. {{history.notificationNo}}
                            </button>

                        </h3>

                        <div [attr.id]="'flush-section'+i" name="flush-headingOne" class="collapse"
                            aria-labelledby="flush-heading" data-bs-parent="#accordionFlushExample" #flushsection1>
                            <div class="accordion-body tab-content wrap-content">
                                <div class="pagebody table-responsive">
                                    <table class="table table-hover">
                                        <tbody>
                                            <tr>

                                                <th style="text-align: center;width: 10%;" cbicTranslate="number">Number</th>
                                                <th style="text-align: center;width: 10%;" cbicTranslate="date">Date</th>
                                                <th style="text-align: center;width: 50%;" cbicTranslate="subject">Subject</th>
                                                <th style="text-align: center;width: 10%;" cbicTranslate="viewNotification">View Notification </th>
                                                <th style="text-align: center;width: 50%;" cbicTranslate="textOfAmendNoti">Text of Amended
                                                    Notification (As on Date)</th>
                                            </tr>
                                            <tr *ngFor="let notiHistory of historyList, let i=index">
                                                <td class="text-start"
                                                    [ngClass]="{ 'notification-his':parentContentId === notiHistory.notificationContentId || notiHistory.notificationContentId === baseContentId, 
                                                                 'noti-superseded': notiHistory.ntRemarks}">
                                                    {{notiHistory.notificationNo}}</td>
                                                <td class="date"
                                                    [ngClass]="{ 'notification-his':parentContentId === notiHistory.notificationContentId || notiHistory.notificationContentId === baseContentId, 
                                                                 'noti-superseded': notiHistory.ntRemarks}">
                                                    {{notiHistory.notificationDt| date: 'dd-MMM-yyyy':'UTC'}}</td>
                                                <td class="text-start"
                                                    [ngClass]="{ 'notification-his':parentContentId === notiHistory.notificationContentId || notiHistory.notificationContentId === baseContentId, 
                                                                 'noti-superseded': notiHistory.ntRemarks}">
                                                    {{notiHistory.notificationName}} </td>
                                                <td align="center"><a [routerLink]="" *ngIf="notiHistory.docFilePath"
                                                (click)="viewDocumentsV2( notiHistory.docFilePath, notiHistory.id, 'ENG', selectedTab)">View</a>
                                                </td>
                                                <td align="center">
                                                    <a [routerLink]=""
                                                        *ngIf="notiHistory.docFilePathAOD && notiHistory.notificationContentId !== parentContentId"
                                                        (click)="viewDocumentsV2( notiHistory.docFilePathAOD, notiHistory.mappingId, 'ENG', 'History')">View</a>
                                                    <p *ngIf="notiHistory.ntRemarks" class="noti-superseded"> {{notiHistory.ntRemarks}}</p>    
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- Circular History-->
                <div *ngIf="!historyNotFound && circularHistoryList" class="accordion accordion-flush" id="accordionFlushExample">

                    <div *ngFor="let history of circularHistoryList; let i = index" class="accordion-item">

                        <h3 class="accordion-header" id="{{i}}">
                            <button class="history-accordian accordion-button collapsed " type="button"
                                data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-section'+i"
                                aria-expanded="false" aria-controls="flush-collapseOne"
                                (click)="fetchCircularHistory(history.contentId)">
                                Amendment(s) to Circular No. {{history.circularNo}}
                            </button>

                        </h3>

                        <div [attr.id]="'flush-section'+i" name="flush-headingOne" class="collapse"
                            aria-labelledby="flush-heading" data-bs-parent="#accordionFlushExample" #flushsection1>
                            <div class="accordion-body tab-content wrap-content">
                                <div class="pagebody table-responsive">
                                    <table class="table table-hover">
                                        <tbody>
                                            <tr>

                                                <th style="text-align: center;width: 10%;" cbicTranslate="number">Number</th>
                                                <th style="text-align: center;width: 10%;" cbicTranslate="date">Date</th>
                                                <th style="text-align: center;width: 50%;" cbicTranslate="subject">Subject</th>
                                                <th style="text-align: center;width: 10%;" cbicTranslate="viewNotification">View Notification </th>
                                                <th style="text-align: center;width: 50%;" cbicTranslate="textOfAmendNoti">Text of Amended
                                                    Notification (As on Date)</th>
                                            </tr>
                                            <tr *ngFor="let cirHistory of historyListCircular, let i=index">
                                                <td class="text-start"
                                                    [ngClass]="{ 'notification-his':parentContentId === cirHistory.circularContentId || cirHistory.circularContentId === baseContentId, 
                                                                 'noti-superseded': cirHistory.cirRemarks}">
                                                    {{cirHistory.circularNo}}</td>
                                                <td class="date"
                                                [ngClass]="{ 'notification-his':parentContentId === cirHistory.circularContentId || cirHistory.circularContentId === baseContentId, 
                                                'noti-superseded': cirHistory.cirRemarks}">
                                                    {{cirHistory.circularDt| date: 'dd-MMM-yyyy':'UTC'}}</td>
                                                <td class="text-start"
                                                [ngClass]="{ 'notification-his':parentContentId === cirHistory.circularContentId || cirHistory.circularContentId === baseContentId, 
                                                'noti-superseded': cirHistory.cirRemarks}">
                                                    {{cirHistory.circularName}} </td>
                                                <!-- <td align="center"><a [routerLink]=""
                                                        (click)="downloadDocuments(notiHistory.id, 'ENG')"
                                                        *ngIf="notiHistory.docFilePath"> <i class="fa fa-download"
                                                            aria-hidden="true"></i></a></td> -->
                                                <td align="center"><a [routerLink]="" *ngIf="cirHistory.docFilePath"
                                                (click)="viewDocumentsV2( cirHistory.docFilePath, cirHistory.id, 'ENG', selectedTab)">View</a>
                                                </td>
                                                <td align="center">
                                                    <a [routerLink]=""
                                                        *ngIf="cirHistory.docFilePathAOD && cirHistory.circularContentId !== parentContentId"
                                                        (click)="viewDocumentsV2( cirHistory.docFilePathAOD, cirHistory.mappingId, 'ENG', 'History')">View</a>
                                                    <p *ngIf="cirHistory.cirRemarks" class="noti-superseded"> {{cirHistory.cirRemarks}}</p>    
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- <div class="page-header">
    <div class="container-fluid">
        <div class="row">
            <h5 style="font-size: 14px;">Home <span style="color: #E19F20;"> >> </span>{{taxName}} {{selectedTab}} <span style="color: #E19F20;"> >> </span> History</h5>
            <div class="col-8 wrapTitleUnderline" *ngIf="selectedTab === 'Notifications'">
                <h4>Notification History</h4>
            </div>
            <div class="col-8 wrapTitleUnderline" *ngIf="selectedTab === 'Circulars'">
                <h4>Circulars History</h4>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div #readingArea class="content-col col-lg-12 col-md-12" id="readingArea">
                <div class="search-view">
                    <div class="table-responsive">
                        <table class="table table-hover" *ngIf="selectedTab === 'Notifications'">
                            <tbody>
                                <tr>
                                    <th style="text-align: center;width: 10%;">Number</th>
                                    <th style="text-align: center;width: 10%;">Date</th>
                                    <th style="text-align: center;width: 50%;">Subject</th>
                                    <th style="text-align: center;width: 10%;">Download </th>
                                    <th style="text-align: center;width: 30%;">Updated Notifications (As on Date) </th>
                                </tr>
                                <tr *ngFor="let history of notificationHistoryList, let i=index">
                                    <td class="text-start"
                                        [ngClass]="{ 'notification-his':parentId === history.id || notificationId === history.id}">
                                        {{history.notificationNo}}</td>
                                    <td class="date"
                                        [ngClass]="{ 'notification-his':parentId === history.id || notificationId === history.id}">
                                        {{history.notificationDt| date: 'dd/MM/yyyy'}}</td>
                                    <td class="text-start"
                                        [ngClass]="{ 'notification-his':parentId === history.id || notificationId === history.id}">
                                        {{history.notificationName}} </td>
                                    <td style="text-align: center;"><a class="notification-view" [routerLink]="" (click)="downloadNotification(history.id, 'ENG')"></a><i
                                                class="fas fa-download"></i>
                                    </td>
                                    <td style="text-align: center;"><a *ngIf="parentId !== history.id" class="notification-view" [routerLink]="" (click)="viewDocuments( history.docFilePathAOD)">View</a></td>
                                </tr> 

                            </tbody>
                        </table>

                        <table class="table table-hover" *ngIf="selectedTab === 'Circulars'">
                            <tbody>
                                <tr>
                                    <th style="text-align: center;width: 10%;">Number</th>
                                    <th style="text-align: center;width: 10%;">Date</th>
                                    <th style="text-align: center;width: 50%;">Subject</th>
                                    <th style="text-align: center;width: 10%;">Download </th>
                                    <th style="text-align: center;width: 30%;">Updated Notifications (As on Date) </th>
                                </tr>
                                <tr *ngFor="let history of circularHistoryList, let i=index">
                                    <td class="text-start"
                                        [ngClass]="{ 'notification-his':parentId === history.id || notificationId === history.id}">
                                        {{history.circularNo}}</td>
                                    <td class="date"
                                        [ngClass]="{ 'notification-his':parentId === history.id || notificationId === history.id}">
                                        {{history.issueDt| date: 'dd/MM/yyyy'}}</td>
                                    <td class="text-start"
                                        [ngClass]="{ 'notification-his':parentId === history.id || notificationId === history.id}">
                                        {{history.circularName}} </td>
                                    <td style="text-align: center;"><a class="notification-view" [routerLink]="" (click)="downloadCircular(history.id, 'ENG')"><i
                                                class="fas fa-download"></i></a>
                                    </td>
                                    <td style="text-align: center;"><a *ngIf="parentId !== history.id" class="notification-view" [routerLink]="" (click)="viewDocuments( history.docFilePath)">View</a></td>
                                </tr> 

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->