
<script src="content/js/suneditor.min.js"></script>
<script src="https://cdn.jsdelivr.net/npm/suneditor@2.41.3/src/plugins/index.js"></script>

<div style="width:100%">

<button class="btn"  type="button" (click)="save()">Save</button>
<textarea #mydiv id="sample" style="width:100%;min-height: 400px;font-family: Poppins;"></textarea>
</div>


