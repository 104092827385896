import * as dayjs from 'dayjs';
import { ICbicAlliedActDtls } from '../cbic-allied-act-dtls/cbic-allied-act-dtls.model';
import { ICbicTaxMst } from '../cbic-tax-mst/cbic-tax-mst.model';

export interface ICbicAlliedActMst {
  id?: number;
  content_id?: number | null;
  content_language?: string | null;
  is_active?: string | null;
  created_dt?: dayjs.Dayjs | null;
  updated_dt?: dayjs.Dayjs | null;
  is_amended?: string | null;
  is_omitted?: string | null;
  parent_id?: number | null;
  order_id?: number | null;
  version_no?: number | null;
  created_by?: number | null;
  updated_by?: number | null;
  /* tax_id?: number | null; */
  allied_act_name?: string | null;
  allied_act_name_hi?: string | null;
  allied_act_dt?: dayjs.Dayjs | null;
  issue_dt?: dayjs.Dayjs | null;
  tax?: ICbicTaxMst | null;
}

export class CbicAlliedActMst implements ICbicAlliedActMst {
  constructor(
    public id?: number,
    public content_id?: number | null,
    public content_language?: string | null,
    public is_active?: string | null,
    public created_dt?: dayjs.Dayjs | null,
    public updated_dt?: dayjs.Dayjs | null,
    public is_amended?: string | null,
    public is_omitted?: string | null,
    public parent_id?: number | null,
    public order_id?: number | null,
    public version_no?: number | null,
    public created_by?: number | null,
    public updated_by?: number | null,
    public tax_id?: number | null,
    public allied_act_name?: string | null,
    public allied_act_name_hi?: string | null,
    public allied_act_dt?: dayjs.Dayjs | null,
    public issue_dt?: dayjs.Dayjs | null,
    public tax?: ICbicTaxMst | null
  ) {}
}

export function getCbicAlliedActMstIdentifier(cbicAlliedActMst: ICbicAlliedActMst): number | undefined {
  return cbicAlliedActMst.id;
}
