import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CbicRuleSectionMstService } from 'app/entities/cbic-rule-section-mst/service/cbic-rule-section-mst.service';
import { CbicRuleSectionMst, ICbicRuleSectionMst } from 'app/entities/cbic-rule-section-mst/cbic-rule-section-mst.model';
import { CbicRuleMstService } from 'app/entities/cbic-rule-mst/service/cbic-rule-mst.service';
import { CbicRuleMst } from 'app/entities/cbic-rule-mst/cbic-rule-mst.model';
import { data } from 'jquery';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { TranslateService } from '@ngx-translate/core';


@Injectable({ providedIn: 'root' })
export class ContentPageRoutingRulesResolveService implements Resolve<ICbicRuleSectionMst> {
  constructor(
    protected service: CbicRuleSectionMstService, 
    protected router: Router,
    protected ruleMstService : CbicRuleMstService,
    private sessionStorage: SessionStorageService,
    private localStorage: LocalStorageService,
    private translateService: TranslateService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ICbicRuleSectionMst> | Observable<never> {
    const sectionId = route.params['ruleSectionId'];
    const taxId = route.params['taxId'];
    const language = route.params['language']
    this.sessionStorage.store('locale',language)
    const localLang = this.localStorage.retrieve('language')
    if(localLang){
      this.sessionStorage.store('locale',localLang)
      this.translateService.use(localLang);
    }

    if(taxId === "1000004"){
        return this.ruleMstService.find(sectionId).pipe(
          mergeMap((ruleMst : HttpResponse<CbicRuleMst>) =>
          {
            if(ruleMst.body){
              return of(ruleMst.body);
            }else {
              this.router.navigate(['404']);
              return EMPTY;
            }
          })
        );
    }else{
      if (sectionId) {
        return this.service.find(sectionId).pipe(
          mergeMap((cbicRuleSectionMst: HttpResponse<CbicRuleSectionMst>) => {
            if (cbicRuleSectionMst.body) {
               //alert(cbicRuleSectionMst.body)
              return of(cbicRuleSectionMst.body);
            } else {
              this.router.navigate(['404']);
              return EMPTY;
            }
          })
        );
      }
    }
   
    return of(new CbicRuleSectionMst());
  }
}
