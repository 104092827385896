

export interface ICbicSearchOutput {
    documentName?: string | null;
    taxType?: string | null;
    documentType?: string | null;
    contentName?: string | null;
    referenceNumber?: string | null;
    lastUpdateDt?: string | null;
    chapterId? : string | null,
    sectionId? : string | null,
    formCategory? : string | null,
    ruleId? : string | null,
    taxId? : string | null
  }
  
  export class CbicSearchOutput implements ICbicSearchOutput {
    constructor(
      public documentName?: string | null,
      public taxType?: string | null,
      public documentType?: string | null,
      public contentName?: string | null,
      public referenceNumber?: string | null,
      public lastUpdateDt?: string | null,
      public chapterId? : string | null,
      public sectionId? : string | null,
      public formCategory? : string | null,
      public ruleId? : string | null,
      public taxId? : string | null
    ) {}
  }

  export interface ICbicSearchOutputResult{
    dtos: CbicSearchOutput[] | [];
    totalDocuments: number | null;
  }

  export class CbicSearchOutputResult implements ICbicSearchOutputResult{
    constructor(
      public dtos: CbicSearchOutput[] | [],
      public totalDocuments: number | null,
    ){}
  }