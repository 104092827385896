import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from 'app/layouts/profiles/profile.service';
import * as dayjs from 'dayjs';
import { CbicSearchOutputResult } from '../search-output.model';
import { CbicSearchService } from '../service/cbic-search-service';
@Component({
  selector: 'cbic-search-refine',
  templateUrl: './search-refine.component.html',
  styleUrls: ['./search-refine.component.scss']
})
export class SearchRefineComponent implements OnInit {

  inProduction?: boolean;
  openAPIEnabled?: boolean;
  amendYear?:string;
  amendPeriodFrom?: dayjs.Dayjs;
  amendPeriodTo?:dayjs.Dayjs;
  @Output()
  refineResultChanged = new EventEmitter<{refineResult:boolean,cbicOutputResult:CbicSearchOutputResult}>();
  cbicOutputResult?:CbicSearchOutputResult;
  error:any={isError:false,errorMessage:''};

  constructor(
    private router: Router,
    private profileService: ProfileService,
    private service:CbicSearchService,
  ) { }

  ngOnInit(): void {
    
    this.profileService.getProfileInfo().subscribe(profileInfo => {
      this.inProduction = profileInfo.inProduction;
      this.openAPIEnabled = profileInfo.openAPIEnabled;
    });
  }

  applyRefineParameter():void{
    alert(this.amendYear)
    alert(this.amendPeriodFrom)
    alert(this.amendPeriodTo)
    if(this.amendYear){
      this.service.cbicSearchInput.amendYear = this.amendYear;
    }
    // if(this.amendPeriodFrom && this.amendPeriodTo){
    //   this.service.cbicSearchInput.amendPeriodFrom = this.amendPeriodFrom;
    //   this.service.cbicSearchInput.amendPeriodTo=this.amendPeriodTo;
    // }
    this.service.searchBasedOnRefineParam().subscribe(
      (res: HttpResponse<CbicSearchOutputResult>) => {
        this.cbicOutputResult = res.body ?? new CbicSearchOutputResult([], null); 
        this.refineResultChanged.emit({refineResult:true,cbicOutputResult:this.cbicOutputResult});
    });
  }

  applyResetRefineParameter():void{
    alert(this.amendYear)
    alert(this.amendPeriodFrom)
    alert(this.amendPeriodTo)
    this.amendYear=undefined;
    this.amendPeriodFrom=undefined;
    this.amendPeriodTo=undefined;
    this.service.cbicSearchInput.amendYear = null;
    this.service.cbicSearchInput.amendPeriodFrom = null;
    this.service.cbicSearchInput.amendPeriodTo = null;
    this.service.searchBasedOnRefineParam().subscribe(
      (res: HttpResponse<CbicSearchOutputResult>) => {
        this.cbicOutputResult = res.body ?? new CbicSearchOutputResult([], null); 
        this.refineResultChanged.emit({refineResult:true,cbicOutputResult:this.cbicOutputResult});
    });
  }

  compareTwoDates():void{

    // if(new Date(this.amendPeriodFrom?.format('DD/MM/YYYY'))<new Date(this.form.controls['date_start'].value)){
    const periodFrom = dayjs(this.amendPeriodFrom);
    const periodTo =  dayjs(this.amendPeriodTo);
    alert(periodFrom.format('DD/MM/YYYY') + " " +   periodTo.format('DD/MM/YYYY'))
    if(periodTo.diff(periodFrom) < 1){
      alert('Please select valid period From-to date')
      this.amendPeriodTo=undefined;
    }
 }

}
