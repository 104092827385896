<div class="search-view">
    <div class="table-responsive" *ngIf="dataType ==='Act'">
        <table class="table table-hover">
            <tbody>
                <tr>
                    <th>{{dataType}} Name</th>
                    <th cbicTranslate="download">Download</th>
                </tr>

                <tr *ngFor="let dataLine of dataList, let i=index">
                    <td class="search-link"><a [routerLink]=""
                            (click)="openForm( dataLine.id,dataLine.dataType)">{{dataLine.contentName}}</a></td>

                    <td class="search-link" style="text-align: center;"><a [routerLink]=""
                            (click)="downloadForm(dataLine.id,dataLine.dataType)"><i class="fas fa-download"></i></a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="table-responsive" *ngIf="dataType ==='Rules'">
        <table class="table table-hover">
            <tbody>
                <tr>
                    <th cbicTranslate="ruleName">Rule Name</th>
                    <th cbicTranslate="download">Download</th>
                </tr>

                <tr *ngFor="let dataLine of dataList, let i=index">
                    <td class="search-link"><a [routerLink]=""
                            (click)="openForm( dataLine.id,'Rules')">{{dataLine.ruleDocName}}</a></td>

                    <td class="search-link" style="text-align: center;"><a [routerLink]=""
                            (click)="downloadForm(dataLine.id,'Rules')"><i class="fas fa-download"></i></a></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="table-responsive" *ngIf="dataType ==='OTHR'">
        <table class="table table-hover">
            <tbody>
                <tr>
                    <th style="width: 90%;" cbicTranslate="documentName">Document Name </th>
                    <th style="width: 8%;" cbicTranslate="download">Download</th>
                </tr>
                <tr *ngFor="let document of documentList, let i=index">
                    <td class="search-link" style="text-align: justify !important;">{{document.documentName}}</td>

                    <td class="search-link">
                       
                                <a [routerLink]="" *ngIf="document.docFilePath"
                                        (click)="viewDocuments( document.docFilePath)">English</a>
                           
                               <a [routerLink]="" (click)="downloadDocuments(document.id, 'ENG')"
                                        *ngIf="document.docFilePath"> <i class="fa fa-download"
                                            aria-hidden="true"></i></a>
                            
                         
                                 <a [routerLink]="" *ngIf="document.docFilePathHi"
                                        (click)="viewDocuments( document.docFilePathHi)">हिन्दी</a>
                           
                                <a [routerLink]="" (click)="downloadDocuments(document.id, 'HINDI')"
                                        *ngIf="document.docFilePathHi"><i class="fa fa-download"
                                            aria-hidden="true"></i></a>
                      


                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>