import * as dayjs from 'dayjs';

export interface ICbicAssociateIpDtls {
  id?: number;
  associateName?: string | null;
  ipAddress?: string | null;
  isActive?: string | null;
  createdDt?: dayjs.Dayjs | null;
  createdBy?: number | null;
  updatedDt?: dayjs.Dayjs | null;
  updatedBy?: number | null;
}

export class CbicAssociateIpDtls implements ICbicAssociateIpDtls {
  constructor(
    public id?: number,
    public associateName?: string | null,
    public ipAddress?: string | null,
    public isActive?: string | null,
    public createdDt?: dayjs.Dayjs | null,
    public createdBy?: number | null,
    public updatedDt?: dayjs.Dayjs | null,
    public updatedBy?: number | null
  ) {}
}

export function getCbicAssociateIpDtlsIdentifier(cbicAssociateIpDtls: ICbicAssociateIpDtls): number | undefined {
  return cbicAssociateIpDtls.id;
}
