import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { Search } from 'app/core/request/request.model';
import { ICbicInstructionMst, getCbicInstructionMstIdentifier } from '../cbic-instruction-mst.model';
import { RuleMstResponse } from 'app/entities/cbic-rule-mst/cbic-rule-mst.model';

export type EntityResponseType = HttpResponse<ICbicInstructionMst>;
export type EntityArrayResponseType = HttpResponse<ICbicInstructionMst[]>;

@Injectable({ providedIn: 'root' })
export class CbicInstructionMstService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/cbic-instruction-msts');
  public resourceSearchUrl = this.applicationConfigService.getEndpointFor('api/_search/cbic-instruction-msts');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(cbicInstructionMst: ICbicInstructionMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicInstructionMst);
    return this.http
      .post<ICbicInstructionMst>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(cbicInstructionMst: ICbicInstructionMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicInstructionMst);
    return this.http
      .put<ICbicInstructionMst>(`${this.resourceUrl}/${getCbicInstructionMstIdentifier(cbicInstructionMst) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(cbicInstructionMst: ICbicInstructionMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicInstructionMst);
    return this.http
      .patch<ICbicInstructionMst>(`${this.resourceUrl}/${getCbicInstructionMstIdentifier(cbicInstructionMst) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICbicInstructionMst>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicInstructionMst[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  search(req: Search): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicInstructionMst[]>(this.resourceSearchUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  addCbicInstructionMstToCollectionIfMissing(
    cbicInstructionMstCollection: ICbicInstructionMst[],
    ...cbicInstructionMstsToCheck: (ICbicInstructionMst | null | undefined)[]
  ): ICbicInstructionMst[] {
    const cbicInstructionMsts: ICbicInstructionMst[] = cbicInstructionMstsToCheck.filter(isPresent);
    if (cbicInstructionMsts.length > 0) {
      const cbicInstructionMstCollectionIdentifiers = cbicInstructionMstCollection.map(
        cbicInstructionMstItem => getCbicInstructionMstIdentifier(cbicInstructionMstItem)!
      );
      const cbicInstructionMstsToAdd = cbicInstructionMsts.filter(cbicInstructionMstItem => {
        const cbicInstructionMstIdentifier = getCbicInstructionMstIdentifier(cbicInstructionMstItem);
        if (cbicInstructionMstIdentifier == null || cbicInstructionMstCollectionIdentifiers.includes(cbicInstructionMstIdentifier)) {
          return false;
        }
        cbicInstructionMstCollectionIdentifiers.push(cbicInstructionMstIdentifier);
        return true;
      });
      return [...cbicInstructionMstsToAdd, ...cbicInstructionMstCollection];
    }
    return cbicInstructionMstCollection;
  }

  fetchInstructionByYearCategory(req?:any):Observable<EntityArrayResponseType>{
    const options = createRequestOption(req);
    return this.http
      .get<ICbicInstructionMst[]>(`${this.resourceUrl}/fetch-instructions-year-category`, { params: options,  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  fetchIntructionCount(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicInstructionMst[]>(`${this.resourceUrl}/instuction-count`, { params: options, observe: 'response' })
  }

  fetchInstructionCategory(taxId:number):Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicInstructionMst[]>(`${this.resourceUrl}/instuction-category/${taxId}`, {  observe: 'response' });
  }

  download(id: number, language: string):Observable<RuleMstResponse>{
    return this.http.get<RuleMstResponse>(`${this.resourceUrl}/download/${id}/${language}`);
  }

  protected convertDateFromClient(cbicInstructionMst: ICbicInstructionMst): ICbicInstructionMst {
    return Object.assign({}, cbicInstructionMst, {
      createdDt: cbicInstructionMst.createdDt?.isValid() ? cbicInstructionMst.createdDt.toJSON() : undefined,
      updatedDt: cbicInstructionMst.updatedDt?.isValid() ? cbicInstructionMst.updatedDt.toJSON() : undefined,
      instructionDt: cbicInstructionMst.instructionDt?.isValid() ? cbicInstructionMst.instructionDt.toJSON() : undefined,
      issueDt: cbicInstructionMst.issueDt?.isValid() ? cbicInstructionMst.issueDt.toJSON() : undefined,
      amendDt: cbicInstructionMst.amendDt?.isValid() ? cbicInstructionMst.amendDt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDt = res.body.createdDt ? dayjs(res.body.createdDt) : undefined;
      res.body.updatedDt = res.body.updatedDt ? dayjs(res.body.updatedDt) : undefined;
      res.body.instructionDt = res.body.instructionDt ? dayjs(res.body.instructionDt) : undefined;
      res.body.issueDt = res.body.issueDt ? dayjs(res.body.issueDt) : undefined;
      res.body.amendDt = res.body.amendDt ? dayjs(res.body.amendDt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((cbicInstructionMst: ICbicInstructionMst) => {
        cbicInstructionMst.createdDt = cbicInstructionMst.createdDt ? dayjs(cbicInstructionMst.createdDt) : undefined;
        cbicInstructionMst.updatedDt = cbicInstructionMst.updatedDt ? dayjs(cbicInstructionMst.updatedDt) : undefined;
        cbicInstructionMst.instructionDt = cbicInstructionMst.instructionDt ? dayjs(cbicInstructionMst.instructionDt) : undefined;
        cbicInstructionMst.issueDt = cbicInstructionMst.issueDt ? dayjs(cbicInstructionMst.issueDt) : undefined;
        cbicInstructionMst.amendDt = cbicInstructionMst.amendDt ? dayjs(cbicInstructionMst.amendDt) : undefined;
      });
    }
    return res;
  }
}
