import * as dayjs from 'dayjs';
import { ICbicTaxMst } from 'app/entities/cbic-tax-mst/cbic-tax-mst.model';

export interface ICbicRegulationDocMst {
  id?: number;
  contentId?: number | null;
  contentLanguage?: string | null;
  isActive?: string | null;
  createdDt?: dayjs.Dayjs | null;
  updatedDt?: dayjs.Dayjs | null;
  isAmended?: string | null;
  isOmitted?: string | null;
  parentId?: number | null;
  orderId?: number | null;
  versionNo?: number | null;
  createdBy?: number | null;
  updatedBy?: number | null;
  regulationDocNo?: string | null;
  regulationDocName?: string | null;
  regulationCategory?: string | null;
  issueDt?: dayjs.Dayjs | null;
  amendDt?: dayjs.Dayjs | null;
  tax?: ICbicTaxMst | null;
  contentFilePath?:string|null;
  contentHtmlFilePath?:string|null;
  regulationDocNoHi?: string | null;
  regulationDocNameHi?: string | null;
  regulationCategoryHi?: string | null;
  contentFilePathHi?:string | null;
  contentHtmlFilePathHi?:string | null;
  contentFileNameHi?:string | null;
  contentHtmlFileNameHi?:string | null;
}
export class CbicRegulationDocMst implements ICbicRegulationDocMst {
  constructor(
    public id?: number,
    public contentId?: number | null,
    public contentLanguage?: string | null,
    public isActive?: string | null,
    public createdDt?: dayjs.Dayjs | null,
    public updatedDt?: dayjs.Dayjs | null,
    public isAmended?: string | null,
    public isOmitted?: string | null,
    public parentId?: number | null,
    public orderId?: number | null,
    public versionNo?: number | null,
    public createdBy?: number | null,
    public updatedBy?: number | null,
    public regulationDocNo?: string | null,
    public regulationDocName?: string | null,
    public regulationCategory?: string | null,
    public issueDt?: dayjs.Dayjs | null,
    public amendDt?: dayjs.Dayjs | null,
    public tax?: ICbicTaxMst | null,
    public contentFilePath?:string|null,
    public contentHtmlFilePath?:string|null,
    public regulationDocNoHi?: string | null,
    public regulationDocNameHi?: string | null,
    public regulationCategoryHi?: string | null,
    public contentFilePathHi?:string | null,
    public contentHtmlFilePathHi?:string | null,
    public contentFileNameHi?:string | null,
    public contentHtmlFileNameHi?:string | null

  ) {}
}

export function getCbicRegulationDocMstIdentifier(cbicRegulationDocMst: ICbicRegulationDocMst): number | undefined {
  return cbicRegulationDocMst.id;
}
