import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CbicNotificationMstRoutingResolveService } from 'app/entities/cbic-notification-mst/route/cbic-notification-mst-routing-resolve.service';
import { NotificationHistoryComponent } from './notification-history.component';

const routes: Routes = [];

@NgModule({
  imports: [
    /* jhipster-needle-add-search-module - JHipster will add search modules here */
    RouterModule.forChild([
      {
        path: 'explore-history',
        component: NotificationHistoryComponent,
        data: {
          pageTitle: 'content.title',
        }
      },
      /* jhipster-needle-add-search-route - JHipster will add search routes here */
    ]),
  ],
})
export class NotificationHistoryRoutingModule { }
