import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ProfileService } from 'app/layouts/profiles/profile.service';
import 'suneditor/dist/css/suneditor.min.css';
import suneditor from 'suneditor';
import plugins from 'suneditor/src/plugins'
import link from 'suneditor/src/plugins/dialog/link';
import list from 'suneditor/src/plugins/submenu/list';
import {font} from 'suneditor/src/plugins';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import SunEditorCore from "suneditor/src/lib/core";
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { EntityResponseType } from 'app/search-page/service/cbic-search-service';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { SunEditorInput } from 'app/suneditor/suneditor.model';
declare let hideLanguageSwitch : any;

@Component({
  selector: 'cbic-suneditor',
  templateUrl: './suneditor.component.html',
  styleUrls: ['./suneditor.component.scss']
})
export class SuneditorComponent implements OnInit {
  inProduction?: boolean;
  openAPIEnabled?: boolean;
  sectionToBeOpened:any;
  sectionContentDiv:any;
  data: any = `<p>Hello, world!</p>`;
  @ViewChild('mydiv', { static: false })  mydiv?: ElementRef;
  isSaving = false;
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/sun-editor-save');
  
  private sunEditorInput?:SunEditorInput;
  private suneditor1!:SunEditorCore;
  constructor(private profileService: ProfileService,
    protected activatedRoute: ActivatedRoute,
   private http : HttpClient,
   private sanitizer: DomSanitizer,
   private applicationConfigService: ApplicationConfigService) { }
  
  ngOnInit(): void {
    hideLanguageSwitch();
    this.profileService.getProfileInfo().subscribe(profileInfo => {
      this.inProduction = profileInfo.inProduction;
      this.openAPIEnabled = profileInfo.openAPIEnabled;

      
    });
    
    this.activatedRoute.data.subscribe(({ cbicactsectionmst }) => {
      this.sectionToBeOpened = cbicactsectionmst;
      this.http
        .get('content/html/' + String(this.sectionToBeOpened.contentFilePath),
            { responseType: 'text' })
        .subscribe(data => {
         
          (<HTMLInputElement>document.getElementById("sample")).innerHTML=data;
          this.suneditor1=suneditor.create('sample', {
            plugins: {
              link,
              
            },
            buttonList: [
               ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat', 'outdent', 'indent'],
              ['-right',  'link', 'fullScreen', 'codeView', 'preview', 'print'],
              // (min-width: 992)
              
          ]
              
          });
          
          
          
            
        }
      )
    });
    
  }

  save():void{
   
    const savedata=this.suneditor1.getContents(true);
    this.isSaving = false;
    this.sunEditorInput=new SunEditorInput(savedata,this.sectionToBeOpened.contentFilePath);
    this.http
      .post(this.resourceUrl, this.sunEditorInput, { observe: 'response' }).subscribe(
        data =>{
          alert("html saved successfully");
        }
      );
      
   }

   

}
