import { Injectable} from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { CbicSearchOutput, CbicSearchOutputResult, ICbicSearchOutputResult } from '../search-output.model';
import { CbicSearchInput} from 'app/searchbar/searchbar.model';
import { createRequestOption } from 'app/core/request/request-util';


export type EntityResponseType = HttpResponse<ICbicSearchOutputResult>;
export type EntityArrayResponseType = HttpResponse<ICbicSearchOutputResult[]>;

@Injectable({ providedIn: 'root' })
export class CbicSearchService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/cbic-search');
  public resourceUrlNonTarrif = this.applicationConfigService.getEndpointFor('api/cbic-search/custom-non-tarrif');

  cbicSearchInput!: CbicSearchInput;
  startIndex = 0;
  rows = 0;
  pageNumber = 1;
  cbicSearchOutputResults: CbicSearchOutputResult = new CbicSearchOutputResult([], null);
  
  backToSearchResult = 'N';

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) { }

  getNumberOfPages(totalDocuments: any): number {
    return Number(totalDocuments) / this.rows;
  }

  searchInSection(cbicSearchInput: CbicSearchInput): Observable<EntityResponseType> {
    const copy = cbicSearchInput;
    this.cbicSearchInput = cbicSearchInput;
    if (this.cbicSearchInput.rows) {
      this.rows = Number(this.cbicSearchInput.rows);
    }
    return this.http
      .post<ICbicSearchOutputResult>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateArrayFromServer(res)));
  }
  searchForPagination(index: any): Observable<EntityResponseType> {
    const copy = this.cbicSearchInput;
    copy.startIndex = (Number(index)) * this.rows;
    this.pageNumber = Number(index) + 1;
    return this.http
      .post<ICbicSearchOutputResult>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateArrayFromServer(res)));
  }

 

  searchInSectionWithRecord(recordValue: any): Observable<EntityResponseType> {
    this.cbicSearchInput.rows = Number(recordValue);
    this.cbicSearchInput.startIndex = 0;
    this.rows = Number(this.cbicSearchInput.rows);
    this.pageNumber = 1;
    return this.http
      .post<ICbicSearchOutputResult>(this.resourceUrl, this.cbicSearchInput, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateArrayFromServer(res)));
  }

  

  searchBasedOnRefineParam(): Observable<EntityResponseType>{
    return this.http
      .post<ICbicSearchOutputResult>(this.resourceUrl, this.cbicSearchInput, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateArrayFromServer(res)));
  }

  //   protected convertDateFromClient(cbicSearchInput: ICbicSearchInput): ICbicSearchInput {
  //     return Object.assign({}, cbicSearchInput, {
  //       createdDt: cbicSearchInput.createdDt?.isValid() ? cbicTaxMst.createdDt.toJSON() : undefined,
  //     });
  //   }

  //   protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
  //     if (res.body) {
  //       res.body.createdDt = res.body.createdDt ? dayjs(res.body.createdDt) : undefined;
  //       res.body.updatedDt = res.body.updatedDt ? dayjs(res.body.updatedDt) : undefined;
  //     }
  //     return res;
  //   }

  protected convertDateArrayFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.dtos.forEach((cbicSearchOutput: CbicSearchOutput) => {
        cbicSearchOutput.lastUpdateDt = cbicSearchOutput.lastUpdateDt ? dayjs(cbicSearchOutput.lastUpdateDt).format('DD/MM/YYYY') : undefined;
      });
    }
    return res;
  }
}
