
export interface ICbicCoreError {
    excpetionId ?: string | null,
    exceptionMsg ?: string | null
  }
  
  export class CbicCoreError implements ICbicCoreError {
    constructor(
        public excpetionId ?: string | null,
        public exceptionMsg ?: string | null
    ) {}
  }