import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataUtils } from 'app/core/util/data-util.service';
import { ICbicCircularMst } from 'app/entities/cbic-circular-mst/cbic-circular-mst.model';
import { CbicCircularMstService } from 'app/entities/cbic-circular-mst/service/cbic-circular-mst.service';
import { CbicContentHistoryMapDtlsService } from 'app/entities/cbic-content-history-map-dtls/service/cbic-content-history-map-dtls.service';
import { ICbicContentMap } from 'app/entities/cbic-content-map/cbic-content-map.model';
import { CbicContentMapService } from 'app/entities/cbic-content-map/service/cbic-content-map.service';
import { ICbicNotificationMst } from 'app/entities/cbic-notification-mst/cbic-notification-mst.model';
import { CbicNotificationMstService } from 'app/entities/cbic-notification-mst/service/cbic-notification-mst.service';
import { RuleMstResponse } from 'app/entities/cbic-rule-mst/cbic-rule-mst.model';
import { saveAs} from 'file-saver';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
declare let hideLanguageSwitch : any;

@Component({
  selector: 'cbic-notification-history',
  templateUrl: './notification-history.component.html',
  styleUrls: ['./notification-history.component.scss']
})
export class NotificationHistoryComponent implements OnInit {

  taxId: any;
  notificationId: any;
  parentId: any;
  notificationHistoryList: any;
  circularHistoryList:any;
  selectedTab: any;
  taxName: any;
  historyList: any;
  parentContentId: any;
  childContentId: any;
  baseContentId: any;
  historyNotFound = false;
  tabName: any;
  storageLanguage: any
  historyListCircular: any;

  constructor(protected activatedRoute: ActivatedRoute,
    protected cbicNotificationMstService: CbicNotificationMstService,
    protected cbicCircularMstService: CbicCircularMstService,
    private http : HttpClient,
    private dataUtils:DataUtils,
    protected cbicHistory: CbicContentHistoryMapDtlsService,
    private router : Router,
    private sessionStorage: SessionStorageService,
    private localStorage: LocalStorageService,
    private translateService: TranslateService) { 

      this.notificationId = activatedRoute.snapshot.params['id'];
    this.taxId = activatedRoute.snapshot.params['taxId'];
    this.parentId = activatedRoute.snapshot.params['parentId'];
    this.selectedTab = activatedRoute.snapshot.params['selectedTab'];
    }

  ngOnInit(): void {
    hideLanguageSwitch();
    const localLang = this.localStorage.retrieve('language')
    if(localLang){
      this.sessionStorage.store('locale',localLang)
      this.translateService.use(localLang);
    }

    this.fetchNotificationHistoryData(this.notificationId, 'Notification')
    this.storageLanguage = this.sessionStorage.retrieve('locale')
  
    if(this.storageLanguage === 'hi'){
      if(this.selectedTab === 'Notifications'){
        this.tabName = "सूचनाएं"
      }
      if(this.selectedTab === 'Circulars'){
        this.tabName = "परिपत्र"
      }
    } else{
      this.tabName = this.selectedTab
    }
  }

  fetchNotificationHistoryData(contentId: any, taxId: any): void{
    this.baseContentId = Number(contentId)
    if(this.selectedTab === "Notifications") {

      this.cbicHistory 
        .fetchNotificationByChildId(contentId, taxId)
        .subscribe(
          (res: HttpResponse<ICbicNotificationMst[]>) => {
            this.notificationHistoryList = res.body;
            if(this.notificationHistoryList === null ||this.notificationHistoryList.length === 0){
              this.historyNotFound = true
            }
          }
        )
    }

    if(this.selectedTab === "Circulars") {
      this.cbicHistory 
        .fetchCircularForHistory(contentId, taxId)
        .subscribe(
          (res: HttpResponse<ICbicCircularMst[]>) => {
            this.circularHistoryList = res.body;
            if(this.circularHistoryList === null ||this.circularHistoryList.length === 0){
              this.historyNotFound = true
            }
          }
        )
    } 
  }

  viewDocuments(path:any):void{
    this.http.get('content/pdf/' + String(path),
        { responseType: 'text' })
        .subscribe(res => {
          const json=JSON.parse(res);
          const fileURL = 'data:application/pdf;base64,'+String(json.data);
          this.dataUtils.openFile(String(json.data), "application/pdf");
        }
        );
    return;
  }

  
  fetchNotificationHistory(id:any):void {
    this.parentContentId = id;
    this.cbicNotificationMstService
      .fetchNotificationHistory(id)
      .subscribe(
        (res: HttpResponse<ICbicNotificationMst[]>) =>{
          this.historyList = res.body
        }
      )
  }

  fetchCircularHistory(id:any):void {
    this.parentContentId = id;
    this.cbicCircularMstService
      .fetchCircularHistory(id)
      .subscribe(
        (res: HttpResponse<ICbicCircularMst[]>) =>{
          this.historyListCircular = res.body
        }
      )
  }

  downloadDocuments(id: any, language: any): void {
    if(this.selectedTab === "Notifications") {
      this.cbicNotificationMstService
        .downloadNotification(Number(id), language).subscribe(
          (res: RuleMstResponse) => {
            const data = res.data;
            const byteCharacters = atob(String(data));
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            saveAs(blob, String(res.fileName));
          }
        );
    }
    
  }

  viewDocumentsV2(notificationPath:any, id:any, type:any, selectedTab:any):void{
    const link = this.router.serializeUrl(this.router.createUrlTree(['view-pdf/'+ String(id)+ '/'+ String(type) +'/'+String(selectedTab)]));
    window.open(link, '_blank');
  } 
}
