import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

import { ApplicationConfigService } from '../config/application-config.service';
import { Login } from 'app/login/login.model';

type JwtToken = {
	id_token: string;
};

@Injectable({ providedIn: 'root' })
export class AuthServerProvider {
	constructor(
		private http: HttpClient,
		private $localStorage: LocalStorageService,
		private $sessionStorage: SessionStorageService,
		private applicationConfigService: ApplicationConfigService
	) { }

	getToken(): string {
		const tokenInLocalStorage: string | null = this.$localStorage.retrieve('authenticationToken');
		const tokenInSessionStorage: string | null = this.$sessionStorage.retrieve('authenticationToken');
		const homePageToken: string | null = this.$localStorage.retrieve('authenticationTokenHomePage');
		return tokenInLocalStorage ?? tokenInSessionStorage ?? homePageToken ?? '';
	}

	login(credentials: Login): Observable<void> {
		return this.http
			.post<JwtToken>(this.applicationConfigService.getEndpointFor('api/authenticate'), credentials)
			.pipe(map(response => this.authenticateSuccess(response, credentials.rememberMe)));
	}
	
	loginHomePageToken(): Observable<void> {
		return this.http
			.post<JwtToken>(this.applicationConfigService.getEndpointFor('api/authenticate-token'), null)
			.pipe(map(response => this.authenticateSuccessHomePage(response)));
	}

	logout(): Observable<void> {
		return new Observable(observer => {
			this.$localStorage.clear('authenticationToken');
			this.$sessionStorage.clear('authenticationToken');
			observer.complete();
		});
	}

	private authenticateSuccess(response: JwtToken, rememberMe: boolean): void {
		const jwt = response.id_token;
		if (rememberMe) {
			this.$localStorage.store('authenticationToken', jwt);
			this.$sessionStorage.clear('authenticationToken');
		} else {
			this.$sessionStorage.store('authenticationToken', jwt);
			this.$localStorage.clear('authenticationToken');
		}
	}

	private authenticateSuccessHomePage(response: JwtToken): void {
		const jwt = response.id_token;
		this.$localStorage.store('authenticationTokenHomePage', jwt);
	}
}
