import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { Search } from 'app/core/request/request.model';
import { ICbicRegulationMst, getCbicRegulationMstIdentifier } from '../cbic-regulation-mst.model';

export type EntityResponseType = HttpResponse<ICbicRegulationMst>;
export type EntityArrayResponseType = HttpResponse<ICbicRegulationMst[]>;

@Injectable({ providedIn: 'root' })
export class CbicRegulationMstService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/cbic-regulation-msts');
  public resourceSearchUrl = this.applicationConfigService.getEndpointFor('api/_search/cbic-regulation-msts');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(cbicRegulationMst: ICbicRegulationMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicRegulationMst);
    return this.http
      .post<ICbicRegulationMst>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  findRegulationList(regulationDocId:number):Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicRegulationMst[]>(`${this.resourceUrl}/fetchRegulations/${regulationDocId}`, {  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }
  findRegulationListByChapterId(regulationChapterId:number):Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicRegulationMst[]>(`${this.resourceUrl}/fetchRegulationsByChapter/${regulationChapterId}`, {  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  update(cbicRegulationMst: ICbicRegulationMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicRegulationMst);
    return this.http
      .put<ICbicRegulationMst>(`${this.resourceUrl}/${getCbicRegulationMstIdentifier(cbicRegulationMst) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(cbicRegulationMst: ICbicRegulationMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicRegulationMst);
    return this.http
      .patch<ICbicRegulationMst>(`${this.resourceUrl}/${getCbicRegulationMstIdentifier(cbicRegulationMst) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICbicRegulationMst>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

 

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicRegulationMst[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  search(req: Search): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicRegulationMst[]>(this.resourceSearchUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  addCbicRegulationMstToCollectionIfMissing(
    cbicRegulationMstCollection: ICbicRegulationMst[],
    ...cbicRegulationMstsToCheck: (ICbicRegulationMst | null | undefined)[]
  ): ICbicRegulationMst[] {
    const cbicRegulationMsts: ICbicRegulationMst[] = cbicRegulationMstsToCheck.filter(isPresent);
    if (cbicRegulationMsts.length > 0) {
      const cbicRegulationMstCollectionIdentifiers = cbicRegulationMstCollection.map(
        cbicRegulationMstItem => getCbicRegulationMstIdentifier(cbicRegulationMstItem)!
      );
      const cbicRegulationMstsToAdd = cbicRegulationMsts.filter(cbicRegulationMstItem => {
        const cbicRegulationMstIdentifier = getCbicRegulationMstIdentifier(cbicRegulationMstItem);
        if (cbicRegulationMstIdentifier == null || cbicRegulationMstCollectionIdentifiers.includes(cbicRegulationMstIdentifier)) {
          return false;
        }
        cbicRegulationMstCollectionIdentifiers.push(cbicRegulationMstIdentifier);
        return true;
      });
      return [...cbicRegulationMstsToAdd, ...cbicRegulationMstCollection];
    }
    return cbicRegulationMstCollection;
  }

  protected convertDateFromClient(cbicRegulationMst: ICbicRegulationMst): ICbicRegulationMst {
    return Object.assign({}, cbicRegulationMst, {
      createdDt: cbicRegulationMst.createdDt?.isValid() ? cbicRegulationMst.createdDt.toJSON() : undefined,
      updatedDt: cbicRegulationMst.updatedDt?.isValid() ? cbicRegulationMst.updatedDt.toJSON() : undefined,
      issueDt: cbicRegulationMst.issueDt?.isValid() ? cbicRegulationMst.issueDt.toJSON() : undefined,
      amendDt: cbicRegulationMst.amendDt?.isValid() ? cbicRegulationMst.amendDt.toJSON() : undefined,
      notificationDt: cbicRegulationMst.notificationDt?.isValid() ? cbicRegulationMst.notificationDt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDt = res.body.createdDt ? dayjs(res.body.createdDt) : undefined;
      res.body.updatedDt = res.body.updatedDt ? dayjs(res.body.updatedDt) : undefined;
      res.body.issueDt = res.body.issueDt ? dayjs(res.body.issueDt) : undefined;
      res.body.amendDt = res.body.amendDt ? dayjs(res.body.amendDt) : undefined;
      res.body.notificationDt = res.body.notificationDt ? dayjs(res.body.notificationDt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((cbicRegulationMst: ICbicRegulationMst) => {
        cbicRegulationMst.createdDt = cbicRegulationMst.createdDt ? dayjs(cbicRegulationMst.createdDt) : undefined;
        cbicRegulationMst.updatedDt = cbicRegulationMst.updatedDt ? dayjs(cbicRegulationMst.updatedDt) : undefined;
        cbicRegulationMst.issueDt = cbicRegulationMst.issueDt ? dayjs(cbicRegulationMst.issueDt) : undefined;
        cbicRegulationMst.amendDt = cbicRegulationMst.amendDt ? dayjs(cbicRegulationMst.amendDt) : undefined;
        cbicRegulationMst.notificationDt = cbicRegulationMst.notificationDt ? dayjs(cbicRegulationMst.notificationDt) : undefined;
      });
    }
    return res;
  }
}
