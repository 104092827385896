<h4 class="d-flex" ><label cbicTranslate="formsCategory"></label>
    <div class="read-area-margin"></div>
</h4>
<div class="nav flex-column nav-pills me-3 wrap-content " id="offcanvas" role="tablist" aria-orientation="vertical"
    id="offcanvas" tabindex="-1" data-bs-keyboard="false" data-bs-backdrop="false">
    <button class="nav-link" id="v-pills-c1-tab" [ngClass]="{'active': form.formCategory === currentCategory}" data-bs-toggle="pill"
        data-bs-target="#v-pills-c1" type="button" 
        *ngFor="let form of formsCategoryList; let i = index" role="tab"
        aria-controls="v-pills-c1" aria-selected="true"
        (click)="fetchFormData(form.id, form.formCategory)">
        <p class="name">{{form.formCategory}} ({{form.count}})</p>
        <!-- <p class="title">{{form.count}}</p> -->
    </button>
</div>