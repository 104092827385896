import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from 'app/layouts/profiles/profile.service';

@Component({
  selector: 'cbic-forms-navigation',
  templateUrl: './forms-navigation.component.html',
  styleUrls: ['./forms-navigation.component.scss']
})
export class FormsNavigationComponent implements OnInit {

  @Input() 
  formsCategoryList:any;
  reading:any;

  @Output() someEvent = new EventEmitter<string>();
  @Output() selectFormEvent = new EventEmitter<{selectformId:any, selectformCategory:any}>();
  
  inProduction?: boolean;
  openAPIEnabled?: boolean;
  currentCategory:any;
  size: any;

  constructor(private router: Router,
    private profileService: ProfileService) { }

  ngOnInit(): void {
    //alert("In Forms Tab");
    this.profileService.getProfileInfo().subscribe(profileInfo => {
      this.inProduction = profileInfo.inProduction;
      this.openAPIEnabled = profileInfo.openAPIEnabled;
    });

    if(!this.reading){
      this.reading=[];
      this.reading.push(true);
    }
  }

  fetchFormData(formId:string, formCategory:any):void{
    this.someEvent.emit(formCategory);
    this.selectFormEvent.emit({selectformId:formId, selectformCategory:formCategory});
    
    this.size = window.innerWidth;
    if (this.size < 600) {
      //alert("in Mobile View");
      window.scrollTo(0, 900);
    }

  }

  setCurrentCategory(category : any):void{
    this.currentCategory = category;
  }

}
