import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataUtils } from 'app/core/util/data-util.service';
import { CbicFormMst, FormResponse } from 'app/entities/cbic-form-mst/cbic-form-mst.model';
import { CbicFormMstService } from 'app/entities/cbic-form-mst/service/cbic-form-mst.service';
import { prototype } from 'events';
import { saveAs } from 'file-saver';
import { FormBuilder } from '@angular/forms';
import { CbicFormSearchInput } from 'app/searchbar/searchbar.model';
import { SessionStorageService } from 'ngx-webstorage';


@Component({
  selector: 'cbic-forms-read',
  templateUrl: './forms-read.component.html',
  styleUrls: ['./forms-read.component.scss']
})
export class FormsReadComponent implements OnInit {

  @Input()
  formByCategory: any;
  formName: any;
  language: any;
  constructor(
    private downloadService: CbicFormMstService,
    private dataUtils: DataUtils,
    protected fb: FormBuilder,
    private sessionStorage: SessionStorageService

  ) {// do nothing.
  }

  ngOnInit(): void {
    this.language = this.sessionStorage.retrieve('locale')
    return;

  }

  setFormName(formByCategory: any): void {
    this.formName = formByCategory;
  }


  downloadForm(formId: any , language: any): void {
    this.downloadService.downloadFormHindiEnglish(Number(formId) , language).subscribe(
      (res: FormResponse) => {
        const data = res.data;
        // this.dataUtils.openFile(String(data),"application/pdf");
        const byteCharacters = atob(String(data));
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        saveAs(blob, String(res.fileName));
      }
    );
  }


  openForm(formId: any): void {
    this.downloadService.downloadForm(Number(formId)).subscribe(
      (res: FormResponse) => {
        const data = res.data;
        if(String (res.fileName).indexOf(".pdf")===-1){
          alert("Preview not available, Please Download")
        }else{
          this.dataUtils.openFile(String(data), "application/pdf");
        }
      }
    );
  }


}
