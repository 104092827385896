import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { Search } from 'app/core/request/request.model';
import { ICbicAlliedActDtls, getCbicAlliedActDtlsIdentifier } from '../cbic-allied-act-dtls.model';
import { RuleMstResponse } from 'app/entities/cbic-rule-mst/cbic-rule-mst.model';

export type EntityResponseType = HttpResponse<ICbicAlliedActDtls>;
export type EntityArrayResponseType = HttpResponse<ICbicAlliedActDtls[]>;

@Injectable({ providedIn: 'root' })
export class CbicAlliedActDtlsService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/cbic-allied-act-dtls');
  public resourceSearchUrl = this.applicationConfigService.getEndpointFor('api/_search/cbic-allied-act-dtls');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

 
  fetchAlliedActByYearName(req?:any):Observable<EntityArrayResponseType>{
    const options = createRequestOption(req);
    return this.http
      .get<ICbicAlliedActDtls[]>(`${this.resourceUrl}/fetchAlliedActByYearName`, { params: options,  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  fetchAlliedActYear(req?:any):Observable<EntityArrayResponseType>{
    const options = createRequestOption(req);
    return this.http
      .get<ICbicAlliedActDtls[]>(`${this.resourceUrl}/fetchAlliedActYear`, { params: options,  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  downloadAlliedActDocument(id: number, language: string):Observable<RuleMstResponse>{
    return this.http.get<RuleMstResponse>(`${this.resourceUrl}/download/${id}/${language}`);
  }

  
  queryCountForAlliedAct(req?: any):  Observable<HttpResponse<{}>> {
    const options = createRequestOption(req);
    return this.http
    .get(`${this.resourceUrl}/alliedActGetcount`, { params: options, observe: 'response' });
  }
 /*  .get<ICbicAlliedActDtls[]>(`${this.resourceUrl}/alliedActGetcount`, { params: options,  observe: 'response' }) */
 
  create(cbicAlliedActDtls: ICbicAlliedActDtls): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicAlliedActDtls);
    return this.http
      .post<ICbicAlliedActDtls>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(cbicAlliedActDtls: ICbicAlliedActDtls): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicAlliedActDtls);
    return this.http
      .put<ICbicAlliedActDtls>(`${this.resourceUrl}/${getCbicAlliedActDtlsIdentifier(cbicAlliedActDtls) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(cbicAlliedActDtls: ICbicAlliedActDtls): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicAlliedActDtls);
    return this.http
      .patch<ICbicAlliedActDtls>(`${this.resourceUrl}/${getCbicAlliedActDtlsIdentifier(cbicAlliedActDtls) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICbicAlliedActDtls>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicAlliedActDtls[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  search(req: Search): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicAlliedActDtls[]>(this.resourceSearchUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  addCbicAlliedActDtlsToCollectionIfMissing(
    cbicAlliedActDtlsCollection: ICbicAlliedActDtls[],
    ...cbicAlliedActDtlsToCheck: (ICbicAlliedActDtls | null | undefined)[]
  ): ICbicAlliedActDtls[] {
    const cbicAlliedActDtls: ICbicAlliedActDtls[] = cbicAlliedActDtlsToCheck.filter(isPresent);
    if (cbicAlliedActDtls.length > 0) {
      const cbicAlliedActDtlsCollectionIdentifiers = cbicAlliedActDtlsCollection.map(
        cbicAlliedActDtlsItem => getCbicAlliedActDtlsIdentifier(cbicAlliedActDtlsItem)!
      );
      const cbicAlliedActDtlsToAdd = cbicAlliedActDtls.filter(cbicAlliedActDtlsItem => {
        const cbicAlliedActDtlsIdentifier = getCbicAlliedActDtlsIdentifier(cbicAlliedActDtlsItem);
        if (cbicAlliedActDtlsIdentifier == null || cbicAlliedActDtlsCollectionIdentifiers.includes(cbicAlliedActDtlsIdentifier)) {
          return false;
        }
        cbicAlliedActDtlsCollectionIdentifiers.push(cbicAlliedActDtlsIdentifier);
        return true;
      });
      return [...cbicAlliedActDtlsToAdd, ...cbicAlliedActDtlsCollection];
    }
    return cbicAlliedActDtlsCollection;
  }

  protected convertDateFromClient(cbicAlliedActDtls: ICbicAlliedActDtls): ICbicAlliedActDtls {
    return Object.assign({}, cbicAlliedActDtls, {
      created_dt: cbicAlliedActDtls.created_dt?.isValid() ? cbicAlliedActDtls.created_dt.toJSON() : undefined,
      updated_dt: cbicAlliedActDtls.updated_dt?.isValid() ? cbicAlliedActDtls.updated_dt.toJSON() : undefined,
      allied_act_dt: cbicAlliedActDtls.allied_act_dt?.isValid() ? cbicAlliedActDtls.allied_act_dt.toJSON() : undefined,
      issue_dt: cbicAlliedActDtls.issue_dt?.isValid() ? cbicAlliedActDtls.issue_dt.toJSON() : undefined,
      amend_dt: cbicAlliedActDtls.amend_dt?.isValid() ? cbicAlliedActDtls.amend_dt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.created_dt = res.body.created_dt ? dayjs(res.body.created_dt) : undefined;
      res.body.updated_dt = res.body.updated_dt ? dayjs(res.body.updated_dt) : undefined;
      res.body.allied_act_dt = res.body.allied_act_dt ? dayjs(res.body.allied_act_dt) : undefined;
      res.body.issue_dt = res.body.issue_dt ? dayjs(res.body.issue_dt) : undefined;
      res.body.amend_dt = res.body.amend_dt ? dayjs(res.body.amend_dt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((cbicAlliedActDtls: ICbicAlliedActDtls) => {
        cbicAlliedActDtls.created_dt = cbicAlliedActDtls.created_dt ? dayjs(cbicAlliedActDtls.created_dt) : undefined;
        cbicAlliedActDtls.updated_dt = cbicAlliedActDtls.updated_dt ? dayjs(cbicAlliedActDtls.updated_dt) : undefined;
        cbicAlliedActDtls.allied_act_dt = cbicAlliedActDtls.allied_act_dt ? dayjs(cbicAlliedActDtls.allied_act_dt) : undefined;
        cbicAlliedActDtls.issue_dt = cbicAlliedActDtls.issue_dt ? dayjs(cbicAlliedActDtls.issue_dt) : undefined;
        cbicAlliedActDtls.amend_dt = cbicAlliedActDtls.amend_dt ? dayjs(cbicAlliedActDtls.amend_dt) : undefined;
      });
    }
    return res;
  }
}
