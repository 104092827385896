import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { Search } from 'app/core/request/request.model';
import { ICbicRuleMst, getCbicRuleMstIdentifier, RuleMstResponse } from '../cbic-rule-mst.model';

export type EntityResponseType = HttpResponse<ICbicRuleMst>;
export type EntityArrayResponseType = HttpResponse<ICbicRuleMst[]>;

@Injectable({ providedIn: 'root' })
export class CbicRuleMstService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/cbic-rule-msts');
  public resourceSearchUrl = this.applicationConfigService.getEndpointFor('api/_search/cbic-rule-msts');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  findRulesList(taxId:number):Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicRuleMst[]>(`${this.resourceUrl}/fetchRules/${taxId}`, {  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  fetchRulesCategory(taxId:number):Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicRuleMst[]>(`${this.resourceUrl}/fetchRulesCategory/${taxId}`, {  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  fetchCategoryRelatedRules(taxId:number, ruleCategory:string):Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicRuleMst[]>(`${this.resourceUrl}/fetchCategoryRelatedRules/${taxId}/${ruleCategory}`, {  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  fetchSelectedRulesByTaxId(taxId:number, ruleId:string):Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicRuleMst[]>(`${this.resourceUrl}/fetchSelectedRulesByTaxId/${taxId}/${ruleId}`, {  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  downloadRule(ruleId: number):Observable<RuleMstResponse>{
    return this.http.get<RuleMstResponse>(`${this.resourceUrl}/download/${ruleId}`);
  }

  create(cbicRuleMst: ICbicRuleMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicRuleMst);
    return this.http
      .post<ICbicRuleMst>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(cbicRuleMst: ICbicRuleMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicRuleMst);
    return this.http
      .put<ICbicRuleMst>(`${this.resourceUrl}/${getCbicRuleMstIdentifier(cbicRuleMst) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(cbicRuleMst: ICbicRuleMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicRuleMst);
    return this.http
      .patch<ICbicRuleMst>(`${this.resourceUrl}/${getCbicRuleMstIdentifier(cbicRuleMst) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICbicRuleMst>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicRuleMst[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  search(req: Search): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicRuleMst[]>(this.resourceSearchUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  addCbicRuleMstToCollectionIfMissing(
    cbicRuleMstCollection: ICbicRuleMst[],
    ...cbicRuleMstsToCheck: (ICbicRuleMst | null | undefined)[]
  ): ICbicRuleMst[] {
    const cbicRuleMsts: ICbicRuleMst[] = cbicRuleMstsToCheck.filter(isPresent);
    if (cbicRuleMsts.length > 0) {
      const cbicRuleMstCollectionIdentifiers = cbicRuleMstCollection.map(cbicRuleMstItem => getCbicRuleMstIdentifier(cbicRuleMstItem)!);
      const cbicRuleMstsToAdd = cbicRuleMsts.filter(cbicRuleMstItem => {
        const cbicRuleMstIdentifier = getCbicRuleMstIdentifier(cbicRuleMstItem);
        if (cbicRuleMstIdentifier == null || cbicRuleMstCollectionIdentifiers.includes(cbicRuleMstIdentifier)) {
          return false;
        }
        cbicRuleMstCollectionIdentifiers.push(cbicRuleMstIdentifier);
        return true;
      });
      return [...cbicRuleMstsToAdd, ...cbicRuleMstCollection];
    }
    return cbicRuleMstCollection;
  }

  protected convertDateFromClient(cbicRuleMst: ICbicRuleMst): ICbicRuleMst {
    return Object.assign({}, cbicRuleMst, {
      createdDt: cbicRuleMst.createdDt?.isValid() ? cbicRuleMst.createdDt.toJSON() : undefined,
      updatedDt: cbicRuleMst.updatedDt?.isValid() ? cbicRuleMst.updatedDt.toJSON() : undefined,
      issueDt: cbicRuleMst.issueDt?.isValid() ? cbicRuleMst.issueDt.toJSON() : undefined,
      amendDt: cbicRuleMst.amendDt?.isValid() ? cbicRuleMst.amendDt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDt = res.body.createdDt ? dayjs(res.body.createdDt) : undefined;
      res.body.updatedDt = res.body.updatedDt ? dayjs(res.body.updatedDt) : undefined;
      res.body.issueDt = res.body.issueDt ? dayjs(res.body.issueDt) : undefined;
      res.body.amendDt = res.body.amendDt ? dayjs(res.body.amendDt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((cbicRuleMst: ICbicRuleMst) => {
        cbicRuleMst.createdDt = cbicRuleMst.createdDt!=null ? dayjs(cbicRuleMst.createdDt) : undefined;
        cbicRuleMst.updatedDt = cbicRuleMst.updatedDt ? dayjs(cbicRuleMst.updatedDt) : undefined;
        cbicRuleMst.issueDt = cbicRuleMst.issueDt ? dayjs(cbicRuleMst.issueDt) : undefined;
        cbicRuleMst.amendDt = cbicRuleMst.amendDt ? dayjs(cbicRuleMst.amendDt) : undefined;
      });
    }
    return res;
  }
}
