import * as dayjs from 'dayjs';

export interface ICbicSearchInput {
    keyword?: string | null;
    taxId?: string | null;
    actId?: string | null;
    content?: string | null;
    chapterId?: string | null;
    sectionId?: string | null;
    startIndex?: number | null;
    paginationFlag?: boolean | null;
    rows?: number | null;
    amendYear? : string | null;
    amendPeriodFrom?:dayjs.Dayjs | null;
    amendPeriodTo?:dayjs.Dayjs | null;
    ruleId?: string | null;
  }

  export class CbicSearchInput implements ICbicSearchInput {
    constructor(
      public keyword?: string | null,
      public taxId?: string | null,
      public actId?: string | null,
      public content?: string | null,
      public startIndex?: number | null,
      public paginationFlag?: boolean | null,
      public rows?: number | null,
      public amendYear?:string | null,
      public amendPeriodFrom?:dayjs.Dayjs | null,
      public amendPeriodTo?:dayjs.Dayjs | null,
      public ruleId?: string | null,
    ) {}
  }
  export class CbicSearchInput1 implements ICbicSearchInput {
    constructor(
      public keyword?: string | null,
      public actId?: string | null,
      public chapterId?: string | null,
      public sectionId?: string | null
    ) {}
  }


  export interface ICbicContent{
    id?: string;
    contentName?: string;
  }

  export class CbicContent implements ICbicContent{
    constructor(
      public id?:string,
      public contentName?:string
    ){}
  }

  export class CbicContentSearch{
    constructor(
      public taxId:any,
      public contentType:any,
    ){}
    
  } 

  export class CbicContentSearchHistory{
    constructor(
      public taxId:any,
      public contentType:any,
      public notifyType:any
    ){}
    
  } 

  export class CbicContentLink{
    constructor(
      public taxId:any,
      public contentType:any,
      public contentId: any,
    ){}
  }

  export class CbicContentLinkHistory{
    constructor(
      public taxId:any,
      public contentType:any,
      public contentId: any,
      public notifyType: any
    ){}
  }

  export class CbicContentLink2{
    constructor(
      public taxId:any,
      public regulationCategory: any
    ){}
  }

  
  export class CbicContentLink1{
    constructor(
      public taxId:any,
      public contentType:any,
      public contentId: any,
      public year: any
    ){}
  }

  export class FetchLink{
    constructor(
      public id:any,
      public link:any
    ){}
  }
  
  export class regulationCategory{
    constructor(
      public taxId:any
    ){}
  }

  export class RegulationDtls{
    constructor(
      public contentDtls : CbicContentDtls[],
      public contentHeaders: CbicContentHeader[]
    ){

    }
  }

  export class CbicContentHeader{
    contentNameLable:any;
    constructor(
      public contentId :any,
      public contentName : any){
        this.contentNameLable=contentName;
      }
  }

  export class CbicContentDtls{
    contentName:any;
    constructor(
      public contentDtlsId :any,
      public contentDtlsName : any){
        this.contentName = contentDtlsName;
      }
  }

  export class CbicFormSearchInput implements ICbicSearchInput{
    constructor(
      public keyword?: string | null,
      public taxId?: string | null,
      public formNo?: string | null,
      public content?: string | null
    ) {}
  }

  export class CbicSTRuleSearchInput implements ICbicSearchInput{
    constructor(
      public keyword?: string | null,
      public taxId?: string | null,
      public ruleId?: string | null,
    ) {}

   
  }
  export class CbicInstructionSearch implements ICbicSearchInput{
    constructor(
      public keyword?: string | null,
      public taxId?: string | null,
      public content?: string | null,
    ) {}
  }
  export class CbicOrderSearch implements ICbicSearchInput{
    constructor(
      public keyword?: string | null,
      public taxId?: string | null,
      public content?: string | null,
    ) {}
  }  
