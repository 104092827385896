import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpErrorResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { EventManager, EventWithContent } from 'app/core/util/event-manager.service';
import { Router } from '@angular/router';
import { CbicErrorService } from 'app/layouts/cbiccoreError/cbiccoreerror.service';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private eventManager: EventManager, private route:Router, private errorService : CbicErrorService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        error: (err: HttpErrorResponse) => {
          // this.spinnerService.requestEnded();
          if(err.error.Excpetion === "CoreException"){
            this.errorService.loadCbicCoreError(err);
            this.route.navigate(['cbic-error']);
          }else{
            if (!(err.status === 401 && (err.message === '' || err.url?.includes('api/account')))) {
              this.eventManager.broadcast(new EventWithContent('cbicWebPortalApp.httpError', err));
            }
          }
          
        },
      })
    );
  }
}
