export interface Theme {
    name: string;
    properties: any;
}

export const light: Theme = {
    name: "light",
    properties: {
        "--tax-portal-title-defalut":"#8A7356",
        "--nav-bar-navbar-default-bg1":"#E19F20",
        "--nav-bar-navbar-default-bg2":"#8A7356",
        "--theme-light-color-1" : "#004F7D",
        "--theme-light-color-2" : "#ffffff",
        "--theme-light-color-5" : "#E19F20"
    }
};

export const dark: Theme = {
    name: "dark",
    properties: {
        "--tax-portal-title-defalut":"#004F7D",
        "--nav-bar-navbar-default-bg1":"#8A7356",
        "--nav-bar-navbar-default-bg2":"#E19F20",
        "--theme-light-color-1" : "#3778C2",
        "--theme-light-color-2" : "#CAE7DF",
        "--theme-light-color-5" : "#F2503B"
    }
};