import * as dayjs from 'dayjs';
import { ICbicTaxMst } from 'app/entities/cbic-tax-mst/cbic-tax-mst.model';
import { ICbicRuleMst } from 'app/entities/cbic-rule-mst/cbic-rule-mst.model';

export interface ICbicRuleChapterMst {
  id?: number;
  contentId?: number | null;
  contentLanguage?: string | null;
  isActive?: string | null;
  createdDt?: dayjs.Dayjs | null;
  updatedDt?: dayjs.Dayjs | null;
  isAmended?: string | null;
  parentId?: number | null;
  orderId?: number | null;
  versionNo?: number | null;
  createdBy?: number | null;
  updatedBy?: number | null;
  chapterNo?: string | null;
  chapterName?: string | null;
  chapterNoHi?: string | null;
  chapterNameHi?: string | null;
  issueDt?: dayjs.Dayjs | null;
  amendDt?: dayjs.Dayjs | null;
  cbicTaxMst?: ICbicTaxMst | null;
  cbicRuleMst?: ICbicRuleMst | null;
}

export class CbicRuleChapterMst implements ICbicRuleChapterMst {
  constructor(
    public id?: number,
    public contentId?: number | null,
    public contentLanguage?: string | null,
    public isActive?: string | null,
    public createdDt?: dayjs.Dayjs | null,
    public updatedDt?: dayjs.Dayjs | null,
    public isAmended?: string | null,
    public parentId?: number | null,
    public orderId?: number | null,
    public versionNo?: number | null,
    public createdBy?: number | null,
    public updatedBy?: number | null,
    public chapterNo?: string | null,
    public chapterName?: string | null,
    public chapterNoHi?: string | null,
    public chapterNameHi?: string | null,
    public issueDt?: dayjs.Dayjs | null,
    public amendDt?: dayjs.Dayjs | null,
    public cbicTaxMst?: ICbicTaxMst | null,
    public cbicRuleMst?: ICbicRuleMst | null
  ) {}
}

export function getCbicRuleChapterMstIdentifier(cbicRuleChapterMst: ICbicRuleChapterMst): number | undefined {
  return cbicRuleChapterMst.id;
}
