import * as dayjs from 'dayjs';

export interface ICbicServiceTaxMst {
  id?: number;
  contentId?: number | null;
  contentLanguage?: string | null;
  isActive?: string | null;
  createdDt?: dayjs.Dayjs | null;
  updatedDt?: dayjs.Dayjs | null;
  isAmended?: string | null;
  parentId?: number | null;
  orderId?: number | null;
  versionNo?: number | null;
  createdBy?: number | null;
  updatedBy?: number | null;
  contentName?: string | null;
  contentType?: string | null;
  issueDt?: dayjs.Dayjs | null;
  amendDt?: dayjs.Dayjs | null;
  contentFilePath?: string | null;
  contentFileName?: string | null;
}

export class CbicServiceTaxMst implements ICbicServiceTaxMst {
  constructor(
    public id?: number,
    public contentId?: number | null,
    public contentLanguage?: string | null,
    public isActive?: string | null,
    public createdDt?: dayjs.Dayjs | null,
    public updatedDt?: dayjs.Dayjs | null,
    public isAmended?: string | null,
    public parentId?: number | null,
    public orderId?: number | null,
    public versionNo?: number | null,
    public createdBy?: number | null,
    public updatedBy?: number | null,
    public contentName?: string | null,
    public contentType?: string | null,
    public issueDt?: dayjs.Dayjs | null,
    public amendDt?: dayjs.Dayjs | null,
    public contentFilePath?: string | null,
    public contentFileName?: string | null
  ) {}
}

export function getCbicServiceTaxMstIdentifier(cbicServiceTaxMst: ICbicServiceTaxMst): number | undefined {
  return cbicServiceTaxMst.id;
}

export class ServiceTaxMstResponse{
  constructor(
    public data?: string | null,
    public fileName ?: string | null
  ){}}