import * as dayjs from 'dayjs';
import { ICbicAlliedActMst } from '../cbic-allied-act-mst/cbic-allied-act-mst.model';
import { ICbicTaxMst } from '../cbic-tax-mst/cbic-tax-mst.model';

export interface ICbicAlliedActDtls {
  id?: number;
  content_id?: number | null;
  content_language?: string | null;
  is_active?: string | null;
  created_dt?: dayjs.Dayjs | null;
  updated_dt?: dayjs.Dayjs | null;
  is_amended?: string | null;
  is_omitted?: string | null;
  parent_id?: number | null;
  order_id?: number | null;
  version_no?: number | null;
  created_by?: number | null;
  updated_by?: number | null;
  /* tax_id?: number | null; */
  allied_act_id?: number | null;
  allied_act_ref_no?: string | null;
  allied_act_dt?: dayjs.Dayjs | null;
  allied_act_subject?: string | null;
  allied_act_ccn_no?: string | null;
  issue_dt?: dayjs.Dayjs | null;
  amend_dt?: dayjs.Dayjs | null;
  doc_file_path?: string | null;
  doc_file_name?: string | null;
  doc_file_path_hi?: string | null;
  doc_file_name_hi?: string | null;
  doc_file_path_aod?: string | null;
  doc_file_name_aod?: string | null;
  allied_act_ref_no_hi?: string | null;
  allied_act_subject_hi?: string | null;
  url?: string | null;
  tax?: ICbicTaxMst | null;
  year?: number | null;
  cbicAlliedMst?: ICbicAlliedActMst | null;
   
}

export class CbicAlliedActDtls implements ICbicAlliedActDtls {
  constructor(
    public id?: number,
    public content_id?: number | null,
    public content_language?: string | null,
    public is_active?: string | null,
    public created_dt?: dayjs.Dayjs | null,
    public updated_dt?: dayjs.Dayjs | null,
    public is_amended?: string | null,
    public is_omitted?: string | null,
    public parent_id?: number | null,
    public order_id?: number | null,
    public version_no?: number | null,
    public created_by?: number | null,
    public updated_by?: number | null,
    /* public tax_id?: number | null, */
    public allied_act_id?: number | null,
    public allied_act_ref_no?: string | null,
    public allied_act_dt?: dayjs.Dayjs | null,
    public allied_act_subject?: string | null,
    public allied_act_ccn_no?: string | null,
    public issue_dt?: dayjs.Dayjs | null,
    public amend_dt?: dayjs.Dayjs | null,
    public doc_file_path?: string | null,
    public doc_file_name?: string | null,
    public doc_file_path_hi?: string | null,
    public doc_file_name_hi?: string | null,
    public doc_file_path_aod?: string | null,
    public doc_file_name_aod?: string | null,
    public allied_act_ref_no_hi?: string | null,
    public allied_act_subject_hi?: string | null,
    public url?: string | null,
    public year?: number | null,
    public tax?: ICbicTaxMst | null,
    public cbicAlliedMst?: ICbicAlliedActMst | null,

  ) {}
}

export function getCbicAlliedActDtlsIdentifier(cbicAlliedActDtls: ICbicAlliedActDtls): number | undefined {
  return cbicAlliedActDtls.id;
}
