import * as dayjs from 'dayjs';
import { ICbicTaxMst } from 'app/entities/cbic-tax-mst/cbic-tax-mst.model';

export interface ICbicRuleMst {
  id?: number;
  contentId?: number | null;
  contentLanguage?: string | null;
  isActive?: string | null;
  createdDt?: dayjs.Dayjs | null;
  updatedDt?: dayjs.Dayjs | null;
  isAmended?: string | null;
  parentId?: number | null;
  orderId?: number | null;
  versionNo?: number | null;
  createdBy?: number | null;
  updatedBy?: number | null;
  ruleDocNo?: number | null;
  ruleDocName?: string | null;
  ruleDocNameHi?: string | null;
  contentFilePath?: string | null;
  contentHtmlFilePath?: string | null;
  ruleDocNameLable?: string | null;
  ruleCategory?: string | null;
  issueDt?: dayjs.Dayjs | null;
  amendDt?: dayjs.Dayjs | null;
  cbicTaxMst?: ICbicTaxMst | null;
  ruleCategoryHi?: string | null;
  contentFilePathHi?: string | null;
  contentHtmlFilePathHi?: string | null;
}

export class CbicRuleMst implements ICbicRuleMst {
  constructor(
    public id?: number,
    public contentId?: number | null,
    public contentLanguage?: string | null,
    public isActive?: string | null,
    public createdDt?: dayjs.Dayjs | null,
    public updatedDt?: dayjs.Dayjs | null,
    public isAmended?: string | null,
    public parentId?: number | null,
    public orderId?: number | null,
    public versionNo?: number | null,
    public createdBy?: number | null,
    public updatedBy?: number | null,
    public ruleDocNo?: number | null,
    public contentFilePath?: string | null,
    public ruleDocName?: string | null,
    public ruleDocNameHi?: string | null,
    public ruleDocNameLable?:string| null,
    public ruleCategory?: string | null,
    public issueDt?: dayjs.Dayjs | null,
    public amendDt?: dayjs.Dayjs | null,
    public cbicTaxMst?: ICbicTaxMst | null,
    public contentHtmlFilePath?: string | null,
    public ruleCategoryHi?: string | null,
    public contentFilePathHi?: string | null,
    public contentHtmlFilePathHi?: string | null
  ) {}
}

export function getCbicRuleMstIdentifier(cbicRuleMst: ICbicRuleMst): number | undefined {
  return cbicRuleMst.id;
}

export class RuleMstResponse{
  constructor(
    public data?: string | null,
    public fileName ?: string | null
  ){}
}