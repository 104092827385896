import * as dayjs from 'dayjs';

export interface ICbicContentMap {
  id?: number;
  contentParentId?: number | null;
  contentChildId?: number | null;
  contentParentCategory?: string | null;
  contentChildCategory?: string | null;
  orderId?: number | null;
  isActive?: string | null;
  createdDt?: dayjs.Dayjs | null;
  updatedDt?: dayjs.Dayjs | null;
  createdBy?: number | null;
  updatedBy?: number | null;
}

export class CbicContentMap implements ICbicContentMap {
  constructor(
    public id?: number,
    public contentParentId?: number | null,
    public contentChildId?: number | null,
    public contentParentCategory?: string | null,
    public contentChildCategory?: string | null,
    public orderId?: number | null,
    public isActive?: string | null,
    public createdDt?: dayjs.Dayjs | null,
    public updatedDt?: dayjs.Dayjs | null,
    public createdBy?: number | null,
    public updatedBy?: number | null
  ) {}
}

export class CbicContentMap2 implements ICbicContentMap{
  constructor(
    public contentParentId?: number | null,
    public contentChildId?: number | null,
    public contentParentCategory?: string | null,
    public contentChildCategory?: string | null,
  ){

  }
}

export class CbicContentMapResponse{
  constructor(
    public linkResult?: boolean,
    public message?: string
  ){

  }
}

export class RelatedContentRequest{
  constructor(
    public parentContentCategory?: any,
    public parentContentId?: any
  ){}
}

export class RelatedActSection{
  constructor(
    public taxType?: any,
    public actName?: any,
    public actSubjectName?: any,
    public taxId? : any,
    public sectionId? : any
  ){}
}

export class RelatedRuleMst{
   constructor(
    public taxType?: any,
    public ruleName?: any,
    public ruleSubjectName?: any,
    public taxId? : any,
    public ruleId? : any
   ){}
}

export class RelatedRegulationMst{
  constructor(
    public taxType?: any,
    public regulationName?: any,
    public regulationSubjectName?: any,
    public taxId? : any,
    public regulationId? : any
  ){}
}

export class RelatedFormMst{
  constructor(
    public formCategory?: any,
    public formNumber?: any,
    public formName?: any,
    public taxId? : any,
    public formId? : any
  ){}
}

export class RelatedNotificationMst{
  constructor(
    public notificationCategory?: any,
    public notificationName?: any,
    public notificationNumber?: any,
    public taxId? : any,
    public notificationId? : any
  ){}
}

export class RelatedCircularMst{
  constructor(
    public circularCategory?: any,
    public circularName?: any,
    public circularNo?: any,
    public taxId? : any,
    public circularId? : any
  ){}
}

export class RelatedContentResponse{
 constructor(
  public actSectionMsts?: RelatedActSection[],
  public ruleSectionMsts?: RelatedRuleMst[],
  public regulationMsts?: RelatedRegulationMst[],
  public formMsts?: RelatedFormMst[],
  public notificationMsts?: RelatedNotificationMst[],
  public circularMsts?: RelatedCircularMst[]
 ){}
}

export function getCbicContentMapIdentifier(cbicContentMap: ICbicContentMap): number | undefined {
  return cbicContentMap.id;
}
