import { Component, OnInit } from "@angular/core";
import { ActivatedRoute} from "@angular/router";
import { DataUtils } from "app/core/util/data-util.service";
import { FormResponse } from "app/entities/cbic-form-mst/cbic-form-mst.model";
import { CbicFormMstService } from "app/entities/cbic-form-mst/service/cbic-form-mst.service";

@Component({
    selector: 'cbic-form-open',
    template:''
})
export class FormOpenComponent implements OnInit {
    
    constructor(
        private formService: CbicFormMstService,
        protected activatedRoute: ActivatedRoute,
        private dataUtils: DataUtils,
    ){}
    
    ngOnInit():void{
        let formId = 1;
        this.activatedRoute.data.subscribe(({ cbicformmst }) => {
            formId = cbicformmst.id;
          });
        this.formService.downloadForm(Number(formId)).subscribe(
            (res: FormResponse) => {
              const data = res.data;
              if(String (res.fileName).indexOf(".pdf")===-1){
                alert("Preview not available, Please Download")
              }else{
                this.dataUtils.openFile(String(data), "application/pdf");
              }
            }
          );
    }
}