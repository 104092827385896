import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { Search } from 'app/core/request/request.model';
import { ICbicTaxMst, getCbicTaxMstIdentifier } from '../cbic-tax-mst.model';
import { CbicContentDtls, CbicContentHeader, CbicContentLink, CbicContentLink1, CbicContentLink2, CbicContentSearch, FetchLink, regulationCategory, RegulationDtls } from 'app/searchbar/searchbar.model';

export type EntityResponseType = HttpResponse<ICbicTaxMst>;
export type EntityArrayResponseType = HttpResponse<ICbicTaxMst[]>;
export type FetchResponseType = HttpResponse<CbicContentHeader[]>;
export type FetchDtlsResponseType = HttpResponse<CbicContentDtls[]>;
export type FetchLinkResponse = HttpResponse<FetchLink>;
export type RegulationResponse = HttpResponse<RegulationDtls>;

@Injectable({ providedIn: 'root' })
export class CbicTaxMstService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/cbic-tax-msts');
  public resourceSearchUrl = this.applicationConfigService.getEndpointFor('api/_search/cbic-tax-msts');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(cbicTaxMst: ICbicTaxMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicTaxMst);
    return this.http
      .post<ICbicTaxMst>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(cbicTaxMst: ICbicTaxMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicTaxMst);
    return this.http
      .put<ICbicTaxMst>(`${this.resourceUrl}/${getCbicTaxMstIdentifier(cbicTaxMst) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(cbicTaxMst: ICbicTaxMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicTaxMst);
    return this.http
      .patch<ICbicTaxMst>(`${this.resourceUrl}/${getCbicTaxMstIdentifier(cbicTaxMst) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICbicTaxMst>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicTaxMst[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  search(req: Search): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicTaxMst[]>(this.resourceSearchUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  addCbicTaxMstToCollectionIfMissing(
    cbicTaxMstCollection: ICbicTaxMst[],
    ...cbicTaxMstsToCheck: (ICbicTaxMst | null | undefined)[]
  ): ICbicTaxMst[] {
    const cbicTaxMsts: ICbicTaxMst[] = cbicTaxMstsToCheck.filter(isPresent);
    if (cbicTaxMsts.length > 0) {
      const cbicTaxMstCollectionIdentifiers = cbicTaxMstCollection.map(cbicTaxMstItem => getCbicTaxMstIdentifier(cbicTaxMstItem)!);
      const cbicTaxMstsToAdd = cbicTaxMsts.filter(cbicTaxMstItem => {
        const cbicTaxMstIdentifier = getCbicTaxMstIdentifier(cbicTaxMstItem);
        if (cbicTaxMstIdentifier == null || cbicTaxMstCollectionIdentifiers.includes(cbicTaxMstIdentifier)) {
          return false;
        }
        cbicTaxMstCollectionIdentifiers.push(cbicTaxMstIdentifier);
        return true;
      });
      return [...cbicTaxMstsToAdd, ...cbicTaxMstCollection];
    }
    return cbicTaxMstCollection;
  }

  fetchContentHeader(cbicContent : CbicContentSearch):Observable<FetchResponseType>{
    return this.http.post<CbicContentHeader[]>(`${this.resourceUrl}/fetch-contents-header`, cbicContent,{ observe: 'response' });
  }

  fetchContentDtls(cbicLink : CbicContentLink):Observable<FetchDtlsResponseType>{
    return this.http.post<CbicContentDtls[]>(`${this.resourceUrl}/content-dtls`, cbicLink,{ observe: 'response' });
  }
  
  fetchContentByYear(cbicLink1 : CbicContentLink1):Observable<FetchDtlsResponseType>{
    return this.http.post<CbicContentDtls[]>(`${this.resourceUrl}/content-dtls-year`, cbicLink1,{ observe: 'response' });
  }

  fetchLink(contentName : any):Observable<FetchLinkResponse>{
    return this.http.post<FetchLink>(`${this.resourceUrl}/fetch-link`, contentName,{ observe: 'response' });
  }
  
  fetchRegulationCategory(taxId:any):Observable<HttpResponse<string[]>>{
    const category = new regulationCategory(taxId);
    return this.http.post<string[]>(`${this.resourceUrl}/fetch-regulation-category`, category, {observe: 'response'});
  }
  
  fetchRegulationContent(cbicLink:CbicContentLink2):Observable<RegulationResponse>{
    return this.http.post<RegulationDtls>(`${this.resourceUrl}//fetch-regulations`, cbicLink, {observe: 'response'});

  }

  protected convertDateFromClient(cbicTaxMst: ICbicTaxMst): ICbicTaxMst {
    return Object.assign({}, cbicTaxMst, {
      createdDt: cbicTaxMst.createdDt?.isValid() ? cbicTaxMst.createdDt.toJSON() : undefined,
      updatedDt: cbicTaxMst.updatedDt?.isValid() ? cbicTaxMst.updatedDt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDt = res.body.createdDt ? dayjs(res.body.createdDt) : undefined;
      res.body.updatedDt = res.body.updatedDt ? dayjs(res.body.updatedDt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((cbicTaxMst: ICbicTaxMst) => {
        cbicTaxMst.createdDt = cbicTaxMst.createdDt ? dayjs(cbicTaxMst.createdDt) : undefined;
        cbicTaxMst.updatedDt = cbicTaxMst.updatedDt ? dayjs(cbicTaxMst.updatedDt) : undefined;
      });
    }
    return res;
  }
}
