import * as dayjs from 'dayjs';
import { ICbicTaxMst } from 'app/entities/cbic-tax-mst/cbic-tax-mst.model';

export interface ICbicNotificationMst {
  id?: number;
  contentId?: number | null;
  contentLanguage?: string | null;
  isActive?: string | null;
  createdDt?: dayjs.Dayjs | null;
  updatedDt?: dayjs.Dayjs | null;
  isAmended?: string | null;
  isOmitted?: string | null;
  parentId?: number | null;
  orderId?: number | null;
  versionNo?: number | null;
  createdBy?: number | null;
  updatedBy?: number | null;
  notificationNo?: string | null;
  notificationName?: string | null;
  notificationCategory?: string | null;
  notificationDt?: dayjs.Dayjs | null;
  issueDt?: dayjs.Dayjs | null;
  amendDt?: dayjs.Dayjs | null;
  docFilePath?: string | null;
  docFileName?: string | null;
  docFilePathHi?: string | null;
  docFileNameHi?: string | null;
  docFilePathAOD?: string | null;
  docFileNameAOD?: string | null;
  tax?: ICbicTaxMst | null;
  ntRemarks?: string | null;
  isHistory?: string | null;
  notificationDtHi?: dayjs.Dayjs | null;
  notificationNoHi?: string | null;
  notificationNameHi?: string | null;
  notificationCategoryHi?: string | null;
}

export class CbicNotificationMst implements ICbicNotificationMst {
  constructor(
    public id?: number,
    public contentId?: number | null,
    public contentLanguage?: string | null,
    public isActive?: string | null,
    public createdDt?: dayjs.Dayjs | null,
    public updatedDt?: dayjs.Dayjs | null,
    public isAmended?: string | null,
    public isOmitted?: string | null,
    public parentId?: number | null,
    public orderId?: number | null,
    public versionNo?: number | null,
    public createdBy?: number | null,
    public updatedBy?: number | null,
    public notificationNo?: string | null,
    public notificationName?: string | null,
    public notificationCategory?: string | null,
    public notificationDt?: dayjs.Dayjs | null,
    public issueDt?: dayjs.Dayjs | null,
    public amendDt?: dayjs.Dayjs | null,
    public docFilePath?: string | null,
    public docFileName?: string | null,
    public docFilePathHi?: string | null,
    public docFileNameHi?: string | null,
    public docFilePathAOD?: string | null,
    public docFileNameAOD?: string | null,
    public tax?: ICbicTaxMst | null,
    public ntRemarks?: string | null,
    public isHistory?: string | null,
    public notificationNoHi?: string | null,
    public notificationNameHi?: string | null,
    public notificationDtHi?: dayjs.Dayjs | null,
    public notificationCategoryHi?: string | null
    
  ) {}
}

export function getCbicNotificationMstIdentifier(cbicNotificationMst: ICbicNotificationMst): number | undefined {
  return cbicNotificationMst.id;
}
