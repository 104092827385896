import * as dayjs from 'dayjs';
import { ICbicTaxMst } from 'app/entities/cbic-tax-mst/cbic-tax-mst.model';
import { ICbicRegulationDocMst } from 'app/entities/cbic-regulation-doc-mst/cbic-regulation-doc-mst.model';
import { ICbicRegulationChapterMst } from 'app/entities/cbic-regulation-chapter-mst/cbic-regulation-chapter-mst.model';

export interface ICbicRegulationMst {
  id?: number;
  contentId?: number | null;
  contentLanguage?: string | null;
  isActive?: string | null;
  createdDt?: dayjs.Dayjs | null;
  updatedDt?: dayjs.Dayjs | null;
  isAmended?: string | null;
  isOmitted?: string | null;
  parentId?: number | null;
  orderId?: number | null;
  versionNo?: number | null;
  createdBy?: number | null;
  updatedBy?: number | null;
  regulationNo?: string | null;
  regulationName?: string | null;
  issueDt?: dayjs.Dayjs | null;
  amendDt?: dayjs.Dayjs | null;
  contentFilePath?: string | null;
  contentFileName?: string | null;
  contentData?: string | null;
  notificationDt?: dayjs.Dayjs | null;
  docFilePath?: string | null;
  docFileName?: string | null;
  docFileType?: string | null;
  tax?: ICbicTaxMst | null;
  regulationNoHi?: string | null;
  regulationNameHi?: string | null;
  contentFilePathHi?: string | null;
  contentFileNameHi?: string | null;
  regulationDoc?: ICbicRegulationDocMst | null;
  regulationChapter?: ICbicRegulationChapterMst | null;
}

export class CbicRegulationMst implements ICbicRegulationMst {
  constructor(
    public id?: number,
    public contentId?: number | null,
    public contentLanguage?: string | null,
    public isActive?: string | null,
    public createdDt?: dayjs.Dayjs | null,
    public updatedDt?: dayjs.Dayjs | null,
    public isAmended?: string | null,
    public isOmitted?: string | null,
    public parentId?: number | null,
    public orderId?: number | null,
    public versionNo?: number | null,
    public createdBy?: number | null,
    public updatedBy?: number | null,
    public regulationNo?: string | null,
    public regulationName?: string | null,
    public issueDt?: dayjs.Dayjs | null,
    public amendDt?: dayjs.Dayjs | null,
    public contentFilePath?: string | null,
    public contentFileName?: string | null,
    public contentData?: string | null,
    public notificationDt?: dayjs.Dayjs | null,
    public docFilePath?: string | null,
    public docFileName?: string | null,
    public docFileType?: string | null,
    public tax?: ICbicTaxMst | null,
    public regulationNoHi?: string | null,
    public regulationNameHi?: string | null,
    public contentFilePathHi?: string | null,
    public contentFileNameHi?: string | null,
    public regulationDoc?: ICbicRegulationDocMst | null,
    public regulationChapter?: ICbicRegulationChapterMst | null
  ) {}
}

export function getCbicRegulationMstIdentifier(cbicRegulationMst: ICbicRegulationMst): number | undefined {
  return cbicRegulationMst.id;
}
