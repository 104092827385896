import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { Search } from 'app/core/request/request.model';
import { ICbicActChapterMst, getCbicActChapterMstIdentifier } from '../cbic-act-chapter-mst.model';

export type EntityResponseType = HttpResponse<ICbicActChapterMst>;
export type EntityArrayResponseType = HttpResponse<ICbicActChapterMst[]>;

@Injectable({ providedIn: 'root' })
export class CbicActChapterMstService {
  
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/cbic-act-chapter-msts');
  public resourceSearchUrl = this.applicationConfigService.getEndpointFor('api/_search/cbic-act-chapter-msts');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(cbicActChapterMst: ICbicActChapterMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicActChapterMst);
    return this.http
      .post<ICbicActChapterMst>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(cbicActChapterMst: ICbicActChapterMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicActChapterMst);
    return this.http
      .put<ICbicActChapterMst>(`${this.resourceUrl}/${getCbicActChapterMstIdentifier(cbicActChapterMst) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(cbicActChapterMst: ICbicActChapterMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicActChapterMst);
    return this.http
      .patch<ICbicActChapterMst>(`${this.resourceUrl}/${getCbicActChapterMstIdentifier(cbicActChapterMst) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICbicActChapterMst>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }
  findByActId(actId: number) : Observable<EntityArrayResponseType>{

    return this.http
      .get<ICbicActChapterMst[]>(`${this.resourceUrl}/chapterListByActId/${actId}`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }
  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicActChapterMst[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  search(req: Search): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicActChapterMst[]>(this.resourceSearchUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  getChapterName(id: number) : Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicActChapterMst[]>(`${this.resourceUrl}/getChapterName/${id}`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  addCbicActChapterMstToCollectionIfMissing(
    cbicActChapterMstCollection: ICbicActChapterMst[],
    ...cbicActChapterMstsToCheck: (ICbicActChapterMst | null | undefined)[]
  ): ICbicActChapterMst[] {
    const cbicActChapterMsts: ICbicActChapterMst[] = cbicActChapterMstsToCheck.filter(isPresent);
    if (cbicActChapterMsts.length > 0) {
      const cbicActChapterMstCollectionIdentifiers = cbicActChapterMstCollection.map(
        cbicActChapterMstItem => getCbicActChapterMstIdentifier(cbicActChapterMstItem)!
      );
      const cbicActChapterMstsToAdd = cbicActChapterMsts.filter(cbicActChapterMstItem => {
        const cbicActChapterMstIdentifier = getCbicActChapterMstIdentifier(cbicActChapterMstItem);
        if (cbicActChapterMstIdentifier == null || cbicActChapterMstCollectionIdentifiers.includes(cbicActChapterMstIdentifier)) {
          return false;
        }
        cbicActChapterMstCollectionIdentifiers.push(cbicActChapterMstIdentifier);
        return true;
      });
      return [...cbicActChapterMstsToAdd, ...cbicActChapterMstCollection];
    }
    return cbicActChapterMstCollection;
  }

  protected convertDateFromClient(cbicActChapterMst: ICbicActChapterMst): ICbicActChapterMst {
    return Object.assign({}, cbicActChapterMst, {
      issueDt: cbicActChapterMst.issueDt?.isValid() ? cbicActChapterMst.issueDt.toJSON() : undefined,
      amendDt: cbicActChapterMst.amendDt?.isValid() ? cbicActChapterMst.amendDt.toJSON() : undefined,
      createdDt: cbicActChapterMst.createdDt?.isValid() ? cbicActChapterMst.createdDt.toJSON() : undefined,
      updatedDt: cbicActChapterMst.updatedDt?.isValid() ? cbicActChapterMst.updatedDt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.issueDt = res.body.issueDt ? dayjs(res.body.issueDt) : undefined;
      res.body.amendDt = res.body.amendDt ? dayjs(res.body.amendDt) : undefined;
      res.body.createdDt = res.body.createdDt ? dayjs(res.body.createdDt) : undefined;
      res.body.updatedDt = res.body.updatedDt ? dayjs(res.body.updatedDt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((cbicActChapterMst: ICbicActChapterMst) => {
        cbicActChapterMst.issueDt = cbicActChapterMst.issueDt ? dayjs(cbicActChapterMst.issueDt) : undefined;
        cbicActChapterMst.amendDt = cbicActChapterMst.amendDt ? dayjs(cbicActChapterMst.amendDt) : undefined;
        cbicActChapterMst.createdDt = cbicActChapterMst.createdDt ? dayjs(cbicActChapterMst.createdDt) : undefined;
        cbicActChapterMst.updatedDt = cbicActChapterMst.updatedDt ? dayjs(cbicActChapterMst.updatedDt) : undefined;
      });
    }
    return res;
  }
}
