<div class="link-page">
    <br>
    <div class="link-header">
        <label class="form-control-label" cbicTranslate="cbicWebPortalApp.cbicContentMap.link.header"
            for="field_linkHeader"></label>
    </div>
    <br>
    <form>
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="form-group">
                                    <label class="form-control-label"
                                        cbicTranslate="cbicWebPortalApp.cbicContentMap.link.taxType"
                                        for="field_taxType">Tax
                                        Type</label>
                                    <select class="form-select" id="inputGroupSelect01"
                                        aria-label="Example select with button addon" #parentTaxType
                                        (change)="showParentContent(parentTaxType.value, parentContentType.value, parentContentCategory.value)">
                                        <option selected value="undefined">All Taxes</option>
                                        <option *ngFor="let taxMst of cbicTaxMsts" [value]="taxMst.id">
                                            {{taxMst.taxName}}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label class="form-control-label"
                                        cbicTranslate="cbicWebPortalApp.cbicContentMap.link.contentType"
                                        for="field_contentType">Content Type</label>
                                    <select class="form-select " id="inputGroupSelect02"
                                        aria-label="Example select with button addon" #parentContentType
                                        (change)="showParentContent(parentTaxType.value, parentContentType.value, parentContentCategory.value)">
                                        <option selected value="undefined">All Contents</option>
                                        <option *ngFor="let contentTest of cbicContents" [value]="contentTest.id">
                                            {{contentTest.contentName}}</option>

                                    </select>
                                </div>
                                <div class="form-group" *ngIf="parentContentType.value === 'REGULATIONS'">
                                    <label class="form-control-label"
                                        cbicTranslate="cbicWebPortalApp.cbicContentMap.link.category"
                                        for="field_contentCategory">Category</label>
                                    <select class="form-select actDropDown" id="inputGroupSelect03"
                                        aria-label="Example select with button addon" #parentRegulationCategory
                                        (change)="showParentContentCategoryAndName(parentRegulationCategory.value, parentTaxType.value)">
                                        <option selected value="undefined">All</option>
                                        <option *ngFor="let category of parentRegulationCategory1" [value]="category" title="year">
                                            {{category}}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label class="form-control-label"
                                        cbicTranslate="cbicWebPortalApp.cbicContentMap.link.contentCategory"
                                        for="field_contentCategory">Content Category</label>
                                    <select class="form-select actDropDown" id="inputGroupSelect03"
                                        aria-label="Example select with button addon" #parentContentCategory
                                        (change)="showParentContentDtls(parentTaxType.value, parentContentType.value, parentContentCategory.value)">
                                        <option selected value="undefined">All</option>
                                        <option *ngFor="let actMst of parentContentHeader" [value]="actMst.contentId"
                                            title="{{actMst.contentName}}">
                                            {{actMst.contentNameLable}}</option>
                                    </select>
                                </div>
                                
                                <div class="form-group" *ngIf="parentContentType.value === 'NOTIFICATIONS' || parentContentType.value === 'CIRCULARS'">
                                    <label class="form-control-label"
                                        cbicTranslate="cbicWebPortalApp.cbicContentMap.link.year"
                                        for="field_contentCategory">Year</label>
                                    <select class="form-select actDropDown" id="inputGroupSelect03"
                                        aria-label="Example select with button addon" #parentYear
                                        (change)="showParentContentDtlsWithYear(parentTaxType.value, parentContentType.value, parentContentCategory.value, parentYear.value)">
                                        <option selected value="undefined">All</option>
                                        <option *ngFor="let year of yearList" [value]="year" title="year">
                                            {{year}}</option>
                                        <option value="1999">
                                            < 2000</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label class="form-control-label"
                                        cbicTranslate="cbicWebPortalApp.cbicContentMap.link.contentNo"
                                        for="field_contentNo">Content No</label>
                                    <select class="form-select actDropDown" id="inputGroupSelect03"
                                        aria-label="Example select with button addon" #parentContentNo
                                        (change)="showLink(parentContentNo.value)">
                                        <option selected value="undefined">Select Content Name</option>
                                        <option *ngFor="let content of parentContentDtls"
                                            [value]="content.contentDtlsId" title="{{content.contentDtlsName}}">
                                            {{content.contentName}}</option>
                                    </select>
                                </div>
                                <br><br>
                                <div class="row copy-row">
                                    <div class="col-md-12">
                                        <div class="form-group external-link" *ngIf="parentContentNo.value">
                                            <label class="form-control-label"
                                                cbicTranslate="cbicWebPortalApp.cbicContentMap.link.externalLink"
                                                for="field_link">Link</label>
                                                <div class="input-group mb-3">
                                                    <input class="form-control" type="text" readonly="readonly" #parentLink />
                                                    <button class="btn btn-primary copy-btn" (click)="copyParentInputMsg()"
                                                    value="click to copy">Copy</button>
                                                  </div>
                                           
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-4">
                                        <br>
                                        <button class="btn btn-primary copy-btn" (click)="copyInputMessage(parentLink)"
                                            value="click to copy">Copy</button>
                                    </div> -->
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="form-group">
                                    <label class="form-control-label"
                                        cbicTranslate="cbicWebPortalApp.cbicContentMap.link.taxType"
                                        for="field_taxType">Tax
                                        Type</label>
                                    <select class="form-select" id="inputGroupSelect01"
                                        aria-label="Example select with button addon" #childTaxType
                                        (change)="showChildContent(childTaxType.value, childContentType.value, childContentCategory.value)">
                                        <option selected value="undefined">All Taxes</option>
                                        <option *ngFor="let taxMst of cbicTaxMsts" [value]="taxMst.id">
                                            {{taxMst.taxName}}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label class="form-control-label"
                                        cbicTranslate="cbicWebPortalApp.cbicContentMap.link.contentType"
                                        for="field_contentType">Content Type</label>
                                    <select class="form-select " id="inputGroupSelect02"
                                        aria-label="Example select with button addon" #childContentType
                                        (change)="showChildContent(childTaxType.value, childContentType.value, childContentCategory.value)">
                                        <option selected value="undefined">All Contents</option>
                                        <option *ngFor="let contentTest of cbicContents" [value]="contentTest.id">
                                            {{contentTest.contentName}}</option>

                                    </select>
                                </div>
                                <div class="form-group" *ngIf="childContentType.value === 'REGULATIONS'">
                                    <label class="form-control-label"
                                        cbicTranslate="cbicWebPortalApp.cbicContentMap.link.category"
                                        for="field_contentCategory">Category</label>
                                    <select class="form-select actDropDown" id="inputGroupSelect03"
                                        aria-label="Example select with button addon" #childRegulationCategory
                                        (change)="showChildContentCategoryAndName(childRegulationCategory.value, childTaxType.value)">
                                        <option selected value="undefined">All</option>
                                        <option *ngFor="let category of childRegulationCategory1" [value]="category" title="year">
                                            {{category}}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label class="form-control-label"
                                        cbicTranslate="cbicWebPortalApp.cbicContentMap.link.contentCategory"
                                        for="field_contentCategory">Content Category</label>
                                    <select class="form-select actDropDown" id="inputGroupSelect03"
                                        aria-label="Example select with button addon" #childContentCategory
                                        (change)="showChildContentDtls(childTaxType.value, childContentType.value, childContentCategory.value)">
                                        <option selected value="undefined">All</option>
                                        <option *ngFor="let actMst of childContentHeader" [value]="actMst.contentId"
                                            title="{{actMst.contentName}}">
                                            {{actMst.contentNameLable}}</option>
                                    </select>
                                </div>
                                <div class="form-group" *ngIf="childContentType.value === 'NOTIFICATIONS' || childContentType.value === 'CIRCULARS'">
                                    <label class="form-control-label"
                                        cbicTranslate="cbicWebPortalApp.cbicContentMap.link.year"
                                        for="field_contentCategory">Year</label>
                                    <select class="form-select actDropDown" id="inputGroupSelect03"
                                        aria-label="Example select with button addon" #childYear
                                        (change)="showChildtContentDtlsWithYear(childTaxType.value, childContentType.value, childContentCategory.value, childYear.value)">
                                        <option selected value="undefined">All</option>
                                        <option *ngFor="let year of yearList" [value]="year" title="year">
                                            {{year}}</option>
                                        <option value="1999">
                                            < 2000</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label class="form-control-label"
                                        cbicTranslate="cbicWebPortalApp.cbicContentMap.link.contentNo"
                                        for="field_contentNo">Content No</label>
                                    <select class="form-select actDropDown" id="inputGroupSelect03"
                                        aria-label="Example select with button addon" #childContentNo
                                        (change)="showChildLink(childContentNo.value)">
                                        <option selected value="undefined">Select Content Name</option>
                                        <option *ngFor="let content of childContentDtls" [value]="content.contentDtlsId"
                                            title="{{content.contentDtlsName}}">
                                            {{content.contentName}}</option>
                                    </select>
                                </div>
                                <br><br>
                                <div class="row copy-row">
                                    <div class="col-md-12">
                                        <div class="form-group external-link" *ngIf="childContentNo.value">
                                            <label class="form-control-label"
                                                cbicTranslate="cbicWebPortalApp.cbicContentMap.link.externalLink"
                                                for="field_link">Link</label>
                                            <div class="input-group mb-3">
                                                <input class="form-control" type="text" readonly="readonly" #childLink />
                                                <button class="btn btn-primary copy-btn" (click)="copyChildInputMsg()"
                                                value="click to copy">Copy</button>
                                            </div> 
                                            
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-4">
                                        <br>
                                        <button class="btn btn-primary copy-btn" (click)="copyInputMessage(childLink)"
                                            value="click to copy">Copy</button>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div *ngIf="errMsg">{{errMsg}}</div>
                <div *ngIf="successMsg">{{successMsg}}</div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <button class="btn btn-primary link-btn"
                        (click)="linkContent(parentContentNo.value,childContentNo.value)">Link</button>
                </div>
                <div class="col-md-6">
                    <button class="btn btn-primary unlink-btn"
                        (click)="unlinkContent(parentContentNo.value,childContentNo.value)">Unlink</button>
                </div>
            </div>
            <br>
        </div>
    </form>
</div>