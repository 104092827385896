import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { Search } from 'app/core/request/request.model';
import { ICbicCircularMst, getCbicCircularMstIdentifier } from '../cbic-circular-mst.model';
import { RuleMstResponse } from 'app/entities/cbic-rule-mst/cbic-rule-mst.model';

export type EntityResponseType = HttpResponse<ICbicCircularMst>;
export type EntityArrayResponseType = HttpResponse<ICbicCircularMst[]>;

@Injectable({ providedIn: 'root' })
export class CbicCircularMstService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/cbic-circular-msts');
  public resourceSearchUrl = this.applicationConfigService.getEndpointFor('api/_search/cbic-circular-msts');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  
  fetchAllCircularsByTaxId(taxId:number):Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicCircularMst[]>(`${this.resourceUrl}/fetchAllCircularsByTaxId/${taxId}`, {  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  fetchCircularCategory(taxId:number):Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicCircularMst[]>(`${this.resourceUrl}/fetchCircularCategory/${taxId}`, {  observe: 'response' })
      //.pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  fetchCircularByParentId(parentId:number, taxId:string):Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicCircularMst[]>(`${this.resourceUrl}/fetchCircularByParentId/${parentId}/${taxId}`, {  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  download(id: number, language: string):Observable<RuleMstResponse>{
    return this.http.get<RuleMstResponse>(`${this.resourceUrl}/download/${id}/${language}`);
  }

  fetchCategoryRelatedCirculars(taxId:number, category:string):Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicCircularMst[]>(`${this.resourceUrl}/fetchCategoryRelatedCirculars/${taxId}/${category}`, {  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  fetchCircularByYear(circularYear: string) :Observable<EntityArrayResponseType> {
    const options = createRequestOption(circularYear);
    return this.http
      .get<ICbicCircularMst[]>(`${this.resourceUrl}/fetchCircularByYear/${circularYear}`, {  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  fetchCircularByYearCategory(req?:any):Observable<EntityArrayResponseType>{
    const options = createRequestOption(req);
    return this.http
      .get<ICbicCircularMst[]>(`${this.resourceUrl}/fetchCircularByYearCategory`, { params: options,  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  fetchCircularHistory(contentId:number):Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicCircularMst[]>(`${this.resourceUrl}/fetchCircularHistory/${contentId}`, {  observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }


  create(cbicCircularMst: ICbicCircularMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicCircularMst);
    return this.http
      .post<ICbicCircularMst>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(cbicCircularMst: ICbicCircularMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicCircularMst);
    return this.http
      .put<ICbicCircularMst>(`${this.resourceUrl}/${getCbicCircularMstIdentifier(cbicCircularMst) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(cbicCircularMst: ICbicCircularMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicCircularMst);
    return this.http
      .patch<ICbicCircularMst>(`${this.resourceUrl}/${getCbicCircularMstIdentifier(cbicCircularMst) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICbicCircularMst>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicCircularMst[]>(this.resourceUrl, { params: options, observe: 'response' })
      //.pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  search(req: Search): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicCircularMst[]>(this.resourceSearchUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  addCbicCircularMstToCollectionIfMissing(
    cbicCircularMstCollection: ICbicCircularMst[],
    ...cbicCircularMstsToCheck: (ICbicCircularMst | null | undefined)[]
  ): ICbicCircularMst[] {
    const cbicCircularMsts: ICbicCircularMst[] = cbicCircularMstsToCheck.filter(isPresent);
    if (cbicCircularMsts.length > 0) {
      const cbicCircularMstCollectionIdentifiers = cbicCircularMstCollection.map(
        cbicCircularMstItem => getCbicCircularMstIdentifier(cbicCircularMstItem)!
      );
      const cbicCircularMstsToAdd = cbicCircularMsts.filter(cbicCircularMstItem => {
        const cbicCircularMstIdentifier = getCbicCircularMstIdentifier(cbicCircularMstItem);
        if (cbicCircularMstIdentifier == null || cbicCircularMstCollectionIdentifiers.includes(cbicCircularMstIdentifier)) {
          return false;
        }
        cbicCircularMstCollectionIdentifiers.push(cbicCircularMstIdentifier);
        return true;
      });
      return [...cbicCircularMstsToAdd, ...cbicCircularMstCollection];
    }
    return cbicCircularMstCollection;
  }

  protected convertDateFromClient(cbicCircularMst: ICbicCircularMst): ICbicCircularMst {
    return Object.assign({}, cbicCircularMst, {
      createdDt: cbicCircularMst.createdDt?.isValid() ? cbicCircularMst.createdDt.toJSON() : undefined,
      updatedDt: cbicCircularMst.updatedDt?.isValid() ? cbicCircularMst.updatedDt.toJSON() : undefined,
      issueDt: cbicCircularMst.issueDt?.isValid() ? cbicCircularMst.issueDt.toJSON() : undefined,
      amendDt: cbicCircularMst.amendDt?.isValid() ? cbicCircularMst.amendDt.toJSON() : undefined,
      circularDt: cbicCircularMst.circularDt?.isValid() ? cbicCircularMst.circularDt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDt = res.body.createdDt ? dayjs(res.body.createdDt) : undefined;
      res.body.updatedDt = res.body.updatedDt ? dayjs(res.body.updatedDt) : undefined;
      res.body.issueDt = res.body.issueDt ? dayjs(res.body.issueDt) : undefined;
      res.body.amendDt = res.body.amendDt ? dayjs(res.body.amendDt) : undefined;
      res.body.circularDt = res.body.circularDt ? dayjs(res.body.circularDt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((cbicCircularMst: ICbicCircularMst) => {
        cbicCircularMst.createdDt = cbicCircularMst.createdDt ? dayjs(cbicCircularMst.createdDt) : undefined;
        cbicCircularMst.updatedDt = cbicCircularMst.updatedDt ? dayjs(cbicCircularMst.updatedDt) : undefined;
        cbicCircularMst.issueDt = cbicCircularMst.issueDt ? dayjs(cbicCircularMst.issueDt) : undefined;
        cbicCircularMst.amendDt = cbicCircularMst.amendDt ? dayjs(cbicCircularMst.amendDt) : undefined;
        cbicCircularMst.circularDt = cbicCircularMst.circularDt ? dayjs(cbicCircularMst.circularDt) : undefined;
      });
    }
    return res;
  }
}
