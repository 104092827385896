<br/>
<div align="center" class="container">
    <div class="row">
      <div class="col-md-4">
        <span class="hipster img-fluid rounded"></span>
      </div>
  
      <div class="col-md-4">
        <i class="fas fa-exclamation-triangle error-page-style" ></i><h4 cbicTranslate="error.title">Ohh!! Something went Wrong! </h4>
        <br/> 
        <div *ngIf="cbicCoreError">
          <div class="alert alert-danger">{{ cbicCoreError.exceptionMsg}}</div>
          <div class="alert alert-danger">Exception Id is : {{ cbicCoreError.excpetionId}}</div>
        </div>
      </div>
    </div>
  </div>
  <br />
  <br />
  <br />
  <br />
  <br />
  