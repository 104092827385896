import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from 'app/layouts/profiles/profile.service';
import { FormBuilder } from '@angular/forms';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { faSearch} from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import { CbicSearchInput1, ICbicSearchInput } from 'app/searchbar/searchbar.model';
import { CbicSearchService } from 'app/search-page/service/cbic-search-service';
import { CbicSearchOutputResult } from 'app/search-page/search-output.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'cbic-rules-content-search-bar',
  templateUrl: './rules-content-search-bar.component.html',
  styleUrls: ['./rules-content-search-bar.component.scss']
})
export class RulesContentSearchBarComponent implements OnInit {

  sample: any;
  @Input() rulesListFetched:any;
  @Input() rulesChapterList:any;
  @Input() sectionListFetched:any;
  @Output() ruleSelected = new EventEmitter<string>();
  @Output() chapterSelectedForRules = new  EventEmitter<string>();
  selectedRule:any;
  @Input() searchRuleId:any;
  inProduction?: boolean;
  openAPIEnabled?: boolean;
  searchBarFormRule = this.fb.group({
    keyword : "",
    actId : "",
    chapterId : "",
    sectionId:"",
  });
  searchBarFormRuleAll = this.fb.group({
    keywordAll : "",
  });
  faTimesCircle=faTimesCircle;
  searched=false;
  faSearch=faSearch;
  @Output() sectionSelected = new EventEmitter<{sectionId : string, index: number}>();
  @Input() ruleSectionList:any;
  @Input() searchKeyWord='';
  selectedChapter = "";
  @Output() searchFiredRules= new EventEmitter<string>();
  @Output() clearFiredRules= new EventEmitter<string>();
  @Input() rulesCategoryList:any;
  @Output() categorySelected = new  EventEmitter<string>();
  @Input() taxId:any;
  @Input() serviceTaxRuleList:any;
  @ViewChild("ruleSearchKeyword") ruleKeyword? : ElementRef;
  @Input() taxName: any;
  @ViewChild('allSearch', { static: true }) allSearchTextModal!: ElementRef;
  allSearchMsg: any
  @ViewChild("allRuleSearchKeyword") allRuleSearchKeyword? : ElementRef;
  @Input() activeTab:any
  @Input() tabName:any
  storageLanguage:any


  constructor(private router: Router,
    private profileService: ProfileService, 
    private http : HttpClient,
    private fb:FormBuilder,
    private service: CbicSearchService,
    private modalService : NgbModal,
    private sessionStorage: SessionStorageService) { }

  ngOnInit(): void {
    this.storageLanguage = this.sessionStorage.retrieve('locale')
    this.profileService.getProfileInfo().subscribe(profileInfo => {
      this.inProduction = profileInfo.inProduction;
      this.openAPIEnabled = profileInfo.openAPIEnabled;
    });

    this.selectedRule = this.searchRuleId;
  }

  fireRuleSelected(ruleId:string):void{
    this.searched=false;
    this.ruleSelected.emit(ruleId);
  }

  fireChapterSeleted(chapterId:string):void{
    this.chapterSelectedForRules.emit(chapterId);
  }

  setRuleId(ruleId : any):void{
    this.selectedRule = ruleId;
  }

  fireRuleSectionSelected(ruleName: string):void{
    let sectionId = 0;
    let indexI =0;
    // alert(sectionName)
    if(ruleName !== ""){
      for(let i=0;i<this.ruleSectionList.length;i++){
        if(ruleName === String(this.ruleSectionList[i].sectionNo)+'-'+String(this.ruleSectionList[i].sectionName)){
          sectionId = this.ruleSectionList[i].id;
          indexI = i;
        }
      }
      this.sectionSelected.emit({sectionId:String(sectionId), index : indexI});
    }
    
    // alert(sectionId)
    
  }

  search():void{
    
    const cbicSearchInput = this.createFromForm();
    if(cbicSearchInput.keyword === undefined || cbicSearchInput.keyword!.length < 3){
      alert("Please enter valid keyword");
      this.ruleKeyword?.nativeElement.focus();
      return;
    }

    cbicSearchInput.keyword = cbicSearchInput.keyword?.trim()
    for(let i=0;i<this.ruleSectionList.length;i++){
      if(String(this.ruleSectionList[i].sectionNo)+'-'+String(this.ruleSectionList[i].sectionName)===cbicSearchInput.sectionId){
        cbicSearchInput.sectionId=this.ruleSectionList[i].id;
      }
    }
    this.searchFiredRules.emit(JSON.stringify(cbicSearchInput));
    this.searched=true;
    //this.router.navigate(['searchPage/search'],{queryParams : cbicSearchInput});
  }

  searchAll():void{
    
    const cbicSearchInput = this.createAllForm();
    if(cbicSearchInput.keyword === undefined || cbicSearchInput.keyword!.length < 3){
      alert("Please enter valid keyword");
      this.ruleKeyword?.nativeElement.focus();
      return;
    }

    cbicSearchInput.keyword = cbicSearchInput.keyword?.trim()

    this.service.pageNumber = 1;
      this.service.searchInSection(cbicSearchInput).subscribe(
        (res: HttpResponse<CbicSearchOutputResult>) => {
          if(res.body?.totalDocuments === 0){
            this.allSearchMsg = "No search result found."
            this.allRuleSearchKeyword?.nativeElement.focus();
            this.searchBarFormRuleAll = this.fb.group({
              keywordAll : ""
            });
            return;
          } else{
            this.modalService.dismissAll()
            this.service.cbicSearchOutputResults = res.body ?? new CbicSearchOutputResult([], null);
            this.router.navigate(['searchPage/search'],{queryParams : cbicSearchInput});
            //const url = this.router.serializeUrl(this.router.createUrlTree(['searchPage/search'],{queryParams : cbicSearchInput}));

            //window.open(url, '_blank');
          }
         
        });

  }

  clear():void{
    this.searched=false;
    
    this.searchBarFormRule = this.fb.group({
      keyword : "",
      actId : "",
      chapterId : "",
      sectionId:""
    });
    this.clearFiredRules.emit();
    return;
  }

  changeCategorySelection(ruleCategory:string):void{
    this.categorySelected.emit(ruleCategory);

    if(ruleCategory !== "" )
    {
      if(ruleCategory==="All" || ruleCategory==="सभी" )
      {
        this.categorySelected.emit(ruleCategory);
      }else 
      {

        for(let i=0;i<this.rulesCategoryList.length;i++){
        if(ruleCategory === this.rulesCategoryList[i].ruleCategory){
          if(this.storageLanguage==='hi')
         {
          ruleCategory = this.rulesCategoryList[i].ruleCategoryHi;
          this.categorySelected.emit(ruleCategory);
          alert(ruleCategory)
        
         }else{
        
          ruleCategory = this.rulesCategoryList[i].ruleCategory;
           this.categorySelected.emit(ruleCategory);
              }
        }
      }

     }
      
    }


  }

  openAllSearch(): void{
    //this.modalService.open(this.allSearchTextModal,{centered: true});
    this.modalService.open(this.allSearchTextModal,{windowClass : "", centered : true});
  }

  protected createFromForm(): ICbicSearchInput {
    return {
      ...new CbicSearchInput1(),
      keyword: (this.searchBarFormRule.get(['keyword'])!.value === null || this.searchBarFormRule.get(['keyword'])!.value === undefined) ? null : this.searchBarFormRule.get(['keyword'])!.value.trim(),
      actId: this.searchBarFormRule.get(['actId'])!.value,
      chapterId: this.searchBarFormRule.get(['chapterId'])!.value,
      sectionId: this.searchBarFormRule.get(['sectionId'])!.value,
      content: "RULES",
    };
  }

  protected createAllForm(): ICbicSearchInput {
    return {
      ...new CbicSearchInput1(),
      keyword: (this.searchBarFormRuleAll.get(['keywordAll'])!.value === null || this.searchBarFormRuleAll.get(['keywordAll'])!.value === undefined) ? null : this.searchBarFormRuleAll.get(['keywordAll'])!.value.trim(),
      content: "RULES",
      taxId: this.taxId,
      startIndex: 0,
      paginationFlag: true,
      rows: 10
    };
  }

  

  
  

}
