import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[cbicScrollToSelected]'
})
export class ScrollToSelectedDirective {

  @Input() scrollToClass?: string;
  constructor(private el: ElementRef, private renderer: Renderer2) {
  }
  ngAfterViewInit() :void{
    if (this.el.nativeElement.classList.contains(this.scrollToClass)) {
      this.el.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
}
