<div class="link-page">
    <br>
    <div class="link-header">
        <label class="form-control-label" cbicTranslate="cbicWebPortalApp.cbicContentMap.link.history.header"
            for="field_linkHeader"></label>
    </div>
    <br>
    <form>
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">Base / Principal Content</h5>
                            <div class="row">
                                <div class="form-group">
                                    <label class="form-control-label"
                                        cbicTranslate="cbicWebPortalApp.cbicContentMap.link.taxType"
                                        for="field_taxType">Tax
                                        Type</label>
                                    <select class="form-select" id="inputGroupSelect01"
                                        aria-label="Example select with button addon" #parentTaxType
                                        (change)="showParentContent(parentTaxType.value, parentContentType.value, parentContentCategory.value)">
                                        <option selected value="undefined">All Taxes</option>
                                        <option *ngFor="let taxMst of cbicTaxMsts" [value]="taxMst.id">
                                            {{taxMst.taxName}}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label class="form-control-label"
                                        cbicTranslate="cbicWebPortalApp.cbicContentMap.link.contentType"
                                        for="field_contentType">Content Type</label>
                                    <select class="form-select " id="inputGroupSelect02"
                                        aria-label="Example select with button addon" #parentContentType
                                        (change)="showParentContent(parentTaxType.value, parentContentType.value, parentContentCategory.value)">
                                        <option selected value="undefined">All Contents</option>
                                        <option *ngFor="let contentTest of cbicContents" [value]="contentTest.id">
                                            {{contentTest.contentName}}</option>

                                    </select>
                                </div>

                                <div class="form-group">
                                    <label class="form-control-label"
                                        cbicTranslate="cbicWebPortalApp.cbicContentMap.link.contentCategory"
                                        for="field_contentCategory">Content Category</label>
                                    <select class="form-select actDropDown" id="inputGroupSelect03"
                                        aria-label="Example select with button addon" #parentContentCategory
                                        (change)="showParentContentDtls(parentTaxType.value, parentContentType.value, parentContentCategory.value)">
                                        <option selected value="undefined">All</option>
                                        <option *ngFor="let actMst of parentContentHeader" [value]="actMst.contentId"
                                            title="{{actMst.contentName}}">
                                            {{actMst.contentNameLable}}</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <label class="form-control-label"
                                        cbicTranslate="cbicWebPortalApp.cbicContentMap.link.year"
                                        for="field_contentCategory">Year</label>
                                    <select class="form-select actDropDown" id="inputGroupSelect03"
                                        aria-label="Example select with button addon" #parentYear
                                        (change)="showParentContentDtlsWithYear(parentTaxType.value, parentContentType.value, parentContentCategory.value, parentYear.value)">
                                        <option selected value="undefined">All</option>
                                        <option *ngFor="let year of yearList" [value]="year" title="year">
                                            {{year}}</option>
                                        <option value="1999">
                                            < 2000</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label class="form-control-label"
                                        cbicTranslate="cbicWebPortalApp.cbicContentMap.link.contentNo"
                                        for="field_contentNo">Content No</label>
                                    <!-- <select class="form-select actDropDown" id="inputGroupSelect03"
                                        aria-label="Example select with button addon" #parentContentNo
                                        >
                                        <option selected value="undefined">Select Content Name</option>
                                        <option *ngFor="let content of parentContentDtls"
                                            [value]="content.contentDtlsId" title="{{content.contentDtlsName}}">
                                            {{content.contentName}}</option>
                                    </select> -->
                                    <input class="form-control" list="datalistOptionsForms" id="exampleDataListForms"
                                        #parentContentNo placeholder="Enter Content Name"
                                        (change)="getHistory(parentContentNo.value)">
                                    <!-- (change)="fireRuleSectionSelected(sectionSelection.value)" -->

                                    <datalist id="datalistOptionsForms">
                                        <option *ngFor="let content of parentContentDtls; let i = index"
                                            data-value="{{content.contentDtlsId}}" value="{{content.contentName}}"
                                            title="{{content.contentDtlsName}}"></option>
                                        <input type="hidden" name="answer" id="exampleDataListForms-hidden">
                                    </datalist>
                                </div>
                                <div class="form-group">
                                    <label class="form-control-label"
                                        cbicTranslate="cbicWebPortalApp.cbicContentMap.link.rs"
                                        for="field_contentCategory">Rescinded / Superseded</label>
                                    <select class="form-select actDropDown" id="inputGroupSelect03"
                                        aria-label="Example select with button addon" #ntRemarks>
                                        <option selected value="undefined">Select value</option>
                                        <option value="Rescinded / Superseded">
                                            Rescinded / Superseded</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">Amendments</h5>
                            <div class="row">
                                <div class="form-group">
                                    <label class="form-control-label"
                                        cbicTranslate="cbicWebPortalApp.cbicContentMap.link.taxType"
                                        for="field_taxType">Tax
                                        Type</label>
                                    <select class="form-select" id="inputGroupSelect01"
                                        aria-label="Example select with button addon" #childTaxType
                                        (change)="showChildContent(childTaxType.value, childContentType.value, childContentCategory.value)">
                                        <option selected value="undefined">All Taxes</option>
                                        <option *ngFor="let taxMst of cbicTaxMsts" [value]="taxMst.id">
                                            {{taxMst.taxName}}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label class="form-control-label"
                                        cbicTranslate="cbicWebPortalApp.cbicContentMap.link.contentType"
                                        for="field_contentType">Content Type</label>
                                    <select class="form-select " id="inputGroupSelect02"
                                        aria-label="Example select with button addon" #childContentType
                                        (change)="showChildContent(childTaxType.value, childContentType.value, childContentCategory.value)">
                                        <option selected value="undefined">All Contents</option>
                                        <option *ngFor="let contentTest of cbicContents" [value]="contentTest.id">
                                            {{contentTest.contentName}}</option>

                                    </select>
                                </div>
                                <div class="form-group">
                                    <label class="form-control-label"
                                        cbicTranslate="cbicWebPortalApp.cbicContentMap.link.contentCategory"
                                        for="field_contentCategory">Content Category</label>
                                    <select class="form-select actDropDown" id="inputGroupSelect03"
                                        aria-label="Example select with button addon" #childContentCategory
                                        (change)="showChildContentDtls(childTaxType.value, childContentType.value, childContentCategory.value)">
                                        <option selected value="undefined">All</option>
                                        <option *ngFor="let actMst of childContentHeader" [value]="actMst.contentId"
                                            title="{{actMst.contentName}}">
                                            {{actMst.contentNameLable}}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label class="form-control-label"
                                        cbicTranslate="cbicWebPortalApp.cbicContentMap.link.year"
                                        for="field_contentCategory">Year</label>
                                    <select class="form-select actDropDown" id="inputGroupSelect03"
                                        aria-label="Example select with button addon" #childYear
                                        (change)="showChildtContentDtlsWithYear(childTaxType.value, childContentType.value, childContentCategory.value, childYear.value)">
                                        <option selected value="undefined">All</option>
                                        <option *ngFor="let year of yearList" [value]="year" title="year">
                                            {{year}}</option>
                                        <option value="1999">
                                            < 2000</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label class="form-control-label"
                                        cbicTranslate="cbicWebPortalApp.cbicContentMap.link.contentNo"
                                        for="field_contentNo">Content No</label>
                                    <input class="form-control" list="datalistOptionsForms1" id="exampleDataListForms"
                                        #childContentNo placeholder="Enter Content Name"
                                        (change)="clearFileOnChildContent()">
                                    <!-- (change)="fireRuleSectionSelected(sectionSelection.value)" -->

                                    <datalist id="datalistOptionsForms1">
                                        <option *ngFor="let childContent of childContentDtls; let i = index"
                                            data-value="{{childContent.contentDtlsId}}"
                                            value="{{childContent.contentName}}"
                                            title="{{childContent.contentDtlsName}}"></option>
                                        <!-- <input type="hidden" name="answer1" id="exampleDataListForms-hidden1"> -->
                                    </datalist>
                                    <!-- <datalist class="form-select actDropDown" id="inputGroupSelect03"
                                        aria-label="Example select with button addon" #childContentNo>
                                        <option selected value="undefined">Select Content Name</option>
                                        <option *ngFor="let content of childContentDtls" [value]="content.contentDtlsId"
                                            title="{{content.contentDtlsName}}">
                                            {{content.contentName}}</option>
                                    </datalist> -->
                                </div>
                                <br />
                                <br />
                                <div class="form-group" style="margin-top: 15px;">
                                    <input type="file" (change)="selectFile($event)" #fileInput />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div *ngIf="errMsg">{{errMsg}}</div>
                <div *ngIf="successMsg">{{successMsg}}</div>
            </div>
            <div class="outer-btn">
                <div class="inner-btn">
                    <button class="btn btn-primary link-btn"
                        (click)="linkHis(parentContentNo.value, childContentNo.value)">Link</button>
                </div>
                <div class="inner-btn">
                    <button class="btn btn-primary unlink-btn"
                        (click)="unlinkHistory(parentContentNo.value, childContentNo.value)">Unlink</button>
                </div>
                <div class="inner-btn">
                    <button class="btn btn-primary unlink-btn" (click)="clear()">Clear</button>
                </div>
            </div>
            <br>
        </div>
    </form>
    <div class="container" *ngIf="historyDtls && historyDtls.length > 0">
        <div class="row">
            <div class="history-view">
                <div class="result-data">
                    <div class="table-responsive">
                        <table class="table table-hover">
                            <tbody>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Base Notification</th>
                                    <th>Amendment Notification</th>
                                    <th>Document as on Date</th>
                                    <th>Upload Document</th>
                                </tr>
                                <tr *ngFor="let history of historyDtls, let i = index">
                                    <td>{{i+1}}</td>
                                    <td>{{history.parentNotificationNo}} - {{history.parentNotificationName}}</td>
                                    <td>{{history.childNotificationNo}} - {{history.childNotificationName}}</td>
                                    <td>
                                        <div class="notification-download-container input-group flex-nowrap" *ngIf="history.docFilePathAOD">
                                            <a (click)="viewFile(history.id)" class="myLink2">View</a>
                                            <br>
                                            <a (click)="delete(history.id, parentContentNo.value)" class="myLink4"><i class="fa fa-trash"></i></a>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="notification-download-container input-group flex-nowrap" *ngIf="!history.docFilePathAOD">
                                            <input type="file" class="myLink3" (change)="selectFile2($event)" #fileInput2/>
                                            <br>
                                            <a (click)="updateFile(history.id, parentContentNo.value)" class="myLink4"><i class="fa fa-file-upload"></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br><br>
</div>