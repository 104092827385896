import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { ICbicActSectionMst, CbicActSectionMst } from '../entities/cbic-act-section-mst/cbic-act-section-mst.model';
import { CbicActSectionMstService } from '../entities/cbic-act-section-mst/service/cbic-act-section-mst.service'
import { CbicRuleSectionMstService } from 'app/entities/cbic-rule-section-mst/service/cbic-rule-section-mst.service';
import { CbicRuleSectionMst } from 'app/entities/cbic-rule-section-mst/cbic-rule-section-mst.model';
import { CbicActMstService } from 'app/entities/cbic-act-mst/service/cbic-act-mst.service';
import { CbicActMst } from 'app/entities/cbic-act-mst/cbic-act-mst.model';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { TranslateService } from '@ngx-translate/core';


@Injectable({ providedIn: 'root' })
export class ContentPageRoutingResolveService implements Resolve<any> {
  constructor(
    protected service: CbicActSectionMstService,
    protected actMstService:CbicActMstService, 
    protected router: Router,
    private sessionStorage: SessionStorageService,
    private localStorage: LocalStorageService,
    private translateService: TranslateService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Observable<never> {
    const sectionId = route.params['sectionId'];
    const taxId = route.params['taxId'];
    const language = route.params['language']
    this.sessionStorage.store('locale',language)
    const localLang = this.localStorage.retrieve('language')
    if(localLang){
      this.sessionStorage.store('locale',localLang)
      this.translateService.use(localLang);
    }

    if(taxId === "1000004"){
      return this.actMstService.find(sectionId).pipe(
        mergeMap((actMst : HttpResponse<CbicActMst>) =>
        {
          if(actMst.body){
            return of(actMst.body);
          }else {
            this.router.navigate(['404']);
            return EMPTY;
          }
        })
      );
  }else{
      if (sectionId) {
        return this.service.find(sectionId).pipe(
          mergeMap((cbicActSectionMst: HttpResponse<CbicActSectionMst>) => {
            if (cbicActSectionMst.body) {
              return of(cbicActSectionMst.body);
            } else {
              this.router.navigate(['404']);
              return EMPTY;
            }
          })
        );
      }
      return of(new CbicActSectionMst());
    }
  }
}
