import { HttpResponse } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { CbicContentHistoryMapDtls, HistoryRequest, HistoryRequestV2, HistoryResponse, HistoryResponseV2, ICbicContentHistoryMapDtls } from "app/entities/cbic-content-history-map-dtls/cbic-content-history-map-dtls.model";
import { CbicContentHistoryMapDtlsService } from "app/entities/cbic-content-history-map-dtls/service/cbic-content-history-map-dtls.service";
import { CbicContentMap2, CbicContentMapResponse } from "app/entities/cbic-content-map/cbic-content-map.model";
import { CbicContentMapService } from "app/entities/cbic-content-map/service/cbic-content-map.service";
import { ICbicTaxMst } from "app/entities/cbic-tax-mst/cbic-tax-mst.model";
import { CbicTaxMstService } from "app/entities/cbic-tax-mst/service/cbic-tax-mst.service";
import { CbicVisitorCountService } from "app/entities/cbic-visitor-count/service/cbic-visitor-count.service";
import { CbicContent, CbicContentDtls, CbicContentHeader, CbicContentLink, CbicContentLink1, CbicContentLink2, CbicContentLinkHistory, CbicContentSearch, CbicContentSearchHistory, FetchLink, ICbicContent, RegulationDtls } from "app/searchbar/searchbar.model";

@Component({
  selector: "cbic-link-history",
  templateUrl: "./link-history.component.html"
})
export class LinkHistoryComponent implements OnInit {

  cbicTaxMsts?: ICbicTaxMst[];
  cbicContents?: ICbicContent[];
  cbicContentHeader?: CbicContentHeader[];
  parentContentHeader?: CbicContentHeader[];
  childContentHeader?: CbicContentHeader[];
  cbicContentDtls?: CbicContentDtls[];
  parentContentDtls?: CbicContentDtls[];
  childContentDtls?: CbicContentDtls[];
  errMsg?: string;
  cbicContentMapResponse?: CbicContentMapResponse | null;
  successMsg?: string;
  @ViewChild("childTaxType") childTaxType!: ElementRef;
  @ViewChild("childContentType") childContentType!: ElementRef;
  contentsLink?: Map<string, string>;
  yearList: any;
  tempYear: any;
  @ViewChild("parentYear") parentYear!: ElementRef;
  @ViewChild("childYear") childYear!: ElementRef;
  fetchLink: any;
  // @ViewChild("parentLink") parentLink!: ElementRef;
  // @ViewChild("childLink") childLink!: ElementRef;
  regulationCategory?: string[];
  parentRegulationCategory1?: string[];
  childRegulationCategory1?: string[];
  @ViewChild("parentRegulationCategory") category!: ElementRef;
  @ViewChild("childRegulationCategory") childCategory!: ElementRef;
  @ViewChild("childContentCategory") childContentCategory!: ElementRef;
  selectedFiles: any;
  historyDtls!: ICbicContentHistoryMapDtls[];
  historyResponse?: HistoryResponseV2 | null;
  fileReq? : File;
  @ViewChild("childContentNo") childContentNo!: ElementRef;
  @ViewChild("parentContentNo") parentContentNo!: ElementRef;
  @ViewChild("ntRemarks") ntRemarks!: ElementRef;
  @ViewChild("fileInput") fileInput!: ElementRef;
  @ViewChild("fileInput2") fileInput2!: ElementRef;
  selectedFiles2: any;
  fileReq2?: File;
  constructor(
    private taxMstService: CbicTaxMstService,
    private contentMapService: CbicContentMapService,
    private historyService: CbicContentHistoryMapDtlsService,
    private testService: CbicVisitorCountService,
    private router : Router
  ) {
  }

  ngOnInit(): void {
    this.loadTaxMsts();
    this.loadContents();
    this.loadActs();
    this.loadContentDtls();
    this.loadContentMap();
    this.loadYearList();
    this.loadRegualtionCategory()
  }

  loadRegualtionCategory(): void {
    this.taxMstService.fetchRegulationCategory(null).subscribe(
      (res: HttpResponse<string[]>) => {
        if (res.body) {
          this.regulationCategory = res.body;
          this.parentRegulationCategory1 = this.regulationCategory;
          this.childRegulationCategory1 = this.regulationCategory;
        }

      }
    );

  }

  loadYearList(): void {
    this.tempYear = [];
    for (let i = 2000; i <= new Date().getFullYear(); i++) {
      this.tempYear.push(i);
    }
    this.tempYear.reverse()
    this.yearList = this.tempYear;
  }

  loadContentMap(): void {
    const contentMap = new Map();
    contentMap.set('CIRCULARS', 'CIRCULARS');
    contentMap.set('NOTIFICATIONS', 'NOTIFICATIONS');
    this.contentsLink = contentMap;
  }

  loadTaxMsts(): void {
    this.taxMstService.query().subscribe(
      (res: HttpResponse<ICbicTaxMst[]>) => {
        this.cbicTaxMsts = res.body ?? [];
      }
    );
  }

  loadContents(): void {
    this.cbicContents = [];
    this.cbicContents.push(
      new CbicContent('NOTIFICATIONS', 'Notifications'),
      new CbicContent('CIRCULARS', 'Circulars')
    )
  }


  loadActs(): void {
    this.taxMstService.fetchContentHeader(new CbicContentSearchHistory("", "", "Y")).subscribe(
      (res: HttpResponse<CbicContentHeader[]>) => {
        this.cbicContentHeader = res.body ?? [];
        for (let i = 0; i < this.cbicContentHeader.length; i++) {
          let maxlen = this.cbicContentHeader[i].contentName.length;
          if (maxlen > 70) {
            maxlen = 67;
          }
          this.cbicContentHeader[i].contentNameLable = String(this.cbicContentHeader[i].contentName.substring(0, maxlen));
          if (maxlen === 67) {
            this.cbicContentHeader[i].contentNameLable = String(this.cbicContentHeader[i].contentName.substring(0, maxlen)) + "...";
          }
        }
        this.parentContentHeader = this.cbicContentHeader;
        this.childContentHeader = this.cbicContentHeader;
      }
    );

  }

  loadContentDtls(): void {
    this.taxMstService.fetchContentDtls(new CbicContentLinkHistory("", "", "", "Y")).subscribe(
      (res: HttpResponse<CbicContentDtls[]>) => {
        this.cbicContentDtls = res.body ?? [];
        for (let i = 0; i < this.cbicContentDtls.length; i++) {
          let maxlen = this.cbicContentDtls[i].contentDtlsName.length;
          if (maxlen > 70) {
            maxlen = 67;
          }
          this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen));
          if (maxlen === 67) {
            this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen)) + "...";
          }
        }
        this.parentContentDtls = this.cbicContentDtls;
        this.childContentDtls = this.cbicContentDtls;
      }
    );

  }

  showParentContent(taxId: any, contentType: any, contentCategory: any): void {
    let taxRegulationCategory: any = null;
    if (taxId === "undefined") {
      taxId = "";
    }
    if (contentType === "undefined") {
      contentType = "";
    }

    this.taxMstService.fetchContentHeader(new CbicContentSearchHistory(taxId, contentType,"Y")).subscribe(
      (res: HttpResponse<CbicContentHeader[]>) => {
        this.cbicContentHeader = res.body ?? [];
        for (let i = 0; i < this.cbicContentHeader.length; i++) {
          let maxlen = this.cbicContentHeader[i].contentName.length;
          if (maxlen > 70) {
            maxlen = 67;
          }
          this.cbicContentHeader[i].contentNameLable = String(this.cbicContentHeader[i].contentName.substring(0, maxlen));
          if (maxlen === 67) {
            this.cbicContentHeader[i].contentNameLable = String(this.cbicContentHeader[i].contentName.substring(0, maxlen)) + "...";
          }
        }
        this.parentContentHeader = this.cbicContentHeader;
      }
    );
    this.taxMstService.fetchContentDtls(new CbicContentLinkHistory(taxId, contentType, "", "Y")).subscribe(
      (res: HttpResponse<CbicContentDtls[]>) => {
        this.cbicContentDtls = res.body ?? [];
        for (let i = 0; i < this.cbicContentDtls.length; i++) {
          let maxlen = this.cbicContentDtls[i].contentDtlsName.length;
          if (maxlen > 70) {
            maxlen = 67;
          }
          this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen));
          if (maxlen === 67) {
            this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen)) + "...";
          }
        }
        this.parentContentDtls = this.cbicContentDtls;
        if (contentType !== "" && (contentType === 'NOTIFICATIONS' || contentType === 'CIRCULARS')) {
          this.parentYear.nativeElement.value = "undefined";
          // this.parentLink.nativeElement.value = "";
          this.clearContent();
        }
        if (contentType !== "" && contentType === 'REGULATIONS') {
          this.category.nativeElement.value = "undefined";
          if (taxId !== "") {
            taxRegulationCategory = taxId;
          }
          this.taxMstService.fetchRegulationCategory(taxRegulationCategory).subscribe(
            (res2: HttpResponse<string[]>) => {
              if (res2.body) {
                this.regulationCategory = res2.body;
                this.parentRegulationCategory1 = this.regulationCategory;
              }

            }
          );
        }
      }
    );

    if (taxId !== "") {
      this.childTaxType.nativeElement.value = taxId;
      if (contentType !== "") {
        if (this.contentsLink?.has(contentType)) {
          this.childContentType.nativeElement.value = this.contentsLink.get(contentType);
          this.showChildContent(taxId, this.contentsLink.get(contentType), "");
        } else {
          this.showChildContent(taxId, "", "");
        }
      } else {
        this.showChildContent(taxId, "", "");
      }
    } else {
      if (contentType !== "") {
        if (this.contentsLink?.has(contentType)) {
          this.childContentType.nativeElement.value = this.contentsLink.get(contentType);
          this.showChildContent("", this.contentsLink.get(contentType), "");
        }
      }
    }
    
  }

  showParentContentDtls(taxId: any, contentType: any, contentCategory: any): void {
    let contentCategory1;
    let contentType1;
    if (taxId === "undefined") {
      taxId = "";
    }
    if (contentType === "undefined") {
      contentType = "";
    }
    if (contentCategory === "undefined") {
      contentCategory = "";
    }
    if (contentCategory !== "") {
      const content = String(contentCategory).split("_");
      contentCategory1 = content[0];
      if (contentType === "") {
        contentType1 = content[1].toUpperCase();
      } else {
        contentType1 = contentType;
      }

    }
    this.taxMstService.fetchContentDtls(new CbicContentLinkHistory(taxId, contentType1, contentCategory1, "Y")).subscribe(
      (res: HttpResponse<CbicContentDtls[]>) => {
        this.cbicContentDtls = res.body ?? [];
        for (let i = 0; i < this.cbicContentDtls.length; i++) {
          let maxlen = this.cbicContentDtls[i].contentDtlsName.length;
          if (maxlen > 70) {
            maxlen = 67;
          }
          this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen));
          if (maxlen === 67) {
            this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen)) + "...";
          }
        }
        this.parentContentDtls = this.cbicContentDtls;
        if (contentType !== "" && (contentType === 'NOTIFICATIONS' || contentType === 'CIRCULARS')) {
          this.parentYear.nativeElement.value = "undefined";
          // this.parentLink.nativeElement.value = "";
          this.clearContent();
        }
      }
    );
    if (contentCategory !== "") {
      this.childContentCategory.nativeElement.value = contentCategory;
      this.showChildContentDtls(taxId, contentType, contentCategory);
    }
  }

  showChildContent(taxId: any, contentType: any, contentCategory: any): void {
    let taxRegulationCategory: any = null;
    if (taxId === "undefined") {
      taxId = "";
    }
    if (contentType === "undefined") {
      contentType = "";
    }
    if (contentCategory === "undefined") {
      contentCategory = "";
    }

    this.taxMstService.fetchContentHeader(new CbicContentSearchHistory(taxId, contentType,"Y")).subscribe(
      (res: HttpResponse<CbicContentHeader[]>) => {
        this.cbicContentHeader = res.body ?? [];
        for (let i = 0; i < this.cbicContentHeader.length; i++) {
          let maxlen = this.cbicContentHeader[i].contentName.length;
          if (maxlen > 70) {
            maxlen = 67;
          }
          this.cbicContentHeader[i].contentNameLable = String(this.cbicContentHeader[i].contentName.substring(0, maxlen));
          if (maxlen === 67) {
            this.cbicContentHeader[i].contentNameLable = String(this.cbicContentHeader[i].contentName.substring(0, maxlen)) + "...";
          }
        }
        this.childContentHeader = this.cbicContentHeader;
      }
    );
    this.taxMstService.fetchContentDtls(new CbicContentLinkHistory(taxId, contentType, "", "Y")).subscribe(
      (res: HttpResponse<CbicContentDtls[]>) => {
        this.cbicContentDtls = res.body ?? [];
        for (let i = 0; i < this.cbicContentDtls.length; i++) {
          let maxlen = this.cbicContentDtls[i].contentDtlsName.length;
          if (maxlen > 70) {
            maxlen = 67;
          }
          this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen));
          if (maxlen === 67) {
            this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen)) + "...";
          }
        }
        this.childContentDtls = this.cbicContentDtls;
        if (contentType !== "" && (contentType === 'NOTIFICATIONS' || contentType === 'CIRCULARS')) {
          this.childYear.nativeElement.value = "undefined";
          // this.childLink.nativeElement.value = "";
          this.childContentNo.nativeElement.value = "";
          if(this.selectedFiles){
            this.fileInput.nativeElement.value = "";
            this.selectedFiles = undefined;
          }
        }
        if (contentType !== "" && contentType === 'REGULATIONS') {
          this.childCategory.nativeElement.value = "undefined";
          if (taxId !== "") {
            taxRegulationCategory = taxId;
          }
          this.taxMstService.fetchRegulationCategory(taxRegulationCategory).subscribe(
            (res2: HttpResponse<string[]>) => {
              if (res2.body) {
                this.regulationCategory = res2.body;
                this.childRegulationCategory1 = this.regulationCategory;
              }

            }
          );
        }
      }
    );
  }

  showChildContentDtls(taxId: any, contentType: any, contentCategory: any): void {
    let contentCategory1;
    let contentType1;
    if (taxId === "undefined") {
      taxId = "";
    }
    if (contentType === "undefined") {
      contentType = "";
    }
    if (contentCategory === "undefined") {
      contentCategory = "";
    }
    if (contentCategory !== "") {
      const content = String(contentCategory).split("_");
      contentCategory1 = content[0];
      if (contentType === "") {
        contentType1 = content[1].toUpperCase();
      } else {
        contentType1 = contentType;
      }

    }
    this.taxMstService.fetchContentDtls(new CbicContentLinkHistory(taxId, contentType1, contentCategory1, "Y")).subscribe(
      (res: HttpResponse<CbicContentDtls[]>) => {
        this.cbicContentDtls = res.body ?? [];
        for (let i = 0; i < this.cbicContentDtls.length; i++) {
          let maxlen = this.cbicContentDtls[i].contentDtlsName.length;
          if (maxlen > 70) {
            maxlen = 67;
          }
          this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen));
          if (maxlen === 67) {
            this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen)) + "...";
          }
        }
        this.childContentDtls = this.cbicContentDtls;
        if (contentType !== "" && (contentType === 'NOTIFICATIONS' || contentType === 'CIRCULARS')) {
          this.childYear.nativeElement.value = "undefined";
          // this.childLink.nativeElement.value = "";
          this.childContentNo.nativeElement.value = "";
          if(this.selectedFiles){
            this.fileInput.nativeElement.value = "";
            this.selectedFiles = undefined;
          }
        }
      }
    );
  }

  showParentContentDtlsWithYear(taxType: any, contentType: any, contentCategory: any, year: any): void {
    if (taxType === "undefined") {
      taxType = "";
    }
    if (contentCategory === "undefined") {
      contentCategory = "";
    }
    if (year === "undefined") {
      year = "";
    }
    this.taxMstService.fetchContentByYear(new CbicContentLink1(taxType, contentType, contentCategory, year)).subscribe(
      (res: HttpResponse<CbicContentDtls[]>) => {
        this.cbicContentDtls = res.body ?? [];
        for (let i = 0; i < this.cbicContentDtls.length; i++) {
          let maxlen = this.cbicContentDtls[i].contentDtlsName.length;
          if (maxlen > 70) {
            maxlen = 67;
          }
          this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen));
          if (maxlen === 67) {
            this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen)) + "...";
          }
        }
        this.parentContentDtls = this.cbicContentDtls;
        // this.parentLink.nativeElement.value = "";
        this.clearContent();
      }
    );
    
  }

  showChildtContentDtlsWithYear(taxType: any, contentType: any, contentCategory: any, year: any): void {
    if (taxType === "undefined") {
      taxType = "";
    }
    if (contentCategory === "undefined") {
      contentCategory = "";
    }
    if (year === "undefined") {
      year = "";
    }
    if (contentType === "undefined") {
      contentType = "";
    }
    this.taxMstService.fetchContentByYear(new CbicContentLink1(taxType, contentType, contentCategory, year)).subscribe(
      (res: HttpResponse<CbicContentDtls[]>) => {
        this.cbicContentDtls = res.body ?? [];
        for (let i = 0; i < this.cbicContentDtls.length; i++) {
          let maxlen = this.cbicContentDtls[i].contentDtlsName.length;
          if (maxlen > 70) {
            maxlen = 67;
          }
          this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen));
          if (maxlen === 67) {
            this.cbicContentDtls[i].contentName = String(this.cbicContentDtls[i].contentDtlsName.substring(0, maxlen)) + "...";
          }
        }
        this.childContentDtls = this.cbicContentDtls;
        // this.childLink.nativeElement.value = "";
        this.childContentNo.nativeElement.value = "";
        if(this.selectedFiles){
          this.fileInput.nativeElement.value = "";
          this.selectedFiles = undefined;
        }
      }
    );
  }

  copyInputMessage(inputElement: ElementRef): void {
    inputElement.nativeElement.select();
    document.execCommand('copy');
    inputElement.nativeElement.setSelectionRange(0, 0);
  }

  copyParentInputMsg(): void {
    // this.parentLink.nativeElement.select();
    document.execCommand('copy');
    // this.parentLink.nativeElement.setSelectionRange(0, 0);
  }

  copyChildInputMsg(): void {
    // this.childLink.nativeElement.select();
    document.execCommand('copy');
    // this.childLink.nativeElement.setSelectionRange(0, 0);
  }

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }

  linkHis(parentContent: any, childContent: any): void {
    //  this.historyService.linkHistory();
    this.errMsg = "";
    this.successMsg = "";
    let remarks;
    if (parentContent === "" || parentContent === null || childContent === "" || childContent === null) {
      this.errMsg = "Please Select Content No";
    } else {
      if (parentContent === childContent) {
        this.errMsg = "You can not link same content";
      } else {
        const parentContentId = this.parentContentDtls?.find(x => x.contentName === parentContent)?.contentDtlsId;
        const childContentId = this.childContentDtls?.find(x => x.contentName === childContent)?.contentDtlsId;

        const parentContentArr = String(parentContentId).split("_");
        const childContentArr = String(childContentId).split("_");
        const parentId = parentContentArr[0];
        const parentType = parentContentArr[1];
        const childId = childContentArr[0];
        const childType = childContentArr[1];
        if(this.ntRemarks.nativeElement.value !== "undefined"){
          remarks = this.ntRemarks.nativeElement.value;
        }else{
          remarks = "";
        }
        if(this.selectedFiles){
          this.fileReq = this.selectedFiles.item(0);
          if(this.fileReq?.type !== "application/pdf" ){
            this.errMsg = "Please upload .pdf file only";
          }else{
            this.historyService.linkHistory(new HistoryRequest(Number(parentId), Number(childId), parentType, String(remarks)), this.selectedFiles.item(0)).subscribe(
              (res : HttpResponse<HistoryResponseV2>)=>{
                if(res.body){
                  this.historyResponse = res.body;
                  if(this.historyResponse.result){
                    this.successMsg = String(this.historyResponse.msg);
                  }else{
                    this.errMsg = String(this.historyResponse.msg);
                  }
                  this.getHistory(parentContent);
                }
              }
            );
          }
        }else{
          this.historyService.linkHistoryWithoutFile(new HistoryRequest(Number(parentId), Number(childId), parentType,String(remarks))).subscribe(
            (res : HttpResponse<HistoryResponseV2>)=>{
              if(res.body){
                this.historyResponse = res.body;
                if(this.historyResponse.result){
                  this.successMsg = String(this.historyResponse.msg);
                }else{
                  this.errMsg = String(this.historyResponse.msg);
                }
                this.getHistory(parentContent);
                this.clearAfterLink();
              }
            }
          );
        }

        
      }
    }
  }

  test(): void {
    this.testService.find(1).subscribe(
      res => alert(res.body)
    );
  }

  clear():void{
    this.childContentNo.nativeElement.value="";
    if(this.selectedFiles){
      this.fileInput.nativeElement.value = "";
      this.selectedFiles = undefined;
    }
  }

  getHistory(parentContent: any): void {
    const parentContentId = this.parentContentDtls?.find(x => x.contentName === parentContent)?.contentDtlsId;
    const parentContentArr = String(parentContentId).split("_");
    const parentId = parentContentArr[0];
    const parentType = parentContentArr[1];

    this.historyService.getHistory(Number(parentId)).subscribe(
      (res: HttpResponse<ICbicContentHistoryMapDtls[]>) => {
        if (res.body) {
          this.historyDtls = res.body;
        }
      }
    );

  }

  unlinkHistory(parentContent: any, childContent: any): void {
    this.errMsg = "";
    this.successMsg = "";
    if (parentContent === "" || parentContent === null || childContent === "" || childContent === null) {
      this.errMsg = "Please Select Content No";
    } else {
      if (parentContent === childContent) {
        this.errMsg = "You can not link same content";
      } else {
        const parentContentId = this.parentContentDtls?.find(x => x.contentName === parentContent)?.contentDtlsId;
        const childContentId = this.childContentDtls?.find(x => x.contentName === childContent)?.contentDtlsId;

        const parentContentArr = String(parentContentId).split("_");
        const childContentArr = String(childContentId).split("_");
        const parentId = parentContentArr[0];
        const parentType = parentContentArr[1];
        const childId = childContentArr[0];
        const childType = childContentArr[1];
        this.historyService.unlinkContent(new HistoryRequest(Number(parentId), Number(childId))).subscribe(
          (res: HttpResponse<HistoryResponseV2>) => {
            this.historyResponse = res.body;
            if (this.historyResponse?.result) {
              this.successMsg = "UnLink between contents is successful";
            } else {
              this.successMsg = "Link does not exist between these contents";
            }
            this.getHistory(parentContent);
          }

        );
      }
    }
  }

  clearContent():void{
    this.childContentNo.nativeElement.value = "";
    this.parentContentNo.nativeElement.value = "";
    if(this.selectedFiles){
      this.fileInput.nativeElement.value = "";
      this.selectedFiles = undefined;
    }
    this.ntRemarks.nativeElement.value = "undefined";
  }

  clearAfterLink():void{
    if(this.selectedFiles){
      this.fileInput.nativeElement.value = "";
      this.selectedFiles = undefined;
    }
    this.ntRemarks.nativeElement.value = "undefined";
  }

  clearFileOnChildContent():void{
    if(this.selectedFiles){
      this.fileInput.nativeElement.value = "";
      this.selectedFiles = undefined;
    }
  }

  selectFile2(event: any): void {
    this.selectedFiles2 = event.target.files;
  }

  viewFile(id: any) :void{
    const type= 'ENG';
    const selectedTab= 'History';
    const link = this.router.serializeUrl(this.router.createUrlTree(['view-pdf/'+ String(id)+ '/'+ String(type) +'/'+String(selectedTab)]));  
    window.open(link, '_blank');
  }

  delete(id:any, parentContent: any):void{
    this.successMsg = "";
    this.errMsg = "";
    this.historyService.deleteFile(new HistoryRequestV2(Number(id))).subscribe(
      (res:HttpResponse<HistoryResponseV2>) =>{
        if(res.body){
          const resultObj = res.body;
          if(resultObj.result){
            this.successMsg = String(resultObj.msg);
          }
          this.getHistory(parentContent);
        }
      }
    );
  }

  updateFile(id:any, parentContent: any):void{
    this.successMsg = "";
    this.errMsg = "";
    if(this.selectedFiles2){
      this.fileReq2 = this.selectedFiles2.item(0);
      if(this.fileReq2?.type !== "application/pdf" ){
        this.errMsg = "Please upload .pdf file only";
      }else{
        this.historyService.updateFile(new HistoryRequestV2(Number(id)), this.selectedFiles2.item(0)).subscribe(
          (res : HttpResponse<HistoryResponseV2>)=>{
            if(res.body){
              this.historyResponse = res.body;
              if(this.historyResponse.result){
                this.successMsg = String(this.historyResponse.msg);
              }else{
                this.errMsg = String(this.historyResponse.msg);
              }
              this.getHistory(parentContent);
              this.fileInput2.nativeElement.value = "";
              this.selectedFiles2 = undefined;
            }
          }
        );
      }
  }else{
    this.successMsg = "Please Upload a File";
  }
}

}