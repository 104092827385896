import * as dayjs from 'dayjs';

export interface ICbicAmendmentDocHistory {
  id?: number;
  contentId?: number | null;
  contentLanguage?: string | null;
  isActive?: string | null;
  createdDt?: dayjs.Dayjs | null;
  updatedDt?: dayjs.Dayjs | null;
  versionNo?: number | null;
  docContentId?: number | null;
  createdBy?: number | null;
  updatedBy?: number | null;
  amendmentDt?: dayjs.Dayjs | null;
  contentType?: string | null;
  taxId?: number | null;
  pdfFilePath?: string | null;
  pdfFileName?: string | null;
  htmlFilePath?: string | null;
  htmlFileName?: string | null;
}

export class CbicAmendmentDocHistory implements ICbicAmendmentDocHistory {
  constructor(
    public id?: number,
    public contentId?: number | null,
    public contentLanguage?: string | null,
    public isActive?: string | null,
    public createdDt?: dayjs.Dayjs | null,
    public updatedDt?: dayjs.Dayjs | null,
    public versionNo?: number | null,
    public docContentId?: number | null,
    public createdBy?: number | null,
    public updatedBy?: number | null,
    public amendmentDt?: dayjs.Dayjs | null,
    public contentType?: string | null,
    public taxId?: number | null,
    public pdfFilePath?: string | null,
    public pdfFileName?: string | null,
    public htmlFilePath?: string | null,
    public htmlFileName?: string | null
  ) {}
}

export function getCbicAmendmentDocHistoryIdentifier(cbicAmendmentDocHistory: ICbicAmendmentDocHistory): number | undefined {
  return cbicAmendmentDocHistory.id;
}
