<section class="search-sec">
    <div class="container">
        <form action="#" method="post" novalidate="novalidate" >
            <div class="row">
                <div class="col-lg-12">
                    <form name="formsSearchBarForm" role="form" [formGroup]="formsSearchBarForm" novalidate="novalidate" (ngSubmit)="search()">
                        <div class="row">
                            <div class="input-group flex-nowrap"> 

                                <input class="form-control" list="datalistOptionsForms" id="exampleDataListForms" #formSelection
                                placeholder="{{ 'enterFormNumber' | translate }}" formControlName="formNo" (change)="fireFormSelected(formSelection.value)">
                                <!-- (change)="fireRuleSectionSelected(sectionSelection.value)" -->
                                
                                <datalist id="datalistOptionsForms" *ngIf="storageLanguage === 'en'">
                                    <option *ngFor="let form of formsListFetched; let i = index"  data-value="{{form.id}}" value="{{form.formNo}}-{{form.formName}}"></option>
                                    <input type="hidden" name="answer" id="exampleDataListForms-hidden">
                                </datalist>
                                <datalist id="datalistOptionsForms" *ngIf="storageLanguage === 'hi'">
                                    <option  *ngFor="let form of formsListFetched; let i = index"  data-value="{{form.id}}"
                                     value="{{form.formNoHi ? form.formNoHi : form.formNo}}-{{form.formNameHi ? form.formNameHi : form.formName}}">
                                    </option>
                                <input type="hidden" name="answer" id="exampleDataListForms-hidden">
                                </datalist>
                               
 
                                <!-- <datalist class="form-select" #formSelection
                                id="inputGroupSelectActListForContentPage"
                                aria-label="Forms Selection"
                                formControlName="formId" [(ngModel)]="selectedForm" style="width: 25%;" >
                                
                                </datalist>>                             -->
                                <!-- <input class="form-control"  id="formsList" 
                                placeholder="Enter Form Number..." formControlName="formNo"  aria-label="Forms Selection" autocomplete="off"> -->
                                <span class="input-group-text"></span>
                                <input type="text" class="form-control" placeholder="{{ 'enterKeyword' | translate }}" #formSearchKeyword maxlength="100"
                                    aria-label="Username" aria-describedby="addon-wrapping" formControlName="keyword" [(ngModel)]="searchKeyword"
                                    autocomplete="off">
                                <button class="btn" *ngIf="!searched" type="submit" (click)="search()"><fa-icon [icon]="faSearch"></fa-icon></button>
                                <button class="btn" *ngIf="searched" type="button" (click)="clear()"><fa-icon [icon]="faTimesCircle"></fa-icon></button>
                                <!-- <button class="btn disablebtn">Select Forms</button>
                             -->
                            </div>
                            <div class="advance-search">
                                <a *ngIf="storageLanguage === 'en'" [routerLink]="" (click)="openAllSearch()"><u>Click here </u>to search across all {{taxName}} {{tabName}}</a>
                                <a *ngIf="storageLanguage === 'hi'" [routerLink]="" (click)="openAllSearch()">सभी {{taxName}} {{tabName}} में खोजने के लिए यहां <u>क्लिक करें</u></a>
                            </div>
                            <!-- <div class="advance-search">
                            Advance Search
                        </div> -->
                        </div>
                    </form>
                </div>
            </div>
        </form>
    </div>
</section>

<ng-template #allSearch let-modal>
    <div class="modal-content all-search-modal">
        <div class="modal-header page-header">
            <h4 *ngIf="storageLanguage === 'en'">Search across all {{taxName}} {{tabName}}</h4>
            <h4 *ngIf="storageLanguage === 'hi'">सभी {{taxName}} {{tabName}} में खोजें</h4>

            <button type="button" class="btn btn-light" (click)="modal.close('Close click')"><i
                    class="fas fa-times"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-12">
                    <form name="searchBarFormAll" role="form" [formGroup]="searchBarFormAll"
                        novalidate="novalidate" (ngSubmit)="searchAll()">
                        <div class="input-group flex-nowrap search-sec">
                            <input type="text" class="form-control" placeholder="{{ 'enterKeyword' | translate }}" aria-label="Username"
                                maxlength="100" #allRuleSearchKeyword aria-describedby="addon-wrapping"
                                formControlName="keywordAll" autocomplete="off"/>
                                <button class="btn" type="button" (click)="searchAll()"><fa-icon [icon]="faSearch"></fa-icon></button>
                        </div>
                        <p *ngIf="allSearchMsg" class="all-search-msg">{{allSearchMsg}}</p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-template>