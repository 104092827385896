import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { Search } from 'app/core/request/request.model';
import { ICbicAttachmentDtls, getCbicAttachmentDtlsIdentifier } from '../cbic-attachment-dtls.model';
import { RuleMstResponse } from 'app/entities/cbic-rule-mst/cbic-rule-mst.model';

export type EntityResponseType = HttpResponse<ICbicAttachmentDtls>;
export type EntityArrayResponseType = HttpResponse<ICbicAttachmentDtls[]>;

@Injectable({ providedIn: 'root' })
export class CbicAttachmentDtlsService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/cbic-attachment-dtls');
  public resourceSearchUrl = this.applicationConfigService.getEndpointFor('api/_search/cbic-attachment-dtls');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  fetchAttachmentList(id:number, type:string):Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicAttachmentDtls[]>(`${this.resourceUrl}/fetchAttachmentList/${id}/${type}`, {  observe: 'response' })
  }

  downloadNotification(id: number, language: string):Observable<RuleMstResponse>{
    return this.http.get<RuleMstResponse>(`${this.resourceUrl}/download/${id}/${language}`);
  }

  create(cbicAttachmentDtls: ICbicAttachmentDtls): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicAttachmentDtls);
    return this.http
      .post<ICbicAttachmentDtls>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(cbicAttachmentDtls: ICbicAttachmentDtls): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicAttachmentDtls);
    return this.http
      .put<ICbicAttachmentDtls>(`${this.resourceUrl}/${getCbicAttachmentDtlsIdentifier(cbicAttachmentDtls) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(cbicAttachmentDtls: ICbicAttachmentDtls): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicAttachmentDtls);
    return this.http
      .patch<ICbicAttachmentDtls>(`${this.resourceUrl}/${getCbicAttachmentDtlsIdentifier(cbicAttachmentDtls) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICbicAttachmentDtls>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicAttachmentDtls[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  search(req: Search): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicAttachmentDtls[]>(this.resourceSearchUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  addCbicAttachmentDtlsToCollectionIfMissing(
    cbicAttachmentDtlsCollection: ICbicAttachmentDtls[],
    ...cbicAttachmentDtlsToCheck: (ICbicAttachmentDtls | null | undefined)[]
  ): ICbicAttachmentDtls[] {
    const cbicAttachmentDtls: ICbicAttachmentDtls[] = cbicAttachmentDtlsToCheck.filter(isPresent);
    if (cbicAttachmentDtls.length > 0) {
      const cbicAttachmentDtlsCollectionIdentifiers = cbicAttachmentDtlsCollection.map(
        cbicAttachmentDtlsItem => getCbicAttachmentDtlsIdentifier(cbicAttachmentDtlsItem)!
      );
      const cbicAttachmentDtlsToAdd = cbicAttachmentDtls.filter(cbicAttachmentDtlsItem => {
        const cbicAttachmentDtlsIdentifier = getCbicAttachmentDtlsIdentifier(cbicAttachmentDtlsItem);
        if (cbicAttachmentDtlsIdentifier == null || cbicAttachmentDtlsCollectionIdentifiers.includes(cbicAttachmentDtlsIdentifier)) {
          return false;
        }
        cbicAttachmentDtlsCollectionIdentifiers.push(cbicAttachmentDtlsIdentifier);
        return true;
      });
      return [...cbicAttachmentDtlsToAdd, ...cbicAttachmentDtlsCollection];
    }
    return cbicAttachmentDtlsCollection;
  }

  protected convertDateFromClient(cbicAttachmentDtls: ICbicAttachmentDtls): ICbicAttachmentDtls {
    return Object.assign({}, cbicAttachmentDtls, {
      createdDt: cbicAttachmentDtls.createdDt?.isValid() ? cbicAttachmentDtls.createdDt.toJSON() : undefined,
      updatedDt: cbicAttachmentDtls.updatedDt?.isValid() ? cbicAttachmentDtls.updatedDt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDt = res.body.createdDt ? dayjs(res.body.createdDt) : undefined;
      res.body.updatedDt = res.body.updatedDt ? dayjs(res.body.updatedDt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((cbicAttachmentDtls: ICbicAttachmentDtls) => {
        cbicAttachmentDtls.createdDt = cbicAttachmentDtls.createdDt ? dayjs(cbicAttachmentDtls.createdDt) : undefined;
        cbicAttachmentDtls.updatedDt = cbicAttachmentDtls.updatedDt ? dayjs(cbicAttachmentDtls.updatedDt) : undefined;
      });
    }
    return res;
  }
}
