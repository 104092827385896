import * as dayjs from 'dayjs';
import { ICbicTaxMst } from 'app/entities/cbic-tax-mst/cbic-tax-mst.model';

export interface ICbicOrderMst {
  id?: number;
  contentId?: number | null;
  contentLanguage?: string | null;
  isActive?: string | null;
  createdDt?: dayjs.Dayjs | null;
  updatedDt?: dayjs.Dayjs | null;
  isAmended?: string | null;
  isOmitted?: string | null;
  parentId?: number | null;
  orderId?: number | null;
  versionNo?: number | null;
  createdBy?: number | null;
  updatedBy?: number | null;
  orderNo?: string | null;
  orderName?: string | null;
  orderCategory?: string | null;
  orderDt?: dayjs.Dayjs | null;
  issueDt?: dayjs.Dayjs | null;
  amendDt?: dayjs.Dayjs | null;
  docFilePath?: string | null;
  docFileName?: string | null;
  docFilePathHi?: string | null;
  docFileNameHi?: string | null;
  docFilePathAod?: string | null;
  docFileNameAod?: string | null;
  tax?: ICbicTaxMst | null;
  ordYear?: number | null;
  serialNo?: number | null;
  isAttachment?: string | null;
}

export class CbicOrderMst implements ICbicOrderMst {
  constructor(
    public id?: number,
    public contentId?: number | null,
    public contentLanguage?: string | null,
    public isActive?: string | null,
    public createdDt?: dayjs.Dayjs | null,
    public updatedDt?: dayjs.Dayjs | null,
    public isAmended?: string | null,
    public isOmitted?: string | null,
    public parentId?: number | null,
    public orderId?: number | null,
    public versionNo?: number | null,
    public createdBy?: number | null,
    public updatedBy?: number | null,
    public orderNo?: string | null,
    public orderName?: string | null,
    public orderCategory?: string | null,
    public orderDt?: dayjs.Dayjs | null,
    public issueDt?: dayjs.Dayjs | null,
    public amendDt?: dayjs.Dayjs | null,
    public docFilePath?: string | null,
    public docFileName?: string | null,
    public docFilePathHi?: string | null,
    public docFileNameHi?: string | null,
    public docFilePathAod?: string | null,
    public docFileNameAod?: string | null,
    public tax?: ICbicTaxMst | null,
    public ordYear?: number | null,
    public serialNo?: number | null,
    public isAttachment?: string | null
  ) {}
}

export function getCbicOrderMstIdentifier(cbicOrderMst: ICbicOrderMst): number | undefined {
  return cbicOrderMst.id;
}
