import * as dayjs from 'dayjs';

export interface ISunEditorInput {
    htmlContent:string,
    filepath:string

  }

  export class SunEditorInput implements ISunEditorInput {
    constructor(
      public htmlContent: string ,
      public filepath: string 
    ) {}
  }
  