<section class="search-sec">
    <div class="container">
        <form action="#" method="post" novalidate="novalidate" >
            <div class="row">
                <div class="col-lg-12">
                    <form name="searchBarForm"   role="form" [formGroup]="searchBarForm" novalidate="novalidate" (ngSubmit)="search()">
                    <div class="row">
                        <div class="input-group flex-nowrap">
                            <button class="btn disablebtn" cbicTranslate="selectact">Select Act</button>
                            <select class="form-select" #actSelection
                                id="inputGroupSelectActListForContentPage"
                                aria-label="Act Selection" (change)='fireActSelected(actSelection.value)' formControlName="actId" [(ngModel)]="selectedAct" style="width: 25%;">
                                
                                <option *ngFor="let act of actList; let i = index" title="{{act.actName}}"  value="{{act.id}}"  [selected]="i === 0">
                                    <!-- {{act.actName}} -->
                                    <div *ngIf="storageLanguage === 'en'">{{act.actName}}</div>
                                    <div *ngIf="storageLanguage === 'hi'">{{act.actNameHi?act.actNameHi:act.actName}}</div>
                                 </option>
                                </select>
                            <span class="input-group-text"></span>
                            <button class="btn disablebtn" cbicTranslate="selectChapter">Select Chapter</button>
                            <select class="form-select"
                                id="inputGroupSelectChapterListForContentPage" #chpterSelection
                                aria-label="Chapter Selection" (change)='fireChapterSeleted(chpterSelection.value)' formControlName="chapterId" [(ngModel)]="selectedChapter">
                                <option  value="" cbicTranslate="allchapters" ></option>
                                <option *ngFor="let chapter of chapterList; let i = index" value="{{chapter.id}}" >
                                    <!-- {{chapter.chapterNo}} -->
                                    <div *ngIf="storageLanguage === 'en'">{{chapter.chapterNo}}</div>
                                    <div *ngIf="storageLanguage === 'hi'">{{chapter.chapterNoHi ? chapter.chapterNoHi : chapter.chapterNo}}</div>
                                </option>
                                
                            </select>
                            <input class="form-control" list="datalistOptions" id="exampleDataList" #sectionSelection
                                placeholder="{{ 'enterSection' | translate }}" formControlName="sectionId" (change)="fireSectionSelected(sectionSelection.value)">
                            <datalist id="datalistOptions">

                                <div *ngFor="let section of sectionList; let i = index">

                                    <div *ngIf="storageLanguage === 'en'">
                                    <option data-value="{{section.id}}" value="{{section.sectionNo}}-{{section.sectionName}}"></option>
                                   </div>
    
                                   <div *ngIf="storageLanguage === 'hi'">
                                    <option data-value="{{section.id}}" value="{{section.sectionNoHi ? section.sectionNoHi : section.sectionNo}}-{{section.sectionNameHi ? section.sectionNameHi : section.sectionName}}"></option>
                                   </div>
    
                                 </div>
    

                                <!-- <option *ngFor="let section of sectionList; let i = index"  data-value="{{section.id}}" value="{{section.sectionNo}}-{{section.sectionName}}"></option> -->
                                <!--<option value="Section 1 - Classes of officers of customs">
                                <option
                                    value="Section 1 - Entrustment of functions of Board and customs officers on certain other officers">
                                <option value="Section 1 - Entry of goods for exportation">
                                <option value="Section 1 - Clearance of goods for home consumption">
                                <option
                                    value="Section 1 - Storage of imported goods in warehouse pending clearance or removal">-->
                                    <input type="hidden" name="answer" id="exampleDataList-hidden">
                            </datalist>

                            <span class="input-group-text"></span>
                            <input type="text" class="form-control" placeholder="{{ 'enterKeyword' | translate }}" aria-label="Username" maxlength="100" #contentSearchKeyword
                                aria-describedby="addon-wrapping" formControlName="keyword" autocomplete="off">
                            <button class="btn" *ngIf="!searched" type="button" (click)="search()"><fa-icon [icon]="faSearch"></fa-icon></button>
                            <button class="btn" *ngIf="searched" type="button" (click)="clear()"><fa-icon [icon]="faTimesCircle"></fa-icon></button>
                        </div>
                        <br/>
                        <!-- <div class="all-search">
                            <a [routerLink]="" (click)="openAllSearch()">Click here to search across all {{taxName}} {{activeTab}}</a>
                        </div> -->
                        <div class="advance-search">
                            <a *ngIf="storageLanguage === 'en'" [routerLink]="" (click)="openAllSearch()"><u>Click here </u>to search across all {{taxName}} {{tabName}}</a>
                            <a *ngIf="storageLanguage === 'hi'" [routerLink]="" (click)="openAllSearch()">सभी {{taxName}} {{tabName}} में खोजने के लिए यहां <u>क्लिक करें</u></a>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        </form>
    </div>
</section>

<ng-template #allSearch let-modal>
    <div class="modal-content all-search-modal">
        <div class="modal-header page-header">
            <h4 *ngIf="storageLanguage === 'en'">Search across all {{taxName}} {{tabName}}</h4>
            <h4 *ngIf="storageLanguage === 'hi'">सभी {{taxName}} {{tabName}} में खोजें</h4>

            <button type="button" class="btn btn-light" (click)="modal.close('Close click')"><i
                    class="fas fa-times"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-12">
                    <form name="searchBarFormAll" role="form" [formGroup]="searchBarFormAll"
                        novalidate="novalidate" (ngSubmit)="searchAll()">
                        <div class="input-group flex-nowrap search-sec">
                            <input type="text" class="form-control" placeholder="{{ 'enterKeyword' | translate }}" aria-label="Username"
                                maxlength="100" #allRuleSearchKeyword aria-describedby="addon-wrapping"
                                formControlName="keywordAll" autocomplete="off"/>
                                <button class="btn" type="button" (click)="searchAll()"><fa-icon [icon]="faSearch"></fa-icon></button>
                        </div>
                        <p *ngIf="allSearchMsg" class="all-search-msg">{{allSearchMsg}}</p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-template>