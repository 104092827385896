<div class="row">
    <div class="col-lg-12">
        <h4 class="d-flex"><label cbicTranslate="form"></label>  {{formName}}
            <div class="read-area-margin"></div>
        </h4>
    </div>
    <!-- <div class="col-lg-2">
        <div class="input-group input-group-sm ">
            <span class="input-group-text"><a href=""><i class="fa fa-arrow-left" aria-hidden="true"></i></a></span>
            <span class="bg-transparent input-group-text"></span>
            <span class="input-group-text"><a href=""><i class="fa fa-arrow-right" aria-hidden="true"></i></a></span>
        </div>
    </div> -->
</div>
<div class="search-view">
    <div class="table-responsive">
        <table class="table table-hover">
            <tbody>
                <tr>
                    <th class="col-sm-2" cbicTranslate="formNumber">Form Number</th>
                    <th cbicTranslate="formName">Form Name</th>
                    <th cbicTranslate="download">Download</th>
                </tr>

                <tr *ngFor="let formsDtls of formByCategory, let i=index">

                    <td class="search-link">  
                        <div *ngIf="language === 'en'"><a [routerLink]="" (click)="openForm(formsDtls.id)">{{formsDtls.formNo}}</a></div>
                        <div *ngIf="language === 'hi'"><a [routerLink]="" (click)="openForm(formsDtls.id)">{{formsDtls.formNoHi ? formsDtls.formNoHi : formsDtls.formNo}}</a></div>
                    
                    </td>

                    <td class="search-link">            
                        <div *ngIf="language === 'en'"><a [routerLink]="" (click)="openForm(formsDtls.id)">{{formsDtls.formName}}</a></div>
                        <div *ngIf="language === 'hi'"><a [routerLink]="" (click)="openForm(formsDtls.id)">{{formsDtls.formNameHi ? formsDtls.formNameHi : formsDtls.formName}}</a></div>
                        
                    </td>


                    <!-- <td class="search-link"><a [routerLink]="" (click)="openForm( formsDtls.id)">{{formsDtls.formNo}}</a></td> -->
                    <!-- <td class="search-link"><a [routerLink]="" (click)="openForm( formsDtls.id)">{{formsDtls.formName}}</a></td> -->
                    <!-- <td class="search-link" style="text-align: center;"><a [routerLink]="" (click)="downloadForm(formsDtls.id)"><i
                                class="fas fa-download"></i></a></td> -->  
                    <td>
                        <div>
                            <a [routerLink]="" *ngIf="formsDtls.contentFilePath" (click)="downloadForm(formsDtls.id , 'ENG')">English</a>
                            <a [routerLink]=""  *ngIf="formsDtls.contentFilePath" (click)="downloadForm(formsDtls.id , 'ENG')">
                              <i class="fa fa-download" aria-hidden="true"></i></a> <br />
                
                            <a [routerLink]="" *ngIf="formsDtls.contentFilePathHi" (click)="downloadForm(formsDtls.id ,'HINDI')">हिन्दी</a> &nbsp; &nbsp;
                            <a [routerLink]=""*ngIf="formsDtls.contentFilePathHi" (click)="downloadForm(formsDtls.id ,'HINDI')"><i
                                class="fa fa-download" aria-hidden="true"></i></a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<!-- END TABLE RESULT -->