import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CbicSearchOutputResult } from 'app/search-page/search-output.model';
import { CbicSearchService } from 'app/search-page/service/cbic-search-service';
import { CbicOrderSearch, CbicSearchInput1, ICbicSearchInput } from 'app/searchbar/searchbar.model';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'cbic-order-search-bar',
  templateUrl: './order-search-bar.component.html',
  styleUrls: ['./order-search-bar.component.scss']
})
export class OrderSearchBarComponent implements OnInit {

  faTimesCircle=faTimesCircle;
  faSearch=faSearch;
  searched=false;
  @Output() clearOrderSearch = new EventEmitter<string>();
  @Output() OrderSearch= new EventEmitter<string>();
  @Input() taxId: any;
  @Input() orderCategoryList: any;
  @Output() categorySelected = new  EventEmitter<string>();
  @Output() orderYearSelected = new EventEmitter<string>();
  yearList:any;
  orderYear:any;
  orderCategory: any;
  tempYear:any;
  orderYearValue: any;
  selectedYear: any;

  searchBarOrder = this.fb.group({
    keyword : [],
    taxId: [],
    orderYear:[],
    category: [],
  });

  searchBarFormAll = this.fb.group({
    keywordAll : "",
  });
  @ViewChild("ruleSearchKeyword") ruleKeyword? : ElementRef;
  @Input() taxName: any;
  @ViewChild('allSearch', { static: true }) allSearchTextModal!: ElementRef;
  allSearchMsg: any
  @ViewChild("allRuleSearchKeyword") allRuleSearchKeyword? : ElementRef;
  @Input() activeTab:any
  @Input() tabName:any
  storageLanguage:any

  constructor(private fb:FormBuilder,
    private service: CbicSearchService,
    private modalService : NgbModal,
    private router: Router,
    private sessionStorage: SessionStorageService) { }

  ngOnInit(): void {
    this.storageLanguage = this.sessionStorage.retrieve('locale')
    this.yearList=[];
    this.tempYear=[];

    let i;
    if(this.taxId === 1000001){
      i = 2017;
    } else {
      i = 2000;
    }
  
    for(i;i<=new Date().getFullYear();i++){
      this.tempYear.push(i);
      this.orderYear=i;
    }
    
    this.tempYear.reverse()
    this.yearList = this.tempYear
    return;
  }

  setOrderYear(year: any):void{
    this.orderYear = new Date().getFullYear();
    this.searchBarOrder.patchValue({
      orderYear: year
    });
  }

  setOrderCategory(ordCategory: any): void{
    this.orderCategory = ordCategory;
    this.searchBarOrder.patchValue({
      category: ordCategory
    });
  }

  clear():void{
    this.searched=false;
    
    this.searchBarOrder = this.fb.group({
      keyword : "",
      orderYear: "",
      category: ""
    });
    this.clearOrderSearch.emit();
  }

  search():void{
    const cbicSearchInput = this.createOrderForm();
    if(cbicSearchInput.keyword === undefined || cbicSearchInput.keyword!.length < 3){
      alert("Please enter valid keyword");
    }

    cbicSearchInput.keyword = cbicSearchInput.keyword?.trim()
    
    this.OrderSearch.emit(JSON.stringify(cbicSearchInput));
    this.searched=true;
  }

  changeCategorySelection(ordCategory:string):void{
    this.categorySelected.emit(ordCategory);
  }

  fireOrderYearSelected(instructionYear:any):void{
    this.orderYearSelected.emit(instructionYear);
  }

  searchAll():void{
    
    const cbicSearchInput = this.createAllForm();
    if(cbicSearchInput.keyword === undefined || cbicSearchInput.keyword!.length < 3){
      alert("Please enter valid keyword");
      this.ruleKeyword?.nativeElement.focus();
      return;
    }

    cbicSearchInput.keyword = cbicSearchInput.keyword?.trim()

    this.service.pageNumber = 1;
      this.service.searchInSection(cbicSearchInput).subscribe(
        (res: HttpResponse<CbicSearchOutputResult>) => {
          if(res.body?.totalDocuments === 0){
            this.allSearchMsg = "No search result found."
            this.allRuleSearchKeyword?.nativeElement.focus();
            this.searchBarFormAll = this.fb.group({
              keywordAll : ""
            });
            return;
          } else{
            this.modalService.dismissAll()
            this.service.cbicSearchOutputResults = res.body ?? new CbicSearchOutputResult([], null);
            this.router.navigate(['searchPage/search'],{queryParams : cbicSearchInput});
            //const url = this.router.serializeUrl(this.router.createUrlTree(['searchPage/search'],{queryParams : cbicSearchInput}));

            //window.open(url, '_blank');
          }
         
        });

  }

  openAllSearch(): void{
    this.modalService.open(this.allSearchTextModal,{windowClass : "", centered : true});
  }

  protected createAllForm(): ICbicSearchInput {
    return {
      ...new CbicSearchInput1(),
      keyword: (this.searchBarFormAll.get(['keywordAll'])!.value === null || this.searchBarFormAll.get(['keywordAll'])!.value === undefined) ? null : this.searchBarFormAll.get(['keywordAll'])!.value.trim(),
      content: "ORDERS",
      taxId: this.taxId,
      startIndex: 0,
      paginationFlag: true,
      rows: 10
    };
  }

  protected createOrderForm(): ICbicSearchInput {
    return {
      ...new CbicOrderSearch(),
      keyword: (this.searchBarOrder.get(['keyword'])!.value === null || this.searchBarOrder.get(['keyword'])!.value === undefined) ? null : this.searchBarOrder.get(['keyword'])!.value,
      taxId: this.taxId,
      content: "ORDERS",
    };
  }

}
