import { HttpResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { CbicOtherDocumentMstRequest, CbicOthersDocumentMst } from "app/entities/cbic-others-document-mst/cbic-others-document-mst.model";
import { CbicOthersDocumentMstService } from "app/entities/cbic-others-document-mst/service/cbic-others-document-mst.service";
import { SessionStorageService } from "ngx-webstorage";

@Component({
    selector: "cbic-amend-ordinance",
    templateUrl: "./amend-ordinance.component.html"
  })
  export class AmendOrdinanceComponent implements OnInit {
    documentList : any;
    menuOtherDocType : any;
    menuOtherDocName : any;
    menuOtherTypeMap : any;
    otherTypeForActive : any;
    tabName:any
    storageLanguage: any

    constructor(
        protected othersDocumentService:CbicOthersDocumentMstService,
        private sessionStorage: SessionStorageService
    ){
       
    }

    ngOnInit():void{
        this.loadMap();
        if(history.state.menuInput){
            this.menuOtherDocType = history.state.menuInput.otherDocType;
        }
        if(this.menuOtherDocType){
            this.fetchOtherDocument(this.menuOtherDocType);
        }

        this.storageLanguage = this.sessionStorage.retrieve('locale')
    }

    loadMap():void{
        const typeMap = new Map();
        typeMap.set('FINACT', 'Finance Acts');
        typeMap.set('AMEND', 'Amendments');
        typeMap.set('ORDNCE', 'Ordinances');
        this.menuOtherTypeMap = typeMap;
    }

    fetchOtherDocument(otherType : any):void{
        this.othersDocumentService.getActiveList(new CbicOtherDocumentMstRequest(String(otherType))).subscribe(
            (res:HttpResponse<CbicOthersDocumentMst[]>) => {
                if(res.body){
                    this.documentList =res.body;
                    this.otherTypeForActive = otherType;
                    this.menuOtherDocName = this.menuOtherTypeMap.get(otherType);

                    if(this.storageLanguage === 'hi'){
                        if(this.menuOtherDocName === 'Ordinances'){
                            this.tabName = "अध्यादेश"
                        }
                        if(this.menuOtherDocName === 'Amendments'){
                            this.tabName = "संशोधन"
                        }
                        if(this.menuOtherDocName === 'Finance Acts'){
                            this.tabName = "वित्त अधिनियम"
                        }
                    } else{
                        this.tabName = this.menuOtherDocName
                    }
                }
            }
        )
    }

  }