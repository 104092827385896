
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CbicVisitorCountService } from 'app/entities/cbic-visitor-count/service/cbic-visitor-count.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'ngx-webstorage';
import { LANGUAGES } from 'app/config/language.constants';
import { ThemeService } from 'app/shared/theme/theme.service';
import { Router } from '@angular/router';
import { CbicAssociateIpDtlsService } from 'app/entities/cbic-associate-ip-dtls/service/cbic-associate-ip-dtls.service';


@Component({
  selector: 'cbic-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  displayNone='none';
  display='';
  //faArrowDown:faGlobe;
  visitorCount = 0;
  visitorCountDigits!:Array<number>;
  @ViewChild('disclaimer', { static: true }) disclaimerModal!: ElementRef;
  isNavbarCollapsed = false;
  languages = LANGUAGES;
  @ViewChild('disclaimerText', { static: true }) disclaimerTextModal!: ElementRef;
  notAllowed = false;

  constructor(private service:CbicVisitorCountService,
    private modalService : NgbModal,
    private translateService: TranslateService,
    private sessionStorage: SessionStorageService,
    private themeService : ThemeService,
    private router: Router,
    private ipService: CbicAssociateIpDtlsService){}
  
  ngOnInit():void{
    this.checkLinkageAllowed()
    this.service.getVisitorCounter().subscribe(
      (res : number) => {
        this.visitorCount = res;
        this.visitorCountDigits=[];
        while(this.visitorCount>=10){
          this.visitorCountDigits.push(Math.floor(this.visitorCount%10));
          this.visitorCount=this.visitorCount/10;
        }
        this.visitorCountDigits.push(Math.floor(this.visitorCount));
        this.visitorCountDigits=this.visitorCountDigits.reverse();
      }
    );
  }

  toggle(): void {
    this.displayNone = this.displayNone ? '' : 'none';
    this.display = this.displayNone ? '' : 'none';
  }

  openDisclaimer(): void{
    this.modalService.open(this.disclaimerModal,{centered: true});
  }
 
  downloadFile():void{
      return
  }

  changeLanguage(languageKey: string): void {
    this.sessionStorage.store('locale', languageKey);
    this.translateService.use(languageKey);
  }

  collapseNavbar(): void {
    this.isNavbarCollapsed = true;
  }

  toogleTheme():void{
    alert("Theme")
    this.themeService.setLightTheme();
  }

  downloadForm1():void{
    return
  }

  openDisclaimerText(): void{
    this.modalService.open(this.disclaimerTextModal,{centered: true});
  }

  checkLinkageAllowed(): void{
    this.ipService.checkLinkageAllowed().subscribe(
      (res: any) => {
        this.notAllowed = res.body
        this.sessionStorage.store('notAllowed',this.notAllowed)
      }
    )
  }
}

