<section class="search-sec">
    <div class="container">
        <form action="#" method="post" novalidate="novalidate" (ngSubmit)="search()">
            <div class="row">
                <div class="col-lg-12">
                    <form name="searchBarFormRule"   role="form" [formGroup]="searchBarFormRule" novalidate="novalidate" (ngSubmit)="search()">
                    <div class="row">
                        <div class="input-group flex-nowrap">
                            <input class="form-control" list="detailsServiceTaxRules" 
                                   id="serviceTaxRules" #ruleSelection 
                                   placeholder="Enter Rule Number" formControlName="ruleId"
                                   (change)="fireSeletedRule(ruleSelection.value)">
                                <datalist id="detailsServiceTaxRules">
                                    <option *ngFor="let rule of serviceTaxRuleList; let i = index"  data-value="{{rule.id}}" value="{{rule.ruleDocName}}"></option>
                                        <input type="hidden" name="answer" id="serviceTaxRules-hidden">
                                </datalist>
                                <span class="input-group-text" style="background: #F1F1E6;"></span>
                                <input type="text" class="form-control" placeholder="Enter Keyword" aria-label="Username" maxlength="100" #serviceTaxKeyWord
                                aria-describedby="addon-wrapping" formControlName="keyword" autocomplete="off" [(ngModel)]="searchKeyWord"> 
                            <button class="btn" *ngIf="!searched" type="submit" (click)="search()"><fa-icon [icon]="faSearch"></fa-icon></button>
                            <button class="btn" *ngIf="searched" type="button" (click)="clear()"><fa-icon [icon]="faTimesCircle"></fa-icon></button>
                             
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        </form>
    </div>
</section>