import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { Search } from 'app/core/request/request.model';
import { ICbicOthersDocumentMst, getCbicOthersDocumentMstIdentifier, CbicOthersDocumentMst, CbicOtherDocumentMstRequest, CbicOtherDocumentMstResponse } from '../cbic-others-document-mst.model';

export type EntityResponseType = HttpResponse<ICbicOthersDocumentMst>;
export type EntityArrayResponseType = HttpResponse<ICbicOthersDocumentMst[]>;
export type EntityArrayResponse = HttpResponse<CbicOthersDocumentMst[]>;

@Injectable({ providedIn: 'root' })
export class CbicOthersDocumentMstService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/cbic-others-document-msts');
  public resourceSearchUrl = this.applicationConfigService.getEndpointFor('api/_search/cbic-others-document-msts');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(cbicOthersDocumentMst: ICbicOthersDocumentMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicOthersDocumentMst);
    return this.http
      .post<ICbicOthersDocumentMst>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(cbicOthersDocumentMst: ICbicOthersDocumentMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicOthersDocumentMst);
    return this.http
      .put<ICbicOthersDocumentMst>(`${this.resourceUrl}/${getCbicOthersDocumentMstIdentifier(cbicOthersDocumentMst) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(cbicOthersDocumentMst: ICbicOthersDocumentMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicOthersDocumentMst);
    return this.http
      .patch<ICbicOthersDocumentMst>(`${this.resourceUrl}/${getCbicOthersDocumentMstIdentifier(cbicOthersDocumentMst) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICbicOthersDocumentMst>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicOthersDocumentMst[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  search(req: Search): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicOthersDocumentMst[]>(this.resourceSearchUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  addCbicOthersDocumentMstToCollectionIfMissing(
    cbicOthersDocumentMstCollection: ICbicOthersDocumentMst[],
    ...cbicOthersDocumentMstsToCheck: (ICbicOthersDocumentMst | null | undefined)[]
  ): ICbicOthersDocumentMst[] {
    const cbicOthersDocumentMsts: ICbicOthersDocumentMst[] = cbicOthersDocumentMstsToCheck.filter(isPresent);
    if (cbicOthersDocumentMsts.length > 0) {
      const cbicOthersDocumentMstCollectionIdentifiers = cbicOthersDocumentMstCollection.map(
        cbicOthersDocumentMstItem => getCbicOthersDocumentMstIdentifier(cbicOthersDocumentMstItem)!
      );
      const cbicOthersDocumentMstsToAdd = cbicOthersDocumentMsts.filter(cbicOthersDocumentMstItem => {
        const cbicOthersDocumentMstIdentifier = getCbicOthersDocumentMstIdentifier(cbicOthersDocumentMstItem);
        if (
          cbicOthersDocumentMstIdentifier == null ||
          cbicOthersDocumentMstCollectionIdentifiers.includes(cbicOthersDocumentMstIdentifier)
        ) {
          return false;
        }
        cbicOthersDocumentMstCollectionIdentifiers.push(cbicOthersDocumentMstIdentifier);
        return true;
      });
      return [...cbicOthersDocumentMstsToAdd, ...cbicOthersDocumentMstCollection];
    }
    return cbicOthersDocumentMstCollection;
  }

  getActiveList(otherDocumentMst : CbicOtherDocumentMstRequest):Observable<EntityArrayResponse>{
    return this.http.post<CbicOthersDocumentMst[]>(`${this.resourceUrl}/get-list`, otherDocumentMst, {observe : 'response'});
  }

  downloadDocument(id: number, language: string):Observable<CbicOtherDocumentMstResponse>{
    return this.http.get<CbicOtherDocumentMstResponse>(`${this.resourceUrl}/download/${id}/${language}`);
  }

  protected convertDateFromClient(cbicOthersDocumentMst: ICbicOthersDocumentMst): ICbicOthersDocumentMst {
    return Object.assign({}, cbicOthersDocumentMst, {
      createdDt: cbicOthersDocumentMst.createdDt?.isValid() ? cbicOthersDocumentMst.createdDt.toJSON() : undefined,
      updatedDt: cbicOthersDocumentMst.updatedDt?.isValid() ? cbicOthersDocumentMst.updatedDt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDt = res.body.createdDt ? dayjs(res.body.createdDt) : undefined;
      res.body.updatedDt = res.body.updatedDt ? dayjs(res.body.updatedDt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((cbicOthersDocumentMst: ICbicOthersDocumentMst) => {
        cbicOthersDocumentMst.createdDt = cbicOthersDocumentMst.createdDt ? dayjs(cbicOthersDocumentMst.createdDt) : undefined;
        cbicOthersDocumentMst.updatedDt = cbicOthersDocumentMst.updatedDt ? dayjs(cbicOthersDocumentMst.updatedDt) : undefined;
      });
    }
    return res;
  }
}
