import * as dayjs from 'dayjs';
import { ICbicTaxMst } from 'app/entities/cbic-tax-mst/cbic-tax-mst.model';

export interface ICbicCircularMst {
  id?: number;
  contentId?: number | null;
  contentLanguage?: string | null;
  isActive?: string | null;
  createdDt?: dayjs.Dayjs | null;
  updatedDt?: dayjs.Dayjs | null;
  isAmended?: string | null;
  isOmitted?: string | null;
  parentId?: number | null;
  orderId?: number | null;
  versionNo?: number | null;
  createdBy?: number | null;
  updatedBy?: number | null;
  circularNo?: string | null;
  circularName?: string | null;
  circularCategory?: string | null;
  issueDt?: dayjs.Dayjs | null;
  amendDt?: dayjs.Dayjs | null;
  docFilePath?: string | null;
  docFileName?: string | null;
  circularDt?: dayjs.Dayjs | null;
  docFilePathHi?: string | null;
  docFileNameHi?: string | null;
  docFilePathAOD?: string | null;
  docFileNameAOD?: string | null;
  tax?: ICbicTaxMst | null;
  isAttachment?: string | null;
  cirRemarks?: string | null;
  isHistory?: string | null;
  circularNoHi?: string | null;
  circularNameHi?: string | null;
  circularDtHi?: dayjs.Dayjs | null;
  circularCategoryHi?: string | null;
  }

export class CbicCircularMst implements ICbicCircularMst {
  constructor(
    public id?: number,
    public contentId?: number | null,
    public contentLanguage?: string | null,
    public isActive?: string | null,
    public createdDt?: dayjs.Dayjs | null,
    public updatedDt?: dayjs.Dayjs | null,
    public isAmended?: string | null,
    public isOmitted?: string | null,
    public parentId?: number | null,
    public orderId?: number | null,
    public versionNo?: number | null,
    public createdBy?: number | null,
    public updatedBy?: number | null,
    public circularNo?: string | null,
    public circularName?: string | null,
    public circularCategory?: string | null,
    public issueDt?: dayjs.Dayjs | null,
    public amendDt?: dayjs.Dayjs | null,
    public docFilePath?: string | null,
    public docFileName?: string | null,
    public circularDt?: dayjs.Dayjs | null,
    public docFilePathHi?: string | null,
    public docFileNameHi?: string | null,
    public docFilePathAOD?: string | null,
    public docFileNameAOD?: string | null,
    public tax?: ICbicTaxMst | null,
    public isAttachment?: string | null,
    public cirRemarks?: string | null,
    public isHistory?: string | null,
    public circularNoHi?: string | null,
    public circularNameHi?: string | null,
    public circularDtHi?: dayjs.Dayjs | null,
    public circularCategoryHi?: string | null,
  ) {}
}

export function getCbicCircularMstIdentifier(cbicCircularMst: ICbicCircularMst): number | undefined {
  return cbicCircularMst.id;
}
