import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as $ from 'jquery';
import { AccountService } from 'app/core/auth/account.service';
import { Account } from 'app/core/auth/account.model';
import { faSearch} from '@fortawesome/free-solid-svg-icons';
import { HomeService} from 'app/home/home.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CbicNotificationMstService } from 'app/entities/cbic-notification-mst/service/cbic-notification-mst.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ICbicUpdateMst } from 'app/entities/cbic-notification-mst/cbic-update-mst.model';
import { RuleMstResponse } from 'app/entities/cbic-rule-mst/cbic-rule-mst.model';
import { DataUtils } from 'app/core/util/data-util.service';
import { saveAs} from 'file-saver';
import { CbicCircularMstService } from 'app/entities/cbic-circular-mst/service/cbic-circular-mst.service';
import { SessionStorageService } from 'ngx-webstorage';
import { TranslateService } from '@ngx-translate/core';
import { ICbicSearchInput, CbicSearchInput } from 'app/searchbar/searchbar.model';
import { CbicSearchOutputResult } from 'app/search-page/search-output.model';
import { CbicSearchService } from 'app/search-page/service/cbic-search-service';
declare let documentReady : any;

@Component({
  selector: 'cbic-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  account: Account | null = null;
  authSubscription?: Subscription;
  faSearch=faSearch;
  gstUpdates:any=[];
  currentSelectedTab:any="GST";
  stUpdates:any=[];
  ceUpdates:any=[];
  customUpdates:any=[];
  authenticationError = false;
  taxIdMap : Map<string, number> = new Map<string,number>();
  @ViewChild('content', { static: true }) modalSelection!: ElementRef;
  language: any
  totalRecordsCustRead : any
  
  constructor(
	private accountService: AccountService, 
	private router: Router,
	private homeService: HomeService,
  private modalService : NgbModal,
  protected cbicNotificationMstService:CbicNotificationMstService,
  private http : HttpClient,
  private dataUtils:DataUtils,
  private cbicCircularMstService: CbicCircularMstService,
  private sessionStorage: SessionStorageService,
  private service: CbicSearchService,
  private translateService: TranslateService) {
    this.taxIdMap.set('GST',1000001);
    this.taxIdMap.set('Customs',1000002);
    this.taxIdMap.set('Central Excise',1000003);
    this.taxIdMap.set('Service Tax',1000004);

  }

  ngOnInit(): void {
    this.authSubscription = this.accountService.getAuthenticationState().subscribe(account => (this.account = account));
    
      this.fetchLatestUpdates();
      this.gstUpdates=['1','2','3','4'];
      
      this.homeService.homePageToken()
      .subscribe(
        () => {
          this.authenticationError = false;
          if (!this.router.getCurrentNavigation()) {
            // There were no routing during login (eg from navigationToStoredUrl)
            this.router.navigate(['']);
          }
        },
        () => (this.authenticationError = true)
      );

     /*  if(!this.accountService.modalOpen){
        this.modalService.open(this.modalSelection,{centered: true});
        this.accountService.modalOpen = true;
      } */
      
      documentReady();
      this.language = this.sessionStorage.retrieve('locale')
      this.translateService.use(this.language);
      if(this.language === "hi"){
        this.currentSelectedTab = "जीएसटी"

        this.taxIdMap.set('जीएसटी', 1000001);
        this.taxIdMap.set('सीमा शुल्क', 1000002);
        this.taxIdMap.set('केंद्रीय उत्पाद शुल्क', 1000003);
        this.taxIdMap.set('सेवा कर', 1000004);
      }

      if (this.language === 'en') {
        this.taxIdMap.set('GST', 1000001);
        this.taxIdMap.set('Customs', 1000002);
        this.taxIdMap.set('Central Excise', 1000003);
        this.taxIdMap.set('Service Tax', 1000004);
      }
  
  }
  fetchLatestUpdates() :void{
    this.cbicNotificationMstService.fetchLatestUpdatesbyTaxId(Number(this.taxIdMap.get(this.currentSelectedTab))).
    subscribe(
      (res: HttpResponse<ICbicUpdateMst[]>) => {
        
        this.gstUpdates=res.body;
        }
    );
  }

  navigateNotification():void{
    const navigationExtras: NavigationExtras = {
      state: {
        menuInput: JSON.parse("{\"taxId\":"+String(this.taxIdMap.get(this.currentSelectedTab))+",\"menuType\":\"Notifications\"}")
      }
    };
    
    this.router.navigate(['/content-page/explore-notification'],navigationExtras);
  }
  viewDocuments(notificationPath:any):void{
    this.http.get('content/pdf/' + String(notificationPath),
        { responseType: 'text' })
        .subscribe(res => {
          if(res){

          
            const json=JSON.parse(res);
            const fileURL = 'data:application/pdf;base64,'+String(json.data);
            this.dataUtils.openFile(String(json.data), "application/pdf");
          }else{
            alert("No data found");
          }
        }
        );
  }

  downloadDocuments(updateType:any,id: any, language: any): void {
    if(updateType === "Notification") {
      this.cbicNotificationMstService
        .downloadNotification(Number(id), language).subscribe(
          (res: RuleMstResponse) => {
            const data = res.data;
            const byteCharacters = atob(String(data));
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            saveAs(blob, String(res.fileName));
          }
        );
    }
    if(updateType === "Circular") {
      this.cbicCircularMstService
        .download(Number(id), language).subscribe(
          (res: RuleMstResponse) => {
            const data = res.data;
            const byteCharacters = atob(String(data));
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            saveAs(blob, String(res.fileName));
          }
        );
    }
    
  }
  navigateCirculars():void{
    const navigationExtras: NavigationExtras = {
      state: {
        menuInput: JSON.parse("{\"taxId\":"+String(this.taxIdMap.get(this.currentSelectedTab))+",\"menuType\":\"Circulars\"}")
      }
    };
    this.router.navigate(['/content-page/explore-circulars'],navigationExtras);
  }

  loadUpdates(tabName:any):void{
    if(this.sessionStorage.retrieve('locale') === "hi"){
      if(tabName === 'GST'){
        this.currentSelectedTab = "जीएसटी"
      }

      if(tabName === 'Customs'){
        this.currentSelectedTab = "सीमा शुल्क"
      }

      if(tabName === 'Central Excise'){
        this.currentSelectedTab = "केंद्रीय उत्पाद शुल्क"
      }

      if(tabName === 'Service Tax'){
        this.currentSelectedTab = "सेवा कर"
      }

      
    } 

    if(this.sessionStorage.retrieve('locale') === "en"){
      this.currentSelectedTab=tabName;
    }
    //this.currentSelectedTab=tabName;
    this.fetchLatestUpdates();
  }
  isAuthenticated(): boolean {
    return this.accountService.isAuthenticated();
  }

  login(): void {
    this.router.navigate(['/login']);
  }

  viewDocumentsV2(notificationPath:any, id:any, type:any, selectedTab:any):void{
    if(selectedTab === 'Notification'){
      selectedTab = 'Notifications';
    }
    if(selectedTab === 'Circular'){
      selectedTab = 'Circulars';
    }
    const link = this.router.serializeUrl(this.router.createUrlTree(['view-pdf/'+ String(id)+ '/'+ String(type) +'/'+String(selectedTab)]));
    window.open(link, '_blank');
  }

  ngOnDestroy(): void {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

}
