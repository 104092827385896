import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { Search } from 'app/core/request/request.model';
import { ICbicActSectionMst, getCbicActSectionMstIdentifier } from '../cbic-act-section-mst.model';

export type EntityResponseType = HttpResponse<ICbicActSectionMst>;
export type EntityArrayResponseType = HttpResponse<ICbicActSectionMst[]>;

@Injectable({ providedIn: 'root' })
export class CbicActSectionMstService {
  
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/cbic-act-section-msts');
  public resourceSearchUrl = this.applicationConfigService.getEndpointFor('api/_search/cbic-act-section-msts');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(cbicActSectionMst: ICbicActSectionMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicActSectionMst);
    return this.http
      .post<ICbicActSectionMst>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(cbicActSectionMst: ICbicActSectionMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicActSectionMst);
    return this.http
      .put<ICbicActSectionMst>(`${this.resourceUrl}/${getCbicActSectionMstIdentifier(cbicActSectionMst) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(cbicActSectionMst: ICbicActSectionMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicActSectionMst);
    return this.http
      .patch<ICbicActSectionMst>(`${this.resourceUrl}/${getCbicActSectionMstIdentifier(cbicActSectionMst) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ICbicActSectionMst>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  findByChapterId(chapterid: number): Observable<EntityArrayResponseType> {
    return this.http
      .get<ICbicActSectionMst[]>(`${this.resourceUrl}/findByChapterId/${chapterid}`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }
  findByActId(actId: number) : Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicActSectionMst[]>(`${this.resourceUrl}/findByActId/${actId}`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }
  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicActSectionMst[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  search(req: Search): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicActSectionMst[]>(this.resourceSearchUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  addCbicActSectionMstToCollectionIfMissing(
    cbicActSectionMstCollection: ICbicActSectionMst[],
    ...cbicActSectionMstsToCheck: (ICbicActSectionMst | null | undefined)[]
  ): ICbicActSectionMst[] {
    const cbicActSectionMsts: ICbicActSectionMst[] = cbicActSectionMstsToCheck.filter(isPresent);
    if (cbicActSectionMsts.length > 0) {
      const cbicActSectionMstCollectionIdentifiers = cbicActSectionMstCollection.map(
        cbicActSectionMstItem => getCbicActSectionMstIdentifier(cbicActSectionMstItem)!
      );
      const cbicActSectionMstsToAdd = cbicActSectionMsts.filter(cbicActSectionMstItem => {
        const cbicActSectionMstIdentifier = getCbicActSectionMstIdentifier(cbicActSectionMstItem);
        if (cbicActSectionMstIdentifier == null || cbicActSectionMstCollectionIdentifiers.includes(cbicActSectionMstIdentifier)) {
          return false;
        }
        cbicActSectionMstCollectionIdentifiers.push(cbicActSectionMstIdentifier);
        return true;
      });
      return [...cbicActSectionMstsToAdd, ...cbicActSectionMstCollection];
    }
    return cbicActSectionMstCollection;
  }

  viewAllSectionByActId(actId: number) : Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicActSectionMst[]>(`${this.resourceUrl}/viewAllSectionByActId/${actId}`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  viewSectionById(sectionId: number) : Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicActSectionMst[]>(`${this.resourceUrl}/viewSectionById/${sectionId}`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  protected convertDateFromClient(cbicActSectionMst: ICbicActSectionMst): ICbicActSectionMst {
    return Object.assign({}, cbicActSectionMst, {
      issueDt: cbicActSectionMst.issueDt?.isValid() ? cbicActSectionMst.issueDt.toJSON() : undefined,
      amendDt: cbicActSectionMst.amendDt?.isValid() ? cbicActSectionMst.amendDt.toJSON() : undefined,
      createdDt: cbicActSectionMst.createdDt?.isValid() ? cbicActSectionMst.createdDt.toJSON() : undefined,
      updatedDt: cbicActSectionMst.updatedDt?.isValid() ? cbicActSectionMst.updatedDt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.issueDt = res.body.issueDt ? dayjs(res.body.issueDt) : undefined;
      res.body.amendDt = res.body.amendDt ? dayjs(res.body.amendDt) : undefined;
      res.body.createdDt = res.body.createdDt ? dayjs(res.body.createdDt) : undefined;
      res.body.updatedDt = res.body.updatedDt ? dayjs(res.body.updatedDt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((cbicActSectionMst: ICbicActSectionMst) => {
        cbicActSectionMst.issueDt = cbicActSectionMst.issueDt ? dayjs(cbicActSectionMst.issueDt) : undefined;
        cbicActSectionMst.amendDt = cbicActSectionMst.amendDt ? dayjs(cbicActSectionMst.amendDt) : undefined;
        cbicActSectionMst.createdDt = cbicActSectionMst.createdDt ? dayjs(cbicActSectionMst.createdDt) : undefined;
        cbicActSectionMst.updatedDt = cbicActSectionMst.updatedDt ? dayjs(cbicActSectionMst.updatedDt) : undefined;
      });
    }
    return res;
  }

}
