import * as dayjs from 'dayjs';
import { ICbicTaxMst } from 'app/entities/cbic-tax-mst/cbic-tax-mst.model';
import { ICbicActMst } from 'app/entities/cbic-act-mst/cbic-act-mst.model';
import { ICbicActChapterMst } from 'app/entities/cbic-act-chapter-mst/cbic-act-chapter-mst.model';

export interface ICbicActSectionMst {
  id?: number;
  contentId?: number | null;
  sectionNo?: number | null;
  sectionName?: string | null;
  issueDt?: dayjs.Dayjs | null;
  amendDt?: dayjs.Dayjs | null;
  contentFilePath?: string | null;
  contentFileName?: string | null;
  contentData?: string | null;
  isActive?: string | null;
  isAmended?: string | null;
  parentId?: number | null;
  orderId?: number | null;
  versionNo?: number | null;
  createdBy?: number | null;
  createdDt?: dayjs.Dayjs | null;
  updatedBy?: number | null;
  updatedDt?: dayjs.Dayjs | null;
  taxId?: ICbicTaxMst | null;
  actId?: ICbicActMst | null;
  chapterId?: ICbicActChapterMst | null;
  type:string;
  contentFilePathHi?: string | null;
  contentFileNameHi?: string | null;
  sectionNoHi?: number | null;
  sectionNameHi?: string | null;
}

export class CbicActSectionMst implements ICbicActSectionMst {
  constructor(
    public id?: number,
    public contentId?: number | null,
    public sectionNo?: number | null,
    public sectionName?: string | null,
    public issueDt?: dayjs.Dayjs | null,
    public amendDt?: dayjs.Dayjs | null,
    public contentFilePath?: string | null,
    public contentFileName?: string | null,
    public contentData?: string | null,
    public isActive?: string | null,
    public isAmended?: string | null,
    public parentId?: number | null,
    public orderId?: number | null,
    public versionNo?: number | null,
    public createdBy?: number | null,
    public createdDt?: dayjs.Dayjs | null,
    public updatedBy?: number | null,
    public updatedDt?: dayjs.Dayjs | null,
    public taxId?: ICbicTaxMst | null,
    public actId?: ICbicActMst | null,
    public chapterId?: ICbicActChapterMst | null,
    public contentFilePathHi?: string | null,
    public contentFileNameHi?: string | null,
    public sectionNoHi?: number | null,
    public sectionNameHi?: string | null,
    public type= 'CbicActSectionMst'
  ) {}
}

export function getCbicActSectionMstIdentifier(cbicActSectionMst: ICbicActSectionMst): number | undefined {
  return cbicActSectionMst.id;
}
