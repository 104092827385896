
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ProfileService } from 'app/layouts/profiles/profile.service';
import { SessionStorageService } from 'ngx-webstorage';
@Component({
  selector: 'cbic-mega-menu-item',
  templateUrl: './mega-menu-item.component.html',
  styleUrls: ['./mega-menu-item.component.scss']
})
export class MegaMenuItemComponent implements OnInit {
  openAPIEnabled?: boolean;
  inProduction?: boolean;
  @ Input() menuItemTitle="";
  @Input() frequentViewList:any;
  @Input() lastUpdateList:any;
  @Input() informationLinks:any;
  @Input() logopath:any;
  @Input() currentSelectionAct:any;
  @Output() menuItemClicked = new EventEmitter<string>();
  constructor(
    private router: Router,
    private profileService: ProfileService,
    private sessionStorage: SessionStorageService
  ) { }

   
  ngOnInit(): void {
    this.profileService.getProfileInfo().subscribe(profileInfo => {
      this.inProduction = profileInfo.inProduction;
      this.openAPIEnabled = profileInfo.openAPIEnabled;
    });
    $(".sm-hide").on("click", function () { $("#navbarSupportedContent").removeClass("show"); });
  }
  navigate( path: string,menuInput:string):void{
    this.sessionStorage.store('path', path);
    this.sessionStorage.clear('actId')
    this.menuItemClicked.emit(this.menuItemTitle);
    
    const navigationExtras: NavigationExtras = {
      state: {
        menuInput: JSON.parse(menuInput)
      }
    };
    if(path==='externalLink'){
      window.open(JSON.parse(menuInput).externalLink, "_blank");
    }
    else{

    
    if(this.router.url===path){
      
        this.router.navigateByUrl('/#',{skipLocationChange:false}).then(() => {
          this.router.navigate([path],navigationExtras);
        });
    }
    else{

      
        this.router.navigate([path],navigationExtras);
      }
    }
  }
  

}
