export interface ICbicVisitorCount {
  id?: number;
  visitorCount?: number | null;
}

export class CbicVisitorCount implements ICbicVisitorCount {
  constructor(public id?: number, public visitorCount?: number | null) {}
}

export function getCbicVisitorCountIdentifier(cbicVisitorCount: ICbicVisitorCount): number | undefined {
  return cbicVisitorCount.id;
}
