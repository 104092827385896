<div class="page-header">
    <div class="container-fluid">
        <div class="row">
            <h5 style="font-size: 14px;"><label cbicTranslate="homeButton"> Home</label> <span style="color: #E19F20;">>></span> <label cbicTranslate="amendmentsOrdinances"> Amendments, Ordinances</label> <span style="color: #E19F20;">>></span> {{tabName}}</h5>
        </div>
    </div>
</div>
<div class="content-view">
    <div class="container-fluid">
        <div class="row">
            <div class="card text-center">
                <div class="card-body myset">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="financeActs-tab" data-bs-toggle="tab"
                                data-bs-target="#financeActs" type="button" role="tab" aria-controls="financeActs"
                                aria-selected="true" (click)="fetchOtherDocument('FINACT')" [ngClass]="{ 'active':otherTypeForActive==='FINACT'}" cbicTranslate="financeActs">Finance Acts</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="amendments-tab" data-bs-toggle="tab"
                                data-bs-target="#amendments" type="button" role="tab" aria-controls="amendments"
                                aria-selected="false" (click)="fetchOtherDocument('AMEND')" [ngClass]="{ 'active':otherTypeForActive==='AMEND'}" cbicTranslate="amendments">Amendments</button>
                        </li>

                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="ordinances-tab" data-bs-toggle="tab"
                                data-bs-target="#ordinances" type="button" role="tab" aria-controls="ordinances"
                                aria-selected="false" (click)="fetchOtherDocument('ORDNCE')" [ngClass]="{ 'active':otherTypeForActive==='ORDNCE'}" cbicTranslate="ordinances" >Ordinances</button>
                        </li>
                    </ul>
                </div>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="act" role="tabpanel" aria-labelledby="act-tab">
                        <div class="container" style="margin: 0%;padding: 0px;">
                            <div class="row">
                                <div #readingArea class="content-col col-lg-12 col-md-12" id="readingArea">
                                    <cbic-table-content-display [dataType]="'OTHR'"
                                    [documentList]="documentList">
                                    </cbic-table-content-display>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>