
import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProfileService } from 'app/layouts/profiles/profile.service';
@Component({
  selector: 'cbic-content-error-page',
  templateUrl: './content-error-page.component.html',
  styleUrls: ['./content-error-page.component.scss']
})
export class ContentErrorPageComponent implements OnInit {
  inProduction?: boolean;
  openAPIEnabled?: boolean;
  constructor(
    private profileService: ProfileService
  ) { 
    
  }
 
  ngOnInit(): void {
    
    this.profileService.getProfileInfo().subscribe(profileInfo => {
      this.inProduction = profileInfo.inProduction;
      this.openAPIEnabled = profileInfo.openAPIEnabled;
    });
    
    
  }

}
