<div>
  <cbic-page-ribbon></cbic-page-ribbon>
  <div>
    <router-outlet name="navbar"></router-outlet>
  </div>

  <div class="">
    <div class="">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<div class="home-page">
  <cbic-footer></cbic-footer>
</div>
<cbic-spinner></cbic-spinner>