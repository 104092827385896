<section class="search-sec">
    <div class="container">
        <form action="#" method="post" novalidate="novalidate" >
            <div class="row">
                <div class="col-lg-12">
                    <form name="searchBarForm"   role="form" [formGroup]="searchBarForm" novalidate="novalidate" (ngSubmit)="search()">
                    <div class="row">
                        <div class="input-group flex-nowrap">
                            <button class="btn disablebtn" cbicTranslate="selectCategory">Select Category</button>
                            <select  class="form-select" #regulationCategorySelection
                                    id="inputGroupSelectCategoryForContentPage"
                                    aria-label="Category Selection" style="width: 5%;" 
                                    (change)="changeCategorySelection(regulationCategorySelection.value)" >

                            <option *ngIf="storageLanguage === 'en'" value="All">All</option> 
                            <option *ngIf="storageLanguage === 'hi'" value="All">सभी</option> 

                             <option  *ngFor="let category of regulationsCategoryList; let i = index" value="{{category.regulationCategory}}"  >
                                <div *ngIf="storageLanguage === 'hi'">{{category.regulationCategoryHi}}</div>
                                <div *ngIf="storageLanguage === 'en'">{{category.regulationCategory}}</div>
                             </option> 

                            </select>
                            <span class="input-group-text"></span>
                            <button class="btn disablebtn" style="width: 15%;" cbicTranslate="selectRegulation">Select Regulation</button>
                            <select class="form-select" #regulationDocSelection
                                id="inputGroupSelectRegulationListForContentPage"
                                aria-label="Regulation Document Selection"
                                formControlName="regulationDocId" [(ngModel)]="selectedRegulationDocId" style="width: 15%;" 
                                (change)='fireRegulationDocSelected(regulationDocSelection.value)'>
                                
                                <option *ngFor="let regulation of regulationDocList; let i = index"  value="{{regulation.id}}" title="{{regulation.regulationDocName}}">
                                   
                                   <div *ngIf="storageLanguage === 'hi'">{{regulation.regulationDocNameHi}}</div>
                                   <div *ngIf="storageLanguage === 'hi' && (regulation.regulationDocNameHi===null || regulation.regulationDocNameHi==='')">{{regulation.regulationDocName}}</div>
                                   <div *ngIf="storageLanguage === 'en'">{{regulation.regulationDocName}}</div>

                            
                                </option>
                                
                                
                            </select>
                            <input class="form-control" list="regulationDatalistOptions" id="regulationDataList" #regulationSelection
                                placeholder="{{ 'enterRegulation' | translate }}" formControlName="regulationId" (change)="fireRegulationSelected(regulationSelection.value)" aria-label="Regulation  Selection" autocomplete="off"/>
                            <!-- <datalist id="regulationDatalistOptions">
                                <option *ngFor="let regulation of regulationList; let i = index"  data-value="{{regulation.id}}" value="{{regulation.regulationNo}}-{{regulation.regulationName}}"></option>
                            </datalist> -->

                            <datalist id="regulationDatalistOptions" *ngIf="storageLanguage === 'en'">
                                <option *ngFor="let regulation of regulationList; let i = index"  data-value="{{regulation.id}}" value="{{regulation.regulationNo}}-{{regulation.regulationName}}"></option>
                            </datalist>
                            <datalist id="regulationDatalistOptions"  *ngIf="storageLanguage === 'hi'">
                                <div *ngFor="let regulation of regulationList; let i = index">
                                <option *ngIf="regulation.regulationNoHi"  data-value="{{regulation.id}}" value="{{regulation.regulationNoHi}}-{{regulation.regulationNameHi}}"></option>
                                <option *ngIf="regulation.regulationNoHi===null || regulation.regulationNoHi===''" data-value="{{regulation.id}}" value="{{regulation.regulationNo}}-{{regulation.regulationName}}"></option>
                                </div>
                            </datalist>

                            <span class="input-group-text"></span>
                            <input type="text" class="form-control" placeholder="{{ 'enterKeyword' | translate }}" aria-label="Username" maxlength="100" #regSearchKeyword
                                aria-describedby="addon-wrapping" formControlName="keyword" autocomplete="off"/>
                            <button class="btn" *ngIf="!searched" type="button" (click)="search()"><fa-icon [icon]="faSearch"></fa-icon></button>
                            <button class="btn" *ngIf="searched" type="button" (click)="clear()"><fa-icon [icon]="faTimesCircle"></fa-icon></button>
                            
                            
                        </div>
                        <div class="advance-search">
                            <a *ngIf="storageLanguage === 'en'" [routerLink]="" (click)="openAllSearch()"><u>Click here </u>to search across all {{taxName}} {{tabName}}</a>
                            <a *ngIf="storageLanguage === 'hi'" [routerLink]="" (click)="openAllSearch()">सभी {{taxName}} {{tabName}} में खोजने के लिए यहां <u>क्लिक करें</u></a>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        </form>
    </div>
</section>

<ng-template #allSearch let-modal>
    <div class="modal-content all-search-modal">
        <div class="modal-header page-header">
            <h4 *ngIf="storageLanguage === 'en'">Search across all {{taxName}} {{tabName}}</h4>
            <h4 *ngIf="storageLanguage === 'hi'">सभी {{taxName}} {{tabName}} में खोजें</h4>

            <button type="button" class="btn btn-light" (click)="modal.close('Close click')"><i
                    class="fas fa-times"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-12">
                    <form name="searchBarFormAll" role="form" [formGroup]="searchBarFormAll"
                        novalidate="novalidate" (ngSubmit)="searchAll()">
                        <div class="input-group flex-nowrap search-sec">
                            <input type="text" class="form-control" placeholder="{{ 'enterKeyword' | translate }}" aria-label="Username"
                                maxlength="100" #allRuleSearchKeyword aria-describedby="addon-wrapping"
                                formControlName="keywordAll" autocomplete="off"/>
                                <button class="btn" type="button" (click)="searchAll()"><fa-icon [icon]="faSearch"></fa-icon></button>
                        </div>
                        <p *ngIf="allSearchMsg" class="all-search-msg">{{allSearchMsg}}</p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-template>