import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { Search } from 'app/core/request/request.model';
import { ICbicRuleSectionMst, getCbicRuleSectionMstIdentifier } from '../cbic-rule-section-mst.model';

export type EntityResponseType = HttpResponse<ICbicRuleSectionMst>;
export type EntityArrayResponseType = HttpResponse<ICbicRuleSectionMst[]>;

@Injectable({ providedIn: 'root' })
export class CbicRuleSectionMstService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/cbic-rule-section-msts');
  public resourceSearchUrl = this.applicationConfigService.getEndpointFor('api/_search/cbic-rule-section-msts');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  findRuleByChapterId(chapterid: number): Observable<EntityArrayResponseType> {
    return this.http
      .get<ICbicRuleSectionMst[]>(`${this.resourceUrl}/findRuleByChapterId/${chapterid}`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  findSectionByRuleId(ruleId: number) : Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicRuleSectionMst[]>(`${this.resourceUrl}/findSectionByRuleId/${ruleId}`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  viewBySectionAllRules(ruleId: number) : Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicRuleSectionMst[]>(`${this.resourceUrl}/viewBySectionAllRules/${ruleId}`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  fetchRuleSectionForSelectedRule(sectionId: number) : Observable<EntityArrayResponseType>{
    return this.http
      .get<ICbicRuleSectionMst[]>(`${this.resourceUrl}/fetchRuleSectionForSelectedRule/${sectionId}`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  create(cbicRuleSectionMst: ICbicRuleSectionMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicRuleSectionMst);
    return this.http
      .post<ICbicRuleSectionMst>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(cbicRuleSectionMst: ICbicRuleSectionMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicRuleSectionMst);
    return this.http
      .put<ICbicRuleSectionMst>(`${this.resourceUrl}/${getCbicRuleSectionMstIdentifier(cbicRuleSectionMst) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(cbicRuleSectionMst: ICbicRuleSectionMst): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(cbicRuleSectionMst);
    return this.http
      .patch<ICbicRuleSectionMst>(`${this.resourceUrl}/${getCbicRuleSectionMstIdentifier(cbicRuleSectionMst) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
  return from(this.http
      .get<ICbicRuleSectionMst>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .toPromise().then(((res: EntityResponseType) => this.convertDateFromServer(res))));
    // return this.http
    //   .get<ICbicRuleSectionMst>(`${this.resourceUrl}/${id}`, { observe: 'response' })
    //   .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicRuleSectionMst[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  search(req: Search): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ICbicRuleSectionMst[]>(this.resourceSearchUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  addCbicRuleSectionMstToCollectionIfMissing(
    cbicRuleSectionMstCollection: ICbicRuleSectionMst[],
    ...cbicRuleSectionMstsToCheck: (ICbicRuleSectionMst | null | undefined)[]
  ): ICbicRuleSectionMst[] {
    const cbicRuleSectionMsts: ICbicRuleSectionMst[] = cbicRuleSectionMstsToCheck.filter(isPresent);
    if (cbicRuleSectionMsts.length > 0) {
      const cbicRuleSectionMstCollectionIdentifiers = cbicRuleSectionMstCollection.map(
        cbicRuleSectionMstItem => getCbicRuleSectionMstIdentifier(cbicRuleSectionMstItem)!
      );
      const cbicRuleSectionMstsToAdd = cbicRuleSectionMsts.filter(cbicRuleSectionMstItem => {
        const cbicRuleSectionMstIdentifier = getCbicRuleSectionMstIdentifier(cbicRuleSectionMstItem);
        if (cbicRuleSectionMstIdentifier == null || cbicRuleSectionMstCollectionIdentifiers.includes(cbicRuleSectionMstIdentifier)) {
          return false;
        }
        cbicRuleSectionMstCollectionIdentifiers.push(cbicRuleSectionMstIdentifier);
        return true;
      });
      return [...cbicRuleSectionMstsToAdd, ...cbicRuleSectionMstCollection];
    }
    return cbicRuleSectionMstCollection;
  }

  protected convertDateFromClient(cbicRuleSectionMst: ICbicRuleSectionMst): ICbicRuleSectionMst {
    return Object.assign({}, cbicRuleSectionMst, {
      createdDt: cbicRuleSectionMst.createdDt?.isValid() ? cbicRuleSectionMst.createdDt.toJSON() : undefined,
      updatedDt: cbicRuleSectionMst.updatedDt?.isValid() ? cbicRuleSectionMst.updatedDt.toJSON() : undefined,
      issueDt: cbicRuleSectionMst.issueDt?.isValid() ? cbicRuleSectionMst.issueDt.toJSON() : undefined,
      amendDt: cbicRuleSectionMst.amendDt?.isValid() ? cbicRuleSectionMst.amendDt.toJSON() : undefined,
      enactmentDt: cbicRuleSectionMst.enactmentDt?.isValid() ? cbicRuleSectionMst.enactmentDt.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDt = res.body.createdDt ? dayjs(res.body.createdDt) : undefined;
      res.body.updatedDt = res.body.updatedDt ? dayjs(res.body.updatedDt) : undefined;
      res.body.issueDt = res.body.issueDt ? dayjs(res.body.issueDt) : undefined;
      res.body.amendDt = res.body.amendDt ? dayjs(res.body.amendDt) : undefined;
      res.body.enactmentDt = res.body.enactmentDt ? dayjs(res.body.enactmentDt) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((cbicRuleSectionMst: ICbicRuleSectionMst) => {
        cbicRuleSectionMst.createdDt = cbicRuleSectionMst.createdDt ? dayjs(cbicRuleSectionMst.createdDt) : undefined;
        cbicRuleSectionMst.updatedDt = cbicRuleSectionMst.updatedDt ? dayjs(cbicRuleSectionMst.updatedDt) : undefined;
        cbicRuleSectionMst.issueDt = cbicRuleSectionMst.issueDt ? dayjs(cbicRuleSectionMst.issueDt) : undefined;
        cbicRuleSectionMst.amendDt = cbicRuleSectionMst.amendDt ? dayjs(cbicRuleSectionMst.amendDt) : undefined;
        cbicRuleSectionMst.enactmentDt = cbicRuleSectionMst.enactmentDt ? dayjs(cbicRuleSectionMst.enactmentDt) : undefined;
      });
    }
    return res;
  }
}
