
    <h4>Refine By</h4>
    <div class="accordion accordion-flush" id="accordionFlushExample">
        <div class="accordion-item">
            <div class="accordion-header" id="flush-headingOne">
                <button class="accordion-button collapsed" type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne" aria-expanded="false"
                    aria-controls="flush-collapseOne">
                    Amendment Year
                </button>
            </div>
            <div id="flush-collapseOne" class="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <input class="form-control" type="year" placeholder="YYYY" [(ngModel)]="amendYear" maxlength="4">
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="flush-headingTwo">
                <button class="accordion-button collapsed" type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo" aria-expanded="false"
                    aria-controls="flush-collapseTwo">
                    Amendment Period
                </button>
            </div>
            <div id="flush-collapseTwo" class="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <span class="input-group-text">Date
                        From-To</span>
                    <input class="form-control py-2 border-right-0 border"
                        type="date" value="" [(ngModel)]="amendPeriodFrom">
                    <input class="form-control py-2 border-right-0 border"
                        type="date" value="" (blur)="compareTwoDates()" [(ngModel)]="amendPeriodTo">
                </div>
            </div>

        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="flush-headingThree">
                <button class="accordion-button collapsed" type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree" aria-expanded="false"
                    aria-controls="flush-collapseThree">
                    Industry
                </button>
            </div>
            <div id="flush-collapseThree" class="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body text-start">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                        <label class="form-check-label" for="defaultCheck1">
                            Mining
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck2">
                        <label class="form-check-label" for="defaultCheck2">
                            Automobile
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck3">
                        <label class="form-check-label" for="defaultCheck3">
                            Oil & Gas
                        </label>
                      </div>
                </div>
            </div>

        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="flush-headingFour">
                <button class="accordion-button collapsed" type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour" aria-expanded="false"
                    aria-controls="flush-collapseFour">
                    Information Type
                </button>
            </div>
            <div id="flush-collapseFour" class="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body text-start">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                        <label class="form-check-label" for="defaultCheck1">
                            Document
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck2">
                        <label class="form-check-label" for="defaultCheck2">
                            Form
                        </label>
                      </div>
                </div>
            </div>

        </div>
       
        <!--<div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingFive">
                <button class="accordion-button collapsed" type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFive" aria-expanded="false"
                    aria-controls="flush-collapseFive">
                    Visitor Intent
                </button>
            </h2>
            <div id="flush-collapseFive" class="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body text-start">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                        <label class="form-check-label" for="defaultCheck1">
                            Import
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck2">
                        <label class="form-check-label" for="defaultCheck2">
                            Export
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck3">
                        <label class="form-check-label" for="defaultCheck3">
                            Immigration
                        </label>
                      </div>
                </div>
            </div>

        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingSix">
                <button class="accordion-button collapsed" type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseSix" aria-expanded="false"
                    aria-controls="flush-collapseSix">
                    Visitor Type
                </button>
            </h2>
            <div id="flush-collapseSix" class="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body text-start">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                        <label class="form-check-label" for="defaultCheck1">
                            Personal
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck2">
                        <label class="form-check-label" for="defaultCheck2">
                            Business
                        </label>
                      </div>
                </div>
            </div>

        </div>-->
    </div>
    <br>
    <div>
        <button class="btn" (click)="applyRefineParameter()">Apply</button>
        <button class="btn" (click)="applyResetRefineParameter()">Reset</button>
    </div>
