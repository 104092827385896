import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { CbicAlliedActDtlsService } from 'app/entities/cbic-allied-act-dtls/service/cbic-allied-act-dtls.service';
import { CbicAttachmentDtlsService } from 'app/entities/cbic-attachment-dtls/service/cbic-attachment-dtls.service';
import { CbicCircularMstService } from 'app/entities/cbic-circular-mst/service/cbic-circular-mst.service';
import { CbicContentHistoryMapDtlsService } from 'app/entities/cbic-content-history-map-dtls/service/cbic-content-history-map-dtls.service';
import { CbicInstructionMstService } from 'app/entities/cbic-instruction-mst/service/cbic-instruction-mst.service';
import { CbicNotificationMstService } from 'app/entities/cbic-notification-mst/service/cbic-notification-mst.service';
import { CbicOrderMstService } from 'app/entities/cbic-order-mst/service/cbic-order-mst.service';
import { RuleMstResponse } from 'app/entities/cbic-rule-mst/cbic-rule-mst.model';
import { saveAs} from 'file-saver';
declare let hideLanguageSwitch : any;

@Component({
  selector: 'cbic-view-pdf',
  templateUrl: './view-pdf.component.html',
  styleUrls: ['./view-pdf.component.scss']
})
export class ViewPdfComponent implements OnInit {

  notificationPath: any;
  fileURL: any;
  page: any;
  totalPages: any;
  isLoaded = false;
  docId: any;
  selectedTab: any;
  docType: any;

  constructor(private http : HttpClient, protected activatedRoute: ActivatedRoute, 
              private sanitizer: DomSanitizer,
              private cbicCircularMstService: CbicCircularMstService,
              protected notiService: CbicNotificationMstService,
              protected instService: CbicInstructionMstService,
              protected orderService: CbicOrderMstService,
              protected historyService: CbicContentHistoryMapDtlsService,
              protected attachmentServie: CbicAttachmentDtlsService,
              protected cbicAlliedActDtlsService:CbicAlliedActDtlsService) { 
    this.activatedRoute.data.subscribe(({ pdfPath }) => {
      this.notificationPath = pdfPath
      
    });

    this.docId = activatedRoute.snapshot.params['id'];
    this.selectedTab = activatedRoute.snapshot.params['selectedTab'];
    this.docType = activatedRoute.snapshot.params['type'];
    //(window as any).pdfWorkerSrc = '/assets/pdfjs/pdf.worker.min.js';
  }

  ngOnInit(): void {
  hideLanguageSwitch();
    this.page = 1;
    this.totalPages = 0;
    if(this.notificationPath){
      this.http.get('content/pdf/' + String(this.notificationPath),
        { responseType: 'text' })
        .subscribe(res => {
          if(res){
            const json=JSON.parse(res);
            this.fileURL = 'data:application/pdf;base64,'+String(json.data);
          }else{
            alert("No Data Found");
          }
        }
        );
    }


  }
  
  afterLoadComplete(pdfData: any):void {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }

  nextPage():void {
    this.page++;
  }

  prevPage():void {
    this.page--;
  }

  downloadDocuments(id: any, language: any): void {
    
    if(this.selectedTab === "Notifications") {
      this.notiService
        .downloadNotification(Number(id), language).subscribe(
          (res: RuleMstResponse) => {
            const data = res.data;
            const byteCharacters = atob(String(data));
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            saveAs(blob, String(res.fileName));
          }
        );
    }
    if(this.selectedTab === "Allied Acts") {
      this.cbicAlliedActDtlsService
        .downloadAlliedActDocument(Number(id), language).subscribe(
          (res: RuleMstResponse) => {
            const data = res.data;
            const byteCharacters = atob(String(data));
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            saveAs(blob, String(res.fileName));
          }
        );
    }
    if(this.selectedTab === "Circulars") {
      this.cbicCircularMstService
        .download(Number(id), language).subscribe(
          (res: RuleMstResponse) => {
            const data = res.data;
            const byteCharacters = atob(String(data));
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            saveAs(blob, String(res.fileName));
          }
        );
    }
    if(this.selectedTab === "Instructions") {
      this.instService
        .download(Number(id), language).subscribe(
          (res: RuleMstResponse) => {
            const data = res.data;
            const byteCharacters = atob(String(data));
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            saveAs(blob, String(res.fileName));
          }
        );
    }
    if(this.selectedTab === "Orders") {
      this.orderService
        .download(Number(id), language).subscribe(
          (res: RuleMstResponse) => {
            const data = res.data;
            const byteCharacters = atob(String(data));
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            saveAs(blob, String(res.fileName));
          }
        );
    }

    if(this.selectedTab === "History") {
      this.historyService
        .downloadNotification(Number(id), language).subscribe(
          (res: RuleMstResponse) => {
            const data = res.data;
            const byteCharacters = atob(String(data));
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            saveAs(blob, String(res.fileName));
          }
        );
    }

    if(this.selectedTab === "ATT") {
      this.attachmentServie
        .downloadNotification(Number(id), language).subscribe(
          (res: RuleMstResponse) => {
            const data = res.data;
            const byteCharacters = atob(String(data));
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            saveAs(blob, String(res.fileName));
          }
        );
    }
    
    
  }

}
