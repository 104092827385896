import * as dayjs from 'dayjs';
import { ICbicTaxMst } from 'app/entities/cbic-tax-mst/cbic-tax-mst.model';
import { ICbicRuleMst } from 'app/entities/cbic-rule-mst/cbic-rule-mst.model';
import { ICbicRuleChapterMst } from 'app/entities/cbic-rule-chapter-mst/cbic-rule-chapter-mst.model';

export interface ICbicRuleSectionMst {
  id?: number;
  contentId?: number | null;
  contentLanguage?: string | null;
  isActive?: string | null;
  createdDt?: dayjs.Dayjs | null;
  updatedDt?: dayjs.Dayjs | null;
  isAmended?: string | null;
  parentId?: number | null;
  orderId?: number | null;
  versionNo?: number | null;
  createdBy?: number | null;
  updatedBy?: number | null;
  sectionNo?: string | null;
  sectionName?: string | null;
  issueDt?: dayjs.Dayjs | null;
  amendDt?: dayjs.Dayjs | null;
  contentFilePath?: string | null;
  contentFileName?: string | null;
  contentData?: string | null;
  enactmentDt?: dayjs.Dayjs | null;
  docFilePath?: string | null;
  docFileName?: string | null;
  docFileType?: string | null;
  cbicTaxMst?: ICbicTaxMst | null;
  cbicRuleMst?: ICbicRuleMst | null;
  cbicRuleChapterMst?: ICbicRuleChapterMst | null;
  contentFilePathHi?: string | null;
  contentFileNameHi?: string | null;
  sectionNoHi?: number | null;
  sectionNameHi?: string | null;
}

export class CbicRuleSectionMst implements ICbicRuleSectionMst {
  constructor(
    public id?: number,
    public contentId?: number | null,
    public contentLanguage?: string | null,
    public isActive?: string | null,
    public createdDt?: dayjs.Dayjs | null,
    public updatedDt?: dayjs.Dayjs | null,
    public isAmended?: string | null,
    public parentId?: number | null,
    public orderId?: number | null,
    public versionNo?: number | null,
    public createdBy?: number | null,
    public updatedBy?: number | null,
    public sectionNo?: string | null,
    public sectionName?: string | null,
    public issueDt?: dayjs.Dayjs | null,
    public amendDt?: dayjs.Dayjs | null,
    public contentFilePath?: string | null,
    public contentFileName?: string | null,
    public contentData?: string | null,
    public enactmentDt?: dayjs.Dayjs | null,
    public docFilePath?: string | null,
    public docFileName?: string | null,
    public docFileType?: string | null,
    public cbicTaxMst?: ICbicTaxMst | null,
    public cbicRuleMst?: ICbicRuleMst | null,
    public cbicRuleChapterMst?: ICbicRuleChapterMst | null,
    public contentFilePathHi?: string | null,
    public contentFileNameHi?: string | null,
    public sectionNoHi?: number | null,
    public sectionNameHi?: string | null
  ) {}
}

export function getCbicRuleSectionMstIdentifier(cbicRuleSectionMst: ICbicRuleSectionMst): number | undefined {
  return cbicRuleSectionMst.id;
}
